import WebinarCard from '#app/customerEvents/WebinarCard'
import { LoadingSpinner } from '#components'
import { getBankWebinars } from '#services/payloadCMS/payloadCMS'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
    Box,
    Collapse,
    Drawer,
    List,
    ListItem,
    Pagination,
    Stack,
    Tab,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { isFuture, isPast } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { TransitionGroup } from 'react-transition-group'
import { Webinar } from '#services/payloadCMS/payload-types'
import { RichTextContent, RichTextRenderer } from '#components/PayloadCMS/RichTextRenderer/RichTextRenderer'
import { SILVER_GRAY } from '#app/colors/colors'
import { getPayloadEmployee, getPayloadImage, getWebinarCategoryName } from '#app/customerEvents/customerEvents'

const WEBINARS_PER_PAGE = 1

export const Webinars = () => {
    const { t } = useTranslation()

    const [page, setPage] = useState(0)
    const [webinarForSlideout, setWebinarForSlideout] = useState<Webinar | null>(null)

    const {
        data: webinars,
        isFetching,
        error,
    } = useQuery({
        queryFn: async () => await getBankWebinars(),
        queryKey: ['webinars'],
    })
    const pastWebinarsSorted = webinars
        ?.filter((e) => isPast(new Date(e.dateTo)))
        ?.sort((a, b) => new Date(b.dateTo).getTime() - new Date(a.dateTo).getTime())

    const upcomingWebinarsSorted = webinars
        ?.filter((e) => isFuture(new Date(e.dateTo)))
        ?.sort((a, b) => new Date(a.dateTo).getTime() - new Date(b.dateTo).getTime())
    const selectedPastWebinars = pastWebinarsSorted?.slice(
        page * WEBINARS_PER_PAGE,
        page * WEBINARS_PER_PAGE + WEBINARS_PER_PAGE
    )

    const [tab, setTab] = useState<'upcoming' | 'past'>(upcomingWebinarsSorted?.length > 0 ? 'upcoming' : 'past')

    const handleTabChange = (event: React.SyntheticEvent, newValue: 'upcoming' | 'past') => {
        setTab(newValue)
    }

    return (
        <Stack>
            {isFetching && !webinars && <LoadingSpinner />}
            {!isFetching && (error || !webinars) && (
                <Typography>{t('pages-BankDashboard.couldNotLoadWebinars')}</Typography>
            )}
            {webinars && (
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        <Tab
                            label={t('pages-BankDashboard.upcomingWebinar')}
                            value="upcoming"
                            icon={<i className="ri-video-line" />}
                        />
                        <Tab
                            label={t('pages-BankDashboard.pastWebinars')}
                            value="past"
                            icon={<i className="ri-archive-line" />}
                        />
                    </TabList>
                    <TabPanel value={'upcoming'} sx={{ pt: 1, px: 0, pb: 0 }}>
                        {upcomingWebinarsSorted?.length > 0 ? (
                            <Stack spacing={2}>
                                {upcomingWebinarsSorted?.map((webinar) => (
                                    <WebinarCard
                                        key={webinar.id}
                                        webinar={webinar}
                                        onReadMore={() => setWebinarForSlideout(webinar)}
                                    />
                                ))}
                            </Stack>
                        ) : (
                            <Typography variant={'body1'}>{t('pages-BankDashboard.noUpcomingWebinars')}</Typography>
                        )}
                    </TabPanel>
                    <TabPanel value={'past'} sx={{ pt: 1, px: 0, pb: 0 }}>
                        {pastWebinarsSorted?.length > 0 && (
                            <List sx={{ p: 0, mb: 1 }}>
                                <TransitionGroup>
                                    {selectedPastWebinars?.map((webinar) => (
                                        <Collapse key={webinar.id}>
                                            <ListItem sx={{ m: 0, p: 0 }}>
                                                <WebinarCard
                                                    key={webinar.id}
                                                    webinar={webinar}
                                                    onReadMore={() => setWebinarForSlideout(webinar)}
                                                />
                                            </ListItem>
                                        </Collapse>
                                    ))}
                                </TransitionGroup>
                            </List>
                        )}
                        {pastWebinarsSorted.length > WEBINARS_PER_PAGE && (
                            <Pagination
                                count={Math.ceil(pastWebinarsSorted.length / WEBINARS_PER_PAGE)}
                                color={'primary'}
                                onChange={(_, page) => setPage(page - 1)}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            )}
            <WebinarInfoSlideOut webinar={webinarForSlideout} onClose={() => setWebinarForSlideout(null)} />
        </Stack>
    )
}

const WebinarInfoSlideOut = ({ webinar, onClose }: { webinar: Webinar | null; onClose: () => void }) => {
    const { t } = useTranslation()

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isUpcoming = isFuture(new Date(webinar?.dateTo))

    return (
        <Drawer
            open={!!webinar}
            anchor={isMobile ? 'bottom' : 'right'}
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    maxHeight: isMobile ? '80%' : '100%',
                    width: !isMobile ? '50rem' : '100%',
                },
            }}
        >
            {webinar && (
                <Stack px={3} spacing={2} mt={2} mb={2} maxWidth="100%">
                    <Stack spacing={1}>
                        <Typography variant={'h3'}>{webinar.title}</Typography>
                        <Typography variant={'subtitle2'}>
                            {getWebinarCategoryName(webinar)}
                            {webinar?.episodeNumber
                                ? ' – ' + t('pages-BankDashboard.episode') + ' ' + webinar?.episodeNumber
                                : ''}
                        </Typography>
                    </Stack>
                    {isUpcoming &&
                        webinar?.description?.root?.children?.map((block: RichTextContent, key) => {
                            return <RichTextRenderer key={key} content={block} />
                        })}
                    {!isUpcoming &&
                        webinar?.descriptionPast?.root?.children?.map((block: RichTextContent, key) => {
                            return <RichTextRenderer key={key} content={block} />
                        })}
                    <Stack spacing={2}>
                        {isUpcoming && webinar.guests.length > 0 && (
                            <Stack spacing={1.5}>
                                <Typography fontWeight="700" fontSize="1.8rem">
                                    {webinar.guests.length > 1
                                        ? t('pages-BankDashboard.guests')
                                        : t('pages-BankDashboard.guest')}
                                </Typography>
                                <Stack spacing={1.5}>
                                    {webinar.guests.map((guest) => (
                                        <Stack
                                            key={webinar?.id + guest?.id}
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Stack alignItems="center" justifyContent="center">
                                                <img
                                                    src={getPayloadImage(guest.image)}
                                                    alt={guest.name}
                                                    style={{ width: '5rem', height: '5rem', borderRadius: '100rem' }}
                                                />
                                            </Stack>
                                            <Box>
                                                <Typography fontWeight="700" fontSize="1.6rem">
                                                    {guest.name}
                                                </Typography>
                                                <Typography fontSize="1.4rem" color={SILVER_GRAY[500]}>
                                                    {guest.title}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Stack>
                        )}
                        {isUpcoming && webinar.hosts.length > 0 && (
                            <Stack spacing={1.5}>
                                <Typography fontWeight="700" fontSize="1.8rem">
                                    {webinar.hosts.length > 1
                                        ? t('pages-BankDashboard.hosts')
                                        : t('pages-BankDashboard.host')}
                                </Typography>
                                <Stack spacing={1.5}>
                                    {webinar.hosts.map((host) => {
                                        const employee = getPayloadEmployee(host.employee)
                                        const employeeImage =
                                            getPayloadImage(employee?.image) !== null
                                                ? getPayloadImage(employee?.image)
                                                : ''
                                        return (
                                            <Stack
                                                key={getPayloadEmployee(host.employee).id}
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                            >
                                                <Stack alignItems="center" justifyContent="center">
                                                    <img
                                                        src={employeeImage}
                                                        alt={employee.id}
                                                        style={{
                                                            width: '5rem',
                                                            height: '5rem',
                                                            borderRadius: '100rem',
                                                        }}
                                                    />
                                                </Stack>
                                                <Box>
                                                    <Typography fontWeight="700" fontSize="1.6rem">
                                                        {employee.name}
                                                    </Typography>
                                                    <Typography fontSize="1.4rem" color={SILVER_GRAY[500]}>
                                                        {employee.title}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            )}
        </Drawer>
    )
}
