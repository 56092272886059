import { useState } from 'react'
import Paper from '#app/components/Paper/FxPaper'
import BankEmailRegistrationForm from './BankEmailRegistrationForm'
import { useTranslation } from 'react-i18next'
import { BankDto } from '@fixrate/fixrate-query'
import { Alert, Divider, MenuItem, Select, Typography } from '@mui/material'
import AsyncButton from '#app/components/Button/AsyncButton'

export default function BankEmail({ bank }: { bank: BankDto }) {
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [fileType, setFileType] = useState('excel')

    const handleGenerateNotificationsOverview = async () => {
        setLoading(true)
        try {
            const response = await fetch(`/api/bank/notifications/overview?language=${i18n.language}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept:
                        fileType === 'excel'
                            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                            : 'application/json',
                },
            })
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            if (fileType === 'csv') {
                const data = await response.json()
                const csv = jsonToCsv(data.emailTypesFixrateToBank)
                downloadCsv(csv, 'report.csv')
            } else if (fileType === 'excel') {
                const blob = await response.blob()
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'email_notifications_report.xlsx'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
            }
        } catch (error) {
            console.error('Error fetching the report:', error)
        } finally {
            setLoading(false)
        }
    }

    const jsonToCsv = (json) => {
        const headers = ['Prefix', 'Subject']
        const csvRows = [headers.join(',')]

        for (const key in json) {
            if (Object.hasOwn(json, key)) {
                const value = JSON.stringify(json[key], replacer)
                csvRows.push(`${key},${value}`)
            }
        }
        return csvRows.join('\n')
    }

    const replacer = (key, value) => (value === null ? '' : value)

    const downloadCsv = (csv, filename) => {
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', filename)
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    return (
        <Paper id="email-settings" title={t('pages-mybank.emailNotificationSettingsHeader')}>
            <Typography sx={{ marginBottom: '1.5rem' }}>{t('pages-mybank.emailNotificationSettingsInfo')}</Typography>
            <BankEmailRegistrationForm bank={bank} />
            <Divider sx={{ marginTop: '2rem' }} />
            <Typography sx={{ margin: '2rem 0 1rem 0' }}>{t('pages-mybank.emailNotificationsHeader')}</Typography>
            <Alert icon={false} severity="info" sx={{ marginBottom: '1rem' }}>
                {t('pages-mybank.emailNotificationsInfo')}
            </Alert>
            <Typography sx={{ fontWeight: '700', marginBottom: '1rem' }}>{t('pages-mybank.selectFileType')}</Typography>
            <Select value={fileType} onChange={(e) => setFileType(e.target.value)} displayEmpty>
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="excel">Excel</MenuItem>
            </Select>
            <AsyncButton
                onClick={handleGenerateNotificationsOverview}
                loading={loading}
                sx={{ display: 'flex', marginTop: '3rem' }}
            >
                {loading ? t('common.loading') : t('pages-mybank.generateOverview')}
            </AsyncButton>
        </Paper>
    )
}
