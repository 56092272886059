import { Fragment } from 'react'
import styles from './FundGrid.module.scss'
import { Animated, CurrencyOutput, InterestOutput, LoadingSpinner } from '#components'
import classNames from 'classnames'
import { FundDto } from '@fixrate/fixrate-query'
import useShoppingCart from '#services/useShoppingCart'
import FundReturnsChart from '#blocks/FundReturnsChart/FundReturnsChart'
import subYears from 'date-fns/subYears'
import { useTranslation } from 'react-i18next'
import { Box, Button, Stack, Tooltip } from '@mui/material'
import { fundCreditDurationCategoryTMap, fundMorningstarCategoryTMap } from '#services/enumTranslationKeyMapping'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import ShareClassSelect from '../FundTable/TableComponents/ShareClassSelect'
import { FundLogo } from '#pages/FundDetails/FundLogo/FundLogo'
import useCurrentDepositor from '#services/useCurrentDepositor'
import { useFundPlatformFee } from '#services/platformFeeCalculator'
import { useLocalStorage } from '#app/services/LocalStorageContext'
import LongTooltip from '#components/LongTooltip/LongTooltip'
import useFundShareClassSelected from '#services/useFundShareClassSelected'

type Props = {
    fund: FundDto
    openShoppingCart: () => void
}

export default function FundCard({ fund, openShoppingCart }: Props) {
    const { t } = useTranslation()
    const currentDepositor = useCurrentDepositor()
    const { dispatchLocalStorage } = useLocalStorage()
    const { calculateTotalCosts } = useFundPlatformFee()

    const [shareClassId] = useFundShareClassSelected(fund.id, fund.fundShareClasses[0].id)

    const selectedShareClass =
        fund.fundShareClasses.find((fund) => fund.id === shareClassId) || fund.fundShareClasses[0]

    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(fund.id)
    const selectedReturnValue = selectedShareClass?.annualizedReturnRates?.PERIOD_3Y

    const platformFee = useFundPlatformFee().fundPlatformFeeForPortfolio?.[fund.fundCategory]
    const totalCosts = calculateTotalCosts(fund.fundCategory, selectedShareClass)

    return platformFee == null || totalCosts == null ? (
        <LoadingSpinner />
    ) : (
        <Animated
            tag="li"
            data-fx={fund.id}
            data-cy="fundCardItem"
            data-creditduration={t(fundCreditDurationCategoryTMap[fund.creditDurationCategory])}
            data-omf={fund.omf}
            data-subordinatedloans={fund.subOrdinatedLoans}
            className={classNames(styles.fundCard, styles.cardWrapper, isInShoppingCart && styles.inCart)}
        >
            <div className={classNames(styles.cardWrapper, isInShoppingCart && styles.inCart)}>
                <div className={styles.cardHeader}>
                    <div>
                        {fund.esgCategory === 'ARTICLE_6' && (
                            <span className={classNames(styles.esgIndicator, styles.article6)}>
                                <i className="ri-leaf-line" />
                                <span>6</span>
                            </span>
                        )}
                        {fund.esgCategory === 'ARTICLE_8' && (
                            <span className={classNames(styles.esgIndicator, styles.article8)}>
                                <i className="ri-leaf-line" />
                                <span>8</span>
                            </span>
                        )}
                        {fund.esgCategory === 'ARTICLE_9' && (
                            <span className={classNames(styles.esgIndicator, styles.article9)}>
                                <i className="ri-leaf-line" />
                                <span>9</span>
                            </span>
                        )}
                    </div>
                    <div>
                        <FundLogo fundName={selectedShareClass?.fullName} />
                    </div>
                </div>
                <div className={styles.cardHeader}>
                    <div className={styles.title}>
                        <p data-cy="fundCategoryText" className={styles.fundCategory}>
                            {t(fundMorningstarCategoryTMap[fund.morningstarCategory])}
                        </p>
                        <h2
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                dispatchLocalStorage({
                                    type: 'SHARE_CLASS_FOR_SLIDEOUT',
                                    payload: selectedShareClass?.id,
                                })
                            }
                            data-cy="fundNameText"
                            className={styles.name}
                        >
                            {fund.name}
                        </h2>
                    </div>
                </div>
                <Box>
                    {fund && (
                        <ShareClassSelect
                            fund={fund}
                            depositor={currentDepositor}
                            tooltip={selectedShareClass?.specialPrice && t('pages-fund-details.specialPriceTooltip')}
                        />
                    )}
                </Box>
                <div>
                    <div className={styles.returns}>
                        <div className={styles.returnInfo}>
                            <p className={styles.description}>{t('pages-fund-details.3yAnnualized')}</p>
                            {selectedReturnValue === undefined || selectedReturnValue === null ? (
                                <Tooltip title={t('pages-fund-details.notAvailableTooltip')} arrow>
                                    <p className={classNames(styles.returnLabel, styles.notAvailable)}>
                                        {t('pages-fund-details.notAvailable')}
                                    </p>
                                </Tooltip>
                            ) : (
                                <InterestBadge sx={{ fontSize: 14 }} interest={selectedReturnValue} />
                            )}
                        </div>
                        <div className={styles.chart}>
                            <FundReturnsChart
                                fundShareClass={selectedShareClass}
                                options={{
                                    showAxes: false,
                                    showDateRange: false,
                                    defaultStartDate: subYears(new Date(), 3),
                                    aspectRatio: { width: 5, height: 2 },
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.risk}>
                            <p className={styles.description}>{t('pages-fund-details.risk')}</p>
                            <div className={styles.indicator}>
                                {[...new Array(7)].map((_, i) => (
                                    <span key={i} className={i < fund.risk ? styles.active : undefined} />
                                ))}
                            </div>
                        </div>
                        <div className={styles.costs}>
                            <p className={styles.description}>{t('pages-fund-details.minimumBuyAmount')}</p>
                            <p className={styles.value}>
                                <span>{CurrencyOutput.formatMillion(selectedShareClass?.minimumInvestment, '')}</span>
                            </p>
                        </div>
                        <div className={styles.costs}>
                            <p className={styles.description}>{t('pages-fund-details.totalCostsFrom')}</p>
                            <p className={styles.value}>
                                <span>{InterestOutput.formatWithDecimals(totalCosts.from, 3)}</span>
                                <LongTooltip
                                    inline
                                    title={
                                        t('pages-fund-details.totalCosts') +
                                        ' ' +
                                        (totalCosts.from === totalCosts.to
                                            ? InterestOutput.formatWithDecimals(totalCosts.from, 3)
                                            : `fra ${InterestOutput.formatWithDecimals(totalCosts.from, 3)} til ${InterestOutput.formatWithDecimals(totalCosts.to, 3)}`)
                                    }
                                    description={t('pages-fund-details.totalCostsExplanation')}
                                />
                            </p>
                        </div>
                    </div>
                    <Stack direction="row" justifyContent="space-between" my={1.5}>
                        <Fragment>
                            {!isInShoppingCart && (
                                <Button
                                    size="small"
                                    startIcon={<i className="ri-shopping-cart-2-line" />}
                                    variant="outlined"
                                    data-cy="addToCartButton"
                                    onClick={() => shoppingCart.addItem(fund.id, shareClassId)}
                                >
                                    {t('pages-fund-details.addToCart')}
                                </Button>
                            )}
                            {isInShoppingCart && (
                                <Button
                                    size="small"
                                    color="error"
                                    variant="outlined"
                                    startIcon={<i className="ri-delete-bin-line" />}
                                    data-cy="removeFromCartButton"
                                    onClick={() => shoppingCart.removeItem(fund.id)}
                                >
                                    {t('pages-fund-details.removeFromCart')}
                                </Button>
                            )}
                        </Fragment>
                        <Button
                            data-cy="readMoreButton"
                            size="small"
                            variant="outlined"
                            onClick={() =>
                                dispatchLocalStorage({
                                    type: 'SHARE_CLASS_FOR_SLIDEOUT',
                                    payload: selectedShareClass?.id,
                                })
                            }
                            startIcon={<i className="ri-eye-line" />}
                        >
                            {t('pages-fund-details.readMore')}
                        </Button>
                    </Stack>
                    <button
                        className={classNames(styles.goToCart, isInShoppingCart && styles.visible)}
                        onClick={openShoppingCart}
                    >
                        <span className={styles.corneredAngle}></span>
                        <i className="ri-shopping-cart-line" />
                        <span>{t('pages-fund-details.goToCart')}</span>
                    </button>
                </div>
            </div>
        </Animated>
    )
}
