import { fundDetailsLink } from '#app/services/fundDetailsLink'
import { Animated } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import { FundLogo } from '#pages/FundDetails/FundLogo/FundLogo'
import FundTableHeaderCell from '#pages/FundMarketplace/FundTable/FundTableHeaderCell'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useShoppingCart from '#services/useShoppingCart'
import useTableSort, { Column } from '#services/useTableSort'
import { DepositorDto, FundDto } from '@fixrate/fixrate-query'
import { Alert } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './FundTable.module.scss'
import ShareClassSelect from './TableComponents/ShareClassSelect'
import TableShoppingCartButton from './TableComponents/TableShoppingCartButton'
import useFundShareClassSelected from '#services/useFundShareClassSelected'

type Props = {
    funds: FundDto[]
    selectedShareClasses: { [fundId: string]: string }
}

type Row = FundDto & {
    logo: string
    shareClass: number
    returnRateYTD: number
    returnRate6M: number
    returnRate1Y: number
    returnRate3Y: number
    returnRate5Y: number
    returnRate10Y: number
}

type RowProps = {
    row: Row
    depositor: DepositorDto
}

export default function ReturnsTable({ funds, selectedShareClasses }: Props) {
    const { t } = useTranslation()
    const currentDepositor = useCurrentDepositor()

    const rows = funds.map((fund) => {
        const selectedFundShareClass =
            fund.fundShareClasses.find((fundClass) => fundClass.id === selectedShareClasses[fund.id]) ||
            fund.fundShareClasses[0]
        return {
            ...fund,
            logo: '',
            shareClass: selectedFundShareClass?.minimumInvestment,
            returnRateYTD: selectedFundShareClass?.returnRates?.PERIOD_YTD,
            returnRate6M: selectedFundShareClass?.returnRates?.PERIOD_6M,
            returnRate1Y: selectedFundShareClass?.returnRates?.PERIOD_1Y,
            returnRate3Y: selectedFundShareClass?.annualizedReturnRates?.PERIOD_3Y,
            returnRate5Y: selectedFundShareClass?.annualizedReturnRates?.PERIOD_5Y,
            returnRate10Y: selectedFundShareClass?.annualizedReturnRates?.PERIOD_10Y,
        }
    })

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<Row>(
        rows,
        'risk',
        'asc',
        'returnRate1Y',
        'desc'
    )

    const columns: Column<Row>[] = [
        { id: 'logo', label: '', sortable: false },
        { id: 'name', label: t('pages-fund-details.name'), sortable: true },
        { id: 'shareClass', label: t('pages-fund-details.shareClassAndAmount'), sortable: true },
        { id: 'returnRateYTD', label: t('pages-fund-details.ytd'), sortable: true },
        { id: 'returnRate6M', label: t('pages-fund-details.6m'), sortable: true },
        { id: 'returnRate1Y', label: t('pages-fund-details.1y'), sortable: true },
        { id: 'returnRate3Y', label: t('pages-fund-details.3yAnnualized'), sortable: true },
        { id: 'returnRate5Y', label: t('pages-fund-details.5yAnnualized'), sortable: true },
        { id: 'returnRate10Y', label: t('pages-fund-details.10yAnnualized'), sortable: true },
    ]

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <FundTableHeaderCell<Row>
                                key={column.id}
                                column={column}
                                sortKey={sortKey}
                                sortDirection={sortDirection}
                                onClick={(c) => setSorting(c.id)}
                            />
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedRows.map((row) => (
                        <TableRow key={row.id} row={row} depositor={currentDepositor} />
                    ))}
                </tbody>
            </table>
            <Alert severity="info" sx={{ width: '100%', maxWidth: '120rem', fontSize: '1.2rem', mt: '2rem' }}>
                <span>{t('pages-fund-details.returnsExplanation')}</span>
            </Alert>
        </>
    )
}

function TableRow({ row, depositor }: RowProps) {
    const { t } = useTranslation()
    const [shareClassId] = useFundShareClassSelected(row.id, row.fundShareClasses[0].id)
    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(row.id)
    const selectedShareClass = row.fundShareClasses.find((fund) => fund.id === shareClassId)

    return (
        <Animated tag={'tr'} className={isInShoppingCart ? styles.active : null}>
            <td className={classNames(styles.logo, isInShoppingCart ? styles.highlight : null)}>
                <FundLogo fundName={selectedShareClass?.fullName} squared={true} />
            </td>
            <td className={classNames(styles.name)}>
                <Link className={styles.fundLink} to={fundDetailsLink(row)}>
                    {row.name}
                </Link>
            </td>
            <td>
                {row && (
                    <ShareClassSelect
                        fund={row}
                        depositor={depositor}
                        tooltip={selectedShareClass?.specialPrice && t('pages-fund-details.specialPriceTooltip')}
                    />
                )}
            </td>
            <ValueRow value={selectedShareClass?.returnRates?.PERIOD_YTD} />
            <ValueRow value={selectedShareClass?.returnRates?.PERIOD_6M} />
            <ValueRow value={selectedShareClass?.returnRates?.PERIOD_1Y} />
            <ValueRow value={selectedShareClass?.annualizedReturnRates?.PERIOD_3Y} />
            <ValueRow value={selectedShareClass?.annualizedReturnRates?.PERIOD_5Y} />
            <ValueRow value={selectedShareClass?.annualizedReturnRates?.PERIOD_10Y} />
            <TableShoppingCartButton fund={row} />
        </Animated>
    )
}

function ValueRow({ value, className }: { value: number | null; className?: string }) {
    return (
        <td className={className}>
            <InterestBadge sx={{ fontSize: 12 }} interest={value} />
        </td>
    )
}
