import { Currency, FundCustomerDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useCommand } from '#command'
import { format } from 'date-fns'
import { useState } from 'react'
import { Box, FormLabel, Stack, Typography } from '@mui/material'
import { YearSelect } from '#app/components/YearSelect/YearSelect'
import { MonthSelect } from '#components/MonthSelect/MonthSelect'
import { NumberInput } from '#components'
import { useFieldState } from '@fixrate/fieldstate'
import { LoadingButton } from '@mui/lab'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'

type Props = {
    customer: FundCustomerDto
}

export function SetFixedMonthlyPlatformFee({ customer }: Props) {
    const { t } = useTranslation()
    const { changeDepositorFundPlatformFixedMonthlyFee } = useCommand()
    const [startDateYear, setStartDateYear] = useState<number>(new Date().getFullYear())
    const [startDateMonth, setStartDateMonth] = useState<number>(new Date().getMonth())
    const fixedMonthlyFeeField = useFieldState<number>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (value === null || isNaN(value)) {
            return t('validation.mustBeNumber')
        }
        if (value < 0) {
            return t('validation.mustBePositiveOrZero')
        }
        return ''
    })
    const [submitting, setSubmitting] = useState(false)

    const isDuplicate = Object.keys(customer.fixedMonthlyFeeHistory).includes(
        format(new Date(startDateYear, startDateMonth), 'yyyy-MM')
    )
    const rangeStart = 2022
    const rangeEnd = new Date().getFullYear() + 3

    async function onSubmit() {
        const valid = fixedMonthlyFeeField.validate()
        if (!valid || submitting) {
            return
        }
        setSubmitting(true)
        try {
            const { waitForCommand } = await changeDepositorFundPlatformFixedMonthlyFee(
                customer.depositorId,
                format(new Date(startDateYear, startDateMonth), 'yyyy-MM'),
                fixedMonthlyFeeField.value
            )
            const success = await waitForCommand()
            if (success) {
                resetForm()
            }
        } finally {
            setSubmitting(false)
        }
    }

    function resetForm() {
        fixedMonthlyFeeField.reset()
    }

    return (
        <Box sx={{ backgroundColor: '#f9f7fd', py: 1, px: 2 }}>
            <Stack direction={'column'} spacing={2}>
                <Typography variant={'subtitle1'}>
                    {t('pages.fundCustomers.customerDetail.platformFee.setFixedMonthlyFee.subtitle')}
                </Typography>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                    <FormLabel>
                        {t('pages.fundCustomers.customerDetail.platformFee.setFixedMonthlyFee.startDate')}
                    </FormLabel>
                    <Stack direction={{ md: 'row', sm: 'column' }} spacing={2}>
                        <YearSelect
                            value={startDateYear}
                            rangeStart={rangeStart}
                            rangeEnd={rangeEnd}
                            onChange={setStartDateYear}
                        />
                        <MonthSelect value={startDateMonth} onChange={setStartDateMonth} />
                    </Stack>
                </Box>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                        <FormLabel>
                            {t('pages.fundCustomers.customerDetail.platformFee.setFixedMonthlyFee.fee')}
                        </FormLabel>
                        <NumberInput
                            enforceThousandSeparator={true}
                            value={fixedMonthlyFeeField.value}
                            onChange={fixedMonthlyFeeField.setValue}
                            error={!fixedMonthlyFeeField.valid}
                            formatFn={(value) =>
                                CurrencyOutputObject(value, {
                                    withCurrency: true,
                                    currency: 'NOK' as Currency,
                                    minimumDecimals: 0,
                                    maximumDecimals: 2,
                                    millionFormat: false,
                                })
                            }
                            placeholder={'1 000 kr'}
                        />
                    </Box>
                    <LoadingButton variant={'outlined'} onClick={onSubmit} loading={submitting} disabled={submitting}>
                        {isDuplicate ? t('pages.fundCustomers.customerDetail.platformFee.overwrite') : t('common.add')}
                    </LoadingButton>
                </Box>
            </Stack>
        </Box>
    )
}
