import { useState, useEffect, useMemo, useCallback } from 'react'
import { Box, Fade, IconButton, Stack, Typography } from '@mui/material'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { getMarketPlaceNews } from '#app/services/payloadCMS/payloadCMS'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSelector } from '#state/useSelector'
import { useLocation, useNavigate } from 'react-router-dom'
import { MarketplaceNew } from '#app/services/payloadCMS/payload-types'
import { hasFundAccessSelector } from '#app/state/selectors'

export default function NewsNotification() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isVisible, setIsVisible] = useState(false)
    const hasFund = useSelector((state) => hasFundAccessSelector(state))
    const [relatedNews, setRelatedNews] = useState<MarketplaceNew | null>(null)
    const location = useLocation()
    const funds = useSelector((state) => state.funds)

    const {
        data: news,
        isFetching,
        error,
    } = useQuery({
        queryFn: async () => await getMarketPlaceNews(),
        queryKey: ['marketnews'],
    })

    const matchedFund = useMemo(() => {
        if (!funds || !location) return null
        const urlSegments = location.pathname.split('/')
        const extractedId = urlSegments[urlSegments.length - 1]
        return funds.find((fund) => fund.id === extractedId)
    }, [location, funds])

    const sortedNews = useMemo(() => {
        if (!news || news.length === 0) return []
        const filteredNews = news.filter(
            (article) => article.fundInformation?.length > 0 && !article.targetAudience?.includes('fixrateDraft')
        )
        return filteredNews.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    }, [news])

    const getArticleTeaser = useCallback((text: string): string => {
        if (!text) return '...'
        const words = text.length > 100 ? text.slice(0, 100) : text
        return words.trim() + '...'
    }, [])

    const findRelatedNewsArticle = useCallback((fund, newsArticles) => {
        if (!fund || !newsArticles || newsArticles.length === 0) return null

        const fundIsins = fund.fundShareClasses
            .map((shareClass) => shareClass.isin)
            .filter((isin) => !isin.includes('-deleted'))

        const fundLei = fund.lei
        const fundOrgNr = fund.managerOrganisationNationalIdentity

        // Step 1: First try to find article by orgNumber
        const relatedNewsArticle = newsArticles.find((article) =>
            article.fundInformation?.some((fundInfo) => fundInfo.orgNumber === fundOrgNr)
        )

        // Step 2: If no match by orgNumber, try to find by leiNumber
        const relatedNewsArticleByLei = !relatedNewsArticle
            ? newsArticles.find((article) =>
                  article.fundInformation?.some((fundInfo) => fundInfo.leiNumber === fundLei)
              )
            : null

        // Step 3: If no match by orgNumber or leiNumber, try to find by isin
        const relatedNewsArticleByIsin =
            !relatedNewsArticle && !relatedNewsArticleByLei
                ? newsArticles.find((article) =>
                      article.fundInformation?.some((fundInfo) => {
                          if (!fundInfo.isin) return false

                          // Handle case when isin is a single string
                          if (!fundInfo.isin.includes(';')) {
                              return fundIsins.includes(fundInfo.isin)
                          }

                          // Handle case when isin is a semicolon-separated string of multiple ISINs
                          const fundInfoIsins = fundInfo.isin
                              .split(';')
                              .map((isin) => isin.trim())
                              .filter((isin) => isin.length > 0)

                          return fundInfoIsins.some((isin) => fundIsins.includes(isin))
                      })
                  )
                : null

        // Use the first match found in order of priority
        return relatedNewsArticle || relatedNewsArticleByLei || relatedNewsArticleByIsin
    }, [])

    useEffect(() => {
        if (!hasFund || isFetching || error || !news || news.length === 0 || !matchedFund) {
            setIsVisible(false)
            return
        }

        const relatedArticle = findRelatedNewsArticle(matchedFund, sortedNews)

        if (relatedArticle) {
            setRelatedNews(relatedArticle)
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [hasFund, matchedFund, sortedNews, isFetching, error, findRelatedNewsArticle, news])

    if (!isVisible || !relatedNews) {
        return null
    }

    return (
        <Fade
            in={isVisible}
            timeout={{
                appear: 800,
                enter: 800,
                exit: 400,
            }}
            mountOnEnter
            unmountOnExit
            style={{
                transitionTimingFunction: isVisible ? 'cubic-bezier(0.34, 1.56, 0.64, 1)' : 'ease-out',
            }}
        >
            <Box
                id={'news-article-notification'}
                onClick={() => {
                    navigate(`/news/id/${relatedNews.id}`)
                }}
                sx={{
                    position: 'fixed',
                    zIndex: 1000,
                    bottom: 12,
                    left: 8,
                    maxWidth: { xs: '10rem', md: '23rem' },
                    height: '30rem',
                    color: 'white',
                    backgroundColor: PURPLE[900],
                    borderRadius: '1rem',
                    fontSize: '2.8rem',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.25)',
                    transform: 'translateY(0)',
                    transformOrigin: 'bottom',
                    cursor: 'pointer',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        padding: '1rem',
                        marginTop: '1.5rem',
                    }}
                >
                    <Typography
                        sx={{
                            padding: '1rem 2rem',
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            color: PURPLE[900],
                            backgroundColor: SPRING_GREEN[500],
                            borderRadius: '5rem',
                        }}
                    >
                        {t('pages-News.news')}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" height="100%">
                        <IconButton
                            aria-label="close notification"
                            onClick={() => {
                                setIsVisible(false)
                            }}
                        >
                            <i className="ri-close-line" style={{ color: 'white' }} />
                        </IconButton>
                    </Box>
                </Stack>
                <Stack sx={{ padding: '1.4rem' }}>
                    <Typography
                        sx={{
                            fontSize: '1.5rem',
                            color: 'white',
                            paddingBottom: '1rem',
                            fontWeight: 500,
                            lineHeight: 1.3,
                        }}
                    >
                        {relatedNews.title}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'white',
                            lineHeight: 1.6,
                            fontSize: '1.3rem',
                        }}
                    >
                        {relatedNews.content?.root?.children?.[0]?.children?.[0]?.text
                            ? getArticleTeaser(relatedNews.content.root.children[0].children[0].text)
                            : t('pages-News.readMore')}
                    </Typography>
                </Stack>
                <Box sx={{ position: 'absolute', bottom: '1rem', right: '3rem' }}>
                    <Typography sx={{ color: PURPLE[25], textDecoration: 'underline' }} variant="body2">
                        {t('pages-News.readMore')}
                    </Typography>
                </Box>
            </Box>
        </Fade>
    )
}
