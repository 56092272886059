import nb from 'date-fns/locale/nb'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { format } from 'date-fns'
import { useSessionDateFnsLocale } from '#services/useSessionLanguage'

type Props = {
    value: number
    onChange: (value: unknown) => void
}

export function MonthSelect({ value, onChange }: Props) {
    const locale = useSessionDateFnsLocale() || nb
    const months = Array.from({ length: 12 }, (_, i) => i)

    return (
        <Select
            sx={{ maxWidth: '12rem', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
            value={value}
            onChange={(e: SelectChangeEvent<number>) => onChange(e.target.value)}
        >
            {months.map((month) => (
                <MenuItem key={month} value={month}>
                    {format(new Date(0, month), 'MMMM', { locale: locale })}
                </MenuItem>
            ))}
        </Select>
    )
}
