import read from '#app/config'
import RegistrationProgress from '#app/layers/Signup/RegistrationProgress'
import OnboardingFrame from '#components/OnboardingFrame/OnboardingFrame'
import { useOnIdle } from '#services/OnIdle'
import { useSessionBroadcast } from '#services/sessionBroadcast'
import { logout, syncSessionState } from '#services/thunks/session'
import { SessionDto } from '@fixrate/fixrate-security'
import { GetState } from '#state/types'
import { useSelector } from '#state/useSelector'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LoginPortal from './LoginPortal'

type Props = {
    registration?: boolean
    method?: string[]
    onLoginSuccess?: (session: SessionDto) => void
    forceIdp?: boolean
    logoutOnCancel?: boolean
}

/**
 * The Login overlay
 * <p/>
 * See https://www.notion.so/fixrate/Innlogging-og-SSO-flyt-935bf83b4ca640c798d4fd0b9531f1d9?pvs=4 for details
 */
export default function Login({
    registration = false,
    method = null,
    onLoginSuccess = undefined,
    forceIdp,
    logoutOnCancel = false,
}: Props) {
    const { t } = useTranslation()

    const userFullName = useSelector(({ session }) => session.firstName + ' ' + session.lastName)
    const dispatch =
        useDispatch<
            (
                arg0: (dispatch: unknown, getState?: GetState) => Promise<void> | Promise<SessionDto>
            ) => Promise<SessionDto>
        >()
    const broadcast = useSessionBroadcast()
    const config = read()
    const navigate = useNavigate()

    // Go to marketplace when idle for 10 minutes
    // We do not want the login window to stay open for very long
    useOnIdle(() => {
        navigate('/marketplace')
    })

    const _onLoginSuccess = () => {
        dispatch(syncSessionState()).then((session) => {
            if (onLoginSuccess) {
                onLoginSuccess(session)
            }
        })
        broadcast.sendLoginMessage()
    }

    function onLoginSuccessForce() {
        window.location.reload()
    }

    function onCancel() {
        if (logoutOnCancel) {
            dispatch(logout(navigate))
        } else {
            navigate('/marketplace')
        }
    }

    const registrationProgress = registration && <RegistrationProgress step={0} />

    const getTexts: () => { instructions: string; header: string } = () => {
        if (registration) {
            return {
                instructions: t('layers-Login.registrationBankIDMessage'),
                header: t('layers-Login.registrationHeading'),
            }
        }
        if (forceIdp) {
            return {
                instructions: t('layers-Login.loginForceIdp'),
                header: t('layers-Login.welcomeHeading', { userFullName }),
            }
        }
        return {
            instructions: t('layers-Login.loginBankIDMessage'),
            header: t('layers-Login.loginHeading'),
        }
    }

    return (
        <OnboardingFrame header={getTexts().header} onCancel={onCancel} progress={registrationProgress}>
            <p>{getTexts().instructions}</p>
            <LoginPortal method={method} onLoginSuccess={_onLoginSuccess} onLoginSuccessForce={onLoginSuccessForce} />
            <Box mt={2}>
                <Button
                    onClick={() => window.location.replace(config.homePageUrl)}
                    size="small"
                    startIcon={<i className="ri-arrow-left-line" />}
                    sx={{ textDecoration: 'none', ':hover': { textDecoration: 'none' } }}
                >
                    {t('layers-Login.goToHomePage')}
                </Button>
            </Box>
        </OnboardingFrame>
    )
}
