import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { useEndpoint } from '#command'
import { CurrencyOutput, LoadingSpinner, NumberInput } from '#components'
import { setPotential } from '#services/thunks/user'
import { useSelector } from '#state/useSelector'
import { Box, InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

function InterestCalculator({ initialPotentialVolume }: { initialPotentialVolume?: number }) {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const timeout = useRef(null)
    const [monthlyGain, setMonthlyGain] = useState<null | number>(null)
    const [potentialVolume, setPotentialVolume] = useState<null | number>(initialPotentialVolume || null)
    const [historicInterestRate, setHistoricInterestRate] = useState<null | number>(null)
    const [type, setType] = useState('year')
    const [calculating, setCalculating] = useState(false)

    const dashboard = useSelector(({ dashboard }) => dashboard)

    const dispatch = useDispatch()
    const { getPotentialInterest } = useEndpoint()

    function valuePrUnit(unit: string, value: number) {
        if (value > 0) {
            switch (unit) {
                case 'day':
                    return value / 30
                case 'month':
                    return value
                case 'year':
                    return value * 12
            }
        }
        return 0
    }

    // Checking if the potential volume and historic interest rate is previously set once the component is mounted
    useEffect(() => {
        if (dashboard.potentialVolume && potentialVolume === null) {
            setPotentialVolume(dashboard.potentialVolume || 0)
        }
        if (dashboard.historicInterestRate && historicInterestRate === null) {
            console.log('dashboard.historicInterestRate', dashboard.historicInterestRate)
            setHistoricInterestRate(dashboard.historicInterestRate || 0)
        }
    }, [dashboard.historicInterestRate, dashboard.potentialVolume, historicInterestRate, potentialVolume])

    // Updates UI when dashboard is updated from the server or changed by the function 'setPotential' below
    useEffect(() => {
        setCalculating(true)
        if (dashboard.todaysMonthlyInterest !== null && dashboard.potentialMonthlyInterest !== null) {
            setMonthlyGain(dashboard.potentialMonthlyInterest - dashboard.todaysMonthlyInterest)
        } else {
            setMonthlyGain(null)
        }

        const timer = setTimeout(() => {
            setCalculating(false)
        }, 1000)

        return () => {
            clearTimeout(timer)
        }
    }, [dashboard])

    // Send data to server when potentialVolume and historicInterestRate have changed and remained untouched for 1 second
    useEffect(() => {
        if (timeout.current) {
            clearTimeout(timeout.current)
        }

        timeout.current = setTimeout(() => {
            if (
                potentialVolume !== dashboard.potentialVolume ||
                historicInterestRate !== dashboard.historicInterestRate
            ) {
                // Updates the potential volume and historic interest rate
                // If the user is authenticated, it is sent to fixrate-command and the potential interest is returned over the websocket
                // If the user is not authenticated, it is sent to fixrate-query and the potential interest is returned over the rest api
                // Either way, the potential interest is stored in the dashboard state
                dispatch(setPotential(potentialVolume || 0, historicInterestRate || 0))
                setCalculating(true)
                setMonthlyGain(null)
            }
        }, 1000)

        setCalculating(
            potentialVolume !== dashboard.potentialVolume || historicInterestRate !== dashboard.historicInterestRate
        )

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
                timeout.current = null
            }
        }
    }, [
        potentialVolume,
        historicInterestRate,
        dashboard.potentialVolume,
        dashboard.historicInterestRate,
        dispatch,
        getPotentialInterest,
    ])

    return (
        <Fragment>
            <p>{t('pages-onboarding.potentialInfo')}</p>
            <Stack spacing={2}>
                <Box>
                    <InputLabel>{t('common.bankDeposits')}</InputLabel>
                    <NumberInput
                        data-cy="potentialVolume"
                        sx={{ width: '16rem' }}
                        value={potentialVolume}
                        formatFn={(val) => CurrencyOutput.formatNoCode(val, 0)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                        }}
                        onChange={(number) => setPotentialVolume(number)}
                    />
                </Box>
                <Box>
                    <InputLabel>{t('pages-onboarding.currentInterestLabel')}</InputLabel>
                    <NumberInput
                        data-cy="potentialVolume"
                        sx={{ width: '9rem' }}
                        value={historicInterestRate}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                        onChange={(number) => setHistoricInterestRate(number)}
                    />
                </Box>
                <Box>
                    <InputLabel>{t('pages-onboarding.interestGain')}</InputLabel>
                    {calculating && potentialVolume !== null && historicInterestRate !== null ? (
                        <LoadingSpinner />
                    ) : (
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                opacity: historicInterestRate !== null && potentialVolume !== null ? 1 : 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="interestBig">
                                <span>
                                    {historicInterestRate !== null && potentialVolume !== null && monthlyGain !== null
                                        ? Currency(valuePrUnit(type, monthlyGain), { decimals: 0 })
                                        : Currency(0, { decimals: 0 })}
                                </span>
                            </Typography>
                            <Select sx={{ maxWidth: '11rem' }} value={type}>
                                <MenuItem value="day" onClick={() => setType('day')}>
                                    {t('pages-DashboardHeader.perDay')}
                                </MenuItem>
                                <MenuItem value="month" onClick={() => setType('month')}>
                                    {t('pages-DashboardHeader.perMonth')}
                                </MenuItem>
                                <MenuItem value="year" onClick={() => setType('year')}>
                                    {t('pages-DashboardHeader.perYear')}
                                </MenuItem>
                            </Select>
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Fragment>
    )
}

export default InterestCalculator
