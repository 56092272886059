import { Navigate, Route, Routes } from 'react-router-dom'
import DepositDetailsBank from './DepositDetailsBank/DepositDetailsBank'
import PortfolioBankIndex from './PortfolioBankIndex'

const PortfolioBank = () => {
    return (
        <Routes>
            <Route
                path="balance-reporting"
                element={<Navigate to={{ pathname: '/bank-reports/balance-reporting' }} />}
            />
            <Route path=":depositId" element={<DepositDetailsBank />} />
            <Route path="" element={<PortfolioBankIndex />} />
        </Routes>
    )
}

export default PortfolioBank
