import { Fragment } from 'react'
import MenuItem from './MenuItem'
import { useSelector } from '#state/useSelector'
import { isLoggedIn, messageTasksActionRequiredByUserCountSelector } from '#state/selectors'
import { useTranslation } from 'react-i18next'

export default function FundIntermediaryMenu() {
    const { t } = useTranslation()

    const loggedIn = useSelector(isLoggedIn)
    const messageTasksActionRequiredByUserCount = useSelector(messageTasksActionRequiredByUserCountSelector)

    const inboxMenuItem = (
        <MenuItem
            id="inboxMenuItem"
            link={'/inbox'}
            name={t('menu.inbox')}
            remixIcon="ri-inbox-line"
            counter={messageTasksActionRequiredByUserCount}
        />
    )

    const fundBuyOrderMenuItem = (
        <MenuItem
            id="fundBuyOrderMenuItem"
            link={'/fund-buy-orders'}
            name={t('menu.fundBuyOrders')}
            remixIcon="ri-shopping-cart-2-line"
        />
    )

    const fundSellOrderMenuItem = (
        <MenuItem
            id="fundSellOrderMenuItem"
            link={'/fund-sell-orders'}
            name={t('menu.fundSellOrders')}
            remixIcon="ri-hand-coin-line"
        />
    )

    const fundTransactions = (
        <MenuItem
            id="fundTransactionsMenuItem"
            link={'/fund-transactions'}
            name={t('menu.fundTransactions')}
            remixIcon="ri-briefcase-line"
        >
            <MenuItem.SubNav to="/fund-transactions">{t('menu.overview')}</MenuItem.SubNav>
            <MenuItem.SubNav to="/fund-transactions/import">{t('menu.import')}</MenuItem.SubNav>
        </MenuItem>
    )

    const fundAdminOverview = (
        <MenuItem
            id="fundAdminOverviewMenuItem"
            link={'/fund-admin-overview'}
            name={t('menu.fundAdminOverview')}
            remixIcon={'ri-layout-grid-line'}
        >
            <MenuItem.SubNav to={'/fund-admin-overview'}>{t('menu.fundAdminOverview')}</MenuItem.SubNav>
            <MenuItem.SubNav id="adminFundPlacementsLink" to="/fund-admin-overview/fund-placements">
                {t('menu.adminFundPlacements')}
            </MenuItem.SubNav>
        </MenuItem>
    )

    const loginMenuItem = (
        <MenuItem
            id="loginMenuItem"
            key="login"
            link={'/marketplace?login'}
            name={t('menu.logIn')}
            remixIcon="ri-account-circle-line"
        />
    )

    const fundInvoiceMenuItem = (
        <MenuItem
            id="fundInvoiceMenuItem"
            link={'/fund-invoice'}
            name={t('menu.fundInvoice')}
            remixIcon="ri-file-list-2-line"
        />
    )

    const fundRetrocessionDistributionMenuItem = (
        <MenuItem
            id="fundRetrocessionDistributionMenuItem"
            link={'/fund-retrocession-distribution'}
            name={t('menu.fundRetrocessionDistribution')}
            remixIcon="ri-calculator-line"
        />
    )

    return (
        <Fragment>
            <MenuItem
                id="marketPlaceMenuItem"
                link={'/marketplace/'}
                name={t('menu.marketplace')}
                remixIcon="ri-store-2-line"
            />
            {loggedIn && inboxMenuItem}
            {loggedIn && fundBuyOrderMenuItem}
            {loggedIn && fundSellOrderMenuItem}
            {loggedIn && fundTransactions}
            {loggedIn && fundAdminOverview}
            {loggedIn && fundInvoiceMenuItem}
            {loggedIn && fundRetrocessionDistributionMenuItem}
            {loggedIn && (
                <MenuItem
                    id="customerMenuItem"
                    link={'/fund-customers'}
                    name={t('menu.customers')}
                    remixIcon="ri-group-line"
                >
                    <MenuItem.SubNav to="/fund-customers/followup">{t('menu.followUp')}</MenuItem.SubNav>
                </MenuItem>
            )}
            {!loggedIn && loginMenuItem}
        </Fragment>
    )
}
