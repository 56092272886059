import AsyncButton from '#app/components/Button/AsyncButton'
import RegistrationProgress from '#app/layers/Signup/RegistrationProgress'
import { syncSessionState } from '#app/services/thunks/session'
import { showConfirmationModal } from '#app/state/reducers/confirmationModal'
import { useSelector } from '#app/state/useSelector'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import OnboardingFrame from '#components/OnboardingFrame'
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTracking } from 'react-tracking'

export default function RegisterCompanySE() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { createDepositor } = useCommand()
    const { setProspectiveCompany } = useCommand()
    const session = useSelector((state) => state.session)
    const { trackEvent } = useTracking({ page: 'ChooseOrganization', session })
    const [orgnum, setOrgnum] = useState('')

    function cancel() {
        navigate('/marketplace/')
    }

    async function submit() {
        if (orgnum) {
            const { waitForCommand: waitForData } = await setProspectiveCompany(orgnum, '')
            await waitForData()

            const { waitForCommand: waitForDepositorData } = await createDepositor(orgnum.replace('-', ''), 'SE')
            const success = await waitForDepositorData()
            if (success) {
                trackEvent({ event: 'registerNewOrganization' })
                dispatch(syncSessionState())
                navigate('/marketplace?welcome')
            } else {
                dispatch(
                    showConfirmationModal({
                        title: t('command.error.organisationAlreadyRegistered'),
                        text: t('layers-Signup.registerCompanyAlreadyExistsMessage', {
                            companyName: orgnum,
                        }),
                        buttonText: t('common.ok'),
                    })
                )
                navigate('/marketplace?welcome')
            }
        }
    }

    const registrationProgress = <RegistrationProgress step={2} />
    return (
        <OnboardingFrame
            visibleOverflow={true}
            header={t('layers-Signup.registerCompanyHeading')}
            cancel={'/marketplace'}
            progress={registrationProgress}
        >
            <Box width={'60rem'} maxWidth={'100%'} mt={1} mb={2}>
                <InputLabel sx={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{t('common.organisationNumber')}</span>
                    <Typography variant="caption">{t('layers-Signup.searchForCompanyDescription')}</Typography>
                </InputLabel>
                <TextField value={orgnum} id="orgnum" onChange={(e) => setOrgnum(e.target.value)} />
            </Box>

            <ButtonRow>
                <Button variant={'outlined'} data-cy="signupCancelButton" onClick={cancel}>
                    {t('layers-Signup.registerCompanyPostpone')}
                </Button>
                <AsyncButton data-cy="signupContinueButton" variant="contained" onClick={submit} disabled={!orgnum}>
                    {t('layers-Signup.registerCompanyComplete')}
                </AsyncButton>
            </ButtonRow>
        </OnboardingFrame>
    )
}
