import { SUNSET_ORANGE } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { CurrencyOutput, LoadingSpinner, NumberInput } from '#components'
import { Box, InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PotentialInterestApi from './api/PotentialInterestApi'

async function calculatePotentialEarnings(currentInterest: number, volume: number) {
    const potentialInterest = await PotentialInterestApi.getCalculatedInterest(volume)
    const currentInterestEarnings = (volume * 1e6 * currentInterest) / 100
    return potentialInterest * 12 - currentInterestEarnings
}

function PartnerCalculator({
    showCalculator,
    setShowCalculator,
}: {
    showCalculator: boolean
    setShowCalculator: (show: boolean) => void
}) {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const [yearlyGain, setYearlyGain] = useState<null | number>(null)
    const [potentialVolume, setPotentialVolume] = useState<null | number>(null)
    const [historicInterestRate, setHistoricInterestRate] = useState<null | number>(null)
    const [type, setType] = useState('year')
    const [calculating, setCalculating] = useState(false)
    const timeout = useRef(null)

    function valuePrUnit(unit: string, value: number) {
        if (value > 0) {
            switch (unit) {
                case 'day':
                    return value / 365
                case 'month':
                    return value / 12
                case 'year':
                    return value
            }
        }
        return 0
    }

    useEffect(() => {
        async function calculate() {
            setCalculating(true)
            const potentialEarnings = await calculatePotentialEarnings(historicInterestRate, potentialVolume)
            setYearlyGain(potentialEarnings)
            setCalculating(false)
        }

        setCalculating(true)
        if (timeout.current) {
            clearTimeout(timeout.current)
        }

        timeout.current = setTimeout(() => {
            if (potentialVolume && historicInterestRate !== null) {
                calculate()
            }
        }, 1000)

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
                timeout.current = null
            }
        }
    }, [potentialVolume, historicInterestRate])

    return (
        <FxDialog open={showCalculator} onClose={() => setShowCalculator(false)}>
            <FxDialogTitle onClose={() => setShowCalculator(false)}>
                {t('pages-onboarding.calculateInterestHeader')}
            </FxDialogTitle>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: SUNSET_ORANGE[50] }}>
                <Box maxWidth={'45rem'}>
                    <FixrateIllustration name="manShowingReport" color="default" />
                </Box>
            </Stack>
            <FxDialogContent>
                <p>{t('pages-onboarding.potentialInfo')}</p>
                <Stack spacing={2} mb={4}>
                    <Box>
                        <InputLabel>{t('common.bankDeposits')}</InputLabel>
                        <NumberInput
                            data-cy="potentialVolume"
                            sx={{ width: '16rem' }}
                            value={potentialVolume}
                            formatFn={(val) => CurrencyOutput.formatNoCode(val, 0)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                            }}
                            onChange={(number) => setPotentialVolume(number)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('pages-onboarding.currentInterestLabel')}</InputLabel>
                        <NumberInput
                            data-cy="potentialVolume"
                            sx={{ width: '9rem' }}
                            value={historicInterestRate}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            onChange={(number) => setHistoricInterestRate(number)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>{t('pages-onboarding.interestGain')}</InputLabel>
                        {calculating && potentialVolume !== null && historicInterestRate !== null ? (
                            <LoadingSpinner />
                        ) : (
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    opacity: historicInterestRate !== null && potentialVolume !== null ? 1 : 0.5,
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="interestBig">
                                    <span>
                                        {historicInterestRate !== null &&
                                        potentialVolume !== null &&
                                        yearlyGain !== null
                                            ? Currency(valuePrUnit(type, yearlyGain), { decimals: 0 })
                                            : Currency(0, { decimals: 0 })}
                                    </span>
                                </Typography>
                                <Select sx={{ maxWidth: '11rem' }} value={type}>
                                    <MenuItem value="day" onClick={() => setType('day')}>
                                        {t('pages-DashboardHeader.perDay')}
                                    </MenuItem>
                                    <MenuItem value="month" onClick={() => setType('month')}>
                                        {t('pages-DashboardHeader.perMonth')}
                                    </MenuItem>
                                    <MenuItem value="year" onClick={() => setType('year')}>
                                        {t('pages-DashboardHeader.perYear')}
                                    </MenuItem>
                                </Select>
                            </Stack>
                        )}
                    </Box>
                </Stack>
            </FxDialogContent>
        </FxDialog>
    )
}

export default PartnerCalculator
