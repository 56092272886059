import { useState } from 'react'
import { useSelector } from '#state/useSelector'
import { Box, Button, FormLabel, InputAdornment, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useCommand } from '#command'
import { InterestRateBenchmark } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { NumberInput } from '#components'
import { LoadingButton } from '@mui/lab'
import { useFieldState } from '@fixrate/fieldstate'
import DateInput from '#components/DateInput'
import { format } from 'date-fns'
import { useCurrencyOutput } from '#components/CurrencyOutput/useCurrencyOutput'
import { useConfirmModal } from '#app/layers/ConfirmModal/ConfirmModal'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useDispatch } from 'react-redux'

type Props = {
    depositorId: string
    portfolioId: string
}
export function ReferenceInterestRateSettings({ depositorId, portfolioId }: Props) {
    const { t } = useTranslation()
    const confirmModal = useConfirmModal()
    const dispatch = useDispatch()

    const portfolio = useSelector((state) =>
        state.fundCustomers.find((c) => c.depositorId === depositorId)?.portfolios?.find((p) => p?.id === portfolioId)
    )
    const { removeDepositorPortfolioReferenceInterestRate } = useCommand()
    const Currency = useCurrencyOutput()
    if (!portfolio) {
        return null
    }

    async function onRemove(startDate: string) {
        confirmModal({
            title: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmModal.title'),
            text: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmModal.text'),
            submitButtonText: t('common.remove'),
            cancelButtonText: t('common.cancel'),
            submitAction: async () => {
                await removeReferenceInterestRate(startDate)
            },
        })
    }
    async function removeReferenceInterestRate(startDate: string) {
        const { waitForCommand } = await removeDepositorPortfolioReferenceInterestRate(
            depositorId,
            portfolioId,
            startDate
        )
        const success = await waitForCommand()
        if (success) {
            dispatch(
                showConfirmationModal({
                    title: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmationModalRemove.title'),
                    text: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmationModalRemove.text', {
                        referenceInterestRateDate: format(new Date(startDate), 'dd.MM.yyyy'),
                    }),
                    buttonText: t('common.ok'),
                })
            )
        }
    }

    function formatBenchmark(benchmark: InterestRateBenchmark) {
        if (benchmark) {
            return benchmark.replaceAll('_', ' ')
        }
        return ''
    }

    function formatAddedMargin(addedMargin: number) {
        const end =
            Currency(addedMargin, {
                withCurrency: false,
                decimals: 2,
            }) + '%'
        if (addedMargin < 0) {
            return '-' + end
        } else {
            return '+' + end
        }
    }

    return (
        <Stack spacing={1}>
            <dl>
                <dd>
                    <h4>{t('pages.fundCustomers.customerDetail.referenceInterestRate.history')}</h4>
                    <table style={{ textAlign: 'left', width: '100%' }}>
                        <thead>
                            <tr>
                                <th>{t('pages.fundCustomers.customerDetail.referenceInterestRate.startDate')}</th>
                                <th>{t('pages.fundCustomers.customerDetail.referenceInterestRate.benchmark')}</th>
                                <th>{t('pages.fundCustomers.customerDetail.referenceInterestRate.marginAddition')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={'default'}>
                                <td>
                                    <em>01.01.0001</em>
                                </td>
                                <td>
                                    <em>NIBOR 3M*</em>
                                </td>
                                <td>
                                    <em>{formatAddedMargin(0)}</em>
                                </td>
                                <td></td>
                            </tr>
                            {Object.entries(portfolio.referenceInterestRateHistory).map(([startDate, interestRate]) => (
                                <tr key={startDate}>
                                    <td>{format(new Date(startDate), 'dd.MM.yyyy')}</td>
                                    <td>{formatBenchmark(interestRate.benchmark)}</td>
                                    <td>{formatAddedMargin(interestRate.addedMargin)}</td>
                                    <td>
                                        <Button size={'small'} variant={'outlined'} onClick={() => onRemove(startDate)}>
                                            {t('common.remove')}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </dd>
                <dd>
                    <Typography variant={'body2'}>
                        <em>{t('pages.fundCustomers.customerDetail.referenceInterestRate.standardValueExplain')}</em>
                    </Typography>
                </dd>
            </dl>
            <ChangeReferenceInterestRateDialog depositorId={depositorId} portfolioId={portfolio.id} />
        </Stack>
    )
}

function ChangeReferenceInterestRateDialog({ depositorId, portfolioId }: { depositorId: string; portfolioId: string }) {
    const { t } = useTranslation()
    const { changeDepositorPortfolioReferenceInterestRate } = useCommand()
    const dispatch = useDispatch()
    const [submitting, setSubmitting] = useState(false)
    const portfolio = useSelector((state) =>
        state.fundCustomers.find((c) => c.depositorId === depositorId)?.portfolios.find((p) => p.id === portfolioId)
    )
    const benchmarks: InterestRateBenchmark[] = [
        'NIBOR_3M',
        'STIBOR_3M',
        'SWB_VECKA',
        'SARON_3M',
        'LIBOR_USD_3M',
        'EURIBOR_3M',
        'SOFR',
        'SWESTR_3M',
    ]
    const startDateField = useFieldState<Date | null>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (!value) {
            return t('validation.mustBeValidDate')
        }
    })
    const isDuplicate =
        startDateField.value != null &&
        Object.keys(portfolio.referenceInterestRateHistory).includes(format(startDateField.value, 'yyyy-MM-dd'))
    const addedMarginField = useFieldState<number | null>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (value === null || isNaN(value)) {
            return t('validation.mustBeNumber')
        }
        // if (value < 0) {
        //     return t('validation.mustBePositiveOrZero')
        // }
        return ''
    })
    const benchmarkField = useFieldState<InterestRateBenchmark>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (!value) {
            return t('validation.mustBeSelected')
        }
        return ''
    })

    async function changeReferenceInterestRate() {
        const valid = startDateField.validate() && addedMarginField.validate() && benchmarkField.validate()
        if (!valid || submitting) {
            return
        }
        setSubmitting(true)
        try {
            const { waitForCommand } = await changeDepositorPortfolioReferenceInterestRate(
                depositorId,
                portfolioId,
                format(startDateField.value, 'yyyy-MM-dd'),
                benchmarkField.value,
                addedMarginField.value
            )
            const success = await waitForCommand()
            if (success) {
                dispatch(
                    showConfirmationModal({
                        title: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmationModalAdd.title'),
                        text: t('pages.fundCustomers.customerDetail.referenceInterestRate.confirmationModalAdd.text', {
                            referenceInterestRateDate: format(startDateField.value, 'dd.MM.yyyy'),
                        }),
                        buttonText: t('common.ok'),
                    })
                )
                resetForm()
            }
        } finally {
            setSubmitting(false)
        }
    }

    function resetForm() {
        startDateField.reset()
        addedMarginField.reset()
        benchmarkField.reset()
    }

    return (
        <Box sx={{ backgroundColor: '#f9f7fd', py: 1, px: 2 }}>
            <FormLabel>{t('pages.fundCustomers.customerDetail.referenceInterestRate.newTitle')}</FormLabel>
            <Stack spacing={2} direction={'row'}>
                <Box sx={{ mb: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <label>{t('pages.fundCustomers.customerDetail.referenceInterestRate.startDate')}</label>
                        <DateInput
                            value={startDateField.value}
                            onChange={startDateField.setValue}
                            onBlur={startDateField.onBlur}
                        />
                    </Box>
                    {!startDateField.valid && <p className="field-error-message">{startDateField.errorMessage}</p>}
                </Box>
                <Stack direction={'row'} spacing={2}>
                    <Stack>
                        <label>{t('pages.fundCustomers.customerDetail.referenceInterestRate.benchmark')}</label>
                        <Select
                            value={benchmarkField.value}
                            onChange={(e) => benchmarkField.setValue(e.target.value as InterestRateBenchmark)}
                            error={!benchmarkField.valid}
                            sx={{ maxWidth: '24rem' }}
                            displayEmpty
                        >
                            {benchmarks.map((benchmark) => (
                                <MenuItem key={benchmark} value={benchmark}>
                                    {benchmark}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <Stack>
                        <label>{t('pages.fundCustomers.customerDetail.referenceInterestRate.marginAddition')}</label>
                        <NumberInput
                            value={addedMarginField.value}
                            onChange={addedMarginField.setValue}
                            error={!addedMarginField.valid}
                            placeholder={'0,00'}
                            sx={{ maxWidth: '12rem' }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <LoadingButton
                variant={'outlined'}
                onClick={changeReferenceInterestRate}
                loading={submitting}
                disabled={submitting}
            >
                {isDuplicate
                    ? t('pages.fundCustomers.customerDetail.referenceInterestRate.overwrite')
                    : t('common.add')}
            </LoadingButton>
        </Box>
    )
}
