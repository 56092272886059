import styles from './FundOrderOverview.module.scss'
import { useTranslation } from 'react-i18next'
import FundOrders from './FundOrders'
import { Typography } from '@mui/material'

const FundOrderOverview = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.fundOrderOverview}>
            <Typography mt={0} component={'h3'} variant="h3">
                <i className="ri-time-line" />
                <span>{t('pages-fund-order-overview.ongoingOrders')}</span>
            </Typography>
            <FundOrders
                showTitle={false}
                hideCompleted={true}
                showEmptyMessage={true}
                emptyMessage={t('pages-fund-order-overview.emptyOngoingOrders')}
            />
            <Typography mt={5} component={'h3'} variant="h3">
                <i className="ri-check-line green" />
                <span>{t('pages-fund-order-overview.completedOrders')}</span>
            </Typography>
            <FundOrders
                showTitle={false}
                onlyOrderState={'COMPLETED'}
                showEmptyMessage={true}
                emptyMessage={t('pages-fund-order-overview.emptyCompletedOrders')}
            />
        </div>
    )
}

export default FundOrderOverview
