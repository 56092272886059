import useCurrentDepositor from '#app/services/useCurrentDepositor'
import BackLink from '#components/BackLink'
import { Stack } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import DepositorPersonList from './components/DepositorPersonList/DepositorPersonList'
import TabSelector from './components/TabSelector'
import AdvisorProfile from './views/AdvisorProfile'
import Board from './views/Board'
import OrderProcess from './views/OrderProcess'
import Owners from './views/Owners'
import PowerOfAttorney from './views/PowerOfAttorney/PowerOfAttorney'
import UserProfile from './views/UserProfile'

export default function UsersAndRoles() {
    const depositor = useCurrentDepositor()
    const basePath = `/organizations/${depositor.id}/users/all`
    const location = useLocation()

    const showTabSelector = ['/all', '/roles', '/board', '/owners', '/power-of-attorney'].some((path) =>
        location.pathname.includes(path)
    )

    return (
        <Stack maxWidth={1400}>
            <Stack spacing={3}>
                {showTabSelector && <TabSelector depositor={depositor} />}
                <Routes>
                    <Route path="" element={<Navigate to={'roles'} replace />} />
                    <Route path={'all'} element={<DepositorPersonList depositor={depositor} />} />
                    <Route path={'roles'} element={<OrderProcess depositor={depositor} />} />
                    <Route path={'board'} element={<Board depositor={depositor} />} />
                    <Route path={'owners'} element={<Owners depositor={depositor} />} />
                    <Route path={'power-of-attorney'} element={<PowerOfAttorney depositor={depositor} />} />
                </Routes>
            </Stack>
            <Routes>
                <Route
                    path={'profile/:personId'}
                    element={
                        <Stack>
                            <BackLink fallback={`/organizations/${depositor.id}/users/all`} />
                            <UserProfile depositorId={depositor.id} basePath={basePath} />
                        </Stack>
                    }
                />
                <Route
                    path={'advisor/:partnerId/:userId'}
                    element={
                        <Stack spacing={3}>
                            <BackLink fallback={`/organizations/${depositor.id}/users/all`} />
                            <AdvisorProfile depositor={depositor} />
                        </Stack>
                    }
                />
            </Routes>
        </Stack>
    )
}
