/* eslint-disable react/prop-types */

import { PURPLE, SILVER_GRAY, SUNSET_ORANGE } from '#app/colors/colors'
import { DateOutput, InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { formatAccount } from '#app/services/formatnumber'
import getDepositValue from '#app/services/getDepositValue'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import useTableSort from '#app/services/useTableSort'
import { bankNames } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import {
    Box,
    Chip,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DepositTableRowMenu } from './DepositTableRowMenu'
import { depositWarnings } from './ProcessInformation'
import TableRowWarning from './TableRowWarning'

type depositRowsType = {
    label: string
    id:
        | 'bankId'
        | 'effectiveInterestRate'
        | 'nominalInterestRate'
        | 'product'
        | 'volume'
        | 'expires'
        | 'tagText'
        | 'terminationDate'
        | 'interestRateConvention'
    body: (deposit: DepositDto) => JSX.Element | string
    visible: boolean
}

export default function DepositTable({
    deposits,
    productType,
}: {
    deposits: DepositDto[]
    productType: 'FIXED' | 'NIBOR' | 'USDLIBOR3M'
}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const nameOfBanks = useSelector(bankNames)
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()
    const terminatedDeposits = deposits?.find((deposit) => deposit.terminationState === 'SENT_TO_BANK')
    const terminationSentToBank = terminatedDeposits !== undefined
    const interestChanges = useSelector((state) => state.interestRateChange)

    const floatingInterestFormatted = (deposit: DepositDto) => {
        return InterestOutput.formatMarginWithBenchmarkObj(
            deposit.nominalInterestRate,
            deposit.interestRateBenchmark,
            t
        )
    }

    const hasFloatingDepositsTerminated =
        (productType === 'NIBOR' || productType === 'USDLIBOR3M') &&
        deposits.find((d) => d.terminationDate) !== undefined

    const hasSameInterestConvention =
        (productType === 'NIBOR' || productType === 'USDLIBOR3M') &&
        deposits.every((deposit) => deposit.interestRateConvention === deposits[0].interestRateConvention)

    const showInterestConvention =
        !hasSameInterestConvention && (productType === 'NIBOR' || productType === 'USDLIBOR3M')

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<DepositDto>(
        deposits,
        hasFloatingDepositsTerminated ? 'terminationDate' : 'effectiveInterestRate',
        'desc'
    )

    const depositGetsLowerInterestRate = (deposit: DepositDto): boolean => {
        const interestChange = interestChanges?.find((irc) => irc.depositId === deposit.id)

        if (!deposit.newInterestRateChangeDate || !interestChange) {
            return false
        }

        if (interestChange?.acceptedByDepositor) {
            return false
        }

        if (new Date(interestChange?.terminationDeadline) < new Date()) {
            return false
        }

        if (interestChange?.newInterest < interestChange?.oldInterest) {
            return true
        }

        return false
    }

    const depositRows: depositRowsType[] = [
        {
            label: t('pages-portfolio-depositor.headersBankName'),
            id: 'bankId',
            visible: true,
            body: (deposit: DepositDto) => (
                <TableCell
                    onClick={() => navigate('/portfolio/id/' + deposit.id)}
                    sx={{
                        width: { md: '25rem' },
                        pl: '1rem',
                        borderLeftWidth: '0.4rem',
                        borderLeftStyle: 'solid',
                        borderLeftColor: depositWarnings(deposit).hasWarnings ? SUNSET_ORANGE[600] : 'transparent',
                    }}
                >
                    <Typography sx={{ fontWeight: '600', fontSize: '1.4rem' }} data-cy="bankNameItem">
                        {nameOfBanks[deposit.bankId]}
                    </Typography>
                    <Typography
                        sx={{ fontWeight: '500', fontSize: '1.4rem', color: SILVER_GRAY[500] }}
                        data-cy="bankAccountNumber"
                    >
                        {formatAccount(deposit.account, organisationCountry)}
                    </Typography>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersTag'),
            id: 'tagText',
            visible: deposits.find((d) => d.tagText) !== undefined,
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} sx={{ width: { md: '10rem' } }}>
                    {deposit.tagText && (
                        <Chip label={deposit.tagText} sx={{ backgroundColor: deposit.tagColor, color: 'white' }} />
                    )}
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersInterest'),
            id: 'effectiveInterestRate',
            visible: true,
            body: (deposit: DepositDto) => (
                <TableCell
                    onClick={() => navigate('/portfolio/id/' + deposit.id)}
                    data-cy="effective-interest-rate"
                    sx={{ fontSize: '1.6rem', fontWeight: '700', color: PURPLE[500] }}
                >
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography sx={{ fontSize: '1.8rem', fontWeight: '700', color: PURPLE[500] }}>
                            {InterestOutput.format(deposit.effectiveInterestRate)}
                        </Typography>
                        {depositGetsLowerInterestRate(deposit) && deposit.terminationState !== 'SENT_TO_BANK' && (
                            <Chip
                                size="small"
                                sx={{ mt: 0.5 }}
                                color="warning"
                                label={t('pages-portfolio-depositor.interestChangeOngoing')}
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersMargin'),
            id: 'nominalInterestRate',
            visible: productType === 'NIBOR' || productType === 'USDLIBOR3M',
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} data-cy="margin-addition">
                    <Box
                        sx={{
                            backgroundColor: PURPLE[50],
                            color: PURPLE[500],
                            py: 0.6,
                            px: 1,
                            display: 'inline-flex',
                        }}
                    >
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <Typography
                                fontSize={'1.2rem'}
                                whiteSpace="nowrap"
                                display={{ xs: 'none', lg: 'inline-flex' }}
                                fontWeight={'600'}
                            >
                                {floatingInterestFormatted(deposit)?.label}
                            </Typography>
                            <Divider
                                sx={{
                                    borderColor: PURPLE[100],
                                    height: '1.8rem',
                                    display: { xs: 'none', lg: 'inline-flex' },
                                }}
                                orientation="vertical"
                            />
                            <Typography fontSize={'1.4rem'} fontWeight={'700'} whiteSpace="nowrap">
                                {floatingInterestFormatted(deposit)?.interest}
                            </Typography>
                        </Stack>
                    </Box>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.interestRateConvention'),
            id: 'interestRateConvention',
            visible: showInterestConvention,
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} data-cy="interest-rate-convention">
                    <Typography>{t(`common.interestRateConvention.${deposit.interestRateConvention}`)}</Typography>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersNoticePeriod'),
            id: 'product',
            visible: productType === 'NIBOR' || productType === 'USDLIBOR3M',
            body: (deposit: DepositDto) => (
                <TableCell
                    onClick={() => navigate('/portfolio/id/' + deposit.id)}
                    data-cy="termination-period"
                    sx={{ width: { md: '20rem' } }}
                >
                    <Typography variant="body2">{t(`common.productLongName${deposit.product.id}`)}</Typography>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersTerminationDate'),
            id: 'expires',
            visible: productType === 'FIXED',
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} sx={{ width: { md: '20rem' } }}>
                    <DateOutput.Date date={deposit.expires.date} />
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersDuration'),
            id: 'product',
            visible: productType === 'FIXED',
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} sx={{ width: { md: '20rem' } }}>
                    <Typography>{t(`common.productLongName${deposit.product.id}`)}</Typography>
                </TableCell>
            ),
        },
        {
            label: t('pages-portfolio-depositor.headersVolume'),
            id: 'volume', // Volume is useed as key because of the sorting function. Calculated balance is null on new deposits.
            visible: true,
            body: (deposit: DepositDto) => (
                <TableCell
                    onClick={() => navigate('/portfolio/id/' + deposit.id)}
                    data-cy="volume"
                    sx={{ width: { md: '20rem' } }}
                >
                    <Typography fontWeight={'700'}>{Currency(getDepositValue(deposit), { decimals: 0 })}</Typography>
                </TableCell>
            ),
        },
        {
            label: deposits.find((d) => d.terminationDate) ? t('pages-portfolio-depositor.paymentDate') : '',
            id: 'terminationDate',
            visible:
                hasFloatingDepositsTerminated ||
                terminationSentToBank ||
                deposits.find((d) => d.terminationDate) !== undefined,
            body: (deposit: DepositDto) => (
                <TableCell onClick={() => navigate('/portfolio/id/' + deposit.id)} data-cy="termination-info">
                    {(deposit.terminationDate ||
                        (!deposit.terminationDate && deposit.terminationState === 'SENT_TO_BANK')) && (
                        <Stack spacing={1}>
                            {deposit.product.type !== 'FIXED' && (
                                <Chip
                                    variant="outlined"
                                    color="warning"
                                    label={t('pages-portfolio-depositor.depositTerminated')}
                                />
                            )}
                            <Typography sx={{ fontSize: '1.2rem', fontWeight: '500', color: SILVER_GRAY[500] }}>
                                {t('pages-portfolio-depositor.paymentDate')}:{' '}
                                {deposit.terminationDate ? (
                                    <DateOutput.Date date={deposit.terminationDate} />
                                ) : (
                                    t('pages-portfolio-depositor.notSet')
                                )}
                            </Typography>
                        </Stack>
                    )}
                </TableCell>
            ),
        },
    ]

    return deposits.length > 0 ? (
        <Fragment>
            <Table sx={{ borderSpacing: '0 3rem', borderCollapse: 'separate' }}>
                <TableHead>
                    <TableRow
                        sx={{
                            '& .MuiTableCell-root:last-of-type': { paddingRight: '2rem' },
                            '& .MuiTableCell-root:first-of-type': { paddingLeft: '2rem' },
                        }}
                    >
                        {depositRows
                            .filter((r) => r.visible)
                            .map((row) => (
                                <TableCell key={row.id} sx={{ pl: row.id === 'bankId' ? '1rem' : 0 }}>
                                    <TableSortLabel
                                        active={sortKey === row.id}
                                        direction={sortKey === row.id && sortDirection ? sortDirection : 'asc'}
                                        onClick={() =>
                                            depositRows.find((r) => r.id === row.id) ? setSorting(row?.id) : null
                                        }
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        <TableCell data-cy="termination-info-header"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map((deposit) => (
                        <Fragment key={deposit.id}>
                            <TableRow
                                sx={{
                                    boxShadow: '0.1rem 0.1rem 1rem rgba(0,0,0,0.1)',
                                    borderRadius: '0.6rem',
                                    overflow: 'hidden',
                                    '& .MuiTableCell-root:last-of-type': { paddingRight: '2rem' },
                                    '& .MuiTableCell-root:first-of-type': { paddingLeft: '2rem' },
                                    '&.MuiTableRow-hover:hover': { backgroundColor: 'rgba(0,0,0,0.02)' },
                                }}
                                data-cy="portfolioListItem"
                                hover
                            >
                                {depositRows
                                    .filter((r) => r.visible)
                                    .map((row) => (
                                        <Fragment key={row.id}>{row.body(deposit)}</Fragment>
                                    ))}
                                <TableCell>
                                    <DepositTableRowMenu depositId={deposit.id} />
                                </TableCell>
                            </TableRow>
                            <TableRowWarning deposit={deposit} />
                        </Fragment>
                    ))}
                </TableBody>
            </Table>
        </Fragment>
    ) : (
        <Typography color={SILVER_GRAY[500]}>{t('pages-portfolio-depositor.noFloatingInterestDeposits')}</Typography>
    )
}
