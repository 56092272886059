import FundTransactions from '#pages/portfolio-depositor/FundTransactions/FundTransactions'
import AccountingReportPage from '#pages/portfolio-depositor/Reports/FundReports/AccountingReport/AccountingReportPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import AnnualStatements from './BankDepositReports/AnnualStatements/AnnualStatements'
import BankDepositReports from './BankDepositReports/BankDepositReports'
import CollectedStatement from './BankDepositReports/CollectedStatement/CollectedStatement'
import MonthlyReports from './BankDepositReports/MonthlyReports/MonthlyReports'
import FundReports from './FundReports/FundReportOverview/FundReportOverview'
import InventoryReportPage from './FundReports/InventoryReport/InventoryReportPage'

export default function DepositorReports() {
    return (
        <Routes>
            <Route path="" element={<Navigate to={{ pathname: '/reports/deposits/interest-balance' }} />} />
            <Route path="deposits" element={<BankDepositReports />}>
                <Route path={'interest-balance'} element={<MonthlyReports />} />
                <Route path={'collected-statement'} element={<CollectedStatement />} />
                <Route path={'annual-statements'} element={<AnnualStatements />} />
            </Route>
            <Route path="funds" element={<FundReports />} />
            <Route path="funds/fund-transactions" element={<FundTransactions />} />
            <Route path="funds/accounting/:period" element={<AccountingReportPage />} />
            <Route path="funds/inventory/:period" element={<InventoryReportPage />} />
        </Routes>
    )
}
