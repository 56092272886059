import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getLastFullySubscribedAds } from '#services/thunks/statistics'
import { useSelector } from '#state/useSelector'
import isAfter from 'date-fns/isAfter'
import { Paper, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AdCard from '#app/pages/marketplace/AdCard/AdCard'
import { InterestRateBenchmark } from '@fixrate/fixrate-query'

export interface FullySubscribedAd {
    bankId: string
    created: string
    currentVolume: number
    depositorId?: string
    id: string
    interest: number
    max: number
    min: number
    orders: string[]
    productId: string
    validity: string
    volume: number
    fullySubscribed: string
    nominalInterestRate: number
    interestRateBenchmark: InterestRateBenchmark
    published: string
}

export const FullySubscribedAds = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch<(arg0: (any) => void) => Promise<FullySubscribedAd[]>>()
    const products = useSelector((state) => state.products)
    const banks = useSelector((state) => state.banks)
    const benchmarkInterestRates = useSelector((state) => state.interestRateBenchmarks.benchmarkInterestRates)

    const [ads, setAds] = useState<FullySubscribedAd[]>([])
    const [error, setError] = useState(false)

    useEffect(() => {
        dispatch(getLastFullySubscribedAds())
            .then((ads) => {
                const sorted = ads
                    .slice()
                    .sort((ad1, ad2) => (isAfter(new Date(ad2.validity), new Date(ad1.validity)) ? 1 : -1))
                setAds(sorted)
            })
            .catch(() => setError(true))
    }, [dispatch])

    const adsToPresent = ads.slice(0, 6)

    return (
        <Stack width={'100%'}>
            <Stack
                direction="row"
                spacing={2}
                flexWrap="wrap"
                justifyContent={'flex-start'}
                sx={{ lg: { overflow: 'auto' } }}
            >
                {adsToPresent.map((ad, index) => {
                    const product = products[ad.productId]
                    const bank = banks[ad.bankId]

                    const totalInterestRate =
                        product?.type === 'FIXED'
                            ? ad.interest
                            : ad.interest + benchmarkInterestRates[ad.interestRateBenchmark]

                    const adWithAllProps = {
                        ...ad,
                        openOrders: [],
                        remaining: 0,
                        bank: bank,
                        nominalInterestRate: ad.interest,
                        interest: totalInterestRate,
                        validity: ad.validity,
                        adTag: null,
                        depositorId: ad.depositorId,
                    }
                    return (
                        <Paper elevation={3} key={index + ad.productId}>
                            <AdCard preview ad={adWithAllProps} product={product} onAdClick={() => null} />
                        </Paper>
                    )
                })}
            </Stack>
            {error && (
                <p>
                    <i className="ri-error-warning-line" /> {t('pages-BankDashboard.loadingFailed')}
                </p>
            )}
        </Stack>
    )
}
