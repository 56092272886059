import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSearchParams from '#services/useSearchParams'
import { useSelector } from '#state/useSelector'
import isValidUuid from '#services/isValidUuid'
import { PageHeader, PageLayout, Paper } from '#components'
import styles from './AdminFundPlacements.module.scss'
import AdminFundPlacementTable from '#pages/FundAdminOverview/FundPlacements/AdminFundPlacementTable'
import AsyncButton from '#components/Button/AsyncButton'
import Pagination from '#components/Pagination/Pagination'
import { Checkbox, FormControlLabel } from '@mui/material'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'

const ITEMS_PER_PAGE = 20

type Params = {
    depositorId?: string
    fundId?: string
    fundManagerOrganisationNumber?: string
    displayInactive?: boolean
}

export default function AdminFundPlacements() {
    const { t } = useTranslation()
    const { depositorId, fundId, fundManagerOrganisationNumber, displayInactive } = useSearchParams<Params>()

    const [page, setPage] = useState(0)
    const [depositorFilter, setDepositorFilter] = useState('')
    const [fundFilter, setFundFilter] = useState('')
    const [fundManagerFilter, setFundManagerFilter] = useState('')
    const [displayInactiveFilter, setDisplayInactiveFilter] = useState(false)

    const fundPlacements = useSelector((state) => state.fundPlacements)
    const funds = useSelector((state) => state.funds)
    const depositorNames = useSelector((state) =>
        Object.assign(
            {},
            ...Object.values(state.fundPlacements).map((fp) => ({
                [fp.depositorId]: state.depositorNames[fp.depositorId],
            }))
        )
    )
    const fundManagerOrganisations = useSelector((state) =>
        state.funds
            .map((fund) => {
                return {
                    organisationNumber: fund.managerOrganisationNationalIdentity ?? '',
                    name: fund.managerOrganisationName ?? '',
                }
            })
            .filter(
                (v, i, a) =>
                    v.organisationNumber !== '' &&
                    a.findIndex((t) => t.organisationNumber === v.organisationNumber) === i
            )
    )

    useEffect(() => {
        setDepositorFilter(isValidUuid(depositorId) ? depositorId : '')
        setFundFilter(isValidUuid(fundId) ? fundId : '')
        setFundManagerFilter(fundManagerOrganisationNumber ?? '')
        setDisplayInactiveFilter(displayInactive ?? false)
    }, [depositorId, fundId, fundManagerOrganisationNumber, displayInactive])

    useEffect(() => {
        setPage(0)
    }, [depositorFilter, fundFilter, fundManagerFilter])

    const filteredFundPlacements = fundPlacements
        .filter((fp) => !depositorFilter || fp.depositorId === depositorFilter)
        .filter((fp) => !fundFilter || fp.fundId === fundFilter)
        .filter(
            (fp) =>
                !fundManagerFilter ||
                funds.find((f) => f.id === fp.fundId)?.managerOrganisationNationalIdentity === fundManagerFilter
        )
        .filter((fp) => displayInactiveFilter || fp.unitQuantity !== 0)

    const placementRows = filteredFundPlacements.map((fp) => {
        const fund = funds.find((f) => f.id === fp.fundId)
        const fundShareClasses = fund?.fundShareClasses
        const shareClassName = fundShareClasses?.find((sc) => sc.id === fp?.fundShareClassId)?.fullName
        return {
            ...fp,
            depositorName: depositorNames[fp.depositorId],
            fundShareClasses: fundShareClasses,
            shareClassName: `${shareClassName}`,
            fund: fund,
        }
    })

    const visiblePlacementRows = placementRows.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)

    const currentValueSum = placementRows.reduce((acc, row) => acc + row.currentValue, 0)
    const currencyOptions = {
        millionFormat: false,
        maximumDecimals: 2,
        minimumDecimals: 2,
        withCurrency: true,
    }

    return (
        <Fragment>
            <PageHeader title={t('pages.adminFundPlacements.title')} />
            <PageLayout>
                <Paper title={t('pages.adminFundPlacements.title')} sx={{ width: '100%' }}>
                    <div className={styles.headerContainer}>
                        <span className={styles.totalValue}>
                            {t('pages.adminFundPlacements.totalValue')}
                            {': '}
                            {CurrencyOutputObject(currentValueSum, currencyOptions)}
                        </span>
                    </div>
                    <div className={styles.toolbar}>
                        <div className={styles.toolbarGroup}>
                            <p className={styles.toolbarLabel}>
                                <i className="ri-filter-line" />
                                <span>{t('pages.adminFundPlacements.filter')}</span>
                            </p>
                            <div className={styles.toolbarFilter}>
                                <select
                                    className={styles.toolbarSelect}
                                    onChange={(e) => setFundFilter(e.target.value)}
                                    value={fundFilter}
                                >
                                    <option value="">{t('pages.adminFundPlacements.allFunds')}</option>
                                    {funds
                                        .map((fund) => {
                                            return { id: fund.id, name: fund.name }
                                        })
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((f) => (
                                            <option key={f.id} value={f.id}>
                                                {f.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className={styles.toolbarFilter}>
                                <select
                                    className={styles.toolbarSelect}
                                    onChange={(e) => setDepositorFilter(e.target.value)}
                                    value={depositorFilter}
                                >
                                    <option value="">{t('pages.adminFundPlacements.allDepositors')}</option>
                                    {Object.keys(depositorNames)
                                        .sort((a, b) =>
                                            (depositorNames[a] || '').localeCompare(depositorNames[b] || '')
                                        )
                                        .map((id) => (
                                            <option key={id} value={id}>
                                                {depositorNames[id]}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className={styles.toolbarFilter}>
                                <select
                                    className={styles.toolbarSelect}
                                    onChange={(e) => setFundManagerFilter(e.target.value)}
                                    value={fundManagerFilter}
                                >
                                    <option value="">{t('pages.adminFundPlacements.allFundManagers')}</option>
                                    {fundManagerOrganisations
                                        .slice()
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((fm) => (
                                            <option
                                                key={fm.organisationNumber}
                                                value={fm.organisationNumber}
                                            >{`${fm.name} (${fm.organisationNumber})`}</option>
                                        ))}
                                </select>
                            </div>
                            <div className={styles.toolbarFilter}>
                                <FormControlLabel
                                    style={{ width: '100%' }}
                                    control={
                                        <Checkbox
                                            checked={displayInactiveFilter}
                                            onChange={(e) => setDisplayInactiveFilter(e.target.checked)}
                                        />
                                    }
                                    label={'Vis inaktive plasseringer'}
                                />
                            </div>
                            {(fundFilter || depositorFilter || fundManagerFilter) && (
                                <div className={styles.toolbarFilter}>
                                    <AsyncButton
                                        size={'small'}
                                        onClick={() => {
                                            setDepositorFilter('')
                                            setFundFilter('')
                                            setFundManagerFilter('')
                                        }}
                                    >
                                        <i className="ri-restart-line" style={{ marginRight: '0.4rem' }} />
                                        <span>{t('pages.adminFundPlacements.resetFilter')}</span>
                                    </AsyncButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <AdminFundPlacementTable placementRows={visiblePlacementRows} />
                    {visiblePlacementRows.length === 0 && (
                        <p className={styles.emptyMessage}>
                            {!!depositorFilter || !!fundFilter || !!fundManagerFilter
                                ? t('pages.adminFundPlacements.noFundPlacementsMatchingFilter')
                                : t('pages.adminFundPlacements.noFundPlacements')}
                        </p>
                    )}
                    {placementRows.length > ITEMS_PER_PAGE && (
                        <div style={{ marginTop: '2rem' }}>
                            <Pagination
                                totalItems={placementRows.length}
                                itemsPerPage={ITEMS_PER_PAGE}
                                selectedPage={page}
                                setPage={setPage}
                            />
                        </div>
                    )}
                </Paper>
            </PageLayout>
        </Fragment>
    )
}
