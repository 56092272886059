import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import config from '#app/config'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { CurrencyOutput, DateOutput, InterestOutput } from '#components'
import { messageDataSelector } from '#pages/Inbox/messageDataSelector'
import { securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { formatAccount, formatIban } from '#services/formatnumber'
import { useSelector } from '#state/useSelector'
import { AdDto, DepositDto, MessageDto, OrganisationType, RegulatoryRegion } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export interface CallToActionLabels {
    label: string
    link: string | (() => void)
}

export interface CheckboxTaskLabels {
    pledge: string
    buttonLabel: string
}

export interface MessageData {
    title: string
    description: string
    depositorName?: string
    actionRequiredByUser: boolean
    taskDescription: string
    cta: CallToActionLabels | null
    checkboxTaskLabels: CheckboxTaskLabels | null
    taskTarget: string
    taskResolvedByUserName: string
}

const interestTerms = (ad, t) => {
    return `${InterestOutput.format(ad?.interest)}${ad?.interestRateBenchmark ? ' (' + t('common.interestRateBenchmark.' + ad?.interestRateBenchmark) + ' + ' + InterestOutput.format(ad?.nominalInterestRate) + ')' : ''}`
}

const settlementAccountDescription = (deposit: DepositDto, country: RegulatoryRegion) =>
    `${deposit?.settlementAccountName} (${formatAccount(deposit?.settlementAccount, country) || formatIban(deposit?.settlementAccountIban)})`

const settlementAccountDescriptionNoName = (deposit: DepositDto, country: RegulatoryRegion) =>
    `${formatAccount(deposit?.settlementAccount, country) || formatIban(deposit?.settlementAccountIban)}`

export default function useMessageData(message: MessageDto): MessageData {
    const { t } = useTranslation()
    const { messageType, messageParameters, task } = message
    const {
        ad,
        product,
        order,
        deposit,
        depositor,
        depositorId,
        depositorName,
        bankName,
        organisationName,
        settlementAccount,
        interestRateChange,
        actionRequiredByUser,
        targetUserName,
        taskResolvedByUserName,
        userFullName,
        actingUserFullName,
        partnerName,
        partnerDepositorName,
        depositorOffer,
        month,
        year,
        document,
        fundBuyOrder,
        fundSellOrder,
        fundShareClass,
        orderGroupId,
        proposal,
        hasActiveDeposits,
    } = useSelector((state) => messageDataSelector(state)(messageParameters, messageType, task))

    const targetRoleName = task.targetRole && t(securityRoleTMap[task.targetRole])
    const targetRoleText =
        targetRoleName && t('pages-inbox.roleInCompany', { role: targetRoleName, company: organisationName })
    const partners = depositor?.partnerRelations ?? []
    const partnerNames = useSelector((state) => state.partnerNames)
    const benchmarkInterestRate = useSelector((state) => state.interestRateBenchmarks.defaultBenchmarkInterestRate)
    const Currency = useCurrencyOutput()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const organisationCountry = useCurrentCountryCode()

    let title = '--- ' + messageType
    let description = '---'
    let taskDescription = ''
    let cta: CallToActionLabels | null = null
    const taskTarget = targetUserName || targetRoleText || ''
    let checkboxTaskLabels: CheckboxTaskLabels | null = null

    const partnerIsAccountant = (partnerName: string) => {
        const partnerId = Object.keys(partnerNames).find((key) => partnerNames[key] === partnerName)
        const partner = partners.find((p) => p.partnerId === partnerId)

        return partner?.partnerCategory === 'ACCOUNTANT'
    }

    function goToCustomerAd(organisationType: OrganisationType, orgId: string, ad: AdDto) {
        const adUrl = `/marketplace/category/deposits/${ad.min >= 20 ? 'over-20' : 'under-20'}/${ad.id}`
        dispatch(switchOrganisation(organisationType, orgId, () => navigate(adUrl)))
    }

    function goToCustomerDeposit(organisationType: OrganisationType, orgId: string, depositId: string) {
        const depositUrl = `/portfolio/id/${depositId}`
        dispatch(switchOrganisation(organisationType, orgId, () => navigate(depositUrl)))
    }

    switch (messageType) {
        case 'DEPOSITOR__NEW_ORDER': {
            title = t('pages-inbox.depositorNewOrderTitle', { count: order?.volume, bankName })
            description = t('pages-inbox.depositorNewOrderDescription', {
                actingUserFullName,
                depositorName,
                count: order?.volume,
                currency: order?.currency,
                interest: interestTerms(ad, t),
                bankName,
            })
            break
        }
        case 'DEPOSITOR__ACCOUNT_AGREEMENT_RECEIVED': {
            title = t('pages-inbox.depositorAccountAgreementReceivedTitle', { bankName })
            description = t('pages-inbox.depositorAccountAgreementReceivedDescription', {
                bankName,
                depositorName,
                count: order?.volume,
                currency: order?.currency,
            })
            taskDescription = t('pages-inbox.depositorAccountAgreementReceivedTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToSigning'),
                    link: '/orders/signing',
                }
            }
            break
        }

        case 'BANK__NEW_AD_PUBLISHED': {
            title = t('pages-inbox.bankNewAdPublishedTitle')
            const isSpecialOffer = !!ad?.depositorName

            let termsDescription = ''
            switch (product?.termsType) {
                case 'NOTICE':
                    termsDescription = t('pages-inbox.bankNewAdTermsNoticePeriod', { days: product?.days })
                    break
                case 'FIXED_TERMS':
                    termsDescription = `
                        ${product?.months !== -1 ? t('pages-inbox.bankNewAdTermsDuration', { months: product?.months }) : ''}
                        ${ad?.termination ? t('pages-inbox.bankNewAdTermsTerminationDate', { date: DateOutput.formatDate(ad.termination) }) : ''}
                    `
                    break
            }

            let interestDescription = ''
            switch (product?.type) {
                case 'FIXED':
                    interestDescription = t('pages-inbox.bankNewAdFixedInterestTerms', {
                        interest: InterestOutput.format(ad?.interest),
                    })
                    break
                case 'FLOATING':
                    interestDescription = t('pages-inbox.bankNewAdFloatingInterestTerms', {
                        interest: InterestOutput.format(ad?.interest),
                        nominalInterestRate: InterestOutput.format(ad?.nominalInterestRate),
                        benchmark: t('common.interestRateBenchmark.' + ad?.interestRateBenchmark),
                    })
                    break
            }

            description = `
                ${isSpecialOffer ? t('pages-inbox.bankNewAdSpecialOfferPublished') : t('pages-inbox.bankNewAdPublished', { user: actingUserFullName })}
                ${termsDescription}
                ${interestDescription}
                ${
                    ad?.volume
                        ? t('pages-inbox.bankNewAdTotalVolume', {
                              count: ad?.volume,
                              currency: ad?.currency,
                          })
                        : ''
                }
                ${ad?.min ? t('pages-inbox.bankNewAdMinVolume', { count: ad?.min, currency: ad?.currency }) : ''}
                ${ad?.max ? t('pages-inbox.bankNewAdMaxVolume', { count: ad?.max, currency: ad?.currency }) : ''}
                ${ad?.depositorName ? t('pages-inbox.bankNewAdSpecialOffer', { depositorName: ad?.depositorName }) : ''}
            `
            break
        }
        case 'BANK__NEW_ORDER': {
            title = t('pages-inbox.bankNewOrderTitle', { depositorName, count: order?.volume })
            description = `
                ${t('pages-inbox.bankNewOrder', { depositorName })}
                ${t('pages-inbox.bankNewOrderAmount', { count: order?.volume, currency: order?.currency })}
                ${t('pages-inbox.bankNewOrderProductName', { productName: product?.name })}
                ${t('pages-inbox.bankNewOrderInterestTerms', { terms: interestTerms(ad, t) })}
                ${t('pages-inbox.bankNewOrderAccountHolder', { user: order?.accountCreator?.name })}
            `
            taskDescription = t('pages-inbox.bankNewOrderTaskDescription')
            cta = {
                label: t('pages-inbox.goToOrder'),
                link: `/bank-orders/${order?.id}`,
            }

            if (actionRequiredByUser) {
                cta.label = t('pages-inbox.bankNewOrderStartProcessing')
            }
            break
        }
        case 'BANK__SIGNED_ACCOUNT_AGREEMENT_RECEIVED': {
            title = t('pages-inbox.bankSignedAccountAgreementReceivedTitle', { depositorName })
            description = t('pages-inbox.bankSignedAccountAgreementReceived', {
                depositorName,
                account: order?.toAccount,
            })
            taskDescription = t('pages-inbox.bankSignedAccountAgreementReceivedTaskDescription')
            cta = {
                label: t('pages-inbox.goToOrder'),
                link: `/bank-orders/${order?.id}`,
            }

            if (actionRequiredByUser) {
                cta.label = t('pages-inbox.bankSignedAccountAgreementReceivedConfirmActivation')
            }
            break
        }
        case 'DEPOSITOR__ORDER_CANCELED': {
            title = t('pages-inbox.depositorOrderCanceledTitle', { bankName })
            description = t('pages-inbox.depositorOrderCanceledDescription', {
                depositorName,
                count: order?.volume,
                currency: order?.currency,
                bankName,
                reason: order?.reasonForCancelling,
            })
            taskDescription = t('pages-inbox.depositorOrderCanceledTaskDescription')
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages-inbox.depositorOrderCanceledCheckboxPledge'),
                    buttonLabel: t('pages-inbox.depositorOrderCanceledCheckboxButton'),
                }
            } else {
                cta = {
                    label: t('pages-inbox.viewOrder'),
                    link: '/orders/canceled',
                }
            }
            break
        }
        case 'BANK__ORDER_CANCELED': {
            title = t('pages-inbox.bankOrderCanceledTitle', { depositorName })
            description = t('pages-inbox.bankOrderCanceledDescription', {
                actingUserFullName,
                count: order?.volume,
                currency: order?.currency,
            })
            break
        }
        case 'BANK__ORDER_COMPLETED': {
            title = t('pages-inbox.bankOrderCompletedTitle', { depositorName })
            description = t('pages-inbox.bankOrderCompletedDescription', {
                actingUserFullName,
                count: order ? order.actualVolume / 1_000_000 : 0,
                currency: order?.currency,
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${order?.depositId}`,
            }
            break
        }
        case 'DEPOSITOR__ACCOUNT_ACTIVATED': {
            title = t('pages-inbox.depositorAccountActivatedTitle', { bankName })
            description = t('pages-inbox.depositorAccountActivatedDescription', {
                bankName,
                depositorName,
                count: order?.volume,
                currency: order?.currency,
            })
            taskDescription = t('pages-inbox.depositorAccountActivatedTaskDescription')
            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToOrder'),
                    link: '/orders/transaction',
                }
            }
            break
        }
        case 'DEPOSITOR__ORDER_COMPLETED': {
            title = t('pages-inbox.depositorOrderCompletedTitle', { bankName })
            description = t('pages-inbox.depositorOrderCompletedDescription', {
                bankName,
                depositorName,
                count: order ? order.actualVolume / 1_000_000 : 0,
                currency: order?.currency,
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${order?.depositId}`,
            }
            break
        }
        case 'BANK__ORDER_PAYMENT_CONFIRMED_BY_DEPOSITOR': {
            title = t('pages-inbox.bankOrderPaymentConfirmedTitle', { depositorName })
            description = t('pages-inbox.bankOrderPaymentConfirmedDescription', {
                depositorName,
                count: order?.volume,
                currency: order?.currency,
                account: order?.toAccount,
            })
            cta = {
                label: actionRequiredByUser ? t('pages-inbox.goToConfirmation') : t('pages-inbox.viewOrder'),
                link: `/bank-orders/${order?.id}`,
            }
            taskDescription = t('pages-inbox.bankOrderPaymentConfirmedTaskDescription')
            break
        }
        case 'BANK__ORDER_PAYMENT_NOT_CONFIRMED_BY_BANK': {
            title = t('pages-inbox.bankOrderPaymentNotConfirmedByBankTitle')
            description = t('pages-inbox.bankOrderPaymentNotConfirmedByBankDescription', { depositorName })
            cta = {
                label: actionRequiredByUser ? t('pages-inbox.goToConfirmation') : t('pages-inbox.viewOrder'),
                link: `/bank-orders/${order?.id}`,
            }
            taskDescription = t('pages-inbox.bankOrderPaymentNotConfirmedByBankTaskDescription')
            break
        }
        case 'DEPOSITOR__DEPOSIT_TERMINATION_MUST_BE_SIGNED': {
            title = t('pages-inbox.depositorTerminationMustBeSignedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorTerminationMustBeSignedDescription', {
                    bankName,
                    actingUserFullName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            taskDescription = t('pages-inbox.depositorSignTerminationTaskDescription')
            break
        }
        case 'DEPOSITOR__DEPOSIT_TERMINATED': {
            title = t('pages-inbox.depositorDepositTerminatedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorDepositTerminatedDescription', {
                    bankName,
                    actingUserFullName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
                ${
                    deposit?.terminationDate
                        ? t('pages-inbox.depositorDepositTerminatedDescriptionLine2', {
                              date: DateOutput.formatDate(deposit.terminationDate),
                              account: settlementAccountDescription(deposit, organisationCountry),
                          })
                        : ''
                }
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_TERMINATED': {
            title = t('pages-inbox.bankDepositTerminatedTitle', { depositorName })
            description = `
                ${t('pages-inbox.bankDepositTerminatedDescription', { depositorName, account: formatAccount(deposit?.account, organisationCountry) })}
                ${
                    deposit?.terminationDate
                        ? t('pages-inbox.bankDepositTerminatedDescriptionLine2', {
                              date: DateOutput.formatDate(deposit.terminationDate),
                              account: settlementAccountDescriptionNoName(deposit, organisationCountry),
                          })
                        : ''
                }
            `
            cta = {
                label: actionRequiredByUser ? t('pages-inbox.goToConfirmation') : t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            taskDescription = t('pages-inbox.bankDepositTerminatedTaskDescription')
            break
        }
        case 'DEPOSITOR__SETTLEMENT_ACCOUNT_ADDED': {
            title = t('pages-inbox.depositorSettlementAccountAddedTitle')
            description = `
                ${t('pages-inbox.depositorSettlementAccountAddedDescription', { actingUserFullName, depositorName })}
                ${settlementAccount?.name ? t('pages-inbox.settlementAccountName', { accountName: settlementAccount?.name }) : ''}
                ${settlementAccount?.account ? t('pages-inbox.settlementAccountNumber', { accountNumber: settlementAccount?.account }) : ''}
                ${settlementAccount?.iban ? t('pages-inbox.settlementAccountIban', { iban: settlementAccount?.iban }) : ''}
                ${settlementAccount?.bic ? t('pages-inbox.settlementAccountBic', { bic: settlementAccount?.bic }) : ''}
            `
            break
        }
        case 'BANK__SETTLEMENT_ACCOUNT_CHANGED': {
            title = t('pages-inbox.bankSettlementAccountChangedTitle', { depositorName })
            description = t('pages-inbox.bankSettlementAccountChangedDescription', {
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
                date: DateOutput.formatDate(deposit?.terminationDate),
            })

            cta = {
                label: actionRequiredByUser ? t('pages-inbox.goToConfirmation') : t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            taskDescription = t('pages-inbox.bankSettlementAccountChangedTaskDescription')
            break
        }
        case 'DEPOSITOR__SETTLEMENT_ACCOUNT_CHANGE_CONFIRMED': {
            title = t('pages-inbox.depositorSettlementAccountChangeConfirmedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorSettlementAccountChangeConfirmedDescription', {
                    actingUserFullName,
                    depositorName,
                    account: formatAccount(deposit?.account, organisationCountry),
                    bankName,
                })}
                ${settlementAccount?.name ? t('pages-inbox.settlementAccountName', { accountName: settlementAccount?.name }) : ''}
                ${settlementAccount?.account ? t('pages-inbox.settlementAccountNumber', { accountNumber: settlementAccount?.account }) : ''}
                ${settlementAccount?.iban ? t('pages-inbox.settlementAccountIban', { iban: settlementAccount?.iban }) : ''}
                ${settlementAccount?.bic ? t('pages-inbox.settlementAccountBic', { bic: settlementAccount?.bic }) : ''}
                ${t('pages-inbox.depositorSettlementAccountChangeConfirmedDescriptionLastLine')}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__SETTLEMENT_ACCOUNT_CHANGE_CONFIRMED_BY_BANK': {
            title = t('pages-inbox.depositorSettlementAccountChangeConfirmedByBankTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorSettlementAccountChangeConfirmedByBankDescription', {
                    bankName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
                ${
                    deposit?.terminationDate && settlementAccount?.name
                        ? t('pages-inbox.depositorSettlementAccountChangeConfirmedByBankDescriptionLine2', {
                              date: DateOutput.formatDate(deposit?.terminationDate),
                              account: `${settlementAccount?.name} (${settlementAccount?.account || settlementAccount?.iban})`,
                          })
                        : ''
                }
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__SETTLEMENT_ACCOUNT_CHANGE_CANCELED': {
            title = t('pages-inbox.depositorSettlementAccountChangeCanceledByDepositorTitle', { actingUserFullName })
            description = `
                ${t('pages-inbox.depositorSettlementAccountChangeCanceledByDepositorDescription', {
                    bankName,
                    actingUserFullName,
                })}
                ${t('pages-inbox.depositorSettlementAccountChangeCanceledByDepositorDescriptionLine2')}
         `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }

        case 'DEPOSITOR__CUSTOMER_DECLARATION_PUBLISHED': {
            title = t('pages-inbox.depositorCustomerDeclarationPublishedTitle')
            description = t('pages-inbox.depositorCustomerDeclarationPublishedDescription', {
                actingUserFullName,
                depositorName,
            })
            cta = {
                label: t('pages-inbox.goToCustomerDeclaration'),
                link: `/organizations/${depositorId}/customerdecl`,
            }
            break
        }
        case 'DEPOSITOR__CUSTOMER_DECLARATION_WILL_EXPIRE': {
            title = t('pages-inbox.depositorCustomerDeclarationWillExpireTitle')
            description = depositor?.customerDeclarationExpired
                ? t('pages-inbox.depositorCustomerDeclarationHasExpiredDescription', {
                      actingUserFullName,
                      depositorName,
                  }) +
                  (hasActiveDeposits ? ' ' + t('pages-inbox.depositorCustomerDeclarationWillExpireCancelation') : '')
                : t('pages-inbox.depositorCustomerDeclarationWillExpireDescription', {
                      actingUserFullName,
                      depositorName,
                  }) +
                  (hasActiveDeposits ? ' ' + t('pages-inbox.depositorCustomerDeclarationWillExpireCancelation') : '')
            cta = {
                label: t('pages-inbox.goToCustomerDeclaration'),
                link: `/organizations/${depositorId}/customerdecl`,
            }
            taskDescription = t('pages-inbox.depositorCustomerDeclarationWillExpireTaskDescription')
            break
        }
        case 'BANK__CUSTOMER_DECLARATION_RECEIVED': {
            title = t('pages-inbox.bankCustomerDeclarationReceivedTitle', { depositorName })
            description = t('pages-inbox.bankCustomerDeclarationReceivedDescription', { depositorName })
            taskDescription = t('pages-inbox.bankCustomerDeclarationReceivedTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToCustomerDeclaration'),
                    link: `/newcustomerdeclarations/${depositorId}`,
                }
            }
            break
        }
        case 'DEPOSITOR__INTEREST_RATE_CHANGE_MESSAGE_RECEIVED': {
            // WARNING: The data of an implemented irc will not be sent to the client, so interestRateChange in this message will be undefined when irc is implemented
            title = t('pages-inbox.depositorInterestRateChangeNoticeReceivedTitle', { bankName })
            description = t('pages-inbox.depositorInterestRateChangeNoticeReceivedDescription', {
                bankName,
                account: formatAccount(deposit?.account, organisationCountry),
            })

            if (interestRateChange) {
                description += `
                    ${t('pages-inbox.interestRateChangeOldMargin', {
                        interest: InterestOutput.format(interestRateChange.oldInterest),
                    })}
                    ${t('pages-inbox.interestRateChangeNewMargin', {
                        interest: InterestOutput.format(interestRateChange.newInterest),
                    })}
                    ${t('pages-inbox.interestRateChangeDate', {
                        date: DateOutput.formatDate(interestRateChange.changeDate),
                        interestRateBenchmark: deposit.interestRateBenchmark,
                        benchmarkInterestRate,
                        oldInterestRate: (interestRateChange.oldInterest + benchmarkInterestRate).toFixed(2),
                        newInterestRate: (interestRateChange.newInterest + benchmarkInterestRate).toFixed(2),
                    })}
                `
            }

            taskDescription = t('pages-inbox.depositorInterestRateChangeTaskDescription')

            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'PARTNER__INTEREST_RATE_CHANGE_IMPLEMENTED': {
            title = t('pages-inbox.partnerInterestChangeForDepositorTitle', { depositorName: partnerDepositorName })
            description = t('pages-inbox.partnerInterestChangeForDepositorDescription', {
                depositorName: partnerDepositorName,
                bankName,
            })

            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: () => goToCustomerDeposit('DEPOSITOR', depositorId, messageParameters.depositId),
            }
            break
        }
        case 'BANK__INTEREST_RATE_CHANGE_MESSAGE_SENT': {
            // WARNING: The data of an implemented irc will not be sent to the client, so interestRateChange in this message will be undefined when irc is implemented
            title = t('pages-inbox.bankInterestRateChangeNoticeSentTitle', { depositorName })
            description = t('pages-inbox.bankInterestRateChangeNoticeSentDescription', {
                actingUserFullName,
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
            })

            if (interestRateChange) {
                description += `
                    ${t('pages-inbox.interestRateChangeOldMargin', { interest: InterestOutput.format(interestRateChange.oldInterest) })}
                    ${t('pages-inbox.interestRateChangeNewMargin', { interest: InterestOutput.format(interestRateChange.newInterest) })}
                    ${t('pages-inbox.interestRateChangeDate', {
                        interestRateBenchmark: t('common.interestRateBenchmark.' + deposit?.interestRateBenchmark),
                        benchmarkInterestRate: InterestOutput.format(benchmarkInterestRate),
                        oldInterestRate: InterestOutput.format(interestRateChange.oldInterest + benchmarkInterestRate),
                        newInterestRate: InterestOutput.format(interestRateChange.newInterest + benchmarkInterestRate),
                        date: DateOutput.formatDate(interestRateChange.changeDate),
                    })}
                `
            }

            cta = {
                label: t('pages-inbox.viewNotice'),
                link: `/interestratechange-bank/${interestRateChange?.id}`,
            }
            break
        }
        case 'DEPOSITOR__INTEREST_RATE_CHANGE_IMPLEMENTED': {
            // WARNING: The data of an implemented irc will not be sent to the client, so interestRateChange in this message will be undefined when irc is implemented
            title = t('pages-inbox.depositorInterestRateChangeImplementedTitle', { bankName })
            description = t('pages-inbox.depositorInterestRateChangeImplementedDescription', {
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
                bankName,
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__INTEREST_RATE_CHANGE_IMPLEMENTED': {
            title = t('pages-inbox.bankInterestRateChangeImplementedTitle', { depositorName })
            description = t('pages-inbox.bankInterestRateChangeImplementedDescription', {
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
                bankName,
                newInterest: interestRateChange?.newInterest
                    ? InterestOutput.format(interestRateChange?.newInterest)
                    : t('common.unchanged'),
                oldInterest: interestRateChange?.oldInterest
                    ? InterestOutput.format(interestRateChange?.oldInterest)
                    : t('common.unchanged'),
                newInterestRateConvention: interestRateChange?.newInterestRateConvention
                    ? t('common.interestRateConvention.' + interestRateChange?.newInterestRateConvention)
                    : t('common.unchanged'),
                oldInterestRateConvention: interestRateChange?.oldInterestRateConvention
                    ? t('common.interestRateConvention.' + interestRateChange?.oldInterestRateConvention)
                    : t('common.unchanged'),
            })
            taskDescription = t('pages-inbox.bankInterestRateChangeImplementedTaskDescription')
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages-inbox.bankInterestRateChangeImplementedCheckboxPledge', {
                        date: DateOutput.formatDate(interestRateChange?.implemented),
                    }),
                    buttonLabel: t('pages-inbox.bankInterestRateChangeImplementedCheckboxButton'),
                }
            } else {
                cta = {
                    label: t('pages-inbox.viewDeposit'),
                    link: `/portfolio-bank/${deposit?.id}`,
                }
            }
            break
        }
        case 'DEPOSITOR__CONDITIONAL_DEPOSIT_TERMINATION_SENT': {
            title = t('pages-inbox.depositorConditionalTerminationSentTitle', { bankName })
            const requirement = t('pages-inbox.conditionalTerminationRequirement', {
                from: InterestOutput.format(deposit?.nominalInterestRate),
                to: InterestOutput.format(deposit?.interestRateRequirement),
            })
            description = t('pages-inbox.depositorConditionalTerminationSentDescription', {
                account: formatAccount(deposit?.account, organisationCountry),
                bankName,
                requirement,
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__CONDITIONAL_DEPOSIT_TERMINATION_RECEIVED': {
            title = t('pages-inbox.bankConditionalTerminationReceivedTitle', { depositorName })
            const requirement = t('pages-inbox.conditionalTerminationRequirement', {
                from: InterestOutput.format(deposit?.nominalInterestRate),
                to: InterestOutput.format(deposit?.interestRateRequirement),
            })
            description = t('pages-inbox.bankConditionalTerminationReceivedDescription', {
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
                requirement,
            })
            cta = {
                label: actionRequiredByUser ? t('pages-inbox.goToTermination') : t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            taskDescription = t('pages-inbox.bankConditionalTerminationReceivedTaskDescription')
            break
        }
        case 'DEPOSITOR__CONDITION_REJECTED': {
            title = t('pages-inbox.depositorConditionRejectedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorConditionRejectedDescription', { bankName })}
                ${t('pages-inbox.depositorConditionRejectedDescriptionLine2', {
                    date: DateOutput.formatDate(deposit?.terminationDate),
                    account: settlementAccountDescription(deposit, organisationCountry),
                })}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__DEPOSIT_TERMINATION_CANCELLED': {
            title = t('pages-inbox.depositorDepositTerminationCancelledTitle', { depositorName, bankName })
            description = `
                ${t('pages-inbox.depositorDepositTerminationCancelledDescription', {
                    account: formatAccount(deposit?.account, organisationCountry),
                    bankName,
                })}
                ${t('pages-inbox.depositorDepositTerminationCancelledDescriptionLine2')}
            `

            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__CONDITION_REJECTED': {
            title = t('pages-inbox.bankConditionRejectedTitle', { depositorName })
            description = `
                ${t('pages-inbox.bankConditionRejectedDescription')}
                ${t('pages-inbox.bankConditionRejectedDescriptionLine2', {
                    date: DateOutput.formatDate(deposit?.terminationDate),
                    account: settlementAccountDescription(deposit, organisationCountry),
                })}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__CONDITION_ACCEPTED': {
            title = t('pages-inbox.depositorConditionAcceptedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorConditionAcceptedDescription', { bankName })}
                ${t('pages-inbox.depositorConditionAcceptedDescriptionLine2')}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__CONDITION_ACCEPTED': {
            title = t('pages-inbox.bankConditionAcceptedTitle', { depositorName })
            description = `
                ${t('pages-inbox.bankConditionAcceptedDescription')}
                ${t('pages-inbox.bankConditionAcceptedDescriptionLine2')}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'BANK__NEW_USER': {
            title = t('pages-inbox.bankNewUserTitle', { userFullName })
            description = t('pages-inbox.bankNewUserDescription', { userFullName, bankName })
            break
        }
        case 'BANK__USER_REMOVED': {
            title = t('pages-inbox.bankUserRemovedTitle', { userFullName })
            description = t('pages-inbox.bankUserRemovedDescription', { userFullName, bankName })
            break
        }
        case 'USER__ADDED_TO_BANK': {
            title = t('pages-inbox.bankUserAddedToBankTitle', { bankName })
            description = t('pages-inbox.bankUserAddedToBankDescription', { bankName })
            break
        }
        case 'USER__REMOVED_FROM_BANK': {
            title = t('pages-inbox.bankUserRemovedFromBankTitle', { bankName })
            description = t('pages-inbox.bankUserRemovedFromBankDescription', { bankName })
            break
        }
        case 'DEPOSITOR__NEW_USER': {
            title = t('pages-inbox.depositorNewUserTitle', { userFullName, depositorName })
            description = t('pages-inbox.depositorNewUserDescription', { userFullName, depositorName })
            break
        }
        case 'DEPOSITOR__USER_REMOVED': {
            title = t('pages-inbox.depositorUserRemovedTitle', { userFullName, depositorName })
            description = t('pages-inbox.depositorUserRemovedDescription', {
                userFullName,
                depositorName,
                actingUserFullName,
            })
            break
        }
        case 'USER__ADDED_TO_DEPOSITOR': {
            title = t('pages-inbox.depositorUserAddedToDepositorTitle', { depositorName })
            description = t('pages-inbox.depositorUserAddedToDepositorDescription', { depositorName })
            break
        }
        case 'USER__REMOVED_FROM_DEPOSITOR': {
            title = t('pages-inbox.depositorUserRemovedFromDepositorTitle', { depositorName })
            description = t('pages-inbox.depositorUserRemovedFromDepositorDescription', { depositorName })
            break
        }
        case 'PARTNER__NEW_USER': {
            title = t('pages-inbox.partnerNewUserTitle', { userFullName })
            description = t('pages-inbox.partnerNewUserDescription', { userFullName, partnerName })
            break
        }
        case 'PARTNER__USER_REMOVED': {
            title = t('pages-inbox.partnerUserRemovedTitle', { userFullName })
            description = t('pages-inbox.partnerUserRemovedDescription', { userFullName, partnerName })
            break
        }
        case 'DEPOSITOR__PARTNER_PROPOSAL_CREATED': {
            title = t('pages-inbox.depositorPartnerProposalCreatedTitle', { partnerName })
            description = t('pages-inbox.depositorPartnerProposalCreatedDescription', {
                partnerName,
                bankName,
                interest: ad?.interest,
            })
            cta = {
                label: t('pages-inbox.depositorPartnerProposalCreatedLabel'),
                link: '/proposals',
            }
            break
        }
        case 'PARTNER__PROPOSAL_ACCEPTED': {
            title = t('pages-inbox.depositorPartnerProposalAcceptedTitle', { partnerDepositorName })
            description = t('pages-inbox.depositorPartnerProposalAcceptedDescription', {
                partnerDepositorName,
                currency: ad?.currency,
                amount: proposal?.amount,
                bankName,
            })
            taskDescription = t('pages-inbox.depositorPartnerProposalAcceptedTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.depositorPartnerProposalAcceptedLabel'),
                    link: () => goToCustomerAd('DEPOSITOR', depositorId, ad),
                }
            }

            break
        }
        case 'PARTNER__PROPOSAL_REJECTED': {
            title = t('pages-inbox.depositorPartnerProposalRejectedTitle', { partnerDepositorName })
            description = t('pages-inbox.depositorPartnerProposalRejectedDescription', {
                bankName,
                partnerDepositorName,
            })
            cta = {
                label: t('pages-inbox.depositorPartnerProposalRejectedLabel'),
                link: `/partner/proposals/rejected`,
            }
            break
        }
        case 'USER__ADDED_TO_PARTNER': {
            title = t('pages-inbox.partnerUserAddedToPartnerTitle', { partnerName })
            description = t('pages-inbox.partnerUserAddedToPartnerDescription', { partnerName })
            break
        }
        case 'USER__REMOVED_FROM_PARTNER': {
            title = t('pages-inbox.partnerUserRemovedFromPartnerTitle', { partnerName })
            description = t('pages-inbox.partnerUserRemovedFromPartnerDescription', { partnerName })
            break
        }
        case 'DEPOSITOR__DEPOSIT_EXTENSION_OFFER_RECEIVED': {
            title = t('pages-inbox.depositorDepositExtensionOfferReceivedTitle', { bankName })
            description = t('pages-inbox.depositorDepositExtensionOfferReceivedDescription', {
                bankName,
                account: formatAccount(deposit?.account, organisationCountry),
            })
            taskDescription = t('pages-inbox.depositorDepositExtensionOfferReceivedTaskDescription')
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToOffer'),
                    link: `/portfolio/id/${deposit?.id}`,
                }
            }
            break
        }
        case 'DEPOSITOR__DEPOSIT_EXTENSION_IMPLEMENTED': {
            title = t('pages-inbox.depositorDepositExtensionImplementedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorDepositExtensionImplementedDescription', {
                    bankName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
                ${t('pages-inbox.depositorDepositExtensionImplementedDescriptionLine2')}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_EXTENSION_OFFER_SENT': {
            title = t('pages-inbox.bankDepositExtensionOfferSentTitle', { depositorName })
            description = t('pages-inbox.bankDepositExtensionOfferSentDescription', {
                actingUserFullName,
                depositorName,
                account: formatAccount(deposit?.account, organisationCountry),
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_EXTENSION_OFFER_REPLY_RECEIVED': {
            title = t('pages-inbox.bankDepositExtensionOfferReplyReceivedTitle', { depositorName })
            description =
                deposit?.extensionOfferState === 'REJECTED'
                    ? `
                ${t('pages-inbox.bankDepositExtensionOfferRejectedDescription', {
                    depositorName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
                ${t('pages-inbox.bankDepositExtensionOfferRejectedDescriptionLine2')}
            `
                    : t('pages-inbox.bankDepositExtensionOfferAcceptedDescription', {
                          depositorName,
                          account: formatAccount(deposit?.account, organisationCountry),
                      })

            taskDescription = t('pages-inbox.bankDepositExtensionOfferReplyReceivedTaskDescription')
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_EXTENSION_IMPLEMENTED': {
            title = t('pages-inbox.bankDepositExtensionImplementedTitle', { depositorName })
            description = `
                ${t('pages-inbox.bankDepositExtensionImplementedDescription', {
                    depositorName,
                    account: formatAccount(deposit?.account, organisationCountry),
                })}
                ${t('pages-inbox.bankDepositExtensionImplementedDescriptionLine2')}
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__DEPOSIT_TERMINATION_REACHED': {
            title = t('pages-inbox.depositorDepositTerminationReachedTitle', { bankName })
            description = `
                ${t('pages-inbox.depositorDepositTerminationReachedDescription', { depositorName, bankName })}
                ${
                    deposit?.calculatedFinalBalance
                        ? t('pages-inbox.depositorDepositTerminationDescriptionLine2', {
                              amount: Currency(deposit.calculatedFinalBalance - deposit?.actualStartVolume),
                          })
                        : ''
                }
            `
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_TERMINATION_REACHED': {
            title = t('pages-inbox.bankDepositTerminationReachedTitle', { depositorName })
            description = t('pages-inbox.bankDepositTerminationReachedDescription', { depositorName, bankName })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'BANK__ACCOUNT_STATEMENT_REQUESTED': {
            title = t('pages-inbox.bankAccountStatementRequestedTitle')
            description = t('pages-inbox.bankAccountStatementRequestedDescription')
            taskDescription = t('pages-inbox.bankAccountStatementRequestedTaskDescription')
            cta = {
                label: t('pages-inbox.goToAccountStatements'),
                link: '/bank-reports/balance-reporting',
            }
            break
        }
        case 'BANK__ACCOUNT_STATEMENT_REMINDER': {
            title = t('pages-inbox.bankAccountStatementReminderTitle')

            description = t('pages-inbox.bankAccountStatementReminderDescription', {
                yearMonth: DateOutput.formatMonthYear(month),
            })
            taskDescription = t('pages-inbox.bankAccountStatementReminderTaskDescription')
            cta = {
                label: t('pages-inbox.goToAccountStatements'),
                link: '/bank-reports/balance-reporting',
            }
            break
        }
        case 'BANK__YEARLY_STATEMENT_DUE': {
            title = t('pages-inbox.bankYearlyStatementDueTitle')
            description = t('pages-inbox.bankYearlyStatementDueDescription')

            cta = {
                label: t('pages-inbox.goToAccountStatements'),
                link: '/bank-reports/balance-reporting',
            }
            break
        }
        case 'BANK__YEARLY_STATEMENT_REMINDER': {
            title = t('pages-inbox.bankYearlyStatementReminderTitle')

            description = t('pages-inbox.bankYearlyStatementReminderDescription', {
                year: year,
            })
            taskDescription = t('pages-inbox.bankYearlyStatementReminderTaskDescription')
            cta = {
                label: t('pages-inbox.goToAccountStatements'),
                link: '/bank-reports/balance-reporting',
            }
            break
        }
        case 'BANK__AD_FULLY_SUBSCRIBED': {
            title = t('pages-inbox.bankAdFullySubscribedTitle')
            description = t('pages-inbox.bankAdFullySubscribedDescription', {
                product: product?.name,
                interest:
                    product?.type === 'FIXED'
                        ? InterestOutput.format(ad?.interest)
                        : InterestOutput.formatMarginWithBenchmark(
                              ad?.nominalInterestRate,
                              ad?.interestRateBenchmark,
                              t
                          ),
            })

            cta = {
                label: t('pages-inbox.goToBankAds'),
                link: '/ads',
            }
            break
        }
        case 'BANK__AD_EXPIRED': {
            title = t('pages-inbox.bankAdExpiredTitle')
            description = t('pages-inbox.bankAdExpiredDescription', {
                product: product?.name,
                interest:
                    product?.type === 'FIXED'
                        ? InterestOutput.format(ad?.interest)
                        : InterestOutput.formatMarginWithBenchmark(
                              ad?.nominalInterestRate,
                              ad?.interestRateBenchmark,
                              t
                          ),
            })

            cta = {
                label: t('pages-inbox.goToBankAds'),
                link: '/ads',
            }
            break
        }
        case 'DEPOSITOR__PARTNER_INVITE_RECEIVED': {
            title = t('pages-inbox.depositorPartnerInviteReceivedTitle', { partnerName })
            description = partnerIsAccountant(partnerName)
                ? t('pages-inbox.depositorAccountantInviteReceivedDescription', { partnerName, depositorName })
                : t('pages-inbox.depositorPartnerInviteReceivedDescription', { partnerName, depositorName })
            taskDescription = t('pages-inbox.depositorPartnerInviteReceivedTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToPartnerRequest'),
                    link: '/organizations',
                }
            }
            break
        }
        case 'DEPOSITOR__PARTNER_USER_ADDED': {
            title = partnerIsAccountant(partnerName)
                ? t('pages-inbox.depositorAccountantUserAddedTitle', { userFullName })
                : t('pages-inbox.depositorPartnerUserAddedTitle', { userFullName })
            description = partnerIsAccountant(partnerName)
                ? t('pages-inbox.depositorAccountantUserAddedDescription', { userFullName, partnerName, depositorName })
                : t('pages-inbox.depositorPartnerUserAddedDescription', { userFullName, partnerName, depositorName })
            break
        }
        case 'DEPOSITOR__PARTNER_USER_REMOVED': {
            title = partnerIsAccountant(partnerName)
                ? t('pages-inbox.depositorAccountantUserRemovedTitle', { userFullName })
                : t('pages-inbox.depositorPartnerUserRemovedTitle', { userFullName })
            description = partnerIsAccountant(partnerName)
                ? t('pages-inbox.depositorAccountantUserRemovedDescription', {
                      userFullName,
                      partnerName,
                      depositorName,
                  })
                : t('pages-inbox.depositorPartnerUserRemovedDescription', { userFullName, partnerName, depositorName })
            break
        }
        case 'PARTNER__INVITE_SENT': {
            title = t('pages-inbox.partnerInviteSentTitle', { partnerDepositorName })
            description = t('pages-inbox.partnerInviteSentDescription', { partnerDepositorName, partnerName })
            break
        }
        case 'PARTNER__INVITE_ACCEPTED': {
            title = t('pages-inbox.partnerInviteAcceptedTitle', { partnerDepositorName })
            description = t('pages-inbox.partnerInviteAcceptedDescription', { partnerDepositorName, partnerName })
            break
        }
        case 'PARTNER__INVITE_REJECTED': {
            title = t('pages-inbox.partnerInviteRejectedTitle', { partnerDepositorName })
            description = t('pages-inbox.partnerInviteRejectedDescription', { partnerDepositorName, partnerName })
            break
        }
        case 'USER__NEWLY_REGISTERED': {
            title = t('pages-inbox.userNewlyRegisteredTitle')
            description = t('pages-inbox.userNewlyRegisteredDescription')
            taskDescription = t('pages-inbox.userNewlyRegisteredTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToSettings'),
                    link: '/organizations',
                }
            }
            break
        }
        case 'USER__ACCOUNT_HOLDER_ROLE_ADDED': {
            title = t('pages-inbox.userAccountHolderRoleAddedTitle', { depositorName })
            description = t('pages-inbox.userAccountHolderRoleAddedDescription')
            taskDescription = t('pages-inbox.userAccountHolderRoleAddedTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToYourProfile'),
                    link: '/profile',
                }
            }
            break
        }
        case 'DEPOSITOR__CUSTOMER_DECLARATION_REQUIRED': {
            title = t('pages-inbox.depositorCustomerDeclarationAccountRequiredTitle', { depositorName })
            description = t('pages-inbox.depositorCustomerDeclarationAccountRequiredDescription', { depositorName })
            taskDescription = t('pages-inbox.depositorCustomerDeclarationAccountRequiredTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToSettings'),
                    link: `/organizations/${depositorId}/customerdecl`,
                }
            }
            break
        }
        case 'DEPOSITOR__SETTLEMENT_ACCOUNT_REQUIRED': {
            title = t('pages-inbox.depositorSettlementAccountRequiredTitle', { depositorName })
            description = t('pages-inbox.depositorSettlementAccountRequiredDescription', { depositorName })
            taskDescription = t('pages-inbox.depositorSettlementAccountRequiredTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToSettings'),
                    link: `/organizations/${depositorId}/accounts`,
                }
            }
            break
        }
        case 'DEPOSITOR__TERMS_ACCEPTANCE_REQUIRED': {
            title = t('pages-inbox.depositorTermsAcceptanceRequiredTitle', { depositorName })
            description = t('pages-inbox.depositorTermsAcceptanceRequiredDescription', { depositorName })
            taskDescription = t('pages-inbox.depositorTermsAcceptanceRequiredTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.goToSettings'),
                    link: `/organizations/${depositorId}/terms/deposit`,
                }
            }
            break
        }
        case 'BANK__FIXED_INTEREST_PERIOD_TERMS_DUE': {
            title = t('pages-inbox.bankDepositReadyForRenewalTitle', { depositorName })
            description = t('pages-inbox.bankDepositReadyForRenewalDescription', { depositorName })
            taskDescription = t('pages-inbox.bankDepositReadyForRenewalTaskDescription')

            if (actionRequiredByUser) {
                cta = {
                    label: t('pages-inbox.viewDeposit'),
                    link: `/portfolio-bank/${deposit?.id}`,
                }
            }
            break
        }
        case 'DEPOSITOR__FIXED_INTEREST_PERIOD_TERMS_RECEIVED': {
            title = t('pages-inbox.depositorFixedInterestPeriodTermsReceivedTitle', { bankName })
            description = t('pages-inbox.depositorFixedInterestPeriodTermsReceivedDescription', {
                bankName,
            })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSIT_EXTENSION_OFFER_AVAILABLE': {
            title = t('pages-inbox.bankDepositExtensionOfferAvailableTitle')
            description = t('pages-inbox.bankDepositExtensionOfferAvailableDescription', { depositorName })
            cta = {
                label: t('pages-inbox.viewDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'BANK__DEPOSITOR_OFFER_AVAILABLE': {
            title = t('pages-inbox.bankDepositorOfferAvailableTitle')
            description = t('pages-inbox.bankDepositorOfferAvailableDescription', {
                depositorName,
                maxVolume: depositorOffer?.maxVolume,
            })
            cta = {
                label: t('pages-inbox.viewOffer'),
                link: `/depositoroffer-bank/${depositorOffer?.depositorOfferId}`,
            }
            break
        }
        case 'DEPOSITOR__ALL_ACCOUNT_STATEMENTS_RECEIVED': {
            title = t('pages-inbox.depositorAllAccountStatementsReceivedTitle', { depositorName, month })
            description = t('pages-inbox.depositorAllAccountStatementsReceivedDescription', { depositorName, month })
            cta = {
                label: t('pages-inbox.viewMonthlyReports'),
                link: `/portfolio/monthly-reports`,
            }
            break
        }
        case 'USER__DOCUMENT_FULLY_SIGNED': {
            title = t('pages-inbox.userDocumentFullySignedTitle', {
                documentName: document?.name ?? t('common.document'),
            })
            description = t('pages-inbox.userDocumentFullySignedDescription', {
                documentName: document?.name ?? t('common.document'),
            })
            break
        }
        case 'BANK__DEPOSIT_DEVIATION_DETECTED': {
            title = t('pages-inbox.depositDeviationDetectedTitle')
            description = t('pages-inbox.depositDeviationDetectedDescription', { depositorName })
            taskDescription = t('pages-inbox.depositDeviationDetectedTaskDescription')

            cta = {
                label: t('pages-inbox.goToDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            break
        }
        case 'USER__AUTH_DOCUMENT_SIGNATURE_REQUEST': {
            title = t('pages-inbox.authDocumentSignatureRequestTitle')
            description = t('pages-inbox.authDocumentSignatureRequestDescription', {
                documentName: document?.name ?? t('common.document'),
            })
            taskDescription = t('pages-inbox.authDocumentSignatureRequestTaskDescription')

            cta = {
                label: t('pages-inbox.viewSignatureRequests'),
                link: `/sign/`,
            }
            break
        }
        case 'USER__COORDINATOR_ROLE_ADDED': {
            title = t('pages-inbox.bankUserAddedAsCoordinator', { bankName })
            description = t('pages-inbox.bankUserCoordinatorDescription')
            taskDescription = t('pages-inbox.bankUserCoordinatorTaskDescription')
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages-inbox.bankUserCoordinatorTaskPledge'),
                    buttonLabel: t('pages-inbox.depositorOrderCanceledCheckboxButton'),
                }
            }
            cta = {
                label: t('pages-inbox.bankUserCoordinatorDeviationGuideLink'),
                link: () =>
                    window.open(
                        `${config().fixrateFrontDocumentUrl}/dokumentpakke-bank/${config().deviationGuideDocument}`,
                        '_blank',
                        'noopener, noreferrer'
                    ),
            }
            break
        }
        case 'FUND_INTERMEDIARY__FUND_BUY_ORDER_FULLY_SIGNED': {
            title = t('pages.inbox.messages.fundIntermediary.fundBuyOrderFullySigned.title', {
                depositorName,
                fundName: fundShareClass?.fullName,
            })
            description = t('pages.inbox.messages.fundIntermediary.fundBuyOrderFullySigned.description', {
                depositorName,
                fundName: fundShareClass?.fullName,
                amount: CurrencyOutput.formatMillion(fundBuyOrder?.amount),
            })
            taskDescription = t('pages.inbox.messages.fundIntermediary.fundBuyOrderFullySigned.taskDescription')

            cta = {
                label: t('pages-inbox.goToFundBuyOrders'),
                link: '/fund-buy-orders',
            }
            break
        }
        case 'FUND_INTERMEDIARY__FUND_BUY_ORDER_SIGNED_BY_NEW_USER': {
            title = t('pages.inbox.messages.fundIntermediary.fundBuyOrderSignedByNewUser.title', {
                depositorName,
                fundName: fundShareClass?.fullName,
            })
            description = t('pages.inbox.messages.fundIntermediary.fundBuyOrderSignedByNewUser.description', {
                depositorName,
                fundName: fundShareClass?.fullName,
                amount: CurrencyOutput.formatMillion(fundBuyOrder?.amount),
                userId: messageParameters?.userId,
            })
            taskDescription = t('pages.inbox.messages.fundIntermediary.fundBuyOrderSignedByNewUser.taskDescription')

            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages.inbox.messages.fundIntermediary.fundBuyOrderSignedByNewUser.checkboxPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            cta = {
                label: t('pages.inbox.messages.goTo.fundCustomerDetail'),
                link: `/fund-customers/${depositorId}`,
            }
            break
        }

        case 'FUND_INTERMEDIARY__FUND_SELL_ORDER_FULLY_SIGNED': {
            title = t('pages.inbox.messages.fundIntermediary.fundSellOrderFullySigned.title', {
                depositorName,
                fundName: fundShareClass?.fullName,
            })
            description = t('pages.inbox.messages.fundIntermediary.fundSellOrderFullySigned.description', {
                depositorName,
                fundName: fundShareClass?.fullName,
                unitQuantity: CurrencyOutput.formatNoCode(fundSellOrder?.unitQuantity, 0),
            })
            taskDescription = t('pages.inbox.messages.fundIntermediary.fundSellOrderFullySigned.taskDescription')

            cta = {
                label: t('pages-inbox.goToFundSellOrders'),
                link: '/fund-sell-orders',
            }
            break
        }

        case 'FUND_INTERMEDIARY__FUND_SELL_ORDER_SIGNED_BY_NEW_USER': {
            title = t('pages.inbox.messages.fundIntermediary.fundSellOrderSignedByNewUser.title', {
                depositorName,
                fundName: fundShareClass?.fullName,
            })
            description = t('pages.inbox.messages.fundIntermediary.fundSellOrderSignedByNewUser.description', {
                depositorName,
                fundName: fundShareClass?.fullName,
                unitQuantity: CurrencyOutput.formatNoCode(fundSellOrder?.unitQuantity, 0),
                userId: messageParameters?.userId,
            })
            taskDescription = t('pages.inbox.messages.fundIntermediary.fundSellOrderSignedByNewUser.taskDescription')

            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages.inbox.messages.fundIntermediary.fundSellOrderSignedByNewUser.checkboxPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            cta = {
                label: t('pages.inbox.messages.goTo.fundCustomerDetail'),
                link: `/fund-customers/${depositorId}`,
            }
            break
        }

        case 'FUND_INTERMEDIARY__FUND_BUY_ORDER_PUTS_PLACEMENT_ABOVE_LIMIT': {
            title = t('pages.inbox.messages.fundPlacementAboveLimit.title', {
                fundName: fundShareClass?.fullName,
                depositorName: depositorName,
            })
            description = t('pages.inbox.messages.fundPlacementAboveLimit.description', {
                depositorName: depositorName,
                fundName: fundShareClass?.fullName,
            })
            taskDescription = t('pages.inbox.messages.fundPlacementAboveLimit.taskDescription')

            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages.inbox.messages.fundPlacementAboveLimit.checkboxPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            cta = {
                label: t('pages-inbox.goToFundBuyOrders'),
                link: '/fund-buy-orders',
            }
            break
        }
        case 'FUND_INTERMEDIARY__FUND_SELL_ORDER_PUTS_PLACEMENT_BELOW_LIMIT': {
            title = t('pages.inbox.messages.fundPlacementBelowLimit.title', {
                fundName: fundShareClass?.fullName,
                depositorName: depositorName,
            })
            description = t('pages.inbox.messages.fundPlacementBelowLimit.description', {
                depositorName: depositorName,
                fundName: fundShareClass?.fullName,
            })
            taskDescription = t('pages.inbox.messages.fundPlacementBelowLimit.taskDescription')

            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages.inbox.messages.fundPlacementBelowLimit.checkboxPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            cta = {
                label: t('pages-inbox.goToFundSellOrders'),
                link: '/fund-sell-orders',
            }
            break
        }
        case 'FUND_INTERMEDIARY__CUSTOMER_DECLARATION_RECEIVED': {
            const tPath = 'pages.inbox.messages.fundIntermediary.customerDeclarationReceived'
            title = t(`${tPath}.title`, { depositorName })
            description = t(`${tPath}.description`, { depositorName })
            taskDescription = t(`${tPath}.taskDescription`)

            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t(`${tPath}.checkboxPledge`),
                    buttonLabel: t('common.confirm'),
                }
            }
            const tPathGoTo = 'pages.inbox.messages.goTo'
            cta = {
                label: t(`${tPathGoTo}.fundCustomerDetail`),
                link: `/fund-customers/${depositorId}`,
            }
            break
        }
        case 'FUND_INTERMEDIARY__SETTLEMENT_ACCOUNT_ADDED': {
            const tPath = 'pages.inbox.messages.fundIntermediary.settlementAccountAdded'
            title = t(`${tPath}.title`, { depositorName })
            description = t(`${tPath}.description`, { depositorName })
            taskDescription = t(`${tPath}.taskDescription`)
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t(`${tPath}.checkboxPledge`),
                    buttonLabel: t('common.confirm'),
                }
            }
            const tPathGoTo = 'pages.inbox.messages.goTo'
            cta = {
                label: t(`${tPathGoTo}.fundCustomerDetail`),
                link: `/fund-customers/${depositorId}`,
            }
            break
        }
        case 'FUND_INTERMEDIARY__AUTHORIZATION_DOCUMENT_FULLY_SIGNED_BY_FUND_CUSTOMER_SIGNATORY': {
            title = t(
                'pages.inbox.messages.fundIntermediary.authorizationDocumentFullySignedByFundCustomerSignatory.title',
                { depositorName }
            )
            description = t(
                'pages.inbox.messages.fundIntermediary.authorizationDocumentFullySignedByFundCustomerSignatory.description',
                { depositorName }
            )
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t(
                        'pages.inbox.messages.fundIntermediary.authorizationDocumentFullySignedByFundCustomerSignatory.checkboxPledge'
                    ),
                    buttonLabel: t('common.confirm'),
                }
            }
            cta = {
                label: t(
                    'pages.inbox.messages.fundIntermediary.authorizationDocumentFullySignedByFundCustomerSignatory.checkboxPledge'
                ),
                link: `/fund-customers/${depositorId}`,
            }
            break
        }
        case 'FUND_INTERMEDIARY__FA_DEPOSIT_DETECTED': {
            title = t('pages.inbox.messages.fundIntermediary.faDepositDetected.title', { depositorName })
            description = t('pages.inbox.messages.fundIntermediary.faDepositDetected.description', { depositorName })
            break
        }
        case 'FUND_INTERMEDIARY__FA_BUY_ORDER_COMPLETED': {
            title = t('pages.inbox.messages.fundIntermediary.faBuyOrderCompleted.title', { depositorName })
            description = t('pages.inbox.messages.fundIntermediary.faBuyOrderCompleted.description', { depositorName })
            cta = {
                label: t('pages-inbox.goToFundBuyOrders'),
                link: '/fund-buy-orders/',
            }
            break
        }
        case 'FUND_INTERMEDIARY__FA_SELL_ORDER_COMPLETED': {
            title = t('pages.inbox.messages.fundIntermediary.faSellOrderCompleted.title', { depositorName })
            description = t('pages.inbox.messages.fundIntermediary.faSellOrderCompleted.description', { depositorName })
            cta = {
                label: t('pages-inbox.goToFundSellOrders'),
                link: '/fund-sell-orders/',
            }
            break
        }
        case 'FUND_INTERMEDIARY__CUSTOMER_FOLLOWUP_SCHEDULED': {
            title = t('pages.inbox.messages.fundIntermediary.fundCustomerFollowUp.title', { depositorName })
            description = t('pages.inbox.messages.fundIntermediary.fundCustomerFollowUp.description', { depositorName })
            taskDescription = t('pages.inbox.messages.fundIntermediary.fundCustomerFollowUp.taskDescription')
            const tPathGoTo = 'pages.inbox.messages.goTo'
            cta = {
                label: t(`${tPathGoTo}.fundCustomerDetail`),
                link: `/fund-customers/${depositorId}`,
            }
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages.inbox.messages.fundIntermediary.fundCustomerFollowUp.checkboxPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            break
        }
        case 'DEPOSITOR__FUND_BUY_ORDER_READY_FOR_PAYMENT': {
            title = t('pages-inbox.fundReadyForPaymentTitle')
            description = t('pages-inbox.fundReadyForPaymentDescription', { depositorName })
            taskDescription = t('pages-inbox.fundReadyForPaymentTaskDescription')

            cta = {
                label: t('pages-inbox.goToTradeOrder'),
                link: `/orders/funds/${orderGroupId}`,
            }
            break
        }
        case 'DEPOSITOR__FUND_BUY_ORDER_PAYMENT_RECEIVED': {
            title = t('pages.inbox.messages.fundBuyOrderPaymentReceived.title', {
                amount: CurrencyOutput.formatMillion(fundBuyOrder?.originalAmount),
            })
            description = t('pages.inbox.messages.fundBuyOrderPaymentReceived.description', {
                amount: CurrencyOutput.formatMillion(fundBuyOrder?.originalAmount),
                fundName: fundShareClass?.fullName,
                depositorName,
            })
            break
        }
        case 'DEPOSITOR__FUND_SELL_ORDER_IN_PROGRESS': {
            title = t('pages-inbox.fundSellOrderInProgressTitle')
            description = t('pages-inbox.fundSellOrderInProgressDescription', { depositorName })

            cta = {
                label: t('pages-inbox.goToTradeOrder'),
                link: `/orders/funds/${orderGroupId}`,
            }
            break
        }
        case 'DEPOSITOR__FUND_BUY_ORDER_COMPLETED': {
            const transactionDate = fundBuyOrder?.transactionDate && DateOutput.formatDate(fundBuyOrder.transactionDate)
            const unitQuantity = fundBuyOrder?.unitQuantity && CurrencyOutput.formatNoCode(fundBuyOrder.unitQuantity)
            const unitPrice = fundBuyOrder?.unitPrice && Currency(fundBuyOrder.unitPrice, { decimals: 4 })
            title = t('pages-inbox.fundBuyOrderCompletedTitle')
            description = t('pages-inbox.fundBuyOrderCompletedDescription', {
                depositorName: depositorName,
                fundName: fundShareClass?.fullName,
                transactionDate: transactionDate,
                unitQuantity: unitQuantity,
                unitPrice: unitPrice,
            })

            cta = {
                label: t('pages-inbox.goToFundTransactions'),
                link: `/portfolio/fund-transactions`,
            }
            break
        }
        case 'DEPOSITOR__FUND_SELL_ORDER_COMPLETED': {
            const transactionDate =
                fundSellOrder?.transactionDate && DateOutput.formatDate(fundSellOrder.transactionDate)
            const unitQuantity = fundSellOrder?.unitQuantity && CurrencyOutput.formatNoCode(fundSellOrder.unitQuantity)
            const unitPrice = fundSellOrder?.unitPrice && Currency(fundSellOrder.unitPrice, { decimals: 4 })
            title = t('pages-inbox.fundSellOrderCompletedTitle')
            description = t('pages-inbox.fundSellOrderCompletedDescription', {
                depositorName: depositorName,
                fundName: fundShareClass?.fullName,
                transactionDate: transactionDate,
                unitQuantity: unitQuantity,
                unitPrice: unitPrice,
            })

            cta = {
                label: t('pages-inbox.goToFundTransactions'),
                link: `/portfolio/fund-transactions`,
            }
            break
        }
        case 'DEPOSITOR__IMPORTED_PEOPLE_CHANGED': {
            title = t('pages-inbox.publicInformationUpdatedTitle')
            description = t('pages-inbox.publicInformationUpdatedDescription', { depositorName })
            taskDescription = t('pages-inbox.publicInformationUpdatedTaskDescription')

            cta = {
                label: t('pages-inbox.publicInformationUpdatedGoToBoard'),
                link: `/organizations/${depositorId}/users/board`,
            }
            break
        }
        case 'BANK__DEPOSIT_EXTENSION_OFFERING_EXPIRES_SOON': {
            title = t('pages-inbox.bankDepositExtensionOfferingExpiresSoonTitle')
            description = `
                ${t('pages-inbox.bankDepositExtensionOfferingExpiresSoonDescription', { depositorName })}
                ${
                    deposit?.extensionOfferingSendDeadline
                        ? t('pages-inbox.bankDepositExtensionOfferingExpiresSoonDescription2', {
                              date: DateOutput.formatDate(deposit.extensionOfferingSendDeadline),
                          })
                        : ''
                }
            `
            taskDescription = t('pages-inbox.bankDepositExtensionOfferingExpiresSoonTaskDescription')
            cta = {
                label: t('pages-inbox.goToDeposit'),
                link: `/portfolio-bank/${deposit?.id}`,
            }
            if (actionRequiredByUser) {
                checkboxTaskLabels = {
                    pledge: t('pages-inbox.bankDepositExtensionOfferingExpiresSoonCheckboxTaskPledge'),
                    buttonLabel: t('common.confirm'),
                }
            }
            break
        }
        case 'BANK__CONTRACT_ADDED': {
            title = t('pages-inbox.bankContractChangedTitle')
            description = `${t('pages-inbox.bankContractChangedDescription', { documentName: document?.name })}`
            taskDescription = t('pages-inbox.bankContractChangedTaskDescription')
            cta = {
                label: t('pages-inbox.goToBankPage'),
                link: `/my-bank#contract`,
            }
            break
        }
        case 'DEPOSITOR__DEPOSIT_EXTENSION_NOT_OFFERED': {
            title = t('pages-inbox.depositorDepositExtensionNotOfferedTitle')
            description = t('pages-inbox.depositorDepositExtensionNotOfferedDescription', {
                depositorName,
                bankName,
                terminationDate: DateOutput.formatDate(deposit.terminationDate),
            })
            cta = {
                label: t('pages-inbox.goToDeposit'),
                link: `/portfolio/id/${deposit?.id}`,
            }
            break
        }
        case 'DEPOSITOR__FUND_CONTRACT_SIGNATURE_REQUIRED': {
            title = t('pages-inbox.depositorFundContractSignatureRequiredTitle', { depositorName })
            description = t('pages-inbox.depositorFundContractSignatureRequiredMessage', { depositorName })
            taskDescription = t('pages-inbox.depositorFundContractSignatureRequiredTask')
            cta = {
                label: t('pages-inbox.goToAgreements'),
                link: `/organizations/${depositorId}/terms/fund`,
            }
            break
        }
        case 'DEPOSITOR__FUND_BUY_ENABLED': {
            title = t('pages.inbox.messages.depositorFundBuyEnabled.title')
            description = t('pages.inbox.messages.depositorFundBuyEnabled.description', {
                depositorName: depositorName,
            })
            cta = {
                label: t('pages-inbox.goToFundMarketplace'),
                link: `/marketplace/category/funds/money-market-fund`,
            }
            break
        }
        case 'DEPOSITOR__OFFER_ACCEPTED': {
            title = t('pages-inbox.depositorOfferAcceptedTitle', { bankName })
            description = t('pages-inbox.depositorOfferAcceptedDescription', {
                depositorName,
                count: order?.volume,
                currency: order?.currency,
                interest: interestTerms(order?.ad, t),
                bankName,
            })
            break
        }
        case 'DEPOSITOR__FUND_ORDER_SIGNATURE_REQUEST': {
            title = t('pages-inbox.depositorFundOrderSignatureRequestTitle')
            description = t('pages-inbox.depositorFundOrderSignatureRequestDescription', {
                actingUserFullName,
            })
            cta = {
                label: t('pages-inbox.goToFund'),
                link: `/orders/funds`,
            }
            break
        }
        case 'USER__FUND_ORDER_SIGNATURE_REQUEST': {
            title = t('pages-inbox.depositorFundOrderSignatureRequestTitle')
            description = t('pages-inbox.depositorFundOrderSignatureRequestDescription', {
                actingUserFullName,
            })
            cta = {
                label: t('pages-inbox.goToFund'),
                link: `/orders/funds`,
            }
            break
        }
    }

    return {
        title,
        description,
        depositorName,
        actionRequiredByUser,
        taskDescription,
        cta,
        checkboxTaskLabels,
        taskTarget,
        taskResolvedByUserName,
    }
}
