import { Fragment, useMemo, useState } from 'react'
import styles from './DateRangePicker.module.scss'
import { createStaticRanges, DateRangePicker as ReactDateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DateRangeStyles.scss'
import { nb } from 'date-fns/locale'
import subMonths from 'date-fns/subMonths'
import subYears from 'date-fns/subYears'
import startOfYear from 'date-fns/startOfYear'
import { FundReturnPeriod } from '@fixrate/fixrate-query'
import { eachYearOfInterval, endOfYear, subDays } from 'date-fns'
import classNames from 'classnames'
import format from 'date-fns/format'
import { useTranslation } from '#components/i18n'
import { InterestOutput } from '..'
import { Backdrop } from '@mui/material'
import { NavAndReturn } from '#blocks/FundReturnsChart/useFundMarketData'
export const FUND_PERIOD_CONFIG: { period: FundReturnPeriod; label: string; startDate: Date }[] = [
    { period: 'PERIOD_1M', label: '1M', startDate: subMonths(subDays(new Date(), 1), 1) },
    { period: 'PERIOD_3M', label: '3M', startDate: subMonths(subDays(new Date(), 1), 3) },
    { period: 'PERIOD_6M', label: '6M', startDate: subMonths(subDays(new Date(), 1), 6) },
    { period: 'PERIOD_YTD', label: 'YTD', startDate: startOfYear(new Date()) },
    { period: 'PERIOD_1Y', label: '1Y', startDate: subYears(subDays(new Date(), 1), 1) },
    { period: 'PERIOD_3Y', label: '3Y', startDate: subYears(subDays(new Date(), 1), 3) },
    { period: 'PERIOD_5Y', label: '5Y', startDate: subYears(subDays(new Date(), 1), 5) },
    { period: 'PERIOD_10Y', label: '10Y', startDate: subYears(subDays(new Date(), 1), 10) },
]

type Props = {
    fundData?: NavAndReturn
    startDate: Date
    endDate: Date
    onChange: (startDate: Date, endDate: Date) => void
    maxDate: Date
    minDate: Date
    periodFilter?: FundReturnPeriod[]
    defaultSelectedPeriod?: FundReturnPeriod
}

export default function DateRangePicker({
    startDate,
    endDate,
    onChange,
    maxDate,
    minDate,
    fundData,
    periodFilter,
    defaultSelectedPeriod,
}: Props) {
    const { t } = useTranslation('components-DateRangePicker')

    const [dateRangeVisible, setDateRangeVisible] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState<FundReturnPeriod | null>(defaultSelectedPeriod || 'PERIOD_YTD')

    const periods = periodFilter
        ? FUND_PERIOD_CONFIG.filter((c) => periodFilter.includes(c.period))
        : FUND_PERIOD_CONFIG

    const predefinedRanges = useMemo(
        () =>
            createStaticRanges(
                eachYearOfInterval({ start: minDate, end: maxDate })
                    .reverse()
                    .map((date) => ({
                        label: format(date, 'yyyy'),
                        range() {
                            return {
                                startDate: startOfYear(date),
                                endDate: endOfYear(date),
                            }
                        },
                    }))
            ),
        [minDate, maxDate]
    )

    const selectPredefinedPeriod = (period: FundReturnPeriod) => {
        setSelectedPeriod(period)
        onChange(periods.find((c) => c.period === period).startDate, new Date())
        setDateRangeVisible(false)
    }

    const selectDateRange = () => {
        setDateRangeVisible(!dateRangeVisible)
    }

    const handleSelect = (ranges) => {
        setSelectedPeriod(null)
        onChange(ranges.selection.startDate, ranges.selection.endDate)
    }

    return (
        <div className={styles.buttonRow}>
            {periods.map(({ period, label }) => (
                <div
                    key={period}
                    className={classNames(
                        styles.button,
                        period === 'PERIOD_6M' || period === 'PERIOD_5Y' || period === 'PERIOD_10Y'
                            ? styles.removeMobile
                            : null,
                        period === selectedPeriod ? styles.active : undefined
                    )}
                    onClick={() => selectPredefinedPeriod(period)}
                >
                    <span>{t(label)}</span>
                    {fundData && (
                        <span
                            className={classNames(
                                styles.returns,
                                fundData?.returnRates[period] < 0 ? styles.negative : null
                            )}
                        >
                            {InterestOutput.format(fundData?.returnRates[period])}
                        </span>
                    )}
                </div>
            ))}
            <div className={classNames(styles.calendarWrapper)}>
                <span
                    className={classNames(styles.button, selectedPeriod === null ? styles.active : undefined)}
                    onClick={() => selectDateRange()}
                >
                    <i className={'ri-calendar-2-line'} />
                </span>
                {dateRangeVisible && (
                    <Fragment>
                        <Backdrop
                            sx={{ backgroundColor: 'transparent' }}
                            open={true}
                            onClick={() => setDateRangeVisible(false)}
                        />
                        <ReactDateRangePicker
                            ranges={[{ key: 'selection', startDate, endDate }]}
                            onChange={handleSelect}
                            minDate={minDate}
                            maxDate={maxDate}
                            inputRanges={[]}
                            locale={nb}
                            staticRanges={predefinedRanges}
                            editableDateInputs={true}
                            dateDisplayFormat={'dd.MM.yyyy'}
                            className={styles.dateRange}
                            color="#4D2A98"
                            rangeColors={['#4D2A98']}
                        />
                        <div className={styles.calendarIndicator} />
                    </Fragment>
                )}
            </div>
        </div>
    )
}
