import CopyLink from '#app/pages/PartnerProposals/components/CopyLink'
import { UsersListParams } from '#blocks/UsersList/UsersList'
import { useCommand } from '#command'
import { DateOutput } from '#components'
import { supportedLanguages, Trans, useTranslation } from '#components/i18n'
import If from '#components/If/If'
import Modal from '#components/Modal'
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material'
import classNames from 'classnames'
import isAfter from 'date-fns/isAfter'
import { Fragment, useState } from 'react'
import MenuItem from './MenuItem'
import ResendUserInvite from './ResendUserInvite'
import RoleBadgeList from './RoleBadgeList'
import styles from './UsersList.module.scss'

type Props = {
    userInvite
    userListParams: UsersListParams
}

export default function UserInvite({ userInvite, userListParams }: Props) {
    const { t } = useTranslation('blocks-UsersList')

    const { isAdmin, organisation, missingRoles, basePath } = userListParams

    const emphasizeRoleLink = missingRoles?.length > 0
    const [resendInviteVisible, setResendInviteVisible] = useState(false)
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)

    const expired = isAfter(new Date(), new Date(userInvite.expirationDate))
    const invalid = userInvite.invalid
    const inviteLink = window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite.id

    const { removeUserInvite } = useCommand()

    async function remove() {
        try {
            await removeUserInvite(organisation.id, userInvite.id)
        } catch (err) {
            console.error(err)
        }
    }

    const resendButton =
        isAdmin && organisation.type !== 'DEPOSITOR' ? (
            <Button
                startIcon={<i className="ri-send-plane-2-line" />}
                variant="outlined"
                id="resendInvitationMenuitem"
                href={`${basePath}/resend-invite/${userInvite.id}#!`}
            >
                {t('menuItemResendInvite')}
            </Button>
        ) : (
            <Button
                startIcon={<i className="ri-send-plane-2-line" />}
                variant="outlined"
                id="resendInvitationMenuitem"
                onClick={() => setResendInviteVisible(true)}
            >
                {t('menuItemResendInvite')}
            </Button>
        )

    return (
        <div
            id={userInvite.id}
            data-cy="userRequestElement"
            className={classNames(styles.userslist__listElement, styles.userslist__sentInvitation)}
        >
            <div className={styles.userslist__listElementTop}>
                <div>
                    <h3 className={styles.userlist__name}>{userInvite.firstName + ' ' + userInvite.lastName}</h3>
                    <div className={styles.userlist__identity}>
                        <p className={styles.userlist__identityElement}>
                            <i className="ri-mail-send-line" />
                            <span>
                                <Trans t={t} i18nKey={'inviteSentOn'}>
                                    Invite sent on <DateOutput.Date date={userInvite.requested} />
                                </Trans>
                            </span>
                        </p>
                        {resendButton}
                    </div>
                    {expired && (
                        <p className={styles.userlist__invitationStatus}>
                            {t('inviteStatusExpired', {
                                date: DateOutput.formatDate(userInvite.requested),
                                expiryDate: DateOutput.formatDate(userInvite.expirationDate),
                            })}
                        </p>
                    )}
                    {!expired && !invalid && (
                        <p className={styles.userlist__invitationStatus}>
                            {t('inviteStatusActive', {
                                date: DateOutput.formatDate(userInvite.requested),
                                expiryDate: DateOutput.formatDate(userInvite.expirationDate),
                            })}
                        </p>
                    )}
                    {!expired && invalid && (
                        <p className={styles.userlist__invitationStatus}>{t('inviteStatusInvalid')}</p>
                    )}
                </div>
                <ul className={styles.actionMenu}>
                    <If condition={isAdmin}>
                        <Fragment>
                            {organisation.type !== 'DEPOSITOR' && (
                                <MenuItem
                                    emphasized={emphasizeRoleLink}
                                    id="editRoleMenuItem"
                                    text={t('menuItemEditRole')}
                                    url={`${basePath}/edit-role/${userInvite.id}#!`}
                                    icon="ri-edit-line"
                                />
                            )}
                            <MenuItem
                                id="removeInvitationMenuitem"
                                onClick={() => setRemoveUserModalVisible(true)}
                                text={t('menuItemRemoveInvite')}
                                icon="ri-delete-bin-line"
                            />
                        </Fragment>
                    </If>
                </ul>
            </div>
            <div>
                <input
                    id={'inviteLink' + userInvite.id}
                    readOnly
                    style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}
                    type="text"
                    value={inviteLink}
                />
                <Table sx={{ maxWidth: '100%', width: '50rem' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>{t('inviteEmail')}</TableCell>
                            <TableCell>{userInvite.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('inviteDateOfBirth')}</TableCell>
                            <TableCell>
                                <DateOutput.Date date={userInvite.birthDate} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{ width: '50%' }}>{t('inviteLink')}</TableCell>
                            <TableCell>
                                {expired ? (
                                    t('expired')
                                ) : (
                                    <CopyLink id="copyLink" link={inviteLink} buttonText={t('copyToClipboard')} />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('validUntil')}</TableCell>
                            <TableCell>
                                <DateOutput.Date date={userInvite.expirationDate} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>{t('inviteLanguage')}</TableCell>
                            <TableCell>
                                {(userInvite.language && supportedLanguages[userInvite.language]) || ''}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>

            <RoleBadgeList userListParams={userListParams} roles={userInvite.roles} userId={userInvite.id} />

            {resendInviteVisible && (
                <ResendUserInvite
                    organisationId={organisation.id}
                    organisationType={organisation.type}
                    inviteId={userInvite.id}
                    inviteEmail={userInvite.email}
                    close={() => setResendInviteVisible(false)}
                    returnTo={basePath}
                />
            )}
            {removeUserModalVisible && (
                <Modal
                    onCancel={() => setRemoveUserModalVisible(false)}
                    width="40rem"
                    header={t('removeInviteHeading')}
                    onSubmit={() => remove()}
                    className={styles.confirmation}
                >
                    <div className={styles.innerWrapper}>
                        <p className={styles.body}>
                            {t('removeInviteConfirmation', {
                                userName: userInvite.firstName + ' ' + userInvite.lastName,
                                organizationName: organisation.name,
                            })}
                        </p>
                    </div>
                </Modal>
            )}
        </div>
    )
}
