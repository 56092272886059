import { Column } from '#services/useTableSort'
import { Box, IconButton, SortDirection, Stack, Tooltip } from '@mui/material'
import LongTooltip from '#app/components/LongTooltip/LongTooltip'

type Props<Row> = {
    column: Column<Row>
    sortKey: keyof Row
    sortDirection: SortDirection
    onClick: (column: Column<Row>) => void
    tooltip?: string
    longTooltip?: string
}

export default function FundTableHeaderCell<Row>({
    column,
    sortKey,
    sortDirection,
    onClick,
    tooltip,
    longTooltip,
}: Props<Row>) {
    function _onClick() {
        if (column.sortable) {
            onClick(column)
        }
    }

    return (
        <th>
            <Box
                sx={{
                    cursor: column.sortable ? 'pointer' : 'default',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: column.align === 'right' ? 'row-reverse' : 'row',
                    textAlign: column.align === 'right' ? 'right' : 'left',
                }}
            >
                <Stack direction={'row'} alignItems={'center'} sx={{ whiteSpace: tooltip ? 'nowrap' : null }}>
                    <span onClick={_onClick}>{column.label}</span>
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <IconButton color="primary">
                                <i className="ri-question-line" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {longTooltip && <LongTooltip title={column.label} description={longTooltip} />}
                </Stack>
                <i
                    onClick={_onClick}
                    className="ri-arrow-up-line"
                    style={{
                        marginLeft: column.align !== 'right' ? '0.4rem' : '0',
                        marginRight: column.align === 'right' ? '0.4rem' : '0',
                        transition: '0.2s ease-in-out',
                        transform: `${sortDirection === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)'} ${
                            sortKey === column.id ? 'scale(1)' : 'scale(0)'
                        }`,
                    }}
                />
            </Box>
        </th>
    )
}
