import { PURPLE } from '#app/colors/colors'
import { AccountNumberInput } from '#app/components/AccountNumberInput/AccountNumberInput'
import { useAuthorization } from '#app/services/authorization'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useCommand } from '#command'
import { HelpIcon, InterestOutput } from '#components'
import FileUpload from '#components/FileUpload/FileUpload'
import HelpPopup from '#components/HelpPopup'
import Modal from '#components/Modal'
import { useFieldState } from '@fixrate/fieldstate'
import { validateAccountNumber } from '#services/validateFields'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import CheckedIcon from '../../../../common/icons/CheckedIcon'
import OrderCancel from '../OrderCancel'
import styles from './InitialProcessing.module.scss'
import SignedDocumentList from './SignedDocumentList'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'

export type Props = {
    orderId: string
}

const Step = ({
    stepNumber,
    title,
    description,
    supportCommonName,
    helpText,
    children,
}: {
    stepNumber: number
    title: string
    description?: string
    supportCommonName?: SupportCategoryNameType
    helpText?: string
    children: JSX.Element
}) => {
    return (
        <Stack spacing={1}>
            <Stack direction="row" alignItems={'flex-start'} spacing={1}>
                <Stack
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        borderRadius: '10rem',
                        fontSize: '2rem',
                        width: '3.5rem',
                        height: '3.5rem',
                        aspectRatio: '1/1',
                        backgroundColor: PURPLE[50],
                        color: PURPLE[500],
                        fontWeight: '600',
                    }}
                >
                    {stepNumber}
                </Stack>
                <Stack direction="row" alignItems={'center'} minHeight={'3.5rem'}>
                    {helpText ? (
                        <HelpPopup text={helpText}>
                            <Typography variant="h3">{title}</Typography>
                        </HelpPopup>
                    ) : (
                        <Typography variant="h3">{title}</Typography>
                    )}
                    {supportCommonName && (
                        <HelpIcon supportCommonName={supportCommonName} sx={{ width: '2rem', height: '2rem', ml: 1 }} />
                    )}
                </Stack>
            </Stack>
            <Box pl={'4.5rem'}>
                {description && <Typography>{description}</Typography>}
                {children}
            </Box>
        </Stack>
    )
}

export default function InitialProcessing({ orderId }: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { uploadAccountAgreement } = useCommand()
    const order = useSelector((state) => selectors.ordersWithAd(state).find((order) => order.id === orderId))
    const documents = useSelector((state) => order && selectors.documentIdToDocumentMapper(state)(order.documents))
    const lookupSignatureStatus = useSelector(selectors.lookupSignatureStatus)
    const organisationCountry = useCurrentCountryCode()
    const accountAgreementDocument =
        documents && documents.find((document) => document.documentType === 'ACCOUNT_AGREEMENT')
    const { sendToDepositor, setToAccountOnOrder, removeDocumentFromOrder, startElectronicSignature } = useCommand()
    const [accountAgreementError, setAccountAgreementError] = useState(null)
    const [documentsNotCheckedError, setDocumentsNotCheckedError] = useState(null)
    const [isUploadingAccountAgreement, setIsUploadingAccountAgreement] = useState(false)
    const [controlStepVisible, setControlStepVisible] = useState(false)
    const [rejectStepVisible, setRejectStepVisible] = useState(false)
    const [allDocumentsChecked, setAllDocumentsChecked] = useState(false)
    const products = useSelector((state) => state.products)
    const auth = useAuthorization()

    const toAccountValidator = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('pages-orders.bankAccountNoMissing')
            }
            if (!isEditing && !validateAccountNumber(value, organisationCountry)) {
                return t('pages-orders.bankAccountNoInvalid')
            }
        },
        [organisationCountry, t]
    )
    const toAccountSubmit = useCallback(
        (value) => setToAccountOnOrder(order.id, value),
        [order.id, setToAccountOnOrder]
    )
    const toAccount = useFieldState(order.toAccount || '', toAccountValidator, { debug: true }, toAccountSubmit)

    // Validate required fields
    const formValidState = useMemo(() => {
        const validAccountNumber = toAccount.valid
        const hasSignedAccountAgreement = accountAgreementDocument && accountAgreementDocument.signedByAll
        return {
            validAccountNumber: validAccountNumber,
            hasSignedAccountAgreement: hasSignedAccountAgreement,
            formValid: validAccountNumber && hasSignedAccountAgreement,
        }
    }, [accountAgreementDocument, toAccount.valid])

    if (!order) return null

    async function _uploadAccountAgreement(files) {
        setAccountAgreementError(false)
        setIsUploadingAccountAgreement(true)
        const { waitForCommand, rejected } = await uploadAccountAgreement(order.id, files)

        if (rejected) {
            setIsUploadingAccountAgreement(false)
        } else {
            const success = await waitForCommand(60_000)

            setIsUploadingAccountAgreement(false)
            if (!success) {
                dispatch(
                    showConfirmationModal({
                        title: t('pages-orders.bankAccountAgreementUploaded'),
                        text: t('pages-orders.bankAccountAgreementUploadedButNotConfirmed'),
                        buttonText: t('common.ok'),
                        sentIcon: false,
                    })
                )
            }
        }
    }

    async function removeFile(documentId) {
        const { waitForCommand } = await removeDocumentFromOrder(order.id, documentId)
        await waitForCommand()
    }

    function openConfirmation() {
        if (!allDocumentsChecked) {
            setDocumentsNotCheckedError(t('pages-orders.documentsMustBeChecked'))
            return
        } else {
            setDocumentsNotCheckedError(null)
        }
        if (!formValidState.formValid) {
            let accountAgreementError = null
            if (!accountAgreementDocument) {
                accountAgreementError = t('pages-orders.bankAccountAgreementMustBeUploaded')
            } else if (!formValidState.hasSignedAccountAgreement) {
                accountAgreementError = t('pages-orders.bankAccountAgreementMustBeSigned')
            }
            setAccountAgreementError(accountAgreementError)
        } else {
            setControlStepVisible(true)
        }
    }

    async function submit() {
        const { waitForCommand } = await sendToDepositor(orderId)
        const success = await waitForCommand()
        if (success) {
            navigate('/bank-orders')
            dispatch(
                showConfirmationModal({
                    title: t('pages-orders.bankAccountAgreementIsSentTitle'),
                    text: t('pages-orders.bankAccountAgreementIsSentText'),
                    buttonText: t('common.continue'),
                })
            )
        }
    }

    async function onStartAccountAgreementSignature() {
        const processId = uuidv4()
        const { waitForCommand } = await startElectronicSignature(
            processId,
            'SINGLE_DOCUMENT',
            accountAgreementDocument.id
        )
        const success = await waitForCommand()
        if (success) {
            navigate(`/signature/${processId}?context=/bank-orders/${orderId}`)
        }
    }

    if (!order.accountCreator) {
        return (
            <>
                <p>{t('pages-orders.bankOrdererNoLongerUser', { depositorName: order.depositor.name })}</p>
                <p>{t('pages-orders.bankContactFixrate')}</p>
            </>
        )
    }

    function validationErrorElement(validationError) {
        switch (validationError) {
            case 'MISSING_ACCOUNT_NUMBER':
                return t('pages-orders.bankAccountNoCannotBeFound')
            case 'MISSING_NATIONAL_IDENTITY':
                return t('pages-orders.bankNationalIdCannotBeFound')
        }
    }

    let validationText
    if (!accountAgreementDocument || !accountAgreementDocument.validationResult) {
        validationText = <p>{t('pages-orders.bankAccountAgreementWillBeValidated')}</p>
    } else if (accountAgreementDocument.validationResult.length === 0) {
        validationText = (
            <p className={styles.validationOk}>
                <CheckedIcon className={styles.checkedicon} />
                {t('pages-orders.bankAccountAgreementOk')}
            </p>
        )
    } else {
        validationText = (
            <Stack spacing={1}>
                <Typography>{t('pages-orders.bankAccountAgreementSeemsInvalid')}</Typography>
                {accountAgreementDocument.validationResult.map((error) => (
                    <Alert sx={{ boxShadow: 'none' }} severity="warning" key={error}>
                        {validationErrorElement(error)}
                    </Alert>
                ))}
            </Stack>
        )
    }

    const ControlStep = () => {
        return (
            <Modal
                submitButtonId="sendDcoumentsToDepositorButton"
                header={t('pages-orders.confirmAccountInformation')}
                submitButtonText={t('pages-orders.bankSendDocuments')}
                onSubmit={submit}
                onCancel={() => setControlStepVisible(false)}
            >
                <div className={styles.confirmationStep}>
                    <p>{t('pages-orders.pleaseControlInformation')}</p>
                    <dl>
                        {products[order.ad.productId].type !== 'FIXED' && (
                            <>
                                <dt>{t('pages-orders.marginAddition')}</dt>
                                <dd>
                                    {t('common.interestRateBenchmark.' + order.interestRateBenchmark)} +{' '}
                                    {InterestOutput.format(order.ad.nominalInterestRate)}
                                </dd>
                            </>
                        )}
                        {products[order.ad.productId].type === 'FIXED' && (
                            <>
                                <dt>{t('pages-orders.interestRate')}</dt>
                                <dd>{InterestOutput.format(order.ad.interest)}</dd>
                            </>
                        )}
                        <dt>{t('pages-orders.interestRateConvention')}</dt>
                        <dd>{t(`enum-InterestRateConvention.${order.ad.interestRateConvention}`)}</dd>
                        <dt>{t('pages-orders.capitalizationFrequency')}</dt>
                        <dd>{t('pages-orders.yearly')}</dd>
                    </dl>
                </div>
            </Modal>
        )
    }

    return (
        <Stack spacing={3}>
            <Step
                stepNumber={1}
                title={t('pages-orders.bankStep1')}
                description={t('pages-orders.bankStep1Description')}
                supportCommonName="termsBankAcceptingDeposits"
            >
                <>
                    <SignedDocumentList
                        orderId={order.id}
                        checkDocuments
                        removeDocumentType={'ACCOUNT_AGREEMENT'}
                        documents={documents}
                        onAllDocumentsCheckedChanged={setAllDocumentsChecked}
                    />
                    <Typography>{t('pages-orders.markDocumentsAsDone')}</Typography>
                </>
            </Step>
            <Step stepNumber={2} title={t('pages-orders.bankStep2')} helpText={t('pages-orders.bankStep2HelpText')}>
                <AccountNumberInput accountNumber={toAccount} submitValueOnBlur />
            </Step>
            <Step stepNumber={3} title={t('pages-orders.bankStep3')} helpText={t('pages-orders.bankStep3HelpText')}>
                <Stack spacing={1}>
                    <FileUpload
                        uploadFiles={_uploadAccountAgreement}
                        document={accountAgreementDocument}
                        removeFile={removeFile}
                        isUploadingDocument={isUploadingAccountAgreement}
                        onStartSignature={onStartAccountAgreementSignature}
                        isCheckingSignatureStatus={
                            accountAgreementDocument && lookupSignatureStatus(accountAgreementDocument.id)
                        }
                        multiple={false}
                    />
                    <Box>
                        <p className="field-error-message">{accountAgreementError}</p>
                        {validationText}
                    </Box>
                </Stack>
            </Step>
            <Step stepNumber={4} title={t('pages-orders.bankStep4')} helpText={t('pages-orders.bankStep4HelpText')}>
                <p>{t('pages-orders.bankAccountAgreementCheck')}</p>
            </Step>
            {!auth.bank.hasCustomerServiceRole && (
                <p className="field-error-message">{t('pages-orders.bankAccountAgreementNoAccess')}</p>
            )}
            {documentsNotCheckedError && !allDocumentsChecked && (
                <Alert severity="error" sx={{ boxShadow: 'none' }}>
                    {documentsNotCheckedError}
                </Alert>
            )}
            <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
                <Button
                    data-cy="bankRejectOrderButton"
                    variant={'text'}
                    color={'inherit'}
                    onClick={() => setRejectStepVisible(true)}
                    disabled={!auth.bank.hasCustomerServiceRole}
                >
                    {t('pages-orders.bankRejectButtonText')}
                </Button>
                <Button
                    data-cy="verifyInformation"
                    data-cy-form-ready={allDocumentsChecked && formValidState.formValid}
                    variant={'contained'}
                    onClick={openConfirmation}
                    disabled={!auth.bank.hasCustomerServiceRole}
                >
                    {t('pages-orders.goToVerificationStep')}
                </Button>
            </Stack>
            {rejectStepVisible && (
                <Modal header={t('pages-orders.bankRejectButtonText')} onCancel={() => setRejectStepVisible(false)}>
                    <OrderCancel closeModal={() => setRejectStepVisible(false)} order={order} />
                </Modal>
            )}
            {controlStepVisible && <ControlStep />}
        </Stack>
    )
}
