import { Box, Slider } from '@mui/material'

type Props = {
    minMaxFilter: [number, number]
    setMinMaxFilter: (value: [number, number]) => void
}

export default function MinMaxSlider({ minMaxFilter, setMinMaxFilter }: Props) {
    function handleMinMaxFilterChange(event: Event, newValue: [number, number]) {
        if (!Array.isArray(newValue) || newValue.length !== 2) {
            return
        }

        setMinMaxFilter(newValue)
    }

    return (
        <Box sx={{ pl: 2.4, pr: 3 }}>
            <Slider
                value={minMaxFilter}
                onChange={handleMinMaxFilterChange}
                valueLabelDisplay="auto"
                min={1}
                max={19}
                marks={[
                    { value: 1, label: '1 mill.' },
                    { value: 5, label: '' },
                    { value: 10, label: '10 mill.' },
                    { value: 15, label: '' },
                    { value: 19, label: '19 mill.' },
                ]}
                sx={{ minWidth: '22rem', maxWidth: '25rem', mb: 0 }}
            />
        </Box>
    )
}
