import command from '../command/command'
import format from 'date-fns/format'
import { updateDepositTag } from '#state/reducers/deposits'
import { updateOrderTag } from '#state/reducers/orders'

export const createDeposit = (deposit) => (dispatch) => {
    return command(dispatch).createDeposit(deposit)
}

export const confirmDepositTermination = (depositId) => (dispatch) => {
    return command(dispatch).confirmDepositTermination(depositId)
}

export const confirmInterestRateChange = (depositId) => (dispatch) => {
    return command(dispatch).confirmInterestRateChange(depositId)
}

export const setSettlementAccount = (depositId, settlementAccountId) => (dispatch) => {
    return command(dispatch).setSettlementAccount(depositId, settlementAccountId)
}

export const sendSettlementAccountChangeToBank = (depositId, settlementAccountId) => (dispatch) => {
    return command(dispatch).sendSettlementAccountChangeToBank(depositId, settlementAccountId)
}

export const confirmSettlementAccountChanged = (depositId, settlementAccountId) => (dispatch) => {
    return command(dispatch).confirmSettlementAccountChanged(depositId, settlementAccountId)
}

export const cancelChangeSettlementAccount = (depositId) => (dispatch) => {
    return command(dispatch).cancelChangeSettlementAccount(depositId)
}

export const setTag = (orderId, depositId, text, color) => (dispatch) => {
    depositId && dispatch(updateDepositTag({ depositId, text, color }))
    orderId && dispatch(updateOrderTag({ orderId, text, color }))
    return command(dispatch).setTag({ orderId: orderId || '', depositId: depositId || '', text, color })
}

export const createExtensionOffer = (depositId, interestRate, terminationDate) => (dispatch) => {
    const formattedTerminationDate = format(terminationDate, 'yyyy-MM-dd')
    return command(dispatch).createExtensionOffer(depositId, interestRate, formattedTerminationDate)
}

export const deleteExtensionOffer = (depositId) => (dispatch) => {
    return command(dispatch).deleteExtensionOffer(depositId)
}

export const registerExtension = (depositId) => (dispatch) => {
    return command(dispatch).registerExtension(depositId)
}

export const sendExtensionOfferToDepositor = (depositId) => (dispatch) => {
    return command(dispatch).sendExtensionOfferToDepositor(depositId)
}
