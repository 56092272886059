import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useCommand } from '#command'
import Button from '#components/Button'
import HelpPopup from '#components/HelpPopup'
import { useTranslation } from '#components/i18n'
import { formatAccount } from '#services/formatnumber'
import { DocumentDto, OrderDto } from '@fixrate/fixrate-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './OrderDialog.module.scss'
import SignedDocumentList from './SignedDocumentList'

interface SignedDocumentProps {
    order: OrderDto
    documents: DocumentDto[]
}

export default function SignedDocuments({ order, documents }: SignedDocumentProps) {
    const { t } = useTranslation('pages-orders')
    const navigate = useNavigate()
    const { sendToDepositor } = useCommand()
    const organisationCountry = useCurrentCountryCode()

    const [activated, setActivated] = useState(false)
    const [touched, setTouched] = useState(false)

    async function onCompleteOrderClick() {
        if (activated) {
            const { waitForCommand } = await sendToDepositor(order.id)
            const success = await waitForCommand()
            if (success) {
                navigate('/bank-orders')
            }
        }
    }

    function onChange() {
        setActivated(!activated)
        setTouched(true)
    }

    return (
        <div>
            <dl className="actionframe__labeledinput">
                <dt className="actionframe__labeledinput-label">
                    <HelpPopup text={t('bankCheckSignedAgreementHelpText')}>
                        {t('bankCheckSignedAgreementText')}
                    </HelpPopup>
                </dt>
                <dd className="actionframe__labeledinput-value">
                    <SignedDocumentList
                        documents={documents}
                        documentTypes={['ACCOUNT_AGREEMENT', 'ACCOUNT_TERMINATION']}
                    />
                </dd>
            </dl>

            <dl className="actionframe__labeledinput">
                <dt className="actionframe__labeledinput-label">
                    <HelpPopup text={t('bankDepositorEmailHelpText')}>{t('bankDepositorEmailText')}</HelpPopup>
                </dt>
                <dd className="actionframe__labeledinput-value">
                    <span className={styles.email}>{order.reportEmailAddress}</span>
                </dd>
            </dl>

            <dl className="actionframe__labeledinput">
                <dt className="actionframe__labeledinput-label">
                    <HelpPopup text={t('bankActivateAccountHelpText')}>
                        {t('bankActivateAccountText', { account: formatAccount(order.toAccount, organisationCountry) })}
                    </HelpPopup>
                </dt>
                <dd className="actionframe__labeledinput-value">
                    <label>
                        <input
                            type="checkbox"
                            id="activationConfirm"
                            checked={activated}
                            onChange={onChange}
                            required
                        />
                        {t('accountIsActivated')}
                    </label>
                    {touched && !activated && <p className="field-error-message">{t('bankActivateAccountError')}</p>}
                </dd>
            </dl>

            <Button id="confirmAccountActivationButton" onClick={onCompleteOrderClick}>
                {t('bankActivateAccountButtonText')}
            </Button>
        </div>
    )
}
