import AsyncButton from '#app/components/Button/AsyncButton'
import { useCommand } from '#command'
import { ButtonRow, DateOutput, PageHeader, PageLayout } from '#components'
import Animated from '#components/Animated/Animated'
import { useTranslation } from '#components/i18n'
import DocumentIcon from '#components/icons/DocumentIcon'
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner'
import Paper from '#components/Paper'
import SigningAction from '#components/SignableDocument/SigningAction'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { createElement, Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import styles from './SignatureInbox.module.css'

const SignatureInbox = () => {
    const { t } = useTranslation('pages-signatureinbox')
    const navigate = useNavigate()
    const { startElectronicSignature } = useCommand()
    const [isSigning, setIsSigning] = useState(false)

    const loaded = useSelector((state) => state.loaded)
    const authorizationDocumentsToSign = useSelector(selectors.authorizationDocumentsToSign)

    const documents = authorizationDocumentsToSign
        .slice()
        .sort((d1, d2) => new Date(d2.created).getTime() - new Date(d1.created).getTime())
    const signatureStatus = useSelector((state) => {
        const lookupSignatureStatus = selectors.lookupSignatureStatus(state)
        const status = {}
        documents.forEach((d) => {
            status[d.id] = lookupSignatureStatus(d.id)
        })
        return status
    })
    const documentsToSignIds = authorizationDocumentsToSign.filter((doc) => doc.userCanSign).map((doc) => doc.id)
    const documentsToSignCount = documentsToSignIds.length

    async function startSignature(document) {
        setIsSigning(true)
        const processId = uuidv4()
        try {
            await startElectronicSignature(processId, 'SINGLE_DOCUMENT', document.id)
            navigate(`/signature/${processId}?context=/sign`)
        } catch (err) {
            console.error(err)
        } finally {
            setIsSigning(false)
        }
    }

    async function signAll() {
        setIsSigning(true)
        const processId = uuidv4()
        try {
            await startElectronicSignature(processId, 'MULTIPLE_DOCUMENTS', documentsToSignIds.join(','))
            navigate(`/signature/${processId}?context=/sign`)
        } catch (err) {
            console.error(err)
        } finally {
            setIsSigning(false)
        }
    }

    return (
        <Fragment>
            <PageHeader title={t('authorizationsToSign')} />
            <PageLayout>
                {!loaded.documents && <LoadingSpinner text={t('loadingDocuments')} />}
                <div className={styles.list}>
                    {documentsToSignCount > 1 && (
                        <Fragment>
                            <ButtonRow className={styles.buttonRow}>
                                <AsyncButton disabled={isSigning} onClick={signAll}>
                                    {t('signAll')}
                                </AsyncButton>
                            </ButtonRow>
                        </Fragment>
                    )}

                    {loaded.documents && documents.length === 0 && <p>{t('noDocuments')}</p>}
                    {documents.map((document) => (
                        <Animated key={document.id} className={classNames(styles.listElement)}>
                            <Paper title={document.name}>
                                <div>
                                    <p className={styles.infoText}>
                                        {t('documentInfo', {
                                            date: DateOutput.formatVerboseMonth(document.created),
                                        })}
                                    </p>
                                </div>

                                <div className={styles.documentBase}>
                                    {document.signedByUser &&
                                        createElement(document.signedByAll ? DocumentIcon : DocumentIcon, {
                                            className: styles.icon,
                                            style: { fill: '#336699' },
                                        })}
                                    {document.signedByUser && (
                                        <div className={styles.documentDownload}>
                                            <span className={styles.titleText}>
                                                <Link to={document.id}>{t('viewAuthorization')}</Link>
                                            </span>
                                        </div>
                                    )}
                                    <span className={styles.signText}>
                                        <SigningAction
                                            disabled={isSigning}
                                            isCheckingSignatureStatus={signatureStatus[document.id]}
                                            document={document}
                                            signText={t('readAndSignAuthorization')}
                                            onStartSignature={() => startSignature(document)}
                                        />
                                    </span>
                                </div>
                            </Paper>
                        </Animated>
                    ))}
                </div>
            </PageLayout>
        </Fragment>
    )
}

export default SignatureInbox
