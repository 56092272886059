import alfredBergLogo from './images/alfred-berg@2x.jpg'
import amundiLogo from './images/amundi@2x.jpg'
import danskeInvestLogo from './images/danske-invest@2x.jpg'
import holbergLogo from './images/holberg@2x.jpg'
import landkredittLogo from './images/landkreditt@2x.jpg'
import odinLogo from './images/odin@2x.jpg'
import storebrandLogo from './images/storebrand@2x.jpg'
import dnbLogo from './images/dnb@2x.jpg'
import plussLogo from './images/pluss-fondene@2x.jpg'
import arcticLogo from './images/arctic@2x.jpg'
import firstLogo from './images/first-fondene@2x.jpg'
import paretoLogo from './images/pareto@2x.jpg'
import klpLogo from './images/klp@2x.jpg'
import nordeaLogo from './images/nordea@2x.jpg'
import handelsbankenLogo from './images/handelsbanken@2x.jpg'

import alfredBergLogoSq from './images/squared/alfred-berg@2x.png'
import amundiLogoSq from './images/squared/amundi@2x.png'
import danskeInvestLogoSq from './images/squared/danske-invest@2x.png'
import holbergLogoSq from './images/squared/holberg@2x.png'
import landkredittLogoSq from './images/squared/landkreditt@2x.png'
import odinLogoSq from './images/squared/odin@2x.png'
import storebrandLogoSq from './images/squared/storebrand@2x.png'
import dnbLogoSq from './images/squared/dnb@2x.png'
import plussLogoSq from './images/pluss-fondene@2x.jpg'
import arcticLogoSq from './images/squared/arctic@2x.png'
import firstLogoSq from './images/first-fondene@2x.jpg'
import paretoLogoSq from './images/pareto@2x.jpg'
import klpLogoSq from './images/squared/klp@2x.png'
import handelsbankenLogoSq from './images/squared/handelsbanken@2x.png'
import nordeaLogoSq from './images/squared/nordea@2x.png'
import skagenLogoSq from './images/squared/skagen@2x.png'
import skagenLogo from './images/skagen@2x.jpg'

// TODO: Should be moved to admin
// The logos is exported from Figma – do not add logos without design consultation. Same sizes and format needs to be preserved.
const logos = [
    { name: 'alfred berg', logo: alfredBergLogo, logoSq: alfredBergLogoSq },
    { name: 'skagen', logo: skagenLogo, logoSq: skagenLogoSq },
    { name: 'amundi', logo: amundiLogo, logoSq: amundiLogoSq },
    { name: 'arctic', logo: arcticLogo, logoSq: arcticLogoSq },
    { name: 'dnb', logo: dnbLogo, logoSq: dnbLogoSq },
    { name: 'danske invest', logo: danskeInvestLogo, logoSq: danskeInvestLogoSq },
    { name: 'holberg', logo: holbergLogo, logoSq: holbergLogoSq },
    { name: 'landkreditt', logo: landkredittLogo, logoSq: landkredittLogoSq },
    { name: 'odin', logo: odinLogo, logoSq: odinLogoSq },
    { name: 'storebrand', logo: storebrandLogo, logoSq: storebrandLogoSq },
    { name: 'pareto', logo: paretoLogo, logoSq: paretoLogoSq },
    { name: 'klp', logo: klpLogo, logoSq: klpLogoSq },
    { name: 'nordea', logo: nordeaLogo, logoSq: nordeaLogoSq },
    { name: 'handelsbanken', logo: handelsbankenLogo, logoSq: handelsbankenLogoSq },
    { name: 'first', logo: firstLogo, logoSq: firstLogoSq },
    { name: 'pluss', logo: plussLogo, logoSq: plussLogoSq },
]

export const FundLogo = ({ fundName, squared = false }: { fundName: string; squared?: boolean }) => {
    if (!fundName) {
        return null
    }
    const data = logos.find((logo) => fundName?.toLowerCase().includes(logo.name))
    const logo = squared ? data?.logoSq : data?.logo
    return logo ? <img src={logo} alt={''} /> : null
}
