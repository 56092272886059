import { LoadingSpinner } from '#app/components'
import { useSelector } from '#app/state/useSelector'
import { useParams } from 'react-router-dom'
import ValueReport from '../ValueReport'

const CustomerValueReport = () => {
    const { depositorId } = useParams()
    const partner = useSelector((state) => state.partner)
    const customer = partner?.customers.find((c) => c.depositorId === depositorId)
    const prospect = partner?.prospects.find((p) => p.nationalIdentity === customer.nationalIdentity)

    return customer ? (
        <ValueReport
            depositorId={depositorId}
            depositorName={customer.name}
            registrationDate={customer.fullyRegisteredAt}
            initialPreviousInterestRate={prospect?.currentInterest}
            backLink={'/customer/' + depositorId}
        />
    ) : (
        <LoadingSpinner />
    )
}

export { CustomerValueReport }
