import { BURNT_ORANGE, PURPLE } from '#app/colors/colors'
import AsyncButton from '#app/components/Button/AsyncButton'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import FxIllustrationButton from '#app/components/FxIllustrationButton/FxIllustrationButton'
import RegisterCustomer from '#app/pages/Customers/RegisterCustomer/RegisterCustomer'
import { useCommand } from '#app/services/beta'
import { switchOrganisation } from '#app/services/thunks/session'
import { useSelector } from '#app/state/useSelector'
import { DateOutput, InterestOutput } from '#components'
import { OrganisationalPersonDto, PartnerCustomerDto } from '@fixrate/fixrate-query'
import { OrganisationType } from '@fixrate/fixrate-security'
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grow,
    InputLabel,
    LinearProgress,
    ListItemIcon,
    MenuItem,
    Popover,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import AdCard from '../AdCard/AdCard'
import AddEmailBirthdateDialog from './AddEmailBirthdateDialog'
import BrregSignatories from './BrregSignatories'
import { proposalLinkText, proposalMessageText, proposalSubject } from './proposalUtils'
import SuggestedMessage from './SuggestedMessage'

type Params = {
    proposalAdId?: string
}

export default function SendPartnerProposal() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { proposalAdId } = useParams<Params>()
    const dispatch = useDispatch()
    const ad = useSelector((state) => state.ads.find((ad) => ad.id === proposalAdId))
    const session = useSelector((state) => state.session)
    const product = useSelector((state) => state.products[ad?.productId])
    const partner = useSelector((state) => state.partner)
    const banks = useSelector((state) => state.banks)
    const customers = useSelector(
        (state) =>
            state.partner?.customers?.filter((customer) =>
                customer.advisors.find((advisor) => advisor === session.id)
            ) ?? []
    )
    const [wizardStep, setWizardStep] = useState(1)
    const [registerCustomer, setRegisterCustomer] = useState(false)
    const [customerId, setCustomerId] = useState('')
    const [hasSigningRight, setHasSigningRight] = useState(false)
    const [receiverId, setReceiverId] = useState('')
    const [linkText, setLinkText] = useState<string | null>(null)
    const [signers, setSigners] = useState<OrganisationalPersonDto[]>([])
    const [emailPersonId, setEmailPersonId] = useState<string>('')
    const [showEmailBirthdateModal, setShowEmailBirthdateModal] = useState(false)
    const [showEmailMissing, setShowEmailMissing] = useState(false)
    const showOfferCard = false
    const backUrl = '/marketplace'
    const selectedCustomer = customers.find((c) => c.depositorId === customerId)
    const LINK_PREFIX = window.location.origin
    const { createPartnerProposal } = useCommand()
    const bankName = banks ? banks[ad?.bankId]?.name : ''
    const [buttonAnchor, setButtonAnchor] = useState<HTMLButtonElement | null>(null)

    const authorizationDocumentSigned = selectedCustomer?.users?.find(
        (a) => a.id === session.id
    )?.hasSignedAuthorizationDocument

    const availableReceivers =
        customers
            ?.find((c) => c?.depositorId === customerId)
            ?.people?.filter((p) => p.associatedUserId !== session.id) ?? []
    const allReceveiversHasEmail = availableReceivers
        ?.filter((receiver) => signers?.find((s) => s.personId === receiver.personId))
        ?.every((s) => s.email)
    const allReceveiversHasBirthDates = availableReceivers
        ?.filter((receiver) => signers?.find((s) => s.personId === receiver.personId))
        ?.every((s) => s.birthDate)

    const personName = (personId: string) => {
        const person = customers.find((c) => c?.depositorId === customerId)?.people.find((p) => p.personId === personId)
        return person?.firstName + ' ' + person?.lastName
    }

    const getPersonFromId = (personId: string) => {
        const person = customers.find((c) => c?.depositorId === customerId)?.people.find((p) => p.personId === personId)
        return person
    }

    const createProposal = async () => {
        const person = getPersonFromId(receiverId)

        if (authorizationDocumentSigned && !allReceveiversHasEmail) {
            openEmailModal(receiverId)
            return
        }
        if (allReceveiversHasEmail) {
            const proposalId = uuidv4()
            const inviteId =
                !person.associatedUserId && !person.associatedInviteId ? uuidv4() : (person.associatedInviteId ?? null)
            const signerIds = signers.map((s) => s.personId).filter((pId) => pId !== receiverId)
            const { waitForCommand } = await createPartnerProposal(
                partner.id,
                customerId,
                receiverId,
                ad.id,
                proposalId,
                inviteId,
                signerIds
            )
            const success = await waitForCommand()
            if (success) {
                setLinkText(proposalLinkText(LINK_PREFIX, partner.id, proposalId, inviteId))
                setWizardStep(2)
            }
        } else {
            setShowEmailMissing(true)
        }
    }

    function openEmailModal(personId: string) {
        setEmailPersonId(personId)
        setShowEmailBirthdateModal(true)
    }

    function logInToCustomer(organisationType: OrganisationType, orgId: string) {
        dispatch(switchOrganisation(organisationType, orgId, () => navigate('/organizations')))
    }

    function addReceiver(id: string) {
        setReceiverId(id)
        const person = getPersonFromId(id)

        if (signers.length === 1) {
            setSigners([person])
        } else if (!signers.find((s) => s.personId === person.personId)) {
            setSigners([...signers, person])
        }
    }

    function addSigner(personId: string) {
        if (personId) {
            setSigners([...signers, getPersonFromId(personId)])
            setButtonAnchor(null)
        }
    }

    function closeCustomerExistsDialog() {
        setCustomerId('')
        navigate(backUrl)
    }

    const wizardSteps = [
        {
            step: 1,
            content:
                !selectedCustomer && customerId ? (
                    <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={true}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : (
                    <Stack alignItems="flex-start" spacing={2}>
                        <Box>
                            <InputLabel>{t('layers-sendProposal.selectCompany')}</InputLabel>
                            <Select
                                value={customerId}
                                data-cy="select-company"
                                onChange={(e) => {
                                    setCustomerId(e.target.value)
                                    setReceiverId('')
                                }}
                                sx={{
                                    '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                                    '& .MuiSelect-select .MuiListItemIcon-root': {
                                        minHeight: '0',
                                        minWidth: '0',
                                    },
                                }}
                            >
                                {customers
                                    ?.sort((a, b) => (b.accepted ? 1 : 0) - (a.accepted ? 1 : 0))
                                    ?.sort((a, b) => (b.fullyRegisteredAt ? 1 : 0) - (a.fullyRegisteredAt ? 1 : 0))
                                    .map((c) => (
                                        <MenuItem
                                            key={c.depositorId}
                                            disabled={c.accepted !== true || c.fullyRegisteredAt === null}
                                            divider
                                            value={c.depositorId}
                                        >
                                            <ListItemIcon sx={{ backgroundColor: 'transparent' }}>
                                                {c.accepted !== true ? (
                                                    <i className="ri-mail-line" />
                                                ) : c.fullyRegisteredAt ? (
                                                    <i className="ri-building-line" />
                                                ) : (
                                                    <i className="ri-error-warning-line" />
                                                )}
                                            </ListItemIcon>
                                            <Stack justifyContent="center">
                                                {c.name}
                                                {c.accepted !== true && (
                                                    <Typography variant="caption">
                                                        {t('layers-sendProposal.awaitingApproval')}
                                                    </Typography>
                                                )}
                                                {c.fullyRegisteredAt === null && (
                                                    <Typography variant="caption">
                                                        {t('common.notRegistered')}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Box>
                        {customerId && (
                            <Stack spacing={3}>
                                <Stack spacing={1}>
                                    <Box>
                                        <InputLabel sx={{ m: 0 }}>{t('layers-sendProposal.selectReceiver')}</InputLabel>
                                        <Typography variant="labelDescription">
                                            {t('layers-sendProposal.receiverDescription')}
                                        </Typography>
                                    </Box>
                                    {availableReceivers?.length > 0 ? (
                                        <Box>
                                            <Select
                                                data-cy="select-receiver"
                                                value={receiverId}
                                                onChange={(e) => addReceiver(e.target.value)}
                                            >
                                                {availableReceivers?.map((person) => (
                                                    <MenuItem key={person.personId} value={person.personId}>
                                                        {person.firstName} {person.lastName}{' '}
                                                        {person.email ? `(${person.email})` : ''}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={hasSigningRight}
                                                        onChange={(e) => setHasSigningRight(e.target.checked)}
                                                    />
                                                }
                                                data-cy="has-signing-right-check"
                                                sx={{ mt: 1 }}
                                                label={t('layers-sendProposal.receiverHasSigningRight')}
                                            />
                                        </Box>
                                    ) : (
                                        <Alert
                                            severity="warning"
                                            action={
                                                <Button
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() =>
                                                        logInToCustomer('DEPOSITOR', selectedCustomer.depositorId)
                                                    }
                                                >
                                                    {t('common.login')}
                                                </Button>
                                            }
                                        >
                                            <AlertTitle>{t('layers-sendProposal.noRecepientsFound')}</AlertTitle>
                                            {t('layers-sendProposal.noRecepientsFoundDescription')}
                                        </Alert>
                                    )}

                                    {!authorizationDocumentSigned && (
                                        <Box>
                                            <InputLabel sx={{ m: 0 }}>{t('layers-sendProposal.signers')}</InputLabel>
                                            <Typography variant="labelDescription">
                                                {t('layers-sendProposal.whoIsGoingToSign')}
                                            </Typography>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t('common.name')}</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>{t('common.email')}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {availableReceivers
                                                        ?.filter((receiver) =>
                                                            signers?.find((s) => s.personId === receiver.personId)
                                                        )
                                                        ?.map((signer, index) => (
                                                            <TableRow
                                                                data-cy={`userName-${signer.firstName?.replace(' ', '_')}`}
                                                                sx={{ height: '5rem' }}
                                                                key={signer.personId}
                                                            >
                                                                <TableCell
                                                                    sx={{ fontSize: '1.4rem', fontWeight: '600' }}
                                                                >
                                                                    <Stack
                                                                        alignItems="center"
                                                                        direction="row"
                                                                        spacing={0.5}
                                                                    >
                                                                        <i className="ri-user-line" />
                                                                        <span>
                                                                            {signer.firstName} {signer.lastName}
                                                                        </span>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {!signer.birthDate && (
                                                                        <Button
                                                                            data-cy="add-signer-birthdate"
                                                                            color="primary"
                                                                            size="small"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                                openEmailModal(signer.personId)
                                                                            }
                                                                        >
                                                                            {t('layers-sendProposal.addBirthdate')}
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {signer.email ? (
                                                                        <Button
                                                                            data-cy="signer-email"
                                                                            color="primary"
                                                                            startIcon={<i className="ri-mail-line" />}
                                                                            size="small"
                                                                            onClick={() =>
                                                                                openEmailModal(signer.personId)
                                                                            }
                                                                        >
                                                                            {signer.email}
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            data-cy="add-signer-email"
                                                                            color="primary"
                                                                            size="small"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                                openEmailModal(signer.personId)
                                                                            }
                                                                        >
                                                                            {t('common.addEmail')}
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {signer.personId !== receiverId && (
                                                                        <Button
                                                                            color="error"
                                                                            size="small"
                                                                            variant="outlined"
                                                                            startIcon={
                                                                                <i className="ri-delete-bin-line" />
                                                                            }
                                                                            onClick={() =>
                                                                                setSigners(
                                                                                    signers.filter(
                                                                                        (s) =>
                                                                                            signer.personId !==
                                                                                            s.personId
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            {t('common.remove')}
                                                                        </Button>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                            {allReceveiversHasEmail === false && showEmailMissing && (
                                                <Alert severity="error" sx={{ mt: 1 }}>
                                                    {t('layers-sendProposal.missingSignatures')}
                                                </Alert>
                                            )}
                                            {allReceveiversHasBirthDates === false && (
                                                <Alert severity="error" sx={{ mt: 1 }}>
                                                    {t('layers-sendProposal.missingBirthDates')}
                                                </Alert>
                                            )}
                                            <Button
                                                onClick={(e) => setButtonAnchor(e.currentTarget)}
                                                aria-describedby="signer-popover"
                                                sx={{ mt: 2 }}
                                                variant="outlined"
                                                data-cy="add-signer"
                                                size="small"
                                            >
                                                {t('layers-sendProposal.addSigner')}
                                            </Button>
                                            <Popover
                                                anchorEl={buttonAnchor}
                                                id="signer-popover"
                                                open={!!buttonAnchor}
                                                onClose={() => setButtonAnchor(null)}
                                                sx={{
                                                    '& .MuiPopover-paper': { backgroundColor: PURPLE[900], mt: 0.5 },
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <Stack p={2} spacing={1} color={PURPLE[50]} maxWidth={'28rem'}>
                                                    {availableReceivers?.filter(
                                                        (receiver) =>
                                                            !signers?.some((s) => s.personId === receiver?.personId)
                                                    )?.length > 0 ? (
                                                        <Select
                                                            data-cy="select-signer"
                                                            placeholder="Velg signatar"
                                                            size="small"
                                                            onChange={(e, val) => addSigner(e.target.value as string)}
                                                        >
                                                            {availableReceivers
                                                                ?.filter(
                                                                    (receiver) =>
                                                                        !signers?.some(
                                                                            (s) => s.personId === receiver?.personId
                                                                        )
                                                                )
                                                                ?.map((person) => (
                                                                    <MenuItem
                                                                        key={person.personId}
                                                                        value={person.personId}
                                                                    >
                                                                        {person.firstName} {person.lastName}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    ) : (
                                                        <Grow in={true} timeout={1000}>
                                                            <Alert
                                                                sx={{ '& .MuiAlert-message': { fontSize: '1.2rem' } }}
                                                                icon={false}
                                                                severity="warning"
                                                            >
                                                                {t('layers-sendProposal.noPersonsCanBeAdded')}
                                                            </Alert>
                                                        </Grow>
                                                    )}
                                                    <Button
                                                        color="inherit"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => setButtonAnchor(null)}
                                                    >
                                                        {t('common.close')}
                                                    </Button>
                                                </Stack>
                                            </Popover>
                                        </Box>
                                    )}
                                    <BrregSignatories depositorId={selectedCustomer?.depositorId} />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                ),
            actions: (
                <FxDialogActions>
                    <AsyncButton
                        variant="contained"
                        data-cy="create-proposal"
                        disabled={!customerId || !receiverId || !hasSigningRight}
                        onClick={() => createProposal()}
                    >
                        {t('common.continue')}
                    </AsyncButton>
                </FxDialogActions>
            ),
        },
        {
            step: 2,
            content: (
                <SuggestedMessage
                    linkText={linkText}
                    subject={proposalSubject}
                    messageText={proposalMessageText(ad?.interest, bankName, linkText)}
                    receiverName={personName(receiverId)}
                />
            ),
            actions: (
                <FxDialogActions>
                    <Button onClick={() => navigate(backUrl)}>Lukk vindu</Button>
                    <Button
                        variant="contained"
                        data-cy="go-to-proposal-overview"
                        onClick={() => navigate('/partner/proposals/sent')}
                    >
                        {t('layers-sendProposal.goToSuggestions')}
                    </Button>
                </FxDialogActions>
            ),
        },
    ]

    if (!ad || !product) {
        return null
    }

    return (
        <>
            <Fragment>
                {selectedCustomer?.accepted === null ? (
                    <FxDialog maxWidth="sm" open={true} onClose={() => navigate(backUrl)}>
                        <FxDialogTitle onClose={closeCustomerExistsDialog}>
                            <Typography variant={'h3'}>{t('layers-sendProposal.awaitingApproval')}</Typography>
                        </FxDialogTitle>
                        <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ backgroundColor: BURNT_ORANGE[50] }}
                        >
                            <Box maxWidth={'35rem'}>
                                <FixrateIllustration name="emailSent" color="default" />
                            </Box>
                        </Stack>
                        <FxDialogContent>
                            <Typography>{t('layers-sendProposal.awaitingApprovalDescription')}</Typography>
                        </FxDialogContent>
                        <FxDialogActions>
                            <Button
                                startIcon={<i className="ri-arrow-left-line" />}
                                sx={{ mr: 'auto' }}
                                onClick={closeCustomerExistsDialog}
                            >
                                {t('common.goBack')}
                            </Button>
                        </FxDialogActions>
                    </FxDialog>
                ) : (
                    <FxDialog open={true} onClose={() => navigate(backUrl)} fullWidth>
                        <FxDialogTitle onClose={() => navigate(backUrl)}>
                            <Stack>
                                <Typography variant={'h3'}>{t('layers-sendProposal.sendProposal')}</Typography>
                                <Typography fontSize="1.4rem" component="p" variant="subtitle2">
                                    {banks[ad.bankId]?.name} ({InterestOutput.format(ad.interest)})
                                </Typography>
                            </Stack>
                        </FxDialogTitle>
                        {showOfferCard && (
                            <Stack
                                sx={{ backgroundColor: PURPLE[50] }}
                                p={{ xs: 1, md: 4 }}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Box sx={{ transform: { xs: 'scale(0.8)', md: 'scale(1)' } }}>
                                    <AdCard ad={ad} preview={true} product={product} onAdClick={null} />
                                </Box>
                            </Stack>
                        )}
                        {product.type === 'FIXED' && (
                            <Alert severity="info" sx={{ width: '60rem', margin: '0 auto', pl: 2.6 }}>
                                {t('pages-marketplace.paymentDate')}: {DateOutput.formatVerboseMonth(ad.termination)}
                            </Alert>
                        )}
                        {showOfferCard || product.type === 'FIXED' ? <Divider /> : <Box />}
                        <FxDialogContent>{wizardSteps?.find((s) => s.step === wizardStep)?.content}</FxDialogContent>
                        {wizardSteps?.find((s) => s.step === wizardStep)?.actions}
                        {showEmailBirthdateModal && (
                            <AddEmailBirthdateDialog
                                open={showEmailBirthdateModal}
                                setOpen={setShowEmailBirthdateModal}
                                customerId={customerId}
                                personId={emailPersonId}
                            />
                        )}
                    </FxDialog>
                )}
            </Fragment>
        </>
    )
}
