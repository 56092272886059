import { useDispatch } from 'react-redux'
import { useSelector } from '#state/useSelector'
import * as infoMessageActions from '#state/reducers/infoMessages'
import styles from './InfoMessages.module.scss'
import { createPortal } from 'react-dom'
import { Animated } from '#components'
import { v4 as uuidv4 } from 'uuid'
import { Alert, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function InfoMessages() {
    const dispatch = useDispatch()
    const infoMessages = useSelector((state) => state.infoMessages)
    const infoMessageKeys = useSelector((state) => Object.keys(state.infoMessages))
    const { t } = useTranslation()

    function removeInfoMessage(key) {
        dispatch(infoMessageActions.removeInfoMessage(key))
    }

    const infoMessageItems = infoMessageKeys.map((key) => {
        const infoMessage = infoMessages[key]

        let infoMessageDetailElement = null
        if (infoMessage.detail) {
            infoMessageDetailElement = <div className={styles.detail}>{infoMessage.detail}</div>
        }

        let infoMessageTechMsgElement = null
        if (infoMessage.technicalDetail) {
            infoMessageTechMsgElement = <div className={styles.techMsg}>{infoMessage.technicalDetail}</div>
        }

        const counter = infoMessage.count > 1 && '(' + infoMessage.count + ')'

        return (
            <Animated key={key} tag="li">
                <Alert
                    severity={infoMessage.type === 'error' ? 'error' : 'info'}
                    sx={{ width: '100%', boxShadow: '0.1rem 0.1rem 0.3rem rgba(0,0,0,0.3)' }}
                    action={
                        <Button color="inherit" size="small" onClick={() => removeInfoMessage(key)}>
                            {t('common.close')}
                        </Button>
                    }
                >
                    {infoMessage.text} {counter}
                    {infoMessageDetailElement}
                    {infoMessageTechMsgElement}
                </Alert>
            </Animated>
        )
    })

    if (infoMessageKeys.length < 1) {
        return null
    }

    return createPortal(
        <div className={styles.body}>
            <ul>{infoMessageItems}</ul>
        </div>,
        document.body
    )
}

export function useInfoMessages() {
    const dispatch = useDispatch()

    function show(type, message, detail, technicalDetail, selfRemoving) {
        const id = uuidv4()
        dispatch(infoMessageActions.addInfoMessage({ type, message, detail, technicalDetail, id }))
        if (selfRemoving) {
            setTimeout(() => {
                dispatch(infoMessageActions.removeInfoMessage(id))
            }, 8000)
        }
    }

    function showInfoMessage({
        type,
        message,
        detail = undefined,
        technicalDetail = undefined,
        selfRemoving = false,
        delay = 0,
    }) {
        if (delay) {
            const timeoutId = setTimeout(() => show(type, message, detail, technicalDetail, selfRemoving), delay)
            return () => clearTimeout(timeoutId)
        } else {
            setTimeout(() => show(type, message, detail, technicalDetail, selfRemoving), delay)
        }
    }

    function clearInfoMessages() {
        dispatch(infoMessageActions.clearInfoMessages({}))
    }

    return {
        showInfoMessage,
        clearInfoMessages,
    }
}
