import React from 'react'
import {
    Box,
    Typography,
    ListItem,
    ListItemText,
    List,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'

import { DateOutput, LoadingSpinner, PageHeader, PageLayout, Paper } from '#app/components'
import { FundCustomerDto } from '@fixrate/fixrate-query'
import { ExpandMore } from '@mui/icons-material'

const FundCustomerFollowUpOverview: React.FC = () => {
    const { t } = useTranslation()
    const customer = useSelector((state) => state.fundCustomers)
    let groupedFollowUps: { [key: string]: FundCustomerDto[] } = {}

    if (customer) {
        const now = new Date()
        const sixMonthsFromNow = new Date()
        sixMonthsFromNow.setMonth(now.getMonth() + 6)

        const filteredFollowUps = customer.filter((followUp) => {
            const followUpDate = new Date(followUp.fundCustomerFollowUp.followUpDate)
            return followUpDate >= now && followUpDate <= sixMonthsFromNow
        })

        groupedFollowUps = filteredFollowUps.reduce(
            (acc: { [key: string]: FundCustomerDto[] }, followUp: FundCustomerDto) => {
                const date = followUp.fundCustomerFollowUp.followUpDate.split('T')[0]
                if (!acc[date]) {
                    acc[date] = []
                }
                acc[date].push(followUp)
                return acc
            },
            {}
        )
    } else {
        return <LoadingSpinner />
    }

    const sortedDates = Object.keys(groupedFollowUps).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

    return (
        <>
            <PageHeader title={t('pages.fundCustomers.customerDetail.customerFollowUpOverview.title')} />
            <PageLayout>
                <Paper title={t('pages.fundCustomers.customerDetail.customerFollowUpOverview.title')}>
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {t('pages.fundCustomers.customerDetail.customerFollowUpOverview.upcomingFollowUps')}
                        </Typography>
                        {sortedDates.map((date) => (
                            <Accordion key={date}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography>
                                        {DateOutput.formatDate(date)} ({groupedFollowUps[date].length})
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {groupedFollowUps[date].map((followUp) => (
                                            <ListItem key={followUp.depositorId}>
                                                <ListItemText
                                                    primary={followUp.name}
                                                    secondary={`${t(`enum-RiskMitigationLevel.${followUp.fundCustomerFollowUp.riskMitigationMeasure}`)} - ${t(`enum-RiskLevel.${followUp.fundCustomerFollowUp.fundCustomerRiskClass}`)}`}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Paper>
            </PageLayout>
        </>
    )
}

export default FundCustomerFollowUpOverview
