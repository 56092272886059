import { getDepositorVolumePerDay, getEarningsForDepositor } from '#services/thunks/statistics'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { StaggData } from '#state/stagg'
import { format } from 'date-fns'

export interface Earnings {
    totalEarnings: number
    earningsOverSSB: number
    previousInterestEarnings?: number
}

interface StaggEarningsData {
    totalEarnings: number
    earnings: number
}

function getRangeString(startDate: Date, endDate: Date) {
    return `?startDate=${format(startDate, 'yyyy-MM-dd')}&endDate=${format(endDate, 'yyyy-MM-dd')}`
}

function accumulated(values: [string, number, number?][]): [string, number, number?][] {
    return values.reduce((acc: [string, number, number?][], val) => {
        let accumulated = val[1]
        if (acc.length > 0) {
            accumulated += acc[acc.length - 1][1]
        }
        acc.push([val[0], accumulated])
        return acc
    }, [])
}

export function useDepositorInterestEarnings(
    depositorId: string,
    startDate: Date,
    endDate: Date,
    previousInterestRate?: number
) {
    const dispatch = useDispatch<(arg0: unknown) => Promise<unknown>>()

    const {
        data: earningsData,
        isLoading: earningsIsLoading,
        isError: earningsIsError,
    } = useQuery(['depositorEarnings', depositorId, startDate, endDate], async () => {
        return await dispatch(getEarningsForDepositor(depositorId, getRangeString(startDate, endDate)))
    })

    const {
        data: volumeData,
        isLoading: volumeIsLoading,
        isError: volumeIsError,
    } = useQuery(['depositorVolume', depositorId, startDate, endDate], async () => {
        return await dispatch(getDepositorVolumePerDay(depositorId, getRangeString(startDate, endDate)))
    })

    const calculateHistoricInterestEarnings = () => {
        if (!previousInterestRate) return null

        if (!volumeData) return null

        const volume = volumeData as StaggData
        const accumulatedInterestPerDay = accumulated(
            volume[0].series[0].values.map((val) => {
                const deposit = val[1] * 1_000_000 // Deposit is in millions
                const rate = previousInterestRate / 100 // Rate is in percentage
                return [val[0], (deposit * rate) / 365]
            })
        )
        return accumulatedInterestPerDay[accumulatedInterestPerDay.length - 1][1]
    }

    const earnings: Earnings = {
        totalEarnings: (earningsData as StaggEarningsData)?.totalEarnings ?? null,
        earningsOverSSB: (earningsData as StaggEarningsData)?.earnings ?? null,
        previousInterestEarnings: calculateHistoricInterestEarnings(),
    }

    return {
        isLoading: earningsIsLoading || volumeIsLoading,
        isError: earningsIsError || volumeIsError,
        earnings,
    }
}
