import AsyncButton from '#app/components/Button/AsyncButton'
import { useTranslation } from '#components/i18n'
import OnboardingFrame from '#components/OnboardingFrame'
import { logout, unlockSession } from '#services/thunks/session'
import { useSelector } from '#state/useSelector'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTracking } from 'react-tracking'
import styles from './Pin.module.css'
import { GetState } from '#state/types'

function Pin() {
    const dispatch = useDispatch<(arg0: (dispatch, getState: GetState) => unknown) => Promise<unknown>>()
    const [pin, setPin] = useState('')
    const session = useSelector((state) => state.session)
    const { t } = useTranslation('layers-Pin')
    const { trackEvent } = useTracking({ page: 'Pin', session })
    const navigate = useNavigate()

    const onSubmitForm = () => {
        dispatch(unlockSession(pin)).then((success) => {
            trackEvent({ event: 'unlockSession' })
        })
    }

    const doLogout = () => {
        dispatch(logout(navigate))
    }

    return (
        <OnboardingFrame header={session.firstName + ' ' + session.lastName} cancel="/marketplace">
            <p>{t('message')}</p>

            <dl className={styles.labelInput}>
                <dt className="label">
                    <label htmlFor="input-pin">{t('pinCode')}</label>
                </dt>
                <dd>
                    <input
                        id="input-pin"
                        type="password"
                        className="text-input"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                    />
                </dd>
            </dl>

            <p className={styles.submit}>
                <AsyncButton id="pinExitButton" onClick={doLogout} variant={'outlined'}>
                    {t('logOut')}
                </AsyncButton>
                <AsyncButton id="unlockButton" variant={'contained'} onClick={onSubmitForm}>
                    {t('unlock')}
                </AsyncButton>
            </p>
        </OnboardingFrame>
    )
}
export default Pin
