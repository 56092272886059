import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useSelector } from '#state/useSelector'
import { useLocalStorage } from '#services/LocalStorageContext'

const useFundShareClassSelected = (
    fundId: string,
    initialFundShareClassId: string
): [string, Dispatch<SetStateAction<string>>] => {
    const {
        keys: { shareClassForFund },
        dispatchLocalStorage,
    } = useLocalStorage()

    const fundClassId = shareClassForFund[fundId] || initialFundShareClassId

    const setFundClassId = useCallback(
        (shareClassId: string) => {
            dispatchLocalStorage({ type: 'SET_SHARE_CLASS_FOR_FUND', payload: { fundId, shareClassId } })
        },
        [dispatchLocalStorage, fundId]
    )

    const availableFunds = useSelector((state) => {
        if (state.loaded.funds) {
            return state.funds
        }
        return null
    })

    useEffect(() => {
        if (availableFunds === null) {
            return
        }
        const fund = availableFunds.find((fund) => fund.id === fundId)
        if (!fund) {
            return
        }
        if (fund.fundShareClasses.find((shareClass) => shareClass.id === fundClassId)) {
            return
        }
        setFundClassId(fund.fundShareClasses[0].id)
    }, [availableFunds, fundClassId, fundId, setFundClassId])

    return [fundClassId, setFundClassId]
}

export default useFundShareClassSelected
