import { useState, cloneElement, ReactElement } from 'react'
import { Box, ClickAwayListener, Grow, Paper, Popper, PopperPlacementType, useTheme } from '@mui/material'

interface Props {
    content: ReactElement
    children: ReactElement
    open: boolean
    onClose?: () => void
    arrow?: boolean
    placement?: PopperPlacementType
}

export default function RichTooltip({
    content,
    children,
    open,
    onClose = () => 0,
    arrow = true,
    placement = 'bottom',
}: Props) {
    const theme = useTheme()
    const classes = {
        popoverRoot: {
            backgroundColor: theme.palette.background.paper,
            maxWidth: 1000,
        },
        popper: {
            zIndex: 2000,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: '-0.71em',
                marginLeft: 4,
                marginRight: 4,
                '&::before': {
                    transformOrigin: '0 100%',
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: '-0.71em',
                marginLeft: 4,
                marginRight: 4,
                '&::before': {
                    transformOrigin: '100% 0',
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: '-0.71em',
                height: '1em',
                width: '0.71em',
                marginTop: 4,
                marginBottom: 4,
                '&::before': {
                    transformOrigin: '100% 100%',
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: '-0.71em',
                height: '1em',
                width: '0.71em',
                marginTop: 4,
                marginBottom: 4,
                '&::before': {
                    transformOrigin: '0 0',
                },
            },
        },
        arrow: {
            // overflow: 'hidden',
            position: 'absolute',
            zIndex: -1,
            width: '1em',
            height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
            boxSizing: 'border-box',
            color: theme.palette.background.paper,
            '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: '100%',
                height: '100%',
                boxShadow: theme.shadows[1],
                backgroundColor: 'currentColor',
                transform: 'translate(0, -.2em) rotate(45deg)',
            },
        },
    }

    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
    const [childNode, setChildNode] = useState<HTMLElement | null>(null)

    return (
        <div>
            {cloneElement(children, { ...children.props, ref: setChildNode })}
            <Popper
                open={open}
                anchorEl={childNode}
                placement={placement}
                transition
                sx={classes.popper}
                modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            rootBoundary: 'window',
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                {({ TransitionProps }) => (
                    <Grow style={{ transformOrigin: 'top center' }} {...TransitionProps} timeout={350}>
                        <Paper sx={{ mt: 1 }}>
                            <ClickAwayListener onClickAway={onClose}>
                                <Paper sx={classes.popoverRoot}>
                                    {arrow ? <Box component={'span'} sx={classes.arrow} ref={setArrowRef} /> : null}
                                    <Box sx={{ p: 2 }}>{content}</Box>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
