import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import EmptyState from '#app/components/EmptyState/EmptyState'
import PartnerCalculator from '#app/pages/Onboarding/InterestCalculator/PartnerCalculator'
import { InterestOutput, LoadingSpinner } from '#components'
import PageHeader, { getIconText, getOrganisationSelectorOptions } from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import CustomerInvite from '#pages/Customers/CustomerOverview/CustomerInvite'
import CustomerList from '#pages/Customers/CustomerOverview/CustomerList'
import { useSelector } from '#state/useSelector'
import { PartnerCustomerDto } from '@fixrate/fixrate-query'
import { Clear } from '@mui/icons-material'
import {
    Avatar,
    Button,
    Divider,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RegisterCustomer from '../RegisterCustomer/RegisterCustomer'
import { CustomerKeyFigure } from './components/CustomerKeyFigure'
import AccountantProspects from './AccountantProspects/AccountantProspects'

export function isFinishedWithOnboarding(customer: PartnerCustomerDto) {
    const hasPartnerProposals = customer.partnerRelations.some((pr) => pr.partnerProposals.length > 0)
    const hasMadeOrders = customer.orderCount > 0
    return hasMadeOrders || (!!customer.fullyRegisteredAt && hasPartnerProposals)
}

export default function CustomerOverview({
    openRegistration = false,
    customerCategory,
}: {
    openRegistration?: boolean
    customerCategory?: 'registered' | 'client-list'
}) {
    const { t } = useTranslation()
    const [customerSearchQuery, setCustomerSearchQuery] = useState('')
    const [showCalculator, setShowCalculator] = useState(false)
    const [selectedCustomerGroup, setSelectedCustomerGroup] = useState<'all' | 'registered' | 'not-registered'>('all')
    const [registerCustomerModalOpen, setRegisterCustomerModalOpen] = useState(openRegistration)
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()
    const partner = useSelector((state) => state.partner)
    const allCustomers = partner?.customers ?? []

    const customersInOnboarding = allCustomers.filter((customer) => !isFinishedWithOnboarding(customer))
    const customersNotInOnboarding = allCustomers.filter(isFinishedWithOnboarding)
    const isAccountant = partner?.partnerCategory === 'ACCOUNTANT'
    const associations = useSelector((state) => state.session?.associations)
    const association = useSelector((state) => state.session?.association)
    const firstName = useSelector((state) => state.session?.firstName)
    const orgOptions = getOrganisationSelectorOptions(associations, association)
    const isAdmin = associations
        .find((assoc) => assoc.organisation?.id === partner?.id)
        ?.roles.includes('PARTNER_ADMIN')

    function customersForList() {
        switch (selectedCustomerGroup) {
            case 'all':
                return allCustomers
            case 'registered':
                return customersNotInOnboarding
            case 'not-registered':
                return customersInOnboarding
            default:
                return allCustomers
        }
    }

    const defaultCurrency = 'NOK' // TODO: Should be able to select currency somehow

    const filteredCustomers =
        customersForList().filter(
            (customer) =>
                !customerSearchQuery ||
                sanitizeString(`${customer.name}${customer.nationalIdentity}`).includes(
                    sanitizeString(customerSearchQuery)
                )
        ) ?? []
    const hasCustomers = allCustomers.length > 0

    const allDefaultCurrencyPortfolios = allCustomers.flatMap((customer) =>
        customer.portfolios.filter((portfolio) => portfolio.currency === defaultCurrency)
    )

    const totalDefaultCurrencyPortfolioBalance = allDefaultCurrencyPortfolios
        .map((portfolio) => portfolio.calculatedTotalBalance || portfolio.calculatedBalance || 0)
        .reduce((acc, balance) => acc + balance, 0)

    const portfoliosWithInterestRate = allDefaultCurrencyPortfolios.filter(
        (portfolio) => portfolio.calculatedTotalInterestRate || portfolio.calculatedInterestRate
    )
    const averagePortfolioInterestRate =
        portfoliosWithInterestRate.reduce(
            (acc, portfolio) => acc + portfolio.calculatedTotalInterestRate || portfolio.calculatedInterestRate || 0,
            0
        ) / portfoliosWithInterestRate.length

    const totalDefaultCurrencyFundVolume = allDefaultCurrencyPortfolios
        .filter((portfolio) => portfolio.fundPlacements.length > 0)
        .flatMap((portfolio) => portfolio.fundPlacements.map((fundPlacement) => fundPlacement.currentValue))
        .reduce((acc, currentValue) => acc + currentValue, 0)

    return (
        <>
            <PageHeader icon="ri-briefcase-line" title={t('pages-customers.customersPageHeading')} />
            <PageLayout>
                {!isAccountant && (
                    <CustomerInvite
                        visible={registerCustomerModalOpen}
                        hide={() => setRegisterCustomerModalOpen(false)}
                    />
                )}
                {isAccountant && (
                    <RegisterCustomer
                        visible={registerCustomerModalOpen}
                        hide={() => setRegisterCustomerModalOpen(false)}
                    />
                )}
                <PartnerCalculator showCalculator={showCalculator} setShowCalculator={setShowCalculator} />
                <Stack spacing={{ xs: 2, md: 3 }}>
                    <Stack
                        flexWrap="wrap"
                        direction={{ md: 'row' }}
                        justifyContent="space-between"
                        alignItems={{ md: 'center' }}
                    >
                        <Stack sx={{ px: { xs: 2, md: 0 } }} direction="row" spacing={2} alignItems="center">
                            <Avatar
                                sx={{
                                    width: '8rem',
                                    height: '8rem',
                                    fontSize: '2.6rem',
                                    backgroundColor: orgOptions.find((org) => org.organisationId === partner?.id)
                                        ?.iconColor,
                                }}
                            >
                                {getIconText(partner?.name)}
                            </Avatar>
                            <Stack spacing={0.5}>
                                <h2>
                                    {t('common.hi')} {firstName}!
                                </h2>
                                <p>{t('pages-customers.yourCustomersDescription')}</p>
                            </Stack>
                        </Stack>
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            alignItems={{ md: 'flex-start' }}
                            spacing={{ md: 2 }}
                        >
                            <CustomerKeyFigure
                                label={t('pages-customers.totalVolume')}
                                value={Currency(totalDefaultCurrencyPortfolioBalance)}
                                icon="coinsFill"
                            />
                            <CustomerKeyFigure
                                label={t('pages-customers.averageInterestRate')}
                                value={InterestOutput.format(averagePortfolioInterestRate || 0)}
                                icon="interestBankFill"
                            />
                            {totalDefaultCurrencyFundVolume !== 0 && (
                                <CustomerKeyFigure
                                    label={t('pages-customers.totalFundVolume')}
                                    value={Currency(totalDefaultCurrencyFundVolume || 0)}
                                    icon="exchangeBankFill"
                                />
                            )}
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack
                        justifyContent={'space-between'}
                        gap={2}
                        direction={{ md: 'row' }}
                        alignItems={{ md: 'center' }}
                        flexWrap="wrap"
                    >
                        {isAccountant && (
                            <Tabs value={customerCategory} onChange={(e, val) => navigate('/customer/' + val)}>
                                <Tab label={t('pages-customers.registeredCustomerList')} value="registered" />
                                <Tab label={t('pages-customers.customerList')} value="client-list" />
                            </Tabs>
                        )}
                        <Stack direction={{ md: 'row' }} alignItems={'flex-start'} spacing={2} gap={2}>
                            <Button
                                size="small"
                                startIcon={<i className="ri-add-line" />}
                                variant={'contained'}
                                data-cy="inviteButton"
                                onClick={() => setRegisterCustomerModalOpen(true)}
                            >
                                {isAccountant
                                    ? t('pages-customers.registerNewCustomer')
                                    : t('pages-customers.inviteNewCustomer')}
                            </Button>
                            <Button
                                size="small"
                                startIcon={<i className="ri-calculator-line" />}
                                variant="outlined"
                                onClick={() => setShowCalculator(true)}
                            >
                                {t('pages-customer.calculateInterestGain')}
                            </Button>
                        </Stack>
                    </Stack>
                    {customerCategory === 'registered' && (
                        <Fragment>
                            {hasCustomers ? (
                                <Paper sx={{ p: 3 }}>
                                    {hasCustomers && (
                                        <Stack direction="row" justifyContent="space-between">
                                            <ToggleButtonGroup
                                                color="primary"
                                                size="small"
                                                sx={{ mb: 3 }}
                                                value={selectedCustomerGroup}
                                            >
                                                <ToggleButton
                                                    onClick={(e, val) => setSelectedCustomerGroup(val)}
                                                    value={'all'}
                                                >
                                                    {t('pages-customers.allCustomers')}
                                                </ToggleButton>
                                                <ToggleButton
                                                    onClick={(e, val) => setSelectedCustomerGroup(val)}
                                                    value={'not-registered'}
                                                >
                                                    {t('pages-customers.underOnboarding')}
                                                </ToggleButton>
                                                <ToggleButton
                                                    onClick={(e, val) => setSelectedCustomerGroup(val)}
                                                    value={'registered'}
                                                >
                                                    {t('pages-customers.registeredCustomers')}
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            <TextField
                                                size="small"
                                                placeholder={t('pages-customers.customerSearch')}
                                                value={customerSearchQuery}
                                                onChange={(event) => setCustomerSearchQuery(event.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{
                                                                opacity: customerSearchQuery.length === 0 ? 0 : 1,
                                                                transformOrigin: 'center',
                                                                transform:
                                                                    customerSearchQuery.length === 0
                                                                        ? 'scale(0)'
                                                                        : 'scale(1)',
                                                                transition: '0.2s ease-in-out',
                                                            }}
                                                        >
                                                            <IconButton
                                                                size={'small'}
                                                                onClick={() => setCustomerSearchQuery('')}
                                                            >
                                                                <Clear fontSize={'small'} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Stack>
                                    )}
                                    {!isAdmin && (
                                        <p>
                                            {isAccountant
                                                ? t('pages-customers.customersListMessageAccountantAdvisorRole', {
                                                      partnerName: partner?.name ?? '',
                                                  })
                                                : t('pages-customers.customersListMessageAdvisorNotAdminRole', {
                                                      partnerName: partner?.name ?? '',
                                                  })}
                                        </p>
                                    )}
                                    <CustomerList
                                        partner={partner}
                                        customers={filteredCustomers}
                                        currency={defaultCurrency}
                                    />
                                </Paper>
                            ) : partner?.id ? (
                                <EmptyState
                                    illustration={{ name: 'womanOfficeWorking', color: 'orange' }}
                                    title={t('pages-customers.addFirstCustomer')}
                                    button={{
                                        dataCy: 'inviteButton',
                                        text: isAccountant
                                            ? t('pages-customers.registerNewCustomer')
                                            : t('pages-customers.inviteNewCustomer'),
                                        onClick: () => setRegisterCustomerModalOpen(true),
                                    }}
                                />
                            ) : (
                                <LoadingSpinner />
                            )}
                        </Fragment>
                    )}
                    {customerCategory === 'client-list' && (
                        <Paper sx={{ p: 3 }}>
                            <AccountantProspects />
                        </Paper>
                    )}
                </Stack>
            </PageLayout>
        </>
    )
}

export function sanitizeString(string: string): string {
    // lowercase, remove whitespace, convert accented characters to non-accented, convert ø to o, convert æ to e, remove special characters, remove double characters
    return string
        .toLowerCase()
        .replace(/\s/g, '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace('ø', 'o')
        .replace('æ', 'e')
        .replace(/[^a-z0-9]/g, '')
        .replace(/(.)\1+/g, '$1')
}
