import { Children, useMemo, useState, useEffect, useRef, memo, cloneElement } from 'react'
import PropTypes from 'prop-types'
import styles from './CustomerDeclarationButton.module.css'

/**
 * @return {[function(): void, function(): boolean]}
 */
function useThrottle(callback, time = 1000) {
    const timeout = useRef(null)
    const event = useRef(null)
    const lock = useRef(null)
    const [cleared, setCleared] = useState(0)

    /** @return {boolean} */
    function isThrottling() {
        return lock.current === true
    }

    const { current: throttle } = useRef((e) => {
        event.current = e
        if (timeout.current) {
            clearTimeout(timeout.current)
        }

        lock.current = true
        setCleared(cleared + 1)

        timeout.current = setTimeout(() => {
            lock.current = false
            setCleared(0)
        }, time)
    })

    useEffect(() => {
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    }, [])

    useEffect(() => {
        if (lock.current && cleared === 1) {
            callback(event.current)
        }
    }, [lock.current, cleared])

    return [throttle, isThrottling]
}

function CustomerDeclarationButton({
    children = null,
    className: propsClassName = '',
    onClick = () => void 0,
    onTouch = () => void 0,
    disabled: propsDisabled = false,
    transparent = false,
    throttleBy = 500,
    ...props
}) {
    const [hasFocus, setFocus] = useState(false)
    const [pressed, setPressed] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const className = useMemo(() => {
        return (transparent ? [styles.transparent, propsClassName] : [styles.button, propsClassName])
            .filter(Boolean)
            .join(' ')
            .trim()
    }, [propsClassName, transparent])

    const { left, center, right } = useMemo(() => {
        const left = []
        const right = []
        const center = []

        Children.forEach(children, (child) => {
            if (!child) {
                return
            }
            if (
                (child.type === CustomerDeclarationButton.Align && child.props.left) ||
                child.type === CustomerDeclarationButton.Align.Left
            ) {
                left.push(child)
            } else if (
                (child.type === CustomerDeclarationButton.Align && child.props.right) ||
                child.type === CustomerDeclarationButton.Align.Right
            ) {
                right.push(child)
            } else {
                center.push(child)
            }
        })

        return {
            left,
            right,
            center,
        }
    }, [children])

    const [handleClick, isThrottling] = useThrottle(function () {
        setDisabled(true)
        onClick()
        if (!pressed) {
            setPressed(true)
            onTouch()
        }
    }, throttleBy)

    function handleKey(e) {
        if (e.keyCode == 32 || e.key === ' ' || e.key.toLowerCase() === 'enter') {
            e.preventDefault()
            handleClick()
        }
    }

    useEffect(() => {
        function handleSpaceScroll(e) {
            if (e.keyCode == 32 && hasFocus) {
                e.preventDefault()
            }
            return false
        }

        window.addEventListener('keydown', handleSpaceScroll, true)

        return () => {
            window.removeEventListener('keydown', handleSpaceScroll, true)
        }
    }, [hasFocus])

    useEffect(() => {
        if (disabled && !isThrottling()) {
            setDisabled(false)
        }
    }, [disabled, isThrottling()])

    return (
        <div
            {...props}
            tabIndex={0}
            role="button"
            aria-pressed={pressed}
            data-disabled={propsDisabled.toString() || isThrottling().toString()}
            onClick={propsDisabled ? null : handleClick}
            onKeyDown={propsDisabled ? null : handleKey}
            onFocus={
                propsDisabled
                    ? null
                    : () => {
                          setFocus(true)
                      }
            }
            onBlur={
                propsDisabled
                    ? null
                    : () => {
                          setFocus(false)
                      }
            }
            className={className}
        >
            <div style={{ alignSelf: 'flex-start' }}>{left}</div>
            <div style={{ alignSelf: 'center' }}>{center}</div>
            <div style={{ alignSelf: 'flex-end' }}>{right}</div>
        </div>
    )
}
//const Button = memo(ButtonFunction)

CustomerDeclarationButton.Primary = ({ className = '', ...props }) => (
    <CustomerDeclarationButton className={[styles.primary, className].filter(Boolean).join(' ').trim()} {...props} />
)
CustomerDeclarationButton.Cancel = ({ className = '', ...props }) => (
    <CustomerDeclarationButton className={[styles.cancel, className].filter(Boolean).join(' ').trim()} {...props} />
)
CustomerDeclarationButton.Success = ({ className = '', ...props }) => (
    <CustomerDeclarationButton className={[styles.success, className].filter(Boolean).join(' ').trim()} {...props} />
)

CustomerDeclarationButton.Remove = ({ children = null, ...props }) => (
    <CustomerDeclarationButton style={{ width: '20px' }} transparent {...props}>
        <CustomerDeclarationButton.Icon.Remove />
        {children}
    </CustomerDeclarationButton>
)

CustomerDeclarationButton.Primary.displayName = 'ButtonPrimary'
CustomerDeclarationButton.Cancel.displayName = 'ButtonCancel'
CustomerDeclarationButton.Success.displayName = 'ButtonSuccess'
CustomerDeclarationButton.Remove.displayName = 'ButtonRemove'

CustomerDeclarationButton.Align = ({ children = null }) => children
CustomerDeclarationButton.Align.Left = ({ children = null }) => children
CustomerDeclarationButton.Align.Right = ({ children = null }) => children

CustomerDeclarationButton.Align.displayName = 'ButtonAlign'
CustomerDeclarationButton.Align.Right.displayName = 'ButtonAlignRight'
CustomerDeclarationButton.Align.Left.displayName = 'ButtonAlignLeft'

CustomerDeclarationButton.Outline = (_) => null

// eslint-disable-next-line react/display-name
CustomerDeclarationButton.Outline.Primary = ({ className = '', ...props }) => (
    <CustomerDeclarationButton
        className={[styles.primary__outline, className].filter(Boolean).join(' ').trim()}
        {...props}
    />
)

// eslint-disable-next-line react/display-name
CustomerDeclarationButton.Outline.Cancel = ({ className = '', ...props }) => (
    <CustomerDeclarationButton
        className={[styles.cancel__outline, className].filter(Boolean).join(' ').trim()}
        {...props}
    />
)

CustomerDeclarationButton.Outline.displayName = 'ButtonOutline'
CustomerDeclarationButton.Outline.Primary.displayName = 'ButtonOutlinePrimary'
CustomerDeclarationButton.Outline.Cancel.displayName = 'ButtonOutlineCancel'

CustomerDeclarationButton.Rounded = ({
    children = null,
    style = {},
    topLeft = '0px',
    topRight = '0px',
    bottomLeft = '0px',
    bottomRight = '0px',
    ...props
}) =>
    children
        ? Children.map(children, (child) =>
              cloneElement(child, {
                  style: {
                      ...style,
                      borderTopLeftRadius: topLeft,
                      borderTopRightRadius: topRight,
                      borderBottomLeftRadius: bottomLeft,
                      borderBottomRightRadius: bottomRight,
                  },
                  ...props,
              })
          )
        : null

CustomerDeclarationButton.Rounded.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    topLeft: PropTypes.string,
    topRight: PropTypes.string,
    bottomLeft: PropTypes.string,
    bottomRight: PropTypes.string,
}

CustomerDeclarationButton.Rounded.displayName = 'ButtonRounded'

CustomerDeclarationButton.Icon = () => null

CustomerDeclarationButton.Icon.Plus = function (props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                fill="currentColor"
                fillOpacity="1"
            />
        </svg>
    )
}

CustomerDeclarationButton.Icon.Loading = function (props) {
    return null
}

CustomerDeclarationButton.Icon.Remove = function (props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 10C0 4.47 4.47 0 10 0C15.53 0 20 4.47 20 10C20 15.53 15.53 20 10 20C4.47 20 0 15.53 0 10ZM12.89 14.3C13.28 14.69 13.91 14.69 14.3 14.3C14.68 13.91 14.68 13.27 14.3 12.89L11.41 10L14.3 7.11C14.69 6.72 14.69 6.09 14.3 5.7C13.91 5.31 13.28 5.31 12.89 5.7L9.99999 8.59L7.10999 5.7C6.71999 5.31 6.08999 5.31 5.69999 5.7C5.51274 5.88683 5.4075 6.14048 5.4075 6.405C5.4075 6.66952 5.51274 6.92317 5.69999 7.11L8.58999 10L5.69999 12.89C5.51274 13.0768 5.4075 13.3305 5.4075 13.595C5.4075 13.8595 5.51274 14.1132 5.69999 14.3C6.08999 14.69 6.71999 14.69 7.10999 14.3L9.99999 11.41L12.89 14.3Z"
                fill="currentColor"
            />
        </svg>
    )
}

CustomerDeclarationButton.Icon.displayName = 'ButtonIcon'
CustomerDeclarationButton.Icon.Plus.displayName = 'ButtonIconPlus'
CustomerDeclarationButton.Icon.Loading.displayName = 'ButtonIconLoading'
CustomerDeclarationButton.Icon.Remove.displayName = 'ButtonIconRemove'

CustomerDeclarationButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onTouch: PropTypes.func,
    throttleBy: PropTypes.number,
}

CustomerDeclarationButton.displayName = 'Button'

export default CustomerDeclarationButton
