import PropTypes from 'prop-types'

const ChevronCircledRight = (props) => (
    <svg
        className={props.className}
        id="Chevron_circled_right"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3.81 6.74"
    >
        <title>hoyre</title>
        <path
            d="M7.78,6.78a.5.5,0,0,1,.71,0L11.3,9.64a.51.51,0,0,1,0,.72L8.49,13.22a.5.5,0,0,1-.71,0,.51.51,0,0,1,0-.72L10.09,10,7.78,7.49A.51.51,0,0,1,7.78,6.78Z"
            transform="translate(-7.64 -6.63)"
        />
    </svg>
)

ChevronCircledRight.propTypes = {
    className: PropTypes.string,
}

export default ChevronCircledRight
