import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FundReturnsChart from '#blocks/FundReturnsChart/FundReturnsChart'
import { InterestOutput, LoadingSpinner, PageHeader, PageLayout, Paper } from '#components'
import TransactionTable from '#pages/portfolio-depositor/FundPlacementDetails/TransactionTable'
import { useSelector } from '#state/useSelector'
import { useParams } from 'react-router-dom'
import styles from './FundPlacementDetails.module.scss'

type Params = {
    fundPlacementId?: string
}

export default function FundPlacementDetails() {
    const { fundPlacementId } = useParams<Params>()
    const Currency = useCurrencyOutput()

    const loaded = useSelector((state) => !!state.loaded.fundPlacements)

    const fundPlacement = useSelector((state) => state.fundPlacements.find((fp) => fp.id === fundPlacementId))
    const fund = useSelector((state) => state.funds.find((f) => f.id === fundPlacement?.fundId))
    const shareClass = fund.fundShareClasses.find((sc) => sc.id === fundPlacement?.fundShareClassId)

    if (!loaded || !fundPlacement) {
        return (
            <>
                <PageHeader backToLink={'/portfolio/overview/funds'} />
                <PageLayout>
                    {!loaded && <LoadingSpinner />}
                    {loaded && !fundPlacement && <p>Fant ikke plasseringen</p>}
                </PageLayout>
            </>
        )
    }

    const returnPercentage = fundPlacement.returnRate * 100

    return (
        <>
            <PageHeader title={fund?.name} backToLink={'/portfolio/overview/funds'} />
            <PageLayout>
                <div className={styles.overview}>
                    <div className={styles.heroSection}>
                        <div className={styles.row}>
                            <p className={styles.title}>Verdi</p>
                            <p className={styles.value}>{Currency(fundPlacement.currentValue, { decimals: 0 })}</p>
                        </div>
                        <ul className={styles.content}>
                            <li>
                                <span className={styles.header}>NAV-kurs</span>
                                <span className={styles.value}>{Currency(shareClass.nav, { decimals: 4 })}</span>
                            </li>
                            <li>
                                <span className={styles.header}>Avkastning</span>
                                <span className={styles.value}>
                                    {returnPercentage > 0 && '+'}
                                    {InterestOutput.format(returnPercentage)}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <FundReturnsChart fundShareClass={shareClass} />
                </div>
                <Paper title={'Transaksjoner'} sx={{ width: '100%' }} className={styles.transactions}>
                    <TransactionTable fundPlacementId={fundPlacementId} />
                </Paper>
            </PageLayout>
        </>
    )
}
