import { useCommand } from '#command'
import { useTranslation } from '#components/i18n'
import Modal from '#components/Modal'
import { validateEmailAddress as ValidatorEmail } from '#services/validateFields'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { InputLabel, TextField } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './ResendUserInvite.module.scss'

const ResendUserInvite = ({
    organisationId,
    organisationType,
    close = null,
    returnTo,
    inviteId,
    inviteEmail,
}: {
    organisationId: string
    organisationType: OrganisationType
    close?: () => void
    returnTo: string
    inviteId: string
    inviteEmail: string
}) => {
    const { t } = useTranslation('blocks-UsersList')

    const [email, setEmail] = useState(inviteEmail)
    const [error, setError] = useState({
        email: null,
    })

    const navigate = useNavigate()
    const { resendUserInvite } = useCommand()

    const lookupDepositorUserInvite = useSelector(selectors.lookupDepositorUserInvite)
    const bankUserInvite = useSelector((state) =>
        selectors.bankUserInvites(state).find((invite) => invite.id === inviteId)
    )
    const partnerUserInvite = useSelector((state) =>
        selectors.partnerUserInvites(state).find((invite) => invite.id === inviteId)
    )
    const userInvite = (() => {
        switch (organisationType) {
            case 'DEPOSITOR':
                return lookupDepositorUserInvite(organisationId, inviteId)
            case 'BANK':
                return bankUserInvite
            case 'PARTNER':
                return partnerUserInvite
        }
    })()

    function validateEmail() {
        if (!email) {
            throw t('inviteEmailMissing')
        }

        if (!ValidatorEmail(email)) {
            throw t('inviteEmailInvalid')
        }
    }

    async function submit() {
        try {
            setError((prevState) => ({ ...prevState, email: null }))
            validateEmail()
        } catch (err) {
            setError((prevState) => ({ ...prevState, email: err }))
            return
        }

        try {
            const { waitForCommand } = await resendUserInvite(organisationId, userInvite.id, email)
            const success = await waitForCommand()
            if (success) {
                closeModal()
            }
        } catch (err) {
            console.error(err)
        }
    }

    function onBlur() {
        try {
            setError((prevState) => ({ ...prevState, email: null }))
            validateEmail()
        } catch (err) {
            setError((prevState) => ({ ...prevState, email: err }))
        }
    }

    const closeModal = () => {
        if (close) {
            close()
        } else {
            navigate(returnTo)
        }
    }

    return (
        <Modal header={t('inviteResendHeading')} onSubmit={submit} onCancel={closeModal} width="40rem">
            <InputLabel className={styles.newuser__inputlabel} htmlFor="emailField">
                {t('inviteEmail')}
            </InputLabel>
            <TextField
                id="emailField"
                onBlur={onBlur}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                value={email}
            />
            <p className="field-error-message">{error.email}</p>
        </Modal>
    )
}

export default ResendUserInvite
