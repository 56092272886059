import CompanySelect from '#app/components/CompanySelect'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { AccountantCustomerCompanyDto } from '@fixrate/fixrate-query'
import { useCommand } from '#command'
import { useSelector } from '#state/useSelector'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './RegisterCustomer.module.scss'
import { CompanyInfo } from '#app/components/CompanySelect/CompanySelect'
import { FOREST_GREEN } from '#app/colors/colors'
import FixrateIllustration from '#components/FixrateIllustration/FixrateIllustration'
import { useNavigate } from 'react-router-dom'

export default function RegisterCustomer({
    hide,
    visible,
    selectedProspect,
}: Readonly<{
    hide: () => void
    visible: boolean
    selectedProspect?: AccountantCustomerCompanyDto
}>) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [company, setCompany] = useState<CompanyInfo | AccountantCustomerCompanyDto | null>(selectedProspect || null)
    const [companyError, setCompanyError] = useState('')
    const [selectedAdvisor, setSelectedAdvisor] = useState(null)
    const [loading, setLoading] = useState(false)
    const partner = useSelector((state) => state.partner)
    const currentUserId = useSelector((state) => state.session.id)
    const isPartnerAdmin = partner?.users?.find((user) => user.id === currentUserId)?.roles.includes('PARTNER_ADMIN')
    const registeredCustomer =
        partner?.customers.find((customer) => customer.nationalIdentity === company?.nationalIdentity) ?? null
    const primaryAdvisor = partner?.users.find((user) => user.id === registeredCustomer?.primaryAdvisor)

    const { sendPartnerInvite } = useCommand()

    useEffect(() => {
        if (!selectedAdvisor) {
            setSelectedAdvisor(currentUserId)
        }
    }, [currentUserId, selectedAdvisor])

    const resetForm = useCallback(() => {
        setLoading(false)
        setCompany(null)
        setCompanyError('')
        setSelectedAdvisor(currentUserId)
        hide()
    }, [currentUserId, hide])

    async function onSubmit() {
        setLoading(true)
        if (!company) {
            setCompanyError(t('pages-customers.inviteCompanySelectValidation'))
            setLoading(false)
        } else {
            const { waitForCommand } = await sendPartnerInvite(
                partner.id,
                company.nationalIdentity,
                company.nationality,
                true,
                selectedAdvisor
            )
            await waitForCommand()
            setLoading(false)
        }
    }

    return (
        <FxDialog data-cy="registerCustomerDialog" fullWidth={true} open={visible} onClose={resetForm}>
            <Box sx={{ position: 'absolute', right: '1.6rem', top: '1.6rem' }}>
                <IconButton sx={{ fontSize: '2.4rem', color: FOREST_GREEN[500] }} onClick={resetForm}>
                    <i className="ri-close-line" />
                </IconButton>
            </Box>
            <Box
                sx={{
                    backgroundColor: '#FDF3E5',
                    px: '4rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                {registeredCustomer ? (
                    <FixrateIllustration name={'statusSuccessCircle'} color={'default'} />
                ) : (
                    <FixrateIllustration name={'manCoffeeDocument'} color={'multiColor'} />
                )}
            </Box>
            <FxDialogContent sx={{ padding: '4rem' }} className={styles.registerCustomer}>
                {registeredCustomer ? (
                    <Fragment>
                        <h2>{t('pages-customers.customerRegistered')}</h2>
                        <label className="flex">{t('pages-customers.companyName')}</label>
                        <Typography>{company?.name}</Typography>
                        {primaryAdvisor && (
                            <>
                                <label className="flex">{t('pages-customers.accountant')}</label>
                                <Typography>
                                    {primaryAdvisor.firstName} {primaryAdvisor.lastName}
                                </Typography>
                            </>
                        )}
                        <Stack mt={3} spacing={2} direction={'row'} justifyContent={'space-between'}>
                            <Button
                                data-cy="closeRegisterCustomerDialog"
                                variant="outlined"
                                onClick={() => {
                                    resetForm()
                                }}
                            >
                                {t('common.close')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    navigate(`/customer/${registeredCustomer?.depositorId}`)
                                }}
                            >
                                {t('pages-customers.goToCustomer')}
                            </Button>
                        </Stack>
                    </Fragment>
                ) : (
                    <Fragment>
                        <h2>{t('pages-customers.registerNewCustomer')}</h2>
                        {selectedProspect ? (
                            <>
                                <label className="flex">{t('pages-customers.companyName')}</label>
                                <Typography>{company?.name}</Typography>
                            </>
                        ) : (
                            <>
                                <label className="flex">{t('pages-customers.searchForOrganization')}</label>
                                <CompanySelect
                                    onChange={setCompany}
                                    setErrorMessage={setCompanyError}
                                    placeholder={'Søk'}
                                />
                                <p className="field-error-message">{companyError}</p>
                            </>
                        )}
                        {isPartnerAdmin && (
                            <Fragment>
                                <label className="flex">{t('pages-customers.accountant')}</label>
                                <Select
                                    value={selectedAdvisor}
                                    onChange={(e: SelectChangeEvent) => setSelectedAdvisor(e.target.value)}
                                >
                                    {partner?.users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.firstName} {user.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Fragment>
                        )}
                        <Box sx={{ marginTop: '3rem' }}>
                            <LoadingButton
                                data-cy="registerCustomerButton"
                                loading={loading}
                                variant="contained"
                                onClick={() => onSubmit()}
                            >
                                {t('pages-customers.registerCustomer')}
                            </LoadingButton>
                        </Box>
                    </Fragment>
                )}
            </FxDialogContent>
        </FxDialog>
    )
}
