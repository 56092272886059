import { useUpdateAdStatusRunner } from '#services/adactive'
import { setCurrentUrl } from '#state/reducers/supportPane'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loginWithToken, registerLanding, syncSessionState } from '#services/thunks/session'
import { useSessionBroadcast } from '#services/sessionBroadcast'
import * as analytics from '#services/analytics'
import { useSelector } from '#state/useSelector'
import { useShoppingCartServerSync } from '#services/useShoppingCart'

/**
 * Starts all global effects that initialises the application and runs in the background
 */
export function GlobalEffects({ children }: { children: React.ReactNode }) {
    console.log('Starting GlobalEffects')

    // NB!! Be very careful and precise with selectors here. If the result of these selectors changes, then the whole page will re-render
    // Only select exactly the data you actually need

    const location = useLocation()
    const dispatch = useDispatch()
    const sessionBroadcast = useSessionBroadcast()
    const association = useSelector((state) => state.session?.association)
    const associations = useSelector((state) => state.session?.associations)

    // Updates the current url in redux when the url changes
    useEffect(() => {
        dispatch(setCurrentUrl(location.pathname))
    }, [dispatch, location])

    // Registers the landing page if the url contains utm_ parameters
    useEffect(() => {
        if (location.search.includes('utm_')) {
            dispatch(registerLanding(window.location.href, document.referrer))
        }
    }, [dispatch, location])

    // Syncs session state, opens or closes the websockets and starts the session broadcast on initial render
    useEffect(() => {
        dispatch(syncSessionState())
        sessionBroadcast.init()
    }, [dispatch, sessionBroadcast])

    // Registers a page view in analytics whenever the url changes
    useEffect(() => {
        analytics.pageView({ path: location.pathname + location.search, association, associations })
    }, [location, association, associations])

    // Updates the ad status every second
    useUpdateAdStatusRunner()

    // Loads the shopping cart from the server and keeps it in sync
    useShoppingCartServerSync()

    useEffect(() => {
        const url = new URL(window.location.href)
        const token = url.searchParams.get('token')
        if (token) {
            loginWithToken(token, url)
        }
    }, [])

    return <>{children}</>
}
