import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useSelector } from '#app/state/useSelector'
import { TerminationMode } from '#pages/portfolio-depositor/DepositDetails/DepositTermination/DepositTermination'
import { useTranslation } from 'react-i18next'
import SelectTerminationType from './steps/SelectTerminationType'
import StartTermination from './steps/StartTermination'

type Props = {
    depositId: string
    terminationMode: TerminationMode
    setTerminationMode: (terminationMode: TerminationMode) => void
}

export default function TerminateDepositWizard({ depositId, terminationMode, setTerminationMode }: Props) {
    const { t } = useTranslation()
    const deposit = useSelector((state) => state.deposits.find((deposit) => deposit.id === depositId))
    const startStep =
        terminationMode === 'UNCONDITIONAL' ||
        terminationMode === 'CONDITIONAL' ||
        terminationMode === 'REJECT_INTEREST_RATE_CHANGE'
            ? true
            : false
    const getTitle = (terminationMode: TerminationMode) => {
        if (!startStep) {
            return t('pages-portfolio-depositor.terminationStartHeader')
        }
        if (terminationMode === 'CONDITIONAL') {
            return t('pages-portfolio-depositor.terminationConditionalHeader')
        }
        if (terminationMode === 'UNCONDITIONAL') {
            return t('pages-portfolio-depositor.terminationUnconditionalHeader')
        }
        if (terminationMode === 'REJECT_INTEREST_RATE_CHANGE') {
            return t('pages-portfolio-depositor.rejectInterestChangeAndTerminateHeader')
        }
    }

    return terminationMode !== 'HIDE' ? (
        <FxDialog open={true} onClose={() => setTerminationMode('HIDE')}>
            <FxDialogTitle onClose={() => setTerminationMode('HIDE')}>{getTitle(terminationMode)}</FxDialogTitle>
            {terminationMode === 'SELECT' && (
                <SelectTerminationType sx={{ mt: 2 }} deposit={deposit} setTerminationMode={setTerminationMode} />
            )}
            {startStep && (
                <StartTermination
                    setTerminationMode={setTerminationMode}
                    terminationMode={terminationMode}
                    deposit={deposit}
                />
            )}
        </FxDialog>
    ) : null
}
