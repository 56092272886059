import AsyncButton from '#app/components/Button/AsyncButton'
import Modal from '#app/components/Modal'
import { UserInviteDto } from '@fixrate/fixrate-security'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import EmptyState from '#components/EmptyState/EmptyState'
import { openChatBubble } from '#services/hubspot'
import { getInvite } from '#services/thunks/user'
import { useSelector } from '#state/useSelector'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

const AcceptInvite = () => {
    const { t } = useTranslation()

    const session = useSelector(({ session }) => session)
    const dispatch = useDispatch<(arg0: unknown) => Promise<unknown>>()
    const navigate = useNavigate()
    const location = useLocation()
    const [userInvite, setUserInvite] = useState<UserInviteDto | null>(null)
    const { acceptUserInvite } = useCommand()
    const [notFound, setNotFound] = useState(false)
    const inviteId = location.pathname.substring('/invite/'.length)

    useEffect(() => {
        function redirectIfAlreadyUsed(userInvite: UserInviteDto) {
            if (!userInvite) {
                console.log('Invite does not exist any more. Redirecting to /marketplace')
                navigate('/marketplace')
                return true
            }
            if (userInvite?.status === 'ACCEPTED') {
                console.log('Invite is already accepted. Redirecting to /marketplace')
                navigate('/marketplace')
                return true
            }
        }

        function redirectIfRegistered(organisationId: string) {
            if (session.associations) {
                const organisationIdList =
                    session.associations.filter((ass) => ass.organisation).map((ass) => ass.organisation.id) || []
                if (organisationIdList.includes(organisationId)) {
                    console.log('User is already registered in this organisation. Redirecting to /marketplace')
                    navigate('/marketplace')
                    return true
                }
            }
        }

        const controller = new AbortController()
        const signal = controller.signal

        dispatch(getInvite(inviteId, signal))
            .then((data: UserInviteDto) => {
                const redirected = redirectIfAlreadyUsed(data) || redirectIfRegistered(data?.organisationId)
                if (!redirected) {
                    setUserInvite(data)
                    if (!data) {
                        setNotFound(true)
                    }
                }
            })
            .catch((err) => {
                if (err.name === 'AbortError') {
                    console.log('getInvite was aborted')
                } else {
                    console.error('Failed while fetching invite ' + inviteId, err)
                    setNotFound(true)
                }
            })

        return () => {
            console.log('Aborting getInvite')
            controller.abort()
        }
    }, [dispatch, inviteId, navigate, session.associations, session.authenticationLevel])

    async function onAcceptUserInvite(isAccepted: boolean) {
        const { waitForCommand } = await acceptUserInvite(userInvite.id, isAccepted)
        const success = await waitForCommand()
        if (success) {
            navigate('/marketplace')
        }
    }

    if (!session.authenticated) {
        return null
    }

    if (notFound) {
        return (
            <EmptyState
                illustration={{ name: 'brokenLink', color: 'default' }}
                variant="centered-on-background"
                title={t('error.header')}
                button={{
                    text: t('pages-marketplace.goToMarketplace'),
                    onClick: () => navigate('/marketplace'),
                }}
                link={{
                    text: t('blocks-UsersList.talkToSupport'),
                    onClick: openChatBubble,
                }}
                description={{
                    dataCy: 'inviteNotFound',
                    text: t('blocks-UsersList.inviteNotFound'),
                }}
            ></EmptyState>
        )
    }

    if (!userInvite) {
        return null
    }

    let body = null
    switch (userInvite.status) {
        case 'NOT_FOR_SUBJECT':
            body = (
                <EmptyState
                    illustration={{ name: 'brokenLink', color: 'default' }}
                    variant="centered-on-background"
                    title={t('error.header')}
                    button={{
                        text: t('pages-marketplace.goToMarketplace'),
                        onClick: () => navigate('/marketplace'),
                    }}
                    link={{
                        text: t('blocks-UsersList.talkToSupport'),
                        onClick: openChatBubble,
                    }}
                    description={{
                        dataCy: 'inviteNotForSubject',
                        text: t('blocks-UsersList.inviteNotForSubject'),
                    }}
                ></EmptyState>
            )
            break

        case 'EXPIRED':
            body = (
                <EmptyState
                    illustration={{ name: 'brokenLink', color: 'default' }}
                    variant="centered-on-background"
                    title={t('error.header')}
                    button={{
                        text: t('pages-marketplace.goToMarketplace'),
                        onClick: () => navigate('/marketplace'),
                    }}
                    link={{
                        text: t('blocks-UsersList.talkToSupport'),
                        onClick: openChatBubble,
                    }}
                    description={{
                        dataCy: 'inviteExpired',
                        text: t('blocks-UsersList.inviteExpired'),
                    }}
                ></EmptyState>
            )
            break

        case 'OPEN':
            body = (
                <span>
                    <p>
                        {t('blocks-UsersList.acceptInviteMsgPart1', {
                            organisationName: userInvite.organisationName,
                            inviterName: userInvite.inviterFirstName + ' ' + userInvite.inviterLastName,
                        })}
                    </p>
                    <p>
                        {t('blocks-UsersList.acceptInviteMsgPart2', {
                            inviterName: userInvite.inviterFirstName + ' ' + userInvite.inviterLastName,
                        })}
                    </p>
                    <ButtonRow>
                        <AsyncButton
                            id="ignoreInvitationButton"
                            variant={'outlined'}
                            onClick={() => onAcceptUserInvite(false)}
                        >
                            {t('blocks-UsersList.ignoreInvite')}
                        </AsyncButton>
                        <AsyncButton
                            id="acceptInvitationButton"
                            variant={'contained'}
                            onClick={() => onAcceptUserInvite(true)}
                        >
                            {t('blocks-UsersList.acceptInvite')}
                        </AsyncButton>
                    </ButtonRow>
                </span>
            )
            break
    }

    return <Modal header={t('blocks-UsersList.invitesHeading')}>{body}</Modal>
}

export default AcceptInvite
