import { useSelector } from '#state/useSelector'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { formatAccount } from '#services/formatnumber'
import Paper from '#app/components/Paper/FxPaper'
import styles from './DepositDetailsInfo.module.css'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import { TerminationModeConstant } from '#pages/portfolio-bank/DepositDetailsBank/DepositDetailsBank'
import { TerminationState } from '#state/selectors'
import { CurrencyOutput, DateOutput, HelpIcon, InterestOutput } from '#components'
import { useTermination } from '#services/useTermination'
import { useTranslation } from 'react-i18next'
import { DepositDto } from '@fixrate/fixrate-query'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { Alert, Button, Divider, Stack } from '@mui/material'
import { useAuthorization } from '#app/services/authorization'
import { useState } from 'react'

type Props = {
    deposit: DepositDto
    terminationMode
    setTerminationMode
    showChangeSettlementAccount
    toggleChangeSettlementAccount
}

export default function DepositDetailsInfo(props: Props) {
    const { deposit } = props
    const { t } = useTranslation()
    const bank = useSelector(({ banks }) => banks[deposit.bankId])
    const { firstValidTerminationDate } = useTermination()
    const organisationCountry = useCurrentCountryCode()
    const auth = useAuthorization(deposit?.depositor.id)
    const [showCanNotTerminateMessage, setShowCanNotTerminateMessage] = useState(false)

    const terminatedAndNowIsNotAfterThreeDaysBeforeTermination =
        (TerminationState.SENT_TO_BANK === deposit.terminationState ||
            TerminationState.CONFIRMED === deposit.terminationState) &&
        !isAfter(Date.now(), addDays(new Date(deposit.terminationDate), -3))

    const showTerminationButton = TerminationState.NO_ACTIVE_TERMINATION === deposit.terminationState
    const disableTerminationButton = props.terminationMode !== TerminationModeConstant.HIDE
    const showChangeSettlementAccountButton =
        terminatedAndNowIsNotAfterThreeDaysBeforeTermination && deposit.settlementAccountId
    const disableChangeSettlementAccountButton =
        deposit.changedSettlementAccountStatus !== 'CONFIRMED' || props.showChangeSettlementAccount

    const calculatedVolume = deposit.calculatedBalance
    const calculatedFinalVolume = deposit.calculatedFinalBalance
    let calculatedTotalVolume
    let calculatedTotalVolumeDate

    let interestRateDescription
    switch (deposit.product.type) {
        case 'FIXED':
            interestRateDescription = t('common.productDescription.interestFixed')
            calculatedTotalVolume = deposit.estimatedTotalBalance
            calculatedTotalVolumeDate = deposit.estimatedDate
            break
        case 'FLOATING':
            interestRateDescription = t('common.productDescription.interestFloating', {
                benchmark: t('common.interestRateBenchmark.' + deposit.interestRateBenchmark),
            })
            calculatedTotalVolume = deposit.calculatedTotalBalance
            calculatedTotalVolumeDate = deposit.calculatedDate
            break
    }

    let termsDescription
    switch (deposit.product.termsType) {
        case 'NOTICE':
            termsDescription = t('common.productDescription.termsNotice', { count: deposit.product.days })
            break
        case 'FIXED_TERMS':
            termsDescription = t('common.productDescription.termsFixed')
            break
        case 'RECURRING_FIXED_TERMS':
            termsDescription = t('common.productDescription.termsRecurring')
            break
    }

    const terminationOnClick = () => {
        const hasOrderPermissions = auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole
        if (hasOrderPermissions) {
            props.setTerminationMode('SELECT')
        } else {
            setShowCanNotTerminateMessage(true)
        }
    }

    return (
        <Paper title={t('pages-portfolio-depositor.agreementInformationHeader')}>
            <p>
                {interestRateDescription} {termsDescription}{' '}
                <HelpIcon supportCommonName="depositTermsOverview" inline />
            </p>
            <div className={styles.infoList}>
                <LabeledInfo label={t('pages-portfolio-depositor.bankLabel')} info={bank?.name} />
                <LabeledInfo
                    label={t('pages-portfolio-depositor.accountNumberLabel')}
                    info={formatAccount(deposit.account, organisationCountry)}
                />
                {!!calculatedVolume && (
                    <LabeledInfo
                        label={t('pages-portfolio-depositor.capitalizedBalanceLabel', {
                            calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate),
                        })}
                        info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedVolume)}
                        helpTextLabel={t('pages-portfolio-depositor.capitalizedBalanceHelpText', {
                            calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate),
                        })}
                    />
                )}
                {!!calculatedTotalVolume && (
                    <LabeledInfo
                        label={t('pages-portfolio-depositor.estimatedBalanceLabel', {
                            calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate),
                        })}
                        info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedTotalVolume)}
                        helpTextLabel={t('pages-portfolio-depositor.estimatedBalanceHelpText', {
                            calculatedDate: DateOutput.formatDate(calculatedTotalVolumeDate),
                        })}
                    />
                )}
                {!!calculatedFinalVolume && (
                    <LabeledInfo
                        label={t('pages-portfolio-depositor.endingBalanceLabel')}
                        info={deposit.currency + ' ' + CurrencyOutput.formatNoCode(calculatedFinalVolume)}
                        helpTextLabel={t('pages-portfolio-depositor.endingBalanceHelpText', {
                            calculatedDate: DateOutput.formatDate(deposit.calculatedDate),
                        })}
                    />
                )}
                <LabeledInfo
                    label={t('pages-portfolio-depositor.startBalanceLabel')}
                    info={
                        deposit.currency +
                        ' ' +
                        CurrencyOutput.formatNoCode(deposit.actualStartVolume || deposit.volume * 1e6)
                    }
                    helpTextLabel={t('pages-portfolio-depositor.startBalanceHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type === 'FIXED'}
                    label={t('pages-portfolio-depositor.effectiveInterestLabelWithoutDate')}
                    info={InterestOutput.format(deposit.effectiveInterestRate)}
                    helpTextLabel={t('pages-portfolio-depositor.effectiveFixedInterestHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type !== 'FIXED' && !deposit.benchmarkInterestRateAvailable}
                    label={t('pages-portfolio-depositor.effectiveInterestLabelWithoutDate')}
                    info={
                        t('common.interestRateBenchmark.' + deposit.interestRateBenchmark) +
                        ' + ' +
                        InterestOutput.format(deposit.effectiveInterestRate)
                    }
                    helpTextLabel={t('pages-portfolio-depositor.effectiveFloatingInterestNoBenchmarkHelpText')}
                />
                <LabeledInfo
                    show={deposit.product.type !== 'FIXED' && deposit.benchmarkInterestRateAvailable}
                    label={t('pages-portfolio-depositor.effectiveInterestLabel', {
                        interestDate: DateOutput.formatDate(deposit.interestDate),
                    })}
                    info={InterestOutput.format(deposit.effectiveInterestRate)}
                    helpTextLabel={t('pages-portfolio-depositor.effectiveFloatingInterestHelpText', {
                        benchmark: t('common.interestRateBenchmark.' + deposit.interestRateBenchmark),
                    })}
                />
                <LabeledInfo
                    show={deposit.product.type === 'FIXED'}
                    label={t('pages-portfolio-depositor.fixedInterestLabel')}
                    info={InterestOutput.format(deposit.nominalInterestRate)}
                />
                <LabeledInfo
                    hide={!deposit.currentPeriod}
                    label={t('pages-portfolio-depositor.interestUpdateLabel')}
                    helpTextLabel={t('pages-portfolio-depositor.interestUpdateHelpText')}
                >
                    <DateOutput.VerboseMonth date={deposit.currentPeriod?.terminationDate} />
                </LabeledInfo>
                <LabeledInfo
                    hide={deposit.product.type === 'FIXED'}
                    label={t('pages-portfolio-depositor.interestMarginLabel')}
                    info={InterestOutput.format(deposit.nominalInterestRate)}
                />
                <LabeledInfo
                    label={t('pages-portfolio-depositor.interestRateConvention')}
                    info={t('common.interestRateConvention.' + deposit.interestRateConvention)}
                    helpTextLabel={t('common.interestRateConventionHelpText')}
                />
                <LabeledInfo
                    id="interestRateChangedInfo"
                    label={t('pages-portfolio-depositor.updatedInterestMarginLabel')}
                    hide={!deposit.newNominalInterestRate}
                >
                    {t('pages-portfolio-depositor.updatedInterestMarginInfo', {
                        date: DateOutput.formatVerboseMonth(deposit.newInterestRateChangeDate),
                        margin: InterestOutput.format(deposit.newNominalInterestRate),
                    })}
                </LabeledInfo>
                <LabeledInfo
                    id="extensionInterestRateInfo"
                    label={t('pages-portfolio-depositor.updatedInterestRateLabel')}
                    hide={deposit.extensionOfferState !== 'ACCEPTED'}
                >
                    {t('pages-portfolio-depositor.updatedInterestRateInfo', {
                        date: DateOutput.formatVerboseMonth(deposit.extensionStartDate),
                        rate: InterestOutput.format(deposit.extensionInterestRate),
                    })}
                </LabeledInfo>
                <LabeledInfo label={t('pages-portfolio-depositor.depositConfirmedLabel')}>
                    <DateOutput.VerboseMonth date={deposit.created} />
                </LabeledInfo>
                <LabeledInfo label={t('pages-portfolio-depositor.interestBearingFromLabel')} hide={!deposit.startDate}>
                    <DateOutput.VerboseMonth date={deposit.startDate} />
                </LabeledInfo>
                <LabeledInfo
                    label={t('pages-portfolio-depositor.noticeLabel')}
                    hide={
                        !!deposit.terminationDate ||
                        !!deposit.terminationRequested ||
                        deposit.product.termsType !== 'NOTICE'
                    }
                    helpTextLabel={t('pages-portfolio-depositor.noticeHelpText', { count: deposit.product.days })}
                >
                    {t('pages-portfolio-depositor.noticeInfo', { count: deposit.product.days })}
                </LabeledInfo>
                <LabeledInfo
                    label={t('pages-portfolio-depositor.noticeLabel')}
                    hide={
                        !!deposit.terminationDate ||
                        !!deposit.terminationRequested ||
                        deposit.product.termsType !== 'RECURRING_FIXED_TERMS'
                    }
                    helpTextLabel={t('pages-portfolio-depositor.noticeRecurringHelpText')}
                >
                    {t('pages-portfolio-depositor.noticeRecurringInfo', {
                        date: DateOutput.formatVerboseMonth(firstValidTerminationDate(deposit)),
                    })}
                </LabeledInfo>
                <LabeledInfo
                    label={t('pages-portfolio-depositor.terminationDateLabel')}
                    hide={!deposit.terminationDate}
                >
                    <DateOutput.VerboseMonth date={deposit.terminationDate} />
                </LabeledInfo>
                <LabeledInfo
                    id="settlementAccountInfo"
                    label={t('pages-portfolio-depositor.settlementAccountLabel')}
                    info={`${deposit?.settlementAccountName} (${formatAccount(deposit.settlementAccount, organisationCountry)})`}
                    hide={!deposit.settlementAccount}
                />
                <LabeledInfo
                    label={t('pages-portfolio-depositor.settlementAccountIbanLabel')}
                    info={`${deposit?.settlementAccountName} (${deposit.settlementAccountIban})`}
                    hide={!deposit.settlementAccountIban}
                />
                <LabeledInfo
                    label={t('pages-portfolio-depositor.settlementAccountBicLabel')}
                    info={deposit.settlementAccountBic}
                    hide={!deposit.settlementAccountBic}
                />
            </div>
            {showCanNotTerminateMessage && (
                <Alert severity="error">{t('pages-portfolio-depositor.terminationStartInfo3')}</Alert>
            )}
            {(showTerminationButton || showChangeSettlementAccountButton) && (
                <Stack spacing={2}>
                    <Divider />
                    <ButtonRow>
                        {showChangeSettlementAccountButton && (
                            <Button
                                id="changeSettlementAccountButton"
                                variant="contained"
                                disabled={disableChangeSettlementAccountButton}
                                onClick={props.toggleChangeSettlementAccount}
                            >
                                {t('pages-portfolio-depositor.updateSettlementAccount')}
                            </Button>
                        )}
                        {showTerminationButton && (
                            <Button
                                id="startTerminationButton"
                                variant="contained"
                                disabled={disableTerminationButton}
                                onClick={terminationOnClick}
                            >
                                {t('pages-portfolio-depositor.startTermination')}
                            </Button>
                        )}
                    </ButtonRow>
                </Stack>
            )}
        </Paper>
    )
}
