import { PURPLE } from '#app/colors/colors'
import { useSelector } from '#state/useSelector'
import {
    Avatar,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type Params = {
    depositorId?: string
}

export default function CustomerDetailAdvisors() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const partner = useSelector((state) => state.partner)
    const customer = partner?.customers.find((c) => c.depositorId === params.depositorId)
    const advisors = !customer
        ? []
        : customer.advisors.map((userId) => partner.users.find((u) => u.id === userId)).filter((a) => a)

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    function addAdvisor() {
        navigate(`/customer/${customer.depositorId}/add`)
    }

    function setPrimaryAdvisor() {
        navigate(`/customer/${customer.depositorId}/set-primary`)
    }

    async function removeAdvisor(userId) {
        navigate(`/customer/${customer.depositorId}/remove/${userId}`)
    }

    return (
        <Paper sx={{ px: 4, py: 3 }}>
            <Stack
                direction={{ md: 'row' }}
                sx={{ mb: 2 }}
                gap={1}
                justifyContent={{ md: 'space-between' }}
                alignItems={{ xs: 'flex-start', md: 'center' }}
            >
                <Typography variant={'h2'}>
                    {partner.partnerCategory === 'ACCOUNTANT'
                        ? t('pages-customers.customerDetailAccountantHeading')
                        : t('pages-customers.customerDetailAdvisorsHeading')}
                </Typography>
                <Button
                    variant="outlined"
                    size="small"
                    startIcon={<i className="ri-user-add-line" />}
                    data-cy="addAdvisorButton"
                    onClick={addAdvisor}
                >
                    {partner.partnerCategory === 'ACCOUNTANT'
                        ? t('pages-customers.customerDetailAdvisorsAddAccountant')
                        : t('pages-customers.customerDetailAdvisorsAddAdvisor')}
                </Button>
            </Stack>
            {advisors.length > 0 && (
                <>
                    <p>
                        {(partner.partnerCategory === 'ASSET_MANAGER' || partner.partnerCategory === 'SUPPORT') &&
                            t('pages-customers.customerDetailAdvisorsMessage')}
                        {partner.partnerCategory === 'ACCOUNTANT' &&
                            t('pages-customers.customerDetailAccountantsMessage')}
                    </p>
                    <Table sx={{ '& .MuiTableCell-root': { fontSize: '1.4rem' } }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('pages-customers.advisor')}</TableCell>
                                <TableCell>{t('pages-customers.status')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {advisors.map((advisor) => (
                                <TableRow key={advisor.id}>
                                    <TableCell>
                                        {advisor.firstName} {advisor.lastName}
                                    </TableCell>
                                    <TableCell>
                                        {customer.primaryAdvisor === advisor.id && (
                                            <Stack spacing={1} direction="row" alignItems="center">
                                                {!isMobile && (
                                                    <Avatar sx={{ backgroundColor: PURPLE[50], color: PURPLE[500] }}>
                                                        <i className="ri-customer-service-2-line" />
                                                    </Avatar>
                                                )}
                                                <Typography fontSize={{ xs: '1.2rem', md: '1.6rem' }}>
                                                    {partner.partnerCategory === 'ACCOUNTANT'
                                                        ? t('pages-customers.responsible')
                                                        : t('pages-customers.primary')}
                                                </Typography>
                                            </Stack>
                                        )}
                                    </TableCell>
                                    <TableCell align={'right'}>
                                        <Button color="error" size="small" onClick={() => removeAdvisor(advisor.id)}>
                                            {t('pages-customers.customerDetailAdvisorsRemove')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
            {advisors.length === 0 && (
                <>
                    <Typography color={'#666'} variant="h6" sx={{ mt: 2 }}>
                        {t('customerDetailAdvisorsNoAdvisors')}
                    </Typography>
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        size="small"
                        startIcon={<i className="ri-user-add-line" />}
                        data-cy="addAdvisorButton"
                        onClick={addAdvisor}
                    >
                        {partner.partnerCategory === 'ACCOUNTANT'
                            ? t('pages-customers.customerDetailAdvisorsAddAccountant')
                            : t('pages-customers.customerDetailAdvisorsAddAdvisor')}
                    </Button>
                </>
            )}
            {advisors.length > 1 && (
                <Button
                    startIcon={<i className="ri-customer-service-2-fill" />}
                    sx={{ mt: 2 }}
                    variant="outlined"
                    size="small"
                    onClick={setPrimaryAdvisor}
                >
                    {partner.partnerCategory === 'ACCOUNTANT'
                        ? t('pages-customers.customerDetailAccountantsChangePrimary')
                        : t('pages-customers.customerDetailAdvisorsChangePrimary')}
                </Button>
            )}
        </Paper>
    )
}
