import { PageHeader, PageLayout } from '#components'
import DepositorOfferCard from '#pages/DepositorOfferBank/DepositorOfferCard'
import DepositorOfferDetails from '#pages/DepositorOfferBank/DepositorOfferDetails'
import { useSelector } from '#state/useSelector'
import { Stack } from '@mui/material'
import { add } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

type Params = {
    depositorOfferId?: string
}

export default function DepositorOfferBank() {
    const { t } = useTranslation()
    const depositorOfferList = useSelector((state) => state.depositorOffers)
    const navigate = useNavigate()
    const { depositorOfferId } = useParams<Params>()
    // Setting 2 days where we show the ad. Afterwards it is not shown
    const offersNotExpired = depositorOfferList.filter(
        (offer) => add(new Date(offer.deadline), { days: 14 }) > new Date()
    )

    function onCardClick(id: string) {
        if (id === depositorOfferId) {
            navigate('/depositoroffer-bank/')
        } else {
            navigate(`/depositoroffer-bank/${id}`)
        }
    }

    const cards = offersNotExpired.map((offer) => {
        return <DepositorOfferCard key={offer.depositorOfferId} depositorOffer={offer} onClick={onCardClick} />
    })

    return (
        <>
            <PageHeader title={t('pages-offer.offerFromDepositorHeading')} />
            <PageLayout>
                <Stack direction="row" flexWrap="wrap" spacing={2}>
                    {cards}
                </Stack>
                {depositorOfferId && <DepositorOfferDetails />}
            </PageLayout>
        </>
    )
}
