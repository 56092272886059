import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import Button from '#components/Button'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import Modal from '#components/Modal'
import Table from '#components/Table/Table'
import TableCell from '#components/Table/TableCell'
import TableHeader from '#components/Table/TableHeader'
import TableHeaderCell from '#components/Table/TableHeaderCell'
import TableRow from '#components/Table/TableRow'
import { useSelector } from '#state/useSelector'
import { Stack, Typography } from '@mui/material'
import { isAfter } from 'date-fns'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Fields } from '../types'
import styles from './Banks.module.css'

function setBankAttribute(fields, bankId, attribute, value) {
    const bank = fields.banks.value.find((bank) => bank.bankId === bankId)
    if (bank) {
        bank[attribute] = value
    }
}

function getBankAttribute(fields, bankId, attribute) {
    const bank = fields.banks.value.find((bank) => bank.bankId === bankId)
    if (bank) {
        return bank[attribute]
    } else {
        return ''
    }
}

type Params = {
    depositorOfferId?: string
}

export default function Banks({ fields }: { fields: Fields }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()
    const currentCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const regulatoryRegion = useCurrentCountryCode()

    const banks = useSelector((state) =>
        Object.values(state.banks)
            .filter((bank) => bank.enabled)
            .filter((bank) => bank.currencies.includes(currentCurrency))
            .filter((bank) => bank.regulatoryRegion === regulatoryRegion)
            .slice()
            .sort((a, b) => -(a.totalAssets - b.totalAssets))
    )

    const eligibleDepositorIds = useSelector((state) =>
        state.depositors
            .filter((depositor) => depositor.premiumProducts.includes('OFFER'))
            .map((depositor) => depositor.id)
    )

    const deposits = useSelector((state) => {
        if (fields.depositorId.value) {
            return state.deposits.filter((deposit) => deposit.depositor.id === fields.depositorId.value)
        } else {
            return state.deposits.filter((deposit) => eligibleDepositorIds.includes(deposit.depositor.id))
        }
    })

    const bankExposure = useMemo(
        () =>
            deposits.reduce((exposure, deposit) => {
                const isActive = !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date())
                if (isActive) {
                    exposure[deposit.bankId] = deposit.volume + (exposure[deposit.bankId] ?? 0)
                }
                return exposure
            }, {}),
        [deposits]
    )

    // List of bank id's that are selected
    const selectedBankIds = fields.banks.value?.map((bank) => bank.bankId)

    if (!banks || !selectedBankIds) {
        return null
    }

    function close() {
        navigate(`/depositoroffer/${params.depositorOfferId}/edit/2`)
    }

    function onSelectedClick(bankId) {
        let fieldValue
        if (selectedBankIds.includes(bankId)) {
            fieldValue = fields.banks.value.filter((bank) => bank.bankId !== bankId)
        } else {
            fieldValue = [...fields.banks.value, { bankId }]
        }
        fields.banks.submitValue(fieldValue)
    }

    function onMinVolumeChange(bankId, value) {
        setBankAttribute(fields, bankId, 'minVolume', value)
        fields.banks.setValue([...fields.banks.value])
    }

    function onMaxVolumeChange(bankId, value) {
        setBankAttribute(fields, bankId, 'maxVolume', value)
        fields.banks.setValue([...fields.banks.value])
    }

    function submit() {
        fields.banks.submit()
    }

    const bankRows = banks.map((bank) => {
        const selected = selectedBankIds.includes(bank.id)
        return (
            <TableRow key={bank.id}>
                <TableCell onClick={() => onSelectedClick(bank.id)}>
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                        <input checked={selected} type="checkbox" onChange={() => onSelectedClick(bank.id)} />
                        <Typography fontSize={'1.4rem'} fontWeight={600}>
                            {bank.name}
                        </Typography>
                    </Stack>
                </TableCell>
                <TableCell onClick={() => onSelectedClick(bank.id)} align={'right'}>
                    <Typography fontSize={'1.4rem'}>
                        {CurrencyOutputObject(bank.totalAssets / 1000, { withCurrency: false, maximumDecimals: 1 })}{' '}
                        mrd.
                    </Typography>
                </TableCell>
                <TableCell onClick={() => onSelectedClick(bank.id)} align={'right'}>
                    <Typography fontSize={'1.4rem'}>
                        {bank.officialRating} {bank.ratingAgency && '(' + bank.ratingAgency + ')'}
                    </Typography>
                </TableCell>
                <TableCell onClick={() => onSelectedClick(bank.id)} align={'right'}>
                    <Typography fontSize={'1.4rem'}>
                        {bankExposure[bank.id] ? `${bankExposure[bank.id]} ${t('pages-offer.mill')}` : ''}
                    </Typography>
                </TableCell>
                <TableCell align={'right'}>
                    <input
                        type="text"
                        className={styles.minVolume}
                        value={getBankAttribute(fields, bank.id, 'minVolume')}
                        onChange={(e) => onMinVolumeChange(bank.id, e.target.value)}
                        onBlur={submit}
                        placeholder={selected ? fields.minVolume.value : ''}
                    />
                </TableCell>
                <TableCell align={'right'}>
                    <input
                        type="text"
                        className={styles.maxVolume}
                        value={getBankAttribute(fields, bank.id, 'maxVolume')}
                        onChange={(e) => onMaxVolumeChange(bank.id, e.target.value)}
                        onBlur={submit}
                        placeholder={selected ? fields.maxVolume.value : ''}
                    />
                </TableCell>
            </TableRow>
        )
    })

    let instruction
    switch (selectedBankIds.length) {
        case 0:
            instruction = t('pages-offer.wizardBanksNoneSelected')
            break
        case 1:
            instruction = t('pages-offer.wizardBanksOneSelected')
            break
        default:
            instruction = t('pages-offer.wizardBanksMultipleSelected', { count: selectedBankIds.length })
            break
    }

    return (
        <Modal
            cancel={`/depositoroffer/${params.depositorOfferId}/edit/2`}
            header={t('pages-offer.wizardBanksMessage')}
        >
            <div className={styles.body}>
                <p>{instruction}</p>
                <div className={styles.tableScroll}>
                    <Table>
                        <TableHeader>
                            <TableHeaderCell>Bank</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.totalAssets')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-marketplace.officialRating')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.exposure')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.minAmount')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.maxAmount')}</TableHeaderCell>
                        </TableHeader>
                        {bankRows}
                    </Table>
                </div>
                <ButtonRow>
                    <Button id="closeButton" onClick={close}>
                        {t('common.close')}
                    </Button>
                </ButtonRow>
            </div>
        </Modal>
    )
}
