import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { InterestOutput } from '#components'
import { PURPLE } from '#app/colors/colors'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'
import { FundDto, FundShareClassDto } from '@fixrate/fixrate-query'
import usePlatformFeeVirtualValuta from '#services/platformFeeVirtualValuta'
import usePortfolio from '#services/usePortfolio'
import useShoppingCart from '#services/useShoppingCart'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useFundShareClassSelected from '#services/useFundShareClassSelected'

type Props = {
    fund: FundDto
}

export default function ShareClassTable({ fund }: Props) {
    const { t } = useTranslation()
    const { convertToVirtual } = usePlatformFeeVirtualValuta()
    const portfolio = usePortfolio()
    const depositor = useCurrentDepositor()
    const shoppingCart = useShoppingCart<FundDto>('FUNDS')
    const [fundClassId] = useFundShareClassSelected(fund.id, fund.fundShareClasses[0].id)
    if (!portfolio || !depositor || !shoppingCart) {
        return null
    }
    const feeModel = depositor.fundData?.feeModel
    if (!feeModel) {
        return null
    }
    const totalOrderVolume = shoppingCart.getTotalAmount()
    if (totalOrderVolume != null && totalOrderVolume < 0) {
        return null
    }

    const allSteps = Object.keys(feeModel.platformFeeSteps)
        .map((step) => +step)
        .sort((a, b) => a - b)
    const selectedStep = [...allSteps]
        .reverse()
        .find(
            (step) =>
                +step * 1e6 <=
                feeModel.marketValueVirtualValuta + convertToVirtual(portfolio.currency, totalOrderVolume ?? 0)
        )
    const platformFeeSteps = feeModel.platformFeeSteps
    const platformFee = platformFeeSteps[selectedStep][fund.fundCategory]

    const fundShareClasses: FundShareClassDto[] = Object.assign(
        [],
        Object.values(
            fund.fundShareClasses.filter(
                (shareClass) => shareClass.published || shareClass.tradableByDepositorIds?.includes(depositor?.id)
            )
        )
    ).sort((a, b) => {
        return (a.minimumInvestment ?? 0) - (b.minimumInvestment ?? 0)
    })

    const currencyOptions = {
        millionFormat: true,
        maximumDecimals: 0,
        minimumDecimals: 0,
        withCurrency: false,
    }

    return (
        <Table size={'small'}>
            <TableHead>
                <TableRow>
                    <TableCell align={'left'}></TableCell>
                    <TableCell align={'left'}>{t('components.ShareClassTable.shareClass')}</TableCell>
                    <TableCell align={'left'}>{t('components.ShareClassTable.investmentLimit')}</TableCell>
                    <TableCell align={'left'}>{t('components.ShareClassTable.totalCost')}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fundShareClasses.map((sc) => {
                    const isSelected = sc.id === fundClassId
                    const ongoingCharges = sc.ongoingCharges || sc.managementFee || 0
                    const totalCost = platformFee != null ? ongoingCharges + platformFee : undefined
                    return (
                        <TableRow
                            key={sc.id}
                            sx={
                                isSelected
                                    ? { backgroundColor: PURPLE[50], '& .MuiTableCell-root': { fontWeight: 700 } }
                                    : {}
                            }
                        >
                            <TableCell sx={{ pr: 1, pl: 1 }} align={'left'}>
                                {isSelected && (
                                    <Box>
                                        <i className="ri-arrow-right-line" />
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell align={'left'}>{sc.name}</TableCell>
                            <TableCell align={'left'}>
                                {sc?.minimumInvestment &&
                                    sc?.maximumInvestment &&
                                    CurrencyOutputObject(sc?.minimumInvestment, currencyOptions)}
                                {sc?.minimumInvestment &&
                                    sc?.maximumInvestment &&
                                    '-' + CurrencyOutputObject(sc?.maximumInvestment, currencyOptions) + 'M.'}
                                {sc?.minimumInvestment &&
                                    !sc?.maximumInvestment &&
                                    t('components-ShareClassSelect.from') +
                                        CurrencyOutputObject(sc?.minimumInvestment, currencyOptions) +
                                        'M.'}
                            </TableCell>
                            <TableCell align={'left'}>
                                {totalCost && InterestOutput.formatWithDecimals(totalCost, 3)}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}
