import { SUNSET_ORANGE } from '#app/colors/colors'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    onClose?: () => void
    title: string
    headerElement?: ReactNode
    children: ReactNode
    onBack?: () => void
    backText?: string
    onNext: () => void
    nextText?: string
    open: boolean
    skip?: boolean | false
}

export default function TourFrame({
    onClose,
    title,
    headerElement,
    children,
    onBack,
    backText,
    onNext,
    nextText,
    open,
    skip,
}: Props) {
    const { t } = useTranslation()

    return (
        <FxDialog data-cy="welcome-tour" open={open} onClose={onClose}>
            <Stack justifyContent={'center'} alignItems={'center'} sx={{ backgroundColor: SUNSET_ORANGE[50] }}>
                <IconButton
                    sx={{ position: 'absolute', right: '1.6rem', top: '1.6rem' }}
                    data-cy="closeDialogButton"
                    aria-label="close"
                    onClick={onClose}
                >
                    <i className="ri-close-line" />
                </IconButton>
                {headerElement}
            </Stack>
            <FxDialogContent>
                <Box sx={{ px: 2, pb: 3 }}>
                    <Box sx={{ pb: 1.5 }}>
                        <h2>{title}</h2>
                    </Box>
                    {children}
                    <Stack direction="row" sx={{ pt: 2 }} spacing={2} justifyContent={'space-between'}>
                        {onBack && <Button onClick={onBack}>{backText ? backText : t('common.back')}</Button>}
                        {skip ? (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button sx={{ mr: 2 }} onClick={onNext} variant="outlined">
                                    {t('common.skip')}
                                </Button>
                                <Button onClick={onNext} variant="contained">
                                    {nextText ? nextText : t('common.goToNext')}
                                </Button>
                            </div>
                        ) : (
                            <Button sx={{ marginLeft: 'auto' }} onClick={onNext} variant="contained">
                                {nextText ? nextText : t('common.goToNext')}
                            </Button>
                        )}
                    </Stack>
                </Box>
            </FxDialogContent>
        </FxDialog>
    )
}
