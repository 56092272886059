import AsyncButton from '#app/components/Button/AsyncButton'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useCommand } from '#command'
import HelpIcon from '#components/HelpIcon/HelpIcon'
import { useTranslation } from '#components/i18n'
import Modal from '#components/Modal'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './EditRole.module.scss'

type EditBankRoleProps = {
    userId: string
}

const EditBankRole = function ({ userId }: EditBankRoleProps) {
    const { t } = useTranslation('blocks-UsersList')

    const [roles, setRoles] = useState([])
    const [error, setError] = useState({
        roles: null,
    })
    const bank = useSelector(({ bank }) => bank)
    const session = useSelector(({ session }) => session)
    const user = bank.users.find((u) => u.id === userId)
    const userInvite = bank.userInvites?.find((userInvite) => userInvite.id === userId)
    const navigate = useNavigate()

    const { setBankUserRoles: setBankUserRolesCommand, setUserInviteRoles: setUserInviteRolesCommand } = useCommand()

    const isEditingOwnUser = session.id === userId

    let header
    if (user) {
        header = `${user?.firstName || ''} ${user?.lastName || ''}`.trim()
    } else {
        header = `${userInvite?.firstName || ''} ${userInvite?.lastName || ''}`.trim()
    }

    const viewRoleIsForced = roles.filter((r) => r !== 'BANK_VIEW').length > 0 && !roles.every((r) => r === 'BANK_VIEW')

    function validateRoles() {
        if (roles.length === 0) {
            throw t('rolesValidation')
        }
    }

    function roleElement({ role, title, description, forced = false, disabled = false }) {
        return (
            <li className={styles.roleelement}>
                <input
                    checked={forced || roles.includes(role)}
                    disabled={forced || disabled}
                    id={role}
                    onChange={() => {
                        if (roles.includes(role)) {
                            setRoles(roles.filter((r) => r !== role))
                        } else {
                            setRoles((prevState) => [...prevState, role])
                        }
                    }}
                    type="checkbox"
                />
                <div>
                    <label className={classNames({ [styles.roletextMainDisabled]: forced || disabled })} htmlFor={role}>
                        {title}
                    </label>
                    <div className={styles.roletextSub}>{description}</div>
                </div>
            </li>
        )
    }

    async function submit() {
        try {
            setError((prevState) => ({ ...prevState, roles: null }))
            validateRoles()
        } catch (err) {
            setError((prevState) => ({ ...prevState, roles: err }))
            return
        }

        if (user) {
            await setBankUserRoles(userId, bank.id, roles)
        } else if (userInvite) {
            await setUserInviteRoles(userInvite.id, bank.id, roles)
        }

        navigate('/my-bank#!')
    }

    async function setBankUserRoles(userId, bankId, roles) {
        try {
            await setBankUserRolesCommand(userId, bankId, roles)
        } catch (err) {
            console.error(err)
        }
    }

    async function setUserInviteRoles(inviteId, bankId, roles) {
        try {
            await setUserInviteRolesCommand(inviteId, bankId, roles)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (user?.roles || userInvite?.roles) {
            setRoles(user?.roles || userInvite?.roles)
        }
    }, [user?.roles, userInvite?.roles])

    return (
        <Modal cancel="/my-bank" header={header} onSubmit={submit}>
            <div className={styles.form}>
                <div style={{ display: 'flex' }}>
                    <h3 className={styles.inputlabel}>{t('rolesHeading')}</h3>
                    <HelpIcon supportCommonName="rolesBank" helpContextPage="EditBankRole" />
                </div>
                <p>{t('rolesMessage')}</p>
                <ul className={styles.roleList}>
                    {roleElement({
                        role: 'BANK_VIEW',
                        title: t('bankRoleViewTitle'),
                        description: t('bankRoleViewDescription'),
                        forced: viewRoleIsForced,
                    })}
                    {roleElement({
                        role: 'BANK_AD_MANAGER',
                        title: t('bankRoleAdManagerTitle'),
                        description: t('bankRoleAdManagerDescription'),
                    })}
                    {roleElement({
                        role: 'BANK_CUSTOMER_SERVICE',
                        title: t('bankRoleCustomerServiceTitle'),
                        description: t('bankRoleCustomerServiceDescription'),
                    })}
                    {roleElement({
                        role: 'BANK_ADMIN',
                        title: t('bankRoleAdminTitle'),
                        description: t('bankRoleAdminDescription'),
                        disabled: isEditingOwnUser,
                    })}
                    {roleElement({
                        role: 'BANK_COORDINATOR',
                        title: t('bankRoleCoordinatorTitle'),
                        description: t('bankRoleCoordinatorDescription'),
                    })}
                </ul>
                <p className="field-error-message">{error.roles}</p>
            </div>
        </Modal>
    )
}

type EditPartnerRoleProps = {
    userId: string
}

const EditPartnerRole = function ({ userId }: EditPartnerRoleProps) {
    const { t } = useTranslation('blocks-UsersList')
    const navigate = useNavigate()

    const [roles, setRoles] = useState([])
    const [error, setError] = useState({
        roles: null,
    })
    const partner = useSelector(({ partner }) => partner)
    const session = useSelector(({ session }) => session)
    const user = partner.users.find((u) => u.id === userId)
    const userInvite = partner.userInvites[userId]

    const { setPartnerUserRoles: setPartnerUserRolesCommand, setUserInviteRoles: setUserInviteRolesCommand } =
        useCommand()

    const isEditingOwnUser = session.id === userId

    const viewRoleIsForced =
        roles.filter((r) => r !== 'PARTNER_VIEW').length > 0 && !roles.every((r) => r === 'PARTNER_VIEW')

    function validateRoles() {
        if (roles.length === 0) {
            throw t('rolesValidation')
        }
    }

    function roleElement({ role, title, description, forced = false, disabled = false }) {
        return (
            <li className={styles.roleelement}>
                <input
                    checked={forced || roles.includes(role)}
                    disabled={forced || disabled}
                    id={role}
                    onChange={() => {
                        if (roles.includes(role)) {
                            setRoles(roles.filter((r) => r !== role))
                        } else {
                            setRoles((prevState) => [...prevState, role])
                        }
                    }}
                    type="checkbox"
                />
                <div>
                    <label className={classNames({ [styles.roletextMainDisabled]: forced || disabled })} htmlFor={role}>
                        {title}
                    </label>
                    <div className={styles.roletextSub}>{description}</div>
                </div>
            </li>
        )
    }

    async function submit() {
        try {
            setError((prevState) => ({ ...prevState, roles: null }))
            validateRoles()
        } catch (err) {
            setError((prevState) => ({ ...prevState, roles: err }))
            return
        }

        if (user) {
            await setPartnerUserRoles(userId, partner.id, roles)
        } else if (userInvite) {
            await setUserInviteRoles(userInvite.id, partner.id, roles)
        }

        navigate('/partner/users#!')
    }

    async function setPartnerUserRoles(userId, bankId, roles) {
        try {
            await setPartnerUserRolesCommand(userId, bankId, roles)
        } catch (err) {
            console.error(err)
        }
    }

    async function setUserInviteRoles(inviteId, bankId, roles) {
        try {
            await setUserInviteRolesCommand(inviteId, bankId, roles)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (user?.roles || userInvite?.roles) {
            setRoles(user?.roles || userInvite?.roles)
        }
    }, [user?.roles, userInvite?.roles])

    return (
        <FxDialog onClose={() => navigate('/partner/users')} open={true}>
            <FxDialogTitle supportCommonName="roles" onClose={() => navigate('/partner/users')}>
                {t('rolesHeading')}
            </FxDialogTitle>
            <FxDialogContent>
                <Box>
                    <div className={styles.form}>
                        <p>{t('rolesMessage')}</p>
                        <ul className={styles.roleList}>
                            {roleElement({
                                role: 'PARTNER_VIEW',
                                title: t('partnerRoleViewTitle'),
                                description: t('partnerRoleViewDescription'),
                                forced: viewRoleIsForced,
                            })}
                            {roleElement({
                                role: 'PARTNER_ADVISOR',
                                title: t('partnerRoleAdvisorTitle'),
                                description: t('partnerRoleAdvisorDescription'),
                            })}
                            {partner.partnerCategory === 'SUPPORT' &&
                                roleElement({
                                    role: 'PARTNER_BANKSUPPORT',
                                    title: t('partnerRoleBankSupportTitle'),
                                    description: t('partnerRoleBankSupportDescription'),
                                })}
                            {partner.partnerCategory === 'SUPPORT' &&
                                roleElement({
                                    role: 'PARTNER_BANKSUPPORT_ADMIN',
                                    title: t('partnerRoleBankSupportAdminTitle'),
                                    description: t('partnerRoleBankSupportAdminDescription'),
                                })}
                            {roleElement({
                                role: 'PARTNER_ADMIN',
                                title: t('partnerRoleAdminTitle'),
                                description: t('partnerRoleAdminDescription'),
                                disabled: isEditingOwnUser,
                            })}
                        </ul>
                        <p className="field-error-message">{error.roles}</p>
                    </div>
                </Box>
            </FxDialogContent>
            <FxDialogActions>
                <AsyncButton onClick={submit} variant="contained">
                    {t('save')}
                </AsyncButton>
            </FxDialogActions>
        </FxDialog>
    )
}

const EditRole = function ({ organisationType, userId }: { organisationType: OrganisationType; userId: string }) {
    switch (organisationType) {
        case 'BANK':
            return <EditBankRole userId={userId} />
        case 'PARTNER':
            return <EditPartnerRole userId={userId} />
        default:
            return null
    }
}

export default EditRole
