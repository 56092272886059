import { useSelector } from '#app/state/useSelector'
import ReportDownloadExcel from '#components/ReportDownloadExcel/ReportDownloadExcel'
import * as selectors from '#state/selectors'
import { Box, MenuItem, Select, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DepositDto } from '@fixrate/fixrate-query'
import { PortfolioFilter } from '#state/types'

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

function PortfolioDepositorOverviewHeader({
    deposits,
    onChange,
    filter,
}: {
    deposits: DepositDto[]
    onChange: (filter: PortfolioFilter) => void
    filter: PortfolioFilter
}) {
    const { t } = useTranslation()

    const bankNames = useSelector((state) => selectors.bankNames(state))

    const bankOptions = Object.keys(deposits)
        .map((depositId) => deposits[depositId].bankId)
        .filter(onlyUnique)
        .map((bankId) => ({ id: bankId, name: bankNames[bankId] }))
        .sort((a, b) => {
            if (!('name' in a) || !('name' in b)) {
                return 0
            }
            if (a.name === undefined || b.name === undefined) return 0
            return a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : 0
        })

    const tagOptions = deposits
        .filter((deposit) => deposit.tagText)
        .filter(onlyUnique)
        .map((deposit) => ({ tagText: deposit.tagText, tagColor: deposit.tagColor }))

    const onSelectBankFilter = (e) => {
        onChange({
            ...filter,
            bankId: e.target.value !== 'all' ? e.target.value : undefined,
        })
    }

    const onSelectTagFilter = (e) => {
        onChange({
            ...filter,
            tagText: e.target.value !== 'all' ? e.target.value : undefined,
        })
    }

    return (
        deposits.length > 0 && (
            <Stack
                direction={'row'}
                spacing={2}
                mt={4}
                justifyContent={'space-between'}
                maxWidth={'120rem'}
                flexWrap="wrap"
            >
                <Stack direction={{ md: 'row' }} spacing={2}>
                    <Select
                        sx={{ maxWidth: '20rem' }}
                        onChange={onSelectBankFilter}
                        value={filter.bankId ? filter.bankId : 'all'}
                    >
                        <MenuItem value="all">{t('pages-marketplace.allBanks')}</MenuItem>
                        {bankOptions.map((bank) => (
                            <MenuItem key={bank.id} value={bank.id}>
                                {bank.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {tagOptions.length > 0 && (
                        <Select
                            sx={{ maxWidth: '20rem' }}
                            onChange={onSelectTagFilter}
                            value={filter.tagText ? filter.tagText : 'all'}
                        >
                            <MenuItem value="all">{t('pages-portfolio-depositor.filterAllTags')}</MenuItem>
                            {tagOptions.map((tag) => (
                                <MenuItem key={tag.tagText} value={tag.tagText}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Box
                                            sx={{ backgroundColor: tag.tagColor }}
                                            width={'1rem'}
                                            height={'1rem'}
                                        ></Box>
                                        <Box>{tag.tagText}</Box>
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Stack>
                <ReportDownloadExcel report="DEPOSITS" />
            </Stack>
        )
    )
}

export default PortfolioDepositorOverviewHeader
