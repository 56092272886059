import { FixrateColor, PURPLE } from '#app/colors/colors'
import { IconButton, Stack, SxProps, Tooltip, Typography } from '@mui/material'

const KeyFigureCard = ({
    value,
    label,
    remixIcon,
    color,
    children,
    sx,
    disclaimer,
    direction,
}: {
    value: string
    label: string
    remixIcon: string
    color?: FixrateColor
    children?: JSX.Element
    sx?: SxProps
    disclaimer?: string
    direction?: 'row' | 'column'
}) => {
    const COLOR: FixrateColor = color ? color : PURPLE
    return (
        <Stack
            direction={direction || 'column'}
            spacing={1.5}
            alignItems={direction === 'row' ? 'center' : 'flex-start'}
            px={3}
            py={3}
            bgcolor={COLOR[25]}
            borderRadius={'0.8rem'}
            sx={sx}
        >
            <Stack
                sx={{
                    width: 50,
                    height: 50,
                    fontSize: 24,
                    backgroundColor: COLOR[300],
                    mb: direction === 'column' ? 1 : 0,
                    borderRadius: '10rem',
                    color: 'white',
                }}
                justifyContent="center"
                alignItems="center"
            >
                <i className={remixIcon} />
            </Stack>
            <Stack>
                <Typography fontSize={'2.4rem'} fontWeight={700} color={PURPLE[900]}>
                    {value}
                </Typography>
                <Stack direction="row">
                    <Typography fontSize={'1.6rem'} color={PURPLE[900] + '90'}>
                        <span>{label}</span>
                    </Typography>
                    {disclaimer && (
                        <Tooltip title={disclaimer}>
                            <IconButton color="primary" size="small" sx={{ ml: 0.3, mt: -0.4 }}>
                                <i className="ri-information-line" />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                {children}
            </Stack>
        </Stack>
    )
}

export { KeyFigureCard }
