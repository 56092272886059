import React, { useState } from 'react'
import { useCommand } from '#command'
import { useDispatch } from 'react-redux'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material'
import { DatePicker } from '#components'
import { LoadingButton } from '@mui/lab'
import { PlacementRow } from '#pages/FundAdminOverview/FundPlacements/AdminFundPlacementTable'
import { useTranslation } from 'react-i18next'
import { FundShareClassDto } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'

const DetailRow = ({ children, title }: { children: React.ReactNode; title: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: 'lightgray 1px solid',
                marginBottom: 4,
                paddingBottom: 4,
            }}
        >
            <Typography variant={'body1'}>{title}:</Typography>
            {children}
        </Box>
    )
}

export default function ChangeFundShareClassDialog({ pr, onClose }: { pr: PlacementRow; onClose: () => void }) {
    const { t } = useTranslation()
    const [fromShareClassId, setFromShareClassId] = useState<string | null>(pr.fundShareClassId)
    const [toShareClassId, setToShareClassId] = useState<string | null>(null)
    const [toFundId, setToFundId] = useState<string | null>(pr.fundId)
    const [allowFundChange, setAllowFundChange] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [transactionDate, setTransactionDate] = useState<Date | null>(null)
    const [operationType, setOperationType] = useState('EXCHANGE')
    const { changeFundPlacementShareClass } = useCommand()
    const { moveFundPlacementShareClass } = useCommand()
    const dispatch = useDispatch()
    const funds = useSelector((state) => state.funds)
    if (!pr || !funds) {
        return null
    }
    const availableToShareClasses = funds.find((f) => f.id === toFundId).fundShareClasses

    function closeDialog() {
        setFromShareClassId(pr.fundShareClassId)
        setToFundId(pr.fundId)
        setToShareClassId(null)
        setTransactionDate(null)
        setAllowFundChange(false)
        onClose()
    }

    function onFromShareClassIdChange(id: string) {
        if (pr.fundShareClasses.some((sc) => sc.id === id)) {
            setFromShareClassId(id)
        }
    }

    function onToFundIdChange(id: string) {
        if (funds.some((f) => f.id === id)) {
            setToFundId(id)
        }
    }

    function onToShareClassIdChange(id: string) {
        if (availableToShareClasses.some((sc) => sc.id === id && sc.fundId === toFundId)) {
            setToShareClassId(id)
        }
    }

    function onAllowFundChangeChange(allow: boolean) {
        if (!allow) {
            setToFundId(pr.fundId)
        }
        setAllowFundChange(allow)
    }

    async function handleSubmit() {
        if (!pr) return
        const fromShareClass = pr.fundShareClasses.find((sc) => sc.id === fromShareClassId)
        const toShareClass = availableToShareClasses.find((sc) => sc.id === toShareClassId)
        const toFund = funds.find((f) => f.id === toFundId)
        if (!fromShareClass || !toShareClass || !toFund) {
            return
        }
        setSubmitting(true)
        let success = false
        if (operationType === 'EXCHANGE') {
            const { waitForCommand } = await changeFundPlacementShareClass(
                pr.id,
                fromShareClass.id,
                toShareClass.id,
                toFund.id,
                transactionDate
            )
            success = await waitForCommand()
        } else if (operationType === 'MOVE') {
            const { waitForCommand } = await moveFundPlacementShareClass(
                pr.id,
                fromShareClass.id,
                toShareClass.id,
                toFund.id,
                transactionDate
            )
            success = await waitForCommand()
        } else {
            return
        }
        if (success) {
            dispatch(
                showConfirmationModal({
                    title: t('pages.adminFundPlacements.changeShareClassDialog.confirmationModal.title'),
                    text: t('pages.adminFundPlacements.changeShareClassDialog.confirmationModal.text', {
                        fromShareClassName: fromShareClass.fullName,
                        toShareClassName: toShareClass.fullName,
                    }),
                    buttonText: t('pages.adminFundPlacements.changeShareClassDialog.confirmationModal.buttonText'),
                })
            )
            closeDialog()
        }
        setSubmitting(false)
    }

    function isSubmitDisabled(): boolean {
        return !toShareClassId || !toFundId || toShareClassId === fromShareClassId || !transactionDate
    }

    const shareClassSelectionIsValid =
        toShareClassId &&
        fromShareClassId &&
        toShareClassId !== fromShareClassId &&
        availableToShareClasses.some((sc) => sc.id === toShareClassId && sc.fundId === toFundId)

    return (
        <Dialog open={pr !== null} onClose={closeDialog} fullWidth maxWidth={'md'}>
            <DialogTitle>{t('pages.adminFundPlacements.changeShareClassDialog.title')}</DialogTitle>
            {pr && (
                <DialogContent>
                    <DetailRow title={t('pages.adminFundPlacements.changeShareClassDialog.depositor')}>
                        {pr.depositorName}
                    </DetailRow>
                    <DetailRow title={'Andelsklassebytte (EXCHANGE) eller flytting (MOVE)?'}>
                        <ActionTypeDropdown value={operationType} onChange={setOperationType} />
                    </DetailRow>
                    <DetailRow title={'Fra fond'}>{pr.fund.name}</DetailRow>
                    <DetailRow title={t('pages.adminFundPlacements.changeShareClassDialog.fromShareClass')}>
                        <ShareClassDropdown
                            shareClasses={pr.fundShareClasses}
                            value={fromShareClassId}
                            disabledItemText={t(
                                'pages.adminFundPlacements.changeShareClassDialog.selectFromShareClass'
                            )}
                            onShareClassSelect={onFromShareClassIdChange}
                        />
                    </DetailRow>
                    <DetailRow title={'Er den nye andelsklassen i et annet fond?'}>
                        <Select
                            onChange={(e: SelectChangeEvent) => onAllowFundChangeChange(e.target.value === 'true')}
                            displayEmpty={true}
                            value={allowFundChange ? 'true' : 'false'}
                        >
                            <MenuItem value={'true'}>Ja</MenuItem>
                            <MenuItem value={'false'}>Nei</MenuItem>
                        </Select>
                    </DetailRow>
                    <DetailRow title={'Til fond'}>
                        <FundDropdown
                            value={toFundId}
                            disabled={!allowFundChange}
                            disabledItemText={'Velg fond (til)'}
                            onFundSelect={onToFundIdChange}
                        />
                    </DetailRow>
                    <DetailRow title={t('pages.adminFundPlacements.changeShareClassDialog.toShareClass')}>
                        <ShareClassDropdown
                            data-cy="shareClassDropdown"
                            shareClasses={availableToShareClasses}
                            value={toShareClassId}
                            disabledItemText={t('pages.adminFundPlacements.changeShareClassDialog.selectToShareClass')}
                            onShareClassSelect={onToShareClassIdChange}
                        />
                    </DetailRow>
                    {shareClassSelectionIsValid && (
                        <div>
                            <DetailRow title={t('pages.adminFundPlacements.changeShareClassDialog.transactionDate')}>
                                <DatePicker
                                    id="transactionDateInput"
                                    selected={transactionDate}
                                    onChange={(date) => setTransactionDate(date)}
                                    inputProps={{
                                        label: t('pages.adminFundPlacements.changeShareClassDialog.transactionDate'),
                                        helperText:
                                            !transactionDate &&
                                            t(
                                                'pages.adminFundPlacements.changeShareClassDialog.transactionDateHelperText'
                                            ),
                                        error: !transactionDate,
                                    }}
                                />
                            </DetailRow>
                            <DialogActions title={t('pages.adminFundPlacements.changeShareClassDialog.performChanges')}>
                                <Button data-cy="cancelButton" onClick={closeDialog}>
                                    {t('pages.adminFundPlacements.changeShareClassDialog.cancel')}
                                </Button>
                                <LoadingButton
                                    data-cy="performShareClassChangeButton"
                                    variant={'contained'}
                                    disabled={isSubmitDisabled()}
                                    loading={submitting}
                                    onClick={handleSubmit}
                                >
                                    {t('pages.adminFundPlacements.changeShareClassDialog.performChanges')}
                                </LoadingButton>
                            </DialogActions>
                        </div>
                    )}
                </DialogContent>
            )}
        </Dialog>
    )
}

type FundDropdownProps = {
    value: string
    disabled: boolean
    disabledItemText: string
    onFundSelect: (value: string) => void
}

function FundDropdown({ value, disabled, disabledItemText, onFundSelect }: FundDropdownProps) {
    const funds = useSelector((state) => state.funds)
    return (
        <Select
            sx={{
                minWidth: '30rem',
                maxWidth: '60rem',
                '& .MuiSelect-select': {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    maxHeight: '100%',
                },
            }}
            onChange={(e: SelectChangeEvent) => onFundSelect(e.target.value)}
            displayEmpty={true}
            value={value ?? ''}
            disabled={disabled}
        >
            <MenuItem disabled value={''}>
                {disabledItemText}
            </MenuItem>
            {Object.values(funds)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((f) => (
                    <MenuItem key={f.id} value={f.id}>
                        {f.name}
                    </MenuItem>
                ))}
        </Select>
    )
}

type ShareClassDropdownProps = {
    'data-cy'?: string
    shareClasses: FundShareClassDto[]
    value: string
    disabledItemText: string
    onShareClassSelect: (value: string) => void
}

function ShareClassDropdown({
    shareClasses,
    value,
    disabledItemText,
    onShareClassSelect,
    'data-cy': dataCy,
}: ShareClassDropdownProps) {
    return (
        <Select
            data-cy={dataCy}
            // autoWidth
            sx={{
                minWidth: '30rem',
                maxWidth: '60rem',
                '& .MuiSelect-select': {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    maxHeight: '100%',
                },
            }}
            onChange={(e: SelectChangeEvent) => onShareClassSelect(e.target.value)}
            displayEmpty={true}
            value={value ?? ''}
        >
            <MenuItem disabled value={''}>
                {disabledItemText}
            </MenuItem>
            {shareClasses.map((sc) => (
                <MenuItem key={sc.id} value={sc.id}>
                    {sc.fullName} ({sc.isin})
                </MenuItem>
            ))}
        </Select>
    )
}

function ActionTypeDropdown({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    return (
        <Select onChange={(e: SelectChangeEvent) => onChange(e.target.value)} displayEmpty={true} value={value ?? ''}>
            <MenuItem value={'EXCHANGE'}>EXCHANGE</MenuItem>
            <MenuItem value={'MOVE'}>MOVE</MenuItem>
        </Select>
    )
}
