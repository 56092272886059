import { useState } from 'react'
import styles from './TagSelector.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import { setTag } from '#services/thunks/deposits'
import { useTranslation } from 'react-i18next'
import { DepositDto, OrderDto } from '@fixrate/fixrate-query'

function ColorSelector({ color, onChange }: { color: string; onChange: (color: string) => void }) {
    const colors = ['#56CCF2', '#3E5641', '#2D232E', '#0B6E4F', '#99621E', '#721817', '#FA9F42', '#2B4162']

    const colorElements = colors.map((colorElement) => {
        const selected = color === colorElement
        const style = {
            backgroundColor: colorElement,
            border: '2px solid ' + (selected ? colorElement : 'white'),
        }
        return (
            <div
                key={colorElement}
                className={styles.colorElement}
                style={style}
                onClick={() => onChange(colorElement)}
            />
        )
    })

    return <div className={styles.colors}>{colorElements}</div>
}

export default function TagSelector({ deposit, order }: { deposit?: DepositDto; order?: OrderDto }) {
    const { t } = useTranslation()
    const [tagText, setTagText] = useState<string>(
        deposit?.tagText || order?.tagText ? deposit?.tagText || order?.tagText : ''
    )
    const [tagColor, setTagColor] = useState<string>(
        deposit?.tagColor || order?.tagColor ? deposit?.tagColor || order?.tagColor : '#666666'
    )
    const [tainted, setTainted] = useState(false)
    const deposits = useSelector((state) => state.deposits)
    const dispatch = useDispatch()

    const session = useSelector(({ session }) => session)
    const association = session.associations.find(
        (a) => a.organisation?.id === (deposit?.depositor?.id || order?.depositor?.id)
    )
    const access = association?.roles?.some(
        (role) => role === 'DEPOSITOR_ADMIN' || role === 'DEPOSITOR_ORDER' || role === 'DEPOSITOR_ACCOUNT_HOLDER'
    )

    const onTagColorChange = (tagColor: string) => {
        setTagColor(tagColor)
        setTainted(true)
    }

    const onTagChange = (tag: string) => {
        setTagText(tag)
        setTainted(true)
    }

    const submitTagChange = () => {
        if (tainted) {
            dispatch(setTag(order?.id, deposit?.id, tagText, tagColor))
        }
    }

    const onTemplateSelected = (text: string, color: string) => {
        onTagChange(text)
        onTagColorChange(color)
    }

    const onlyUniqueTags = (
        value: { text: string; color: string },
        index: number,
        self: {
            text: string
            color: string
        }[]
    ) => self.findIndex((tag) => tag.text === value.text && tag.color === value.color) === index

    const tagTemplates = deposits
        .map((d) => ({ text: d.tagText, color: d.tagColor }))
        .filter((tag) => !!tag.text && !!tag.color)
        .filter(onlyUniqueTags)

    let trigger = null
    if (tagText) {
        trigger = (
            <div className={classNames(styles.badge, access && styles.badgeEdit)} style={{ backgroundColor: tagColor }}>
                {tagText}
            </div>
        )
    } else {
        if (access) {
            trigger = (
                <div className={classNames(styles.badge, styles.createNew)}>
                    {t('pages-portfolio-depositor.tagsCreateTag')}
                </div>
            )
        }
    }

    const tagTemplateElements = tagTemplates.map((template) => (
        <div
            key={template.text + template.color}
            className={styles.deposittagBadge}
            style={{ backgroundColor: template.color, borderColor: template.color }}
            onClick={() => {
                onTemplateSelected(template.text, template.color)
            }}
        >
            {template.text}
        </div>
    ))

    if (tagText) {
        tagTemplateElements.push(
            <div
                key="clear"
                className={[styles.deposittagBadge, styles.deposittagBadgeRemove].join(' ')}
                onClick={() => {
                    onTemplateSelected('', '')
                }}
            >
                {t('pages-portfolio-depositor.tagsRemoveTag')}
            </div>
        )
    }

    return (
        <Popup
            disabled={!access}
            trigger={trigger}
            position="bottom left"
            contentStyle={{ width: 'auto', padding: 0 }}
            closeOnDocumentClick
            onClose={submitTagChange}
        >
            <div className={styles.body}>
                <div className={styles.deposittagHeader}>{t('pages-portfolio-depositor.tagsPopupHeader')}</div>
                <div className={styles.deposittagCustom}>
                    <div className={styles.deposittagCustomText}>
                        <label className={styles.deposittagCustomLabel}>
                            {t('pages-portfolio-depositor.tagsTextLabel')}:
                        </label>
                        <input
                            className={styles.deposittagCustomTextInput}
                            type="text"
                            value={tagText}
                            onChange={(e) => onTagChange(e.target.value)}
                            maxLength={9}
                        />
                    </div>
                    <div className={styles.deposittagCustomColor}>
                        <label className={styles.deposittagCustomLabel}>
                            {t('pages-portfolio-depositor.tagsColorLabel')}:
                        </label>
                        <ColorSelector color={tagColor} onChange={onTagColorChange} />
                    </div>
                </div>
                <div className={styles.deposittagTemplates}>{tagTemplateElements}</div>
            </div>
        </Popup>
    )
}
