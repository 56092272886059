import AsyncButton from '#app/components/Button/AsyncButton'
import PhoneNumber from '#app/components/PhoneNumber'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useCommand } from '#command'
import Paper from '#app/components/Paper/FxPaper'
import { useFieldState } from '@fixrate/fieldstate'
import { validateEmailAddress } from '#services/validateFields'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { InputLabel, Stack, TextField } from '@mui/material'
import { parsePhoneNumber } from 'awesome-phonenumber'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styles from './Profile.module.scss'

export default function ProfileEditor() {
    const { t } = useTranslation()

    const identificationDocument = useSelector((state) => state.identificationDocument)
    const profile = useSelector((state) => state.profile)
    const organisationCountry = useCurrentCountryCode()

    const emailValidator = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('pages-profile.emailMissing')
            }
            if (!isEditing && !validateEmailAddress(value)) {
                return t('pages-profile.emailInvalid')
            }
        },
        [t]
    )
    const email = useFieldState(profile.email, emailValidator)

    const [phoneNumber, setPhoneNumber] = useState(profile.mobile)
    const [validPhoneNumber, setValidPhoneNumber] = useState(false)
    const { updateProfile: updateProfileCommand } = useCommand()
    const dispatch = useDispatch()
    const parsedPhoneNumber = parsePhoneNumber(profile.mobile)
    const initialCountry = parsedPhoneNumber?.regionCode || organisationCountry

    async function onSubmit() {
        if (email.valid && validPhoneNumber) {
            const update = {
                email: email.value,
                mobile: phoneNumber,
            }

            const hasIdDocument = !!identificationDocument.id

            const { waitForCommand } = await updateProfileCommand(update)
            const success = await waitForCommand()

            if (success) {
                dispatch(
                    showConfirmationModal({
                        title: t('pages-profile.profileUpdated'),
                        text:
                            t('pages-profile.profileUpdatedVerbose') + hasIdDocument
                                ? ' ' + t('pages-profile.mustResignIsDoc')
                                : '',
                        buttonText: t('common.ok'),
                    })
                )
                email.untouch()
            }
        }
    }

    const changed =
        email.value !== profile.email ||
        parsedPhoneNumber?.number?.national !== parsePhoneNumber(profile.mobile)?.number?.national

    return (
        <Paper id="contact" title={t('pages-profile.contactInformation')}>
            <Stack spacing={2} alignItems="flex-start">
                <Stack>
                    <InputLabel>{t('pages-profile.email')}</InputLabel>
                    <TextField
                        value={email.value}
                        type="email"
                        className={styles.profile__emailInput}
                        name="email"
                        onBlur={email.onBlur}
                        onChange={(e) => email.setValue(e.target.value)}
                        required
                    />
                    <p className="field-error-message">{email.errorMessage}</p>
                </Stack>
                <Stack>
                    <InputLabel>{t('pages-profile.phone')}</InputLabel>
                    <PhoneNumber
                        setValid={(valid) => setValidPhoneNumber(valid)}
                        setNumber={(number) => setPhoneNumber(number)}
                        initialNumber={parsedPhoneNumber?.number?.input}
                        initialCountry={initialCountry as 'NO' | 'SE'}
                    />
                    <p className="field-error-message">
                        {!phoneNumber && t('pages-profile.phoneMissing')}
                        {phoneNumber && !validPhoneNumber && t('pages-profile.phoneInvalid')}
                    </p>
                </Stack>
                <AsyncButton
                    disabled={
                        !(
                            email.value !== profile.email ||
                            parsePhoneNumber(phoneNumber)?.number?.national !== parsedPhoneNumber?.number?.national ||
                            !validPhoneNumber
                        )
                    }
                    onClick={onSubmit}
                >
                    {t('pages-profile.save')}
                </AsyncButton>
                {changed && <p className="field-info-message">{t('pages-profile.unsavedChanges')}</p>}
            </Stack>
        </Paper>
    )
}
