import FundReturnsChart from '#app/blocks/FundReturnsChart/FundReturnsChart'
import KeyFundFigures from '#app/pages/FundDetails/Components/KeyFundFigures'
import { FundLogo } from '#app/pages/FundDetails/FundLogo/FundLogo'
import { useLocalStorage } from '#app/services/LocalStorageContext'
import useShoppingCart from '#app/services/useShoppingCart'
import { useSelector } from '#app/state/useSelector'
import { Box, Button, Divider, Drawer, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import List, { ListItem } from '#components/List/List'
import { InterestOutput } from '#components'
import { useFundPlatformFee } from '#services/platformFeeCalculator'
import FundReturnsDisclaimer from '#pages/FundDetails/Components/FundReturnsDisclaimer'
import { MorningstarRating } from '#components/MorningstarRating/MorningstarRating'
import { useLocalizedString } from '#services/useLocalizedString'

type Props = {
    shareClassId: string
}

//TODO: Move this to parameter based instead of using localstoragecontext

export default function FundInfoSlideOut({ shareClassId }: Props) {
    const { t } = useTranslation()
    const { dispatchLocalStorage } = useLocalStorage()
    const navigate = useNavigate()
    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(shareClassId)
    const fund = useSelector((state) =>
        state.funds?.find((f) => f.fundShareClasses.some((fsc) => fsc.id === shareClassId))
    )
    const shareClass = fund?.fundShareClasses?.find((sc) => sc.id === shareClassId)
    const yPadding = 3
    const xPadding = 2
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { fundCategory } = useParams()
    const { calculatePlatformFee, calculateTotalCosts } = useFundPlatformFee()
    const fundSummary = useLocalizedString(fund?.fundSummary)

    const readMoreAboutFund = () => {
        dispatchLocalStorage({ type: 'SHARE_CLASS_FOR_SLIDEOUT', payload: '' })
        navigate('/marketplace/category/funds/' + fundCategory + '/' + fund?.id)
    }

    const platformFee = fund && calculatePlatformFee(fund?.fundCategory, shareClass)
    const totalCosts = fund && calculateTotalCosts(fund?.fundCategory, shareClass)

    return (
        <Drawer
            open={!!shareClass}
            anchor={isMobile ? 'bottom' : 'right'}
            onClose={() => dispatchLocalStorage({ type: 'SHARE_CLASS_FOR_SLIDEOUT', payload: '' })}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    maxHeight: isMobile ? '80%' : '100%',
                },
            }}
        >
            {shareClass && fund && (
                <>
                    <Stack maxWidth="100%" direction={'column'} pl={2} py={0.5} spacing={1}>
                        <Stack direction="column" alignItems="flex-end">
                            <Box
                                sx={{ '& img': { width: '100%' } }}
                                width={'15rem'}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <FundLogo fundName={shareClass.fullName} />
                            </Box>
                        </Stack>
                        <Stack maxWidth={{ xs: '46rem', md: '46rem' }} spacing={1}>
                            <Typography variant="h2">{shareClass.fullName}</Typography>
                            {fund.morningstarRating && (
                                <MorningstarRating rating={fund.morningstarRating} sx={{ pb: 1 }} />
                            )}
                            <Button
                                onClick={() => readMoreAboutFund()}
                                sx={{ mb: 2 }}
                                size={isMobile ? 'small' : 'medium'}
                                variant="outlined"
                            >
                                {t('page-fund-details.readMoreAboutFund')}
                            </Button>
                        </Stack>
                    </Stack>

                    <Divider />
                    <Stack width={'50rem'} maxWidth="100%">
                        <Box px={xPadding} pt={2} pb={yPadding}>
                            <FundReturnsChart
                                defaultSelectedPeriod="PERIOD_1Y"
                                fundShareClass={shareClass}
                                options={{
                                    aspectRatio: { height: 4, width: 10 },
                                    maxTicksLimit: { x: 6, y: 5 },
                                    periodFilter: [
                                        'PERIOD_10Y',
                                        'PERIOD_5Y',
                                        'PERIOD_3Y',
                                        'PERIOD_1Y',
                                        'PERIOD_3M',
                                        'PERIOD_6M',
                                    ],
                                }}
                            />
                            <FundReturnsDisclaimer shareClass={shareClass} />
                        </Box>
                        <Divider />
                        <Stack alignItems="flex-start" px={xPadding} py={yPadding} pb={2} spacing={2}>
                            <Stack spacing={1} alignItems="flex-start">
                                <Typography variant="h3">{t('pages-fund-details.aboutFund')}</Typography>
                                <Typography
                                    fontSize="1.4rem"
                                    overflow={'hidden'}
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{ WebkitLineClamp: 4, WebkitBoxOrient: 'vertical' }}
                                >
                                    {fundSummary}
                                </Typography>
                            </Stack>
                            <Box width={'100%'}>
                                <KeyFundFigures
                                    figureFilter={[
                                        'totalAssets',
                                        'effectiveInterestRate',
                                        'standardDeviation',
                                        '3y-return',
                                    ]}
                                    fund={fund}
                                    shareClass={shareClass}
                                />
                            </Box>
                            <Box width={'100%'}>
                                <Typography variant="h3">{t('pages-fund-details.costs')}</Typography>
                                {platformFee !== undefined && (
                                    <List>
                                        <ListItem
                                            data-cy="totalCosts"
                                            infoText={t('pages-fund-details.totalCostsExplanation')}
                                            label={t('pages-fund-details.totalCosts')}
                                            value={InterestOutput.formatRange(
                                                totalCosts?.from,
                                                totalCosts?.to,
                                                2,
                                                true
                                            )}
                                        />
                                        <ListItem
                                            data-cy="ongoingCharges"
                                            infoText={t('pages-fund-details.ongoingChargesExplanation')}
                                            label={t('pages-fund-details.ongoingCharges')}
                                            value={InterestOutput.formatWithDecimals(
                                                shareClass.ongoingCharges || shareClass.managementFee,
                                                3
                                            )}
                                            disabled
                                        />
                                        <ListItem
                                            data-cy="platformFee"
                                            infoText={t('pages-fund-details.platformFeeExplanation')}
                                            label={t('pages-fund-details.platformFee')}
                                            value={InterestOutput.formatRange(
                                                platformFee?.from,
                                                platformFee?.to,
                                                2,
                                                true
                                            )}
                                            disabled
                                        />
                                    </List>
                                )}
                            </Box>
                        </Stack>
                    </Stack>
                    <Box mt="auto">
                        <Divider />
                        <Stack direction="row" p={2} spacing={1} justifyContent="end">
                            <Button
                                size={isMobile ? 'small' : 'medium'}
                                variant="outlined"
                                onClick={() => readMoreAboutFund()}
                            >
                                {t('page-fund-details.readMoreAboutFund')}
                            </Button>
                            {!isInShoppingCart ? (
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    size={isMobile ? 'small' : 'medium'}
                                    onClick={() => {
                                        shoppingCart.addItem(fund.id, shareClass.id)
                                    }}
                                >
                                    {t('pages-fund-details.addToCart')}
                                </Button>
                            ) : (
                                <Button
                                    variant={'outlined'}
                                    color={'error'}
                                    size={isMobile ? 'small' : 'medium'}
                                    onClick={() => {
                                        shoppingCart.removeItem(fund.id)
                                    }}
                                >
                                    {t('pages-fund-details.removeFromCart')}
                                </Button>
                            )}
                        </Stack>
                    </Box>
                </>
            )}
        </Drawer>
    )
}
