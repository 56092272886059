import { CHART_COLORS, PURPLE } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { StaggData } from '#app/state/stagg'
import { Box, SxProps } from '@mui/material'
import { Chart, ChartOptions } from 'chart.js'
import 'chartjs-adapter-date-fns'
import merge from 'lodash/merge'
import { useRef } from 'react'
import { formatStaggData } from '../formatStaggData'

interface BarChartProps {
    staggData: StaggData
    sx?: SxProps
    exludeNames?: string[]
    colors?: string[]
    valueType?: 'interest' | 'currency'
    formatFn?: (value: number | string) => string
    options?: ChartOptions
    labels?: string[]
    darkMode?: boolean
    highlightItem?: string
    maxLabelLength?: number
    reference?: React.RefObject<HTMLElement>
}

const BarChart = ({
    staggData,
    maxLabelLength,
    labels,
    sx,
    colors,
    darkMode,
    exludeNames,
    valueType,
    formatFn,
    options,
    highlightItem,
    reference,
}: BarChartProps) => {
    const Currency = useCurrencyOutput()
    const chartRef = useRef<Chart | null>(null)
    const chartData = formatStaggData({ input: staggData, exludeNames: exludeNames, colors: colors, singleValue: true })
    const chartLabels = labels ?? chartData.labels
    const chartColors = colors ? [...colors, ...CHART_COLORS] : CHART_COLORS
    const textColor = darkMode ? PURPLE[25] : PURPLE[900]
    const gridColor = darkMode ? 'rgba(250,250,250,0.08)' : 'rgba(0,0,0,0.1)'

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas) return

        const context = canvas.getContext('2d')
        const numberWithAnnotation = (value: number | string) => {
            if (formatFn) {
                return formatFn(value)
            }
            const val = typeof value === 'number' ? value : parseFloat(value)
            if (valueType === 'currency') {
                return Currency(val, { decimals: 0 })
            }
            return InterestOutput.formatWithDecimals(val, 2)
        }

        const getChartLabel = (value: number, chartLabels: unknown[]): string => {
            return chartLabels[value] as 'string'
        }

        if (context) {
            chartRef.current?.destroy()
            chartRef.current = new Chart(context, {
                type: 'bar',
                data: {
                    datasets: chartData.datasets.map((dataset, i) => ({
                        borderColor: highlightItem
                            ? chartColors.map((c, i) => (chartLabels.indexOf(highlightItem) === i ? c : c + '95'))
                            : chartColors,
                        backgroundColor: highlightItem
                            ? chartColors.map((c, i) =>
                                  chartLabels.indexOf(highlightItem) === i ? c + '95' : c + '20'
                              )
                            : chartColors.map((c) => c + '90'),
                        hoverBackgroundColor: chartColors.map((c) => c + '95'),
                        hoverBorderColor: chartColors,
                        borderWidth: 1,
                        data: dataset.data.map((d) => d),
                    })),
                    labels: chartLabels,
                },
                options: merge(
                    {
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        return `${context.label}: ${numberWithAnnotation(context.parsed.y)}`
                                    },
                                },
                            },
                            legend: {
                                display: false,
                            },
                        },
                        onResize: (chart, size) => {
                            if (size.height < 300) {
                                chart.options.maintainAspectRatio = false
                            }
                        },
                        color: textColor,
                        scales: {
                            x: {
                                ticks: {
                                    font: {
                                        family: "'Montserrat'",
                                        weight: '600',
                                        size: 12,
                                    },
                                    color: textColor,
                                    callback: (value) =>
                                        maxLabelLength && getChartLabel(value, chartLabels).length > maxLabelLength
                                            ? getChartLabel(value, chartLabels).substring(0, maxLabelLength) + '...'
                                            : chartLabels[value],
                                },
                                grid: {
                                    color: gridColor,
                                },
                            },
                            y: {
                                ticks: {
                                    font: {
                                        family: "'Montserrat'",
                                        weight: '600',
                                    },
                                    color: textColor,
                                    callback: (value) => numberWithAnnotation(value),
                                },
                                grid: {
                                    color: gridColor,
                                },
                            },
                        },
                    },
                    options
                ),
            })
        }
    }

    return (
        <Box sx={sx} height="50rem" ref={reference}>
            <Box ref={canvasCallback} component="canvas" maxHeight={'100%'} />
        </Box>
    )
}

export { BarChart }
