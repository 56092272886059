import styles from './DashboardHeader.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import './DashboardEntry.css'

const DashboardEntry = (props) => {
    const entry = (
        <div
            className={classNames(styles.entry, props.hideIfSmall && styles.hideIfSmall)}
            style={props.minWidth && { minWidth: props.minWidth + 'rem' }}
        >
            <div className={styles.entryHeader}>{props.header}</div>
            <div
                className={classNames(styles.entryValue, props.onClick && styles.clickableEntry)}
                onClick={props.onClick ? props.onClick : undefined}
            >
                {props.value || (props.onClick ? '--?--' : '...')}
            </div>
        </div>
    )

    return (
        <Popup
            trigger={entry}
            position="bottom center"
            on="hover"
            closeOnDocumentClick
            mouseEnterDelay={200}
            className="dashboardentry"
            contentStyle={{ borderRadius: 0 }}
        >
            <div className={styles.tooltip}>{props.tooltip}</div>
        </Popup>
    )
}

DashboardEntry.propTypes = {
    hideIfSmall: PropTypes.bool,
    minWidth: PropTypes.number,
    header: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    tooltip: PropTypes.string,
    onClick: PropTypes.func,
}

export default DashboardEntry
