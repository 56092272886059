import { useSelector } from '#state/useSelector'
import * as selectors from '#state/selectors'
import { useTranslation } from 'react-i18next'
import {
    AdInterestByProduct,
    AdInterestByBankSegment,
    AdInterestForBank,
    AdMarginForBank,
    AdMarginByBankSegment,
    AdMarginByProduct,
    DepthByBankSegment,
    DepthByProduct,
} from '@fixrate/fixrate-analytics-widgets'
import FxPaper from '#app/components/Paper/FxPaper'
import { AnalyticsPaperSx } from '../Analytics'

const AnalyticsAds = () => {
    const { t } = useTranslation()
    const isFixrateRole = useSelector(selectors.isFixrateRole)
    const isBankWithAnalytics = useSelector(selectors.isBankWithAnalytics)
    const isBankWithExtendedAnalytics = useSelector(selectors.isBankWithExtendedAnalytics)
    const bankId = useSelector((state) => selectors.bankInSession(state)?.id)

    return (
        <>
            {(isFixrateRole || isBankWithAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsAllProduct')}>
                    <AdInterestByProduct />
                </FxPaper>
            )}

            {(isFixrateRole || (isBankWithAnalytics && !isBankWithExtendedAnalytics)) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsAllBankSegment')}>
                    <AdInterestByBankSegment />
                </FxPaper>
            )}

            {bankId && isBankWithExtendedAnalytics && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsAllBank')}>
                    <AdInterestForBank bankId={bankId} />
                </FxPaper>
            )}

            {(isFixrateRole || isBankWithAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsMarginProduct')}>
                    <AdMarginByProduct />
                </FxPaper>
            )}

            {(isFixrateRole || (isBankWithAnalytics && !isBankWithExtendedAnalytics)) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsMarginBankSegment')}>
                    <AdMarginByBankSegment />
                </FxPaper>
            )}

            {bankId && isBankWithExtendedAnalytics && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsMarginBank')}>
                    <AdMarginForBank bankId={bankId} />
                </FxPaper>
            )}

            {(isFixrateRole || isBankWithExtendedAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsDepthBankSegment')}>
                    <DepthByBankSegment />
                </FxPaper>
            )}

            {(isFixrateRole || isBankWithExtendedAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.adsDepthProduct')}>
                    <DepthByProduct />
                </FxPaper>
            )}
        </>
    )
}

export default AnalyticsAds
