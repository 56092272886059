import OrdersBankListElement from './OrdersBankListElement'
import { useSelector } from '#state/useSelector'
import { LoadingSpinner, Table } from '#components'
import styles from './OrdersBankListSection.module.css'
import classNames from 'classnames'
import { useTranslation } from '#components/i18n'
import { OrderState } from '@fixrate/fixrate-query'
import FxPaper from '#app/components/Paper/FxPaper'
import { Typography } from '@mui/material'

export type Props = {
    header: string
    ingress: string
    state: OrderState[]
    dataCy?: string
}

export default function OrdersBankListSection({ header, ingress, state, dataCy }: Props) {
    const { t } = useTranslation('pages-orders')
    const orders = useSelector((state) => state.orders)
    const isLoading = useSelector((state) => !state.loaded.orders)
    const products = useSelector((state) => state.products)

    const orderList = orders.filter((order) => state.includes(order.orderState))

    orderList.sort((a, b) => {
        return new Date(a.created).getTime() - new Date(b.created).getTime()
    })

    const orderElements = orderList.map((order) => {
        const product = Object.keys(products).length === 0 ? null : products[order.ad.productId]

        return (
            <OrdersBankListElement
                isTableRow={true} // Required by Table.js to recognize this as a table row element
                key={order.id}
                order={order}
                product={product}
                interest={order.ad.interest}
            />
        )
    })

    const isEmpty = orderList.length === 0

    let body
    if (isLoading) {
        body = <LoadingSpinner text={t('bankLoadingOrders')} />
    } else if (isEmpty) {
        body = <p className={styles.empty}>{t('bankNoOrders')}</p>
    } else {
        body = (
            <Table dataCy={dataCy} className={classNames(styles.table)}>
                {orderElements}
            </Table>
        )
    }

    return (
        <FxPaper title={header} data-cy="OrdersBankListSection">
            <Typography>{ingress}</Typography>
            {body}
        </FxPaper>
    )
}
