import { PageHeader, PageLayout, Paper } from '#app/components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import DocumentLink from '#app/components/DocumentLink'

export default function PartnerContract() {
    const { t } = useTranslation()

    return (
        <Fragment>
            <PageHeader icon="ri-file-paper-2-line" title={t('pages-mypartner.contract')} />
            <PageLayout>
                <Paper title={t('pages-mypartner.termsAndCondition')}>
                    <DocumentLink link={'/partneravtale.pdf'} name={t('pages-mypartner.readContract')} />
                </Paper>
            </PageLayout>
        </Fragment>
    )
}
