import { Paper } from '#app/components'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import { useCommand } from '#app/services/beta'
import { useSelector } from '#app/state/useSelector'
import { LoadingButton } from '@mui/lab'
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function BeforeRegistration() {
    const { t } = useTranslation()

    const {
        depositorHasSingleOwner: depositorHasSingleOwnerCommand,
        setDepositorSupportCategory: setDepositorSupportCategoryCommand,
    } = useCommand()
    const sessionId = useSelector((state) => state.session?.id)
    const selectedDepositor = useSelector((state) => state.depositor)
    const [onlyOwner, setOnlyOwner] = useState<'yes' | 'no' | null>(null)
    const [submittingOnlyOwners, setSubmittingOnlyOwners] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(true)

    async function startRegistration() {
        if (onlyOwner === null) return

        setSubmittingOnlyOwners(true)
        const personId = selectedDepositor.people.find((person) => person.associatedUserId === sessionId)?.personId

        const { waitForCommand } = await depositorHasSingleOwnerCommand(
            selectedDepositor.id,
            onlyOwner === 'yes',
            personId
        )
        const success = await waitForCommand()
        if (success) {
            // Continue to next step
        }
        setSubmittingOnlyOwners(false)
    }

    return (
        <FxDialog open={dialogOpen}>
            <FxDialogTitle onClose={() => setDialogOpen(false)}>
                {t('pages-OrganizationWizard.completeRegistration')}
            </FxDialogTitle>
            <FxDialogContent>
                <Typography>{t('pages-OrganizationWizard.registrationInfo')}</Typography>
                <Typography variant="h3" mt={2} mb={1}>
                    {t('pages-OrganizationWizard.onlyOwnerQuestion')}
                </Typography>
                <RadioGroup
                    aria-labelledby="onlyOwner"
                    defaultValue=""
                    name="onlyOwnerGroup"
                    value={onlyOwner}
                    onChange={(e) => setOnlyOwner(e.target.value as 'yes' | 'no')}
                >
                    <FormControlLabel
                        data-cy="yesRadio"
                        value="yes"
                        control={<Radio />}
                        label={t('pages-OrganizationWizard.onlyOwnerYes')}
                    />
                    <FormControlLabel
                        data-cy="noRadio"
                        value="no"
                        control={<Radio />}
                        label={t('pages-OrganizationWizard.onlyOwnerNo')}
                    />
                </RadioGroup>
            </FxDialogContent>
            <FxDialogActions>
                <LoadingButton
                    data-cy="startRegistrationButton"
                    loading={submittingOnlyOwners}
                    sx={{ marginTop: '2rem' }}
                    variant={'contained'}
                    onClick={startRegistration}
                >
                    {t('pages-OrganizationWizard.startRegistration')}
                </LoadingButton>
            </FxDialogActions>
        </FxDialog>
    )
}
