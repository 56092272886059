import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import { ConfirmationIconName } from '#app/layers/ConfirmationModal/ConfirmationIcon'
import { useCommand } from '#command'
import { CurrencyOutput, NumberInput } from '#components'
import DatePicker from '#components/DatePicker'
import FxDialog from '#components/FxDialog/FxDialog'
import FxDialogContent from '#components/FxDialog/FxDialogContent'
import FxDialogTitle from '#components/FxDialog/FxDialogTitle'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { useFieldState } from '@fixrate/fieldstate'
import { FundBuyOrderState, FundSellOrderState, FundTradeOrderStatusDto } from '@fixrate/fixrate-query'
import { LoadingButton } from '@mui/lab'
import {
    Box,
    Button,
    DialogActions,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { BigNumber } from 'bignumber.js'
import format from 'date-fns/format'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useFund } from '../../services/useFund'
import DetailRow from '../../pages/FundBuyOrderOverview/components/DetailRow'
import { FundBuyOrderRow } from '../../pages/FundBuyOrderOverview/FundBuyOrderTable'
import { FundSellOrderRow } from '../../pages/FundSellOrderOverview/FundSellOrderTable'

type StatusDialogProps<FundRowType extends FundBuyOrderRow | FundSellOrderRow> = {
    fundOrderRow: FundRowType | null
    onClose: () => void
    isOpen: boolean
}

type FundOrderState<FundRowType extends FundBuyOrderRow | FundSellOrderRow> = FundRowType extends FundBuyOrderRow
    ? FundBuyOrderState
    : FundSellOrderState

type StateTexts = {
    label: string
    description: string
}

type WithdrawalResponse = {
    resultStatus: 'SUCCESS' | 'FAILURE' | 'DUPLICATE' | 'INSUFFICIENT_FUNDS' | 'NO_MATCHING_SETTLEMENT_ACCOUNT'
    description?: string
}

type ConfirmationModalPayload = {
    title: string
    text: string
    buttonText: string
    confirmationIconName?: ConfirmationIconName
}

export function FundOrderUpdateStatusDialog({
    fundOrderRow,
    onClose,
    isOpen,
}: StatusDialogProps<FundBuyOrderRow | FundSellOrderRow>) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {
        cancelFundSellOrder,
        completeFundSellOrder,
        registerFundSellOrderExecution,
        registerFundSellOrderExecutingInMarket,
        cancelFundBuyOrder,
        registerFundBuyOrderPayment,
        registerFundBuyOrderExecutingInMarket,
        completeFundBuyOrder,
    } = useCommand()
    const [submittingWithdrawal, setSubmittingWithdrawal] = useState(false)

    const [selectedState, setSelectedState] = useState<FundOrderState<typeof fundOrderRow> | ''>(
        fundOrderRow?.state ?? ''
    )

    const fund = useSelector((state) => state.funds.find((fund) => fund.id === fundOrderRow?.fundId))
    const fundShareClass = useSelector((state) =>
        state.funds.flatMap((f) => f.fundShareClasses).find((fsc) => fsc.id === fundOrderRow?.fundShareClassId)
    )

    const decimalPrecision = fund?.decimalPrecision ?? 4
    const [transactionDate, setTransactionDate] = useState<Date | null>(
        fundOrderRow?.transactionDate ? new Date(fundOrderRow.transactionDate) : null
    )
    const { fundNav, fundAccrual } = useFund(transactionDate, fundOrderRow)

    const unitQuantityField = useFieldState<number | null>(fundOrderRow?.unitQuantity ?? null, ({ isSet }) =>
        !isSet ? 'Antall enheter må fylles ut' : ''
    )
    const unitPriceField = useFieldState<number | null>(fundOrderRow?.unitPrice ?? null, ({ value, isEditing }) => {
        if (!isEditing && value !== null && fundNav !== null) {
            if (!new BigNumber(value).eq(fundNav)) return 'Fondets NAV er ikke likt oppgitt enhetspris'
        }
        return null
    })
    const accrualField = useFieldState<number | null>(null, ({ isSet }) => {
        if (fund?.isDividendFund && !isSet) {
            return 'Rentekurs må fylles ut'
        }
        return ''
    })
    const roundingErrorField = useFieldState<number | null>(fundOrderRow?.roundingError ?? null, ({ isSet }) =>
        !isSet ? 'Avrunding må fylles ut' : ''
    )
    const amountField = useFieldState<number | null>(fundOrderRow?.amount ?? null, ({ value, isSet, isEditing }) => {
        if (!isEditing && isSet) {
            const amount = new BigNumber(value)
            const calculatedAmount = calculateValue()
                .dp(2, BigNumber.ROUND_HALF_UP)
                .plus(new BigNumber(roundingErrorField.value))

            if (!calculatedAmount.eq(amount)) {
                return `Beløp er ikke likt enhetspris * antall enheter + avrunding = ${calculatedAmount})`
            }
        }

        if (!isSet) {
            return 'Beløp må fylles ut'
        }
        return ''
    })

    const [settlementDate, setSettlementDate] = useState<Date | null>(
        fundOrderRow?.settlementDate ? new Date(fundOrderRow.settlementDate) : null
    )

    const [submitting, setSubmitting] = useState<boolean>(false)
    const [statusSubmitting, setStatusSubmitting] = useState<boolean>(false)

    const fundCustomers = useSelector((state) => state.fundCustomers)
    const fundCustomer = fundCustomers.find((fc) => fc.depositorId === fundOrderRow?.depositorId)
    const settlementAccount = fundCustomer?.settlementAccounts?.find(
        (sa) => sa?.id === (fundOrderRow as FundSellOrderRow)?.settlementAccountId
    )

    function calculateValue(): BigNumber {
        const unitQuantity = new BigNumber(unitQuantityField.value ?? 0)
        const unitPrice = new BigNumber(unitPriceField.value ?? 0)
        return unitQuantity.multipliedBy(unitPrice)
    }

    const handleUpdateStatusFromFA = async () => {
        setStatusSubmitting(true)
        try {
            const statusResponse = await fetch(
                `/api/fund/fund-${isFundBuy ? 'buy' : 'sell'}-order-status/${fundOrderRow?.id}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    credentials: 'include',
                }
            )

            const status = (await statusResponse.json()) as FundTradeOrderStatusDto

            if (status.cancelled) {
                setSelectedState('CANCELLED')
            } else if (status.executingInMarket) {
                setSelectedState('EXECUTING_IN_MARKET')
            } else if (status.fulfilled) {
                setSelectedState(isFundBuy ? 'COMPLETED' : 'TRANSFERRING_MONEY')
                amountField.setValue(status.amount)
                unitPriceField.setValue(status.unitPrice)
                unitQuantityField.setValue(status.unitQuantity)
                roundingErrorField.setValue(status.roundingError ?? 0)
                setSettlementDate(new Date(status.settlementDate))
                setTransactionDate(new Date(status.transactionDate))
            }
        } finally {
            setStatusSubmitting(false)
        }
    }

    async function handleSubmittingWithdrawal() {
        setSubmittingWithdrawal(true)
        const cid = fundOrderRow.cid
        const faPortfolioId = cid?.substring(0, cid?.length - 1)
        const reqBody = {
            fundSellOrderId: fundOrderRow.id,
            amount: amountField.value,
            description: 'Utbetaling fra fondssalg',
            transactionDate: format(transactionDate, 'yyyy-MM-dd'),
            settlementDate: format(settlementDate, 'yyyy-MM-dd'),
            settlementAccountNumber: settlementAccount.accountNumber,
        }
        try {
            const withdrawalResp = await fetch(`/api/fund-actions/portfolio/${faPortfolioId}/withdrawal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(reqBody),
            })
            const withdrawalResponse = (await withdrawalResp.json()) as WithdrawalResponse
            dispatch(showConfirmationModal(getModalPayloadForWithdrawalResponse(withdrawalResponse)))
        } catch (error) {
            dispatch(
                showConfirmationModal({
                    title: 'Utbetaling feilet: Ukjent feil',
                    text: 'En ukjent feil oppsto som gjorde at utbetalingen ikke kunne gjennomføres i FA',
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                })
            )
        } finally {
            onClose()
            setSubmittingWithdrawal(false)
        }
    }

    function getModalPayloadForWithdrawalResponse(withdrawalResponse: WithdrawalResponse): ConfirmationModalPayload {
        switch (withdrawalResponse.resultStatus) {
            case 'SUCCESS':
                return {
                    title: 'Utbetaling bekreftet!',
                    text: `Utbetaling er registrert i FA. extId: ${fundOrderRow.id}`,
                    buttonText: t('common.continue'),
                }
            case 'INSUFFICIENT_FUNDS':
                return {
                    title: 'Utbetaling feilet: Ikke nok penger på konto',
                    text: 'Klientkonto i FA har for få midler til å gjennomføre utbetalingen',
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case 'DUPLICATE':
                return {
                    title: 'Utbetaling feilet: Duplikat',
                    text: `Det finnes allerede en utbetaling registrert på denne ordren. Sjekk i FA for extId: ${fundOrderRow.id}`,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case 'NO_MATCHING_SETTLEMENT_ACCOUNT':
                return {
                    title: 'Utbetaling feilet: Uventet utbetalingskonto',
                    text: `Finner ingen utbetalingskonto i FA som matcher utbetalingskonto for salgsordren: ${settlementAccount?.accountNumber}`,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
            case 'FAILURE':
            default:
                return {
                    title: 'Utbetaling feilet: Ukjent feil',
                    text:
                        'Feilmelding: ' +
                        (withdrawalResponse.description ?? 'Ukjent feil oppsto under utbetalingen i FA'),
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                }
        }
    }

    function isFundBuyOrderRow(row: FundBuyOrderRow | FundSellOrderRow): row is FundBuyOrderRow {
        return 'accountNumber' in row
    }

    const isFundBuy = isFundBuyOrderRow(fundOrderRow)

    const fundOrderStatesMap: { [K in FundOrderState<typeof fundOrderRow>]: StateTexts } = {
        AWAITING_SIGNATURE: {
            label: 'Ordre opprettet',
            description: 'Avventer signatur fra kunde.',
        },
        AWAITING_PAYMENT: {
            label: 'Ordre signert',
            description: 'Avventer innbetaling fra kunde',
        },
        AWAITING_PAYMENT_CONFIRMATION: {
            label: 'Kunden har meldt innbetaling',
            description: 'Avventer bekreftelse på mottatt innbetaling fra fondsformidler',
        },
        IN_PROGRESS: {
            label: isFundBuy ? 'Innbetaling bekreftet av fondsformidler' : 'Ordre signert',
            description: isFundBuy
                ? 'Avventer at ordren utføres i markedet'
                : 'Avventer at salgsordren sendes til markedet.',
        },
        EXECUTING_IN_MARKET: {
            label: 'Ordren er i markedet',
            description: isFundBuy ? 'Avventer at ordren fullføres' : 'Avventer at salget blir utført i markedet.',
        },
        TRANSFERRING_MONEY: {
            label: 'Salget er utført i markedet',
            description: 'Avventer at penger overføres til kunde.',
        },
        COMPLETED: {
            label: 'Fullført',
            description: '',
        },
        CANCELLED: {
            label: 'Avbrutt',
            description: '',
        },
        CANCELLED_BY_DEPOSITOR: {
            label: 'Avbrutt av innskyter',
            description: '',
        },
    }

    function currentSelectableStates(): FundOrderState<typeof fundOrderRow>[] {
        const currentState = fundOrderRow.state
        if (!fundOrderRow.registeredInFa) return isFundBuy ? [currentState, 'CANCELLED'] : []
        switch (currentState) {
            case 'AWAITING_SIGNATURE':
                return isFundBuy ? ['AWAITING_SIGNATURE', 'CANCELLED'] : ['AWAITING_SIGNATURE']
            case 'AWAITING_PAYMENT':
                return ['AWAITING_PAYMENT', 'AWAITING_PAYMENT_CONFIRMATION', 'IN_PROGRESS', 'CANCELLED']
            case 'AWAITING_PAYMENT_CONFIRMATION':
                return ['AWAITING_PAYMENT_CONFIRMATION', 'IN_PROGRESS', 'CANCELLED']
            case 'IN_PROGRESS':
                return isFundBuy
                    ? ['IN_PROGRESS', 'EXECUTING_IN_MARKET', 'COMPLETED', 'CANCELLED']
                    : ['IN_PROGRESS', 'EXECUTING_IN_MARKET', 'CANCELLED']
            case 'EXECUTING_IN_MARKET':
                return isFundBuy
                    ? ['EXECUTING_IN_MARKET', 'IN_PROGRESS', 'COMPLETED', 'CANCELLED']
                    : ['EXECUTING_IN_MARKET', 'TRANSFERRING_MONEY', 'CANCELLED']
            case 'TRANSFERRING_MONEY':
                return ['TRANSFERRING_MONEY', 'COMPLETED', 'CANCELLED']
            case 'COMPLETED':
                return ['COMPLETED']
            case 'CANCELLED':
                return ['CANCELLED']
            case 'CANCELLED_BY_DEPOSITOR':
                return ['CANCELLED_BY_DEPOSITOR']
            default:
                return []
        }
    }

    function isDisabled(state: FundOrderState<typeof fundOrderRow>): boolean {
        return !currentSelectableStates().includes(state)
    }

    function isSubmittingWithdrawalDisabled() {
        return submittingWithdrawal || fundOrderRow?.state !== 'TRANSFERRING_MONEY'
    }

    async function handleSubmit() {
        if (isDisabled(selectedState as FundOrderState<typeof fundOrderRow>)) return

        setSubmitting(true)

        switch (selectedState) {
            case 'IN_PROGRESS': {
                if (isFundBuy) {
                    const { waitForCommand } = await registerFundBuyOrderPayment(fundOrderRow.id)
                    const success = await waitForCommand()
                    if (success) {
                        dispatch(
                            showConfirmationModal({
                                title: 'Status oppdatert!',
                                text: 'Innbetalingen ble bekreftet. Status ble satt som: "Innbetaling bekreftet av fondsformidler".',
                                buttonText: t('common.continue'),
                            })
                        )
                        onClose()
                    }
                    break
                } else {
                    dispatch(
                        showConfirmationModal({
                            title: 'Dette går ikke an enda, hehe :)',
                            text: 'Dette går ikke an enda, hehe :)',
                            buttonText: t('common.continue'),
                        })
                    )
                    break
                }
            }
            case 'CANCELLED': {
                const confirmed = window.confirm('Er du sikker på at du vil avbryte denne ordren?')
                if (!confirmed) break

                const { waitForCommand } = isFundBuy
                    ? await cancelFundBuyOrder(fundOrderRow.id)
                    : await cancelFundSellOrder(fundOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(
                        showConfirmationModal({
                            title: 'Ordren er avbrutt!',
                            text: 'Ordren er avbrutt!',
                            buttonText: t('common.continue'),
                        })
                    )
                    onClose()
                }
                break
            }
            case 'EXECUTING_IN_MARKET': {
                const { waitForCommand } = isFundBuy
                    ? await registerFundBuyOrderExecutingInMarket(fundOrderRow.id)
                    : await registerFundSellOrderExecutingInMarket(fundOrderRow.id)
                const success = await waitForCommand()
                if (success) {
                    dispatch(
                        showConfirmationModal({
                            title: 'Status oppdatert!',
                            text: 'Status ble satt som: "Ordren er i markedet"',
                            buttonText: t('common.continue'),
                        })
                    )
                    onClose()
                }
                break
            }
            case 'TRANSFERRING_MONEY': {
                unitPriceField.validate()
                unitQuantityField.validate()
                roundingErrorField.validate()
                amountField.validate()
                if (
                    transactionDate === null ||
                    settlementDate === null ||
                    !unitQuantityField.valid ||
                    !unitPriceField.valid ||
                    !amountField.valid ||
                    !roundingErrorField.valid
                ) {
                    setSubmitting(false)
                    return
                }

                const { waitForCommand } = await registerFundSellOrderExecution(
                    fundOrderRow.id,
                    unitQuantityField.value,
                    unitPriceField.value,
                    format(transactionDate, 'yyyy-MM-dd'),
                    format(settlementDate, 'yyyy-MM-dd'),
                    amountField.value,
                    roundingErrorField.value
                )

                const success = await waitForCommand()
                if (success) {
                    dispatch(
                        showConfirmationModal({
                            title: 'Registering fullført',
                            text: 'Registering fullført',
                            buttonText: t('common.continue'),
                        })
                    )
                    onClose()
                }
                break
            }
            case 'COMPLETED': {
                if (isFundBuy) {
                    unitPriceField.validate()
                    unitQuantityField.validate()
                    roundingErrorField.validate()
                    amountField.validate()
                    if (
                        transactionDate === null ||
                        settlementDate === null ||
                        !unitQuantityField.valid ||
                        !unitPriceField.valid ||
                        !amountField.valid ||
                        !roundingErrorField.valid
                    ) {
                        setSubmitting(false)
                        return
                    }

                    const { waitForCommand } = await completeFundBuyOrder(
                        fundOrderRow.id,
                        unitQuantityField.value,
                        unitPriceField.value,
                        amountField.value,
                        roundingErrorField.value,
                        format(transactionDate, 'yyyy-MM-dd'),
                        format(settlementDate, 'yyyy-MM-dd')
                    )
                    const success = await waitForCommand()
                    if (success) {
                        dispatch(
                            showConfirmationModal({
                                title: 'Ordre fullført!',
                                text: 'Ordre fullført!',
                                buttonText: t('common.continue'),
                            })
                        )
                        onClose()
                    }
                } else {
                    const { waitForCommand } = await completeFundSellOrder(fundOrderRow.id)
                    const success = await waitForCommand()
                    if (success) {
                        dispatch(
                            showConfirmationModal({
                                title: 'Ordre fullført!',
                                text: 'Ordre fullført!',
                                buttonText: t('common.continue'),
                            })
                        )
                        onClose()
                    }
                }
                break
            }
        }

        setSubmitting(false)
    }

    return (
        <FxDialog open={isOpen} onClose={onClose} fullWidth maxWidth={'sm'}>
            <FxDialogTitle onClose={onClose}>Sett status</FxDialogTitle>
            {fundOrderRow && (
                <FxDialogContent sx={{ my: 2 }}>
                    <DialogContentText sx={{ mb: 1 }}>
                        Status skal i utgangspunktet oppdateres automatisk fra FA, men dersom dette ikke har skjedd kan
                        du overstyre her.
                    </DialogContentText>
                    <DialogContentText sx={{ mb: 2 }}>
                        Det er FA som er fasit, men det er denne statusen som kunden vil se i Fixrate.
                    </DialogContentText>
                    <DetailRow title={'Fond'}>{fundOrderRow.fundName}</DetailRow>
                    <DetailRow title={'Innskyter'}>{fundOrderRow.depositorName}</DetailRow>

                    {isFundBuy ? (
                        <>
                            <DetailRow title={'Sum'}>
                                {CurrencyOutputObject(fundOrderRow.amount, { currency: fundShareClass?.currency })}
                            </DetailRow>
                            <DetailRow title={'Andeler'}>
                                {CurrencyOutput.formatNoCode(fundOrderRow.unitQuantity, decimalPrecision)}
                            </DetailRow>
                        </>
                    ) : (
                        <DetailRow data-cy="sellOrderStatusQuantityText" title={'Andeler'}>
                            {CurrencyOutput.formatNoCode(fundOrderRow.unitQuantity, decimalPrecision)}
                        </DetailRow>
                    )}

                    <DetailRow title={'External ID'}>{fundOrderRow.id}</DetailRow>
                    <FormControl sx={{ mt: 2 }}>
                        <FormLabel>Ordrestatus</FormLabel>
                        <RadioGroup data-cy="statusSelectRadio" value={selectedState}>
                            {Object.entries(fundOrderStatesMap).map(([state, texts]) => (
                                <div key={state}>
                                    <FormControl
                                        margin={'normal'}
                                        disabled={isDisabled(state as FundOrderState<typeof fundOrderRow>)}
                                    >
                                        <FormControlLabel
                                            value={state}
                                            control={<Radio />}
                                            slotProps={
                                                state === fundOrderRow.state && { typography: { fontWeight: 700 } }
                                            }
                                            label={`${texts.label}${state === fundOrderRow.state ? ' (nåværende)' : ''}`}
                                            onChange={() =>
                                                setSelectedState(state as FundOrderState<typeof fundOrderRow>)
                                            }
                                        />
                                        <FormHelperText>{texts.description}</FormHelperText>
                                    </FormControl>

                                    {((state === 'TRANSFERRING_MONEY' && selectedState === 'TRANSFERRING_MONEY') ||
                                        (isFundBuy && state === 'COMPLETED' && selectedState === 'COMPLETED')) && (
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 2 }}>
                                                <DatePicker
                                                    id="transactionDateInput"
                                                    selected={transactionDate}
                                                    onChange={(date) => setTransactionDate(date)}
                                                    inputProps={{
                                                        label: 'Transaksjonsdato',
                                                        helperText: !transactionDate && 'Transaksjonsdato må fylles ut',
                                                        error: !transactionDate,
                                                    }}
                                                />
                                                <DatePicker
                                                    id="settlementDateInput"
                                                    selected={settlementDate}
                                                    onChange={(date) => setSettlementDate(date)}
                                                    inputProps={{
                                                        label: 'Bokføringsdato',
                                                        helperText: !settlementDate && 'Bokføringsdato må fylles ut',
                                                        error: !settlementDate,
                                                    }}
                                                />

                                                {isFundBuy ? (
                                                    <NumberInput
                                                        label={'Antall kjøpte andeler'}
                                                        value={unitQuantityField.value}
                                                        onChange={unitQuantityField.setValue}
                                                        formatFn={(v) =>
                                                            CurrencyOutputObject(v, {
                                                                withCurrency: false,
                                                                minimumDecimals: 2,
                                                                maximumDecimals: 10,
                                                            })
                                                        }
                                                        data-cy={'quantityInput'}
                                                        error={!unitQuantityField.valid}
                                                        onBlur={unitQuantityField.onBlur}
                                                        helperText={unitQuantityField.errorMessage}
                                                    />
                                                ) : (
                                                    <NumberInput
                                                        label={'Antall solgte andeler'}
                                                        value={unitQuantityField.value}
                                                        onChange={unitQuantityField.setValue}
                                                        formatFn={(v) =>
                                                            CurrencyOutputObject(v, {
                                                                withCurrency: false,
                                                                maximumDecimals: 10,
                                                            })
                                                        }
                                                        error={!unitQuantityField.valid}
                                                        onBlur={unitQuantityField.onBlur}
                                                        helperText={unitQuantityField.errorMessage}
                                                        data-cy={'unitQuantityInput'}
                                                    />
                                                )}

                                                <NumberInput
                                                    label={'Andelspris'}
                                                    value={unitPriceField.value}
                                                    onChange={unitPriceField.setValue}
                                                    formatFn={(v) =>
                                                        CurrencyOutputObject(v, {
                                                            currency: fundShareClass?.currency,
                                                            minimumDecimals: 2,
                                                            maximumDecimals: 10,
                                                        })
                                                    }
                                                    error={!unitPriceField.valid}
                                                    onBlur={unitPriceField.onBlur}
                                                    helperText={
                                                        (fundNav &&
                                                            transactionDate &&
                                                            'Fondet har ' +
                                                                fundNav.toString() +
                                                                ', den ' +
                                                                format(transactionDate, 'dd.MM.yyyy')) ||
                                                        (transactionDate &&
                                                            'Fondet har ikke NAV for ' +
                                                                format(transactionDate, 'dd.MM.yyyy') +
                                                                '. Oppgitt NAV vil bli brukt.')
                                                    }
                                                    data-cy={isFundBuy ? 'priceInput' : 'unitPriceInput'}
                                                />

                                                {fund.dividendFund && (
                                                    <NumberInput
                                                        disabled={true}
                                                        label={'Fondets rentekurs pr andel'}
                                                        placeholder={'test'}
                                                        value={accrualField.value}
                                                        onChange={accrualField.setValue}
                                                        formatFn={(v) =>
                                                            CurrencyOutputObject(v, {
                                                                currency: fundShareClass?.currency,
                                                                minimumDecimals: 2,
                                                                maximumDecimals: 10,
                                                            })
                                                        }
                                                        onBlur={accrualField.onBlur}
                                                        helperText={
                                                            (fundAccrual &&
                                                                transactionDate &&
                                                                'Fondet har ' +
                                                                    fundAccrual.toString() +
                                                                    ', den ' +
                                                                    format(transactionDate, 'dd.MM.yyyy')) ||
                                                            (transactionDate &&
                                                                'Fondet har ikke rentekurs for ' +
                                                                    format(transactionDate, 'dd.MM.yyyy') +
                                                                    '. Oppgitt rentekurs vil bli brukt.')
                                                        }
                                                        data-cy={'accrualInput'}
                                                    />
                                                )}
                                                <p>
                                                    Handelsbeløp{' '}
                                                    {CurrencyOutputObject(
                                                        calculateValue().dp(2, BigNumber.ROUND_HALF_UP).toNumber(),
                                                        isFundBuy
                                                            ? { currency: fundShareClass?.currency }
                                                            : { maximumDecimals: 2 }
                                                    )}
                                                </p>

                                                <NumberInput
                                                    label={'Avrundingskorrigering'}
                                                    value={roundingErrorField.value}
                                                    onChange={roundingErrorField.setValue}
                                                    formatFn={(v) =>
                                                        CurrencyOutputObject(v, {
                                                            currency: fundShareClass?.currency,
                                                            minimumDecimals: 2,
                                                            maximumDecimals: 10,
                                                        })
                                                    }
                                                    error={!roundingErrorField.valid}
                                                    onBlur={roundingErrorField.onBlur}
                                                    helperText={roundingErrorField.errorMessage}
                                                    data-cy={'roundingErrorInput'}
                                                />

                                                <NumberInput
                                                    label={'Overført beløp'}
                                                    value={amountField.value}
                                                    onChange={amountField.setValue}
                                                    formatFn={(v) =>
                                                        CurrencyOutputObject(v, {
                                                            currency: fundShareClass?.currency,
                                                            minimumDecimals: 2,
                                                            maximumDecimals: 10,
                                                        })
                                                    }
                                                    error={!amountField.valid}
                                                    onBlur={amountField.onBlur}
                                                    helperText={amountField.errorMessage}
                                                    data-cy={'amountInput'}
                                                />
                                            </Box>
                                        )}
                                </div>
                            ))}
                        </RadioGroup>
                    </FormControl>
                </FxDialogContent>
            )}
            <DialogActions>
                <Button disabled={statusSubmitting} onClick={handleUpdateStatusFromFA}>
                    Hent inn status fra FA
                </Button>
                <LoadingButton
                    data-cy="updateStatusButton"
                    variant={'contained'}
                    disabled={submitting || selectedState === fundOrderRow?.state}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    Oppdater status
                </LoadingButton>
                {!isFundBuy && (
                    <LoadingButton
                        data-cy="withdrawButton"
                        variant={'contained'}
                        disabled={isSubmittingWithdrawalDisabled()}
                        loading={submittingWithdrawal}
                        onClick={handleSubmittingWithdrawal}
                    >
                        Utfør utbetaling i FA
                    </LoadingButton>
                )}
            </DialogActions>
        </FxDialog>
    )
}
