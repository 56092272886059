import { LanguageSelect } from '#app/components/LanguageSelect/LanguageSelect'
import { AvailableLanguage } from '#app/services/i18n'
import { useCommand } from '#command'
import Paper from '#components/Paper'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function LanguageSettings() {
    const { setUserLanguage } = useCommand()
    const { t, i18n } = useTranslation()
    const [language, setLanguage] = useState<AvailableLanguage>(i18n.language as AvailableLanguage)

    function onSelectLanguage(language) {
        setLanguage(language)
        i18n.changeLanguage(language)
        setUserLanguage(language)
    }

    return (
        <Paper id="language" title={t('pages-profile.languageSettings')}>
            <Typography mb={2}>{t('pages-profile.selectLanguage')}</Typography>
            <LanguageSelect language={language} setLanguage={onSelectLanguage} />
        </Paper>
    )
}
