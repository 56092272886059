import AsyncButton from '#app/components/Button/AsyncButton'
import HistoryPanel from '#blocks/HistoryPanel/HistoryPanel'
import { useCommand } from '#command'
import { InterestOutput, LabeledInput } from '#components'
import DatePicker from '#components/DatePicker/DatePicker'
import HelpPopup from '#components/HelpPopup'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout/PageLayout'
import { useAuthorization } from '#services/authorization'
import { formatAccount } from '#services/formatnumber'
import { parseNumber } from '#services/parse'
import { useBankCalendar } from '#services/useBankCalendar'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from '../InterestRateChangeBank.module.scss'
import { InterestRateBenchmark } from '@fixrate/fixrate-query'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import FxPaper from '#app/components/Paper/FxPaper'

export default function NewInterestRateChange() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { createInterestRateChange } = useCommand()
    const authorization = useAuthorization()
    const { isValidBankDay, nextBankDay } = useBankCalendar()
    const deposits = useSelector((state) => state.deposits)
    const interestRateChange = useSelector((state) => state.interestRateChange)
    const depositsWithIrc = useSelector(selectors.depositsWithActiveOrUnsentInterestRateChanges)
    const organisationCountry = useCurrentCountryCode()

    const validateDepositId = useStableValidator(
        'NOT_EMPTY',
        t('pages-interestratechange-bank.newNoticeDepositMissing')
    )

    const validateNewInterest = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('pages-interestratechange-bank.newNoticeInterestMissing')
            }
            if (!isEditing) {
                if (value === '-' || !/^-?\d{0,2}([.,]\d\d?)?$/.test(value)) {
                    return t('pages-interestratechange-bank.newNoticeInterestInvalid')
                }
            }
        },
        [t]
    )

    const depositIdField = useFieldState<string>('', validateDepositId)
    const newInterestField = useFieldState<string>('', validateNewInterest)
    const newInterestRateConventionField = useFieldState<string>('')
    const newInterestRateBenchmarkField = useFieldState<string>('')

    const selectedDeposit = useMemo(
        () => depositIdField.value && deposits.find((d) => d.id === depositIdField.value),
        [depositIdField.value, deposits]
    )
    const newInterestIsOverOnePercent = !!newInterestField.value && parseNumber(newInterestField.value) > 1
    const isInterestRateIncrease =
        Number(newInterestField.value.replace(',', '.')) > selectedDeposit.nominalInterestRate

    const getFirstValidDate = useCallback(
        (pastDaysToInclude = 0) => {
            if (!selectedDeposit) return null
            if (isInterestRateIncrease) {
                let date = new Date()
                if (pastDaysToInclude > 0) {
                    date = addDays(new Date(), pastDaysToInclude * -1) // start in past if positive rate change
                }

                return nextBankDay(date, 0)
            } else {
                const date = addDays(new Date(), selectedDeposit.product.days)
                return nextBankDay(date, 11)
            }
        },
        [isInterestRateIncrease, nextBankDay, selectedDeposit]
    )

    const validateIrcDate = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('pages-interestratechange-bank.newNoticeDateMissing')
            }
            if (!isEditing && !isNaN(Date.parse(value))) {
                return t('pages-interestratechange-bank.newNoticeDateInvalid')
            }
            if (value < getFirstValidDate()) {
                return t('pages-interestratechange-bank.newNoticeDateToEarly')
            }
        },
        [t, getFirstValidDate]
    )

    const ircDateField = useFieldState<Date>(null, validateIrcDate)

    const processIdMap = useMemo(() => {
        const processIdMap = deposits.reduce((acc, deposit) => {
            acc[deposit.id] = [deposit.id, deposit.orderId]
            return acc
        }, {})
        interestRateChange.forEach((irc) => {
            processIdMap[irc.depositId].push(irc.id)
        })
        return processIdMap
    }, [deposits, interestRateChange])

    useEffect(() => {
        console.log('selectedDeposit', selectedDeposit)
        newInterestRateBenchmarkField.fn.setValue(selectedDeposit?.interestRateBenchmark)
        newInterestRateConventionField.fn.setValue(selectedDeposit?.interestRateConvention)
    }, [newInterestRateBenchmarkField.fn, newInterestRateConventionField.fn, selectedDeposit])

    async function onSubmit() {
        const isValidDeposit = depositIdField.validate()
        const isValidNewInterest = newInterestField.validate()

        if (isValidDeposit && isValidNewInterest) {
            const { waitForCommand } = await createInterestRateChange(
                depositIdField.value,
                newInterestField.value.replace(',', '.'),
                format(ircDateField.value, 'yyyy-MM-dd'),
                newInterestRateConventionField.value !== selectedDeposit.interestRateConvention
                    ? newInterestRateConventionField.value
                    : selectedDeposit.interestRateConvention,
                newInterestRateBenchmarkField.value !== selectedDeposit.interestRateBenchmark
                    ? newInterestRateBenchmarkField.value
                    : selectedDeposit.interestRateBenchmark
            )
            const success = await waitForCommand()
            if (success) {
                navigate('/interestratechange-bank/' + depositIdField.value)
            }
        }
    }

    const depositOptions = deposits
        .filter((deposit) => !deposit.terminationRequested)
        .filter((deposit) => !depositsWithIrc.includes(deposit.id))
        .filter((deposit) => deposit.product.termsType === 'NOTICE')
        .slice()
        .sort((a, b) => a.account.localeCompare(b.account))
        .map((deposit) => (
            <option key={deposit.id} value={deposit.id}>
                {formatAccount(deposit.account, organisationCountry) + ' ' + deposit.depositor.name}
            </option>
        ))

    const current = selectedDeposit && (
        <>
            <ul className={styles.currentInterest}>
                <li>
                    <div className={styles.currentInterestLabel}>
                        {t('pages-interestratechange-bank.newNoticeMarginToday')}:
                    </div>
                    <div>{InterestOutput.format(selectedDeposit.nominalInterestRate)}</div>
                </li>
                <li>
                    <div className={styles.currentInterestLabel}>
                        {t('pages-interestratechange-bank.newNoticeInterestRateConventionToday')}:
                    </div>
                    <div>{t('common.interestRateConvention.' + selectedDeposit.interestRateConvention)}</div>
                </li>
                <li>
                    <HelpPopup text={t('pages-interestratechange-bank.newNoticeEffectiveInterestHelpText')}>
                        <div className={styles.currentInterestLabel}>
                            {t('pages-interestratechange-bank.newNoticeEffectiveInterest')}:
                        </div>
                        <div>{InterestOutput.format(selectedDeposit.effectiveInterestRate)}</div>
                    </HelpPopup>
                </li>
            </ul>
        </>
    )

    const interestConventionOptions =
        selectedDeposit &&
        [
            { id: 'ACTUAL_ACTUAL', name: t('common.interestRateConvention.ACTUAL_ACTUAL') },
            { id: 'ACTUAL_360', name: t('common.interestRateConvention.ACTUAL_360') },
            { id: 'ACTUAL_365', name: t('common.interestRateConvention.ACTUAL_365') },
        ].map((convention) => (
            <option key={convention.id} value={convention.id}>
                {convention.name}{' '}
                {selectedDeposit.interestRateConvention === convention.id && `(${t('common.unchanged')})`}
            </option>
        ))

    const interestRateBenchmarks = t('common.interestRateBenchmark', { returnObjects: true }) as Map<
        InterestRateBenchmark,
        string
    >

    return (
        <>
            <PageHeader
                title={t('pages-interestratechange-bank.newNoticePageHeader')}
                backToLink={'/interestratechange-bank'}
            />
            <PageLayout>
                {authorization.bank.hasAdManagerRole && (
                    <FxPaper title={t('pages-interestratechange-bank.newNoticeActionHeader')}>
                        <p>{t('pages-interestratechange-bank.newNoticeInfo')}</p>
                        <LabeledInput
                            helpText={t('pages-interestratechange-bank.newNoticeStep1HelpText')}
                            label={t('pages-interestratechange-bank.newNoticeStep1')}
                            errorMessage={depositIdField.errorMessage}
                            className={styles.labeledInput}
                        >
                            <select
                                name="deposit"
                                value={depositIdField.value}
                                onChange={(e) => depositIdField.setValue(e.target.value)}
                                onBlur={depositIdField.onBlur}
                            >
                                <option value="">{t('pages-interestratechange-bank.newNoticeSelectDeposit')}</option>
                                {depositOptions}
                            </select>
                            {current}
                        </LabeledInput>
                        <LabeledInput
                            helpText={t('pages-interestratechange-bank.newNoticeStep2HelpText')}
                            label={t('pages-interestratechange-bank.newNoticeStep2')}
                            errorMessage={newInterestField.errorMessage}
                            className={styles.labeledInput}
                        >
                            <input
                                className={styles.interestInput}
                                name="newInterest"
                                type="text"
                                value={newInterestField.value}
                                onChange={(e) => newInterestField.setValue(e.target.value)}
                                onBlur={newInterestField.onBlur}
                                disabled={!selectedDeposit}
                            />
                            <span>%</span>
                            <div className={styles.example}>
                                {t('pages-interestratechange-bank.newNoticeStep2Example')}
                            </div>
                        </LabeledInput>
                        {newInterestIsOverOnePercent && (
                            <p className="field-info-message">
                                {t('pages-interestratechange-bank.over1PercentWarning')}
                            </p>
                        )}

                        <LabeledInput
                            helpText={t('pages-interestratechange-bank.newNoticeStep3HelpText')}
                            label={t('pages-interestratechange-bank.newNoticeStep3')}
                            errorMessage={ircDateField.errorMessage}
                            className={styles.labeledInput}
                        >
                            <DatePicker
                                id="interestDate"
                                className={styles.dateInput}
                                selected={ircDateField.value}
                                onChange={(date) => {
                                    ircDateField.setValue(date)
                                }}
                                openToDate={getFirstValidDate()}
                                filterDate={(date) => isValidBankDay(date) && !(date < getFirstValidDate())}
                                disabled={!selectedDeposit}
                            />
                        </LabeledInput>

                        {selectedDeposit?.interestRateConvention !== 'ACTUAL_ACTUAL' && (
                            <LabeledInput
                                helpText={t('pages-interestratechange-bank.newNoticeStep4HelpText')}
                                label={t('pages-interestratechange-bank.newNoticeStep4')}
                                className={styles.labeledInput}
                            >
                                <select
                                    name="interestConvention"
                                    value={newInterestRateConventionField.value}
                                    onChange={(e) => newInterestRateConventionField.setValue(e.target.value)}
                                    onBlur={newInterestRateConventionField.onBlur}
                                    disabled={!selectedDeposit}
                                >
                                    {interestConventionOptions}
                                </select>
                            </LabeledInput>
                        )}

                        <LabeledInput
                            helpText={t('pages-interestratechange-bank.newNoticeStep5HelpText')}
                            label={t('pages-interestratechange-bank.newNoticeStep5')}
                            className={styles.labeledInput}
                        >
                            <select
                                name="interestBenchmark"
                                value={newInterestRateBenchmarkField.value}
                                onChange={(e) => newInterestRateBenchmarkField.setValue(e.target.value)}
                                onBlur={newInterestRateBenchmarkField.onBlur}
                                disabled={!selectedDeposit}
                            >
                                {Object.keys(interestRateBenchmarks).map((v) => (
                                    <option key={v} value={v}>
                                        {interestRateBenchmarks[v]}{' '}
                                        {selectedDeposit.interestRateBenchmark === v && `(${t('common.unchanged')})`}
                                    </option>
                                ))}
                            </select>
                        </LabeledInput>

                        <AsyncButton onClick={onSubmit} id="createInterestRateChangeButton">
                            {t('pages-interestratechange-bank.newNoticeButtonText')}
                        </AsyncButton>
                    </FxPaper>
                )}
                {depositIdField.value && (
                    <HistoryPanel
                        processId={processIdMap[depositIdField.value]}
                        processName={formatAccount(selectedDeposit?.account, organisationCountry)}
                    />
                )}
            </PageLayout>
        </>
    )
}
