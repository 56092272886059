import config from '#app/config'
import { DateOutput, Paper } from '#components'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import { useSelector } from '#state/useSelector'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const API_BASE_URL = config().apiUrl

type Params = {
    depositId?: string
}

export default function DepositTerminationSentToBank() {
    const { t } = useTranslation()
    const { depositId } = useParams<Params>()

    const deposit = useSelector(({ deposits }) => deposits.find((deposit) => deposit.id === depositId))
    const interestRateChange = useSelector((state) =>
        state.interestRateChange.find((irc) => irc.id === deposit.interestRateChangeId)
    )
    const terminationDocument = useSelector(
        (state) => deposit.terminationDocumentId && state.documents[deposit.terminationDocumentId]
    )

    switch (deposit.terminationType) {
        case 'CONDITIONAL':
            return (
                <Paper
                    id="terminationIsSentToBankConfirmation"
                    title={t('pages-portfolio-depositor.conditionalTerminationSentHeader')}
                >
                    <p>
                        {t('pages-portfolio-depositor.conditionalTerminationSentInfo1', {
                            date: deposit.terminationRequested
                                ? ' ' + DateOutput.formatDateTime(deposit.terminationRequested)
                                : '',
                        })}
                    </p>
                    <Box mb={1}>
                        {terminationDocument && (
                            <DocumentLink
                                name={t('pages-portfolio-depositor.conditionalTerminationDocumentName')}
                                link={API_BASE_URL + terminationDocument.link}
                            />
                        )}
                    </Box>
                    <p>
                        {t('pages-portfolio-depositor.conditionalTerminationSentInfo2', {
                            deadline: DateOutput.formatDateTime(deposit.interestRateRequirementDate),
                            date: deposit.terminationDate
                                ? ' ' + DateOutput.formatVerboseMonth(deposit.terminationDate)
                                : '',
                        })}
                    </p>
                </Paper>
            )
        case 'REJECT_INTEREST_RATE_CHANGE':
            if (!interestRateChange) return <p>{t('common.loading')}</p>
            return (
                <Paper
                    id="terminationIsSentToBankConfirmation"
                    title={t('pages-portfolio-depositor.conditionalTerminationSentHeader')}
                >
                    <p>
                        {t('pages-portfolio-depositor.rejectInterestChangeInfo1', {
                            date: deposit.terminationRequested
                                ? ' ' + DateOutput.formatDateTime(deposit.terminationRequested)
                                : '',
                        })}
                    </p>
                    <Box mb={1}>
                        {terminationDocument && (
                            <DocumentLink
                                name={t('pages-portfolio-depositor.conditionalTerminationDocumentName')}
                                link={API_BASE_URL + terminationDocument.link}
                            />
                        )}
                    </Box>
                    <p>
                        {t('pages-portfolio-depositor.rejectInterestChangeInfo2', {
                            date: deposit.terminationDate
                                ? ' ' + DateOutput.formatVerboseMonth(deposit.terminationDate)
                                : '',
                        })}
                    </p>
                </Paper>
            )
        case 'UNCONDITIONAL':
            return (
                <Paper
                    id="terminationIsSentToBankConfirmation"
                    title={t('pages-portfolio-depositor.unconditionalTerminationSentHeader')}
                >
                    <p>
                        {t('pages-portfolio-depositor.unconditionalTerminationSentInfo1', {
                            date: deposit.terminationRequested
                                ? ' ' + DateOutput.formatDateTime(deposit.terminationRequested)
                                : '',
                        })}
                    </p>
                    <p>
                        {t('pages-portfolio-depositor.unconditionalTerminationSentInfo2', {
                            date: deposit.terminationDate
                                ? ' ' + DateOutput.formatVerboseMonth(deposit.terminationDate)
                                : '',
                        })}
                    </p>
                    {deposit.terminationReason && (
                        <p>{t('pages-portfolio-depositor.terminationReason', { reason: deposit.terminationReason })}</p>
                    )}
                </Paper>
            )
    }
}
