import { PURPLE, SILVER_GRAY } from '#app/colors/colors'
import { DateOutput, InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { formatAccount } from '#app/services/formatnumber'
import getDepositValue from '#app/services/getDepositValue'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useSelector } from '#app/state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Divider,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import config from '../../../config'

export const DepositCard = ({ deposit, archived }: { deposit: DepositDto; archived?: boolean }) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()
    const navigate = useNavigate()
    const bank = useSelector((state) => state.banks[deposit.bankId])
    const API_BASE_URL = config().apiUrl
    const getBankLogoUrl = (bankId) => `${API_BASE_URL}/api/bank/${bankId}/image/logo`
    return (
        <Card
            sx={{
                filter: archived ? 'grayscale(100%)' : null,
                width: '40rem',
                position: 'relative',
                maxWidth: '100%',
                my: 1,
                ml: 0,
                mr: {
                    sm: 2,
                },
                transition: '0.3s',
            }}
        >
            <CardContent>
                <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                    <Stack>
                        <Typography fontWeight="600">{bank?.name}</Typography>
                        <Typography variant="subtitle1">
                            {formatAccount(deposit.account, organisationCountry)}
                        </Typography>
                    </Stack>
                    <Box sx={{ minHeight: '4.4rem', maxWidth: { xs: '10rem', sm: '13rem' } }}>
                        <img style={{ maxWidth: '100%' }} src={getBankLogoUrl(bank?.id)} alt="" />
                    </Box>
                </Stack>
            </CardContent>
            <Divider />
            {!archived && (
                <>
                    <CardContent>
                        <Stack>
                            <Typography fontSize={'1.6rem'} color={SILVER_GRAY[500]}>
                                {t('pages-portfolio-depositor.headersVolume')}
                            </Typography>
                            <Typography fontSize={'3.2rem'} fontWeight={700}>
                                {Currency(getDepositValue(deposit), { decimals: 0 })}
                            </Typography>
                        </Stack>
                    </CardContent>
                    <Divider />
                </>
            )}
            {deposit.terminationDate && deposit.product.type !== 'FIXED' && (
                <>
                    <Alert icon={false} color="warning" sx={{ boxShadow: 'none', pl: 3 }}>
                        <AlertTitle>{t('pages-portfolio-depositor.depositTerminated')}</AlertTitle>
                        {t('pages-portfolio-depositor.paymentDate')}: <DateOutput.Date date={deposit.terminationDate} />
                    </Alert>
                    <Divider />
                </>
            )}
            <CardContent>
                <Stack spacing={2}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack>
                                <Typography fontSize={'1.4rem'} color={SILVER_GRAY[500]}>
                                    {t('common.effectiveInterest')}
                                </Typography>
                                <Typography fontSize={'2rem'} fontWeight={800} color={PURPLE[500]}>
                                    {InterestOutput.format(deposit.effectiveInterestRate)}
                                </Typography>
                            </Stack>
                        </Grid>
                        {deposit.product.type !== 'FIXED' && (
                            <Grid item xs={6}>
                                <Stack>
                                    <Typography fontSize={'1.4rem'} color={SILVER_GRAY[500]}>
                                        {t('common.marginAddition')}
                                    </Typography>
                                    <Typography fontSize={'2rem'} fontWeight={800} color={PURPLE[500]}>
                                        {InterestOutput.format(deposit.nominalInterestRate)}
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <Stack>
                                <Typography fontSize={'1.4rem'} color={SILVER_GRAY[500]}>
                                    {deposit.product.termsType === 'NOTICE' && t('pages-marketplace.noticePeriod')}
                                    {(deposit.product.termsType === 'FIXED_TERMS' ||
                                        deposit.product.termsType === 'RECURRING_FIXED_TERMS') &&
                                        t('pages-marketplace.duration')}
                                </Typography>
                                <Typography fontSize={'1.6rem'} fontWeight={600}>
                                    {t(`common.productLongName${deposit.product.id}`)}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack>
                                <Typography fontSize={'1.4rem'} color={SILVER_GRAY[500]}>
                                    {t('pages-marketplace.interestType')}
                                </Typography>
                                <Typography fontSize={'1.6rem'} fontWeight={600}>
                                    {deposit.product.type === 'FIXED'
                                        ? t('pages-portfolio-depositor.fixedInterestLabel')
                                        : t('pages-marketplace.floatingInterest')}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
            <Divider />
            <CardActionArea color={PURPLE[500]} onClick={() => navigate('/portfolio/id/' + deposit.id)}>
                <CardContent>
                    <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                        <Typography fontWeight={600} color={PURPLE[500]}>
                            {t('pages-portfolio-depositor.goToDeposit')}
                        </Typography>
                        <i className="ri-arrow-right-line purple" style={{ fontSize: '2rem' }} />
                    </Stack>
                </CardContent>
            </CardActionArea>
            {archived && (
                <Box>
                    <Alert
                        icon={<i className="ri-archive-line" />}
                        severity="info"
                        sx={{ fontSize: '1.2rem', borderRadius: 0, boxShadow: 'none' }}
                    >
                        {t('pages-portfolio-depositor.noLongerActiveDeposit')}{' '}
                        {DateOutput.formatDate(deposit.terminationDate)}
                    </Alert>
                </Box>
            )}
        </Card>
    )
}
