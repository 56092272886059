import { PURPLE } from '#app/colors/colors'
import { DownloadOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { forwardRef } from 'react'

type DownloadLinkProps = {
    link: string
    icon?: React.ReactNode
}

const DownloadLink = forwardRef<HTMLAnchorElement, DownloadLinkProps>(({ link, icon, ...props }, ref) => (
    <IconButton
        ref={ref}
        rel="noopener noreferrer"
        color={'primary'}
        target="_blank"
        sx={{ fontSize: '2.6rem', p: 1.5, backgroundColor: PURPLE[50], color: PURPLE[800] }}
        href={link}
        {...props}
    >
        {icon ? icon : <DownloadOutlined />}
    </IconButton>
))

DownloadLink.displayName = 'DownloadLink'

export default DownloadLink
