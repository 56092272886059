import { PageHeader, PageLayout } from '#app/components'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import PartnerProposalsOverview from './ProposalsOverview'

export default function PartnerProposalRoutes() {
    const { t } = useTranslation()
    return (
        <>
            <PageHeader icon="ri-mail-send-line" title={t('menu.proposals')} />
            <PageLayout>
                <Routes>
                    <Route path="/" element={<Navigate to={{ pathname: '/partner/proposals/sent' }} />} />
                    <Route path="/*" element={<PartnerProposalsOverview />} />
                </Routes>
            </PageLayout>
        </>
    )
}
