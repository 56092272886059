import { Fragment, useCallback } from 'react'
import styles from './BankEmail.module.css'
import { validateEmailAddress } from '#services/validateFields'
import { useTranslation } from '#components/i18n'
import { useFieldState } from '@fixrate/fieldstate'
import { useCommand } from '#command'
import { useConfirmationModal } from '#app/layers/ConfirmationModal/ConfirmationModal'
import { BankDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import AsyncButton from '#app/components/Button/AsyncButton'

export default function BankEmailRegistrationForm({ bank }: { bank: BankDto }) {
    const { t } = useTranslation('pages-mybank')
    const { setBankEmail } = useCommand()
    const confirmationModal = useConfirmationModal()

    const emailValidator = useCallback(
        ({ value, isEditing }) => {
            if (isEditing) return
            if (value === '') return t('emailMissing')
            if (!validateEmailAddress(value)) return t('emailInvalid')
        },
        [t]
    )
    const emailField = useFieldState(bank.email || '', emailValidator)

    async function submitEmail() {
        if (!emailField.touched || !emailField.validate()) {
            return
        }

        const { waitForCommand } = await setBankEmail(bank.id, emailField.value)
        const success = await waitForCommand()

        if (success) {
            emailField.untouch()
            confirmationModal(
                bank.email ? t('emailUpdated') : t('emailRegistered'),
                t('emailConfirmation', { email: emailField.value }),
                t('common.continue')
            )
        }
    }

    async function removeEmail() {
        if (emailField.value) {
            emailField.setValue('')
            const { waitForCommand } = await setBankEmail(bank.id, '')
            const success = await waitForCommand()
            if (success) {
                emailField.untouch()
                confirmationModal(t('emailRemoved'), t('emailRemovedConfirmation'), t('common.continue'))
            }
        }
    }

    const emailRegistered = !!bank.email

    return (
        <Fragment>
            <dl>
                <dt>{t('emailAddressLabel')}</dt>
                <dd>
                    <input
                        type="email"
                        name="email"
                        className={styles.emailField}
                        value={emailField.value}
                        onChange={(event) => emailField.setValue(event.target.value)}
                        onBlur={emailField.onBlur}
                    />
                    <i className={classNames('ri-close-circle-line', styles.closeIcon)} onClick={removeEmail} />
                    <p className="field-error-message">{emailField.errorMessage}</p>
                </dd>
            </dl>
            <AsyncButton onClick={submitEmail}>
                {emailRegistered ? t('updateEmailButtonText') : t('registerEmailButtonText')}
            </AsyncButton>
        </Fragment>
    )
}
