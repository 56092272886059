import styles from '#components/Button/Button.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

type Props = {
    id?: string
    to: string
    children: React.ReactNode
    className?: string
    variant?: 'stroked-primary' | 'secondary' | 'tertiary' | 'transparent' | 'cancel'
    inline?: boolean
    rel?: string
    target?: string
}

export default function LinkButton({ id, to, children, className, variant, inline, ...props }: Props) {
    const classes = classNames(styles.button, variant && styles?.[variant], className, inline ? styles.inline : null)

    return (
        <Link id={id} to={to} className={classes} {...props}>
            {children}
        </Link>
    )
}
