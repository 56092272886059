import { PURPLE, SUCCESS_GREEN } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import ChangeOrgDialog from '#app/components/ChangeOrgDialog/ChangeOrgDialog'
import { getOrganisationSelectorOptions } from '#app/components/PageHeader/PageHeader'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import useUnresolvedTasks from '#app/services/useUnresolvedTasks'
import { useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import { useLocalStorage } from '#services/LocalStorageContext'
import { switchOrganisation } from '#services/thunks/session'
import {
    authorizationDocumentsToSign,
    canBuyFundsSelector,
    depositorAssociations,
    hasFundAccessSelector,
    hasSignAuthorizationRole,
    hasViewDepositorRole,
    isLoggedIn,
} from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Button, ButtonBase, Divider, Hidden, LinearProgress, Snackbar, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './DepositorMenu.module.scss'
import menuStyles from '../Menu.module.scss'
import MenuItem from './MenuItem'
import { isAfter, isBefore } from 'date-fns'
import { CAKE_CAMPAIGN } from '#app/pages/marketplace/StartOrder/StartOrder'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import CakeForm from '#app/components/marketingForms/CakeForm/CakeForm'
import useUiSetting from '#app/services/useUiSetting'

type ShowChangeOrgDialog = {
    show: boolean
    newOrgId?: string
}

export default function DepositorMenu() {
    const { t } = useTranslation()

    const hasAssociations = useSelector((state) => {
        const value = depositorAssociations(state)
        return value ? value.length > 0 : false
    })

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const canSign = useSelector(hasSignAuthorizationRole)
    const unresolvedTasks = useUnresolvedTasks()
    const hasDocumentsToSign = useSelector((state) => authorizationDocumentsToSign(state).length) > 0
    const hasViewRole = useSelector(hasViewDepositorRole)
    const loggedIn = useSelector(isLoggedIn)
    const hasDepositorOffers = useSelector((state) => state.depositor?.premiumProducts.includes('OFFER'))
    const associations = useSelector((state) => state.session?.associations)
    const association = useSelector((state) => state.session?.association)
    const organizations = getOrganisationSelectorOptions(associations, association)
    const depositors = organizations.filter((organization) => organization?.organisationType === 'DEPOSITOR')
    const fundAvailable = useSelector(hasFundAccessSelector)
    const fundBuyAvailable = useSelector(canBuyFundsSelector)
    const currentDepositor = useCurrentDepositor()
    const {
        keys: { doNotShowInboxSwitchMessage },
    } = useLocalStorage()

    const [showChangeOrgDialog, setShowChangeOrgDialog] = useState<ShowChangeOrgDialog>({ show: false })
    const isPartner = organizations?.some((org) => org?.organisationType === 'PARTNER')
    const depositor = useSelector((state) => state.depositor)
    const proposals = depositor?.partnerRelations?.map((relation) => relation?.partnerProposals)?.flat()
    const hasProposals = proposals?.length > 0
    const hasDeposits = useSelector((state) => state.deposits.length > 0)

    const showSignMenuItem = canSign && hasDocumentsToSign
    const hasAnalytics = true
    const wizardStatus = useOnboardingWizardStatus(depositor)

    const registrationProgress = wizardStatus?.progress || 0
    const registerItemSlug = wizardStatus?.currentSlug

    const [cakeDialogVisible, setCakeDialogVisible] = useState(false)
    const [cakeSent, setCakeSent] = useUiSetting('hasOrdered20MRDCake', false)
    const [cakeSentConfirmation, setCakeSentConfirmation] = useState(false)

    const fundBuyOrders = useSelector((state) => state.fundBuyOrders)
    const showCakeMessage =
        !cakeSent &&
        fundBuyOrders?.some((order) =>
            order?.created
                ? isAfter(new Date(order.created), CAKE_CAMPAIGN.start) &&
                  isBefore(new Date(order.created), CAKE_CAMPAIGN.end)
                : false
        )
    /**
     * Switches the current organization to the new organization
     */
    const doSwitchOrganization = (organisationType: OrganisationType, orgId: string) => {
        dispatch(
            switchOrganisation(organisationType, orgId, () => {
                if (currentDepositor && orgId) {
                    navigate(window.location.pathname.replace(currentDepositor.id, orgId))
                }
            })
        )
        setShowChangeOrgDialog({ show: false })
    }

    /**
     * Initiates the change of organization, either by showing the dialog or by switching directly
     */
    const startSwitchOrganization = (newOrgId: string) => {
        if (newOrgId !== currentDepositor?.id) {
            if (doNotShowInboxSwitchMessage) {
                doSwitchOrganization('DEPOSITOR', newOrgId)
            } else {
                setShowChangeOrgDialog({ show: true, newOrgId: newOrgId })
            }
        }
    }

    const orderMenuItem = () => {
        if (fundBuyAvailable) {
            return (
                <MenuItem
                    id="ordersMenuItem"
                    key="orders"
                    link={'/orders'}
                    name={t('menu.orders')}
                    remixIcon="ri-checkbox-circle-line"
                >
                    <MenuItem.SubNav to="/orders" notActivePath="/orders/funds">
                        {t('menu.depositOrders')}
                    </MenuItem.SubNav>
                    <MenuItem.SubNav to="/orders/funds">{t('menu.fundOrders')}</MenuItem.SubNav>
                </MenuItem>
            )
        } else {
            return (
                <MenuItem
                    id="ordersMenuItem"
                    key="orders"
                    link={'/orders'}
                    name={t('menu.orders')}
                    remixIcon="ri-checkbox-circle-line"
                ></MenuItem>
            )
        }
    }

    const portfolioMenuItem = (
        <MenuItem
            id="portfolioMenuItem"
            key="portfolio"
            link={'/portfolio'}
            name={t('menu.portfolio')}
            remixIcon="ri-briefcase-line"
        >
            <MenuItem.SubNav id="portfolioOverviewLink" to="/portfolio/overview">
                {t('menu.overview')}
            </MenuItem.SubNav>
            {fundAvailable && (
                <MenuItem.SubNav id="portfolioFundTransactionsLink" to="/portfolio/fund-transactions">
                    {t('menu.fundTransactions')}
                </MenuItem.SubNav>
            )}
        </MenuItem>
    )

    const reportsMenuItem = (
        <MenuItem
            id="reportsMenuItem"
            key="reports"
            link={'/reports'}
            name={t('menu.reports')}
            remixIcon="ri-file-chart-2-line"
        >
            <MenuItem.SubNav id="depositReportsLink" to="/reports/deposits/interest-balance">
                {t('menu.bankDeposits')}
            </MenuItem.SubNav>
            {fundAvailable && (
                <MenuItem.SubNav id="fundReportsLink" to="/reports/funds">
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <span>{t('menu.funds')}</span>
                    </Stack>
                </MenuItem.SubNav>
            )}
        </MenuItem>
    )

    const analyticsMenuItem = (
        <MenuItem id="analyticsMenuItem" link={'/analytics'} name={t('menu.analytics')} remixIcon="ri-file-chart-line">
            <MenuItem.SubNav id="analyticsDashboardLink" to="/analytics/depositor/dashboard">
                {t('menu.dashboard')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="analyticsDepositsLink" to="/analytics/depositor/deposits">
                {t('menu.deposits')}
            </MenuItem.SubNav>
            <MenuItem.SubNav id="analyticsExposureLink" to="/analytics/depositor/exposure">
                {t('menu.bankExposure')}
            </MenuItem.SubNav>
            {hasDeposits && (
                <MenuItem.SubNav id="analyticsAddedValueLink" to="/analytics/depositor/added-value">
                    {t('menu.addedValue')}
                </MenuItem.SubNav>
            )}
            <MenuItem.SubNav id="analyticsNiborLink" to="/analytics/nibor">
                {t('menu.nibor')}
            </MenuItem.SubNav>
        </MenuItem>
    )

    const signMenuItem = (
        <MenuItem id="signMenuItem" link={'/sign'} name={t('menu.signing')} remixIcon="ri-pen-nib-line">
            {!isPartner &&
                depositors.length > 1 &&
                depositors.map((depositor) => (
                    <MenuItem.SubNavOnClick
                        className={styles.pointer}
                        key={depositor?.organisationId}
                        isActive={depositor?.selected}
                        onClick={() => startSwitchOrganization(depositor?.organisationId)}
                    >
                        {depositor?.name}
                    </MenuItem.SubNavOnClick>
                ))}
        </MenuItem>
    )

    const depositorOfferMenuItem = (
        <MenuItem
            id="depositorOfferMenuItem"
            link={'/depositoroffer'}
            name={t('menu.offers')}
            remixIcon="ri-mail-send-line"
        />
    )

    const inboxMenuItem = (
        <MenuItem
            id="inboxMenuItem"
            link={'/inbox'}
            name={t('menu.inbox')}
            remixIcon="ri-inbox-line"
            counter={!isPartner ? unresolvedTasks.total : unresolvedTasks.organisations[currentDepositor?.id]}
        >
            {!isPartner &&
                depositors.length > 1 &&
                depositors.map((depositor) => (
                    <MenuItem.SubNavOnClick
                        className={styles.pointer}
                        key={depositor?.organisationId}
                        isActive={currentDepositor?.id === depositor?.organisationId}
                        onClick={() => startSwitchOrganization(depositor?.organisationId)}
                        counter={unresolvedTasks.organisations[depositor?.organisationId]}
                    >
                        {depositor?.name}
                    </MenuItem.SubNavOnClick>
                ))}
        </MenuItem>
    )

    const loginMenuItem = (
        <MenuItem
            id="loginMenuItem"
            key="login"
            link={'/marketplace?login'}
            name={t('menu.logIn')}
            remixIcon="ri-account-circle-line"
        />
    )

    const marketplaceMenuItem = (
        <MenuItem
            id="marketPlaceMenuItem"
            link={'/marketplace'}
            name={t('menu.marketplace')}
            remixIcon="ri-store-2-line"
        />
    )

    const proposalsMenuItem = (
        <MenuItem
            id="proposalsMenuItem"
            link={'/proposals'}
            name={t('menu.accountProposals')}
            remixIcon="ri-lightbulb-line"
        />
    )

    const newsMenuItem = (
        <MenuItem id="newsMenuItem" link={'/news'} name={t('menu.news')} remixIcon="ri-newspaper-line" />
    )

    const wizardProgressMenuItem = (
        <ButtonBase
            sx={{ backgroundColor: 'white' }}
            onClick={() => navigate(registerItemSlug)}
            component="a"
            data-cy="goToWizard"
        >
            <Stack width="100%">
                <Hidden mdDown>
                    <Divider />
                </Hidden>
                <Stack width="100%" px={2.5} py={{ xs: 2, md: 3 }}>
                    <Typography variant="h4" data-cy="wizardProgressTitle">
                        {wizardStatus?.progress < 100 ? t('menu.finishRegistration') : t('menu.registrationFinished')}
                    </Typography>
                    <Stack mt={0.5} width="100%">
                        <Typography data-cy="wizardProgress" variant="caption" fontWeight="700" color={PURPLE[500]}>
                            {InterestOutput.formatWithDecimals(registrationProgress, 0)}{' '}
                            {t('pages-OrganizationWizard.statusFinished')}
                        </Typography>
                        <LinearProgress
                            sx={{ width: '100%', marginTop: '0.6rem', height: '0.8rem', borderRadius: '10rem' }}
                            variant={'determinate'}
                            value={registrationProgress}
                        />
                    </Stack>
                </Stack>
                <Hidden mdUp>
                    <Divider />
                </Hidden>
            </Stack>
        </ButtonBase>
    )

    return (
        <Fragment>
            {wizardStatus?.wizardActive ? (
                <ul className={menuStyles.menu__list}>
                    {marketplaceMenuItem}
                    {loggedIn && (hasViewRole || !hasAssociations) && inboxMenuItem}
                    {loggedIn && showSignMenuItem && signMenuItem}
                    {!loggedIn && loginMenuItem}
                </ul>
            ) : (
                <ul className={menuStyles.menu__list}>
                    {showChangeOrgDialog.show && (
                        <ChangeOrgDialog
                            newOrgId={showChangeOrgDialog.newOrgId}
                            switchOrganisation={doSwitchOrganization}
                            closeFn={() => setShowChangeOrgDialog({ show: false })}
                        />
                    )}
                    {marketplaceMenuItem}
                    {loggedIn && (hasViewRole || !hasAssociations) && inboxMenuItem}
                    {loggedIn && hasAssociations && hasViewRole && orderMenuItem()}
                    {loggedIn && hasDepositorOffers && hasViewRole && depositorOfferMenuItem}
                    {loggedIn && hasProposals && hasViewRole && proposalsMenuItem}
                    {loggedIn && hasAssociations && hasViewRole && portfolioMenuItem}
                    {loggedIn && hasAssociations && hasViewRole && reportsMenuItem}
                    {loggedIn && hasAnalytics && hasViewRole && analyticsMenuItem}
                    {loggedIn && fundAvailable && newsMenuItem}
                    {loggedIn && showSignMenuItem && signMenuItem}
                    {!loggedIn && loginMenuItem}
                </ul>
            )}
            {loggedIn && wizardStatus?.wizardActive && (
                <Stack mt={'auto'} width="100%">
                    {wizardProgressMenuItem}
                </Stack>
            )}
            {loggedIn && showCakeMessage && (
                <Stack mt={'auto'} sx={{ backgroundColor: PURPLE[50], p: 2, width: '100%' }}>
                    <FixrateIllustration name="cake" color="default" />
                    <Typography variant="h2" mt={2}>
                        Gratulerer!
                    </Typography>
                    <Typography fontSize={'1.6rem'}>Du har gjort deg fortjent til en gratis kake</Typography>
                    <Button variant="contained" sx={{ mt: 3 }} onClick={() => setCakeDialogVisible(true)}>
                        Bestill kake
                    </Button>
                    <CakeForm
                        formId={CAKE_CAMPAIGN.formId}
                        title="Bestill kake"
                        open={cakeDialogVisible}
                        onFinish={() => {
                            setCakeSent(true)
                            setCakeSentConfirmation(true)
                            setCakeDialogVisible(false)
                        }}
                        onClose={() => {
                            setCakeDialogVisible(false)
                        }}
                    />
                </Stack>
            )}
            <Snackbar
                sx={{ '& .MuiSnackbarContent-root': { backgroundColor: SUCCESS_GREEN[500] } }}
                color="success"
                open={cakeSentConfirmation}
                autoHideDuration={6000}
                onClose={() => setCakeSentConfirmation(false)}
                message={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <i className="ri-cake-line" style={{ fontSize: '2rem' }} />
                        <Typography fontWeight={600}>Kaken er bestilt!</Typography>
                    </Stack>
                }
            />
        </Fragment>
    )
}
