import { useState } from 'react'
import { ButtonRow } from '#components'
import { useTranslation } from 'react-i18next'
import { FundCustomerDto, Portfolio } from '@fixrate/fixrate-query'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Button, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'

type Props = {
    customer: FundCustomerDto
    portfolio: Portfolio
}

export default function SetCid({ customer, portfolio }: Props) {
    const { t } = useTranslation()
    const { setDepositorFundCid } = useCommand()

    const cidField = useFieldState<string>(portfolio.fundData.cid, ({ value, isEditing }) => {
        if (isEditing) {
            return
        }
        if (!isValidNumber(value)) {
            return t('pages.fundCustomers.customerDetail.cid.mustBeNumber')
        }
        if (!isValidMod10(value)) {
            return t('pages.fundCustomers.customerDetail.cid.wrongControlNumber')
        }
        if (!isValidCid(value)) {
            return t('pages.fundCustomers.customerDetail.cid.invalidCid')
        }
    })

    const [submitting, setSubmitting] = useState(false)

    async function submit() {
        if (!cidField.validate()) {
            return
        }

        setSubmitting(true)
        const { waitForCommand } = await setDepositorFundCid(customer.depositorId, portfolio.id, cidField.value)
        const success = await waitForCommand()
        setSubmitting(false)

        if (success) {
            cidField.untouch()
        }
    }

    return (
        <dl>
            <dt>
                <label>{t('pages.fundCustomers.customerDetail.cid.label')}</label>
            </dt>
            <dd>
                <TextField
                    value={cidField.value || ''}
                    onChange={(e) => cidField.setValue(e.target.value)}
                    onBlur={cidField.onBlur}
                    placeholder={t('pages.fundCustomers.customerDetail.cid.placeholder')}
                />
                {!cidField.valid && <p className="field-error-message">{cidField.errorMessage}</p>}
            </dd>
            <Box
                sx={{
                    mt: 1,
                    transformOrigin: 'top',
                    transform: `scaleY(${cidField.touched ? 1 : 0})`,
                    transition: 'transform 0.2s',
                }}
            >
                <ButtonRow align={'left'}>
                    <LoadingButton
                        size={'small'}
                        onClick={submit}
                        variant={'contained'}
                        loading={submitting}
                        disabled={submitting}
                    >
                        {t('common.save')}
                    </LoadingButton>
                    <Button size={'small'} variant={'outlined'} onClick={() => cidField.reset()} disabled={submitting}>
                        {t('common.cancel')}
                    </Button>
                </ButtonRow>
            </Box>
        </dl>
    )
}

function isValidNumber(value: string): boolean {
    if (!value) {
        return false
    }
    return !isNaN(parseFloat(value.replace(/\s/g, '').replace(',', '.')))
}

function isValidMod10(value: string): boolean {
    if (!isValidNumber(value)) return false

    let isOne = false
    let checkSum = 0
    let i

    for (i = value.length - 2; i >= 0; --i) {
        const intNumber = parseInt(value.charAt(i))
        let sum = isOne ? intNumber : intNumber * 2
        if (sum > 9) {
            sum = (sum % 10) + 1
        }
        isOne = !isOne
        checkSum += sum
    }
    const controlNumber = (10 - (checkSum % 10)) % 10 === 0 ? 0 : 10 - (checkSum % 10)
    return controlNumber === parseInt(value.charAt(value.length - 1))
}

function isValidCid(value: string): boolean {
    if (value.length > 0) return isValidMod10(value)
    return true
}
