import { getBenchmarkInterest } from '#app/services/thunks/statistics'
import { useSelector } from '#app/state/useSelector'
import { StaggData } from '#state/stagg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ChartComp } from '#pages/BankDashboard/components/ChartComp'

export function BenchmarkGraph({ height }: { height: number }) {
    const { t } = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<StaggData>>()
    const [data, setData] = useState<StaggData>([])
    const [error, setError] = useState(false)
    const currency = useSelector((state) => state.session?.association?.currentCurrency)

    useEffect(() => {
        dispatch(getBenchmarkInterest(currency, 'range=6M'))
            .then(setData)
            .catch(() => setError(true))
    }, [currency, dispatch])

    const readableLegend = (tags) => {
        if (tags.type === 'reference') {
            return t('pages-BankDashboard.policyRate')
        } else if (tags.type === 'NIBOR_3M') {
            return t('common.interestRateBenchmark.' + tags.type)
        } else {
            return tags.type
        }
    }

    return (
        <>
            {data.length !== 0 && (
                <ChartComp
                    data={data}
                    dataType={'time'}
                    error={error}
                    gradient={true}
                    smallSize={false}
                    type={'line'}
                    height={height ?? 120}
                    tickValue="%"
                    beginAtZero={false}
                    yAxisBuffer={0.02}
                    labels={readableLegend}
                    textColor={'#666'}
                    gridColor={'rgba(0,0,0,0.05)'}
                />
            )}
        </>
    )
}
