import { useBankCalendar } from '#app/services/useBankCalendar'
import { DateOutput } from '#components'
import { isValidDate, parseNorwegianDateFormat } from '#services/dateandtime'
import { useSessionLanguage } from '#services/useSessionLanguage'
import { IconButton, Stack, SxProps, TextField } from '@mui/material'
import {
    CalendarIcon,
    DatePicker,
    DatePickerFieldProps,
    DatePickerProps,
    DateValidationError,
    LocalizationProvider,
    usePickersContext,
    useSplitFieldProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enGB, nb, sv } from 'date-fns/locale'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PatternFormat } from 'react-number-format'

type Props = {
    value?: Date | null
    onChange?: (value: Date | null) => void
    required?: boolean
    dataCy?: string
    hideErrorMessage?: boolean
    minDate?: Date
    maxDate?: Date
    shouldDisableDate?: (date: Date) => boolean
    onlyBankDays?: boolean
    sx?: SxProps
    id?: string
}

const getSelectedLanguage = (language: string) => {
    switch (language) {
        case 'en':
            return enGB
        case 'sv':
            return sv
        default:
            return nb
    }
}

function FixrateDateField(props: DatePickerFieldProps<Date, false>) {
    const [isFilledOut, setIsFilledOut] = useState(true)
    const { internalProps, forwardedProps } = useSplitFieldProps(props, 'date')
    const { value } = internalProps
    const { InputProps } = forwardedProps

    const ref = useRef(null)
    const pickersContext = usePickersContext()

    // TODO: Make it work with typescript
    //const cypressTag = forwardedProps?.ownerState?.dataCy

    const handleTogglePicker = (event: React.UIEvent) => {
        if (pickersContext.open) {
            pickersContext.onClose(event)
        } else {
            pickersContext.onOpen(event)
        }
    }

    return (
        <PatternFormat
            mask={'_'}
            inputRef={ref}
            format={'##.##.####'}
            type={'tel'}
            placeholder={'dd.MM.yyyy'}
            customInput={TextField}
            value={value ? DateOutput.formatDate(value) : ''}
            sx={{ ...props?.sx }}
            data-cy={props?.id}
            id={props?.id}
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <IconButton onClick={handleTogglePicker}>
                        <CalendarIcon color="action" />
                    </IconButton>
                ),
            }}
            onBlur={() => {
                if (!isFilledOut && value) {
                    props.onChange?.(null, ref.current)
                }
            }}
            onValueChange={(values) => {
                const { formattedValue, value } = values
                const date = parseNorwegianDateFormat(formattedValue)
                const isFilledOut = value.length === 8
                const isCleared = value.length === 0
                setIsFilledOut(isFilledOut)
                if (isFilledOut) {
                    props.onChange?.(date, ref.current)
                }
                if (isCleared) {
                    props.onChange?.(null, ref.current)
                }
            }}
        />
    )
}

function CustomDatePicker(props: DatePickerProps<Date> & { id?: string }) {
    return (
        <DatePicker
            {...props}
            slots={{ ...props.slots, field: FixrateDateField }}
            slotProps={{ field: { id: props.id } }}
        />
    )
}

export default function FXDateInput({
    value,
    onChange,
    id,
    required,
    minDate,
    maxDate,
    shouldDisableDate,
    onlyBankDays,
    sx,
}: Props) {
    const { t } = useTranslation()
    const { isValidBankDay } = useBankCalendar()
    const [error, setError] = useState<DateValidationError | null>(null)
    const language = useSessionLanguage()
    const selectedAdapterLanguage = getSelectedLanguage(language)

    const getErrorMessage = (error: DateValidationError | null) => {
        if (error === 'minDate') {
            return t('components-DateInput.canNotBeBefore', { date: DateOutput.formatDate(minDate) })
        }
        if (error === 'maxDate') {
            return t('components-DateInput.canNotBeAfter', { date: DateOutput.formatDate(maxDate) })
        }

        if (error === 'shouldDisableDate') {
            return t('components-DateInput.notValidBankDay')
        }

        return t('components-DateInput.invalidDate')
    }
    const shouldDisableDateFn = (date: Date) => {
        if (onlyBankDays) {
            return !isValidBankDay(date)
        }
        if (shouldDisableDate) {
            return shouldDisableDate(date)
        }

        return false
    }
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={selectedAdapterLanguage}>
                <Stack>
                    <CustomDatePicker
                        value={value}
                        minDate={minDate}
                        maxDate={maxDate}
                        shouldDisableDate={shouldDisableDateFn}
                        format="dd.MM.yyyy"
                        id={id}
                        sx={{ width: '19rem', backgroundColor: 'white', ...sx }}
                        onError={(error) => {
                            setError(error)
                        }}
                        onChange={(date: Date | null) => {
                            onChange?.(isValidDate(date) ? date : null)
                        }}
                    />
                    {error && <p className="field-error-message">{getErrorMessage(error)}</p>}
                </Stack>
            </LocalizationProvider>
        </>
    )
}
