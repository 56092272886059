import analyzeChartLine from './icons/analyze-chart-line.svg?url'
import analyzeChartFill from './icons/analyze-chart-fill.svg?url'
import balloonsFill from './icons/ballons-fill.svg?url'
import balloonsLine from './icons/ballons-line.svg?url'
import barChartRisingFill from './icons/bar-chart-rising-fill.svg?url'
import barChartRisingLine from './icons/bar-chart-rising-line.svg?url'
import businessFill from './icons/business-fill.svg?url'
import businessLine from './icons/business-line.svg?url'
import calculatorFill from './icons/calculator-fill.svg?url'
import calculatorLine from './icons/calculator-line.svg?url'
import calculatorPurseFill from './icons/calculator-purse-fill.svg?url'
import calculatorPurseLine from './icons/calculator-purse-line.svg?url'
import certificateFill from './icons/certificate-fill.svg?url'
import certificateLine from './icons/certificate-line.svg?url'
import certifiedDocumentFill from './icons/certified-document-fill.svg?url'
import certifiedDocumentLine from './icons/certified-document-line.svg?url'
import chartLineFill from './icons/chart-line-fill.svg?url'
import chartLineLine from './icons/chart-line-line.svg?url'
import coinsFill from './icons/coins-fill.svg?url'
import coinsLine from './icons/coins-line.svg?url'
import documentAnalyzeFill from './icons/document-analyze-fill.svg?url'
import documentAnalyzeLine from './icons/document-analyze-line.svg?url'
import doughnutPercentageChartFill from './icons/doughnut-percentage-chart-fill.svg?url'
import doughnutPercentageChartLine from './icons/doughnut-percentage-chart-line.svg?url'
import encryptionKeyFill from './icons/encryption-key-fill.svg?url'
import encryptionKeyLine from './icons/encryption-key-line.svg?url'
import exchangeBankFill from './icons/exchange-bank-fill.svg?url'
import exchangeBankLine from './icons/exchange-bank-line.svg?url'
import handshakeFill from './icons/handshake-fill.svg?url'
import handshakeLine from './icons/handshake-line.svg?url'
import inboxFill from './icons/inbox-fill.svg?url'
import inboxLine from './icons/inbox-line.svg?url'
import inboxLetterFill from './icons/inbox-letter-fill.svg?url'
import inboxLetterLine from './icons/inbox-letter-line.svg?url'
import infoChartFill from './icons/info-chart-fill.svg?url'
import infoChartLine from './icons/info-chart-line.svg?url'
import interestBankFill from './icons/interest-bank-fill.svg?url'
import interestBankLine from './icons/interest-bank-line.svg?url'
import interestNotificationPhoneFill from './icons/interest-notification-phone-fill.svg?url'
import interestNotificationPhoneLine from './icons/interest-notification-phone-line.svg?url'
import lightbulbFill from './icons/lightbulb-fill.svg?url'
import lightbulbLine from './icons/lightbulb-line.svg?url'
import lineBarChartFill from './icons/line-bar-chart-fill.svg?url'
import lineBarChartLine from './icons/line-bar-chart-line.svg?url'
import lockFill from './icons/lock-fill.svg?url'
import lockLine from './icons/lock-line.svg?url'
import lockKeyFill from './icons/lock-key-fill.svg?url'
import lockKeyLine from './icons/lock-key-line.svg?url'
import manComputerFill from './icons/man-computer-fill.svg?url'
import manComputerLine from './icons/man-computer-line.svg?url'
import moneyBillsFill from './icons/money-bills-fill.svg?url'
import moneyBillsLine from './icons/money-bills-line.svg?url'
import moneyCalculatorFill from './icons/money-calculator-fill.svg?url'
import moneyCalculatorLine from './icons/money-calculator-line.svg?url'
import moneyCountFill from './icons/money-count-fill.svg?url'
import moneyCountLine from './icons/money-count-line.svg?url'
import moneySecureFill from './icons/money-secure-fill.svg?url'
import moneySecureLine from './icons/money-secure-line.svg?url'
import notesFill from './icons/notes-fill.svg?url'
import notesLine from './icons/notes-line.svg?url'
import paperPlaneFill from './icons/paperplane-fill.svg?url'
import paperPlaneLine from './icons/paperplane-line.svg?url'
import roleGroupFill from './icons/role-group-fill.svg?url'
import roleGroupLine from './icons/role-group-line.svg?url'
import safeFill from './icons/safe-fill.svg?url'
import safeLine from './icons/safe-line.svg?url'
import safeLockFill from './icons/safe-lock-fill.svg?url'
import safeLockLine from './icons/safe-lock-line.svg?url'
import secureHandshakeFill from './icons/secure-handshake-fill.svg?url'
import secureHandshakeLine from './icons/secure-handshake-line.svg?url'
import settingsFill from './icons/settings-fill.svg?url'
import settingsLine from './icons/settings-line.svg?url'
import sparegrisFill from './icons/sparegris-fill.svg?url'
import sparegrisLine from './icons/sparegris-line.svg?url'
import suitcaseSecureFill from './icons/suitcase-secure-fill.svg?url'
import suitcaseSecureLine from './icons/suitcase-secure-line.svg?url'
import supportFill from './icons/support-fill.svg?url'
import supportLine from './icons/support-line.svg?url'
import supportNotificationFill from './icons/support-notification-fill.svg?url'
import supportNotificationLine from './icons/support-notification-line.svg?url'
import targetFill from './icons/target-fill.svg?url'
import targetLine from './icons/target-line.svg?url'
import timeMoneyFill from './icons/time-money-fill.svg?url'
import timeMoneyLine from './icons/time-money-line.svg?url'
import userAddFill from './icons/user-add-fill.svg?url'
import userAddLine from './icons/user-add-line.svg?url'
import userVerifiedDocumentFill from './icons/user-verified-document-fill.svg?url'
import userVerifiedDocumentLine from './icons/user-verified-document-line.svg?url'
import userVerifiedFill from './icons/user-verified-fill.svg?url'
import userVerifiedLine from './icons/user-verified-line.svg?url'
import userZoomFill from './icons/user-zoom-fill.svg?url'
import userZoomLine from './icons/user-zoom-line.svg?url'
import bankFill from './icons/bank-fill.svg?url'
import bankLine from './icons/bank-line.svg?url'
import secureDocumentFill from './icons/secure-document-fill.svg?url'
import secureDocumentLine from './icons/secure-document-line.svg?url'
import protectedLockLine from './icons/protected-lock-line.svg?url'
import protectedLockFill from './icons/protected-lock-fill.svg?url'
import sendInviteFill from './icons/send-invite-fill.svg?url'
import { Box, SxProps } from '@mui/material'

type IconProps = {
    name: keyof typeof icons
    sx?: SxProps
    backgroundImage?: boolean
}

const icons = {
    analyzeChartLine: analyzeChartLine,
    analyzeChartFill: analyzeChartFill,
    balloonsFill: balloonsFill,
    balloonsLine: balloonsLine,
    barChartRisingFill: barChartRisingFill,
    barChartRisingLine: barChartRisingLine,
    businessFill: businessFill,
    businessLine: businessLine,
    calculatorFill: calculatorFill,
    calculatorLine: calculatorLine,
    calculatorPurseFill: calculatorPurseFill,
    calculatorPurseLine: calculatorPurseLine,
    certificateFill: certificateFill,
    certificateLine: certificateLine,
    certifiedDocumentFill: certifiedDocumentFill,
    certifiedDocumentLine: certifiedDocumentLine,
    chartLineFill: chartLineFill,
    chartLineLine: chartLineLine,
    coinsFill: coinsFill,
    coinsLine: coinsLine,
    documentAnalyzeFill: documentAnalyzeFill,
    documentAnalyzeLine: documentAnalyzeLine,
    doughnutPercentageChartFill: doughnutPercentageChartFill,
    doughnutPercentageChartLine: doughnutPercentageChartLine,
    encryptionKeyFill: encryptionKeyFill,
    encryptionKeyLine: encryptionKeyLine,
    exchangeBankFill: exchangeBankFill,
    exchangeBankLine: exchangeBankLine,
    handshakeFill: handshakeFill,
    handshakeLine: handshakeLine,
    inboxFill: inboxFill,
    inboxLine: inboxLine,
    inboxLetterFill: inboxLetterFill,
    inboxLetterLine: inboxLetterLine,
    infoChartFill: infoChartFill,
    infoChartLine: infoChartLine,
    interestBankFill: interestBankFill,
    interestBankLine: interestBankLine,
    interestNotificationPhoneFill: interestNotificationPhoneFill,
    interestNotificationPhoneLine: interestNotificationPhoneLine,
    lightbulbFill: lightbulbFill,
    lightbulbLine: lightbulbLine,
    lineBarChartFill: lineBarChartFill,
    lineBarChartLine: lineBarChartLine,
    lockFill: lockFill,
    lockLine: lockLine,
    lockKeyFill: lockKeyFill,
    lockKeyLine: lockKeyLine,
    manComputerFill: manComputerFill,
    manComputerLine: manComputerLine,
    moneyBillsFill: moneyBillsFill,
    moneyBillsLine: moneyBillsLine,
    moneyCalculatorFill: moneyCalculatorFill,
    moneyCalculatorLine: moneyCalculatorLine,
    moneyCountFill: moneyCountFill,
    moneyCountLine: moneyCountLine,
    moneySecureFill: moneySecureFill,
    moneySecureLine: moneySecureLine,
    notesFill: notesFill,
    notesLine: notesLine,
    paperPlaneFill: paperPlaneFill,
    paperPlaneLine: paperPlaneLine,
    roleGroupFill: roleGroupFill,
    roleGroupLine: roleGroupLine,
    safeFill: safeFill,
    safeLine: safeLine,
    safeLockFill: safeLockFill,
    safeLockLine: safeLockLine,
    secureHandshakeFill: secureHandshakeFill,
    secureHandshakeLine: secureHandshakeLine,
    settingsFill: settingsFill,
    settingsLine: settingsLine,
    sparegrisFill: sparegrisFill,
    sparegrisLine: sparegrisLine,
    suitcaseSecureFill: suitcaseSecureFill,
    suitcaseSecureLine: suitcaseSecureLine,
    supportFill: supportFill,
    supportLine: supportLine,
    supportNotificationFill: supportNotificationFill,
    supportNotificationLine: supportNotificationLine,
    targetFill: targetFill,
    targetLine: targetLine,
    timeMoneyFill: timeMoneyFill,
    timeMoneyLine: timeMoneyLine,
    userAddFill: userAddFill,
    userAddLine: userAddLine,
    userVerifiedDocumentFill: userVerifiedDocumentFill,
    userVerifiedDocumentLine: userVerifiedDocumentLine,
    userVerifiedFill: userVerifiedFill,
    userVerifiedLine: userVerifiedLine,
    userZoomFill: userZoomFill,
    userZoomLine: userZoomLine,
    bankFill: bankFill,
    bankLine: bankLine,
    secureDocumentFill: secureDocumentFill,
    secureDocumentLine: secureDocumentLine,
    protectedLockLine: protectedLockLine,
    protectedLockFill: protectedLockFill,
    sendInviteFill: sendInviteFill,
}

export type IconName = keyof typeof icons

export default function FixrateIcon({ name, sx, backgroundImage }: IconProps): JSX.Element | null {
    const icon = icons[name]

    if (icon) {
        if (backgroundImage) {
            return (
                <Box
                    width="10rem"
                    height="10rem"
                    sx={{
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        ...sx,
                        backgroundImage: `url(${icon})`,
                    }}
                ></Box>
            )
        } else {
            return (
                <Box sx={{ ...sx }}>
                    <img style={{ maxWidth: '100%' }} src={icon} alt="" />
                </Box>
            )
        }
    }

    return null
}
