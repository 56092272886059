import styles from './LabeledInput.module.css'
import classNames from 'classnames'
import HelpPopup from '../HelpPopup/HelpPopup'
import React from 'react'

type Props = {
    label: string
    children: React.ReactNode
    infoText?: string
    helpText?: string
    className?: string
    errorMessage?: string | boolean
}

export default function LabeledInput({ className, helpText, label, infoText, children, errorMessage }: Props) {
    return (
        <dl className={classNames(styles.body, className)}>
            <dt className={styles.label}>
                {helpText && <HelpPopup text={helpText}>{label}</HelpPopup>}
                {!helpText && label}
                {infoText && <span>{infoText}</span>}
            </dt>
            <dd className={styles.value}>
                {children}
                {errorMessage && <p className="field-error-message">{errorMessage}</p>}
            </dd>
        </dl>
    )
}
