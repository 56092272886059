import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { PageHeader, PageLayout } from '#components'
import { useSelector } from '#state/useSelector'
import { Alert, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes } from 'react-router-dom'
import Accounts from './Accounts/Accounts'
import CustomerDeclarationInfo from './CustomerDeclarationInfo/CustomerDeclarationInfo'
import IntegrationOverview from './Integrations/IntegrationOverview'
import styles from './OrganisationDetail.module.scss'
import PartnerRequest from './PartnerRequest'
import SubMenu from './SubMenu/SubMenu'
import AcceptTerms from './TermsAndConditions/AcceptTerms'
import UsersAndRoles from './UsersAndRoles/UsersAndRoles'
import { Mandate } from './Mandate/Mandate'

export default function OrganisationDetail() {
    const { t } = useTranslation()
    const depositor = useCurrentDepositor()
    const session = useSelector((state) => state.session)

    const canAcceptPartner = session.associations
        .find((a) => a.organisation?.id === depositor?.id)
        ?.permissions.includes('DEPOSITOR__PARTNER__ACCEPT')
    const partnerRequestsPending = depositor?.partnerRelations?.filter((pr) => !pr.accepted)

    if (!depositor) {
        return null
    }

    if (canAcceptPartner && partnerRequestsPending.length > 0) {
        return (
            <>
                <PageHeader icon={'ri-building-line'} title={t('menu.settings')} />
                <PageLayout>
                    {partnerRequestsPending.map((request) => (
                        <PartnerRequest key={request.partnerId} openPartnerRequest={request} />
                    ))}
                </PageLayout>
            </>
        )
    }

    return (
        <>
            <PageHeader icon={'ri-building-line'} title={t('menu.settings')} />
            <PageLayout className={styles.pagelayout}>
                <>
                    {partnerRequestsPending.map((pr) => (
                        <Alert severity="info" key={pr.partnerId}>
                            {t('pages-organizations.partnerRequest', { partnerName: pr.name })}
                        </Alert>
                    ))}
                    <SubMenu depositor={depositor} />
                    <Box sx={{ px: { xs: 2, md: '4.8rem' }, pt: { xs: '3rem', md: '5rem' } }}>
                        <Routes>
                            <Route path={''} element={<Navigate to={'users'} replace />} />
                            <Route path={'users/*'} element={<UsersAndRoles />} />
                            <Route path={'customerdecl'} element={<CustomerDeclarationInfo />} />
                            <Route path={'accounts/*'} element={<Accounts />} />

                            <Route path={'terms'} element={<Navigate to={'deposit'} replace />} />
                            <Route path={'terms/:activeTab'} element={<AcceptTerms />} />
                            <Route path={'integrations/*'} element={<IntegrationOverview />} />
                            <Route path={'placement-profile/*'} element={<Mandate />} />
                        </Routes>
                    </Box>
                </>
            </PageLayout>
        </>
    )
}
