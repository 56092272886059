import FxPaper from '#app/components/Paper/FxPaper'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Nibor, NiborAll } from '@fixrate/fixrate-analytics-widgets'
import { useTranslation } from 'react-i18next'
import { AnalyticsPaperSx } from './Analytics'

export default function AnalyticsNibor() {
    const { t } = useTranslation()
    const isFixrateRole = useSelector(selectors.isFixrateRole)
    const isDepositorWithAnalytics = true
    const isBankWithAnalytics = useSelector(selectors.isBankWithAnalytics)

    return (
        <>
            {(isFixrateRole || isBankWithAnalytics || isDepositorWithAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.niborAndFolio')}>
                    <Nibor startAtZero={false} />
                </FxPaper>
            )}
            {(isFixrateRole || isBankWithAnalytics || isDepositorWithAnalytics) && (
                <FxPaper sx={AnalyticsPaperSx} title={t('pages-analytics.niborAll')}>
                    <NiborAll startAtZero={false} />
                </FxPaper>
            )}
        </>
    )
}
