import { useLocalStorage } from '#app/services/LocalStorageContext'
import { DepositorDto, FundCategory, FundDto, FundShareClassDto } from '@fixrate/fixrate-query'
import { Animated, InterestOutput } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import { FundLogo } from '#pages/FundDetails/FundLogo/FundLogo'
import FundTableHeaderCell from '#pages/FundMarketplace/FundTable/FundTableHeaderCell'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useShoppingCart from '#services/useShoppingCart'
import useTableSort, { Column } from '#services/useTableSort'
import { Button } from '@mui/material'
import classNames from 'classnames'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './FundTable.module.scss'
import ShareClassSelect from './TableComponents/ShareClassSelect'
import TableShoppingCartButton from './TableComponents/TableShoppingCartButton'
import { useFundPlatformFee } from '#services/platformFeeCalculator'
import useFundShareClassSelected from '#services/useFundShareClassSelected'

type Props = {
    funds: FundDto[]
    selectedShareClasses: { [fundId: string]: string }
}

type Row = {
    name: string
    risk: number
    esgCategory: string
    totalCosts: number
    logo: string
    returnRate1Y: number
    shareClass: number
    fund: FundDto
}

type RowProps = {
    row: Row
    depositor: DepositorDto
    calculateTotalCosts: (fundCategory: FundCategory, fundShareClass: FundShareClassDto) => { from: number; to: number }
}

export default function OverviewTable({ funds, selectedShareClasses }: Props) {
    const { t } = useTranslation()
    const currentDepositor = useCurrentDepositor()
    const { calculateTotalCosts } = useFundPlatformFee()

    const rows: Row[] = funds?.map((fund) => {
        const selectedFundShareClass =
            fund.fundShareClasses.find((fundClass) => fundClass.id === selectedShareClasses[fund.id]) ||
            fund.fundShareClasses[0]
        return {
            name: fund.name,
            esgCategory: fund.esgCategory,
            totalCosts: calculateTotalCosts(fund.fundCategory, selectedFundShareClass).from,
            logo: '',
            risk: fund.risk,
            shareClass: selectedFundShareClass?.minimumInvestment,
            tradableByDepositorIds: selectedFundShareClass?.tradableByDepositorIds,
            returnRate1Y: selectedFundShareClass?.returnRates.PERIOD_1Y,
            returnRate3Y: selectedFundShareClass?.annualizedReturnRates.PERIOD_3Y,
            fund, // Adds fund object, even if this is not a row in the table. It is used by each row to calculate some of the fields.
        }
    })

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<Row>(
        rows,
        'risk',
        'asc',
        'returnRate1Y',
        'desc'
    )

    const columns: Column<Row>[] = [
        { id: 'logo', label: '', sortable: false },
        { id: 'name', label: t('pages-fund-details.name'), sortable: true },
        {
            id: 'shareClass',
            label: t('pages-fund-details.shareClassAndAmount'),
            sortable: true,
        },
        { id: 'risk', label: t('pages-fund-details.risk'), sortable: true },
        {
            id: 'returnRate1Y',
            label: t('pages-fund-details.1y'),
            sortable: true,
        },
        {
            id: 'esgCategory',
            label: t('pages-fund-details.sustainability'),
            align: 'right',
            sortable: true,
        },
        {
            id: 'totalCosts',
            label: t('pages-fund-details.totalCosts'),
            align: 'right',
            tooltip: t('pages-fund-details.totalCostsExplanation'),
            sortable: true,
        },
    ]

    return funds ? (
        <Fragment>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <FundTableHeaderCell<Row>
                                key={column.id}
                                column={column}
                                sortKey={sortKey}
                                tooltip={column.tooltip}
                                longTooltip={column.longTooltip}
                                sortDirection={sortDirection}
                                onClick={(c) => setSorting(c.id)}
                            />
                        ))}
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {sortedRows.map((row) => (
                        <TableRow
                            key={row.fund.id}
                            row={row}
                            depositor={currentDepositor}
                            calculateTotalCosts={calculateTotalCosts}
                        />
                    ))}
                </tbody>
            </table>
        </Fragment>
    ) : null
}

function TableRow({ row, depositor, calculateTotalCosts }: RowProps) {
    const { t } = useTranslation()
    const { dispatchLocalStorage } = useLocalStorage()

    const fund = row.fund
    const [shareClassId] = useFundShareClassSelected(fund.id, fund.fundShareClasses[0].id)
    const selectedShareClass = fund.fundShareClasses.find((fundShareClass) => fundShareClass.id === shareClassId)

    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(row.fund.id)

    const setFundInfoSlideOutId = (id: string) => {
        dispatchLocalStorage({ type: 'SHARE_CLASS_FOR_SLIDEOUT', payload: id })
    }

    const totalCosts = calculateTotalCosts(fund.fundCategory, selectedShareClass)

    return (
        <Animated tag={'tr'} className={isInShoppingCart ? styles.active : null}>
            <td
                style={{ cursor: 'pointer' }}
                onClick={() => setFundInfoSlideOutId(selectedShareClass?.id)}
                className={classNames(styles.logo, isInShoppingCart ? styles.highlight : null)}
            >
                <FundLogo fundName={selectedShareClass?.fullName} squared={true} />
            </td>
            <td className={classNames(styles.name)}>
                <Button
                    sx={{
                        textAlign: 'left',
                        whiteSpace: 'normal',
                        fontSize: '1.4rem',
                        fontWeight: '700',
                        textDecoration: 'none',
                        '&:hover': { textDecoration: 'none' },
                    }}
                    color="secondary"
                    onClick={() => setFundInfoSlideOutId(selectedShareClass?.id)}
                >
                    {row.name}
                </Button>
            </td>
            <td>
                {row && (
                    <ShareClassSelect
                        fund={fund}
                        depositor={depositor}
                        tooltip={selectedShareClass?.specialPrice && t('pages-fund-details.specialPriceTooltip')}
                    />
                )}
            </td>
            <td>
                <div className={classNames(styles.risk)}>
                    <div className={styles.indicator}>
                        {[...new Array(7)].map((_, i) => (
                            <span key={i} className={i < row.risk ? styles.active : undefined} />
                        ))}
                    </div>
                </div>
            </td>
            <td>
                <InterestBadge sx={{ fontSize: 12 }} interest={selectedShareClass?.returnRates.PERIOD_1Y} />
            </td>
            <td align={'right'}>
                <div
                    className={classNames(
                        styles.esgValue,
                        {
                            ARTICLE_6: styles.article6,
                            ARTICLE_8: styles.article8,
                            ARTICLE_9: styles.article9,
                        }[row.esgCategory]
                    )}
                >
                    <span>{{ ARTICLE_6: '6', ARTICLE_8: '8', ARTICLE_9: '9' }[row.esgCategory]}</span>
                </div>
            </td>
            <td align={'right'}>
                <p className={styles.value}>{InterestOutput.formatRange(totalCosts?.from, totalCosts?.to, 2, true)}</p>
            </td>
            <TableShoppingCartButton fund={fund} />
        </Animated>
    )
}
