import { Language } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'
import { useSessionLanguage } from '#services/useSessionLanguage'

type LocalizedString = { [P in Language]?: string }

export function useLocalizedString(localizedString: LocalizedString): string | null {
    const sessionLanguage = useSessionLanguage()
    if (!sessionLanguage) {
        return null
    }
    if (!localizedString) {
        return null
    }
    return localizedString[sessionLanguage.toUpperCase()] || null
}
