import config from '#app/config'
import { useCommand } from '#command'
import { ButtonRow, DateOutput, InterestOutput } from '#components'
import Button from '#components/Button'
import DatePicker from '#components/DatePicker/DatePicker'
import HelpPopup from '#components/HelpPopup'
import { useBankCalendar } from '#services/useBankCalendar'
import { useFieldState } from '@fixrate/fieldstate'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { DocumentDto, OrderDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import addMonths from 'date-fns/addMonths'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './OrderReadyForTransaction.module.scss'
import { NumericFormat } from 'react-number-format'

const API_BASE_URL = config().apiUrl

const SUBMIT_TIMEOUT = 10_000

export type Props = {
    order: OrderDto
    documents: DocumentDto[]
}

export default function OrderReadyForTransaction({ order, documents }: Props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { completeOrder } = useCommand()
    const dispatch = useDispatch()

    const [submitting, setSubmitting] = useState(false)
    const [probablySuccess, setProbablySuccess] = useState(false)

    const loaded = useSelector((state) => state.loaded)
    const product = useSelector((state) => state.products[order.ad.productId])
    const { nextBankDay } = useBankCalendar()

    const [controlStepVisible, setControlStepVisible] = useState(false)
    const [confirmedInformation, setConfirmedInformation] = useState(false)
    const [confirmedInformationError, setConfirmedInformationError] = useState('')

    const isRecurring = product?.termsType === 'RECURRING_FIXED_TERMS'

    const volumeValidator = useCallback(
        ({ value, isEditing }) => {
            if (!isEditing && !value) {
                return t('pages-orders.bankAmountMissing')
            }

            if (!isEditing && Number(value) < 1000000) {
                return t('pages-orders.bankAmountToSmall')
            }
        },
        [t]
    )
    const volume = useFieldState((order.volume * 1000000).toFixed(2).replace('.', ','), volumeValidator)

    const startDateValidator = useCallback(
        ({ value, isEditing }) => {
            if (!isEditing && !value) {
                return t('pages-orders.bankDateMissing')
            }

            if (isBefore(endOfDay(value), new Date(order.created))) {
                return t('pages-orders.bankDateMustBeAfterOrderCreated')
            }
        },
        [order.created, t]
    )
    const startDate = useFieldState(null, startDateValidator)

    const nextFixedInterestPeriodStartDateValidator = useCallback(
        ({ value, isEditing }) => {
            if (!isEditing && !value) {
                return t('pages-orders.bankDateMissing')
            }

            if (isBefore(endOfDay(value), new Date(order.created))) {
                return t('pages-orders.bankDateMustBeAfterOrderCreated')
            }
        },
        [order.created, t]
    )
    const nextFixedInterestPeriodStartDate = useFieldState(
        nextBankDay(addMonths(new Date(), product.months)),
        nextFixedInterestPeriodStartDateValidator
    )

    const numberOutput = (number: number | string) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    const interestRateBenchmark = order.ad.interestRateBenchmark
    const nominalInterestRate = order.ad.nominalInterestRate
    const interest = order.ad.interest
    const interestRateConvention = order.ad.interestRateConvention

    const ControlStep = () => {
        return (
            <div>
                <p>{t('pages-orders.pleaseControlInformation')}</p>
                <dl className={styles.verificationList}>
                    <dt className="actionframe__labeledinput-label">
                        <HelpPopup text={t('pages-orders.bankInterestDateHelpText')}>
                            {t('pages-orders.bankInterestDate')}
                        </HelpPopup>
                    </dt>
                    <dd>{DateOutput.formatDate(startDate.value)}</dd>
                    <dt>{t('pages-orders.bankAmount')}</dt>
                    <dd>{numberOutput(volume.value)}</dd>
                    <dd />
                    {product.type !== 'FIXED' && (
                        <>
                            <dt>{t('pages-orders.marginAddition')}</dt>
                            <dd>
                                {t('common.interestRateBenchmark.' + interestRateBenchmark)} +{' '}
                                {InterestOutput.format(nominalInterestRate)}
                            </dd>
                        </>
                    )}
                    {product.type === 'FIXED' && (
                        <>
                            <dt>{t('pages-orders.interestRate')}</dt>
                            <dd>{InterestOutput.format(interest)}</dd>
                        </>
                    )}
                    <dt>{t('pages-orders.interestRateConvention')}</dt>
                    <dd>{t('enum-InterestRateConvention.' + interestRateConvention)}</dd>
                    <dt>{t('pages-orders.capitalizationFrequency')}</dt>
                    <dd>{t('pages-orders.yearly')}</dd>
                </dl>
                <div className={styles.confirmationWrapper}>
                    <label className={styles.confirmationCheck}>
                        <input
                            name="confirmInformation"
                            data-cy="confirmInformationCheck"
                            type="checkbox"
                            checked={confirmedInformation}
                            onChange={(_) => setConfirmedInformation(!confirmedInformation)}
                        />
                        {t('pages-orders.confirmCorrectInformation')}
                    </label>
                    {!confirmedInformation && <p className="field-error-message">{confirmedInformationError}</p>}
                </div>
                <ButtonRow align={'left'}>
                    <Button className={styles.goBack} variant={'tertiary'} onClick={() => setControlStepVisible(false)}>
                        {t('pages-orders.goBack')}
                    </Button>
                    <Button data-cy="completeOrder" onClick={onSubmit} disabled={submitting} loading={submitting}>
                        {t('common.confirm')}
                    </Button>
                </ButtonRow>
            </div>
        )
    }

    const validForm = () => {
        const volumeIsValid = volume.validate()
        const startDateIsValid = startDate.validate()
        const nextFixedInterestPeriodStartDateIsValid = isRecurring ? nextFixedInterestPeriodStartDate.validate() : true

        return volumeIsValid && startDateIsValid && nextFixedInterestPeriodStartDateIsValid
    }

    const goToControl = () => {
        if (validForm()) {
            setControlStepVisible(true)
        }
    }

    async function onSubmit() {
        if (!confirmedInformation) {
            setConfirmedInformationError(t('pages-orders.confirmCorrectInformationError'))
            return
        }
        setSubmitting(true)
        if (validForm()) {
            const parsedVolume = parseFloat(volume.value.replace(',', '.'))
            const formattedInterestDate = format(startDate.value, 'yyyy-MM-dd')
            const formattedNextPeriodStartDate = isRecurring
                ? format(nextFixedInterestPeriodStartDate.value, 'yyyy-MM-dd')
                : null
            const comment = t('pages-orders.startBalance') + ': ' + numberOutput(volume.value)

            const timeout = setTimeout(() => setProbablySuccess(true), SUBMIT_TIMEOUT - 1_000)
            const { waitForCommand } = await completeOrder(
                order.id,
                parsedVolume,
                formattedInterestDate,
                formattedNextPeriodStartDate,
                comment
            )
            const success = await waitForCommand(SUBMIT_TIMEOUT)
            clearTimeout(timeout)

            if (success || probablySuccess) {
                dispatch(
                    showConfirmationModal({
                        title: t('pages-orders.bankOrderCompletedTitle'),
                        text: success
                            ? t('pages-orders.bankOrderCompletedText')
                            : t('pages-orders.bankOrderCompletedButNotConfirmed'),
                        buttonText: t('common.continue'),
                    })
                )
                navigate('/bank-orders')
            }
        } else {
            setControlStepVisible(true)
        }
        setSubmitting(false)
    }

    const accountAgreement = documents.find((doc) => doc.documentType === 'ACCOUNT_AGREEMENT')

    if (!loaded.documents) return null

    return (
        <div>
            <div className={styles.stepMenu}>
                <div
                    onClick={() => setControlStepVisible(false)}
                    className={classNames(styles.step, !controlStepVisible ? styles.active : '')}
                >
                    <span className={styles.count}>1</span>
                    <span className={styles.description}>{t('pages-orders.confirmDeposit')}</span>
                </div>
                <div
                    onClick={() => setControlStepVisible(validForm())}
                    className={classNames(styles.step, controlStepVisible ? styles.active : '')}
                >
                    <span className={styles.count}>2</span>
                    <span className={styles.description}>{t('pages-orders.verifyInformation')}</span>
                </div>
            </div>
            {!controlStepVisible && (
                <div>
                    <dl className="actionframe__labeledinput">
                        <dt className={styles.contextDescription}>
                            {order.orderState === 'READY_FOR_TRANSACTION' && (
                                <HelpPopup text={t('pages-orders.bankConfirmDepositHelpText')}>
                                    {isRecurring
                                        ? t('pages-orders.bankConfirmDepositRecurring')
                                        : t('pages-orders.bankConfirmDeposit')}
                                </HelpPopup>
                            )}
                            {order.orderState === 'READY_FOR_TRANSACTION_CONFIRMATION' &&
                                t('pages-orders.bankCustomerHasConfirmedDeposit')}
                        </dt>
                        <dd>
                            <a
                                href={`${API_BASE_URL}${accountAgreement.signedLink}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {accountAgreement.name}
                            </a>
                        </dd>
                    </dl>

                    <dl className="actionframe__labeledinput">
                        <dt className="actionframe__labeledinput-label">
                            <HelpPopup text={t('pages-orders.bankInterestDateHelpText')}>
                                {t('pages-orders.bankInterestDate')}
                            </HelpPopup>
                        </dt>
                        <dd>
                            <DatePicker
                                id="interestDateDatePicker"
                                selected={startDate.value}
                                onChange={(date) => startDate.setValue(date)}
                                onBlur={startDate.onBlur}
                            />
                            <p className="field-error-message">{startDate.errorMessage}</p>
                        </dd>
                    </dl>

                    {isRecurring && (
                        <dl className="actionframe__labeledinput">
                            <dt className="actionframe__labeledinput-label">
                                <HelpPopup text={t('pages-orders.bankNextPeriodHelpText')}>
                                    {t('pages-orders.bankNextPeriod')}
                                </HelpPopup>
                            </dt>
                            <dd>
                                <DatePicker
                                    id="nextFixedInterestPeriodStartDateDatePicker"
                                    selected={nextFixedInterestPeriodStartDate.value}
                                    onChange={(date) => nextFixedInterestPeriodStartDate.setValue(date)}
                                    onBlur={nextFixedInterestPeriodStartDate.onBlur}
                                />
                                <p className="field-error-message">{nextFixedInterestPeriodStartDate.errorMessage}</p>
                            </dd>
                        </dl>
                    )}

                    <dl className="actionframe__labeledinput">
                        <dt className="actionframe__labeledinput-label">
                            <HelpPopup text={t('pages-orders.bankConfirmAmountHelpText')}>
                                {t('pages-orders.bankConfirmAmount')}
                            </HelpPopup>
                        </dt>
                        <dd>
                            <NumericFormat
                                value={volume.value}
                                onValueChange={(values) => {
                                    const { value } = values
                                    volume.setValue(value)
                                }}
                                onBlur={volume.onBlur}
                                allowNegative={false}
                                decimalScale={0}
                                decimalSeparator={','}
                                thousandSeparator={' '}
                                style={{ textAlign: 'right' }}
                            />
                            &nbsp;{order?.currency}
                            <p className="field-error-message">{volume.errorMessage}</p>
                        </dd>
                    </dl>

                    <Button data-cy="verifyInformation" onClick={goToControl}>
                        {t('pages-orders.verifyInformation')}
                    </Button>
                </div>
            )}
            {controlStepVisible && <ControlStep />}
        </div>
    )
}
