import { BURNT_ORANGE, SUCCESS_GREEN } from '#app/colors/colors'
import { HelpIcon, LoadingSpinner } from '#app/components'
import { useBottomNavigation } from '#app/services/useBottomNavigation'
import { useSelector } from '#app/state/useSelector'
import { OnboardingWizardStep, StepName, useOnboardingWizardContent } from '#app/utilities/useOnboardingWizardStatus'
import {
    Alert,
    Avatar,
    Box,
    Chip,
    Divider,
    Paper,
    Slide,
    Snackbar,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const WizardContent = ({
    step,
    visibleError,
    hideVisibleError,
    loaded,
}: {
    step: OnboardingWizardStep & { content: JSX.Element }
    visibleError: boolean
    hideVisibleError: () => void
    loaded: boolean
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
    useBottomNavigation()

    const iconSize = isMobile ? '3rem' : '5rem'
    const containerRef = useRef<HTMLDivElement>(null)
    return (
        <Paper
            sx={{
                width: '100%',
                position: 'relative',
            }}
        >
            {loaded ? (
                <Stack
                    ref={containerRef}
                    justifyContent={'space-between'}
                    minHeight={{ lg: '50rem' }}
                    height={{ xs: '100%', lg: 'auto' }}
                >
                    <Box>
                        <Stack
                            direction={{ xs: 'row' }}
                            justifyContent="space-between"
                            alignItems={{ xs: 'center' }}
                            px={{ xs: 2, lg: 4 }}
                            py={{ xs: 1.6, lg: 2.2 }}
                        >
                            <Stack direction="row" spacing={{ xs: 1, lg: 2 }} alignItems="center">
                                <Box
                                    width={iconSize}
                                    height={iconSize}
                                    sx={{
                                        backgroundSize: `${iconSize} ${iconSize}`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundImage: `url("${step.icon}")`,
                                    }}
                                ></Box>
                                <Typography m={0} fontSize={{ xs: '1.6rem', sm: '1.8rem', lg: null }} variant="h2">
                                    {t('pages-OrganizationWizard.' + step.name)}
                                </Typography>
                                {step.supportarticleId && (
                                    <HelpIcon sx={{ ml: '-1rem' }} supportCommonName={step.supportarticleId} />
                                )}
                            </Stack>
                            {isMobile ? (
                                <Avatar
                                    sx={{
                                        backgroundColor: step.done ? SUCCESS_GREEN[50] : BURNT_ORANGE[50],
                                        color: step.done ? SUCCESS_GREEN[900] : BURNT_ORANGE[900],
                                    }}
                                >
                                    <i className={`ri-${step.done ? 'checkbox-circle' : 'error-warning'}-fill`} />
                                </Avatar>
                            ) : (
                                <Chip
                                    label={
                                        step.done
                                            ? t('pages-OrganizationWizard.allDone')
                                            : t('pages-OrganizationWizard.notDone')
                                    }
                                    color={step.done ? 'success' : 'error'}
                                    data-cy="stepStatusChip"
                                    data-cy-step-done={step.done}
                                    icon={
                                        <i className={`ri-${step.done ? 'checkbox-circle' : 'error-warning'}-fill`} />
                                    }
                                />
                            )}
                        </Stack>
                        <Divider />
                        <Box px={{ xs: 2, lg: 4 }} py={3}>
                            {step.content}
                        </Box>
                    </Box>
                    {isMobile ? (
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={visibleError}
                            onClose={hideVisibleError}
                            autoHideDuration={5000}
                            sx={{ bottom: '8rem' }}
                            color="error"
                            message={t('pages-OrganizationWizard.stepNotFinishedMessage')}
                        />
                    ) : (
                        <Box overflow={'hidden'} height="auto">
                            <Slide direction="up" in={visibleError} container={containerRef.current}>
                                <Alert severity={'error'} sx={{ boxShadow: 'none' }}>
                                    {t('pages-OrganizationWizard.stepNotFinishedMessage')}
                                </Alert>
                            </Slide>
                        </Box>
                    )}
                </Stack>
            ) : (
                <Stack alignItems="center" justifyContent="center" height="50rem">
                    <LoadingSpinner />
                </Stack>
            )}
        </Paper>
    )
}

export default WizardContent
