import { BURNT_ORANGE, SILVER_GRAY } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { getIconText } from '#app/components/PageHeader/PageHeader'
import { useSelector } from '#app/state/useSelector'
import useTableSort, { Column } from '#services/useTableSort'
import useUiSetting from '#services/useUiSetting'
import { Currency, PartnerCustomerDto, PartnerDto, PartnerUserDto } from '@fixrate/fixrate-query'
import {
    Avatar,
    Box,
    Chip,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CustomerStatusField } from './components/CustomerStatusField'

type Row = PartnerCustomerDto & {
    primaryAdvisorName: string | null
    volume: number
    interestRate: number | null
}

type Props = {
    partner: PartnerDto
    customers: PartnerCustomerDto[]
    currency: Currency
}

type CustomerTableColumn<Row> = Column<Row> & {
    visible: boolean
}

export default function CustomerList({ partner, customers, currency }: Props) {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useUiSetting('FundCustomerList-rowsPerPage', 50)
    const association = useSelector((state) => state.session.association)
    const canSeeOtherPartnerUsers = association?.roles.includes('PARTNER_ADMIN')

    const rows: Row[] =
        customers.map((customer) => {
            const primaryAdvisor: PartnerUserDto | undefined = partner.users.find(
                (user) => user.id === customer.primaryAdvisor
            )
            const portfolio = customer.portfolios.find((portfolio) => portfolio.currency === currency)
            let status = t('common.ok')
            if (!customer.primaryAdvisor) {
                status = t('pages-customers.lacksAdvisor')
            }
            if (customer.accepted === null) {
                status = t('pages-customers.notAccepted')
            }
            if (customer.accepted === false) {
                status = t('pages-customers.rejected')
            }
            return {
                ...customer,
                primaryAdvisorName: primaryAdvisor ? `${primaryAdvisor.firstName} ${primaryAdvisor.lastName}` : '',
                statusText: status,
                unresolvedTasks: customer.unresolvedTasks,
                volume: portfolio?.calculatedTotalBalance || portfolio?.calculatedBalance || 0,
                interestRate: portfolio?.calculatedTotalInterestRate || portfolio?.calculatedInterestRate || null,
            }
        }) ?? []

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<Row>(rows, 'name', 'asc', 'name', 'asc')

    const columns: readonly CustomerTableColumn<Row>[] = [
        {
            id: 'name',
            label: t('pages-customers.customer'),
            visible: true,
        },
        {
            id: 'primaryAdvisorName',
            label:
                partner?.partnerCategory === 'ACCOUNTANT'
                    ? t('pages-customers.accountant')
                    : t('pages-customers.advisor'),
            visible: canSeeOtherPartnerUsers,
        },
        {
            id: 'unresolvedTasks',
            label: t('pages-customers.status'),
            visible: true,
        },
        {
            id: 'volume',
            label: t('pages-customers.volume'),
            visible: true,
        },
        {
            id: 'interestRate',
            label: t('pages-customers.averageInterestRate'),
            visible: true,
        },
    ]

    const Pagination = (
        <TablePagination
            labelRowsPerPage={t('pages-customers.customersPerPage')}
            labelDisplayedRows={({ from, to, count }) => t('pages-customers.customersDisplayed', { from, to, count })}
            rowsPerPageOptions={[50, 100, 250, 500]}
            component="div"
            count={customers.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value)
                setPage(0)
            }}
        />
    )

    return customers.length > 0 ? (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns
                            .filter((c) => c.visible)
                            .map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={sortKey === column.id ? sortDirection : false}
                                >
                                    <TableSortLabel
                                        active={sortKey === column.id}
                                        direction={
                                            sortKey === column.id ? (sortDirection === 'asc' ? 'asc' : 'desc') : 'asc'
                                        }
                                        onClick={() => setSorting(column.id)}
                                    >
                                        {column.label}
                                        {sortKey === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((customer) => (
                        <CustomerListRow customer={customer} partner={partner} key={customer.depositorId} />
                    ))}
                </TableBody>
            </Table>
            {customers.length > 10 && Pagination}
        </TableContainer>
    ) : (
        <Typography variant="emptyState" fontSize={'2rem'} color={SILVER_GRAY[500]}>
            {t('pages-customers.noCustomers')}
        </Typography>
    )
}

function CustomerListRow({ customer, partner }: { customer: Row; partner: PartnerDto }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()
    const association = useSelector((state) => state.session.association)
    const canSeeOtherPartnerUsers = association?.roles.includes('PARTNER_ADMIN')

    return (
        <TableRow
            data-cy="customerTableRow"
            key={customer.depositorId}
            sx={{ '&:hover': { backgroundColor: 'rgba(77, 42, 152, 0.05)', cursor: 'pointer' } }}
            onClick={() => navigate('/customer/' + customer.depositorId)}
        >
            <TableCell sx={{ fontWeight: '600', fontSize: '1.4rem', width: '40rem' }}>{customer.name}</TableCell>
            {canSeeOtherPartnerUsers && (
                <TableCell>
                    <Stack direction="row" spacing={1}>
                        {!customer.primaryAdvisorName ? (
                            <Chip
                                sx={{
                                    backgroundColor: BURNT_ORANGE[50],
                                    color: BURNT_ORANGE[500],
                                    fontSize: '1.2rem',
                                    fontWeight: '600',
                                }}
                                variant="filled"
                                label={
                                    partner?.partnerCategory === 'ACCOUNTANT'
                                        ? t('pages-customers.missingAccountant')
                                        : t('pages-customers.missingAdvisor')
                                }
                            />
                        ) : (
                            <Stack direction={'row'} spacing={0.5} alignItems="center">
                                <Avatar
                                    sx={{
                                        width: '2.6rem',
                                        height: '2.6rem',
                                        fontSize: '1.2rem',
                                        fontWeight: '600',
                                        backgroundColor: SILVER_GRAY[500],
                                    }}
                                >
                                    {getIconText(customer.primaryAdvisorName)}
                                </Avatar>
                                <span>{customer.primaryAdvisorName}</span>
                            </Stack>
                        )}
                    </Stack>
                </TableCell>
            )}
            <TableCell>
                <CustomerStatusField customer={customer} />
            </TableCell>
            <TableCell>{Currency(customer.volume || 0)}</TableCell>
            <TableCell>
                {customer.interestRate !== null
                    ? InterestOutput.format(customer.interestRate)
                    : t('pages-customers.noDeposits')}
            </TableCell>
        </TableRow>
    )
}
