import { createSelector } from 'reselect'
import * as selectors from '#state/selectors'
import {
    AdDto,
    BankDto,
    BankLimitedDto,
    DateAsString,
    DepositDto,
    DepositorDto,
    DepositorOfferDto,
    DepositorPartnerProposalDto,
    DocumentDto,
    FundBuyOrderDto,
    FundDto,
    FundSellOrderDto,
    FundShareClassDto,
    InterestRateChangeDto,
    MessageParametersDto,
    MessageType,
    OrderDto,
    PartnerDto,
    Product,
    ProfileDto,
    SettlementAccountDto,
    TaskDto,
} from '@fixrate/fixrate-query'
import { SessionDto } from '@fixrate/fixrate-security'
import isAfter from 'date-fns/isAfter'

interface MessageDataOutput {
    actionRequiredByUser: boolean
    ad: AdDto
    product: Product
    products: { [key: string]: Product }
    order: OrderDto
    deposit: DepositDto
    depositor: DepositorDto
    depositorId: string
    depositorName: string
    bankName: string
    organisationName: string
    settlementAccount: SettlementAccountDto
    interestRateChange: InterestRateChangeDto
    targetUserName: string
    taskResolvedByUserName: string
    userFullName: string
    actingUserFullName: string
    partnerName: string
    partnerDepositorName: string
    depositorOffer: DepositorOfferDto
    month: DateAsString
    year: string
    document: DocumentDto
    fundBuyOrder: FundBuyOrderDto
    fundSellOrder: FundSellOrderDto
    fund: FundDto
    fundShareClass: FundShareClassDto
    orderGroupId: string
    proposal: DepositorPartnerProposalDto
    hasActiveDeposits: boolean
}

export const actionRequiredByUserSelector = createSelector(
    selectors.userAssociationMap,
    (state) => state?.session?.id,
    (associationMap, sessionId) =>
        (task: TaskDto): boolean => {
            const hasCorrectRole = associationMap[task.targetOrganisationId]?.roles.includes(task.targetRole)
            return task.resolved === false && (hasCorrectRole || sessionId === task.targetUserId)
        }
)

export const messageDataSelector = createSelector(
    [
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        actionRequiredByUserSelector,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.session,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.ads,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.products,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.orders,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.deposits,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.depositors,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.banks,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.bank,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.depositorNames,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.partnerNames,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.partner,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.interestRateChange,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.profile,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.depositorOffers,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.documents,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.fundBuyOrders,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.fundSellOrders,
        // @ts-ignore because the reselect library does not provide typescript declaration for this many selectors
        (state) => state.funds,
    ],
    (
        actionRequiredByUserFunction: (task: TaskDto) => boolean,
        session: SessionDto,
        ads: AdDto[],
        products: { [key: string]: Product },
        orders: OrderDto[],
        deposits: DepositDto[],
        depositors: DepositorDto[],
        banks: { [key: string]: BankLimitedDto },
        associatedBank: BankDto,
        depositorNames: { [key: string]: string },
        partnerNames: { [key: string]: string },
        partner: PartnerDto,
        interestRateChanges: InterestRateChangeDto[],
        profile: ProfileDto,
        depositorOffers: DepositorOfferDto[],
        documents: { [key: string]: DocumentDto },
        fundBuyOrders: FundBuyOrderDto[],
        fundSellOrders: FundSellOrderDto[],
        funds: FundDto[]
    ) =>
        function (messageParameters: MessageParametersDto, messageType: MessageType, task: TaskDto): MessageDataOutput {
            const {
                bankId,
                depositorId,
                adId,
                orderId,
                depositId,
                settlementAccountId,
                interestRateChangeId,
                userId,
                actingUserId,
                partnerId,
                depositorOfferId,
                month,
                year,
                documentId,
                fundOrderId,
                orderGroupId,
                fundId,
                fundShareClassId,
                proposalId,
            } = messageParameters
            const ad = ads.find((ad) => ad.id === adId)
            const product = products[ad?.productId]
            const order = orders.find((order) => order.id === orderId)
            const deposit = deposits.find((deposit) => deposit.id === depositId)
            const depositorUsers = depositors.flatMap((depositor) => depositor.users)
            let depositorName = depositorNames[depositorId]
            let bankName = banks[bankId]?.name
            const depositor = depositors.find((depositor) => depositor.id === depositorId)
            const settlementAccount = depositor?.settlementAccounts.find((sa) => sa.id === settlementAccountId)
            const interestRateChange = interestRateChanges.find((irc) => irc.id === interestRateChangeId)
            const depositorOffer = depositorOffers?.find(
                (depositorOffer) => depositorOffer.depositorOfferId === depositorOfferId
            )
            const document = documents[documentId]

            const knownUserFullNames = {} as { [userId: string]: string }

            const fundBuyOrder = fundBuyOrders.find((o) => o.id === fundOrderId)
            const fundSellOrder = fundSellOrders.find((o) => o.id === fundOrderId)
            const fund = funds.find((f) => f.id === fundId)
            const fundShareClass = fund?.fundShareClasses.find(
                (fsc) =>
                    fsc.id === (fundShareClassId || fundBuyOrder?.fundShareClassId || fundSellOrder?.fundShareClassId)
            )

            const actionRequiredByUser = actionRequiredByUserFunction(task)

            const [messageAccessor] = messageType.split('__')

            let targetUserName = ''

            let taskResolvedByUserName = ''
            let organisationName = ''

            const partnerName = partnerNames?.[partnerId] || ''
            const partnerDepositorName =
                partner?.customers?.find((customer) => customer.depositorId === depositorId)?.name || ''
            const proposal = partner?.customers
                .flatMap((customer) => customer.partnerRelations)
                .flatMap((relation) => relation.partnerProposals)
                .find((proposal) => proposal.proposalId === proposalId)

            switch (messageAccessor) {
                case 'DEPOSITOR': {
                    Object.assign(knownUserFullNames, ...depositors.map((d) => d?.knownUserFullNames ?? {}))
                    const userInDepositor = depositorUsers.find((user) => user.id === task.targetUserId)
                    targetUserName = userInDepositor ? `${userInDepositor.firstName} ${userInDepositor.lastName}` : ''
                    taskResolvedByUserName = task.resolved ? (knownUserFullNames[task.resolvedBy] ?? '') : ''
                    organisationName = depositorName
                    break
                }
                case 'BANK': {
                    const userInBank = associatedBank?.users.find((user) => user.id === task.targetUserId)
                    targetUserName = userInBank ? `${userInBank.firstName} ${userInBank.lastName}` : ''
                    taskResolvedByUserName = task.resolved
                        ? (associatedBank?.knownUserFullNames[task.resolvedBy] ?? '')
                        : ''
                    organisationName = bankName
                    Object.assign(knownUserFullNames, associatedBank?.knownUserFullNames)
                    break
                }
                case 'PARTNER': {
                    const userInPartner = partner?.users.find((user) => user.id === task.targetUserId)
                    targetUserName = userInPartner ? `${userInPartner.firstName} ${userInPartner.lastName}` : ''
                    const taskResolvedByUser = partner?.users.find((user) => user.id === task.resolvedBy)
                    taskResolvedByUserName = task.resolved
                        ? taskResolvedByUser
                            ? `${taskResolvedByUser.firstName} ${taskResolvedByUser.lastName}`
                            : ''
                        : ''
                    organisationName = partnerName
                    Object.assign(knownUserFullNames, partner?.knownUserFullNames)
                    break
                }
                case 'USER': {
                    targetUserName = `${session?.firstName} ${session?.lastName}`
                    taskResolvedByUserName = task.resolved ? targetUserName : ''
                    depositorName = profile?.knownOrganisationNames[depositorId]
                    bankName = profile?.knownOrganisationNames[bankId]
                    break
                }
                case 'FUND_INTERMEDIARY': {
                    organisationName = 'Fixrate Capital'
                    break
                }
                case 'FIXRATE': {
                    break
                }
            }

            const userFullName = knownUserFullNames[userId]
            const actingUserFullName = knownUserFullNames[actingUserId]

            const hasActiveDeposits =
                deposits.filter(
                    (deposit) =>
                        deposit.terminationDate === null || isAfter(new Date(deposit.terminationDate), new Date())
                ).length > 0

            return {
                actionRequiredByUser,
                ad,
                product,
                products,
                order,
                deposit,
                depositor,
                depositorId,
                depositorName,
                bankName,
                organisationName,
                settlementAccount,
                interestRateChange,
                targetUserName,
                taskResolvedByUserName,
                userFullName,
                actingUserFullName,
                partnerName,
                partnerDepositorName,
                depositorOffer,
                month,
                year,
                document,
                fundBuyOrder,
                fundSellOrder,
                fund,
                fundShareClass,
                orderGroupId,
                proposal,
                hasActiveDeposits,
            }
        }
)
