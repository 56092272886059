import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import { InterestOutput } from '#components'
import { useEstimatedDashboardData } from '#services/dashboard'
import { hardLogout } from '#services/thunks/session'
import { useSelector } from '#state/useSelector'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import InterestCalculator from '../Onboarding/InterestCalculator/InterestCalculator'
import TourFrame from '../Onboarding/WelcomeTour/TourFrame'
import WelcomeTour from '../Onboarding/WelcomeTour/WelcomeTour'
import AnimatedNumber from './AnimatedNumber'
import DashboardEntry from './DashboardEntry'
import styles from './DashboardHeader.module.scss'

export default function DashboardHeader() {
    const { t } = useTranslation()
    const dashboard = useSelector((state) => state.dashboard)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const estimated = useEstimatedDashboardData()
    const isPartner = useSelector((state) => state.session?.organisationType === 'PARTNER')
    const currentCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const authenticationLevel = useSelector((state) => state.session?.authenticationLevel)
    const organisationType = useSelector((state) => state.session?.organisationType)
    const authenticated = useSelector((state) => state.session?.authenticated)
    const firstName = useSelector((state) => state.session?.firstName)
    const Currency = useCurrencyOutput()
    const [showPotential, setShowPotential] = useState(false)
    const [aboutFixrateVisible, setAboutFixrateVisible] = useState(false)
    const canShowPotentialInterest = currentCurrency === 'NOK'
    const dashboardBackgroundColor = PURPLE[900]

    const benchmarkName = dashboard?.currentInterestRateBenchmark
        ? t('common.interestRateBenchmark.' + dashboard?.currentInterestRateBenchmark)
        : ''

    const loginButtonStyle = {
        color: dashboardBackgroundColor,
        backgroundColor: SPRING_GREEN[500],
    }

    function potentialGain(dashboard) {
        const gain = (dashboard.potentialMonthlyInterest - dashboard.todaysMonthlyInterest) * 12
        return gain > 0 ? gain : 0
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)

        if (searchParams.get('welcome') !== null) {
            setAboutFixrateVisible(true)
        }
    }, [estimated, location.search])

    if (authenticationLevel === 'KNOWN') {
        return (
            <Box bgcolor={dashboardBackgroundColor} className={styles.body}>
                <Stack width={'100%'} direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
                    <div className={styles.welcomeBackText}>
                        <span>{t('pages-DashboardHeader.welcomeBack', { name: firstName })}</span>
                        <Button
                            data-cy="forgetMeButton"
                            color="inherit"
                            sx={{ ml: '2rem' }}
                            size="small"
                            variant="outlined"
                            startIcon={<i className="ri-close-circle-line" />}
                            onClick={() => dispatch(hardLogout(navigate))}
                        >
                            {t('pages-DashboardHeader.forgetMe')}
                        </Button>
                    </div>
                    <Button
                        sx={loginButtonStyle}
                        color="inherit"
                        variant="contained"
                        className={styles.dashboarHeaderButton}
                        onClick={() => navigate('/marketplace?login')}
                    >
                        {t('pages-DashboardHeader.logIn')}
                    </Button>
                </Stack>
            </Box>
        )
    }

    if (authenticationLevel === 'UNKNOWN') {
        return null
    }

    if (organisationType === 'BANK') {
        // Bank
        return (
            <Box bgcolor={dashboardBackgroundColor} className={styles.body}>
                <DashboardEntry
                    header={t('pages-DashboardHeader.estimatedBalance')}
                    value={Currency(dashboard.total?.estimatedBalance)}
                    tooltip={t('pages-DashboardHeader.estimatedBalanceTooltip')}
                />
                <DashboardEntry
                    header={t('pages-DashboardHeader.averageInterestToday')}
                    value={InterestOutput.format(
                        dashboard.total?.estimatedEffectiveInterestRateToday ??
                            dashboard.total?.estimatedInterestRateToday
                    )}
                    hideIfSmall={true}
                    tooltip={t('pages-DashboardHeader.averageInterestTodayTooltip')}
                />
                <DashboardEntry
                    header={benchmarkName}
                    value={InterestOutput.format(dashboard.currentBenchmarkInterestRate)}
                    tooltip={t('pages-DashboardHeader.benchmarkTooltip', { benchmarkName })}
                />
            </Box>
        )
    }

    if (currentCurrency !== 'NOK') {
        return null
    }

    if (estimated && estimated.estimatedTotalBalance) {
        // Depositor
        return (
            <Box bgcolor={dashboardBackgroundColor} className={styles.body}>
                <DashboardEntry
                    header={t('pages-DashboardHeader.interestThisMonth')}
                    value={
                        <AnimatedNumber
                            start={estimated.estimatedInterestThisMonth}
                            end={estimated.estimatedInterestThisMonth}
                            decimals={0}
                        />
                    }
                    minWidth={16.5}
                    tooltip={t('pages-DashboardHeader.interestThisMonthTooltip')}
                />
                <DashboardEntry
                    header={t('pages-DashboardHeader.interestToday')}
                    value={
                        <AnimatedNumber
                            start={estimated.estimatedInterestSinceMidnight}
                            end={estimated.estimatedInterestSinceMidnight}
                            decimals={0}
                        />
                    }
                    minWidth={16.5}
                    hideIfSmall={true}
                    tooltip={t('pages-DashboardHeader.interestTodayTooltip')}
                />
                <DashboardEntry
                    header={t('pages-DashboardHeader.averageInterestToday')}
                    value={InterestOutput.format(
                        dashboard.total?.estimatedEffectiveInterestRateToday ??
                            dashboard.total?.estimatedInterestRateToday
                    )}
                    hideIfSmall={true}
                    tooltip={t('pages-DashboardHeader.averageInterestTodayTooltip')}
                />
                <DashboardEntry
                    header={benchmarkName}
                    value={InterestOutput.format(dashboard.currentBenchmarkInterestRate)}
                    tooltip={t('pages-DashboardHeader.benchmarkTooltip', { benchmarkName })}
                />
            </Box>
        )
    }

    const loginButton = !authenticated && (
        <Button variant="outlined" color="inherit" onClick={() => navigate('/marketplace/onboarding')}>
            {t('pages-DashboardHeader.whatIsFixrate')}
        </Button>
    )

    if (isPartner) {
        return null
    }

    return (
        <Box bgcolor={dashboardBackgroundColor} className={styles.body}>
            {!dashboard.potentialMonthlyInterest || !dashboard.potentialVolume ? (
                <>
                    {canShowPotentialInterest && (
                        <div className={styles.ctaText} onClick={() => setShowPotential(true)}>
                            <Box display={'inline'} color={SPRING_GREEN[500]}>
                                <IconButton color="inherit" onClick={() => setShowPotential(true)}>
                                    <i className="ri-calculator-line" />
                                </IconButton>
                            </Box>
                            {t('pages-DashboardHeader.whatIsYourPotentialInterestGain')}
                        </div>
                    )}
                    <Button
                        variant={'outlined'}
                        size="small"
                        color="inherit"
                        onClick={() => setAboutFixrateVisible(true)}
                    >
                        {t('pages-DashboardHeader.whatIsFixrate')}
                    </Button>
                </>
            ) : (
                <>
                    {canShowPotentialInterest && (
                        <>
                            <div className={styles.interestRateGain} onClick={() => setShowPotential(true)}>
                                {t('pages-DashboardHeader.potentialInterestGain', {
                                    amount: Currency(potentialGain(dashboard)),
                                })}
                            </div>
                            <div className={styles.interestRateGainUnit}>{t('pages-DashboardHeader.perYear')}</div>
                            <Box color={SPRING_GREEN[500]}>
                                <IconButton color="inherit" onClick={() => setShowPotential(true)}>
                                    <i className="ri-calculator-line" />
                                </IconButton>
                            </Box>
                        </>
                    )}
                    {loginButton}
                </>
            )}
            {showPotential && canShowPotentialInterest && (
                <TourFrame
                    title={t('pages-DashboardHeader.interestHeader')}
                    headerElement={
                        <Box maxWidth={'45rem'}>
                            <FixrateIllustration name="womanOfficeWorking" color="multiColor" />
                        </Box>
                    }
                    onNext={() => setShowPotential(false)}
                    nextText={t('common.close')}
                    open={showPotential}
                    onClose={() => setShowPotential(false)}
                >
                    <InterestCalculator />
                </TourFrame>
            )}
            {aboutFixrateVisible && <WelcomeTour open={aboutFixrateVisible} setOpen={setAboutFixrateVisible} />}
        </Box>
    )
}
