import { PartnerAdvisorDto } from '@fixrate/fixrate-query'
import { Card, CardContent, Chip, Stack, Typography } from '@mui/material'
import React from 'react'
import { securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { useTranslation } from 'react-i18next'

export type AdvisorPerson = PartnerAdvisorDto & {
    partnerCompanyName?: string
}

export default function Advisor({ advisor }: { advisor: AdvisorPerson }) {
    const { t } = useTranslation()

    const roles = advisor.roles.map((role) => t(securityRoleTMap[role]))
    return (
        <Card sx={{ maxWidth: '70rem' }} id={advisor.userId} data-cy="advisorElement">
            <CardContent>
                <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack spacing={0.4}>
                        <Typography variant="h3">{advisor.firstName + ' ' + advisor.lastName}</Typography>
                        <Typography variant="body2">{roles}</Typography>
                    </Stack>
                    <Chip label={advisor.partnerCompanyName} data-cy="partnerNameLabel" />
                </Stack>
            </CardContent>
        </Card>
    )
}
