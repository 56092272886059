import { useCommand } from '#command'
import { DateOutput } from '#components'
import Button from '#components/Button'
import HelpPopup from '#components/HelpPopup'
import {
    useSignableDocumentListInstruction,
    SignableDocumentList,
} from '#components/SignableDocument/SignableDocumentList'
import { useAuthorization } from '#services/authorization'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { DocumentDto, OrderDto, ProcessHistoryDto } from '@fixrate/fixrate-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

export default function OrdersDepositorSignature({
    order,
}: {
    order: OrderDto & { lastHistoryAction?: ProcessHistoryDto; settlementDate?: Date }
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { sendToBank, startElectronicSignature } = useCommand()

    const [showValidationErrors, setShowValidationErrors] = useState(false)

    const documentsToSign = useSelector((state) =>
        selectors
            .documentIdToDocumentMapper(state)(order.documents)
            .filter(
                (document) =>
                    document.documentType === 'ACCOUNT_AGREEMENT' || document.documentType === 'ACCOUNT_TERMINATION'
            )
    )

    const allDocumentsAreSigned = useSelector((state) => selectors.allDocumentsAreSigned(state)(order.id))

    const auth = useAuthorization()
    const hasSendDocumentPermission =
        auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole || auth.depositor.hasAccountHolderRole

    const signInstruction = useSignableDocumentListInstruction(documentsToSign)

    async function onSendClick() {
        if (allDocumentsAreSigned) {
            const { waitForCommand } = await sendToBank(order.id)
            const success = await waitForCommand()
            if (success) {
                dispatch(
                    showConfirmationModal({
                        title: t('pages-orders.depositorAccountAgreementSentModalTitle'),
                        text: t('pages-orders.depositorAccountAgreementSentModalText'),
                        buttonText: t('common.continue'),
                    })
                )
            }
        } else {
            setShowValidationErrors(true)
        }
    }

    async function onStartSignature(documents: DocumentDto[]) {
        if (documents.length < 0) {
            return
        }

        const onlyOne = documents.length === 1
        const processType = onlyOne ? 'SINGLE_DOCUMENT' : 'MULTIPLE_DOCUMENTS'
        const identity = onlyOne ? documents.at(0).id : documents.map((d) => d.id).join(',')

        const processId = uuidv4()
        const { waitForCommand } = await startElectronicSignature(processId, processType, identity)
        const success = await waitForCommand()
        if (success) {
            navigate(`/signature/${processId}?context=/orders/signing`)
        }
    }

    return (
        <div>
            <dl className="actionframe__labeledinput">
                {!allDocumentsAreSigned && (
                    <>
                        <dt className="actionframe__labeledinput-label">
                            <HelpPopup text={t('pages-orders.depositorSignDocumentsHelpText')}>
                                {t('pages-orders.depositorSignDocumentsSteps')}
                            </HelpPopup>
                        </dt>
                        <dd>{signInstruction}</dd>
                    </>
                )}
                <SignableDocumentList
                    documents={documentsToSign}
                    onStartSignature={onStartSignature}
                    showSigningError={showValidationErrors}
                />
            </dl>
            {order.settlementDate && (
                <p>{t('pages-orders.depositorSettlementDate', DateOutput.formatVerboseMonth(order.settlementDate))}</p>
            )}
            {allDocumentsAreSigned && (
                <Button
                    variant={'primary'}
                    id="sendSignedDocumentsToBankButton"
                    onClick={onSendClick}
                    disabled={!hasSendDocumentPermission}
                >
                    {t('pages-orders.depositorSendDocumentsToBank')}
                </Button>
            )}
        </div>
    )
}
