import { Route, Routes } from 'react-router-dom'
import InterestRateChangeBankDetails from './InterestRateChangeBankDetails/InterestRateChangeBankDetails'
import InterestRateChangeBankIndex from './InterestRateChangeBankIndex'
import NewInterestRateChange from './NewInterestRateChange/NewInterestRateChange'

export default function InterestRateChange() {
    return (
        <Routes>
            <Route path="new" element={<NewInterestRateChange />} />
            <Route path=":id" element={<InterestRateChangeBankDetails />} />
            <Route path="" element={<InterestRateChangeBankIndex />} />
        </Routes>
    )
}
