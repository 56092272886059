import { createContext, useContext, useEffect, useReducer } from 'react'

const Validator = createContext(null)

const VALID = 'VALID'
const TOUCH = 'TOUCH'
const REMOVE = 'REMOVE'

type ValidateAllType = {
    state
    areAllValid
    areAnyTouched
    touchAll
}

type Action = {
    type: typeof VALID | typeof TOUCH | typeof REMOVE
    payload
}

type ValidationState = {
    [key: string]: {
        valid: boolean
        touched: boolean
    }
}

type ValidatePathType = {
    state: ValidationState
    self: { valid: boolean; touched: boolean }
    setValid: (value: boolean) => void
    isValid: () => boolean
    setTouched: (value: boolean) => void
    isTouched: () => boolean
}

export function useValidate(path: string): ValidatePathType {
    const context = useContext(Validator)

    if (context === undefined) {
        throw new Error('useValidate can only be used within a Validator')
    }

    const { state, dispatch } = context

    useEffect(() => {
        return () => {
            dispatch({ type: REMOVE, payload: [path] })
        }
    }, [dispatch, path])

    /**
     * Sets the valid state of the field with the given path
     */
    function setValid(value: boolean) {
        if (!state[path] || value !== state[path].valid) {
            dispatch({ type: VALID, payload: [path, value] })
        }
    }

    /**
     * Are all fields on or below this path valid
     */
    function isValid() {
        return Object.keys(state)
            .filter((key) => key.startsWith(path) && (key.length === path.length || key.charAt(path.length) === '.'))
            .reduce((acc, key) => {
                if (state[key].valid === false || acc === false) {
                    return false
                }
                return state[key].valid && acc
            }, true)
    }

    /**
     * Sets the touched state of the field with the given path
     */
    function setTouched(value: boolean) {
        if (!state[path] || value !== state[path].touched) {
            dispatch({ type: TOUCH, payload: [path, value] })
        }
    }

    /**
     * Are any fields on or below this path touched
     */
    function isTouched() {
        return Object.keys(state)
            .filter((key) => key.startsWith(path))
            .reduce((acc, key) => {
                return !!state[key].touched || acc
            }, false)
    }

    return {
        state,
        self: state[path],
        setValid,
        isValid,
        setTouched,
        isTouched,
    }
}

export function useValidateAll(): ValidateAllType {
    const context = useContext(Validator)

    if (context === undefined) {
        throw new Error('useValidate can only be used within a Validator')
    }

    const { state, dispatch } = context

    function areAllValid({ paths = [] } = {}) {
        for (const key of Object.keys(state)) {
            if (
                paths.length === 0 ||
                paths.some(
                    (path) => key.startsWith(path) && (key.length === path.length || key.charAt(path.length) === '.')
                )
            ) {
                if (state[key].valid === false) {
                    return false
                }
            }
        }
        return true
    }

    function areAnyTouched({ paths = [] } = {}) {
        for (const key of Object.keys(state)) {
            if (
                paths.length === 0 ||
                paths.some(
                    (path) => key.startsWith(path) && (key.length === path.length || key.charAt(path.length) === '.')
                )
            ) {
                if (state[key].touched === true) {
                    return true
                }
            }
        }
        return false
    }

    function touchAll() {
        for (const key of Object.keys(state)) {
            if (!state[key].touched) {
                dispatch({ type: TOUCH, payload: [key, true] })
            }
        }
    }

    return {
        state,
        areAllValid,
        areAnyTouched,
        touchAll,
    }
}

export function Validate({ children, initState = {} }: { children?: React.ReactNode; initState?: ValidationState }) {
    const [state, dispatch] = useReducer((state, { type, payload = [] }: Action) => {
        const [path, value] = payload
        switch (type) {
            case VALID: {
                return {
                    ...state,
                    [path]: {
                        ...state[path],
                        valid: value,
                    },
                }
            }
            case TOUCH: {
                return {
                    ...state,
                    [path]: {
                        ...state[path],
                        touched: value,
                    },
                }
            }
            case REMOVE: {
                const newState = { ...state }
                delete newState[payload]
                return newState
            }
            default: {
                return state
            }
        }
    }, initState)

    return <Validator.Provider value={{ state, dispatch }}>{children}</Validator.Provider>
}
