import { Fragment } from 'react'
import classNames from 'classnames'
import ProcessInformation from './ProcessInformation'
import styles from './DepositList.module.scss'
import { CurrencyOutput, DateOutput, InterestOutput } from '#components'
import { useTranslation } from 'react-i18next'
import { formatAccount } from '#services/formatnumber'
import { BankDepositRow, isOver180DaysOld } from '#pages/portfolio-bank/DepositList/DepositList'
import { Box, Tooltip } from '@mui/material'
import { BURNT_ORANGE, SUNSET_ORANGE } from '#app/colors/colors'
import { depositDeviationStateShortTMap } from '#services/enumTranslationKeyMapping'
import { useAuthorization } from '#services/authorization'
import hasInterestCalculation from '#app/services/hasInterestCalculation'
import { useSelector } from '#app/state/useSelector'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

type Props = {
    bankDepositRow: BankDepositRow
    onSelectDeposit: (depositId: string) => void
    expired?: boolean
    archived?: boolean
}

export default function BankDeposit({ bankDepositRow, onSelectDeposit, expired, archived }: Props) {
    const { t } = useTranslation()

    const authorization = useAuthorization()
    const selectedCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const isCoordinator = authorization.bank.hasCoordinatorRole
    const organisationCountry = useCurrentCountryCode()

    const productBadgeClasses = classNames(
        styles.depositList__elementProductBadge,
        expired && styles.depositList__elementProductBadgeExpired
    )
    const interestBadgeClasses = classNames(
        styles.depositList__elementInterestBadge,
        expired && styles.depositList__elementInterestBadgeExpired
    )

    const terminationActionRequired = !!bankDepositRow.terminationRequested && !bankDepositRow.terminationConfirmed
    const settlementAccountActionRequired = bankDepositRow.changedSettlementAccountStatus === 'DOCUMENT_SENT_TO_BANK'
    const extensionOfferStateAccepted =
        bankDepositRow.extensionOfferState === 'ACCEPTED' && !bankDepositRow.extensionRegistered

    const actionRequired = terminationActionRequired || settlementAccountActionRequired || extensionOfferStateAccepted
    const deviationStatus = bankDepositRow.deviationStatus
    const showDeviation = !!deviationStatus && isCoordinator

    const statusMessage = () => {
        if (deviationStatus === 'DETECTED') {
            return t('pages-portfolio-bank.formNeeded')
        } else if (deviationStatus === 'BANK_DETAILS_ADDED') {
            return t('pages-portfolio-bank.formProcessing')
        }
    }

    const statusBadgeStyle = {
        backgroundColor:
            deviationStatus === 'DETECTED'
                ? BURNT_ORANGE[50]
                : deviationStatus === 'BANK_DETAILS_ADDED' || deviationStatus === 'WAIT_FOR_CORRECTION'
                  ? SUNSET_ORANGE[50]
                  : '',
        color:
            deviationStatus === 'DETECTED'
                ? BURNT_ORANGE[700]
                : deviationStatus === 'BANK_DETAILS_ADDED' || deviationStatus === 'WAIT_FOR_CORRECTION'
                  ? SUNSET_ORANGE[700]
                  : '',
        padding: '0.6rem 1.8rem',
        fontWeight: 600,
        fontSize: '1.5rem',
    }

    return (
        <Fragment>
            <li
                className={classNames(
                    styles.depositList__element,
                    archived && styles.depositList__elementArchived,
                    actionRequired && styles.depositList__elementAction,
                    isOver180DaysOld(bankDepositRow) && styles.depositList__elementOld
                )}
                data-cy="portfolioListItem"
                data-account-id={bankDepositRow.account}
                onClick={() => onSelectDeposit(bankDepositRow.id)}
            >
                <ul data-cy="deposit" className={styles.depositList__elementInfo}>
                    <li className={styles.depositList__elementTask}>
                        {showDeviation && <div className={classNames(styles.depositList__taskIndicator)} />}
                    </li>
                    <li className={styles.depositList__elementProduct}>
                        {bankDepositRow.product && (
                            <span className={productBadgeClasses}>
                                {t(`common.productShortName${bankDepositRow.product.id}`)}
                            </span>
                        )}
                    </li>
                    {hasInterestCalculation(selectedCurrency) && (
                        <li className={styles.depositList__elementInterest} data-cy="effectiveInterestRate">
                            <span className={interestBadgeClasses}>
                                {InterestOutput.format(bankDepositRow.effectiveInterestRate)}
                            </span>
                        </li>
                    )}
                    <li className={styles.depositList__elementInterest}>
                        {bankDepositRow.interestMargin ? (
                            <span className={interestBadgeClasses}>
                                {InterestOutput.format(bankDepositRow.interestMargin)}
                            </span>
                        ) : null}
                    </li>
                    <li data-cy="depositListCustomerName" className={styles.depositList__elementCustomer}>
                        {bankDepositRow.depositorName}
                    </li>
                    <li data-cy="depositListBankAccountNumber" className={styles.depositList__elementAccountNumber}>
                        {formatAccount(bankDepositRow.account, organisationCountry)}
                    </li>
                    {isCoordinator && (
                        <li className={styles.depositList__elementDeviationStatus}>
                            <Tooltip title={statusMessage()} arrow>
                                <Box component="span" sx={{ ...statusBadgeStyle }}>
                                    {t(depositDeviationStateShortTMap[deviationStatus])}
                                </Box>
                            </Tooltip>
                        </li>
                    )}
                    <li className={styles.depositList__elementVolume}>
                        {CurrencyOutput.formatMillion(
                            hasInterestCalculation(selectedCurrency)
                                ? (bankDepositRow.balance ?? bankDepositRow.actualStartVolume)
                                : bankDepositRow.actualStartVolume,
                            ''
                        )}
                    </li>
                    <li className={styles.depositList__elementExpires}>
                        {bankDepositRow.terminationDate ? DateOutput.formatDate(bankDepositRow.terminationDate) : ''}
                    </li>
                </ul>
                <ProcessInformation depositId={bankDepositRow.id} />
            </li>
        </Fragment>
    )
}
