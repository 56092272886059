import { useQuery } from 'react-query'
import { Alert, AlertTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LoadingSpinner } from '#app/components'

export default function BrregSignatories({ depositorId }: Readonly<{ depositorId: string }>) {
    const { t } = useTranslation()
    const {
        data: companyPublicInformation,
        isLoading,
        isSuccess,
    } = useQuery({
        queryFn: async () => {
            if (depositorId.length > 0) {
                const data = await fetch(`/api/partner/customer/${depositorId}`)
                return await data.json()
            }
        },
        queryKey: ['customerInfo', depositorId],
    })

    if (isLoading) return <LoadingSpinner />

    return isSuccess ? (
        <Alert severity="info">
            <AlertTitle>{t('layers-sendProposal.rulesForSigning')}</AlertTitle>
            {t('layers-sendProposal.fromBrreg')}
            {': '}
            {companyPublicInformation[0].signatories}
        </Alert>
    ) : (
        <Alert severity="warning">{t('layers-sendProposal.noInfoFromBrreg')}</Alert>
    )
}
