import { useSelector } from '#state/useSelector'
import { setPortfolioFilter } from '#state/reducers/portfolioFilter'
import { useDispatch } from 'react-redux'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function DepositFilter() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const deposits = useSelector((state) => state.deposits)
    const filter = useSelector((state) => state.portfolioFilter)

    const depositorOptions = Object.keys(deposits)
        .map((depositId) => deposits[depositId].depositor)
        .filter((value, _, self) => self.find((depositor) => depositor.id === value.id) === value) // Removes duplicates
        .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
        )
        .map((depositor) => (
            <option key={depositor.id} value={depositor.id}>
                {depositor.name}
            </option>
        ))

    const onSelectDepositorFilter = (e) => {
        dispatch(
            setPortfolioFilter({
                ...filter,
                depositorId: e.target.value !== 'all' ? e.target.value : undefined,
            })
        )
    }

    return depositorOptions.length > 1 ? (
        <Stack direction="row" alignItems={'center'} spacing={1}>
            <Typography fontWeight={'600'}>{t('pages-portfolio-bank.filterHeader')}:</Typography>
            <select onChange={onSelectDepositorFilter} value={filter.depositorId}>
                <option value="all">{t('pages-portfolio-bank.filterAllDepositors')}</option>
                {depositorOptions}
            </select>
        </Stack>
    ) : null
}
