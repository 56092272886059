import { useSelector } from '#state/useSelector'
import useTableSort from './useTableSort'
type AdRange = 'ALL' | 'UNDER_20' | 'OVER_20'

type FullySubscribedOption = 'EXCLUDE_FULLY_SUBSCRIBED' | 'INCLUDE_FULLY_SUBSCRIBED'

export function useSortedAds(range: AdRange = 'ALL', fullySubscribed: FullySubscribedOption) {
    const ads = useSelector((state) => state.ads)
    const adStatus = useSelector((state) => state.adStatus)

    // Filter out ads that are not active or have less than the minimum amount
    const activeAds = ads
        ?.filter((ad) => adStatus[ad.id]?.active)
        .filter((ad) => fullySubscribed === 'INCLUDE_FULLY_SUBSCRIBED' || ad.remaining >= ad.min)

    // Sort ads by interest in descending order, and by published date in ascending order
    const { sortedRows: sortedAds } = useTableSort(activeAds, 'interest', 'desc', 'published', 'asc')

    if (range === 'UNDER_20') {
        return sortedAds?.filter((ad) => ad.min < 20) || []
    }

    if (range === 'OVER_20') {
        return sortedAds?.filter((ad) => ad.min >= 20) || []
    }

    return sortedAds || []
}
