import { CHART_COLORS, PURPLE } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { StaggData, StaggSeries } from '#app/state/stagg'
import { useSelector } from '#app/state/useSelector'
import { format } from 'date-fns'
import parse from 'date-fns/parse'
import { useTranslation } from 'react-i18next'
import { formatBenchmark, formatData, getBanksInSameSegment } from '../chartData'
import { LineChart } from '../LineChart/LineChart'

type keyFigureName = 'coreCapitalCoverage' | 'depositCoverage' | 'totalAssets' | 'totalAssetsIncludingMortgageCredit'

const getValidValue = (value: string | number | null) => {
    if (typeof value === 'string') {
        return parseFloat(value)
    }

    if (value === null || value === undefined) {
        return null
    }

    return value
}

const BankKeyFigureChart = ({
    bankId,
    keyFigureNames,
    valueType,
    formatFn,
}: {
    bankId: string
    keyFigureNames: keyFigureName[]
    valueType: 'interest' | 'currency'
    formatFn?: 'millionFormat'
}) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const banks = useSelector((state) => state.banks)
    const bank = banks[bankId]

    if (!bank || !banks) return null

    const data = formatData(bank.bankInfoList, [...keyFigureNames])
    const banksInSameSegment = getBanksInSameSegment(bank, Object.values(banks))
    const benchmark = formatBenchmark(banksInSameSegment, data)

    const millionLabel = (val: string | number) => {
        if (typeof val === 'string') {
            return Currency(parseFloat(val), { withCurrency: false, decimals: 0 }) + ' ' + t('common.millionShort')
        }
        return Currency(val, { withCurrency: false, decimals: 0 }) + ' ' + t('common.millionShort')
    }

    const keyData: StaggSeries[] = keyFigureNames.map((keyFigureName) => ({
        columns: ['quarter', keyFigureName],
        tags: {
            name: t('components-Charts.' + keyFigureName),
        },
        values: data.map((d) => [
            format(parse(d?.quarter, 'yyyyqqq', new Date()), 'yyyy-MM-dd'),
            getValidValue(d[keyFigureName]),
        ]),
    }))

    const benchmarkData: StaggSeries[] = keyFigureNames.map((keyFigureName) => ({
        name: 'Benchmark:  ' + keyFigureName,
        columns: ['quarter', keyFigureName],
        tags: {
            name: t('components-Charts.benchmark-' + keyFigureName),
        },
        values: benchmark?.map((d) => [
            parse(d?.quarter, 'yyyyqqq', new Date()),
            d[keyFigureName] ? parseFloat(d[keyFigureName]) : null,
        ]),
    }))

    const staggData: StaggData = [
        {
            series: [...keyData, ...benchmarkData],
            error: null,
        },
    ]

    const formatNumberFn = formatFn === 'millionFormat' ? millionLabel : undefined

    return (
        <LineChart
            staggData={staggData}
            gradient={false}
            formatFn={formatNumberFn}
            colors={[PURPLE[500], ...CHART_COLORS]}
            valueType={valueType}
            sx={{ height: '40rem' }}
            quarterlyNumbers
        />
    )
}

export default BankKeyFigureChart
