import FixrateIcon, { IconName } from '#app/components/FixrateIcon/FixrateIcon'
import { Stack, Typography } from '@mui/material'

const CustomerKeyFigure = ({ label, value, icon }: { label: string; value: string; icon: IconName }) => {
    return (
        <Stack direction="row" alignItems="center">
            <FixrateIcon name={icon} sx={{ width: '10rem' }} />
            <Stack>
                <Typography fontSize="1.4rem" fontWeight="600">
                    {label}
                </Typography>
                <Typography fontSize="1.8rem" fontWeight="700">
                    {value}
                </Typography>
            </Stack>
        </Stack>
    )
}

export { CustomerKeyFigure }
