import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { DateOutput, Table, TableRow } from '#components'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout/PageLayout'
import Paper from '#app/components/Paper/FxPaper'
import SelectedCustomerDeclaration from '#pages/newcustomerdeclarations/SelectedCustomerDeclaration'
import { formatOrganizationNumber } from '#services/formatnumber'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './NewCustomerDeclarations.module.scss'

type Params = {
    depositorId?: string
}

export default function NewCustomerDeclarations() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { depositorId } = useParams<Params>()
    const organisationCountry = useCurrentCountryCode()

    const newCustomerDeclarations = useSelector((state) => state.newCustomerDeclarations)
    const documents = useSelector((state) => state.documents)

    const listElements = newCustomerDeclarations.map((newCustomerDeclaration) => {
        const document = documents[newCustomerDeclaration.documentId]
        return (
            <TableRow
                onClick={() => navigate(`/newcustomerdeclarations/${newCustomerDeclaration.depositorId}#!`)}
                key={newCustomerDeclaration.depositorId}
                data-cy="customerDeclarationItem"
            >
                <td>{!!document && DateOutput.formatDate(document.created)}</td>
                <td>{formatOrganizationNumber(newCustomerDeclaration.nationalIdentity, organisationCountry)}</td>
                <td className={styles.customerName}>{newCustomerDeclaration.name}</td>
                <td className={styles.openLink}>{t('common.open')}</td>
            </TableRow>
        )
    })

    const selectedCustomerDeclaration = newCustomerDeclarations.find(
        (customerDeclaration) => customerDeclaration.depositorId === depositorId
    )
    const document = selectedCustomerDeclaration && documents[selectedCustomerDeclaration.documentId]

    return (
        <>
            <PageHeader icon={'ri-survey-line'} title={t('pages-newcustomerdeclarations.pageHeader')} />
            <PageLayout>
                {!selectedCustomerDeclaration && (
                    <Paper title={t('pages-newcustomerdeclarations.paperHeader')} sx={{ width: '100%' }}>
                        <p>{t('pages-newcustomerdeclarations.info1')}</p>
                        <p>{t('pages-newcustomerdeclarations.info2')}</p>
                        <Table>
                            {listElements.length > 0
                                ? listElements
                                : t('pages-newcustomerdeclarations.noNewCustomerDeclarations')}
                        </Table>
                    </Paper>
                )}
                {!!selectedCustomerDeclaration && !!document && (
                    <SelectedCustomerDeclaration
                        selectedCustomerDeclaration={selectedCustomerDeclaration}
                        document={document}
                    />
                )}
            </PageLayout>
        </>
    )
}
