import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { useAuthorization } from '#app/services/authorization'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { DepositDto } from '@fixrate/fixrate-query'
import { Alert, Button, Divider, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
    deposit: DepositDto
    setTerminationMode: (TerminationMode) => void
    sx?: SxProps
}

export default function SelectTerminationType({ deposit, setTerminationMode }: Props) {
    const { t } = useTranslation()
    const auth = useAuthorization(deposit?.depositor.id)
    const hasOrderPermissions = auth?.depositor?.hasOrderRole || auth?.depositor?.hasPartnerAccountantRole

    return (
        <FxDialogContent>
            {!hasOrderPermissions && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {t('pages-portfolio-depositor.terminationStartInfo3')}
                </Alert>
            )}
            {deposit.product.termsType === 'NOTICE' && (
                <Stack spacing={2}>
                    <div>
                        <Typography variant="h3">
                            {t('pages-portfolio-depositor.terminationUnconditionalHeader')}
                        </Typography>
                        <LabeledInfo
                            info={t('pages-portfolio-depositor.terminationUnconditionalInfo1', {
                                count: deposit.product.days,
                            })}
                            helpText={t('pages-portfolio-depositor.noticeHelpText', { count: deposit.product.days })}
                        />
                        <p>{t('pages-portfolio-depositor.terminationUnconditionalInfo2')}</p>
                        <ButtonRow>
                            <Button
                                id="terminateNowButton"
                                variant="contained"
                                disabled={!hasOrderPermissions}
                                onClick={() => setTerminationMode('UNCONDITIONAL')}
                            >
                                {t('pages-portfolio-depositor.terminationUnconditionalButtonText')}
                            </Button>
                        </ButtonRow>
                    </div>
                    <Divider />
                    <div>
                        <Typography variant="h3" mb={2}>
                            {t('pages-portfolio-depositor.terminationConditionalHeader')}
                        </Typography>
                        <p>
                            {t('pages-portfolio-depositor.terminationConditionalInfo1', {
                                count: deposit.product.days,
                            })}
                        </p>
                        <p>{t('pages-portfolio-depositor.terminationConditionalInfo2')}</p>
                        <p>{t('pages-portfolio-depositor.terminationConditionalInfo3')}</p>
                        <ButtonRow>
                            <Button
                                variant="contained"
                                disabled={!hasOrderPermissions}
                                id="askForBetterInterestButton"
                                onClick={() => setTerminationMode('CONDITIONAL')}
                            >
                                {t('pages-portfolio-depositor.terminationConditionalButtonText')}
                            </Button>
                        </ButtonRow>
                    </div>
                </Stack>
            )}
            {deposit.product.termsType === 'RECURRING_FIXED_TERMS' && (
                <div>
                    <Typography variant="h4">{t('pages-portfolio-depositor.terminationRecurringHeader')}</Typography>
                    <Typography>
                        {t('pages-portfolio-depositor.terminationRecurringInfo1', { count: deposit.product.days })}
                    </Typography>
                    <Typography>{t('pages-portfolio-depositor.terminationRecurringInfo2')}</Typography>
                    <ButtonRow>
                        <Button
                            id="terminateNowButton"
                            disabled={!hasOrderPermissions}
                            onClick={() => setTerminationMode('UNCONDITIONAL')}
                        >
                            {t('pages-portfolio-depositor.terminationRecurringButtonText')}
                        </Button>
                    </ButtonRow>
                </div>
            )}
        </FxDialogContent>
    )
}
