import styles from './FundGrid.module.scss'
import { FundDto } from '@fixrate/fixrate-query'
import FundCard from '#pages/FundMarketplace/FundGrid/FundCard'
import useTableSort from '#services/useTableSort'
import FilterLayout from '../FilterLayout/FilterLayout'
import { Stack } from '@mui/material'

type Props = {
    funds: FundDto[]
    openShoppingCart: () => void
}

export default function FundGrid({ funds, openShoppingCart }: Props) {
    const { sortedRows } = useTableSort<FundDto & { returnRate1Y: number }>(
        funds.map((f) => ({ ...f, returnRate1Y: f.fundShareClasses[0].returnRates.PERIOD_1Y })),
        'risk',
        'asc',
        'returnRate1Y',
        'desc'
    )

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" mb={2}>
                <FilterLayout />
            </Stack>
            <ul className={styles.fundGrid}>
                {sortedRows.map((fund) => (
                    <FundCard key={fund.id} fund={fund} openShoppingCart={openShoppingCart} />
                ))}
            </ul>
        </>
    )
}
