import Modal from '#components/Modal'
import { useSelector } from '#state/useSelector'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { InterestOutput } from '#components'
import { useTranslation } from '#components/i18n'
import { DepositorOfferDto } from '@fixrate/fixrate-query'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { defaultBenchmark } from '#services/interestRateBenchmark'

export default function DepositorOfferConfirm({
    onCancel,
    onAccept,
    depositorOffer,
    volume,
}: {
    onCancel: () => void
    onAccept: () => void
    depositorOffer: DepositorOfferDto
    volume: number
}) {
    const { t } = useTranslation('pages-offer')
    const { t: tc } = useTranslation('common')
    const product = useSelector((state) => state.products[depositorOffer?.productId])
    const benchmark = defaultBenchmark(depositorOffer?.currency)

    let interest = InterestOutput.format(depositorOffer.interestRate)

    if (product.type === 'FIXED') {
        interest = `${t('fixedInterest')} ${interest}`
    } else {
        interest = InterestOutput.benchmarkLabel(benchmark, t) + ' + ' + interest
    }

    return (
        <Modal
            header={t('acceptOfferHeading')}
            onCancel={onCancel}
            onSubmit={onAccept}
            submitButtonText={t('acceptOffer')}
            submitButtonId={'acceptOfferButton'}
        >
            <Stack spacing={2}>
                <Box>
                    <Typography>
                        {t('acceptOfferMessagePart1', { depositorName: depositorOffer.depositorName })}:
                    </Typography>
                    <LabeledInfo label={t('customer')} info={depositorOffer.depositorName} />
                    <LabeledInfo label={t('organizationNumber')} info={depositorOffer.depositorNationalIdentity} />
                    <LabeledInfo label={t('product')} info={product.name} />
                    <LabeledInfo
                        label={t('volume')}
                        info={tc('volumeWithCurrency', { count: volume, currency: depositorOffer?.currency })}
                    />
                    <LabeledInfo label={t('interestRate')} info={interest} />
                </Box>
                <Divider />
                <Box>
                    <Typography>{t('acceptOfferMessagePart2')}</Typography>
                    <Typography>{t('acceptOfferMessagePart3')}</Typography>
                </Box>
            </Stack>
        </Modal>
    )
}
