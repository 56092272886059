import { Fragment, useState } from 'react'
import { Animated, ButtonRow, CurrencyOutput, DateOutput, InterestOutput, NumberInput } from '#components'
import { useTranslation } from 'react-i18next'
import { FundCategory, FundCustomerDto } from '@fixrate/fixrate-query'
import { Box, Button, Tooltip, Typography, Zoom } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'
import DateInput from '#components/DateInput'
import format from 'date-fns/format'
import { useFieldState } from '@fixrate/fieldstate'

export type PlatformFeeSteps = { [entryVolume: string]: number }

type Props = {
    customer: FundCustomerDto
    fundCategory: FundCategory
}

export default function SetPlatformFee({ customer, fundCategory }: Props) {
    const { t } = useTranslation()
    const { setDepositorFundPlatformFee } = useCommand()

    const [currentStartDate, currentPlatformFee] = Object.entries(
        customer.specialPlatformFeeHistory[fundCategory]
    ).reduce((acc, entry) => entry, [null, null] as [string | null, PlatformFeeSteps | null])

    const startDateField = useFieldState<Date | null>(
        currentStartDate ? new Date(currentStartDate) : null,
        ({ value, isEditing }) => {
            if (isEditing) {
                return ''
            }
            if (!value) {
                return t('validation.mustBeValidDate')
            }
        }
    )

    const stepsField = useFieldState<PlatformFeeSteps | null>(currentPlatformFee, ({ value }) => {
        if (value && Object.keys(value).length > 0 && value['0'] === undefined) {
            return t('pages.fundCustomers.customerDetail.platformFee.firstStepMustBeZero')
        }
    })

    const [submitting, setSubmitting] = useState(false)

    function addStep(entryVolume: string, fee: number) {
        stepsField.setValue({
            ...stepsField.value,
            [entryVolume]: fee,
        })
    }

    function removeStep(entryVolume: string) {
        const { [entryVolume]: _, ...rest } = stepsField.value
        stepsField.setValue(Object.keys(rest).length === 0 ? null : rest)
    }

    async function submit() {
        const valid = startDateField.validate() && stepsField.validate()
        if (!valid) {
            return
        }

        setSubmitting(true)
        const { waitForCommand } = await setDepositorFundPlatformFee(
            customer.depositorId,
            fundCategory,
            startDateField.value ? format(startDateField.value, 'yyyy-MM-dd') : null,
            stepsField.value
        )
        const success = await waitForCommand()
        setSubmitting(false)

        if (success) {
            untouchForm()
        }
    }

    function untouchForm() {
        startDateField.untouch()
        stepsField.untouch()
    }

    function resetForm() {
        startDateField.reset()
        stepsField.reset()
    }

    const touched = startDateField.touched || stepsField.touched

    const selectedStartDateIsInHistory =
        startDateField.value &&
        customer.specialPlatformFeeHistory[fundCategory][format(startDateField.value, 'yyyy-MM-dd')] !== undefined

    return (
        <Fragment>
            {/*<pre>specialPlatformFeeHistory: {JSON.stringify(customer.specialPlatformFeeHistory, null, 2)}</pre>*/}
            {/*<pre>currentPlatformFee: {JSON.stringify(currentPlatformFee, null, 2)}</pre>*/}
            {/*<pre>stepsField: {JSON.stringify(stepsField, null, 2)}</pre>*/}
            <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <label>{t('pages.fundCustomers.customerDetail.platformFee.startDate')}</label>
                    <DateInput
                        value={startDateField.value}
                        onChange={startDateField.setValue}
                        onBlur={startDateField.onBlur}
                    />
                </Box>
                {!startDateField.valid && <p className="field-error-message">{startDateField.errorMessage}</p>}
            </Box>

            <Box>
                {stepsField.value && (
                    <table style={{ textAlign: 'left', width: '100%' }}>
                        <thead>
                            <tr>
                                <th>{t('pages.fundCustomers.customerDetail.platformFee.step')}</th>
                                <th>{t('pages.fundCustomers.customerDetail.platformFee.entryVolume')}</th>
                                <th>{t('pages.fundCustomers.customerDetail.platformFee.price')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stepsField.value &&
                                Object.entries(stepsField.value).map(([entryVolume, fee], index) => (
                                    <tr key={entryVolume}>
                                        <td>{index + 1}</td>
                                        <td>{CurrencyOutput.formatMillion(+entryVolume * 1e6)}</td>
                                        <td>{InterestOutput.formatWithDecimals(fee, 4)}</td>
                                        <td>
                                            <Button
                                                size={'small'}
                                                variant={'outlined'}
                                                onClick={() => removeStep(entryVolume)}
                                            >
                                                {t('common.remove')}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                )}
                {!stepsField.value && (
                    <Fragment>
                        <label>{t('pages.fundCustomers.customerDetail.platformFee.steps')}</label>
                        <p>{t('pages.fundCustomers.customerDetail.platformFee.noStepsSelected')}</p>
                    </Fragment>
                )}
                <p className="field-error-message">{stepsField.errorMessage}</p>
                <StepForm selectedSteps={stepsField.value} onSubmit={addStep} />
                {touched && (
                    <Animated tag={'div'}>
                        <p className="field-info-message">
                            {t('pages.fundCustomers.customerDetail.platformFee.unsavedChanges')}
                        </p>
                        {selectedStartDateIsInHistory && (
                            <p className="field-info-message">
                                {t('pages.fundCustomers.customerDetail.platformFee.willOverwriteDate', {
                                    date: DateOutput.formatDate(startDateField.value),
                                })}
                            </p>
                        )}
                        <ButtonRow align={'left'}>
                            <LoadingButton
                                data-cy="platformFeeSubmit"
                                size={'small'}
                                onClick={submit}
                                variant={'contained'}
                                loading={submitting}
                                disabled={!stepsField.valid || submitting}
                            >
                                {t('common.save')}
                            </LoadingButton>
                            <Button size={'small'} variant={'outlined'} onClick={resetForm} disabled={submitting}>
                                {t('common.cancel')}
                            </Button>
                        </ButtonRow>
                    </Animated>
                )}
            </Box>
        </Fragment>
    )
}

type StepFormProps = {
    selectedSteps: PlatformFeeSteps | null
    onSubmit: (entryVolume: string, fee: number) => void
}

function StepForm({ selectedSteps, onSubmit }: StepFormProps) {
    const { t } = useTranslation()

    const entryVolumeField = useFieldState<number | null>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (value === null || isNaN(value)) {
            return t('validation.mustBeNumber')
        }
        if (value < 0) {
            return t('validation.mustBePositiveOrZero')
        }
    })

    const feeField = useFieldState<number | null>(null, ({ value, isEditing }) => {
        if (isEditing) {
            return ''
        }
        if (value === null || isNaN(value)) {
            return t('validation.mustBeNumber')
        }
    })

    function submit() {
        const valid = entryVolumeField.validate() && feeField.validate()
        if (!valid) {
            return
        }

        onSubmit(`${entryVolumeField.value}`, feeField.value)
        reset()
    }

    function reset() {
        entryVolumeField.reset()
        feeField.reset()
    }

    const isDuplicate = selectedSteps && Object.keys(selectedSteps).includes(`${entryVolumeField.value}`)

    return (
        <Box sx={{ backgroundColor: '#f9f7fd', py: 1, px: 2 }}>
            <Typography variant={'subtitle1'}>{t('pages.fundCustomers.customerDetail.platformFee.newStep')}</Typography>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                <Tooltip
                    title={entryVolumeField.errorMessage ?? ''}
                    placement={'bottom'}
                    arrow
                    TransitionComponent={Zoom}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <label>{t('pages.fundCustomers.customerDetail.platformFee.entryVolume')}</label>
                        <NumberInput
                            data-cy="platformFeeVolume"
                            sx={{ width: 140 }}
                            value={entryVolumeField.value}
                            onChange={entryVolumeField.setValue}
                            error={!entryVolumeField.valid}
                            onBlur={entryVolumeField.onBlur}
                            formatFn={(v) => (v === null ? '' : CurrencyOutput.formatMillion(v * 1e6))}
                            placeholder={'10M'}
                            noDecimals
                        />
                    </Box>
                </Tooltip>
                <Tooltip title={feeField.errorMessage ?? ''} placement={'bottom'} arrow TransitionComponent={Zoom}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <label>{t('pages.fundCustomers.customerDetail.platformFee.price')}</label>
                        <NumberInput
                            data-cy="platformFeePrice"
                            sx={{ width: 100 }}
                            value={feeField.value}
                            onChange={feeField.setValue}
                            error={!feeField.valid}
                            onBlur={feeField.onBlur}
                            placeholder={'0,120%'}
                            formatFn={(v) => InterestOutput.formatWithDecimals(v, 3)}
                        />
                    </Box>
                </Tooltip>
                <Box>
                    <Button data-cy="platformFeeAdd" variant={'outlined'} onClick={submit}>
                        {isDuplicate ? t('pages.fundCustomers.customerDetail.platformFee.overwrite') : t('common.add')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
