import styles from '../InfoTabs.module.scss'
import { FundDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

type Props = { fund: FundDto }

export default function FundESG({ fund }: Props) {
    const { t } = useTranslation()
    return (
        <div className={styles.esg}>
            <div className={styles.classification}>
                <h4>{t('pages-fund-details.fundClassification')}</h4>
                {
                    {
                        ARTICLE_6: (
                            <div>
                                <div className={classNames(styles.esgArticlePill, styles.orange)}>
                                    <i className="ri-error-warning-line" />
                                    <span>{t('pages-fund-details.article')} 6</span>
                                </div>
                                <p>{t('pages-fund-details.aboutArticle6')}</p>
                            </div>
                        ),
                        ARTICLE_8: (
                            <div>
                                <div className={styles.esgArticlePill}>
                                    <i className="ri-leaf-line" />
                                    <span>{t('pages-fund-details.article')} 8</span>
                                </div>
                                <p>{t('pages-fund-details.aboutArticle8')}</p>
                            </div>
                        ),
                        ARTICLE_9: (
                            <div>
                                <div className={styles.esgArticlePill}>
                                    <i className="ri-leaf-line" />
                                    <span>{t('pages-fund-details.article')} 9</span>
                                </div>
                                <p>{t('pages-fund-details.aboutArticle9')}</p>
                            </div>
                        ),
                    }[fund.esgCategory]
                }
                <div>
                    <p>{t('pages-fund-details.fundSustainabilityInfo')}</p>
                </div>
                {fund.esgLink && (
                    <div>
                        <p>{t('pages-fund-details.fundSustainabilityInfoLink')}</p>
                        <a className={styles.link} href={fund.esgLink} target="_blank" rel="noreferrer">
                            <span>{t('pages-fund-details.fundSustainabilityLink')}</span>
                        </a>
                    </div>
                )}
                {!fund.esgLink && fund.fundShareClasses[0].website && (
                    <div>
                        <a
                            className={styles.link}
                            href={fund.fundShareClasses[0].website}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>{t('pages-fund-details.goToWebsite')}</span>
                        </a>
                    </div>
                )}
            </div>
            <div className={styles.aboutESG}>
                <h4>{t('pages-fund-details.aboutEsgTitle')}</h4>
                <p>{t('pages-fund-details.aboutEsgDescriptionIntro')}</p>
                <p>{t('pages-fund-details.aboutEsgDescriptionPurpose')}</p>
                <p>{t('pages-fund-details.aboutEsgDescriptionArticle9')}</p>
                <p>{t('pages-fund-details.aboutEsgDescriptionArticle8')}</p>
                <p>{t('pages-fund-details.aboutEsgDescriptionArticle6')}</p>
            </div>
        </div>
    )
}
