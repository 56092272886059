import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '#state/useSelector'
import { LoadingSpinner } from '#components'
import { Alert, AlertTitle } from '@mui/material'

export default function RestrictedBankAccessInfo() {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)

    const bankLoaded = useSelector((state) => !!state.loaded.bank)

    useEffect(() => {
        if (bankLoaded) {
            setLoading(false)
        }

        let timeout
        if (loading) {
            timeout = setTimeout(() => setLoading(false), 5000)
        }
        return () => clearTimeout(timeout)
    }, [loading, bankLoaded])

    return loading ? (
        <LoadingSpinner />
    ) : (
        <Fragment>
            <Alert severity="warning">
                <AlertTitle>{t('components-RestrictedBankAccessInfo.info1')}</AlertTitle>
                {t('components-RestrictedBankAccessInfo.info2')}
            </Alert>
        </Fragment>
    )
}
