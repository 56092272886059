import { Fragment, useState } from 'react'
import styles from './UserFilterSelector.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from '#state/useSelector'
import { setMatchAllFilterFrequency, setUserFilterNotify } from '#services/thunks/user'
import SavedFilterDescription from '#blocks/UserFilterSelector/CustomUserFilterDescription'
import { useTracking } from 'react-tracking'
import { AdNotificationFrequency } from '@fixrate/fixrate-query'
import { FormControl, MenuItem, Select, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CustomNotificationDialog from '#blocks/UserFilterSelector/CustomNotificationDialog'
import { LoadingButton } from '@mui/lab'

type FilterOption = 'OFF' | 'CUSTOM' | AdNotificationFrequency

export default function UserFilterSelector() {
    const { t } = useTranslation()
    const session = useSelector((state) => state.session)
    const { trackEvent } = useTracking({ page: 'MarketplaceFilter', session })
    const dispatch = useDispatch()

    const [customNotificationDialogOpen, setCustomNotificationDialogOpen] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const profile = useSelector((state) => state.profile)
    const products = useSelector((state) => state.products)
    const productsLoaded = Object.keys(products).length > 0

    async function setNotification(option: FilterOption) {
        setSubmitting(true)
        switch (option) {
            case 'OFF': {
                if (profile?.matchAllUserFilter.notify) {
                    trackEvent({ event: 'toggleNotifications', notify: false })
                    await dispatch(setUserFilterNotify(profile.matchAllUserFilter.filterId, false))
                }
                if (profile?.customUserFilter.notify) {
                    trackEvent({ event: 'toggleNotifications', notify: false })
                    await dispatch(setUserFilterNotify(profile.customUserFilter.filterId, false))
                }
                break
            }
            case 'CUSTOM': {
                if (profile?.customUserFilter) {
                    trackEvent({ event: 'selectSavedUserFilter' })
                    await dispatch(setUserFilterNotify(profile.customUserFilter.filterId, true))
                    setCustomNotificationDialogOpen(true)
                }
                break
            }
            case 'WEEKLY':
            case 'DAILY':
            case 'REAL_TIME': {
                if (profile?.matchAllUserFilter) {
                    trackEvent({ event: 'selectMatchAllFilter', frequency: option })
                    await dispatch(setMatchAllFilterFrequency(profile.matchAllUserFilter.filterId, option))
                }
            }
        }
        setSubmitting(false)
    }

    const selectedFilter: FilterOption = profile?.customUserFilter.notify
        ? 'CUSTOM'
        : profile?.matchAllUserFilter.notify
          ? profile.matchAllUserFilter.frequency
          : 'OFF'

    return (
        <Stack spacing={0.5}>
            <Stack direction={'row'} spacing={0.5}>
                <FormControl>
                    <Select
                        sx={{
                            backgroundColor: '#fff',
                            maxHeight: '4.7rem',
                            width: '25rem',
                            '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                maxHeight: '100%',
                            },
                        }}
                        data-cy="notificationSelector"
                        data-status={selectedFilter === 'OFF' ? 'OFF' : 'ON'}
                        value={selectedFilter}
                        onChange={(e) => setNotification(e.target.value as FilterOption)}
                        disabled={submitting}
                    >
                        <MenuItem value="OFF" sx={{ gap: 0.5 }}>
                            {submitting && <i className="ri-loader-5-line fx-spin purple" />}
                            {!submitting && <i className="ri-notification-off-line red" />}
                            {t('pages-marketplace.turnedOff')}
                        </MenuItem>
                        <MenuItem value="REAL_TIME" sx={{ gap: 0.5 }}>
                            {submitting && <i className="ri-loader-5-line fx-spin purple" />}
                            {!submitting && <i className="ri-notification-line" />}
                            {t('pages-marketplace.realTimeNotification')}
                        </MenuItem>
                        <MenuItem value="DAILY" sx={{ gap: 0.5 }}>
                            {submitting && <i className="ri-loader-5-line fx-spin purple" />}
                            {!submitting && <i className="ri-notification-line" />}
                            {t('pages-marketplace.dailySummary')}
                        </MenuItem>
                        <MenuItem value="WEEKLY" sx={{ gap: 0.5 }}>
                            {submitting && <i className="ri-loader-5-line fx-spin purple" />}
                            {!submitting && <i className="ri-notification-line" />}
                            {t('pages-marketplace.weeklySummary')}
                        </MenuItem>
                        <MenuItem value="CUSTOM" sx={{ gap: 0.5 }}>
                            {submitting && <i className="ri-loader-5-line fx-spin purple" />}
                            {!submitting && <i className="ri-notification-line" />}
                            {t('pages-marketplace.customNotification')}
                        </MenuItem>
                    </Select>
                </FormControl>
                {selectedFilter === 'CUSTOM' && (
                    <LoadingButton
                        size={'small'}
                        variant={'text'}
                        onClick={() => setCustomNotificationDialogOpen(true)}
                        loading={!productsLoaded || profile === null}
                        disabled={submitting}
                    >
                        {t('pages-marketplace.edit')}
                    </LoadingButton>
                )}
            </Stack>
            {profile != null && (
                <Fragment>
                    {selectedFilter === 'CUSTOM' && (
                        <Tooltip title={<SavedFilterDescription customUserFilter={profile.customUserFilter} />}>
                            <p data-cy="customNotificationDescription" className={styles.filterDescription}>
                                <SavedFilterDescription customUserFilter={profile.customUserFilter} />
                            </p>
                        </Tooltip>
                    )}
                    {productsLoaded && (
                        <CustomNotificationDialog
                            open={customNotificationDialogOpen}
                            onClose={() => setCustomNotificationDialogOpen(false)}
                            customUserFilter={profile.customUserFilter}
                            products={products}
                        />
                    )}
                </Fragment>
            )}
        </Stack>
    )
}
