import hasInterestCalculation from '#app/services/hasInterestCalculation'
import { useAuthorization } from '#services/authorization'
import getDepositValue from '#services/getDepositValue'
import useTableSort, { Column } from '#services/useTableSort'
import { useSelector } from '#state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import { Box, Button, Typography } from '@mui/material'
import classNames from 'classnames'
import { addDays, endOfDay, isAfter } from 'date-fns'
import subDays from 'date-fns/subDays'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BankDeposit from './BankDeposit'
import styles from './DepositList.module.scss'

export type BankDepositRow = DepositDto & {
    task: string
    productName: string
    interestMargin: number | null
    depositorName: string
    balance: number
    deviationStatus: string
}

type Props = {
    variant: 'ACTIVE' | 'INACTIVE'
}

export default function DepositList({ variant }: Props) {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [showAllArchived, setShowAllArchived] = useState(false)

    const authorization = useAuthorization()
    const isCoordinator = authorization.bank.hasCoordinatorRole

    const deposits = useSelector((state) => state.deposits)
    const portfolioFilter = useSelector((state) => state.portfolioFilter)
    const filteredDeposits = deposits
        .filter((deposit) => (variant === 'ACTIVE' ? isActiveDeposit(deposit) : !isActiveDeposit(deposit)))
        .filter(
            (deposit) =>
                deposit && (!portfolioFilter.depositorId || deposit.depositor.id === portfolioFilter.depositorId)
        )
    const depositDeviations = useSelector((state) =>
        state.depositDeviations.filter((deviation) => deviation.state !== 'RESOLVED')
    )
    const selectedCurrency = useSelector((state) => state.session?.association?.currentCurrency)

    const depositRows: BankDepositRow[] = filteredDeposits.map((deposit) => {
        const deviationState = depositDeviations.find((deviation) => deviation.depositId === deposit.id)?.state
        return {
            ...deposit,
            task: '',
            productName: t('common.productShortName' + deposit.product?.id),
            interestMargin: deposit.product.type !== 'FIXED' ? deposit.nominalInterestRate : null,
            depositorName: deposit.depositor?.label ?? '',
            balance: getDepositValue(deposit),
            deviationStatus: deviationState ?? '',
        }
    })

    const columns: Column<BankDepositRow>[] = [
        { id: 'task', label: '', className: styles.depositList__headersTask },
        {
            id: 'productName',
            label: t('pages-portfolio-bank.headersProduct'),
            className: styles.depositList__headersProduct,
        },
        hasInterestCalculation(selectedCurrency) && {
            id: 'effectiveInterestRate',
            label: t('pages-portfolio-bank.headersInterest'),
            className: styles.depositList__headersInterest,
        },
        {
            id: 'interestMargin',
            label: t('pages-portfolio-bank.headersMargin'),
            className: styles.depositList__headersInterest,
        },
        {
            id: 'depositorName',
            label: t('pages-portfolio-bank.headersCustomer'),
            className: styles.depositList__headersCustomer,
        },
        {
            id: 'account',
            label: t('pages-portfolio-bank.headersAccountNumber'),
            className: styles.depositList__headersAccountNumber,
        },
        isCoordinator && {
            id: 'deviationStatus',
            label: t('pages-portfolio-bank.headersDeviationStatus'),
            className: styles.depositList__headersDeviationStatus,
        },
        hasInterestCalculation(selectedCurrency)
            ? {
                  id: 'balance',
                  label: t('pages-portfolio-bank.headersVolume'),
                  className: styles.depositList__headersVolume,
              }
            : {
                  id: 'actualStartVolume',
                  label: t('pages-portfolio-bank.startVolume'),
                  className: styles.depositList__headersVolume,
              },
        {
            id: 'terminationDate',
            label: t('pages-portfolio-bank.headersTerminationDate'),
            className: styles.depositList__headersExpires,
        },
    ]

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<BankDepositRow>(
        depositRows,
        'terminationDate',
        variant === 'ACTIVE' ? 'asc' : 'desc',
        'created',
        'desc'
    )

    const visibleRows = sortedRows.filter((row) => variant === 'ACTIVE' || showAllArchived || !isOver180DaysOld(row))

    function onSelectDeposit(id: string) {
        navigate(`/portfolio-bank/${id}`)
    }

    return (
        <Box sx={{ mb: 2 }}>
            {filteredDeposits.length === 0 && (
                <Typography fontSize={'1.8rem'} variant="emptyState">
                    {t('pages-portfolio-bank.noDeposits')}
                </Typography>
            )}
            {filteredDeposits.length > 0 && (
                <ul className={styles.depositList__headers}>
                    {columns.map((column) => {
                        const sortedStyle =
                            column.id !== sortKey
                                ? undefined
                                : sortDirection === 'asc'
                                  ? styles.activeSortUp
                                  : styles.activeSortDown
                        return (
                            <li
                                key={column.id}
                                className={classNames(
                                    column.className,
                                    column.sortable !== false && styles.sortable,
                                    sortedStyle
                                )}
                                onClick={() => setSorting(column.id)}
                            >
                                {column.label}
                            </li>
                        )
                    })}
                </ul>
            )}
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <ul>
                    {visibleRows.map((row) => (
                        <BankDeposit
                            key={row.id}
                            bankDepositRow={row}
                            onSelectDeposit={onSelectDeposit}
                            archived={!isActiveDeposit(row)}
                        />
                    ))}
                </ul>
            </Box>
            {variant === 'INACTIVE' && hasOldDeposits(filteredDeposits) && !showAllArchived && (
                <Button variant="outlined" onClick={() => setShowAllArchived(true)}>
                    {t('pages-portfolio-bank.showOlder')}
                </Button>
            )}
        </Box>
    )
}

function isActiveDeposit(deposit: DepositDto): boolean {
    return !deposit.terminationDate || isAfter(addDays(endOfDay(new Date(deposit.terminationDate)), 3), new Date())
}

function hasOldDeposits(deposits: DepositDto[]): boolean {
    return deposits.some(isOver180DaysOld)
}

export function isOver180DaysOld(deposit: DepositDto): boolean {
    return deposit.terminationDate ? isAfter(subDays(new Date(), 180), new Date(deposit.terminationDate)) : false
}
