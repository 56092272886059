import { BURNT_ORANGE, PURPLE, SUCCESS_GREEN } from '#app/colors/colors'
import { InterestOutput, LinkButton, PageHeader, PageLayout } from '#app/components'
import { getOrganisationSelectorOptions, OrganisationSelectorOption } from '#app/components/PageHeader/PageHeader'
import { SwitchOrgAnimation, SWITCH_ORG_DURATION } from '#app/components/SwitchOrgAnimation/SwitchOrgAnimation'
import SuccessIcon from '#app/layers/ConfirmationModal/assets/SuccessIcon.svg?url'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'
import { switchOrganisation } from '#app/services/thunks/session'
import { useSelector } from '#app/state/useSelector'
import { useOnboardingWizardContent, useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import Confetti from '#components/Confetti/Confetti'
import useUiSetting from '#services/useUiSetting'
import * as selectors from '#state/selectors'
import { ArrowForward } from '@mui/icons-material'
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    Drawer,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Skeleton,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import BeforeRegistration from './BeforeRegistration'
import ConfettiIllustration from './Confetti.svg?url'
import Tada from './illustrations/tada.svg'
import styles from './OrganizationWizard.module.scss'
import Philip from './philip-portrait.jpg'
import WizardContent from './WizardContent'

type StepName =
    | 'start'
    | 'terms'
    | 'identificationDocument'
    | 'board'
    | 'accounts'
    | 'customerDeclaration'
    | 'owners'
    | 'orderProcess'
    | 'customerDeclarationInfo'
    | 'signingRights'
    | 'powerOfAttorney'
    | 'invitations'
    | 'status'
    | 'partnerSendOffer'

export type WizardStep = {
    name: StepName
    done: boolean
    visible: boolean
    content: ReactElement | null
    icon: string | null
    supportarticleId?: SupportCategoryNameType | null
}

type Params = {
    step?: StepName | string
}

export const OrganizationWizard = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { step } = useParams<Params>()
    const theme = useTheme()
    const association = useSelector((state) => state.session?.association)
    const associations = useSelector((state) => state.session?.associations)
    const organisationSelectorOptions = getOrganisationSelectorOptions(associations, association)
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
    const partnerId = useSelector(
        (state) => state.session.associations?.find((a) => a.organisationType === 'PARTNER')?.organisation?.id
    )

    const [partnerSwitchAnimation, setPartnerSwitchAnimation] = useState(false)
    const [prevOrg, setPrevOrg] = useState<OrganisationSelectorOption | null>(null)
    const [newOrg, setNewOrg] = useState<OrganisationSelectorOption | null>()
    const [visibleErrorSteps, setVisibleErrorSteps] = useState<Array<number>>([])
    const [weHelpYouModal, setWeHelpYouModal] = useState(false)

    const [lastSavedProgress, setLastSavedProgress] = useState(0)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [hideSuccessModalForEver, setHideSuccessModalForEver] = useUiSetting('hideSuccessModalForEver', false)

    const deposits = useSelector((state) => state.deposits)
    const selectedDepositor = useSelector((state) => state.depositor)
    const orders = useSelector((state) => state.orders)
    const userId = useSelector((state) => state.session?.id)
    const [finishedSteps, setFinishedSteps] = useUiSetting(
        'onboardingWizardStep',
        selectedDepositor ? { [selectedDepositor.id]: 0 } : {}
    )
    const finishedStepForDepositor = finishedSteps[selectedDepositor?.id] ?? 0

    const onboardingWizardStatus = useOnboardingWizardStatus(selectedDepositor)
    const wizardContent = useOnboardingWizardContent(selectedDepositor)

    const steps = onboardingWizardStatus?.steps
        .map((step, index) => ({ ...step, index }))
        .map((step) => ({ ...step, content: wizardContent?.[step.name] }))
    const initialStep = steps.find((s) => s.name === step)
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStep?.index ?? 0)
    const currentStep = steps.find((s) => s.index === currentStepIndex) ?? steps.at(0)
    const currentProgress = onboardingWizardStatus.progress
    const isPartnerAccountant = selectedDepositor?.users
        .find((u) => u.id === userId)
        ?.roles.find((r) => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')

    useEffect(() => {
        if (currentProgress === 100 && lastSavedProgress !== 100 && lastSavedProgress !== 0 && !partnerId) {
            setShowSuccessDialog(!hideSuccessModalForEver)
            setHideSuccessModalForEver(true)
        } else if (lastSavedProgress === 100) {
            setHideSuccessModalForEver(true)
        }
        setLastSavedProgress(currentProgress)
    }, [currentProgress, hideSuccessModalForEver, lastSavedProgress, setHideSuccessModalForEver, partnerId])

    function changeStep(index: number) {
        setCurrentStepIndex(index)
        if (index > finishedStepForDepositor) {
            setFinishedSteps({ ...finishedSteps, [selectedDepositor?.id]: index })
        }
        navigate('/organizations/onboarding/' + selectedDepositor.id + '/' + steps.find((s) => s.index === index)?.name)
    }

    function navigateToPartner(url: string) {
        setPartnerSwitchAnimation(true)
        setNewOrg(organisationSelectorOptions.find((o) => o.organisationId === partnerId))
        setPrevOrg(organisationSelectorOptions.find((o) => o.selected))
        setTimeout(() => {
            dispatch(switchOrganisation('PARTNER', partnerId, () => navigate(url)))
            setPartnerSwitchAnimation(false)
            setPrevOrg(null)
            setNewOrg(null)
        }, SWITCH_ORG_DURATION)
    }

    function goToNext(index: number) {
        if (currentStep.done !== true) {
            if (!visibleErrorSteps.includes(currentStepIndex)) {
                setVisibleErrorSteps((prev) => [...prev, currentStepIndex])
            }
        } else {
            changeStep(index)
        }
    }

    function stepIsDisabled(index: number) {
        if (
            steps[index].name === 'customerDeclaration' &&
            steps.find((s) => s.name === 'customerDeclarationInfo')?.done !== true
        ) {
            return true
        }

        if (finishedStepForDepositor > index - 1) {
            return false
        }

        if (currentStep.done === true && index - 1 === currentStep.index) {
            return false
        }

        if (finishedStepForDepositor < index - 1) {
            return true
        }

        if (steps[index - 1].done === true) {
            return false
        }

        return true
    }

    return selectedDepositor ? (
        <>
            {showSuccessDialog && <Confetti />}
            <PageHeader icon={'ri-building-line'} title={t('pages-OrganizationWizard.getStarted')}></PageHeader>
            <PageLayout
                noStyles={true}
                sx={{
                    height: '100%',
                    py: 0,
                    px: 0,
                    width: '100%',
                }}
            >
                {selectedDepositor?.depositorSupportCategory === 'UNKNOWN' && <BeforeRegistration />}
                <Stack height={{ xs: 'calc(100% - 6.3rem)', md: '100%' }} direction="row">
                    <Stack overflow={'auto'} height="100%" width={{ xs: '100%', lg: 'calc(100% - 40rem)' }}>
                        <Stack
                            width="100%"
                            sx={{
                                py: { xs: 0, md: 2, lg: 4.8 },
                                px: { xs: 0, md: 3, lg: 6.4 },
                            }}
                        >
                            {currentProgress === 100 && deposits.length === 0 && orders.length === 0 && (
                                <Alert
                                    sx={{
                                        width: '100%',
                                        marginBottom: '2rem',
                                        boxShadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.2)',
                                    }}
                                    severity="success"
                                    action={
                                        <Button
                                            onClick={() =>
                                                isPartnerAccountant
                                                    ? navigateToPartner('/marketplace/category/deposits/under-20')
                                                    : navigate('/marketplace')
                                            }
                                            color="inherit"
                                            size="small"
                                            variant={'outlined'}
                                            endIcon={<ArrowForward />}
                                        >
                                            {isPartnerAccountant
                                                ? t('pages-OrganizationWizard.findOffers')
                                                : t('pages-OrganizationWizard.goToMarketplace')}
                                        </Button>
                                    }
                                >
                                    {isPartnerAccountant
                                        ? t('pages-OrganizationWizard.accountantReadyMessage')
                                        : t('pages-OrganizationWizard.readyForFirstOrder')}
                                </Alert>
                            )}
                            <WizardContent
                                step={currentStep}
                                visibleError={visibleErrorSteps.includes(currentStepIndex) && !currentStep.done}
                                hideVisibleError={() =>
                                    setVisibleErrorSteps((prev) => prev.filter((i) => i !== currentStepIndex))
                                }
                                loaded={!!onboardingWizardStatus?.loaded}
                            />
                            {onboardingWizardStatus?.loaded && (
                                <Stack
                                    direction="row"
                                    mt={{
                                        xs: 0,
                                        md: 3,
                                    }}
                                    sx={{
                                        borderTop: {
                                            xs: '0.1rem solid rgba(0,0,0,0.1)',
                                            md: 'none',
                                        },
                                        position: {
                                            xs: 'absolute',
                                            md: 'relative',
                                        },
                                        bottom: {
                                            xs: 0,
                                        },
                                        py: {
                                            xs: 1.4,
                                            md: 0,
                                        },
                                        px: {
                                            xs: 2,
                                            md: 0,
                                        },
                                        width: '100%',
                                        backgroundColor: { xs: 'white', md: 'transparent' },
                                    }}
                                >
                                    {currentStepIndex !== 1 && (
                                        <Button
                                            data-cy="previous-step-button"
                                            sx={{ fontSize: isMobile ? '1.4rem' : null }}
                                            startIcon={<i className="ri-arrow-left-line" />}
                                            variant={'outlined'}
                                            size={isMobile ? 'small' : 'medium'}
                                            onClick={() => changeStep(Math.max(1, currentStepIndex - 1))}
                                        >
                                            {t('pages-OrganizationWizard.previousStep')}
                                        </Button>
                                    )}
                                    {currentStepIndex !== steps.length - 1 && (
                                        <Button
                                            data-cy="next-step-button"
                                            endIcon={<i className="ri-arrow-right-line" />}
                                            sx={{
                                                marginLeft: 'auto',
                                                fontSize: isMobile ? '1.4rem' : null,
                                            }}
                                            variant={'contained'}
                                            size={isMobile ? 'small' : 'medium'}
                                            disabled={!onboardingWizardStatus?.loaded}
                                            onClick={() => goToNext(currentStepIndex + 1)}
                                        >
                                            {t('pages-OrganizationWizard.nextStep')}
                                        </Button>
                                    )}
                                    {currentStepIndex === steps.length - 1 && !isPartnerAccountant && (
                                        <Button
                                            disabled={currentProgress !== 100}
                                            endIcon={<i className="ri-arrow-right-line" />}
                                            sx={{
                                                marginLeft: 'auto',
                                                fontSize: isMobile ? '1.4rem' : null,
                                            }}
                                            variant={'contained'}
                                            size={isTablet ? 'small' : 'medium'}
                                            onClick={() => navigate('/marketplace')}
                                        >
                                            {t('pages-OrganizationWizard.goToMarketplace')}
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                    <Drawer
                        variant={isTablet ? 'temporary' : 'permanent'}
                        anchor="right"
                        open={isTablet ? false : undefined}
                        sx={{
                            width: '40rem',
                            position: 'relative',
                            '& .MuiDrawer-paper': {
                                top: '0',
                                right: '0',
                                height: '100%',
                                width: '100%',
                                zIndex: 1,
                                position: 'absolute',
                            },
                        }}
                    >
                        <Stack>
                            <Divider />
                            <Stack p={3}>
                                <Typography variant="h3">
                                    {onboardingWizardStatus?.progress < 100
                                        ? t('menu.finishRegistration')
                                        : t('menu.registrationFinished')}
                                </Typography>
                                <Stack mt={1}>
                                    {onboardingWizardStatus?.loaded ? (
                                        <Box>
                                            <Typography variant="caption" fontWeight="700" color={PURPLE[500]}>
                                                {InterestOutput.formatWithDecimals(currentProgress, 0)}{' '}
                                                {t('pages-OrganizationWizard.statusFinished')}
                                            </Typography>
                                            <LinearProgress
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '0.6rem',
                                                    height: '0.8rem',
                                                    borderRadius: '10rem',
                                                }}
                                                variant={'determinate'}
                                                value={currentProgress}
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Skeleton
                                                variant="text"
                                                width={'8rem'}
                                                sx={{ backgroundColor: PURPLE[300] }}
                                            />
                                            <Skeleton
                                                variant="text"
                                                width={'100%'}
                                                height={15}
                                                sx={{ backgroundColor: PURPLE[50] }}
                                            />
                                        </Box>
                                    )}
                                </Stack>
                            </Stack>
                            <Divider />
                            <List sx={{ pt: 0 }} data-cy="wizardSteps">
                                {steps.map((step) =>
                                    onboardingWizardStatus?.loaded ? (
                                        <Box key={step.name} component="li">
                                            <ListItemButton
                                                sx={{ px: 3, py: 2, mt: 0 }}
                                                disabled={stepIsDisabled(step.index)}
                                                onClick={() => changeStep(step.index)}
                                                selected={currentStepIndex === step.index}
                                                data-cy={'wizardStep'}
                                                data-cy-name={step.name}
                                                data-cy-done={step.done && !stepIsDisabled(step.index)}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minHeight: '2.8rem',
                                                        minWidth: '2.8rem',
                                                        fontSize: '1.8rem',
                                                        backgroundColor:
                                                            finishedStepForDepositor >= step.index && step.done
                                                                ? SUCCESS_GREEN[500]
                                                                : step.index === currentStepIndex
                                                                  ? PURPLE[500]
                                                                  : finishedStepForDepositor > step.index && !step.done
                                                                    ? BURNT_ORANGE[500]
                                                                    : PURPLE[50],
                                                    }}
                                                >
                                                    {finishedStepForDepositor >= step.index && step.done ? (
                                                        <i className="ri-check-line" style={{ color: 'white' }} />
                                                    ) : finishedStepForDepositor > step.index &&
                                                      !step.done &&
                                                      step.index !== currentStepIndex ? (
                                                        <i className="ri-information-line" style={{ color: 'white' }} />
                                                    ) : (
                                                        <Typography
                                                            color={
                                                                step.index === currentStepIndex ? 'white' : PURPLE[900]
                                                            }
                                                            fontWeight={700}
                                                        >
                                                            {step.index + 1}
                                                        </Typography>
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: '1.4rem',
                                                        },
                                                    }}
                                                >
                                                    {t('pages-OrganizationWizard.' + step.name)}
                                                </ListItemText>
                                            </ListItemButton>
                                            <Divider />
                                        </Box>
                                    ) : (
                                        <Fragment key={step.index}>
                                            <ListItemButton sx={{ px: 3, py: 1.9, mt: 0 }} selected={false}>
                                                <ListItemIcon
                                                    sx={{
                                                        minHeight: '2.8rem',
                                                        minWidth: '2.8rem',
                                                    }}
                                                >
                                                    <Skeleton variant="circular" width={28} height={28} />
                                                </ListItemIcon>
                                                <ListItemText>
                                                    <Skeleton variant="text" width={200} />
                                                </ListItemText>
                                            </ListItemButton>
                                            <Divider component={'li'} />
                                        </Fragment>
                                    )
                                )}
                            </List>
                        </Stack>
                    </Drawer>
                </Stack>
                {partnerSwitchAnimation && <SwitchOrgAnimation prevOrg={prevOrg} newOrg={newOrg} />}
                <Dialog
                    maxWidth={'xs'}
                    fullWidth={true}
                    onClose={() => setShowSuccessDialog(false)}
                    open={showSuccessDialog}
                >
                    <DialogContent
                        sx={{
                            backgroundImage: 'url(' + ConfettiIllustration + '), url(' + SuccessIcon + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center -3rem, center 7rem',
                            padding: '5rem 4rem',
                            textAlign: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div className={styles.successModalContent}>
                            <h1>{t('pages-OrganizationWizard.registrationComplete')}</h1>
                            <p>{t('pages-OrganizationWizard.readyForFirstOrder')}</p>
                        </div>
                        <LinkButton data-cy="goToMarketplaceButton" to={'/marketplace'}>
                            {t('pages-OrganizationWizard.goToMarketplace')}
                        </LinkButton>
                        <Button
                            onClick={() => setShowSuccessDialog(false)}
                            sx={{ marginTop: '1rem' }}
                            fullWidth={true}
                            variant={'text'}
                        >
                            {t('pages-OrganizationWizard.close')}
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog open={weHelpYouModal} maxWidth={'xs'} fullScreen={isTablet}>
                    <img src={Philip} style={{ maxWidth: '100%' }} alt={''} />
                    <DialogContent
                        sx={{
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            padding: { lg: '4rem' },
                            textAlign: 'center',
                        }}
                    >
                        <h1>{t('pages-OrganizationWizard.happyToHelp')}</h1>
                        <p className="gray">{t('pages-OrganizationWizard.helpDescription')}</p>
                        <p className={styles.contactLine}>
                            <span>
                                <i className="ri-mail-line" />
                                <span>post@fixrate.no</span>
                            </span>
                            <span>
                                <i className="ri-phone-line" />
                                <span>+47 412 51 918</span>
                            </span>
                        </p>
                        <Button
                            data-cy="continueToRegistrationButton"
                            fullWidth={true}
                            variant={'contained'}
                            onClick={() => window.location.reload()}
                        >
                            {t('pages-OrganizationWizard.continueToRegistration')}
                        </Button>
                    </DialogContent>
                </Dialog>
            </PageLayout>
        </>
    ) : (
        <PageLayout>
            <Typography variant="emptyState">{t('pages-OrganizationWizard.noDepositor')}</Typography>
        </PageLayout>
    )
}
