import usePortfolio from '#services/usePortfolio'
import useShoppingCart from '#services/useShoppingCart'
import useFundShareClassSelected from '#services/useFundShareClassSelected'
import { useSelector } from '#state/useSelector'
import { useOrderAmountLimitStatus } from '#services/funds/calculateOrderLimitStatus'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'
import { useTranslation } from 'react-i18next'
import { FundDto } from '@fixrate/fixrate-query'
import { Alert, Button } from '@mui/material'

export function OrderAmountLimitInfo({ fund }: { fund: FundDto }) {
    const { t } = useTranslation()
    const portfolio = usePortfolio()
    const shoppingCart = useShoppingCart<FundDto>('FUNDS')
    const [fundClassId, setFundClassId] = useFundShareClassSelected(fund.id, fund.fundShareClasses[0]?.id)
    const currentPlacement = useSelector((state) => state.fundPlacements?.find((fp) => fp.fundId === fund?.id))
    const minOrderVolume = useSelector(
        (state) => state.depositor?.portfolios.find((p) => p.id === portfolio?.id)?.fundData?.minOrderVolume
    )
    const shoppingCartItem = shoppingCart?.getItemById(fund.id)
    const orderAmount = shoppingCartItem?.amount
    const shareClass = fund.fundShareClasses.find((fc) => fc.id === fundClassId)
    const otherShareClasses = fund.fundShareClasses.filter((sc) => sc.id !== fundClassId)
    const orderStatus = useOrderAmountLimitStatus({
        orderVolume: orderAmount,
        minOrderVolume: minOrderVolume,
        currentPlacement: currentPlacement,
        selectedShareClass: shareClass,
        otherShareClasses: otherShareClasses,
    })

    const handleFundClassIdChange = (value: string) => {
        setFundClassId(value)
        if (shoppingCart.getItemById(fund.id)) {
            shoppingCart.updateItemSubId(fund.id, value)
        }
    }

    if (!shoppingCart || !portfolio || !shoppingCartItem || !shareClass) {
        return null
    }
    const existingPlacementShareClassMismatch = orderStatus.existingPlacementShareClassMismatch
    const otherShareClassMatching = orderStatus.otherShareClassMatching
    if (existingPlacementShareClassMismatch) {
        return (
            <Alert
                action={
                    otherShareClassMatching ? (
                        <Button
                            sx={{ whiteSpace: 'nowrap', fontSize: '1.2rem', mr: '1rem' }}
                            variant="outlined"
                            onClick={() => handleFundClassIdChange(otherShareClassMatching.id)}
                            color="inherit"
                            size="small"
                            data-cy={'changeShareClassButton'}
                        >
                            {t('components-ShoppingCart.changeShareClassButton')}
                        </Button>
                    ) : null
                }
                data-cy={'existingPlacementShareClassMismatchMsg'}
                severity="warning"
                color="info"
                sx={{ width: '100%', fontSize: '1.2rem' }}
            >
                <span>
                    {t('components-ShoppingCart.existingPlacementShareClassMismatch', {
                        currentShareClass: otherShareClassMatching.name,
                    })}
                </span>
            </Alert>
        )
    }
    if (orderAmount === null || orderAmount === undefined) {
        return null
    }
    const existingPlacementHigherShareClass = orderStatus.existingPlacementHigherShareClass
    if (orderStatus.orderIsValid === true) {
        if (existingPlacementHigherShareClass != null) {
            return (
                <Alert
                    data-cy={'existingPlacementHigherShareClassMsg'}
                    severity="info"
                    color="info"
                    sx={{ width: '100%', fontSize: '1.2rem' }}
                >
                    <span>
                        {t('components-ShoppingCart.existingPlacementHigherShareClass', {
                            higherShareClassMinAmount: (
                                existingPlacementHigherShareClass.minimumInvestment / 1000000
                            ).toFixed(0),
                            currentShareClass: shareClass.name,
                            higherShareClass: existingPlacementHigherShareClass.name,
                        })}
                    </span>
                </Alert>
            )
        }
        return null
    }

    const withinMinOrderVolume = orderStatus.withinMinOrderVolume
    const withinMinInvestment = orderStatus.withinMinInvestment
    const withinMaxInvestment = orderStatus.withinMaxInvestment
    const minInvestment = shareClass.minimumInvestment
    const maxInvestment = shareClass.maximumInvestment

    const showExistingPlacementHigherShareClassMsg = existingPlacementHigherShareClass != null
    const showMinimumOrderVolumeMsg = !withinMinOrderVolume
    const showShareClassLimitRangeMsg = withinMinOrderVolume && !withinMinInvestment && maxInvestment != null
    const showShareClassMinimumMsg = withinMinOrderVolume && !withinMinInvestment && maxInvestment == null
    const showMaximumInvestmentMsg = !withinMaxInvestment && otherShareClassMatching == null
    const showChangeShareClassMsg = otherShareClassMatching != null && otherShareClassMatching.maximumInvestment != null
    const showChangeShareClassNoMaxMsg =
        otherShareClassMatching != null && otherShareClassMatching.maximumInvestment == null
    const dataCy = showExistingPlacementHigherShareClassMsg
        ? 'existingPlacementHigherShareClassMsg'
        : showMinimumOrderVolumeMsg
          ? 'minOrderErrMsg'
          : showShareClassLimitRangeMsg
            ? 'limitRangeErrMsg'
            : showShareClassMinimumMsg
              ? 'minInvestmentErrMsg'
              : showMaximumInvestmentMsg
                ? 'maxInvestmentErrMsg'
                : showChangeShareClassMsg
                  ? 'changeShareClassMsg'
                  : showChangeShareClassNoMaxMsg
                    ? 'changeShareClassNoMaxMsg'
                    : null

    return (
        <Alert
            action={
                otherShareClassMatching ? (
                    <Button
                        sx={{ whiteSpace: 'nowrap', fontSize: '1.2rem', mr: '1rem' }}
                        variant="outlined"
                        onClick={() => handleFundClassIdChange(otherShareClassMatching.id)}
                        color="inherit"
                        size="small"
                        data-cy={'changeShareClassButton'}
                    >
                        {t('components-ShoppingCart.changeShareClassButton')}
                    </Button>
                ) : null
            }
            data-cy={dataCy}
            severity="warning"
            color="info"
            sx={{ width: '100%', fontSize: '1.2rem' }}
        >
            {showMinimumOrderVolumeMsg && (
                <span>
                    {t('components-ShoppingCart.minimumOrderVolume', {
                        minAmount: CurrencyOutputObject(minOrderVolume),
                    })}
                </span>
            )}
            {showShareClassLimitRangeMsg && (
                <span>
                    {t('components-ShoppingCart.shareClassLimitRange', {
                        from: (minInvestment / 1000000).toFixed(0),
                        to: (maxInvestment / 1000000).toFixed(0),
                    })}
                </span>
            )}
            {showShareClassMinimumMsg && (
                <span>
                    {t('components-ShoppingCart.shareClassMinimumAmount', {
                        minAmount: (minInvestment / 1000000).toFixed(0),
                    })}
                </span>
            )}
            {showMaximumInvestmentMsg && (
                <span>
                    {t('components-ShoppingCart.shareClassMaximumAmount', {
                        maxAmount: (maxInvestment / 1000000).toFixed(0),
                    })}
                </span>
            )}
            {showChangeShareClassMsg && (
                <span>
                    {t('components-ShoppingCart.changeShareClass', {
                        shareName: otherShareClassMatching.name,
                        minAmount: (otherShareClassMatching.minimumInvestment / 1000000).toFixed(0),
                        maxAmount: (otherShareClassMatching.maximumInvestment / 1000000).toFixed(0),
                    })}
                </span>
            )}
            {showChangeShareClassNoMaxMsg && (
                <span>
                    {t('components-ShoppingCart.changeShareClassOverLimit', {
                        minAmount: (otherShareClassMatching.minimumInvestment / 1000000).toFixed(0),
                    })}
                </span>
            )}
            {showExistingPlacementHigherShareClassMsg && (
                <span>
                    {t('components-ShoppingCart.existingPlacementHigherShareClass', {
                        higherShareClassMinAmount: (
                            existingPlacementHigherShareClass.minimumInvestment / 1000000
                        ).toFixed(0),
                        currentShareClass: shareClass.name,
                        higherShareClass: existingPlacementHigherShareClass.name,
                    })}
                </span>
            )}
        </Alert>
    )
}
