import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

type BackLinkProps = {
    fallback: string
    text?: string
}

const BackLink = ({ fallback, text }: BackLinkProps) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <Box mb={2}>
            <Button
                href={fallback}
                startIcon={<i className="ri-arrow-left-line" />}
                onClick={() => {
                    if (location.key) {
                        navigate(-1)
                    } else {
                        navigate(fallback)
                    }
                }}
            >
                {text ?? t('pages-organizations.goBack')}
            </Button>
        </Box>
    )
}

export default BackLink
