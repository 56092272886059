import { Suspense } from 'react'
import Theme from '#pages/Theme'
import AppLoadingPage from './AppLoadingPage/AppLoadingPage'
import Layout from './Layout'
import { GlobalEffects } from '#pages/GlobalEffects'
import { Overlay } from '#pages/Overlay'
import { SuspenseLoader } from '#app/layers/SuspenseLoader/SuspenseLoader'

const Root = () => {
    return (
        <Theme>
            <GlobalEffects>
                <AppLoadingPage>
                    <Suspense fallback={SuspenseLoader}>
                        <Layout />
                        <Overlay />
                    </Suspense>
                </AppLoadingPage>
            </GlobalEffects>
        </Theme>
    )
}

export default Root
