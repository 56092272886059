import { DateOutput } from '#app/components'
import styles from './OrderGroupProgressBar.module.scss'
import classNames from 'classnames'
import { DateAsString, FundBuyOrderState, FundSellOrderState } from '@fixrate/fixrate-query'
import { FundOrderState } from '#pages/portfolio-depositor/FundPlacements/FundOrders'
import { useTranslation } from '#app/components/i18n'

type Props = {
    className?: string
    orderType: 'BUY' | 'SELL'
    groupState: FundOrderState
    created: DateAsString
    stateHistory: { [P in FundBuyOrderState]?: DateAsString }
}

type BuyOrderGroupProgressBarProps = {
    className?: string
    groupState: FundBuyOrderState
    created: DateAsString
    stateHistory: { [P in FundBuyOrderState]?: DateAsString }
}
export function BuyOrderGroupProgressBar({ className, groupState, stateHistory }: BuyOrderGroupProgressBarProps) {
    const { t } = useTranslation('components-OrderGroupProgressBar')

    function getStateCompletionTime(state: FundBuyOrderState) {
        let completionTime = undefined
        switch (state) {
            case 'AWAITING_SIGNATURE':
                completionTime =
                    stateHistory['AWAITING_PAYMENT'] ??
                    stateHistory['AWAITING_PAYMENT_CONFIRMATION'] ??
                    stateHistory['IN_PROGRESS'] ??
                    stateHistory['EXECUTING_IN_MARKET'] ??
                    stateHistory['COMPLETED']
                break
            case 'AWAITING_PAYMENT':
                completionTime =
                    stateHistory['AWAITING_PAYMENT_CONFIRMATION'] ??
                    stateHistory['IN_PROGRESS'] ??
                    stateHistory['EXECUTING_IN_MARKET'] ??
                    stateHistory['COMPLETED']
                break
            case 'AWAITING_PAYMENT_CONFIRMATION':
                completionTime =
                    stateHistory['IN_PROGRESS'] ?? stateHistory['EXECUTING_IN_MARKET'] ?? stateHistory['COMPLETED']
                break
            case 'IN_PROGRESS':
                completionTime = stateHistory['EXECUTING_IN_MARKET'] ?? stateHistory['COMPLETED']
                break
            case 'EXECUTING_IN_MARKET':
            case 'COMPLETED':
                completionTime = stateHistory['COMPLETED']
                break
            default:
                break
        }
        return completionTime ? DateOutput.formatDateTime(completionTime) : ''
    }
    return (
        <div className={classNames(className, styles.progress)}>
            <ul className={styles.progressBar}>
                <li className={groupState === 'AWAITING_SIGNATURE' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'AWAITING_SIGNATURE' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('AWAITING_SIGNATURE') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {groupState === 'AWAITING_SIGNATURE' ? 'Mangler signatur' : t('orderRegistered')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('AWAITING_SIGNATURE')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'AWAITING_PAYMENT' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'AWAITING_PAYMENT' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('AWAITING_PAYMENT') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {groupState === 'AWAITING_PAYMENT' ? t('awaitingPayment') : t('paymentDone')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('AWAITING_PAYMENT')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'AWAITING_PAYMENT_CONFIRMATION' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'AWAITING_PAYMENT_CONFIRMATION' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('AWAITING_PAYMENT_CONFIRMATION') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {groupState === 'AWAITING_PAYMENT_CONFIRMATION'
                                    ? t('awaitingPaymentConfirmation')
                                    : t('paymentComplete')}
                            </span>
                            <span className={styles.description}>
                                {getStateCompletionTime('AWAITING_PAYMENT_CONFIRMATION')}
                            </span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'IN_PROGRESS' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'IN_PROGRESS' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('IN_PROGRESS') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('IN_PROGRESS') !== ''
                                    ? t('orderFinishedProcessed')
                                    : t('orderInProgress')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('IN_PROGRESS')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'EXECUTING_IN_MARKET' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'EXECUTING_IN_MARKET' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('EXECUTING_IN_MARKET') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('EXECUTING_IN_MARKET') !== ''
                                    ? t('executedInMarket')
                                    : t('executingInMarket')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('EXECUTING_IN_MARKET')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'COMPLETED' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {getStateCompletionTime('COMPLETED') !== '' && <i className="ri-checkbox-circle-line white" />}
                        <div>
                            <span className={styles.title}>{t('orderComplete')}</span>
                            <span className={styles.description}>{getStateCompletionTime('COMPLETED')}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

type SellOrderGroupProgressBarProps = {
    className?: string
    groupState: FundSellOrderState
    created: DateAsString
    stateHistory: { [P in FundSellOrderState]?: DateAsString }
}
export function SellOrderGroupProgressBar({ className, groupState, stateHistory }: SellOrderGroupProgressBarProps) {
    const { t } = useTranslation('components-OrderGroupProgressBar')

    function getStateCompletionTime(state: FundSellOrderState) {
        let completionTime = undefined
        switch (state) {
            case 'AWAITING_SIGNATURE':
                completionTime =
                    stateHistory['IN_PROGRESS'] ??
                    stateHistory['EXECUTING_IN_MARKET'] ??
                    stateHistory['TRANSFERRING_MONEY'] ??
                    stateHistory['COMPLETED']
                break
            case 'IN_PROGRESS':
                completionTime =
                    stateHistory['EXECUTING_IN_MARKET'] ??
                    stateHistory['TRANSFERRING_MONEY'] ??
                    stateHistory['COMPLETED']
                break
            case 'EXECUTING_IN_MARKET':
                completionTime = stateHistory['TRANSFERRING_MONEY'] ?? stateHistory['COMPLETED']
                break
            case 'TRANSFERRING_MONEY':
            case 'COMPLETED':
                completionTime = stateHistory['COMPLETED']
                break
            default:
                break
        }
        return completionTime ? DateOutput.formatDateTime(completionTime) : ''
    }

    return (
        <div className={classNames(className, styles.progress)}>
            <ul className={styles.progressBar}>
                <li className={groupState === 'AWAITING_SIGNATURE' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'AWAITING_SIGNATURE' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('AWAITING_SIGNATURE') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('AWAITING_SIGNATURE') !== ''
                                    ? t('orderRegistered')
                                    : t('awaitingSignature')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('AWAITING_SIGNATURE')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'IN_PROGRESS' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'IN_PROGRESS' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('IN_PROGRESS') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('IN_PROGRESS') !== ''
                                    ? t('orderFinishedProcessed')
                                    : t('orderInProgress')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('IN_PROGRESS')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'EXECUTING_IN_MARKET' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'EXECUTING_IN_MARKET' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('EXECUTING_IN_MARKET') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('EXECUTING_IN_MARKET') !== ''
                                    ? t('executedInMarket')
                                    : t('executingInMarket')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('EXECUTING_IN_MARKET')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'TRANSFERRING_MONEY' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {groupState === 'TRANSFERRING_MONEY' && <i className="ri-time-line orange" />}
                        {getStateCompletionTime('TRANSFERRING_MONEY') !== '' && (
                            <i className="ri-checkbox-circle-line green" />
                        )}
                        <div>
                            <span className={styles.title}>
                                {getStateCompletionTime('TRANSFERRING_MONEY') !== ''
                                    ? t('moneyTransferred')
                                    : t('transferringMoney')}
                            </span>
                            <span className={styles.description}>{getStateCompletionTime('TRANSFERRING_MONEY')}</span>
                        </div>
                    </div>
                </li>
                <li className={groupState === 'COMPLETED' ? styles.active : undefined}>
                    <div className={styles.content}>
                        {getStateCompletionTime('COMPLETED') !== '' && <i className="ri-checkbox-circle-line white" />}
                        <div>
                            <span className={styles.title}>{t('orderComplete')}</span>
                            <span className={styles.description}>{getStateCompletionTime('COMPLETED')}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default function OrderGroupProgressBar({ className, orderType, groupState, created, stateHistory }: Props) {
    if (orderType === 'BUY') {
        return (
            <BuyOrderGroupProgressBar
                className={className}
                groupState={groupState as FundBuyOrderState}
                created={created}
                stateHistory={stateHistory}
            />
        )
    }
    return (
        <SellOrderGroupProgressBar
            className={className}
            groupState={groupState as FundSellOrderState}
            created={created}
            stateHistory={stateHistory}
        />
    )
}
