import { useEffect, useMemo, useRef, useState } from 'react'
import { Chart } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { CurrencyOutput } from '#components'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import format from 'date-fns/format'
import { differenceInCalendarDays, startOfYear, subYears } from 'date-fns'
import { COLORS, SILVER_GRAY, SUNSET_ORANGE } from '#app/colors/colors'
import { Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useEndpoint } from '#command'
import { DailyLiquidityDto } from '@fixrate/fixrate-query'
import useCurrentDepositor from '#services/useCurrentDepositor'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { useTranslation } from 'react-i18next'

type LineChart = Chart<'line', string[] | number[]>

type PeriodKey = 'YTD' | '1Y' | '3Y' | '5Y'

type Endpoints = {
    getDepositDailyLiquidity: (depositorId?: string) => Promise<DailyLiquidityDto[]>
    getFundDailyLiquidity: (depositorId?: string) => Promise<DailyLiquidityDto[]>
}

export default function LiquidityChart() {
    const { t } = useTranslation()
    const chartRef = useRef<LineChart | null>(null)
    const Currency = useCurrencyOutput()

    const { getDepositDailyLiquidity, getFundDailyLiquidity }: Endpoints = useEndpoint()
    const PERIOD_OPTIONS: { key: PeriodKey; label: string; startDate: Date }[] = useMemo(() => {
        const oneYearLabel = t('pages.portfolio.depositor.chart.liquidity.oneYear')
        const threeYearsLabel = t('pages.portfolio.depositor.chart.liquidity.threeYears')
        const fiveYearsLabel = t('pages.portfolio.depositor.chart.liquidity.fiveYears')
        return [
            { key: 'YTD', label: 'YTD', startDate: startOfYear(new Date()) },
            { key: '1Y', label: oneYearLabel, startDate: subYears(new Date(), 1) },
            { key: '3Y', label: threeYearsLabel, startDate: subYears(new Date(), 3) },
            { key: '5Y', label: fiveYearsLabel, startDate: subYears(new Date(), 5) },
        ]
    }, [t])

    const [depositData, setDepositData] = useState<(number | null)[]>([])
    const [fundData, setFundData] = useState<(number | null)[]>([])

    const [selectedPeriod, setSelectedPeriod] = useState<PeriodKey>('YTD')

    const [fetchError, setFetchError] = useState(false)

    const today = useMemo(() => new Date(), [])

    const maxDateInterval = useMemo(
        () =>
            eachDayOfInterval({
                start: PERIOD_OPTIONS.at(-1).startDate,
                end: today,
            })
                .map((d) => format(d, 'yyyy-MM-dd'))
                .reverse(),
        [PERIOD_OPTIONS, today]
    )

    const depositor = useCurrentDepositor()

    // Fetches new depositData and fundData if depositor.id changes
    useEffect(() => {
        if (!depositor?.id) {
            return
        }

        setFetchError(false)

        getDepositDailyLiquidity(depositor.id)
            .then((data) => {
                const dailyBalanceMap = data.reduce(
                    (acc, dailyValue) => {
                        acc[dailyValue.interestDate] = dailyValue.balance
                        return acc
                    },
                    {} as Record<string, number>
                )
                const lastDate = data.at(-1)?.interestDate ?? new Date()
                setDepositData(
                    maxDateInterval.map(
                        (d) => dailyBalanceMap[d] ?? (isBefore(new Date(d), new Date(lastDate)) ? 0 : null)
                    )
                )
            })
            .catch(() => setFetchError(true))

        getFundDailyLiquidity(depositor.id)
            .then((data) => {
                const dailyBalanceMap = data.reduce(
                    (acc, dailyValue) => {
                        acc[dailyValue.interestDate] = dailyValue.balance
                        return acc
                    },
                    {} as Record<string, number>
                )
                const lastDate = data.at(-1)?.interestDate ?? new Date()
                setFundData(
                    maxDateInterval.map(
                        (d) => dailyBalanceMap[d] ?? (isBefore(new Date(d), new Date(lastDate)) ? 0 : null)
                    )
                )
            })
            .catch(() => setFetchError(true))
    }, [depositor.id, getDepositDailyLiquidity, getFundDailyLiquidity, maxDateInterval])

    // Updates the chart when the depositData or fundData changes, or if selectedPeriod changes
    useEffect(() => {
        const selectedStartDate =
            PERIOD_OPTIONS.find((option) => option.key === selectedPeriod)?.startDate ?? startOfYear(new Date())

        if (chartRef.current) {
            const minDate = PERIOD_OPTIONS.at(-1).startDate
            const maxDate = today
            const cappedStartDate = isBefore(selectedStartDate, minDate) ? minDate : selectedStartDate
            const cappedEndDate = isAfter(today, maxDate) ? maxDate : today
            const endShift = Math.abs(differenceInCalendarDays(maxDate, cappedEndDate))
            const dataCount = differenceInCalendarDays(cappedEndDate, cappedStartDate) + 1

            chartRef.current.data.labels = maxDateInterval.slice(endShift, endShift + dataCount)

            chartRef.current.data.datasets[1].data = depositData.slice(endShift, endShift + dataCount)
            chartRef.current.data.datasets[2].data = fundData.slice(endShift, endShift + dataCount)

            if (depositData.length > 0 && fundData.length > 0) {
                chartRef.current.data.datasets[0].data = chartRef.current.data.labels.map((date, i) => {
                    const depositValue = chartRef.current.data.datasets[1].data[i] as number | null
                    const fundValue = chartRef.current.data.datasets[2].data[i] as number | null
                    return depositValue != null && fundValue != null ? depositValue + fundValue : null
                })
            }

            chartRef.current.update()
        }
    }, [PERIOD_OPTIONS, depositData, today, fundData, maxDateInterval, selectedPeriod])

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        const portfolioLabel = t('pages.portfolio.depositor.chart.liquidity.portfolio')
        const bankDepositsLabel = t('pages.portfolio.depositor.chart.liquidity.bankDeposits')
        const fundsLabel = t('pages.portfolio.depositor.chart.liquidity.funds')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: maxDateInterval,
                    datasets: [
                        {
                            label: portfolioLabel,
                            data: [],
                            backgroundColor: (() => {
                                const gradient = ctx.createLinearGradient(0, 0, 0, 400)
                                gradient.addColorStop(0, SUNSET_ORANGE[500] + '40')
                                gradient.addColorStop(0.5, SUNSET_ORANGE[500] + '00')
                                return gradient
                            })(),
                            pointStyle: 'line',
                            borderDash: [8, 6],
                            borderColor: SUNSET_ORANGE[500],
                            fill: 'start',
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: SUNSET_ORANGE[500],
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                        },
                        {
                            label: bankDepositsLabel,
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: COLORS.DEPOSIT,
                            borderColor: COLORS.DEPOSIT,
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: COLORS.DEPOSIT,
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                        },
                        {
                            label: fundsLabel,
                            data: [],
                            pointStyle: 'line',
                            backgroundColor: COLORS.FUND,
                            borderColor: COLORS.FUND,
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: COLORS.FUND,
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                        },
                    ],
                },
                options: {
                    color: SILVER_GRAY[500],
                    aspectRatio: 5 / 2,
                    maintainAspectRatio: true,
                    responsive: true,
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    scales: {
                        x: {
                            type: 'time',
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: SILVER_GRAY[500],
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            time: {
                                displayFormats: {
                                    day: 'dd. MMM',
                                    month: 'MMM yy',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: SILVER_GRAY[500],
                                callback: (value) =>
                                    CurrencyOutput.formatMillion(typeof value === 'string' ? parseFloat(value) : value),
                            },
                            grid: {
                                borderDash: [4, 8],
                                color: '#00000020',
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            axis: 'x',
                            mode: 'nearest',
                            position: 'nearest',
                            intersect: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    const dataset = chartRef.current.data.datasets[tooltipItem.datasetIndex]
                                    const value = dataset.data[tooltipItem.dataIndex]
                                    return value !== undefined && value !== null
                                        ? `${dataset.label}: ${Currency(typeof value === 'string' ? parseFloat(value) : value)}`
                                        : ''
                                },
                            },
                        },
                    },
                },
            })
        }
    }

    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant={'h2'}>{t('pages.portfolio.depositor.chart.liquidity.onHandLiquidity')}</Typography>
                <Stack direction={'row'} spacing={2}>
                    <Typography variant={'body1'} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component={'span'}
                            sx={{
                                display: 'inline-block',
                                width: '1em',
                                aspectRatio: '4/1',
                                backgroundColor: SUNSET_ORANGE['400'],
                                mr: 1,
                            }}
                        />
                        <Box component={'span'} sx={{ fontSize: { xs: '1.4rem', md: '1.6rem' } }}>
                            {t('pages.portfolio.depositor.chart.liquidity.portfolio')}
                        </Box>
                    </Typography>
                    <Typography variant={'body1'} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component={'span'}
                            sx={{
                                display: 'inline-block',
                                width: '1em',
                                aspectRatio: '4/1',
                                backgroundColor: COLORS.DEPOSIT,
                                mr: 1,
                            }}
                        />
                        <Box component={'span'} sx={{ fontSize: { xs: '1.4rem', md: '1.6rem' } }}>
                            {t('pages.portfolio.depositor.chart.liquidity.bankDeposits')}
                        </Box>
                    </Typography>
                    <Typography variant={'body1'} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            component={'span'}
                            sx={{
                                display: 'inline-block',
                                width: '1em',
                                aspectRatio: '4/1',
                                backgroundColor: COLORS.FUND,
                                mr: 1,
                            }}
                        />
                        <Box component={'span'} sx={{ fontSize: { xs: '1.4rem', md: '1.6rem' } }}>
                            {t('pages.portfolio.depositor.chart.liquidity.funds')}
                        </Box>
                    </Typography>
                </Stack>
            </Stack>
            {fetchError && (
                <p className="field-info-message">{t('pages.portfolio.depositor.chart.liquidity.dataFetchFailed')}</p>
            )}
            <Box sx={{ width: '60rem', maxWidth: '100%' }}>
                <canvas ref={canvasCallback} style={{ maxWidth: '100%' }} />
            </Box>
            <Box>
                <ToggleButtonGroup value={selectedPeriod} size={'small'} exclusive color={'primary'}>
                    {PERIOD_OPTIONS.map((option) => (
                        <ToggleButton
                            key={option.key}
                            value={option.key}
                            sx={{ width: '8rem' }}
                            onClick={() => setSelectedPeriod(option.key)}
                        >
                            {option.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Box>
        </Stack>
    )
}
