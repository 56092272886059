import FXDateInput from '#app/components/DateInput/FXDateInput'
import { LanguageSelect } from '#app/components/LanguageSelect/LanguageSelect'
import { AvailableLanguage } from '#app/services/i18n'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { useCommand } from '#command'
import DateInput from '#components/DateInput'
import Modal from '#components/Modal'
import { useAuthorization } from '#services/authorization'
import { validateEmailAddress } from '#services/validateFields'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useSelector } from '#state/useSelector'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { BankDto, DepositorDto, OrganisationType, OrganisationUserInviteDto, PartnerDto } from '@fixrate/fixrate-query'
import { Alert, Box, InputLabel, Stack, TextField } from '@mui/material'
import format from 'date-fns/format'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { To, useNavigate } from 'react-router-dom'

const validateInviteUniqueness = (
    userInvites: OrganisationUserInviteDto[],
    firstName: string,
    lastName: string,
    birthDate: Date
) =>
    !userInvites
        .map((i) => `${i.firstName}${i.lastName}${i.birthDate}`.replace(/\s/g, '').toLowerCase())
        .includes(
            `${firstName}${lastName}${format(new Date(birthDate), 'yyyy-MM-dd')}`.replace(/\s/g, '').toLowerCase()
        )

type Props = {
    organisationId: string
    organisationType: OrganisationType
    close?: () => void
    isAuthorization?: boolean
    authForUser?: { isUser: boolean; id: string }
    invitedSigners?: {
        id: string
        name: string
        firstName: string
        lastName: string
        email: string
        birthDate: string
        isNew: boolean
    }[]
    setInvitedSigners?: (
        invitedSigners: {
            id: string
            name: string
            firstName: string
            lastName: string
            email: string
            birthDate: string
            isNew: boolean
        }[]
    ) => void
}

const NewUser = function ({
    organisationId,
    organisationType,
    close = null,
    isAuthorization = false,
    authForUser = undefined,
    invitedSigners = undefined,
    setInvitedSigners = undefined,
}: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const depositor = useCurrentDepositor()
    const bank = useSelector((state) => state.bank)
    const partner = useSelector((state) => state.partner)
    const auth = useAuthorization(organisationId)

    const { userInvites, defaultLanguage } = useMemo(() => {
        let organisation: BankDto | DepositorDto | PartnerDto
        switch (organisationType) {
            case 'DEPOSITOR':
                organisation = depositor
                break
            case 'BANK':
                organisation = bank
                break
            case 'PARTNER':
                organisation = partner
                break
        }
        return {
            userInvites: organisation?.userInvites || [],
            defaultLanguage: organisation?.language as AvailableLanguage,
        }
    }, [organisationType, bank, depositor, partner])

    const [isLoading, setIsLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const firstNameValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteFirstNameMissing'))
    const firstName = useFieldState<string>('', firstNameValidator)

    const lastNameValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteLastNameMissing'))
    const lastName = useFieldState<string>('', lastNameValidator)

    const emailValidator = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('blocks-UsersList.inviteEmailMissing')
            }

            if (!isEditing) {
                if (!validateEmailAddress(value)) {
                    return t('blocks-UsersList.inviteEmailInvalid')
                }
            }
            if (!isEditing && !submitting) {
                if (userInvites.find((i) => i.email === value)) {
                    return t('blocks-UsersList.inviteEmailInUse')
                }
            }
        },
        [t, userInvites, submitting]
    )
    const email = useFieldState<string>('', emailValidator)

    const birthDateValidator = useStableValidator('NOT_EMPTY_ON_EDIT', t('blocks-UsersList.inviteDateOfBirthInvalid'))
    const birthDate = useFieldState<Date>(null, birthDateValidator)

    const [language, setLanguage] = useState<AvailableLanguage>(defaultLanguage)
    const navigate = useNavigate()
    const { createUserInvite } = useCommand()

    let returnTo: To

    switch (organisationType) {
        case 'DEPOSITOR':
            returnTo = `/organizations/${organisationId}/users/all`
            break
        case 'BANK':
            returnTo = '/my-bank'
            break
        case 'PARTNER':
            returnTo = '/partner/users'
            break
    }

    if (isAuthorization) {
        if (authForUser.isUser) {
            returnTo = `/organizations/${organisationId}/users/authorization/${authForUser.id}`
        } else {
            returnTo = `/organizations/${organisationId}/users/authorizationforinvite/${authForUser.id}`
        }
    }

    const header = isAuthorization ? t('blocks-UsersList.inviteHeadingPrincipal') : t('blocks-UsersList.inviteHeading')

    async function submit() {
        const isValid =
            firstName.validate() &&
            lastName.validate() &&
            email.validate() &&
            birthDate.validate() &&
            validateInviteUniqueness(userInvites, firstName.value, lastName.value, birthDate.value)

        if (!isValid) return

        setIsLoading(true)
        setSubmitting(true)
        try {
            const firstNameValue = firstName.value
            const lastNameValue = lastName.value
            const emailValue = email.value
            const birthDateValue = format(birthDate.value, 'yyyy-MM-dd')
            if (isAuthorization) {
                setInvitedSigners(
                    invitedSigners.concat({
                        id: firstNameValue + lastNameValue + birthDateValue,
                        name: firstNameValue + ' ' + lastNameValue,
                        firstName: firstNameValue,
                        lastName: lastNameValue,
                        email: emailValue,
                        birthDate: birthDateValue,
                        isNew: true,
                    })
                )
                closeModal()
            } else {
                const { waitForCommand } = await createUserInvite(
                    organisationId,
                    organisationType,
                    firstNameValue,
                    lastNameValue,
                    emailValue,
                    birthDateValue,
                    language
                )
                const success = await waitForCommand()
                if (success) {
                    closeModal()
                    dispatch(
                        showConfirmationModal({
                            title: t('blocks-UsersList.inviteEmailSentHeader'),
                            text: t('blocks-UsersList.inviteEmailSentInfo'),
                            buttonText: t('common.itsOkay'),
                            illustrationName: 'emailSent',
                            illustrationColor: 'default',
                        })
                    )
                }
            }

            if (auth.depositor.hasSupportRole) {
                dispatch(
                    showConfirmationModal({
                        title: t('blocks-UsersList.inviteEmailNotSentAsSupportHeader'),
                        text: t('blocks-UsersList.inviteEmailNotSentAsSupportInfo'),
                        buttonText: t('common.continue'),
                    })
                )
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
            setSubmitting(false)
        }
    }

    const closeModal = () => {
        if (close) {
            close()
        } else {
            navigate(returnTo)
        }
    }
    const autoCompleteBlocker = (field: string) => {
        const randomNumber = Math.floor(Math.random() * (1 - 1000 + 1)) + 1
        return field + '-' + randomNumber
    }
    return (
        <Modal
            header={header}
            onSubmit={submit}
            onCancel={closeModal}
            submitButtonText={t('common.send')}
            width="50rem"
        >
            <form autoComplete="off">
                <Stack spacing={1.5}>
                    <Box>
                        <InputLabel htmlFor="firstNameField">{t('blocks-UsersList.inviteFirstName')}</InputLabel>
                        <TextField
                            id="firstNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={firstName.onBlur}
                            onChange={(e) => firstName.setValue(e.target.value)}
                            value={firstName.value}
                        />
                        <p className="field-error-message">{firstName.errorMessage}</p>
                    </Box>
                    <Box>
                        <InputLabel htmlFor="lastNameField">{t('blocks-UsersList.inviteLastName')}</InputLabel>
                        <TextField
                            id="lastNameField"
                            name={autoCompleteBlocker('fixrate-lname')}
                            onBlur={lastName.onBlur}
                            onChange={(e) => lastName.setValue(e.target.value)}
                            value={lastName.value}
                        />
                        <p className="field-error-message">{lastName.errorMessage}</p>
                    </Box>
                    <Box>
                        <InputLabel htmlFor="emailField">{t('blocks-UsersList.inviteEmail')}</InputLabel>
                        <TextField
                            id="emailField"
                            name={autoCompleteBlocker('fixrate-email')}
                            onBlur={email.onBlur}
                            onChange={(e) => email.setValue(e.target.value)}
                            value={email.value}
                        />
                        <p className="field-error-message">{email.errorMessage}</p>
                    </Box>
                    <Box>
                        <InputLabel htmlFor="birthDateDatePicker">{t('blocks-UsersList.inviteDateOfBirth')}</InputLabel>
                        <FXDateInput
                            id="birthDateDatePicker"
                            value={birthDate.value}
                            onChange={birthDate.setValue}
                            dataCy="birthDateDatePicker"
                        />
                        <p className="field-error-message">{birthDate.errorMessage}</p>
                    </Box>
                    <Box>
                        <InputLabel htmlFor="languageField">{t('blocks-UsersList.inviteLanguage')}</InputLabel>
                        <LanguageSelect language={language} setLanguage={setLanguage} />
                        <Alert sx={{ '& .MuiAlert-icon': { display: 'none' }, mt: 3 }} severity="warning">
                            {t('pages-organizations.inviteTipBankID')}
                        </Alert>
                        {!isLoading &&
                            !validateInviteUniqueness(
                                userInvites,
                                firstName.value,
                                lastName.value,
                                birthDate.value
                            ) && <Alert severity={'error'}>{t('blocks-UsersList.inviteNotUnique')}</Alert>}
                    </Box>
                </Stack>
            </form>
        </Modal>
    )
}

export default NewUser
