import { ButtonRow, DateOutput, Paper } from '#components'
import { Button } from '@mui/material'
import { useTranslation } from '#components/i18n'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { addSelfRemovingInfoMessage } from '#services/thunks/infoMessages'
import { OrganisationUserInviteDto } from '@fixrate/fixrate-query'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function CustomerDetailInvite({
    userInvite,
    depositorId,
}: {
    userInvite: OrganisationUserInviteDto
    depositorId: string
}) {
    const { t } = useTranslation('pages-customers')
    const { t: tul } = useTranslation('blocks-UsersList')
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const inviteLink = window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite.id

    function copyLink(userInvite) {
        const inputElement = document.getElementById('inviteLink' + userInvite.id) as HTMLInputElement
        inputElement.select()
        inputElement.setSelectionRange(0, 99999) //For mobile devices
        document.execCommand('copy')
        dispatch(addSelfRemovingInfoMessage('info', t('customerDetailInviteLinkCopied')))
    }

    const fullName = userInvite.firstName + ' ' + userInvite.lastName

    return (
        <Paper title={t('customerDetailInviteHeading', { fullName })}>
            <p>{t('customerDetailInviteMessage', { fullName })}</p>
            <div>
                <input
                    id={'inviteLink' + userInvite.id}
                    readOnly={true}
                    style={{ position: 'absolute', top: '-10000px', left: '-10000px' }}
                    type="text"
                    value={inviteLink}
                />
                <LabeledInfo label={tul('inviteEmail')} info={userInvite.email} />
                <LabeledInfo label={tul('inviteDateOfBirth')}>
                    <DateOutput.Date date={userInvite.birthDate} />
                </LabeledInfo>
                <LabeledInfo
                    label={tul('inviteLink')}
                    info={
                        <Button
                            style={{
                                padding: 0,
                                minHeight: '0',
                                minWidth: '0',
                                textTransform: 'none',
                                border: 'none',
                            }}
                            variant={'outlined'}
                            size={'small'}
                            onClick={() => copyLink(userInvite)}
                        >
                            {tul('copyToClipboard')}
                        </Button>
                    }
                />
                <LabeledInfo label={tul('inviteSent')}>
                    <DateOutput.Date date={userInvite.requested} />
                </LabeledInfo>
                <LabeledInfo label={tul('validUntil')}>
                    <DateOutput.Date date={userInvite.expirationDate} />
                </LabeledInfo>
            </div>
            <p />
            <p>{t('customerDetailInviteNotice')}</p>
            <ButtonRow>
                <Button
                    variant={'outlined'}
                    onClick={() => navigate(`/customer/${depositorId}/resend-invite/${userInvite.id}`)}
                >
                    {t('customerDetailInviteResend')}
                </Button>
            </ButtonRow>
        </Paper>
    )
}
