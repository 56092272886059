import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CalendarDay from '#components/Calendar/CalendarDay'
import { Temporal } from '@js-temporal/polyfill'

type Day = {
    date: Temporal.PlainDate
    dim: boolean
}

type Props = {
    seedDate: Temporal.PlainDate
    content: Map<string, JSX.Element>
    header?: JSX.Element | JSX.Element[]
}

const Calendar = ({ seedDate, content, header }: Props): JSX.Element => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    // get start of month for seedDate
    const startOfMonth = seedDate.with({ day: 1 })
    // get end of month for seedDate
    const endOfMonth = seedDate.with({ day: seedDate.daysInMonth })

    // get first date in week of startOfMonth
    const dateOfFirstDayOfWeekOfStartOfMonth = startOfMonth.add({ days: 1 - startOfMonth.dayOfWeek })
    // get last date in week of endOfMonth
    const dateOfLastDayOfWeekOfEndOfMonth = endOfMonth.add({ days: 7 - endOfMonth.dayOfWeek })

    //iterate over days between dateOfFirstDayOfWeekOfStartOfMonth and dateOfLastDayOfWeekOfEndOfMonth
    let currentDate = dateOfFirstDayOfWeekOfStartOfMonth
    const weeks = []

    while (Temporal.PlainDate.compare(currentDate, dateOfLastDayOfWeekOfEndOfMonth) === -1) {
        const week: Day[] = []
        for (let i = 0; i < 7; i++) {
            const shouldDim = currentDate.month !== seedDate.month
            // don't render days outside of month if on mobile
            if (!(isMobile && shouldDim)) {
                week.push({ date: currentDate, dim: shouldDim })
            }
            currentDate = currentDate.add({ days: 1 })
        }
        weeks.push(week)
    }

    return (
        <>
            <Stack direction={'row'} sx={{ mb: 1, mt: 2 }} flexGrow={1} justifyContent={'space-between'}>
                {header}
            </Stack>
            <Box>
                {!isMobile && (
                    <Stack direction={'row'} mb={1}>
                        <Typography component={'div'} sx={{ flexGrow: 1, alignItem: 'center' }}>
                            Mandag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Tirsdag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Onsdag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Torsdag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Fredag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Lørdag
                        </Typography>
                        <Typography component={'div'} sx={{ flexGrow: 1 }}>
                            Søndag
                        </Typography>
                    </Stack>
                )}
                {weeks.map((week, i) => (
                    <Stack key={seedDate.toString() + i} direction={isMobile ? 'column' : 'row'}>
                        {week.map((day: Day) => (
                            <CalendarDay key={day.date.toString()} date={day.date} dim={day.dim}>
                                {content.get(day.date.toString())}
                            </CalendarDay>
                        ))}
                    </Stack>
                ))}
            </Box>
        </>
    )
}

export default Calendar
