import { PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { InterestOutput } from '#components'
import { InterestRateBenchmark, InterestType } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

interface AdInterestLabelProps {
    productType: InterestType
    interestRate: number
    interestRateBenchmark?: InterestRateBenchmark
}

const AdInterestLabel = function ({ productType, interestRate, interestRateBenchmark }: AdInterestLabelProps) {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                backgroundColor: productType === 'FIXED' ? SUNSET_ORANGE[50] : PURPLE[50],
                color: productType === 'FIXED' ? SUNSET_ORANGE[800] : PURPLE[500],
                py: 0.6,
                px: 1,
            }}
        >
            {productType === 'FIXED' ? (
                <Typography px={1} fontSize={'1.4rem'} fontWeight={'600'}>
                    {t('pages-marketplace.fixedInterest')}
                </Typography>
            ) : (
                <Stack direction="row" spacing={1} alignItems={'center'}>
                    <Typography fontSize={'1.2rem'} fontWeight={'600'} noWrap>
                        {InterestOutput.formatMarginWithBenchmarkObj(interestRate, interestRateBenchmark, t)?.label}
                    </Typography>
                    <Divider sx={{ borderColor: PURPLE[100], height: '1.8rem' }} orientation="vertical" />
                    <Typography fontSize={'1.4rem'} fontWeight={'700'} noWrap>
                        {InterestOutput.formatMarginWithBenchmarkObj(interestRate, interestRateBenchmark, t)?.interest}
                    </Typography>
                </Stack>
            )}
        </Box>
    )
}

export default AdInterestLabel
