import { UsersListParams } from '#blocks/UsersList/UsersList'
import { useTranslation } from '#components/i18n'
import { partnerRoles } from '#services/authorization'
import { securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { SecurityRole } from '@fixrate/fixrate-query'
import { Link } from 'react-router-dom'
import styles from './UsersList.module.scss'

const ROLE_NAMES: SecurityRole[] = [
    'DEPOSITOR_ADMIN',
    'DEPOSITOR_ORDER',
    'DEPOSITOR_ACCOUNT_HOLDER',
    'DEPOSITOR_PAYMENT',
    'DEPOSITOR_ACCOUNTANT',
    'DEPOSITOR_VIEW',
    'DEPOSITOR_SIGN_AUTHORIZATION',
    ...partnerRoles(),

    'BANK_ADMIN',
    'BANK_CUSTOMER_SERVICE',
    'BANK_AD_MANAGER',
    'BANK_VIEW',
    'BANK_COORDINATOR',

    'PARTNER_ADMIN',
    'PARTNER_ADVISOR',
    'PARTNER_VIEW',
]

interface RoleBadgeProps {
    role: SecurityRole
    userListParams: UsersListParams
    userId: string
}

function RoleBadge({ role, userListParams, userId }: RoleBadgeProps) {
    const { t } = useTranslation()

    const { isAdmin, basePath } = userListParams

    const name = t(securityRoleTMap[role])
    if (!name) {
        return null
    }

    if (isAdmin) {
        let link
        if (userListParams.organisation.type === 'DEPOSITOR') {
            if (role === 'DEPOSITOR_SIGN_AUTHORIZATION') {
                link = `/organizations/${userListParams.organisation.id}/users/power-of-attorney`
            } else {
                link = `/organizations/${userListParams.organisation.id}/users/roles`
            }
        } else {
            link = `${basePath}/edit-role/${userId}#!`
        }

        return (
            <li className={styles.userlist__roleelement} key={name}>
                <Link className={styles.userlist__roleelementLink} data-cy="editRolesLink" to={link}>
                    {name}
                </Link>
            </li>
        )
    } else {
        return (
            <li className={styles.userlist__roleelement} key={name}>
                <span className={styles.userlist__roleelementNolink}>{name}</span>
            </li>
        )
    }
}

interface Props {
    userListParams: UsersListParams
    roles: SecurityRole[]
    userId: string
}

export default function RoleBadgeList({ userListParams, roles, userId }: Props) {
    const elements = ROLE_NAMES.reduce((badges: React.ReactNode[], role: SecurityRole) => {
        if (roles.includes(role)) {
            if (!role.endsWith('_VIEW')) {
                badges.push(<RoleBadge key={role} role={role} userListParams={userListParams} userId={userId} />)
            }
        }

        return badges
    }, [])

    if (elements.length === 0) {
        const appropriateViewRole = roles.find((role) => role.endsWith('_VIEW'))

        if (appropriateViewRole) {
            elements.push(
                <RoleBadge
                    key={appropriateViewRole}
                    role={appropriateViewRole}
                    userListParams={userListParams}
                    userId={userId}
                />
            )
        }
    }

    return <ul className={styles.userlist__rolelist}>{elements}</ul>
}
