import { useState } from 'react'
import { useSelector } from '#state/useSelector'
import { DateOutput, ActionBlock, HelpIcon } from '#components'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import { TerminationState } from '#state/selectors'
import { TerminationModeConstant } from '#pages/portfolio-bank/DepositDetailsBank/DepositDetailsBank'
import { useCommand } from '#command'
import Button from '#components/Button'
import SignedDocumentList from '#pages/orders/bank/OrderDetails/OrderDialog/SignedDocumentList'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

export default function RejectIRCTerminationAction({ deposit }: { deposit: DepositDto }) {
    const { t } = useTranslation()
    const [terminationMode, setTerminationMode] = useState('')
    const terminationDocument = useSelector((state) => state.documents[deposit.terminationDocumentId])
    const { confirmDepositTermination, cancelInterestRateChange } = useCommand()

    async function submitTermination() {
        const { waitForCommand } = await confirmDepositTermination(deposit.id)
        await waitForCommand()
    }

    async function submitCancelInterestRateChange() {
        const { waitForCommand } = await cancelInterestRateChange(
            deposit.interestRateChangeId,
            t('pages-portfolio-bank.rejectIRCReason')
        )
        await waitForCommand()
    }

    if (
        deposit.terminationState !== TerminationState.SENT_TO_BANK ||
        deposit.terminationType !== TerminationModeConstant.REJECT_INTEREST_RATE_CHANGE
    ) {
        return null
    }

    switch (terminationMode) {
        case 'CANCEL':
            return (
                <ActionBlock header={t('pages-portfolio-bank.rejectIRCCancelHeader')}>
                    <HelpIcon
                        supportCommonName="terminationFloatingInterest"
                        helpContextPage="RejectIRCTerminationAction"
                    />
                    <p>{t('pages-portfolio-bank.rejectIRCCancelInfo1')}</p>
                    <p>{t('pages-portfolio-bank.rejectIRCCancelInfo2')}</p>
                    <p>{t('pages-portfolio-bank.rejectIRCCancelInfo3')}</p>
                    <ButtonRow>
                        <Button variant={'secondary'} onClick={() => setTerminationMode('')}>
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={submitCancelInterestRateChange}>
                            {t('pages-portfolio-bank.rejectIRCCancelButtonText')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )

        case 'TERMINATION':
            return (
                <ActionBlock header={t('pages-portfolio-bank.rejectIRCTerminationHeader')}>
                    <HelpIcon
                        supportCommonName="terminationFloatingInterest"
                        helpContextPage="RejectIRCTerminationAction"
                    />
                    <p>{t('pages-portfolio-bank.rejectIRCTerminationInfo1')}</p>
                    <p>
                        {t('pages-portfolio-bank.rejectIRCTerminationInfo2', {
                            date: DateOutput.formatVerboseMonth(deposit?.terminationDate),
                        })}
                    </p>
                    <dl>
                        <dt>{t('pages-portfolio-bank.rejectIRCTerminationStep1')}</dt>
                        <dd>
                            <SignedDocumentList documents={[terminationDocument]} />
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t('pages-portfolio-bank.rejectIRCTerminationStep2')}</dt>
                        {deposit.terminationDate && (
                            <dd>
                                <DateOutput.VerboseMonth date={deposit?.terminationDate} />
                            </dd>
                        )}
                    </dl>
                    <ButtonRow>
                        <Button variant={'secondary'} onClick={() => setTerminationMode('')}>
                            {t('common.cancel')}
                        </Button>
                        <Button onClick={submitTermination}>
                            {t('pages-portfolio-bank.rejectIRCTerminationButtonText')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )

        default:
            return (
                <ActionBlock header={t('pages-portfolio-bank.rejectIRCConditionalHeader')}>
                    <HelpIcon
                        supportCommonName="terminationFloatingInterest"
                        helpContextPage="RejectIRCTerminationAction"
                    />
                    <p>{t('pages-portfolio-bank.rejectIRCConditionalInfo1')}</p>
                    <p>{t('pages-portfolio-bank.rejectIRCConditionalInfo2')}</p>
                    <p>
                        {t('pages-portfolio-bank.rejectIRCConditionalInfo3', {
                            date: DateOutput.formatVerboseMonth(deposit?.terminationDate),
                        })}
                    </p>
                    <p>
                        <SignedDocumentList documents={[terminationDocument]} />
                    </p>

                    <ButtonRow>
                        <Button onClick={() => setTerminationMode('CANCEL')}>
                            {t('pages-portfolio-bank.rejectIRCConditionalCancelButtonText')}
                        </Button>
                        <Button onClick={() => setTerminationMode('TERMINATION')}>
                            {t('pages-portfolio-bank.rejectIRCConditionalTerminateButtonText')}
                        </Button>
                    </ButtonRow>
                </ActionBlock>
            )
    }
}
