import Paper from '#app/components/Paper/FxPaper'
import DocumentLink from '../../common/DocumentLink/DocumentLink'
import { DepositDto } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'
import { useAuthorization } from '#services/authorization'
import { useTranslation } from 'react-i18next'

export default function DepositDetailsBankInterests({ deposit }: { deposit: DepositDto }) {
    const { t } = useTranslation()

    const authorization = useAuthorization()
    const isCoordinator = authorization.bank.hasCoordinatorRole

    const hasDepositDeviations = useSelector(
        (state) =>
            state.depositDeviations
                .filter((deviation) => deviation.depositId === deposit.id)
                .filter((deviation) => deviation.state !== 'RESOLVED').length > 0
    )

    return (
        <Paper title={t('pages-portfolio-bank.interestCalculationsTitle')}>
            <p>{t('pages-portfolio-bank.interestCalculationsInfo1')}</p>
            <p>{t('pages-portfolio-bank.interestCalculationsInfo2')}</p>
            <p>{t('pages-portfolio-bank.interestCalculationsInfo3')}</p>
            {!isCoordinator && hasDepositDeviations && (
                <p style={{ color: 'darkred' }}>
                    {t('pages-portfolio-bank.interestCalculationsInfoDepositDeviations')}
                </p>
            )}
            <DocumentLink
                name={t('pages-portfolio-bank.interestCalculationsDocumentName')}
                link={`/document/deposit/${deposit.id}/interests`}
            />
        </Paper>
    )
}
