import { useEffect, useRef } from 'react'
import { Chart } from 'chart.js'
import { COLORS } from '#app/colors/colors'

type DoughnutChart = Chart<'doughnut', number[]>

type Props = {
    depositsValue: number
    fundsValue: number
}

export default function SplitChart({ depositsValue, fundsValue }: Props) {
    const chartRef = useRef<DoughnutChart | null>(null)

    const totalValue = depositsValue + fundsValue

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.data.datasets[0].data = [fundsValue / totalValue, depositsValue / totalValue]
            chartRef.current.update()
        }
    }, [depositsValue, fundsValue, totalValue])

    function canvasCallback(canvas: HTMLCanvasElement | null) {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: ['Fond', 'Bankinnskudd'],
                    datasets: [
                        {
                            borderWidth: 0,
                            backgroundColor: [COLORS.FUND, COLORS.DEPOSIT],
                            data: [fundsValue / totalValue, depositsValue / totalValue],
                        },
                    ],
                },
                options: {
                    cutout: '60%',
                    hover: {
                        mode: null,
                    },
                    maintainAspectRatio: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                    },
                },
            })
        }
    }

    return <canvas ref={canvasCallback} style={{ maxWidth: '100%', aspectRatio: '1/1' }} />
}
