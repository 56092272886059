import { useSelector } from '#state/useSelector'
import { AdDto } from '@fixrate/fixrate-query'
import { Box, Typography } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Mousewheel, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import AdCard from '../AdCard/AdCard'
import AdCardSkeleton from '../AdCard/AdCardSkeleton'
import styles from './GroupedAdsSwiper.module.scss'
import './SliderScroll.scss'

type Props = {
    ads: AdDto[]
    onAdClick: (id: string, url: string) => void
    marketplaceUrl: string
}

export const GroupedAdsSwiper = ({ ads, onAdClick, marketplaceUrl }: Props) => {
    const products = useSelector(({ products }) => products)
    const orders = useSelector((state) => state.orders)
    const { t } = useTranslation()
    const loadedAds = useSelector((state) => state.loaded?.ads)
    const depositor = useSelector((state) => state.depositor)
    const associations = useSelector((state) => state.session?.associations)
    const partnerId = associations?.find((asso) => asso.organisationType === 'PARTNER')?.organisation?.id
    const partnerProposals = depositor?.partnerRelations?.find(
        (relation) => relation.partnerId === partnerId
    )?.partnerProposals
    const acceptedPartnerProposals = partnerProposals
        ?.filter((proposal) => ads?.find((ad) => ad.id === proposal?.adId))
        ?.filter((proposal) => proposal?.status === 'ACCEPTED')
        ?.filter((proposal) => orders?.find((order) => order.ad.id !== proposal?.adId))

    if (loadedAds === true && ads.length === 0) {
        return (
            <Typography variant="emptyState" sx={{ ml: 5 }}>
                {t('pages-marketplace.noAdsInCategory')}
            </Typography>
        )
    }

    return (
        <Swiper
            modules={[Mousewheel, Scrollbar]}
            spaceBetween={0}
            slidesPerView={'auto'}
            scrollbar={{ draggable: true }}
            mousewheel={{ forceToAxis: true }}
            className={classNames(styles.swiperSlider, 'marketplace-swiper')}
            threshold={10}
        >
            {ads.length === 0
                ? Array.from({ length: 5 }).map((_, index) => (
                      <SwiperSlide key={index} className={styles.swiperSlide}>
                          <ul>
                              <Box component="li" mr={2}>
                                  <AdCardSkeleton />
                              </Box>
                          </ul>
                      </SwiperSlide>
                  ))
                : createAdGroup(ads).map((group) => (
                      <SwiperSlide key={group.bankId} className={styles.swiperSlide}>
                          <ul>
                              {group.adList.slice(0, 1).map((ad) => (
                                  <Box key={ad.id} component="li" mr={2}>
                                      <AdCard
                                          ad={ad}
                                          partnerProposalAd={
                                              !!acceptedPartnerProposals?.find((proposal) => proposal.adId === ad.id)
                                          }
                                          product={products[ad.productId]}
                                          onAdClick={() => onAdClick(ad.id, marketplaceUrl)}
                                          className={styles.adInSwiper}
                                      />
                                  </Box>
                              ))}
                          </ul>
                      </SwiperSlide>
                  ))}
        </Swiper>
    )
}

interface AdGroup {
    bankId: string
    adList: AdDto[]
    maxInterestRate: number
}

function createAdGroup(ads: AdDto[]): AdGroup[] {
    const groupedAds = ads.reduce(
        (acc, ad) => {
            if (!acc[ad.bankId]) {
                acc[ad.bankId] = []
            }

            acc[ad.bankId].push(ad)

            return acc
        },
        {} as { [bankId: string]: AdDto[] }
    )

    return Object.entries(groupedAds)
        .map(([bankId, ads]) => ({
            bankId,
            adList: ads.sort((a1, a2) => a2.interest - a1.interest),
            maxInterestRate: ads.reduce((max, ad) => (ad.interest > max ? ad.interest : max), -Infinity),
        }))
        .sort((g1, g2) => g2.maxInterestRate - g1.maxInterestRate)
}
