import { RegulatoryRegion } from '@fixrate/fixrate-query'
import { CustomerDeclarationNO } from './CustomerDeclaration_NO'
import { CustomerDeclarationSE } from './CustomerDeclaration_SE'
import { useTranslation } from 'react-i18next'

type Props = {
    regulatoryRegion: RegulatoryRegion
}

export function CustomerDeclaration({ regulatoryRegion }: Props) {
    const { t } = useTranslation()

    switch (regulatoryRegion) {
        case 'NO':
            return <CustomerDeclarationNO />
        case 'SE':
            return <CustomerDeclarationSE />
        default:
            return <div>{t('common.loading')}</div>
    }
}
