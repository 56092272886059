import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getVolumePerSegment } from '#services/thunks/statistics'
import { ChartComp } from './ChartComp'
import { StaggData } from '#state/stagg'
import { useTranslation } from 'react-i18next'

export const VolumePerSegment = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<StaggData>>()
    const [data, setData] = useState<StaggData>([])
    const [error, setError] = useState(false)

    useEffect(() => {
        dispatch(getVolumePerSegment('1M', 0.05))
            .then(setData)
            .catch(() => setError(true))
    }, [dispatch])

    const readableLegend = (tags) => {
        if (tags.bankTotalAssets === '0-5000') {
            return t('common.banksTotalAssetsBetween', { min: 0, max: 5 })
        } else if (tags.bankTotalAssets === '5000-15000') {
            return t('common.banksTotalAssetsBetween', { min: 5, max: 15 })
        } else {
            return t('common.banksTotalAssetsMoreThan', { count: 15 })
        }
    }

    return (
        <>
            {data.length !== 0 && (
                <ChartComp
                    data={data}
                    error={error}
                    smallSize={false}
                    tickValue="M"
                    type="bar"
                    description={t('pages-BankDashboard.volumeBankSegmentDescription')}
                    labels={readableLegend}
                />
            )}
        </>
    )
}
