import classNames from 'classnames'
import { Children, cloneElement } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './Progress.module.scss'

/**
 * @param {object} props
 * @param {boolean} [props.circled]
 * @param {boolean} [props.filled]
 * @param {string} [props.link]
 * @param {React.ReactText} [props.bottomText]
 * @return {JSX.Element}
 */
export function ProgressStep({
    circled = false,
    filled = false,
    bottomText = '',
    link = '',
}: {
    circled?: boolean
    filled?: boolean
    bottomText?: string
    link?: string
}) {
    const navigate = useNavigate()
    return (
        <div className={styles.step}>
            <div className={classNames(styles.bottomtext, { [styles.currentText]: circled })}>
                {link ? (
                    <Link to={link} className={styles.link}>
                        {bottomText}
                    </Link>
                ) : (
                    bottomText
                )}
            </div>
            <div className={styles.graphics}>
                <div className={classNames(styles.bar, styles.barLeft, { [styles.selectedLine]: filled })} />
                <div
                    className={classNames(styles.bar, styles.barRight, { [styles.selectedLine]: filled && !circled })}
                />
                <div
                    onClick={() => navigate(link)}
                    className={classNames([styles.circle, { [styles.current]: circled }], {
                        [styles.previous]: filled,
                    })}
                ></div>
            </div>
        </div>
    )
}

export function Progress({
    step,
    children,
    edgeBar = false,
    className = undefined,
}: {
    step: number
    children: JSX.Element | JSX.Element[]
    edgeBar?: boolean
    className?: string
}) {
    const steps = []
    Children.forEach(children, (child, index) => {
        if (child?.type === ProgressStep) {
            steps.push(cloneElement(child, { key: index, filled: step >= index, circled: step === index }))
        }
    })

    return <div className={classNames(styles.body, className, { [styles.hideEdgeBar]: !edgeBar })}>{steps}</div>
}
