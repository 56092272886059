import { Fragment } from 'react'
import styles from '../InfoTabs.module.scss'
import { FundDto, FundShareClassDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import List, { ListItem } from '#app/components/List/List'
import { DateOutput } from '#components'
import { fundCategoryTMap, fundMorningstarCategoryTMap, fundVffCategoryTMap } from '#services/enumTranslationKeyMapping'
import { Box } from '@mui/material'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'
import { useLocalizedString } from '#services/useLocalizedString'

type Props = { fund: FundDto; shareClass: FundShareClassDto; onTabChange: (tabKey: string) => void }

export default function FundAbout({ fund, shareClass, onTabChange }: Props) {
    const { t } = useTranslation()
    const fundSummary = useLocalizedString(fund?.fundSummary)

    const fundHomepage = shareClass && shareClass.website ? shareClass.website : fund.dataSource
    const shareClassStartDate = shareClass && shareClass.startDate ? shareClass.startDate : fund.fundStartDate
    const shareClassSuccessBasedFee = shareClass && shareClass.successBasedFee
    const shareClassSuccessBasedFeePercentage = shareClass && shareClass.successBasedFeePercentage

    return (
        <div className={styles.fundInfo}>
            <div className={styles.summary}>
                {fundSummary && (
                    <>
                        <h4>{t('pages-fund-details.summary')}</h4>
                        <p>{fundSummary}</p>
                    </>
                )}
                <section>
                    <h2 style={{ marginTop: 30 }}>{t('pages-fund-details.categories')}</h2>
                    <List>
                        <ListItem
                            label={t('pages-fund-details.typeOfFund')}
                            value={t(fundCategoryTMap[fund.fundCategory])}
                        />
                        <ListItem
                            label={t('pages-fund-details.morningstarCategory')}
                            value={t(fundMorningstarCategoryTMap[fund.morningstarCategory])}
                        />
                        {fund.vffCategory && (
                            <ListItem
                                label={t('pages-fund-details.VFFCategory')}
                                value={t(fundVffCategoryTMap[fund.vffCategory])}
                            />
                        )}
                        <ListItem
                            label={t('pages-fund-details.dividend')}
                            value={fund.isDividendFund ? t('common.yes') : t('common.no')}
                        />
                        <ListItem
                            label={t('pages-fund-details.swingPrice') + ' *'}
                            value={fund.swingPrice ? t('common.yes') : t('common.no')}
                        />
                        <ListItem
                            label={t('pages-fund-details.successBasedFee') + ' *'}
                            value={
                                shareClassSuccessBasedFee
                                    ? shareClassSuccessBasedFeePercentage !== null
                                        ? t('common.yes') +
                                          ` (${CurrencyOutputObject(shareClassSuccessBasedFeePercentage, { withCurrency: false, maximumDecimals: 2 })}%)`
                                        : t('common.yes')
                                    : t('common.no')
                            }
                        />
                        <Box sx={{ fontSize: '1.2rem', mt: 2 }}>
                            {'*' + t('pages-fund-details.seeProspectForMoreInfo')}
                            <a href="#" onClick={() => onTabChange('documents')}>
                                {t('pages-fund-details.documents')}
                            </a>
                        </Box>
                    </List>
                </section>
            </div>
            <div className={styles.aboutFundManager}>
                <h4>{t('pages-fund-details.aboutManager')}</h4>
                <ul className={classNames(styles.keyValueList)}>
                    {fund.fundManagers.map((manager) => (
                        <Fragment key={manager.name}>
                            <li>
                                <span className={styles.key}>{t('pages-fund-details.portfolioManager')}</span>
                                <span className={styles.value}>{manager.name}</span>
                            </li>
                            <li className={styles.sub}>
                                <span className={styles.key}>{t('pages-fund-details.managedSince')}</span>
                                <span className={styles.value}>{DateOutput.formatDate(manager.startDate)}</span>
                            </li>
                        </Fragment>
                    ))}
                    <li>
                        <span className={styles.key}>{t('pages-fund-details.startDate')}</span>
                        <span className={styles.value}>{DateOutput.formatDate(fund.fundStartDate)}</span>
                    </li>
                    <li>
                        <span className={styles.key}>{t('pages-fund-details.shareClassStartDate')}</span>
                        <span className={styles.value}>{DateOutput.formatDate(shareClassStartDate)}</span>
                    </li>
                </ul>
                <a
                    href={fundHomepage}
                    target="_blank"
                    className={classNames(styles.value, styles.link)}
                    rel="noreferrer"
                >
                    {t('pages-fund-details.goToWebsite')}
                </a>
            </div>
        </div>
    )
}
