import { CurrencyOutput, DateOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow } from '#components'
import { useSelector } from '#state/useSelector'
import DocumentLink from '#components/DocumentLink'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'

type Props = {
    fundPlacementId: string
}

export default function TransactionTable({ fundPlacementId }: Props) {
    const Currency = useCurrencyOutput()
    const fundPlacement = useSelector((state) => state.fundPlacements.find((fp) => fp.id === fundPlacementId))

    return (
        <Table>
            <TableHeader>
                <TableHeaderCell>Dato</TableHeaderCell>
                <TableHeaderCell>Type</TableHeaderCell>
                <TableHeaderCell align={'right'}>Beløp</TableHeaderCell>
                <TableHeaderCell align={'right'}>NAV-kurs</TableHeaderCell>
                <TableHeaderCell align={'right'}>Andeler</TableHeaderCell>
                <TableHeaderCell>Sluttseddel</TableHeaderCell>
            </TableHeader>
            {fundPlacement?.transactions.map((t) => (
                <TableRow key={t.sequenceNumber}>
                    <TableCell>{DateOutput.formatDate(t.transactionDate)}</TableCell>
                    <TableCell>{t.type}</TableCell>
                    <TableCell align={'right'}>
                        {Currency(Math.round(t.unitQuantity * t.unitPrice), { decimals: 0 })}
                    </TableCell>
                    <TableCell align={'right'}>{Currency(t.unitPrice, { decimals: 4 })}</TableCell>
                    <TableCell align={'right'}>{CurrencyOutput.formatNoCode(t.unitQuantity)}</TableCell>
                    <TableCell>
                        <DocumentCell documentId={t.documentId} />
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    )
}

function DocumentCell({ documentId }: { documentId: string }) {
    const document = useSelector((state) => state.documents[documentId])
    return document ? <DocumentLink link={document.link} name={'Last ned'} /> : <span>-</span>
}
