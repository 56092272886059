import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useCommand } from '#command'
import { DateOutput, FileUpload, Paper } from '#components'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import { Table, TableCell, TableRow } from '#components/Table'
import TableHeader from '#components/Table/TableHeader'
import TableHeaderCell from '#components/Table/TableHeaderCell'
import CheckMarkIcon from '#icons/CheckMarkIcon'
import WarningIcon from '#icons/WarningIcon'
import { formatAccount } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Box, Checkbox, FormControlLabel, Tooltip, Typography } from '@mui/material'
import { subMonths } from 'date-fns'
import parse from 'date-fns/parse'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './AccountStatementList.module.css'
import subYears from 'date-fns/subYears'

export const State = { sent: 1, error: 2, processing: 3, notparsable: 4, unknown: 5 }

export const getState = (document) => {
    if (document?.approved) {
        return State.sent
    } else if (document?.type.startsWith('ERROR')) {
        return State.error
    } else if (document?.parsable === false) {
        return State.notparsable
    } else if (document?.type === 'UNCATEGORIZED') {
        return State.processing
    } else {
        return State.unknown
    }
}

export default function AccountStatementListWithUpload() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { uploadBankDocument } = useCommand()
    const organisationCountry = useCurrentCountryCode()
    const [showOldStatements, setShowOldStatements] = useState(false)
    const [showOldYearlyStatements, setShowOldYearlyStatements] = useState(false)
    const date12MonthsAgo = subMonths(new Date(), 12)
    const oneYearAgo = subYears(new Date(), 1).getFullYear()

    const missingAccountStatements = useSelector(selectors.missingAccountStatementsSelector)
    const bankDocuments = useSelector((state) => state.bankDocuments)
    const bank = useSelector((state) => state.bank)
    const deposits = useSelector((state) => state.deposits)
    const [isUploadingAccountStatement, setIsUploadingAccountStatement] = useState(false)
    const hasOldStatements =
        missingAccountStatements.filter((statement) => new Date(statement.forMonth) < date12MonthsAgo).length > 0
    const depositorNames = useSelector((state) => state.depositorNames)

    if (!bank) {
        return null
    }

    const missingYearlyStatements = bank.depositorYearlyStatements
        .filter((statement) => statement.documentId === null)
        .filter((statement) => !depositorNames[statement.depositorId]?.startsWith('DELETED–'))
    const hasOldYearlyStatements = missingYearlyStatements.some((statement) => statement.year < oneYearAgo)

    function handleBankDocument(documentId) {
        navigate('/bank-reports/balance-reporting/' + documentId)
    }

    const onDrop = (files) => {
        setIsUploadingAccountStatement(true)
        files.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                console.log('Uploading file ' + file?.path)
                uploadBankDocument(bank.id, new Blob([reader.result], { type: file.type }), file?.path)
            }
            reader.readAsArrayBuffer(file)
        })
        setIsUploadingAccountStatement(false)
    }

    const tableRows = missingAccountStatements
        // Sort desc by forMonth, then asc by depositorName
        .sort((a, b) => {
            if (a.forMonth === b.forMonth) {
                return a.depositorName?.localeCompare(b.depositorName)
            }
            return b.forMonth?.localeCompare(a.forMonth)
        })
        .filter((statement) => showOldStatements || new Date(statement.forMonth) >= date12MonthsAgo)
        .map((statement) => (
            <TableRow key={`${statement.depositId}-${statement.forMonth}`} data-cy="missingDocumentListItem">
                <TableCell data-cy={'account'}>{formatAccount(statement.accountNo, organisationCountry)}</TableCell>
                <TableCell data-cy={'depositorName'}>{statement.depositorName}</TableCell>
                <TableCell data-cy={'period'}>
                    {statement.forMonth ? DateOutput.formatMonthYear(statement.forMonth) : ''}
                </TableCell>
            </TableRow>
        ))

    const tableRowsYearlyStatements = missingYearlyStatements
        // Sort desc by year, then asc by depositorName
        .sort((a, b) => {
            // Map depositorId to depositorName for sorting
            const nameA = depositorNames[a.depositorId] || 'Unknown'
            const nameB = depositorNames[b.depositorId] || 'Unknown'

            if (a.year === b.year) {
                return nameA.localeCompare(nameB)
            }
            // Numeric comparison for year (desc)
            return b.year - a.year
        })
        // Filter based on the condition for showing old statements
        .filter((statement) => showOldYearlyStatements || statement.year >= oneYearAgo)
        .map((statement) => (
            <TableRow key={`${statement.depositorId}-${statement.year}`} data-cy="missingYearlyDocumentListItem">
                <TableCell data-cy={'missingYearlyDepositorName'}>
                    {depositorNames[statement.depositorId] || 'Unknown'}
                </TableCell>
                <TableCell data-cy={'missingYearlyPeriod'}>{statement.year || ''}</TableCell>
            </TableRow>
        ))

    const cap = (name, max) => (name.length > max ? name.substr(0, max) + '...' : name)

    const periodSummary = (document) => {
        switch (document.type) {
            case 'YEARLY_STATEMENT':
                return 'Årsoppgave ' + (parse(document.month, 'yyyy M', new Date()).getFullYear() - 1)
            default:
                return DateOutput.formatMonthYear(parse(document.month, 'yyyy M', new Date()))
        }
    }

    const readingSummary = (document) => {
        const deposit = deposits.find((d) => d.id === document.depositId)
        return [
            ...(deposit?.account ? [formatAccount(deposit.account, organisationCountry)] : []),
            ...(document.depositorName ? [cap(document.depositorName, 25)] : []),
            ...(document.month ? [periodSummary(document)] : []),
        ].join(', ')
    }

    const getStatus = (document) => {
        const state = getState(document)
        if (state === State.processing) return t('pages-portfolio-bank.statementsStateProcessing')
        if (state === State.sent)
            return (
                <span>
                    <CheckMarkIcon className={styles.iconInList} />
                    {t('pages-portfolio-bank.statementsStateSent')}
                </span>
            )
        if (state === State.error)
            return (
                <span>
                    <WarningIcon className={styles.iconInList} />
                    {t('pages-portfolio-bank.statementsStateError')}
                </span>
            )
        if (state === State.notparsable)
            return (
                <span>
                    <WarningIcon className={styles.iconInList} />
                    <Tooltip title={t('pages-portfolio-bank.statementsStateNotParsableTooltip')}>
                        <a href={'#'}>{t('pages-portfolio-bank.statementsStateNotParsable')}</a>
                    </Tooltip>
                </span>
            )
        return '-'
    }

    const uploadedTableRows = bankDocuments.map((document) => (
        <TableRow
            key={document.documentId}
            onClick={() => handleBankDocument(document.documentId)}
            data-cy="uploadedDocumentListItem"
        >
            <TableCell data-cy={'received'}>{DateOutput.formatDateTime(document.received)}</TableCell>
            <TableCell data-cy={'summary'}>{readingSummary(document) || '-'}</TableCell>
            <TableCell data-cy={'period'}>{getStatus(document)}</TableCell>
        </TableRow>
    ))

    return (
        <>
            <PageHeader title={t('pages-portfolio-bank.statementsMonthlyReportsHeader')} />
            <PageLayout>
                <div style={{ maxWidth: '100rem' }}>
                    <Paper
                        title={t('pages-portfolio-bank.statementsDepositsMissingReportTitle')}
                        sx={{ width: '100%' }}
                    >
                        <p>{t('pages-portfolio-bank.statementsDepositsMissingReportInfo')}</p>

                        <Table>
                            <TableHeader>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsAccountNoLabel')}</TableHeaderCell>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsCustomerLabel')}</TableHeaderCell>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsPeriodLabel')}</TableHeaderCell>
                            </TableHeader>
                            {tableRows}
                        </Table>

                        {tableRows.length === 0 && (
                            <Box mt={2} textAlign="center">
                                <Typography variant="body2" color="textSecondary">
                                    {t('pages-portfolio-bank.statementsDepositsMissingReportNoMissing')}
                                </Typography>
                            </Box>
                        )}

                        {hasOldStatements && (
                            <FormControlLabel
                                sx={{ mt: 1 }}
                                control={
                                    <Checkbox
                                        checked={showOldStatements}
                                        id={'showOldStatementsCheckbox'}
                                        onChange={(e) => setShowOldStatements(!showOldStatements)}
                                    />
                                }
                                label={t('pages-portfolio-bank.statementsShowOldStatements')}
                            />
                        )}
                    </Paper>

                    <Paper title={t('pages-portfolio-bank.statementsYearlyMissingReportTitle')} sx={{ width: '100%' }}>
                        <p>{t('pages-portfolio-bank.statementsYearlyMissingReportInfo')}</p>

                        <Table>
                            <TableHeader>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsCustomerLabel')}</TableHeaderCell>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsYearLabel')}</TableHeaderCell>
                            </TableHeader>
                            {tableRowsYearlyStatements}
                        </Table>

                        {tableRowsYearlyStatements.length === 0 && (
                            <Box mt={2} textAlign="center">
                                <Typography variant="body2" color="textSecondary">
                                    {t('pages-portfolio-bank.statementsYearlyMissingReportNoMissing')}
                                </Typography>
                            </Box>
                        )}

                        {hasOldYearlyStatements && (
                            <FormControlLabel
                                sx={{ mt: 1 }}
                                control={
                                    <Checkbox
                                        checked={showOldYearlyStatements}
                                        id={'showOldYearlyStatementsCheckbox'}
                                        onChange={(e) => setShowOldYearlyStatements(!showOldYearlyStatements)}
                                    />
                                }
                                label={t('pages-portfolio-bank.statementsShowOldYearlyStatements')}
                            />
                        )}
                    </Paper>

                    <Paper title={t('pages-portfolio-bank.statementsReportTitle')} sx={{ width: '100%' }}>
                        <p>{t('pages-portfolio-bank.statementsReportInfo')}</p>

                        <FileUpload
                            uploadFiles={onDrop}
                            isUploadingDocument={isUploadingAccountStatement}
                            acceptedType={{ 'application/pdf': ['.pdf'], 'application/json': ['.json'] }}
                            multiple={true}
                        />

                        <p />

                        <Table>
                            <TableHeader>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsUploadTimeLabel')}</TableHeaderCell>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsSummaryLabel')}</TableHeaderCell>
                                <TableHeaderCell>{t('pages-portfolio-bank.statementsStatusLabel')}</TableHeaderCell>
                            </TableHeader>
                            {uploadedTableRows}
                        </Table>
                    </Paper>
                </div>
            </PageLayout>
        </>
    )
}
