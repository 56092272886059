import { PURPLE } from '#app/colors/colors'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import CakeForm from '#app/components/marketingForms/CakeForm/CakeForm'
import { getIconText, getOrganisationSelectorOptions } from '#app/components/PageHeader/PageHeader'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useCommand } from '#command'
import { InterestOutput, NumberInput } from '#components'
import HelpPopup from '#components/HelpPopup'
import { formatAccount, formatIban } from '#services/formatnumber'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import useCurrentDepositor from '#services/useCurrentDepositor'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import LoadingButton from '@mui/lab/LoadingButton'
import {
    Alert,
    Avatar,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import { isAfter, isBefore } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Confetti from './Confetti.svg?url'
import styles from './StartOrder.module.scss'
import './StartOrder.scss'
import { AdDto, DepositorUserDto } from '@fixrate/fixrate-query'
import { useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import { userIsAccountCreator } from '#app/utilities/accountCreatorUtils'

//TODO: Move this to Payload CMS
const cakeCampaignStart = new Date('2025-02-03')
const cakeCampaignEnd = new Date('2025-02-15')
export const CAKE_CAMPAIGN = {
    start: cakeCampaignStart,
    end: cakeCampaignEnd,
    title: 'Bli med på kakefest!',
    message:
        'Vi feirer at 20 milliarder har blitt plassert i rentefond gjennom Fixrate. Vi deler derfor ut kake til alle som gjør en bestilling av bankinnskudd eller fond innen 15. februar!',
    banner: {
        title: 'Gjør en bestilling og få tilsendt gratis kake!',
        message:
            'Vi feirer at 20 milliarder har blitt plassert i rentefond gjennom Fixrate. Vi deler derfor ut kake til alle som gjør en bestilling av bankinnskudd eller fond innen 15. februar!',
    },
    formId: 'e1763e43-afbe-488c-a6ce-1f72742f6dd4',
    active: isAfter(new Date(), cakeCampaignStart) && isBefore(new Date(), cakeCampaignEnd),
}

function userLabel(user: DepositorUserDto) {
    let label = '(ukjent navn)'
    if (user.lastName) {
        label = user.lastName
    }
    if (user.firstName) {
        label = user.firstName + ' ' + label
    }
    return label.trim()
}

function calculateAdMaxVolume(ad: AdDto) {
    if (ad.max > ad.remaining) {
        return ad.remaining
    } else {
        return ad.max
    }
}

type Props = {
    id: string
    cancelOrder?: () => void
    onCompleteUrl?: string
}

export default function StartOrder({ id: adId, cancelOrder, onCompleteUrl = '/marketplace' }: Props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { createOrder: createOrderCommand } = useCommand()

    // Values from redux
    const userId = useSelector((state) => state.session.id)
    const userAssociations = useSelector(selectors.userAssociationMap)
    const depositor = useCurrentDepositor()
    const organisationCountry = useCurrentCountryCode()
    const documents = useSelector((state) => state.documents)
    const ad = useSelector((state) => state.ads[state.ads.findIndex((ad) => ad.id === adId)])
    const bank = useSelector((state) => state.banks[ad.bankId])
    const product = useSelector((state) => state.products[ad.productId])
    const orders = useSelector((state) => state.orders)
    const deposits = useSelector((state) => state.deposits)
    const associations = useSelector((state) => state.session?.associations)
    const association = useSelector((state) => state.session?.association)
    const currency = useSelector((state) => state.session?.association?.currentCurrency)
    const onboardingWizardStatus = useOnboardingWizardStatus(depositor)

    const partnerId = associations?.find((asso) => asso.organisationType === 'PARTNER')?.organisation?.id
    const connectedProposal = depositor?.partnerRelations
        ?.find((relation) => relation.partnerId === partnerId)
        ?.partnerProposals?.find((proposal) => proposal.adId === adId)
    const lockedVolume = connectedProposal?.amount
    const hasLockedVolume = !!lockedVolume

    const [firstOrderDialogVisible, setFirstOrderDialogVisible] = useState(false)
    const showFirstOrderInformation = orders.length === 0 && deposits.length === 0
    const customerDeclaration = documents[depositor.customerDeclarationId]
    const customerDeclarationIsMissing = !customerDeclaration || !customerDeclaration.signedByAny
    const customerDeclarationHasExpired = depositor?.customerDeclarationExpired
    const depositorName = userAssociations[depositor.id].organisation.name
    const depositorTermsAccepted = depositor.termsAccepted
    const currentUseruserIsAccountCreator = userIsAccountCreator(depositor.users.find((user) => user?.id === userId))
    const organisationOptions = getOrganisationSelectorOptions(associations, association)

    const accountCreatorList = useMemo(
        () => depositor.users.filter((user) => userIsAccountCreator(user)),
        [depositor.users]
    )

    //Cake related code
    const [wantCake, setWantCake] = useState(false)
    const [cakeDialogVisible, setCakeDialogVisible] = useState(false)
    const [cakeSent, setCakeSent] = useState(false)
    const hasOrderedCake =
        orders.filter(
            (order) => order.depositor.id === depositor.id && isAfter(new Date(order.created), CAKE_CAMPAIGN.start)
        )?.length > 0
    const cakeButtonVisible =
        !hasOrderedCake && isAfter(new Date(), CAKE_CAMPAIGN.start) && isBefore(new Date(), CAKE_CAMPAIGN.end)

    const needsSettlementAccount = product.termsType === 'FIXED_TERMS' || currency === 'SEK'

    function getDefaultAccountCreator() {
        if (currentUseruserIsAccountCreator) {
            return userId
        }
        if (accountCreatorList.length === 1) {
            return accountCreatorList[0].id
        }

        return ''
    }

    function getDefaultSettlementAccount() {
        if (depositor.settlementAccounts.length === 1) {
            return depositor.settlementAccounts[0].id
        }
        return ''
    }

    // Field: volume
    const [volume, setVolume] = useState(lockedVolume || 0)
    const [volumeBlurred, setVolumeBlurred] = useState(false)
    const volumeError = () => {
        if (!volume) {
            return t('pages-marketplace.youMustSetAmount')
        }
        if (volume < ad.min) {
            return t('pages-marketplace.minimumAmountIs', { minVolume: ad.min })
        }
        if (volume > calculateAdMaxVolume(ad)) {
            return t('pages-marketplace.maximumAmountIs', { maxVolume: calculateAdMaxVolume(ad) })
        }
        return undefined
    }

    const showVolumeError = volumeBlurred && volumeError()

    // Field: settlementAccount
    const settlementAccountValidator = useStableValidator(
        'NOT_EMPTY',
        depositor.settlementAccounts.length === 0
            ? t('pages-marketplace.noSettlementAccount')
            : t('pages-marketplace.cannotBeEmpty')
    )
    const settlementAccount = useFieldState<string>(getDefaultSettlementAccount(), settlementAccountValidator)

    // Field: accountCreator
    const accountCreatorValidator = useCallback(
        ({ value }) => {
            if (!value) {
                if (accountCreatorList.length === 0) {
                    return t('pages-marketplace.noAccountHolder')
                } else {
                    return t('pages-marketplace.cannotBeEmpty')
                }
            } else {
                const selectedAccountCreator = accountCreatorList.find((user) => user.id === value)
                if (!selectedAccountCreator) {
                    return t('pages-marketplace.userIsNotAccountHolder', { user: userLabel(selectedAccountCreator) })
                }
                if (!selectedAccountCreator.hasSignedIdentificationDocument) {
                    return t('pages-marketplace.userHasNoIdDocument', { user: userLabel(selectedAccountCreator) })
                }
                if (!selectedAccountCreator.roles.includes('DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')) {
                    const authorizationDocument = documents[selectedAccountCreator.authorizationDocumentId]
                    if (!authorizationDocument || !authorizationDocument.signedByAll) {
                        return t('pages-marketplace.userHasNoAuthorization', {
                            user: userLabel(selectedAccountCreator),
                        })
                    }
                }
            }
        },
        [t, accountCreatorList, documents]
    )
    const accountCreator = useFieldState<string>(getDefaultAccountCreator(), accountCreatorValidator)

    // Used to suppress GUI updates while we are submitting an order
    const [submitting, setSubmitting] = useState(false)

    async function onSubmit() {
        // Do nothing if customer declaration is missing or terms not accepted
        if (customerDeclarationIsMissing || customerDeclarationHasExpired || !depositorTermsAccepted) {
            return
        }

        const isValidAccountCreator = accountCreator.validate()
        const isValidSettlementAccount = !needsSettlementAccount || settlementAccount.validate()
        const isValidVolume = !volumeError()

        if (isValidVolume && isValidAccountCreator && isValidSettlementAccount) {
            setSubmitting(true)
            const { waitForCommand, rejected } = await createOrderCommand(
                ad.id,
                volume,
                depositor.id,
                accountCreator.value,
                settlementAccount ? settlementAccount.value : null,
                connectedProposal?.proposalId
            )
            if (rejected) {
                setSubmitting(false)
            } else {
                const success = await waitForCommand(60_000)
                setSubmitting(false)
                if (success) {
                    if (!hasOrderedCake && wantCake) {
                        setCakeDialogVisible(true)
                    } else if (showFirstOrderInformation) {
                        setFirstOrderDialogVisible(true)
                    } else {
                        navigate(onCompleteUrl)
                        dispatch(
                            showConfirmationModal({
                                title: t('pages-marketplace.orderSentToBank', { bankName: bank.name }),
                                text: t('pages-marketplace.goToOrders'),
                                buttonText: t('common.continue'),
                                sentIcon: true,
                            })
                        )
                    }
                } else {
                    navigate(onCompleteUrl)
                    dispatch(
                        showConfirmationModal({
                            title: t('pages-marketplace.orderRegistered'),
                            text: t('pages-marketplace.orderRegisteredButNotConfirmed'),
                            buttonText: t('common.continue'),
                            sentIcon: false,
                        })
                    )
                }
            }
        }
    }

    const submitActionMarkup = () => {
        if (!submitting && (customerDeclarationIsMissing || customerDeclarationHasExpired)) {
            return (
                <p className="start-order__description">
                    <Link to={'/organizations/' + depositor.id + '/customerdecl'}>
                        {customerDeclarationIsMissing
                            ? t('pages-marketplace.customerDeclarationMustBeCreatedToOrder', { depositorName })
                            : t('pages-marketplace.customerDeclarationMustBeUpdatedToOrder', { depositorName })}
                    </Link>
                </p>
            )
        }
        if (!submitting && !depositorTermsAccepted) {
            return (
                <p className="start-order__description">
                    <Link to={'/organizations/' + depositor.id + '/terms'}>
                        {t('pages-marketplace.termsMustBeAccepted', { depositorName })}
                    </Link>
                </p>
            )
        }
        if (!submitting && calculateAdMaxVolume(ad) < ad.min) {
            return (
                <p className="start-order__description">
                    <Link to={'/marketplace'}>{t('pages-marketplace.soldOutVerbose')}</Link>
                </p>
            )
        }
        return (
            <Stack className="start-order__button-wrapper" spacing={1}>
                <LoadingButton loading={submitting} id="sendToBankButton" onClick={onSubmit} variant={'contained'}>
                    {t('pages-marketplace.placeOrder')}
                </LoadingButton>
                <Button
                    color={'secondary'}
                    sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                    onClick={cancelOrder}
                    variant={'text'}
                    disabled={submitting}
                >
                    {t('common.cancel')}
                </Button>
            </Stack>
        )
    }

    const interestLabel =
        product.type === 'FIXED' ? (
            <span>{InterestOutput.format(ad.interest)}</span>
        ) : (
            <span>
                {InterestOutput.formatMarginWithBenchmark(ad.nominalInterestRate, defaultBenchmark(ad.currency), t)}
            </span>
        )

    if (firstOrderDialogVisible) {
        return (
            <div data-cy="firstOrderConfirmation" className={styles.firstOrderConfirmation}>
                <img src={Confetti} alt="" />
                <div className={styles.contentWrap}>
                    <h2>{t('pages-marketplace.orderConfirmationHeader')}</h2>
                    <h4>{t('pages-marketplace.whatsNext')}</h4>
                    <p>{t('pages-marketplace.whatBankDoesNow')}</p>
                    <p>{t('pages-marketplace.emailWhenReadyInfo')}</p>
                    <p>{t('pages-marketplace.depositorCommitmentInfo')}</p>
                    <Button
                        data-cy="goToOrderOverview"
                        onClick={() => navigate('/orders/waiting')}
                        sx={{ marginTop: '2rem', marginBottom: '2rem' }}
                        fullWidth={true}
                        variant={'contained'}
                    >
                        {t('pages-marketplace.goToOrderOverview')}
                    </Button>
                    <Button
                        data-cy="goToMarketplace"
                        onClick={() => navigate(onCompleteUrl)}
                        fullWidth={true}
                        variant={'outlined'}
                    >
                        {t('pages-marketplace.goToMarketplace')}
                    </Button>
                </div>
            </div>
        )
    }

    if (cakeDialogVisible) {
        return !cakeSent ? (
            <CakeForm
                formId={CAKE_CAMPAIGN.formId}
                open={true}
                onFinish={() => {
                    setCakeSent(true)
                }}
                onClose={() => {
                    setCakeDialogVisible(false)
                    navigate(onCompleteUrl)
                }}
            />
        ) : (
            <FxDialog
                open={true}
                onClose={() => {
                    setCakeDialogVisible(false)
                    navigate(onCompleteUrl)
                }}
            >
                <Stack alignItems={'center'} justifyContent={'center'}>
                    <Box maxWidth={'40rem'}>
                        <FixrateIllustration name="emailSent" color="default" />
                    </Box>
                </Stack>
                <FxDialogContent sx={{ pb: 5, px: 5 }}>
                    <Stack alignItems={'center'} sx={{ textAlign: 'center' }} spacing={1}>
                        <h2>Din kakebestilling er mottatt!</h2>
                        <p>Du får nærmere beskjed når kaken din er på vei!</p>

                        <Button
                            data-cy="goToOrderOverview"
                            onClick={() => navigate('/orders/waiting')}
                            sx={{ marginTop: '2rem', marginBottom: '2rem' }}
                            fullWidth={true}
                            variant={'contained'}
                        >
                            {t('pages-marketplace.goToOrderOverview')}
                        </Button>
                        <Button
                            data-cy="goToMarketplace"
                            onClick={() => navigate(onCompleteUrl)}
                            fullWidth={true}
                            variant={'outlined'}
                        >
                            {t('pages-marketplace.goToMarketplace')}
                        </Button>
                    </Stack>
                </FxDialogContent>
            </FxDialog>
        )
    }

    return (
        <div className="start-order">
            <div className="start-order__header">
                <Avatar
                    sx={{
                        backgroundColor: PURPLE[50],
                        mr: 1,
                        width: '6rem',
                        height: '6rem',
                        '& i': { fontSize: '3rem', fontWeight: 500, color: PURPLE[500] },
                    }}
                >
                    <i className="ri-bank-line"></i>
                </Avatar>
                <div className="start-order__offer-info">
                    <h2 className="start-order__header-title">{bank.name}</h2>
                    <p className="start-order__ad-card-detail">{interestLabel}</p>
                    <Typography fontSize={'1.4rem'} variant="subtitle2">
                        {t('common.product-group.productId' + product.id)}
                    </Typography>
                </div>
            </div>
            <Divider sx={{ my: 1 }} />
            <dl>
                <dt>
                    <HelpPopup text={t('pages-marketplace.depositHelpText')}>
                        <label htmlFor="field-amount">{t('pages-marketplace.depositAmount')}</label>
                    </HelpPopup>
                    <Typography variant="labelDescription">
                        {t('pages-marketplace.depositLimit', {
                            adMinAmount: ad.min,
                            adMaxAmount: calculateAdMaxVolume(ad),
                            currency: currency,
                        })}
                    </Typography>
                </dt>
                <dd>
                    <div className="start-order__amount-layout">
                        <div className="start-order__amount-unit">
                            <NumberInput
                                id="field-amount"
                                disabled={!!lockedVolume}
                                className={'start-order__amount-input'}
                                onChange={(val) => setVolume(val)}
                                onBlur={() => setVolumeBlurred(true)}
                                value={volume === 0 ? null : volume}
                                title={t('pages-marketplace.amountTitle')}
                                data-cy="amount"
                                sx={{
                                    '& .Mui-disabled': {
                                        backgroundColor: 'rgba(0,0,0,0.05)',
                                        color: PURPLE[900],
                                        WebkitTextFillColor: PURPLE[900],
                                    },
                                }}
                            />
                            <span className="start-order__amount-label">
                                {t('pages-marketplace.millionAndCurrency', { currency: currency })}
                            </span>
                        </div>
                    </div>
                    <p className="field-error-message">{showVolumeError ? volumeError() : ''}</p>
                </dd>
            </dl>
            {hasLockedVolume && (
                <Alert sx={{ mb: 3 }} severity="info">
                    {t('pages-marketplace.preOrderedVolume', {
                        amount: CurrencyOutputObject(lockedVolume, {
                            minimumDecimals: 0,
                            maximumDecimals: 0,
                            withCurrency: false,
                        }),
                        currency: currency,
                        name:
                            connectedProposal?.receiverPerson?.firstName +
                            ' ' +
                            connectedProposal?.receiverPerson?.lastName,
                    })}
                </Alert>
            )}
            {Object.keys(userAssociations).length > 1 && (
                <dl className="start-order__inputvalue">
                    <dt className="start-order__inputvalue-label">
                        <label>{t('pages-marketplace.organisation')}</label>
                    </dt>
                    <dd className="start-order__inputvalue-value">
                        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                            <Avatar
                                sx={{
                                    height: '3rem',
                                    width: '3rem',
                                    fontSize: '1.2rem',
                                    fontWeight: '600',
                                    backgroundColor: organisationOptions.find((o) => o.organisationId === depositor.id)
                                        .iconColor,
                                }}
                            >
                                {getIconText(depositor.name)}
                            </Avatar>
                            <Typography sx={{ fontSize: '1.4rem' }}>{depositor.name}</Typography>
                        </Stack>
                    </dd>
                </dl>
            )}
            {onboardingWizardStatus?.wizardActive && onboardingWizardStatus?.progress < 100 ? (
                <Stack my={1} width="100%" spacing={1.5}>
                    <Divider sx={{ mb: 1 }} />
                    <Stack spacing={0.4}>
                        <Typography variant="h4">{t('common.finishRegistration')}</Typography>
                        <Typography variant="body2">
                            Du må fullføre registreringen av organisasjonen før du kan gjøre din første bestilling
                        </Typography>
                        <Box pt={0.5}>
                            <Typography variant="caption" fontWeight="700" color={PURPLE[500]}>
                                {InterestOutput.formatWithDecimals(onboardingWizardStatus?.progress, 0)}{' '}
                                {t('pages-OrganizationWizard.statusFinished')}
                            </Typography>
                            <LinearProgress
                                sx={{ width: '100%', marginTop: '0.6rem', height: '0.8rem', borderRadius: '10rem' }}
                                variant={'determinate'}
                                value={onboardingWizardStatus?.progress}
                            />
                        </Box>
                    </Stack>
                    <Box>
                        <Button
                            fullWidth
                            sx={{ mt: 1 }}
                            onClick={() => navigate('/organizations/onboarding/')}
                            variant={'contained'}
                        >
                            {t('common.finishRegistration')}
                        </Button>
                    </Box>
                </Stack>
            ) : (
                <>
                    <dl className="start-order__inputvalue">
                        <dt className="start-order__inputvalue-label">
                            <HelpPopup text={t('pages-marketplace.accountHolderHelpText')}>
                                <label htmlFor="field-depositor-person">{t('pages-marketplace.accountHolder')}</label>
                            </HelpPopup>
                        </dt>
                        <dd className="start-order__inputvalue-value">
                            <FormControl>
                                <Select
                                    value={accountCreator.value}
                                    onChange={(e) => accountCreator.setValue(e.target.value)}
                                    id="field-depositor-person"
                                    name="accountCreator"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem key="empty" value="" disabled>
                                        {t('pages-marketplace.selectAccountHolder')}
                                    </MenuItem>
                                    {accountCreatorList.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {userLabel(user)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <p className="field-error-message">
                                {!accountCreator.valid && accountCreator.errorMessage}
                            </p>
                        </dd>
                    </dl>

                    {needsSettlementAccount && (
                        <dl className="start-order__inputvalue">
                            <dt className="start-order__inputvalue-label">
                                <HelpPopup text={t('pages-marketplace.settlementAccountHelpText')}>
                                    <label htmlFor="field-from-account">
                                        {t('pages-marketplace.settlementAccount')}
                                    </label>
                                </HelpPopup>
                            </dt>
                            <dd className="start-order__inputvalue-value">
                                <FormControl>
                                    <Select
                                        value={settlementAccount.value}
                                        onChange={(e) => settlementAccount.setValue(e.target.value)}
                                        id="field-from-account"
                                        name="settlementAccount"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem key="empty" value="" disabled>
                                            {t('pages-marketplace.selectSettlementAccount')}
                                        </MenuItem>
                                        {depositor.settlementAccounts.map((account) => (
                                            <MenuItem
                                                key={account.id}
                                                value={account.id}
                                                disabled={account.id === settlementAccount.value}
                                            >
                                                {account.name}&nbsp;(
                                                {account.account
                                                    ? formatAccount(account.account, organisationCountry)
                                                    : formatIban(account.iban)}
                                                )
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <p className="field-error-message">
                                    {!settlementAccount.valid && settlementAccount.errorMessage}
                                </p>
                            </dd>
                        </dl>
                    )}
                    {cakeButtonVisible && (
                        <Box sx={{ p: 2, backgroundColor: PURPLE[50] }}>
                            <Box>
                                <Typography fontSize={'1.6rem'} fontWeight={'700'}>
                                    {CAKE_CAMPAIGN.title}
                                </Typography>
                                <Typography fontSize={'1.4rem'}>{CAKE_CAMPAIGN.message}</Typography>
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={wantCake}
                                        onChange={(_, newVal) => setWantCake(newVal)}
                                        name="wantCake"
                                    />
                                }
                                label="Jeg vil ha kake"
                            />
                        </Box>
                    )}
                    {submitActionMarkup()}
                </>
            )}
        </div>
    )
}
