import { Fragment, useEffect, useState } from 'react'
import styles from './MovieViewer.module.scss'

export const MovieViewer = ({ vimeoUrl, videoActive }: { vimeoUrl: string; videoActive: (boolean) => void }) => {
    const [width, setWidth] = useState(1280)
    const [height, setHeight] = useState(720)

    useEffect(() => {
        if (window.innerWidth < 1280) {
            const scalingFactor = window.innerWidth / 1280
            setWidth(1280 * scalingFactor)
            setHeight(720 * scalingFactor)
        }
    }, [])
    return (
        <Fragment>
            <div className={styles.videoContainer}>
                <div className={styles.backgroundLayer} onClick={() => videoActive(false)}></div>
                <div className={styles.videoWrapper}>
                    <button className={styles.closeVideo} onClick={() => videoActive(false)}>
                        <i className="ri-close-line" />
                        <span>Lukk video</span>
                    </button>
                    <iframe
                        id="fixrate-video"
                        className={styles.videoFrame}
                        src={vimeoUrl}
                        width={width}
                        height={height}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen={true}
                        title="Fixrate &amp;ndash; Hvordan fungerer Fixrate"
                    ></iframe>
                </div>
            </div>
        </Fragment>
    )
}
