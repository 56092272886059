import DepositorOfferDetails from '#pages/DepositorOfferDepositor/DepositorOfferOverview/DepositorOfferDetails'
import { Navigate, Route, Routes } from 'react-router-dom'
import DepositorOfferOverview from './DepositorOfferOverview/DepositorOfferOverview'
import DepositorOfferWizard from './DepositorOfferWizard/DepositorOfferWizard'

export default function DepositorOfferDepositor() {
    return (
        <Routes>
            <Route path="" element={<Navigate to={{ pathname: '/depositoroffer/overview' }} />} />
            <Route path="overview" element={<DepositorOfferOverview />} />
            <Route path=":depositorOfferId/edit/:step/:modal" element={<DepositorOfferWizard />} />
            <Route path=":depositorOfferId/edit/:step" element={<DepositorOfferWizard />} />
            <Route path=":depositorOfferId/edit" element={<DepositorOfferWizard />} />
            <Route path=":depositorOfferId" element={<DepositorOfferDetails />} />
        </Routes>
    )
}
