import UsersIcon from './icons/brukere.svg'
import UsersActiveIcon from './icons/brukere-active.svg'

import IntegrationsIcon from './icons/integrasjoner.svg'
import IntegrationsActiveIcon from './icons/integrasjoner-active.svg'

import CustomerDeclaration from './icons/kundeerklaering.svg'
import CustomerDeclarationActive from './icons/kundeerklaering-active.svg'

import SettlementAccount from './icons/oppgjorskonto.svg'
import SettlementAccountActive from './icons/oppgjorskonto-active.svg'

import TermsAndAgreements from './icons/vilkar-avtaler.svg'
import TermsAndAgreementsActive from './icons/vilkar-avtaler-active.svg'
import { ReactSVGElement } from 'react'
import { Avatar } from '@mui/material'
import { BURNT_ORANGE, SUCCESS_GREEN } from '#app/colors/colors'

type Icons = {
    normal: string
    active: string
}

type MenuIcons = {
    users: Icons
    integrations: Icons
    customerDeclaration: Icons
    settlementAccount: Icons
    termsAndAgreements: Icons
}

const SubMenuIcon: MenuIcons = {
    users: {
        normal: UsersIcon,
        active: UsersActiveIcon,
    },
    integrations: {
        normal: IntegrationsIcon,
        active: IntegrationsActiveIcon,
    },
    customerDeclaration: {
        normal: CustomerDeclaration,
        active: CustomerDeclarationActive,
    },
    settlementAccount: {
        normal: SettlementAccount,
        active: SettlementAccountActive,
    },
    termsAndAgreements: {
        normal: TermsAndAgreements,
        active: TermsAndAgreementsActive,
    },
}

const IconStyling = {
    position: 'absolute',
    transform: 'translate(2.5rem, 2.5rem)',
    width: '3rem',
    height: '3rem',
    fontSize: '2.6rem',
    backgroundColor: 'white',
}

const OkIcon = () => (
    <Avatar sx={{ color: SUCCESS_GREEN[500], ...IconStyling }}>
        <i className="ri-checkbox-circle-line" />
    </Avatar>
)

const WarningIcon = () => (
    <Avatar sx={{ color: BURNT_ORANGE[500], ...IconStyling }}>
        <i className="ri-error-warning-line" />
    </Avatar>
)

const DownArrow = () => (
    <svg viewBox="0 0 99 34">
        <path d="M49.5 0L98.4304 33.75H0.569565L49.5 0Z" />
    </svg>
)

export { SubMenuIcon, WarningIcon, OkIcon, DownArrow }
