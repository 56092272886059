import config from '#app/config'
import { Box, Stack } from '@mui/material'
import { FOREST_GREEN } from '#app/colors/colors'

export default function DummyChatBubble() {
    const open = window.location.hash.indexOf('hs-chat-open') !== -1

    return config().hubspot || config().isProduction ? null : (
        <Box
            id={'hubspot-messages-iframe-container'}
            sx={{
                position: 'absolute',
                zIndex: 1000,
                bottom: 16,
                right: 18,
                width: '5.8rem',
                aspectRatio: '1/1',
                color: 'white',
                backgroundColor: FOREST_GREEN[500],
                borderRadius: '100%',
                fontSize: '2.8rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 0 0.5rem 0.2rem rgba(0,0,0,0.2)',
                cursor: 'pointer',
            }}
            onClick={() => (open ? (window.location.hash = '') : (window.location.hash = 'hs-chat-open'))}
        >
            {open ? <i className={'ri-close-line'} /> : <i className={'ri-chat-smile-2-fill'} />}
            {open && (
                <Stack
                    sx={{
                        position: 'absolute',
                        zIndex: 1000,
                        bottom: 80,
                        right: 0,
                        width: '37.5rem',
                        height: '53rem',
                        backgroundColor: 'white',
                        borderRadius: '0.8rem',
                        fontSize: '2.8rem',
                        boxShadow: '0 0 0.5rem 0.2rem rgba(0,0,0,0.2)',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: FOREST_GREEN[500],
                            height: '6.6rem',
                            borderRadius: '0.8rem 0.8rem 0 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        TRÆNG DU HJÆLP??
                    </Box>
                    <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src="https://fixrate-public.s3.eu-central-1.amazonaws.com/annet/support.png" alt="" />
                    </Box>
                </Stack>
            )}
        </Box>
    )
}
