import { SILVER_GRAY } from '#app/colors/colors'
import { InterestOutput, LoadingSpinner, NumberInput } from '#app/components'
import AsyncButton from '#app/components/Button/AsyncButton'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { useCommand } from '#app/services/beta'
import { useSelector } from '#app/state/useSelector'
import useTableSort, { Column } from '#services/useTableSort'
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PotentialInterestApi from '#app/pages/Onboarding/InterestCalculator/api/PotentialInterestApi'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import CopyPasteMessage from './CopyPasteMessage'
import { AccountantCustomerCompanyDto, PartnerCustomerDto, PartnerUserDto } from '@fixrate/fixrate-query'
import { Clear } from '@mui/icons-material'
import { sanitizeString } from '../CustomerOverview'
import { KeyFigureCard } from '#app/components/KeyFigureCard/KeyFigureCard'
import useAccountantClients from '#app/services/useAccountantClients'
import { useDebouncedCallback } from 'use-debounce'
import { PartnerDto } from '@fixrate/fixrate-security'
import RegisterCustomer from '../../RegisterCustomer/RegisterCustomer'

type Row = AccountantCustomerCompanyDto & {
    depositorId?: string
    currentVolume: number
    currentInterest?: number
    potentialValue?: number
}

const prospectMessageText = (
    oldInterest: string,
    newInterest: string,
    potentialgain: string,
    partnerUser: PartnerUserDto
) =>
    `Hei,

Det står i dag et større beløp på en av dine kontoer med en rente på ${oldInterest}.

Dette er lavere enn det som er tilgjengelig i markedet, hvor en plasseringskonto med 31 dagers oppsigelsestid kan gi deg ${newInterest} flytende rente.

Ved å flytte midlene vil du tjene ca ${potentialgain} mer i renteinntekter i året.

Du beholder fortsatt hovedbankavtalen din og trenger ikke å flytte over andre banktjenester.

Vi løser det praktiske med banken, tror du dette kan være av interesse for dere?

Med vennlig hilsen,
${partnerUser.firstName} ${partnerUser.lastName}
`

export default function AccountantProspects() {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const sessionId = useSelector((state) => state.session.id)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [prospectForMessageDialog, setProspectForMessageDialog] = useState<Row>(null)
    const [lastUpdatedProspectIdentity, setLastUpdatedProspectIdentity] = useState<string | null>(null)

    const [selectedProspectGroup, setSelectedProspectGroup] = useState<'all' | 'over-1-mill'>('over-1-mill')
    const [selectedRegions, setSelectedRegions] = useState<string[]>([])
    const [customerSearchQuery, setCustomerSearchQuery] = useState('')
    const { updatePartnerProspect } = useCommand()
    const partner = useSelector((state) => state.partner)
    const currentPartnerUser = partner?.users?.find((u) => u.id === sessionId)
    const { clients, isLoading } = useAccountantClients()
    const prospects = clients
        ? clients?.map((customer) => {
              const prospect = partner.prospects.find((p) => p.nationalIdentity === customer.nationalIdentity)
              const volume =
                  prospect?.balanceCashAndDeposits ??
                  customer.annualAccounts.sort((a, b) => b.accountingYear - a.accountingYear)[0].balanceCashAndDeposits
              const currentInterest = prospect?.currentInterest ?? null
              const potentialValue = prospect?.potentialValue
              return {
                  ...customer,
                  depositorId: partner.customers.find((c) => c.nationalIdentity === customer.nationalIdentity)
                      ?.depositorId,
                  currentVolume: volume,
                  currentInterest: currentInterest,
                  potentialValue: potentialValue,
              }
          })
        : []

    const prospectsOver1Mill = prospects.filter((p) => p.currentVolume >= 1e6)
    const selectedProspects = selectedProspectGroup === 'over-1-mill' ? prospectsOver1Mill : prospects
    const clientRegions = [
        ...new Set(selectedProspects.map((p) => p.municipality || p.postPlace).filter((region) => !!region)),
    ].sort()
    const filteredProspects = useMemo(
        () =>
            selectedProspects
                .filter(
                    (customer) =>
                        !customerSearchQuery ||
                        sanitizeString(`${customer.name}${customer.nationalIdentity}`).includes(
                            sanitizeString(customerSearchQuery)
                        )
                )
                ?.filter(
                    (p) =>
                        selectedRegions.length === 0 ||
                        selectedRegions.includes(p.municipality) ||
                        selectedRegions.includes(p.postPlace)
                ) ?? [],
        [customerSearchQuery, selectedProspects, selectedRegions]
    )

    useEffect(() => {
        setPage(0)
    }, [filteredProspects])

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<Row>(
        filteredProspects,
        'currentVolume',
        'desc'
    )

    const columns: Column<Row>[] = [
        {
            id: 'name',
            label: t('pages-customers.customer'),
        },
        {
            id: 'currentVolume',
            label: t('pages-customers.cashInBank'),
        },
        {
            id: 'currentInterest',
            label: t('pages-customers.currentInterest'),
        },
        {
            id: 'potentialValue',
            label: t('pages-customers.potentialGain'),
        },
    ]

    const Pagination = (
        <TablePagination
            labelRowsPerPage={t('pages-customers.customersPerPage')}
            labelDisplayedRows={({ from, to, count }) => t('pages-customers.customersDisplayed', { from, to, count })}
            rowsPerPageOptions={[50, 100, 250, 500]}
            component="div"
            count={filteredProspects.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => {
                setRowsPerPage(+e.target.value)
                setPage(0)
            }}
        />
    )

    const updateProspect = async (prospect: Row, updatedFields: Partial<Row>) => {
        const updatedProspect = { ...prospect, ...updatedFields }
        const potentialMonthlyInterest = await PotentialInterestApi.getCalculatedInterest(
            updatedProspect.currentVolume / 1e6
        )
        const currentInterestEarnings = (updatedProspect.currentVolume * updatedProspect.currentInterest) / 100
        const potentialValue = potentialMonthlyInterest * 12 - currentInterestEarnings
        const { waitForCommand } = await updatePartnerProspect(
            partner.id,
            updatedProspect.nationalIdentity,
            updatedProspect.currentInterest,
            updatedProspect.currentVolume,
            potentialValue
        )
        await waitForCommand()
        setLastUpdatedProspectIdentity(updatedProspect.nationalIdentity)
    }

    const totalVolume = filteredProspects.reduce((acc, customer) => acc + customer.currentVolume, 0)
    const numberOfregistered = filteredProspects.filter((fp) =>
        partner.customers.find((c) => c.nationalIdentity === fp.nationalIdentity)
    ).length
    const percentageRegistered = (numberOfregistered / filteredProspects.length) * 100

    useEffect(() => {
        if (lastUpdatedProspectIdentity) {
            const index = sortedRows.findIndex((p) => p.nationalIdentity === lastUpdatedProspectIdentity)
            const prospectPage = Math.floor(index / rowsPerPage)
            if (index !== -1 && prospectPage !== page) {
                setPage(prospectPage)
            }
            setTimeout(() => {
                document
                    .getElementById(`row-${lastUpdatedProspectIdentity}`)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 200)
            setLastUpdatedProspectIdentity(null)
        }
    }, [sortedRows, lastUpdatedProspectIdentity, rowsPerPage, page])

    if (isLoading) {
        return <LoadingSpinner />
    }

    function changeProspectGroup(group: 'all' | 'over-1-mill') {
        setPage(0)
        setSelectedProspectGroup(group)
    }

    return (
        <>
            <Stack spacing={3}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction={{ lg: 'row' }} gap={2} spacing={2}>
                        <KeyFigureCard
                            direction="row"
                            value={Currency(totalVolume, { decimals: 0 })}
                            label={t('pages-customers.totalPotentialVolume')}
                            remixIcon="ri-coin-line"
                        />
                        <KeyFigureCard
                            direction="row"
                            value={filteredProspects.length.toString()}
                            label={t('pages-customers.numberOfCustomers')}
                            remixIcon="ri-building-line"
                        />
                        <KeyFigureCard
                            direction="row"
                            value={InterestOutput.format(percentageRegistered ? percentageRegistered : 0)}
                            label={t('pages-customers.shareRegisteredOnFixrate')}
                            remixIcon="ri-checkbox-circle-line"
                        />
                    </Stack>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent={'space-between'} alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <FormControlLabel
                            sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}
                            control={
                                <Checkbox
                                    checked={selectedProspectGroup === 'over-1-mill'}
                                    onChange={(e, val) => changeProspectGroup(val ? 'over-1-mill' : 'all')}
                                />
                            }
                            label={t('pages-customers.customersOver1Mill')}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Autocomplete
                            multiple
                            value={selectedRegions}
                            onChange={(e, val) => setSelectedRegions(val)}
                            options={clientRegions ?? []}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        color="primary"
                                        size="small"
                                        label={option}
                                        {...getTagProps({ index })}
                                        key={option}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    label={t('pages-customers.filterByRegion')}
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            )}
                        />
                        <TextField
                            size="small"
                            placeholder={t('pages-customers.customerSearch')}
                            value={customerSearchQuery}
                            onChange={(event) => setCustomerSearchQuery(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        sx={{
                                            opacity: customerSearchQuery.length === 0 ? 0 : 1,
                                            transformOrigin: 'center',
                                            transform: customerSearchQuery.length === 0 ? 'scale(0)' : 'scale(1)',
                                            transition: '0.2s ease-in-out',
                                        }}
                                    >
                                        <IconButton size={'small'} onClick={() => setCustomerSearchQuery('')}>
                                            <Clear fontSize={'small'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                </Stack>
                <Divider />
                {filteredProspects.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={sortKey === column.id ? sortDirection : false}
                                            >
                                                <TableSortLabel
                                                    active={sortKey === column.id}
                                                    direction={
                                                        sortKey === column.id
                                                            ? sortDirection === 'asc'
                                                                ? 'asc'
                                                                : 'desc'
                                                            : 'asc'
                                                    }
                                                    onClick={() => setSorting(column.id)}
                                                >
                                                    {column.label}
                                                    {sortKey === column.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {sortDirection === 'desc'
                                                                ? 'sorted descending'
                                                                : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedRows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((prospect) => (
                                            <ProspectRow
                                                partner={partner}
                                                prospect={prospect}
                                                key={prospect.nationalIdentity}
                                                onChange={updateProspect}
                                                onSuggestForCustomer={() => setProspectForMessageDialog(prospect)}
                                            />
                                        ))}
                                </TableBody>
                            </Table>
                            {filteredProspects.length > rowsPerPage && Pagination}
                        </TableContainer>
                        <Typography variant="body2">{t('pages-customers.aboutClientListData')}</Typography>
                    </>
                ) : (
                    <Typography variant="emptyState" fontSize={'2rem'} color={SILVER_GRAY[500]}>
                        {t('pages-customers.noCustomers')}
                    </Typography>
                )}
            </Stack>
            {prospectForMessageDialog && (
                <FxDialog maxWidth="sm" fullWidth open={true} onClose={() => setProspectForMessageDialog(null)}>
                    <FxDialogTitle onClose={() => setProspectForMessageDialog(null)}>
                        {t('pages-customers.potentialGain')}
                    </FxDialogTitle>
                    <FxDialogContent>
                        {prospectForMessageDialog.potentialValue > 0 ? (
                            <Stack spacing={2}>
                                <Box>
                                    <InputLabel>Rentegevinst</InputLabel>
                                    <Typography variant="interestBig">
                                        {Currency(prospectForMessageDialog.potentialValue, {
                                            decimals: 0,
                                        })}
                                    </Typography>
                                </Box>
                                <Box>
                                    <CopyPasteMessage
                                        linkText=""
                                        messageText={prospectMessageText(
                                            InterestOutput.format(prospectForMessageDialog.currentInterest),
                                            InterestOutput.format(
                                                ((prospectForMessageDialog.potentialValue +
                                                    (prospectForMessageDialog.currentInterest *
                                                        prospectForMessageDialog.currentVolume) /
                                                        100) /
                                                    prospectForMessageDialog.currentVolume) *
                                                    100
                                            ),
                                            Currency(prospectForMessageDialog.potentialValue, {
                                                decimals: 0,
                                            }),
                                            currentPartnerUser
                                        )}
                                        subject="Forbedring av rentevilkår"
                                    />
                                </Box>
                            </Stack>
                        ) : (
                            <Stack spacing={2} mt={2}>
                                <Typography color={SILVER_GRAY[500]}>
                                    {t('pages-customers.noCurrentInterestGain')}
                                </Typography>
                            </Stack>
                        )}
                    </FxDialogContent>
                </FxDialog>
            )}
        </>
    )
}

type RowProps = {
    prospect: Row
    partner: PartnerDto
    onChange: (prospect: Row, updatedFields: Partial<Row>) => void
    onSuggestForCustomer: () => void
}

const ProspectRow = ({ prospect, partner, onChange, onSuggestForCustomer }: RowProps) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()

    const [loadingPotentialValue, setLoadingPotentialValue] = useState(false)
    const [registerCustomerOpen, setRegisterCustomerOpen] = useState(false)
    const [interest, setInterest] = useState<number | null>(prospect.currentInterest ?? null)
    const [currentVolume, setCurrentVolume] = useState(prospect.currentVolume)

    const handleChange = useDebouncedCallback(async (updatedFields: Partial<Row>) => {
        setLoadingPotentialValue(true)
        await onChange(prospect, updatedFields)
        setLoadingPotentialValue(false)
    }, 1000)

    const potentialValueText = (potentialValue?: number) => {
        if (!potentialValue) {
            return '-'
        } else if (potentialValue > 0) {
            return Currency(potentialValue)
        }
        return t('pages-customers.customerHasGoodInterest')
    }

    return (
        <TableRow data-cy="prospectTableRow" id={`row-${prospect.nationalIdentity}`} key={prospect.nationalIdentity}>
            <TableCell
                sx={{
                    fontWeight: '600',
                    width: '40rem',
                }}
            >
                {prospect.depositorId ? (
                    <Link href={`/customer/${prospect.depositorId}`} color="inherit">
                        {prospect.name}
                    </Link>
                ) : (
                    prospect.name
                )}
            </TableCell>
            <TableCell>
                <NumberInput
                    formatFn={(v) => Currency(v, { decimals: 0 })}
                    value={currentVolume}
                    InputProps={{ disableInjectingGlobalStyles: true }} // This saves literal seconds of rendering time
                    sx={{ width: '16rem' }}
                    onChange={async (val) => {
                        setCurrentVolume(val)
                        await handleChange({ currentVolume: val })
                    }}
                />
            </TableCell>
            <TableCell>
                <NumberInput
                    value={interest}
                    onChange={async (val) => {
                        setInterest(val)
                        await handleChange({ currentInterest: val })
                    }}
                    sx={{ width: '10rem' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        disableInjectingGlobalStyles: true, // This saves literal seconds of rendering time
                    }}
                />
            </TableCell>
            <TableCell>
                {loadingPotentialValue ? <CircularProgress /> : potentialValueText(prospect.potentialValue)}
            </TableCell>
            <TableCell>
                {!!prospect.potentialValue && prospect.potentialValue > 0 && (
                    <Button variant={'outlined'} size={'small'} onClick={onSuggestForCustomer}>
                        {t('pages-customers.informCustomer')}
                    </Button>
                )}
            </TableCell>
            <TableCell>
                {prospect.depositorId ? (
                    <Chip
                        label={t('pages-customers.registeredOnFixrate')}
                        variant="outlined"
                        color="success"
                        icon={<i className="ri-check-line" />}
                    />
                ) : (
                    <AsyncButton variant="contained" size="small" onClick={() => setRegisterCustomerOpen(true)}>
                        {t('pages-customers.registerConfirmationHeading')}
                    </AsyncButton>
                )}
                <RegisterCustomer
                    visible={registerCustomerOpen}
                    selectedProspect={prospect}
                    hide={() => setRegisterCustomerOpen(false)}
                />
            </TableCell>
        </TableRow>
    )
}
