import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import { Divider } from '@mui/material'
import { useSelector } from '#state/useSelector'
import Paper from '#components/Paper'
import PeriodPicker from '#components/PeriodPicker/PeriodPicker'
import LoadingSpinner from '#components/LoadingSpinner'
import { ReportDownload } from '#pages/portfolio-depositor/Reports/ReportDownload'
import * as selectors from '#state/selectors'
import { PageHeader, PageLayout } from '#components'

function getCurrentQuarter() {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const quarter = Math.ceil(month / 3)
    return year + '-Q' + quarter
}

export default function FundInvoiceOverview() {
    const { t } = useTranslation()
    const [fundInvoicePeriod, setFundInvoicePeriod] = useState(getCurrentQuarter())
    const showFundInvoice = useSelector(selectors.isFundIntermediaryAdminSelector)
    const allowedPeriods = 'YHQ' // Only allow selection of year, half year, and quarter.

    return (
        <Fragment>
            <PageHeader title={t('pages.fund-invoice.title')} />
            <PageLayout>
                <Paper>
                    <h3>{t('pages.fund-invoice.title')}</h3>
                    <p>{t('pages.fund-invoice.p1')}</p>
                    <p>{t('pages.fund-invoice.p2')}</p>

                    <PeriodPicker
                        value={fundInvoicePeriod}
                        onChange={setFundInvoicePeriod}
                        showPeriods={allowedPeriods}
                    />

                    {!showFundInvoice && <LoadingSpinner text={t('pages.fund-invoice.reports-loading')} />}
                    <Divider sx={{ mt: 2, mb: 1 }} />
                    {showFundInvoice && (
                        <ReportDownload
                            name={t('pages.fund-invoice.report-download-name')}
                            description={fundInvoicePeriod}
                            link={`/document/report/FUND_INVOICE_POWEROFFICE_EXPORT?period=${fundInvoicePeriod}`}
                            dataCy="fundInvoiceSection"
                            keyValue={`fundInvoice-${fundInvoicePeriod}`}
                            pdf={false}
                            excel={true}
                        />
                    )}
                </Paper>
            </PageLayout>
        </Fragment>
    )
}
