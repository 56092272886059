import { Children, cloneElement, forwardRef, JSXElementConstructor, ReactElement } from 'react'
import { Transition } from 'react-transition-group'
import styles from './Section.module.scss'

interface EnterProps {
    duration?: number
    // disabled rule because the type is a subtype of React.ReactNode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: ReactElement<any, string | JSXElementConstructor<any>>
    timeout?: number
    hide?: boolean
}

function Enter({ duration = 750, children, timeout = 0, hide = false, ...props }: EnterProps) {
    const testMode = !!window['Cypress']
    duration = testMode ? 0 : duration

    const defaultStyle = {
        transition: `transform ${duration}ms ease-in-out`,
        transform: 'scale(0) translateY(0%)',
    }

    const transitionStyles = {
        entering: { transform: 'scale(0) translateY(100%)' },
        entered: { transform: 'scale(1) translateY(0%)' },
        exiting: { transform: 'scale(0) translateY(-100%)' },
        exited: { transform: 'scale(0) translateY(-100%)' },
    }

    return (
        <Transition
            mountOnEnter={true}
            unmountOnExit={true}
            timeout={{
                appear: timeout,
                enter: 0,
                exit: duration,
            }}
            appear={true}
            exit={true}
            in={!hide}
            {...props}
        >
            {(state) =>
                Children.map(children, (Child) =>
                    cloneElement(Child, {
                        style: {
                            ...defaultStyle,
                            ...transitionStyles[state],
                        },
                    })
                )
            }
        </Transition>
    )
}

type Props = {
    title?: string
    children: React.ReactNode
    hide?: boolean
    paths?: string[]
    path?: string
}

const Section = forwardRef<HTMLDivElement, Props>(({ title = '', children = null, hide = false }: Props, ref) => {
    return (
        <Enter hide={hide}>
            <div ref={ref} className={styles.body}>
                {title ? <h3 className={styles.header}>{title}</h3> : null}
                {children}
            </div>
        </Enter>
    )
})

Section.displayName = 'Section'

export default Section
