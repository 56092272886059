import { PURPLE } from '#app/colors/colors'
import { Animated } from '#app/components'
import config from '#app/config'
import { FundDto, FundShareClassDto } from '@fixrate/fixrate-query'
import { DescriptionSharp } from '@mui/icons-material'
import { Alert, Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { eachMonthOfInterval, eachYearOfInterval, subMonths, format, isSameYear } from 'date-fns'
import { nb } from 'date-fns/locale'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FundDocumentIcon from './FundDocumentIcon.svg?react'
import { useSessionDateFnsLocale } from '#services/useSessionLanguage'

const API_BASE_URL = config().apiUrl

type Props = { fund: FundDto; shareClass: FundShareClassDto }

export default function FundDocuments({ fund, shareClass }: Props) {
    const { t } = useTranslation()
    const locale = useSessionDateFnsLocale() || nb

    // Merge monthly reports from share class and fund, with share class as the primary source
    const monthlyReports = { ...(fund.monthlyReports || {}), ...(shareClass.monthlyReports || {}) }
    const latestReport = Object.keys(monthlyReports).sort().reverse()[0]
    const firstReport = Object.keys(monthlyReports).sort()[0]
    const lastMonth = subMonths(new Date(), 1)

    const years = latestReport
        ? eachYearOfInterval({ start: new Date(firstReport), end: new Date(latestReport) }).reverse()
        : [lastMonth]
    const [selectedYear, setSelectedYear] = useState<number>(years[0]?.getFullYear() || lastMonth.getFullYear)

    const months = latestReport
        ? eachMonthOfInterval({ start: new Date(firstReport), end: new Date(latestReport) })
              .filter((month) => isSameYear(month, new Date(selectedYear, 0, 1)))
              .reverse()
        : [lastMonth]
    const [selectedMonth, setSelectedMonth] = useState<number>(months[0]?.getMonth() || lastMonth.getMonth())

    const currentReport = monthlyReports[getYearMonth(new Date(selectedYear, selectedMonth, 1))]

    const kiidDocumentId = shareClass.kiidDocumentId || fund.kiidDocumentId
    const prospectusDocumentId = shareClass.prospectusDocumentId || fund.prospectusDocumentId

    return (
        <Stack direction={{ lg: 'row' }} spacing={2}>
            <Box sx={{ width: '55rem', maxWidth: '100%', mb: 2 }}>
                <h4>{t('pages-fund-details.monthlyReports')}</h4>
                <Stack sx={{ maxWidth: '22rem' }}>
                    <Stack direction={'row'} sx={{ mb: 1, mt: 2 }}>
                        <Select
                            sx={{ maxWidth: '10rem', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
                            value={selectedYear.toString()}
                            onChange={(event: SelectChangeEvent) => setSelectedYear(parseInt(event.target.value))}
                        >
                            {years.map((year) => (
                                <MenuItem key={format(year, 'yyyy')} value={year.getFullYear()}>
                                    {year.getFullYear()}
                                </MenuItem>
                            ))}
                        </Select>
                        <Select
                            sx={{
                                ml: '-0.1rem',
                                maxWidth: '12rem',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                            }}
                            value={selectedMonth.toString()}
                            onChange={(event: SelectChangeEvent) => setSelectedMonth(parseInt(event.target.value))}
                        >
                            {months.map((month) => (
                                <MenuItem
                                    disabled={!monthlyReports[getYearMonth(month)]}
                                    key={month.getMonth()}
                                    value={month.getMonth()}
                                >
                                    {format(month, 'MMMM', { locale: locale })}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    {currentReport && (
                        <Animated key={currentReport}>
                            <Button
                                sx={{ display: 'flex', textDecoration: 'none', '&:hover': { textDecoration: 'none' } }}
                                href={API_BASE_URL + '/api/document/id/' + currentReport + '/data'}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Stack alignItems={'center'} spacing={1}>
                                    <Box p={2} sx={{ backgroundColor: PURPLE[50], display: 'inline-flex' }}>
                                        <FundDocumentIcon />
                                    </Box>
                                    <Typography sx={{ textDecoration: 'none' }} color={PURPLE[500]}>
                                        <i className="ri-eye-line" />
                                        {t('pages-fund-details.showReport')}
                                    </Typography>
                                </Stack>
                            </Button>
                        </Animated>
                    )}
                </Stack>
                {!currentReport && <Alert severity="warning">{t('pages-fund-details.noReport')}</Alert>}
            </Box>
            <Box>
                <h4>{t('pages-fund-details.otherDocuments')}</h4>
                <Stack sx={{ mt: 2 }} direction={{ md: 'row' }} gap={1}>
                    <Button
                        variant="outlined"
                        href={API_BASE_URL + '/api/document/id/' + kiidDocumentId + '/data'}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <DescriptionSharp color={'primary'} sx={{ width: '16px', marginRight: '5px' }} />
                        <span>{t('pages-fund-details.kiidDocument')}</span>
                    </Button>
                    <Button
                        variant="outlined"
                        href={API_BASE_URL + '/api/document/id/' + prospectusDocumentId + '/data'}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <DescriptionSharp color={'primary'} sx={{ width: '16px', marginRight: '5px' }} />
                        <span>{t('pages-fund-details.prospect')}</span>
                    </Button>
                </Stack>
            </Box>
        </Stack>
    )
}

function getYearMonth(date: Date): string {
    return format(date, 'yyyy-MM')
}
