import styles from './ProductBadge.module.scss'
import { useTranslation } from '#components/i18n'

/**
 * @param {object} props
 * @param {string} [props.productId]
 * @param [props.children]
 * @return {JSX.Element}
 */
export default function ProductBadge({ children, productId }: { children?: React.ReactNode; productId?: string }) {
    const { t } = useTranslation('common')
    return <span className={styles.badge}>{t('productShortName' + productId) || children}</span>
}
