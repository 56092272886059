import FxPaper from '#app/components/Paper/FxPaper'
import { InterestRateChangeExtendedDto } from '#app/state/selectors'
import { DateOutput, InterestOutput } from '#components'
import { useTranslation } from '#components/i18n'
import styles from './InterestRateChangeBankDetails.module.css'

export default function SentToDepositor({ interestRateChange }: { interestRateChange: InterestRateChangeExtendedDto }) {
    const { t } = useTranslation('pages-interestratechange-bank')

    return (
        <FxPaper title={t('acceptedActionHeader')}>
            <p>{t('acceptedInfo', { name: interestRateChange.deposit.depositor.name })}</p>
            <table className={styles.infotable} cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>{t('currentInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.oldInterest)}</td>
                    </tr>
                    <tr>
                        <td>{t('newInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.newInterest)}</td>
                    </tr>
                    <tr>
                        <td>{t('changeDate')}:</td>
                        <td>{DateOutput.formatDate(interestRateChange.changeDate)}</td>
                    </tr>
                </tbody>
            </table>
        </FxPaper>
    )
}
