import { PURPLE } from '#app/colors/colors'
import { RichTextContent, RichTextRenderer } from '#app/components/PayloadCMS/RichTextRenderer/RichTextRenderer'
import {
    getSubMenuForSupportArticle,
    SupportArticlePayload,
    updateMultipleOrderPriorities,
} from '#app/services/payloadCMS/payloadCMS'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SortableItem } from '../SortableItem'
import { useIsLoggedInToPayload } from '../hooks/useIsLoggedInToPayload'
import { useQuery } from 'react-query'

const PayloadArticle = ({ article }: { article: SupportArticlePayload }) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const country = useCurrentCountryCode()
    const selectedLanguage = country === 'SE' ? 'sv_SE' : 'nb_NO'
    const loggedInToPaylod = useIsLoggedInToPayload()
    const content = article?.content?.root?.children as RichTextContent[]

    const [subMenuItemIds, setSubMenuItemIds] = useState<string[]>([])

    const { data: subMenu } = useQuery({
        queryFn: async () => await getSubMenuForSupportArticle(selectedLanguage, country, article.id),
        queryKey: ['articleSubMenu', article.id, selectedLanguage, country],
        placeholderData: [],
        onSuccess: (data) => {
            if (data) {
                setSubMenuItemIds([...data]?.sort((a, b) => a.orderPriority - b.orderPriority).map((item) => item.id))
            }
        },
        onError: (error) => {
            console.error(error)
        },
    })

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    )

    const goHome = () => {
        setSearchParams({ supportId: '' })
    }

    function handleDragEnd(event) {
        const { active, over } = event

        if (active.id !== over.id) {
            setSubMenuItemIds((items) => {
                const oldIndex = items.indexOf(active.id)
                const newIndex = items.indexOf(over.id)
                const newArr = arrayMove(items, oldIndex, newIndex)
                updateMultipleOrderPriorities(country, newArr)

                return arrayMove(items, oldIndex, newIndex)
            })
        }
    }

    return (
        <Box>
            <Breadcrumbs
                maxItems={2}
                sx={{ height: '4.5rem', '& .MuiBreadcrumbs-li': { my: 0 } }}
                aria-label="breadcrumb"
                separator="›"
            >
                <IconButton
                    onClick={goHome}
                    color="primary"
                    sx={{
                        '& i': { p: 0, fontSize: '1.8rem' },
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '3rem',
                        height: '3rem',
                    }}
                >
                    <Box component="i" className="ri-home-line" />
                </IconButton>
                {article?.breadcrumbs?.map((breadcrumb, index) => {
                    return index + 1 === article?.breadcrumbs?.length ? (
                        <Typography
                            sx={{ py: 0.65, px: 0.5 }}
                            fontWeight={500}
                            fontSize={'1.4rem'}
                            key={index}
                            color={PURPLE[900]}
                        >
                            {breadcrumb.label}
                        </Typography>
                    ) : (
                        <Button
                            variant="text"
                            size="small"
                            onClick={() =>
                                setSearchParams({
                                    supportId: typeof breadcrumb.doc === 'string' ? breadcrumb.doc : breadcrumb.doc.id,
                                })
                            }
                            key={index}
                        >
                            {breadcrumb.label}
                        </Button>
                    )
                })}
                {article?.breadcrumbs?.length === 0 && (
                    <Typography sx={{ py: 0.65, px: 0.5 }} fontWeight={500} fontSize={'1.4rem'} color={PURPLE[900]}>
                        {article.title}
                    </Typography>
                )}
            </Breadcrumbs>
            <Typography mt={2} variant="h1" mb={3}>
                {article?.title}
            </Typography>
            {content?.map((c, index) => {
                return <RichTextRenderer key={index} content={c} />
            })}

            {loggedInToPaylod ? (
                <List>
                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext items={subMenuItemIds} strategy={verticalListSortingStrategy}>
                            {[...subMenu]
                                ?.sort((a, b) => subMenuItemIds.indexOf(a.id) - subMenuItemIds.indexOf(b.id))
                                ?.map((item) => (
                                    <SortableItem id={item.id} key={item.id}>
                                        <ListItemButton
                                            sx={{ color: PURPLE[500] }}
                                            color="primary"
                                            onClick={() => setSearchParams({ supportId: item.doc })}
                                        >
                                            <ListItemText primary={item.label} />
                                            <ListItemIcon sx={{ backgroundColor: 'transparent' }}>
                                                <i className="ri-arrow-right-line" />
                                            </ListItemIcon>
                                        </ListItemButton>
                                        <Divider />
                                    </SortableItem>
                                ))}
                        </SortableContext>
                    </DndContext>
                </List>
            ) : (
                <List>
                    {[...subMenu]
                        ?.sort((a, b) => a.orderPriority - b.orderPriority)
                        ?.map((item) => (
                            <Fragment key={item.id}>
                                <ListItemButton
                                    sx={{ color: PURPLE[500] }}
                                    color="primary"
                                    onClick={() => setSearchParams({ supportId: item.doc })}
                                >
                                    <ListItemText primary={item.label} />
                                    <ListItemIcon sx={{ backgroundColor: 'transparent' }}>
                                        <i className="ri-arrow-right-line" />
                                    </ListItemIcon>
                                </ListItemButton>
                                <Divider />
                            </Fragment>
                        ))}
                </List>
            )}
        </Box>
    )
}

export { PayloadArticle }
