import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useCommand } from '#command'
import Modal from '#components/Modal'

type Params = {
    depositorId?: string
}

export default function CustomerDetailEndPartnership() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const partner = useSelector((state) => state.partner)
    const customer = partner?.customers.find((c) => c?.depositorId === params?.depositorId)

    const { removeDepositorPartnerRelation } = useCommand()

    async function onSubmit() {
        const { waitForCommand } = await removeDepositorPartnerRelation(partner.id, customer?.depositorId)
        const success = await waitForCommand()
        if (success) {
            navigate(`/customer`)
        } else {
            navigate(`/customer/${customer?.depositorId}`)
        }
    }

    return (
        <Modal
            cancel={`/customer/${customer?.depositorId}`}
            width="40rem"
            header={t('pages-customers.customerDetailEndPartnershipHeading')}
            onSubmit={onSubmit}
        >
            <p style={{ padding: 0 }}>
                {t('pages-customers.customerDetailEndPartnershipConfirm', { customerName: customer.name })}
            </p>
        </Modal>
    )
}
