import { BURNT_ORANGE, FixrateColor, PURPLE, SUCCESS_GREEN, SUNSET_ORANGE } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import { useSelector } from '#app/state/useSelector'
import { useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import { PartnerCustomerDto } from '@fixrate/fixrate-query'
import { Avatar, LinearProgress, Stack, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isFinishedWithOnboarding } from '../CustomerOverview'

const getAvatarIconStyle = (color: FixrateColor, iconColorWeight = 500): SxProps => {
    return {
        width: '2.6rem',
        height: '2.6rem',
        fontSize: '1.2rem',
        backgroundColor: color[50],
        color: color[iconColorWeight],
        '& i': {
            fontSize: '1.4rem',
        },
    }
}

type AvatarStyles = {
    success: SxProps
    error: SxProps
    warning: SxProps
}

const avatarStyles: AvatarStyles = {
    success: getAvatarIconStyle(SUCCESS_GREEN),
    error: getAvatarIconStyle(BURNT_ORANGE),
    warning: getAvatarIconStyle(SUNSET_ORANGE, 800),
}

const StatusElement = ({
    icon,
    text,
    status,
    notificationNumber,
}: {
    icon?: string
    text: string
    status: 'success' | 'error' | 'warning'
    notificationNumber?: number
}): JSX.Element => {
    return (
        <Stack direction={'row'} spacing={0.5} alignItems="center">
            <Avatar sx={avatarStyles[status]}>
                {notificationNumber ? notificationNumber : <i className={icon} />}
            </Avatar>
            <span>{text}</span>
        </Stack>
    )
}

const OnboardingStatusElement = ({ progress }: { progress: number }): JSX.Element => {
    const { t } = useTranslation()
    return (
        <Stack>
            <Stack direction={'row'}>
                <LinearProgress
                    sx={{ width: '100%', marginTop: '0.6rem', height: '0.8rem', borderRadius: '10rem' }}
                    variant={'determinate'}
                    value={progress}
                />
                <Typography ml={0.5} variant="caption" fontWeight="700" color={PURPLE[500]}>
                    {InterestOutput.formatWithDecimals(progress, 0)}
                </Typography>
            </Stack>
            {progress === 100 && (
                <Typography variant="caption" fontWeight="700" color={PURPLE[500]}>
                    {t('pages-customers.readyForProposal')}
                </Typography>
            )}
        </Stack>
    )
}

function CustomerStatusField({ customer }: { customer: PartnerCustomerDto }): JSX.Element {
    const partnerId = useSelector((state) => state.partner.id)
    const partner = useSelector((state) => state.partner)
    const { t } = useTranslation()
    const finishedWithOnboarding = isFinishedWithOnboarding(customer)
    const onboardingStatus = useOnboardingWizardStatus(customer)
    const hasPendingPartnerProposals =
        customer?.partnerRelations
            ?.find((pr) => pr.partnerId === partnerId)
            ?.partnerProposals?.some((pp) => pp.status === 'PENDING') || false
    const isAccountant = partner.partnerCategory === 'ACCOUNTANT'

    const warnings = customer.warnings.filter(
        (w) =>
            w.type !== 'ORGANISATIONAL_ROLES_NOT_CONFIRMED' &&
            w.type !== 'SECURITY_ROLES_NOT_CONFIRMED' &&
            w.type !== 'BENEFICIAL_OWNERS_NOT_CONFIRMED'
    )

    if (customer.accepted === null) {
        return <StatusElement icon="ri-mail-line" text={t('pages-customers.awaitingApproval')} status="warning" />
    }
    if (customer.accepted === false) {
        return <StatusElement icon="ri-close-line" text={t('pages-customers.requestDeclined')} status="error" />
    }

    if (!finishedWithOnboarding && isAccountant) {
        return <OnboardingStatusElement progress={onboardingStatus.progress} />
    }

    if (hasPendingPartnerProposals) {
        return (
            <StatusElement
                icon="ri-mail-send-line"
                text={t('pages-customers.proposalPending') + '!'}
                status="success"
            />
        )
    }

    return warnings.length > 0 ? (
        <StatusElement
            icon="ri-alert-line"
            text={warnings.length === 1 ? t('pages-customers.warning') : t('pages-customers.warnings')}
            status="error"
            notificationNumber={warnings.length}
        />
    ) : (
        <StatusElement icon="ri-check-line" text={t('pages-customers.allGood')} status="success" />
    )
}

export { CustomerStatusField, StatusElement, OnboardingStatusElement }
