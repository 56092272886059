import { BURNT_ORANGE, SILVER_GRAY, SUNSET_ORANGE } from '#app/colors/colors'
import { InterestOutput, PageHeader, PageLayout } from '#app/components'
import { ColorKeyFigureCard } from '#app/components/ColorKeyFigureCard/ColorKeyFigureCard'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FixrateIcon from '#app/components/FixrateIcon/FixrateIcon'
import NumberInput from '#app/components/NumberInput/NewNumberInput'
import {
    Box,
    Button,
    Divider,
    Fade,
    Grid,
    Hidden,
    InputAdornment,
    InputLabel,
    Slider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const getMarks = (maxProp: number, numberOfSteps: number, label: string) => {
    const arr = []
    const markAdd = maxProp / numberOfSteps

    for (let i = 0; i < numberOfSteps + 1; i++) {
        arr.push({
            value: i * markAdd,
            label: i * markAdd + label,
        })
    }

    return arr
}

const getInterestMarks = ({ maxInterest, numberOfSteps }: { maxInterest: number; numberOfSteps?: number }) => {
    const steps = numberOfSteps || maxInterest

    const arr = getMarks(maxInterest, steps, '%')

    arr.sort((a, b) => a.value - b.value)

    return arr
}

const InterestSliderInput = ({
    value,
    onChange,
    marks,
    label,
    labelDescription,
    stepSize,
}: {
    value: number
    onChange: (val: number) => void
    marks: any[]
    label: string
    labelDescription: string
    stepSize?: number
}) => {
    return (
        <Box>
            <InputLabel sx={{ mb: 0 }}>{label}</InputLabel>
            <Typography variant="labelDescription">{labelDescription}</Typography>
            <Stack direction="row" sx={{ mt: 1 }} spacing={4}>
                <Slider
                    aria-label=""
                    defaultValue={1}
                    value={value}
                    onChange={(e, val) => onChange(val as number)}
                    valueLabelDisplay="auto"
                    marks={marks}
                    step={stepSize || 0.05}
                    valueLabelFormat={() => value.toString().replace('.', ',') + '%'}
                    sx={{
                        height: '0.8rem',
                        '& .MuiSlider-markLabel': { top: '3.4rem' },
                    }}
                    max={marks !== undefined ? marks[marks.length - 1].value : 3}
                />
                <NumberInput
                    value={value}
                    onChange={(val) => (typeof val === 'number' ? onChange(val) : null)}
                    sx={{ width: '14rem' }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </Stack>
        </Box>
    )
}

const BankPriceCalculator = () => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const campaignInterestMarks = getInterestMarks({ maxInterest: 6 })
    const currentInterestMarks = getInterestMarks({ maxInterest: 6 })
    const existingCustomerMarks = getInterestMarks({ maxInterest: 100, numberOfSteps: 5 })
    const [volume, setVolume] = useState(0)
    const [campaignInterest, setCampaignInterest] = useState(0)
    const [currentCustomerInterest, setCurrentCustomerInterest] = useState(0)
    const [percentageExistingCustomers, setPercentageExistingCustomers] = useState(0)
    const [showResult, setShowResult] = useState(false)

    const filledOutForm = volume !== 0 && campaignInterest !== 0 && currentCustomerInterest !== 0

    const percentageNewCustomers = 100 - percentageExistingCustomers
    const newCustomerInterest = (percentageNewCustomers * campaignInterest) / 100
    const existingCustomerInterest = (percentageExistingCustomers * (campaignInterest - currentCustomerInterest)) / 100
    const totalInterest = ((newCustomerInterest + existingCustomerInterest) / percentageNewCustomers) * 100
    const monetaryLoss = (volume * (percentageNewCustomers / 100) * 1e6 * (totalInterest - campaignInterest)) / 100

    return (
        <>
            <PageHeader title={t('pages-bankCalculator.repricing')} icon="ri-calculator-line" />
            <PageLayout sx={{ backgroundColor: 'white' }}>
                <Box sx={{ px: { xs: 3, md: 0 }, pt: { xs: 2, md: 0 } }}>
                    <Stack direction="row" alignItems="center" spacing={0.7}>
                        <Hidden mdDown>
                            <FixrateIcon name="moneyCalculatorFill" sx={{ width: '8rem', marginLeft: '-1rem' }} />
                        </Hidden>
                        <Box>
                            <Typography variant="h1">{t('pages-bankCalculator.repricing')}</Typography>
                            <Typography mt={0.5} color="textSecondary">
                                {t('pages-bankCalculator.repricingCalculatorDescription')}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack spacing={4} maxWidth={550} mt={3}>
                        <Box>
                            <InputLabel sx={{ mb: 0 }}>{t('pages-bankCalculator.totalSum')}</InputLabel>
                            <Typography variant="labelDescription">
                                {t('pages-bankCalculator.totalSumDescription')}
                            </Typography>
                            <Box mt={1}>
                                <NumberInput
                                    value={volume}
                                    onChange={(e) => setVolume(e)}
                                    sx={{ maxWidth: '14rem' }}
                                    formatFn={(v) =>
                                        CurrencyOutputObject(v, {
                                            withCurrency: false,
                                            minimumDecimals: 0,
                                            maximumDecimals: 0,
                                        })
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                                    }}
                                />
                            </Box>
                        </Box>
                        <InterestSliderInput
                            value={campaignInterest}
                            onChange={setCampaignInterest}
                            marks={campaignInterestMarks}
                            label={t('pages-bankCalculator.campaignInterest')}
                            labelDescription={t('pages-bankCalculator.campaignInterestDescription')}
                        />
                        <InterestSliderInput
                            value={currentCustomerInterest}
                            onChange={setCurrentCustomerInterest}
                            marks={currentInterestMarks}
                            label={t('pages-bankCalculator.currentInterest')}
                            labelDescription={t('pages-bankCalculator.currentInterestDescription')}
                        />
                        <InterestSliderInput
                            value={percentageExistingCustomers}
                            onChange={setPercentageExistingCustomers}
                            stepSize={1}
                            marks={existingCustomerMarks}
                            label={t('pages-bankCalculator.existingCustomerShare')}
                            labelDescription={t('pages-bankCalculator.existingCustomerShareDescription')}
                        />
                    </Stack>
                    <Box mt={5} maxWidth="90rem">
                        {!showResult ? (
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!filledOutForm}
                                onClick={() => setShowResult(true)}
                            >
                                {t('pages-bankCalculator.estimateCost')}
                            </Button>
                        ) : (
                            <Fade in={showResult}>
                                <Box>
                                    <Stack direction="row" spacing={4} flexWrap="wrap">
                                        <ColorKeyFigureCard
                                            color={BURNT_ORANGE}
                                            title={t('pages-bankCalculator.realCostNewCustomers')}
                                            description={t('pages-bankCalculator.realCostNewCustomersDescription')}
                                            keyFigure={InterestOutput.format(totalInterest)}
                                            sx={{ width: '43rem', maxWidth: '100%' }}
                                        />
                                        <ColorKeyFigureCard
                                            color={SUNSET_ORANGE}
                                            title={t('pages-bankCalculator.repricingCost')}
                                            description={t('pages-bankCalculator.repricingCostDescription')}
                                            keyFigure={InterestOutput.format(totalInterest - campaignInterest)}
                                            keyFigureAlternateText={Currency(monetaryLoss, { decimals: 0 })}
                                            sx={{ width: '43rem', maxWidth: '100%' }}
                                        />
                                    </Stack>
                                    <Grid container spacing={3} mt={2}>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: SILVER_GRAY[100] }} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Box mb={2} maxWidth={300}>
                                                <Stack pb={'0.5rem'} direction="row" justifyContent={'space-between'}>
                                                    <Typography
                                                        variant="caption"
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        {t('pages-bankCalculator.campaignInterestShort')}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        {InterestOutput.format(campaignInterest)}
                                                    </Typography>
                                                </Stack>
                                                <Stack pb={'1rem'} direction="row" justifyContent={'space-between'}>
                                                    <Typography
                                                        variant="caption"
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        {t('pages-bankCalculator.repricingExistingCustomers')}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        + {InterestOutput.format(totalInterest - campaignInterest)}
                                                    </Typography>
                                                </Stack>
                                                <Stack
                                                    py={'0.5rem'}
                                                    borderTop={'0.1rem solid'}
                                                    borderBottom={'0.1rem solid'}
                                                    direction="row"
                                                    justifyContent={'space-between'}
                                                >
                                                    <Typography
                                                        variant="caption"
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        {t('pages-bankCalculator.totalCost')}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontWeight={700}
                                                        component={'p'}
                                                        color={'textSecondary'}
                                                    >
                                                        {InterestOutput.format(totalInterest)}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                            <Typography
                                                maxWidth={600}
                                                variant="caption"
                                                component={'p'}
                                                color={'textSecondary'}
                                            >
                                                {t('pages-bankCalculator.calculationDescription')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Fade>
                        )}
                    </Box>
                </Box>
            </PageLayout>
        </>
    )
}

export { BankPriceCalculator }
