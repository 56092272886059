import FxPaper from '#app/components/Paper/FxPaper'
import { InterestRateChangeExtendedDto } from '#app/state/selectors'
import { DateOutput, InterestOutput } from '#components'
import { useTranslation } from '#components/i18n'
import { Link } from 'react-router-dom'
import styles from './InterestRateChangeBankDetails.module.css'

export default function SentToDepositor({ interestRateChange }: { interestRateChange: InterestRateChangeExtendedDto }) {
    const { t } = useTranslation('pages-interestratechange-bank')

    const showConvention = interestRateChange.oldInterestRateConvention !== interestRateChange.newInterestRateConvention
    const oldInterestRateConvention = interestRateChange.oldInterestRateConvention
        ? t('common.interestRateConvention.' + interestRateChange.oldInterestRateConvention)
        : ''
    const newInterestRateConvention = interestRateChange.newInterestRateConvention
        ? t('common.interestRateConvention.' + interestRateChange.newInterestRateConvention)
        : ''

    return (
        <FxPaper title={t('sentActionHeader')}>
            <p data-cy="interestRateChangeInfo">{t('sentInfo', { name: interestRateChange.deposit.depositor.name })}</p>
            <table className={styles.infotable} cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>{t('currentInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.oldInterest)}</td>
                    </tr>
                    <tr>
                        <td>{t('newInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.newInterest)}</td>
                    </tr>
                    {showConvention && (
                        <tr>
                            <td>{t('oldInterestRateConvention')}:</td>
                            <td>{oldInterestRateConvention}</td>
                        </tr>
                    )}
                    {showConvention && (
                        <tr>
                            <td>{t('newInterestRateConvention')}:</td>
                            <td>{newInterestRateConvention}</td>
                        </tr>
                    )}
                    <tr>
                        <td>{t('sentDate')}:</td>
                        <td>{DateOutput.formatDate(interestRateChange.sentToDepositor)}</td>
                    </tr>
                    <tr>
                        <td>{t('changeDate')}:</td>
                        <td>{DateOutput.formatDate(interestRateChange.changeDate)}</td>
                    </tr>
                </tbody>
            </table>
            <p>
                <Link to={'/portfolio-bank/' + interestRateChange.depositId}>{t('viewDepositInPortfolio')}</Link>
            </p>
        </FxPaper>
    )
}
