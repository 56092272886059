import { Paper } from '#components'
import { useSelector } from '#state/useSelector'
import * as selectors from '#state/selectors'
import DocumentList from '#components/DocumentList/DocumentList'
import { useTranslation } from 'react-i18next'

export default function AnnualStatements() {
    const { t } = useTranslation()
    const bankDocuments = useSelector((state) => selectors.annualStatements(state))

    const formattedBankDocuments = bankDocuments.map((bankDocument) => {
        return {
            ...bankDocument,
            link: '/api/document/bank_document/' + bankDocument.documentId + '/data',
        }
    })

    return (
        <Paper>
            <h3>{t('pages-portfolio-depositor.annualStatementsHeader')}</h3>
            <p>{t('pages-portfolio-depositor.annualStatementsInfo1')}</p>
            <p>{t('pages-portfolio-depositor.annualStatementsInfo2')}</p>
            <DocumentList
                documents={formattedBankDocuments}
                nameFn={(document) =>
                    document.bankName || t('pages-portfolio-depositor.annualtStatementsDefaultDocumentName')
                }
                sortDirection={'desc'}
                maxInitialLength={10}
            />
        </Paper>
    )
}
