import RestrictedBankAccessInfo from '#components/RestrictedBankAccessInfo'
import Paper from '#app/components/Paper/FxPaper'
import { useTranslation } from 'react-i18next'
import { BankDto } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'
import BankTerms from '#pages/mybank/BankTerms'
import config from '#app/config'

const API_BASE_URL = config().apiUrl

type Props = {
    bank: BankDto
}

const paperInlineStyles = {
    marginBottom: 0,
    boxShadow: 'none',
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
}

export default function BankTermsList({ bank }: Props) {
    const { t } = useTranslation()

    const contracts = useSelector((state) =>
        bank.contracts
            .map((contract) => ({
                ...contract,
                document: state.documents[contract.documentId],
            }))
            .filter(Boolean)
    )

    return (
        <Paper title={t('pages-mybank.termsHeader')} id={'contract'}>
            {!bank.termsAccepted && <RestrictedBankAccessInfo />}
            {contracts.map((contract) => (
                <BankTerms key={contract.documentId} bank={bank} contract={contract} />
            ))}
            <Paper style={paperInlineStyles}>
                <a
                    href={`${API_BASE_URL}/document/template/bank/BANK_PRICELIST`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('pages-mybank.priceList')}
                </a>
            </Paper>
        </Paper>
    )
}
