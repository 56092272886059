import { PageHeader, PageLayout } from '#app/components'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { BankMarginSegment } from './components/BankMarginSegment'
import { FullySubscribedAds } from './components/FullySubscribedAds'
import { Header } from './components/Header'
import { LastOrders } from './components/LastOrders'
import { MyAds } from './components/MyAds'
import { VolumePerSegment } from './components/VolumePerSegment'
import { BentoCard } from '#pages/BankDashboard/components/bento/BentoCard'
import { Webinars } from '#pages/BankDashboard/components/Webinars'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { IconBentoCard } from '#pages/BankDashboard/components/bento/IconBentoCard'
import { useNavigate } from 'react-router-dom'
import { useAuthorization } from '#services/authorization'
import EmptyState from '#components/EmptyState/EmptyState'
import { useSelector } from '#state/useSelector'
import { currencyHasBenchmarkInterest } from '#services/currencyBenchmarkUtils'

export const BankDashboard = () => {
    const organisationCountry = useCurrentCountryCode()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const auth = useAuthorization()
    const currency = useSelector((state) => state.session?.association?.currentCurrency)
    const hasBenchmarkInterest = currencyHasBenchmarkInterest(currency)

    return (
        <Fragment>
            <PageHeader icon="ri-dashboard-line" title="Dashboard" />
            <PageLayout sx={{ px: 0, pt: 2, pb: 0 }}>
                <Stack spacing={4}>
                    <Container maxWidth="xl">
                        <Grid container spacing={2} align-items={'flex-start'} p={2}>
                            <BentoCard size={12} sx={{ backgroundColor: 'white' }}>
                                {hasBenchmarkInterest ? (
                                    <Header />
                                ) : (
                                    <EmptyState
                                        illustration={{ name: 'errorMissingInfo', color: 'default' }}
                                        title={t('pages-BankDashboard.noInterestRateForCurrency')}
                                        variant={'left-on-background'}
                                        button={{
                                            dataCy: 'goToPortfolioButton',
                                            text: t('pages-BankDashboard.goToPortfolio'),
                                            onClick: () => navigate('/portfolio-bank'),
                                        }}
                                    />
                                )}
                            </BentoCard>
                            <BentoCard
                                noPadding
                                size={12}
                                title={t('pages-BankDashboard.adsTitle')}
                                sx={{ gridRowEnd: 'span 5' }}
                            >
                                <MyAds />
                            </BentoCard>
                            {organisationCountry === 'NO' && (
                                <BentoCard size={6} title={'Webinar'}>
                                    <Webinars />
                                </BentoCard>
                            )}
                            <IconBentoCard
                                title={t('pages-BankDashboard.download')}
                                content={t('pages-BankDashboard.guidesDescription')}
                                icon={'notesFill'}
                                actions={[
                                    {
                                        text: t('pages-BankDashboard.depositGuide'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => {
                                            window.open(
                                                'https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Veileder-Nytt-Innskudd.pdf',
                                                '_blank'
                                            )
                                        },
                                    },
                                    {
                                        text: t('pages-BankDashboard.productTerms'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => {
                                            window.open(
                                                'https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Produktbetingelser-Fixrate-1.pdf',
                                                '_blank'
                                            )
                                        },
                                    },
                                    {
                                        text: t('pages-BankDashboard.deviationsGuide'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => {
                                            window.open(
                                                'https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Kilder%20til%20avvik%20%E2%80%93%20En%20veileder%20for%20bank.pdf',
                                                '_blank'
                                            )
                                        },
                                    },
                                ]}
                            />
                            {auth.bank.hasAdminRole && (
                                <IconBentoCard
                                    title={t('pages-BankDashboard.newUsers')}
                                    content={t('pages-BankDashboard.manageUsersDescription')}
                                    icon={'sendInviteFill'}
                                    actions={[
                                        {
                                            text: t('pages-BankDashboard.manageUsers'),
                                            icon: 'ri-group-line',
                                            variant: 'contained',
                                            onClick: () => {
                                                navigate('/my-bank#users')
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </Grid>
                    </Container>
                    {organisationCountry === 'NO' && (
                        <Box sx={{ p: 0, pb: 2, m: 0, backgroundColor: PURPLE[900] }}>
                            <Container maxWidth="xl">
                                <Box alignItems={'stretch'}>
                                    <Stack direction={'row'} paddingLeft={3} paddingTop={2}>
                                        <Typography variant={'h3'} color={SPRING_GREEN[500]}>
                                            <i className="ri-store-2-line" />
                                            {t('pages-BankDashboard.latestFromTheMarketplace')}
                                        </Typography>
                                    </Stack>
                                    <Grid container spacing={2} align-items={'flex-start'} p={2}>
                                        <BentoCard
                                            darkMode
                                            size={12}
                                            titleIcon={'ri-flashlight-fill'}
                                            title={t('pages-BankDashboard.lastOrders')}
                                        >
                                            <LastOrders />
                                        </BentoCard>
                                        <BentoCard
                                            darkMode
                                            size={12}
                                            titleIcon={'ri-check-double-line'}
                                            title={t('pages-BankDashboard.lastFullySubscribedAds')}
                                        >
                                            <FullySubscribedAds />
                                        </BentoCard>
                                        <BentoCard
                                            darkMode
                                            size={6}
                                            titleIcon={'ri-bar-chart-grouped-fill'}
                                            title={t('pages-BankDashboard.volumeBankSegment')}
                                        >
                                            <VolumePerSegment />
                                        </BentoCard>
                                        <BentoCard
                                            darkMode
                                            size={6}
                                            titleIcon={'ri-line-chart-fill'}
                                            title={t('pages-BankDashboard.marginAddition')}
                                        >
                                            <BankMarginSegment />
                                        </BentoCard>
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>
                    )}
                </Stack>
            </PageLayout>
        </Fragment>
    )
}
