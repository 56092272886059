import { useSelector } from '#state/useSelector'
import { FundPlatformFeeSteps } from '#state/types'
import { Currency, FundCategory, FundShareClassDto } from '@fixrate/fixrate-query'
import { convertToVirtual } from '#services/platformFeeVirtualValuta'

function calculatePlatformFeePercentPerCategory(platformFeeSteps: FundPlatformFeeSteps, volumeInVirtualValuta: number) {
    if (!platformFeeSteps) {
        return null
    }
    const allSteps = Object.keys(platformFeeSteps)
        .map((step) => +step)
        .sort((a, b) => a - b)
    const selectedStep = [...allSteps].reverse().find((step) => +step * 1e6 <= volumeInVirtualValuta)
    return platformFeeSteps[selectedStep ?? 0]
}

export function useFundPlatformFee() {
    const feeModel = useSelector((state) => state.depositor?.fundData.feeModel)
    const defaultFundPlatformFees = useSelector((state) => state.defaultFundPlatformFees)
    const fundPlatformFeeSteps = feeModel?.platformFeeSteps || defaultFundPlatformFees

    function fundPlatformFee(category: FundCategory, volume: number) {
        if (!category) {
            return null
        }
        return calculatePlatformFeePercentPerCategory(fundPlatformFeeSteps, volume)?.[category]
    }

    function fundPlatformFeeForPortfolio() {
        const marketValueVirtual = feeModel?.marketValueVirtualValuta ?? 0
        return calculatePlatformFeePercentPerCategory(fundPlatformFeeSteps, marketValueVirtual)
    }

    function calculateFundPlatformFeeWithAdditionalAmount(currency: Currency, additionalAmount: number) {
        const additionalVolumeVirtual = convertToVirtual(currency, additionalAmount)
        const marketValueVirtual = feeModel?.marketValueVirtualValuta ?? 0
        return calculatePlatformFeePercentPerCategory(
            fundPlatformFeeSteps,
            marketValueVirtual + additionalVolumeVirtual
        )
    }

    function calculateTotalCosts(fundCategory: FundCategory, fundShareClass: FundShareClassDto) {
        if (!fundCategory || !fundShareClass) {
            return null
        }
        const fundPlatformFee = calculatePlatformFee(fundCategory, fundShareClass)
        if (!fundPlatformFee) {
            return null
        }
        const ongoingCharges = fundShareClass.ongoingCharges || fundShareClass.managementFee || 0
        return {
            from: fundPlatformFee.from + ongoingCharges,
            to: fundPlatformFee.to + ongoingCharges,
        }
    }

    function calculatePlatformFee(fundCategory: FundCategory, fundShareClass: FundShareClassDto) {
        if (!fundCategory || !fundShareClass) {
            return null
        }
        const minInvestment = fundShareClass.minimumInvestment ?? 0
        const minInvestmentVirtual = convertToVirtual(fundShareClass.currency, minInvestment)
        const lowestPlatformFee = fundPlatformFee(fundCategory, Number.MAX_SAFE_INTEGER)
        const highestPlatformFee = fundPlatformFee(fundCategory, minInvestmentVirtual)
        return {
            from: lowestPlatformFee,
            to: highestPlatformFee,
        }
    }

    return {
        fundPlatformFeeSteps: fundPlatformFeeSteps,
        fundPlatformFeeForPortfolio: fundPlatformFeeForPortfolio(),
        fundPlatformFee: fundPlatformFee,
        calculateTotalCosts: calculateTotalCosts,
        calculatePlatformFee: calculatePlatformFee,
        calculateFundPlatformFeeWithAdditionalAmount: calculateFundPlatformFeeWithAdditionalAmount,
    }
}
