import { Fragment } from 'react'
import { useSelector } from '#state/useSelector'
import * as selectors from '#state/selectors'
import { LoadingSpinner, PageHeader, PageLayout } from '#components'
import UsersList from '#blocks/UsersList/UsersList'
import usePartnerUserListParams from '#app/pages/partnerUsers/usePartnerUserListParams'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function PartnerUsers() {
    const { t } = useTranslation()

    const partner = useSelector(selectors.partnerInSession)
    const userListParams = usePartnerUserListParams()

    return (
        <Fragment>
            <PageHeader title={t('common.users')} icon="ri-group-line" />
            <PageLayout>
                <Box sx={{ padding: { xs: '2.6rem', md: '0' } }}>
                    <p>{t('pages-mypartner.info')}</p>
                    {!partner ? <LoadingSpinner /> : <UsersList userListParams={userListParams} />}
                </Box>
            </PageLayout>
        </Fragment>
    )
}
