import { Box, Typography } from '@mui/material'

const DetailRow = ({ children, title }: { children: React.ReactNode; title: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: 'lightgray 1px solid',
                marginBottom: 4,
            }}
        >
            <Typography variant={'body1'}>{title}:</Typography>
            {children}
        </Box>
    )
}

export default DetailRow
