import { PageHeader, PageLayout } from '#app/components'
import { LoadingSpinner } from '#components'
import { isFixrateRole, isFundIntermediaryAdminSelector } from '#app/state/selectors'
import { useSelector } from '#app/state/useSelector'
import { getMarketPlaceNews } from '#services/payloadCMS/payloadCMS'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import NewsTabs from './NewsTabs'

export const News = () => {
    const fixrateUser = useSelector((state) => isFixrateRole(state))
    const fundIntermediary = useSelector((state) => isFundIntermediaryAdminSelector(state))
    const isFixrate = fixrateUser || fundIntermediary
    const { t } = useTranslation()

    const {
        data: news,
        isFetching,
        error,
    } = useQuery({
        queryFn: async () => await getMarketPlaceNews(),
        queryKey: ['marketnews'],
    })

    if (isFetching) {
        return (
            <>
                <PageHeader icon="ri-newspaper-line" title={t('pages-News.news')} />
                <PageLayout>
                    <LoadingSpinner />
                </PageLayout>
            </>
        )
    }

    const notDrafts = isFixrate ? news : news?.filter((article) => !article.targetAudience?.includes('fixrateDraft'))

    if (error || !news || notDrafts.length === 0) {
        return (
            <>
                <PageHeader icon="ri-newspaper-line" title={t('pages-News.news')} />
                <PageLayout>
                    <Typography>{t('pages-News.noNews')}</Typography>
                </PageLayout>
            </>
        )
    }

    return (
        <>
            <PageHeader icon="ri-newspaper-line" title={t('pages-News.news')} />
            <PageLayout>
                <NewsTabs news={news} isFixrate={isFixrate} />
            </PageLayout>
        </>
    )
}

export default News
