import { useState } from 'react'
import { Checkbox, Tooltip } from '@mui/material'
import ContentCopySharpIcon from '@mui/icons-material/ContentCopySharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import { useTranslation } from 'react-i18next'

type Props = {
    inputText?: string
}

export default function ClipboardCopyButton({ inputText }: Props) {
    const { t } = useTranslation()
    const [isCopied, setIsCopied] = useState(false)
    const text = inputText ?? ''

    function handleCopyClick() {
        copyTextToClipboard(text)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => setIsCopied(false), 3000)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Tooltip title={isCopied ? t('common.copied') + '!' : t('common.copy')} placement="right">
            <Checkbox
                size={'small'}
                checked={isCopied}
                onChange={handleCopyClick}
                icon={<ContentCopySharpIcon />}
                checkedIcon={<CheckCircleSharpIcon />}
            />
        </Tooltip>
    )
}

export async function copyTextToClipboard(text: string): Promise<void> {
    return await navigator.clipboard?.writeText(text)
}
