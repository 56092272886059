import { useMemo } from 'react'
import { FundPlacementPart } from '@fixrate/fixrate-query'

type OrderAmountLimitProps = {
    // Order
    orderVolume: number
    minOrderVolume?: number
    // Placement
    currentPlacement?: FundPlacementSubset
    // Share class
    selectedShareClass: FundShareClassSubset
    otherShareClasses: FundShareClassSubset[]
}

export type OrderAmountLimitInfoResult = {
    // Order
    withinMinOrderVolume: boolean
    // Placement
    newPlacementValue: number
    // New placement value vs. share class limits
    withinMinInvestment: boolean
    withinMaxInvestment: boolean
    // Other
    otherShareClassMatching: FundShareClassSubset
    existingPlacementShareClassMismatch: boolean
    existingPlacementHigherShareClass: FundShareClassSubset
    orderIsValid: boolean
}

export interface FundPlacementSubset {
    id: string
    fundShareClassId: string
    currentValue: number
    placementParts: FundPlacementPart[]
}

export interface FundShareClassSubset {
    id: string
    name: string
    minimumInvestment?: number
    maximumInvestment?: number
}

export function calculateOrderLimitStatus({
    orderVolume = 0,
    minOrderVolume,
    currentPlacement,
    selectedShareClass,
    otherShareClasses,
}: OrderAmountLimitProps): OrderAmountLimitInfoResult {
    if (!selectedShareClass) {
        return {
            withinMinOrderVolume: false,
            newPlacementValue: 0,
            withinMinInvestment: false,
            withinMaxInvestment: false,
            otherShareClassMatching: null,
            existingPlacementShareClassMismatch: false,
            existingPlacementHigherShareClass: null,
            orderIsValid: false,
        }
    }
    // Placement
    const hasActivePlacement =
        currentPlacement !== undefined && currentPlacement !== null && currentPlacement.placementParts.length > 0
    const currentPlacementValue = currentPlacement?.currentValue ?? 0
    const newPlacementValue = currentPlacementValue + orderVolume
    // Order
    const hasMinOrderVolume = minOrderVolume !== undefined && minOrderVolume !== null
    const withinMinOrderVolume = !hasMinOrderVolume || orderVolume >= minOrderVolume
    if (hasActivePlacement) {
        const selectedShareClassIsCurrent = currentPlacement.fundShareClassId === selectedShareClass.id
        const existingShareClass = selectedShareClassIsCurrent
            ? selectedShareClass
            : otherShareClasses.find((sc) => sc.id === currentPlacement.fundShareClassId)
        const minInvestment = 0
        const maxInvestment = Number.MAX_VALUE
        const withinMinInvestment = newPlacementValue >= minInvestment
        const withinMaxInvestment = newPlacementValue <= maxInvestment
        const orderIsValid =
            withinMinInvestment && withinMaxInvestment && withinMinOrderVolume && selectedShareClassIsCurrent
        const existingPlacementHigherShareClass =
            newPlacementValue > (existingShareClass.maximumInvestment ?? Number.MAX_VALUE)
                ? otherShareClasses.find(
                      (sc) =>
                          sc.minimumInvestment != null &&
                          newPlacementValue >= sc.minimumInvestment &&
                          newPlacementValue <= (sc.maximumInvestment ?? Number.MAX_VALUE)
                  )
                : null
        return {
            withinMinOrderVolume: withinMinOrderVolume,
            newPlacementValue: newPlacementValue,
            withinMinInvestment: newPlacementValue >= minInvestment,
            withinMaxInvestment: newPlacementValue <= maxInvestment,
            otherShareClassMatching: selectedShareClassIsCurrent ? null : existingShareClass,
            existingPlacementShareClassMismatch: !selectedShareClassIsCurrent,
            existingPlacementHigherShareClass: existingPlacementHigherShareClass,
            orderIsValid: orderIsValid,
        }
    }
    // New placement value compared to share class limits
    const minInvestment = selectedShareClass.minimumInvestment ?? 0
    const maxInvestment = selectedShareClass.maximumInvestment ?? Number.MAX_VALUE
    const withinMinInvestment = newPlacementValue >= minInvestment
    const withinMaxInvestment = newPlacementValue <= maxInvestment
    // Other
    const otherShareClassMatching = otherShareClasses.find(
        (sc) =>
            newPlacementValue >= (sc.minimumInvestment ?? 0) &&
            newPlacementValue <= (sc.maximumInvestment ?? Number.MAX_VALUE)
    )

    const orderIsValid =
        withinMaxInvestment && // 1. The new fund placement must be below the maxInvestment.
        (hasMinOrderVolume
            ? withinMinOrderVolume // 2. a) If the depositor has a minOrderVolume: the orderVolume must be at least that amount.
            : withinMinInvestment) // 2. b) Else, if the shareclass has a minimum investment amount: the new fund placement must be at least that amount.

    return {
        withinMinOrderVolume: withinMinOrderVolume,
        newPlacementValue: newPlacementValue,
        withinMinInvestment: withinMinInvestment,
        withinMaxInvestment: withinMaxInvestment,
        otherShareClassMatching: otherShareClassMatching,
        existingPlacementShareClassMismatch: false,
        existingPlacementHigherShareClass: null,
        orderIsValid: orderIsValid,
    }
}

export function useOrderAmountLimitStatus(props: OrderAmountLimitProps): OrderAmountLimitInfoResult {
    return useMemo(() => calculateOrderLimitStatus(props), [props])
}
