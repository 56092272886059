import config from './config'
import { registerCookieConsentCallback } from '#services/hubspot'

const hotJarId = config().hotjar
let HOTJAR_is_initialized = false

/**
 * Register a callback that will be called
 *  - immedeately if the user already has given consent
 *  - or else when the user has given or rejected consent
 *  - or if the user opens the cookie consent dialog and change consent
 */
registerCookieConsentCallback((analytics) => {
    if (analytics) {
        if (hotJarId && !HOTJAR_is_initialized) {
            console.log('Activating hotjar with id', hotJarId)
            ;(function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        ;(h.hj.q = h.hj.q || []).push(arguments)
                    }
                h._hjSettings = { hjid: hotJarId, hjsv: 6 }
                a = o.getElementsByTagName('head')[0]
                r = o.createElement('script')
                r.async = 1
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                a.appendChild(r)
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
            HOTJAR_is_initialized = true
        }
    }
})
