import { PageHeader, PageLayout, Paper } from '#components'
import { useSelector } from '#state/useSelector'
import { Button, MenuItem, Select, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function FundTransactionsImport() {
    const { t } = useTranslation()
    const [selectedDepositorId, setSelectedDepositorId] = useState('')
    const [selectedPortfolio, setSelectedPortfolio] = useState('')
    const navigate = useNavigate()
    const fundCustomers = useSelector((state) => state.fundCustomers)

    function onSelectedDepositorChange(depositorId) {
        setSelectedDepositorId(depositorId)
        setSelectedPortfolio('')
    }

    return (
        <>
            <PageHeader title={t('pages-fundTransactionsImport.header')} />
            <PageLayout>
                <Paper sx={{ width: '100%' }} title={t('pages-fundTransactionsImport.selectCustomer')}>
                    <Stack direction={'row'} spacing={1}>
                        <Select
                            value={selectedDepositorId}
                            onChange={(e) => onSelectedDepositorChange(e.target.value)}
                            displayEmpty
                            sx={{ width: 'auto' }}
                        >
                            <MenuItem value="">{t('pages-fundTransactionsImport.selectCustomer')}</MenuItem>
                            {[...fundCustomers]
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((customer) => (
                                    <MenuItem key={customer.depositorId} value={customer.depositorId}>
                                        {customer.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        {selectedDepositorId && (
                            <Select
                                value={selectedPortfolio}
                                onChange={(e) => setSelectedPortfolio(e.target.value)}
                                disabled={!selectedDepositorId}
                                displayEmpty
                                sx={{ width: '100%' }}
                            >
                                <MenuItem value="">{t('pages-fundTransactionsImport.selectPortfolio')}</MenuItem>
                                {fundCustomers
                                    .find((c) => c.depositorId === selectedDepositorId)
                                    ?.portfolios.map((p) => (
                                        <MenuItem key={p.id} value={p.id}>
                                            {p.name} ({p.fundData?.cid})
                                        </MenuItem>
                                    ))}
                            </Select>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                selectedDepositorId &&
                                selectedPortfolio &&
                                navigate('/fund-transactions/import/' + selectedDepositorId + '/' + selectedPortfolio)
                            }
                        >
                            {t('pages-fundTransactionsImport.fetchFromFA')}
                        </Button>
                    </Stack>
                </Paper>
            </PageLayout>
        </>
    )
}
