import { DateOutput, Paper } from '#components'
import { useTranslation } from '#components/i18n'
import { DepositDto } from '@fixrate/fixrate-query'

export default function DepositTerminationCompleted({ deposit }: { deposit: DepositDto }) {
    const { t } = useTranslation('pages-portfolio-depositor')

    return (
        <Paper title={t('terminationCompletedHeader')}>
            <p>{t('terminationCompletedInfo1')}</p>
            <p>{t('terminationCompletedInfo2', { date: DateOutput.formatVerboseMonth(deposit.terminationDate) })}</p>
            {deposit.terminationReason && <p>{t('terminationReason', { reason: deposit.terminationReason })}</p>}
        </Paper>
    )
}
