import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import jsonpath from 'jsonpath'
import { useValidate } from '../Validate'
import { useCustomerDeclaration } from '../CustomerDeclarationContext'
import { setCustomerDeclarationValue } from '#services/thunks/depositor'
import { CustomerDeclaration } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material'

type TextInputErrorProps = {
    message: string
}

function TextInputError({ message }: TextInputErrorProps) {
    return (
        <span style={{ color: '#B44A06', display: 'flex', alignItems: 'center' }}>
            <i className="ri-error-warning-line" />
            <span style={{ paddingLeft: '5px' }}>{message}</span>
        </span>
    )
}

type Props = {
    id?: string
    path: string
    unit?: ReactNode
    required?: boolean
    formatter?: (value: string) => string
    customErrorMessage?: (value: string) => string | undefined
    hide?: boolean
    placeholder: string
    nativePlaceholder?: string
    type?: 'textarea'
    'data-cy'?: string
}

export default function TextInput({
    path,
    unit,
    required,
    formatter = (value) => value,
    customErrorMessage,
    hide = false,
    id,
    placeholder,
    nativePlaceholder,
    type,
    'data-cy': dataCy,
}: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const customerDeclaration = useCustomerDeclaration<CustomerDeclaration>()
    const initValue = jsonpath.value(customerDeclaration, path)
    const [value, setValue] = useState<string>(initValue || '')
    const { isValid, setValid, setTouched, isTouched } = useValidate(path)
    const isEmpty = value === null || value.length === 0
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const errorMessage = useCallback(
        (value) => {
            if (customErrorMessage) {
                return customErrorMessage(value)
            }
            if (required && (value == null || value.length === 0)) {
                return t('validation.fieldIsMandatory')
            }
        },
        [required, t, customErrorMessage]
    )

    useEffect(() => {
        setValid(!errorMessage(value))
    }, [value, setValid, errorMessage, path])

    function onBlur() {
        if (initValue !== value) {
            dispatch(setCustomerDeclarationValue(customerDeclaration.depositorId, path, value))
        }
    }

    function onChange(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setTouched(true)
        setValue(formatter(event.target.value))
    }

    function endAdornment() {
        if (isTouched() && !isValid() && !isMobile) {
            return (
                <InputAdornment position="end">
                    <TextInputError message={errorMessage(value)} />
                </InputAdornment>
            )
        } else {
            return unit ? <InputAdornment position="end">{unit}</InputAdornment> : null
        }
    }

    if (hide) {
        return null
    } else {
        return (
            <TextField
                id={id ? id : path}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                label={isEmpty ? '' : placeholder}
                placeholder={nativePlaceholder || placeholder}
                fullWidth
                error={isTouched() && !isValid()}
                multiline={type === 'textarea'}
                minRows={type === 'textarea' ? 4 : 0}
                color={isTouched() && !isValid() ? 'error' : 'primary'}
                InputProps={{
                    endAdornment: endAdornment(),
                    sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px',
                        },
                    },
                    inputProps: {
                        'data-cy': dataCy,
                    },
                }}
                InputLabelProps={{
                    sx: {
                        fontWeight: '500', // Make label text thinner
                    },
                }}
            />
        )
    }
}
