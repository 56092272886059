import { FundCategory, InvestmentCategory } from '@fixrate/fixrate-query'

export function investmentCategory2fundCategory(investmentCategory: InvestmentCategory): FundCategory {
    switch (investmentCategory) {
        case 'EQUITY_FUNDS':
            return null
        case 'BOND_FUNDS':
            return 'BOND_FUND'
        case 'MONEY_MARKET_FUNDS':
            return 'MONEY_MARKET_FUND'
        default:
            throw new Error(`Unknown investment category: ${investmentCategory}`)
    }
}

export function fundCategory2investmentCategory(fundCategory: FundCategory): InvestmentCategory {
    switch (fundCategory) {
        case 'BOND_FUND':
            return 'BOND_FUNDS'
        case 'MONEY_MARKET_FUND':
            return 'MONEY_MARKET_FUNDS'
        default:
            throw new Error(`Unknown fundCategory category: ${fundCategory}`)
    }
}
