import config from '#app/config'
import { PageHeader } from '#components'
import { useSelector } from '#state/useSelector'
import { Fragment } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DownloadIcon from '../common/icons/DownloadIcon'
import styles from './SignatureInbox.module.css'

const API_BASE_URL = config().apiUrl

type Params = {
    documentId?: string
}

const ViewDocument = function () {
    const { t } = useTranslation()
    const params = useParams<Params>()

    const document = useSelector((state) => state.documents[params.documentId])
    if (!document) {
        return null
    }

    const documentLink = `${API_BASE_URL}${document.link}?inline=true`
    const downloadLink = `${API_BASE_URL}${document.link}?inline=false`

    return (
        <Fragment>
            <PageHeader title={document.name} backToLink={'/sign'} />

            <div className={styles.authorizationPreview}>
                <div className={styles.documentDownload}>
                    <a className={styles.authorizationDownload} href={downloadLink}>
                        <DownloadIcon className={styles.downloadIcon} /> {t('common.download')}
                    </a>
                </div>

                <div className={styles.authorizationDocumentObjectBackground}>
                    <object className={styles.authorizationDocumentObject} data={documentLink} type="application/pdf">
                        <div className={styles.authorizationDocumentObjectFallback}>
                            <p>
                                <Trans t={t} i18nKey={'pages-signatureinbox.missingPdfSupport'} />
                            </p>
                            <p>
                                <Trans t={t} i18nKey={'pages-signatureinbox.installPdfSupport'}>
                                    ...
                                    <a href="https://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">
                                        ...
                                    </a>
                                    ...
                                </Trans>
                            </p>
                        </div>
                        <embed src={documentLink} type="application/pdf" />
                    </object>
                </div>
            </div>
        </Fragment>
    )
}

export default ViewDocument
