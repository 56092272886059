import { useState } from 'react'
import { useQuery } from 'react-query'
import Calendar from '#components/Calendar/Calendar'
import { Temporal } from '@js-temporal/polyfill'
import { FundNav } from '@fixrate/fixrate-query'
import { Button, Typography } from '@mui/material'
import styles from '#pages/portfolio-depositor/FundTransactions/FundTransactions.module.scss'
import { useSelector } from '#state/useSelector'

export default function FundNavOverviewCalendar() {
    const fundNames: { string: string } = useSelector((state) =>
        Object.assign(
            {},
            ...state.funds.flatMap((f) => f.fundShareClasses.map((sc) => ({ [sc.id]: `${sc.fullName}` })))
        )
    )

    const [fundShareClassId, setFundShareClassId] = useState('')
    const [monthFromDate, setMonthFromDate] = useState(Temporal.Now.plainDateISO())

    async function fetchFundNav(fundShareClassId: string, monthFromDate: string) {
        if (!fundShareClassId) {
            return Promise.reject('No fundShareClassId')
        }
        const response = await fetch(`/api/fund/nav/${fundShareClassId}?month=${monthFromDate}`)
        if (!response.ok) {
            return Promise.reject(response.status)
        }
        return (await response.json()) as Promise<FundNav[]>
    }

    const { data, isSuccess } = useQuery({
        queryFn: () => fetchFundNav(fundShareClassId, monthFromDate.toString().substring(0, 7)),
        queryKey: ['FundNavOverview', monthFromDate.toString().substring(0, 7), fundShareClassId],
        enabled: fundShareClassId !== '',
    })

    const content = new Map<string, JSX.Element>()
    if (isSuccess) {
        //iterate over data and add to map
        data.forEach((fundNav) => {
            const date = fundNav.date
            const navSource = fundNav.navSource ?? '?'
            const jsx = (
                <>
                    {fundNav.nav} {fundNav.accrual !== null && ' / ' + fundNav.accrual} {` (${navSource})`}
                </>
            )
            content.set(date, jsx)
        })
    }

    const header = (
        <>
            <Button
                variant={'text'}
                size={'large'}
                onClick={() => setMonthFromDate(monthFromDate.subtract({ months: 1 }))}
            >
                &lt;
            </Button>
            <Typography variant={'h3'} onClick={() => setMonthFromDate(Temporal.Now.plainDateISO())}>
                {monthFromDate.year} {monthFromDate.month}
            </Typography>
            <Button variant={'text'} size={'large'} onClick={() => setMonthFromDate(monthFromDate.add({ months: 1 }))}>
                &gt;
            </Button>
        </>
    )

    return (
        <>
            <select
                className={styles.toolbarSelect}
                onChange={(e) => setFundShareClassId(e.target.value)}
                value={fundShareClassId}
            >
                <option key={''} disabled selected value={''}>
                    Velg andelsklasse
                </option>
                {Object.keys(fundNames)
                    .sort((a, b) => fundNames[a].localeCompare(fundNames[b]))
                    .map((id) => (
                        <option key={id} value={id}>
                            {fundNames[id]}
                        </option>
                    ))}
            </select>

            <Calendar seedDate={monthFromDate} content={content} header={header}></Calendar>
        </>
    )
}
