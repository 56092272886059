import { useSelector } from '#app/state/useSelector'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TerminationMode } from '../DepositDetails/DepositTermination/DepositTermination'
import TerminateDepositWizard from '../DepositDetails/DepositTermination/TerminateDepositWizard/TerminateDepositWizard'

const DepositTableRowMenu = ({ depositId }: { depositId: string }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const deposits = useSelector((state) => state.deposits)
    const deposit = deposits.find((deposit) => deposit.id === depositId)
    const [depositMenuAnchor, setDepositMenuAnchor] = useState(null)
    const [terminationMode, setTerminationMode] = useState<TerminationMode>('HIDE')
    const handleDepositMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDepositMenuAnchor(event.currentTarget)
    }

    const handleDepositMenuClose = () => {
        setDepositMenuAnchor(null)
    }

    const openTerminationDialog = () => {
        setTerminationMode('SELECT')
    }

    return (
        <Fragment>
            <IconButton color="primary" onClick={handleDepositMenuClick}>
                <i className="ri-more-2-fill" />
            </IconButton>
            <Menu
                data-cy="depositMenu"
                anchorEl={depositMenuAnchor}
                open={Boolean(depositMenuAnchor)}
                onClose={handleDepositMenuClose}
            >
                <MenuItem onClick={() => navigate(`/portfolio/id/${deposit.id}`)}>
                    {t('pages-portfolio-depositor.goToDeposit')}
                </MenuItem>
                {deposit.terminationState === 'NO_ACTIVE_TERMINATION' && (
                    <MenuItem onClick={() => openTerminationDialog()}>
                        {t('pages-portfolio-depositor.menuItemTerminate')}
                    </MenuItem>
                )}
            </Menu>
            <TerminateDepositWizard
                terminationMode={terminationMode}
                setTerminationMode={setTerminationMode}
                depositId={deposit?.id}
            />
        </Fragment>
    )
}

export { DepositTableRowMenu }
