import { Fragment, useState } from 'react'
import styles from './FundReturnsChart.module.scss'
import 'chartjs-adapter-date-fns'
import { LoadingSpinner } from '#components'
import isAfter from 'date-fns/isAfter'
import startOfYear from 'date-fns/startOfYear'
import isSameDay from 'date-fns/isSameDay'
import DateRangePicker, { FUND_PERIOD_CONFIG } from '#components/DateRangePicker/DateRangePicker'
import isBefore from 'date-fns/isBefore'
import { FundReturnPeriod, FundShareClassDto } from '@fixrate/fixrate-query'
import { FundReturnsChartCanvas } from '#blocks/FundReturnsChart/FundReturnsChartCanvas'
import { useFundMarketData } from '#blocks/FundReturnsChart/useFundMarketData'

export type Options = {
    showDateRange?: boolean
    showAxes?: boolean
    defaultStartDate?: Date
    aspectRatio?: {
        width: number
        height: number
    }
    periodFilter?: FundReturnPeriod[]
    maxTicksLimit?: {
        x: number
        y: number
    }
}

const DEFAULT_OPTIONS: Options = {
    showDateRange: true,
    showAxes: true,
    aspectRatio: {
        width: 5,
        height: 3,
    },
    maxTicksLimit: {
        x: 6,
        y: 8,
    },
}

type Props = {
    fundShareClass: FundShareClassDto
    options?: Options
    defaultSelectedPeriod?: FundReturnPeriod
}

export default function FundReturnsChart({ fundShareClass, options, defaultSelectedPeriod }: Props) {
    const selectedOptions = {
        ...DEFAULT_OPTIONS,
        ...options,
    }

    if (selectedOptions.defaultStartDate === undefined) {
        selectedOptions.defaultStartDate =
            defaultSelectedPeriod !== undefined
                ? FUND_PERIOD_CONFIG.find((c) => c.period === defaultSelectedPeriod)?.startDate ||
                  startOfYear(new Date())
                : startOfYear(new Date())
    }

    const { data, isLoading, isSuccess, minDate, maxDate } = useFundMarketData(fundShareClass.isin)

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(selectedOptions.defaultStartDate)
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(maxDate)

    const simulateBeforeDate = fundShareClass?.startDate

    const changeDateRange = (startDate: Date, endDate: Date) => {
        if (!isSameDay(selectedStartDate, startDate)) {
            setSelectedStartDate(startDate)
        }
        if (!isSameDay(selectedEndDate, endDate)) {
            setSelectedEndDate(endDate)
        }
    }

    if (isLoading) {
        return <LoadingSpinner />
    }
    if (!isSuccess) {
        return <LoadingSpinner />
    }

    const cappedStartDate = isBefore(selectedStartDate, minDate) ? minDate : selectedStartDate
    const cappedEndDate = isAfter(selectedEndDate, maxDate) ? maxDate : selectedEndDate

    return (
        <div className={styles.root}>
            <FundReturnsChartCanvas
                data={data.navWithPerformance}
                selectedOptions={selectedOptions}
                startDate={cappedStartDate}
                endDate={cappedEndDate}
                simulateBeforeDate={simulateBeforeDate}
            />
            {selectedOptions.showDateRange && (
                <Fragment>
                    <DateRangePicker
                        startDate={selectedStartDate}
                        defaultSelectedPeriod={defaultSelectedPeriod}
                        endDate={selectedEndDate}
                        onChange={changeDateRange}
                        maxDate={maxDate}
                        minDate={minDate}
                        periodFilter={selectedOptions.periodFilter || undefined}
                        fundData={data}
                    />
                </Fragment>
            )}
        </div>
    )
}
