import { useState } from 'react'
import * as React from 'react'
import styles from '#pages/newcustomerdeclarations/NewCustomerDeclarations.module.scss'
import {
    ATTRIBUTES_WITH_LIST_TYPE,
    containsEmptyValue,
    formatTranslationKey,
    hasNestedChangelogObjects,
    hasNoChildren,
} from '#pages/newcustomerdeclarations/Changelog/Changelog'
import { ChangelogObject, ChangeType } from '#pages/newcustomerdeclarations/Changelog/types'
import classNames from 'classnames'
import { DiffRow } from '#pages/newcustomerdeclarations/Changelog/DiffRow'
import { useTranslation } from '#components/i18n'

interface AccordionOptions {
    labelIcon: string
    labelClass?: string
}

const ACCORDION_OPTIONS = new Map<ChangeType, AccordionOptions>([
    [
        'UNCHANGED',
        {
            labelIcon: 'ri-check-line',
            labelClass: styles.added,
        },
    ],
    [
        'CHANGED',
        {
            labelIcon: 'ri-edit-line',
            labelClass: styles.changed,
        },
    ],
    [
        'ADDED',
        {
            labelIcon: 'ri-add-circle-line',
            labelClass: styles.added,
        },
    ],
    [
        'UPDATED',
        {
            labelIcon: 'ri-edit-line',
            labelClass: styles.changed,
        },
    ],
    [
        'DELETED',
        {
            labelIcon: 'ri-delete-bin-line',
            labelClass: styles.deleted,
        },
    ],
])

interface Props {
    parentKey: string
    currentKey: string
    changelogObject: ChangelogObject
}

export function DiffAccordion({ parentKey, currentKey, changelogObject }: Props) {
    const { t } = useTranslation('pages-newcustomerdeclarations')
    const { changeType } = changelogObject

    const isExpandable = changeType !== 'UNCHANGED'

    const [open, setOpen] = useState(isExpandable)

    let title

    const noValue = containsEmptyValue(changelogObject)
    const onlyEmptyChildren = Object.keys(changelogObject.value).every((childKey) =>
        hasNoChildren(changelogObject.value[childKey])
    )

    let children: React.ReactElement[] | null

    if (noValue || changeType === 'UNCHANGED' || onlyEmptyChildren) {
        return null
    } else if (hasNestedChangelogObjects(changelogObject)) {
        if (ATTRIBUTES_WITH_LIST_TYPE[parentKey] !== undefined) {
            title = `${changelogObject.value[ATTRIBUTES_WITH_LIST_TYPE[parentKey]].value}`
        }

        children = [
            <ul key={currentKey} className={classNames(styles.diffRow)}>
                {Object.keys(changelogObject.value).map((childKey) => (
                    <DiffRow
                        key={`${currentKey}.${childKey}`}
                        translationKey={formatTranslationKey(`${parentKey}--${currentKey}`, childKey)}
                        parentKey={currentKey}
                        currentKey={childKey}
                        changelogObject={changelogObject.value[childKey]}
                        isNested={true}
                    />
                ))}
            </ul>,
        ]
    }

    if (!title) {
        title = t(`field-${parentKey}--${currentKey}`)
    }

    const { labelIcon, labelClass } = ACCORDION_OPTIONS.get(changeType)

    return (
        <div className={classNames(styles.accordion, styles.subItem)}>
            <div
                className={classNames(styles.accordionHeader, labelClass, isExpandable && styles.clickable)}
                onClick={() => isExpandable && setOpen(!open)}
            >
                <h4>{title}</h4>
                <div className={styles.statusToggleWrapper}>
                    <div className={classNames(styles.label, labelClass)}>
                        <i className={labelIcon} />
                        <span>{t(`changeType-${changeType}`)}</span>
                    </div>
                    {isExpandable && (
                        <div
                            className={styles.toggleIcon}
                            style={{ transform: open ? 'rotate(0)' : 'rotate(-0.5turn)' }}
                        >
                            <i className={'ri-arrow-up-s-line'} />
                        </div>
                    )}
                </div>
            </div>
            {open && <div className={styles.accordionBody}>{children}</div>}
        </div>
    )
}
