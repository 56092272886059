import { Stack, SxProps, Tooltip, Typography, TypographyVariant } from '@mui/material'
import { PURPLE } from '#app/colors/colors'

export type Props = {
    rating: number
    titleVariant?: TypographyVariant
    helpText?: string
    direction?: 'row' | 'column'
    sx?: SxProps
}

export function MorningstarRating({ rating, titleVariant, helpText, direction, sx }: Props) {
    return (
        <Stack direction={direction ?? 'column'} sx={{ ...sx }} spacing={0.5}>
            <Stack direction={'row'} justifyContent={'left'} alignItems={'center'} spacing={0.5}>
                <Typography variant={titleVariant ?? 'button'} mb={0} pr={direction === 'row' ? 1 : 0}>
                    Morningstar rating
                </Typography>
                {helpText && (
                    <Tooltip title={helpText}>
                        <i className={'ri-information-line'} />
                    </Tooltip>
                )}
            </Stack>
            <Stack direction={'row'} spacing={0.8}>
                {Array(rating)
                    .fill(null)
                    .map((_, i) => (
                        <i key={i} className={'ri-star-fill'} style={{ fontSize: '2.3rem', color: PURPLE[500] }} />
                    ))}
                {Array(5 - rating)
                    .fill(null)
                    .map((_, i) => (
                        <i key={i} className={'ri-star-fill'} style={{ fontSize: '2.3rem', color: PURPLE[100] }} />
                    ))}
            </Stack>
        </Stack>
    )
}
