import { DateOutput, LoadingSpinner } from '#app/components'
import { RichTextContent, RichTextRenderer } from '#app/components/PayloadCMS/RichTextRenderer/RichTextRenderer'
import { MarketplaceNew, Media } from '#app/services/payloadCMS/payload-types'
import { Box, Stack, Typography, List, ListItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DocumentLink from '#app/components/DocumentLink'
import { PURPLE } from '#app/colors/colors'
import { useTranslation } from 'react-i18next'

interface NewsArticleCardProps {
    article: MarketplaceNew
    featured?: boolean
    fullView?: boolean
}

const calculateReadingTime = (text: string): number => {
    const wordsPerMinute = 200 // Average reading speed
    const words = text.split(/\s+/).length
    const readingTime = Math.ceil(words / wordsPerMinute)
    return readingTime
}

// TODO: refactor... messy component
export const NewsArticleCard = ({ article, featured = false, fullView = false }: NewsArticleCardProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const content = article?.content.root.children as RichTextContent[]
    const contentTeaser = content.slice(0, 1)
    const fullText = content.map((block) => block.children[0].text).join(' ')
    const readingTime = calculateReadingTime(fullText)

    const articleContent = fullView ? content : contentTeaser

    const handleTitleClick = () => {
        navigate(`/news/id/${article.id}`)
    }

    const isMedia = (coverPhoto: string | Media): coverPhoto is Media => {
        return (coverPhoto as Media).url !== undefined
    }

    const coverPhotoUrl = isMedia(article.coverPhoto) ? article.coverPhoto.url : article.coverPhoto

    const getFileIcon = (mimeType: string) => {
        if (mimeType.includes('pdf')) return 'ri-file-pdf-line'
        if (mimeType.includes('word')) return 'ri-file-word-line'
        if (mimeType.includes('excel') || mimeType.includes('spreadsheet')) return 'ri-file-excel-line'
        if (mimeType.includes('image')) return 'ri-image-line'
        return 'ri-file-text-line'
    }

    if (!featured && !fullView) {
        return article ? (
            <Box
                onClick={handleTitleClick}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    height: 'auto',
                    maxHeight: { xs: 'none', sm: '25rem' },
                    overflow: 'hidden',
                    color: PURPLE[900],
                    cursor: 'pointer',
                }}
            >
                <Box
                    sx={{
                        width: { xs: '100%', sm: '45%' },
                        minWidth: { sm: '25rem' },
                        maxWidth: { xs: '100%', sm: '45%' },
                        position: 'relative',
                        overflow: 'hidden',
                        minHeight: '200px',
                        display: 'block',
                    }}
                >
                    <Box
                        component="img"
                        src={coverPhotoUrl}
                        alt=""
                        sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            display: 'block',
                        }}
                    />
                </Box>
                <Stack
                    px={{ xs: 2, md: 3 }}
                    py={{ xs: 2, md: 2 }}
                    spacing={1}
                    alignItems="flex-start"
                    sx={{
                        width: { xs: '100%', sm: '55%' },
                        flex: 1,
                    }}
                >
                    <Typography
                        component="h2"
                        fontWeight={700}
                        lineHeight={1.4}
                        fontSize={{ xs: '1.8rem', md: '2rem' }}
                        sx={{
                            color: PURPLE[900],
                            '&:hover': { textDecoration: 'underline', color: PURPLE[500] },
                        }}
                    >
                        {article.title}
                    </Typography>
                    <Stack spacing={0.2}>
                        <Stack spacing={1} direction={'row'}>
                            <Stack direction="row" alignItems="center">
                                <i className="ri-time-line" style={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />
                                <Typography fontSize="1.2rem">
                                    {readingTime} {t('pages-News.readingTime')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <i className="ri-calendar-line" style={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />
                                <Typography fontSize="1.2rem">
                                    {DateOutput.formatDateTime(article.createdAt)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        ) : (
            <Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
                <LoadingSpinner />
            </Stack>
        )
    }

    // Layout for featured or full view
    return article ? (
        <Box
            onClick={!fullView ? handleTitleClick : undefined}
            sx={
                !fullView
                    ? {
                          overflow: 'hidden',
                          color: PURPLE[900],
                          cursor: 'pointer',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                      }
                    : {
                          overflow: 'hidden',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                      }
            }
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '0',
                    paddingBottom: featured ? '56.25%' : '40%',
                    overflow: 'hidden',
                    mb: 2,
                }}
            >
                <Box
                    component="img"
                    src={coverPhotoUrl}
                    alt=""
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                    }}
                />
            </Box>
            <Stack px={{ xs: 1, md: 2 }} py={{ xs: 2, md: 3 }} spacing={2} alignItems="flex-start" sx={{ flex: 1 }}>
                <Stack spacing={1}>
                    <Typography
                        component="h2"
                        onClick={!fullView ? handleTitleClick : undefined}
                        fontWeight={700}
                        lineHeight={1.4}
                        fontSize={{ xs: '2rem', md: featured || fullView ? '3rem' : '2rem' }}
                        sx={
                            !fullView
                                ? {
                                      color: PURPLE[900],
                                      cursor: 'pointer',
                                      '&:hover': { textDecoration: 'underline', color: PURPLE[500] },
                                  }
                                : undefined
                        }
                    >
                        {article.title}
                    </Typography>
                    <Stack spacing={0.2}>
                        <Stack spacing={1} direction={'row'}>
                            <Stack direction="row" alignItems="center">
                                <i className="ri-time-line" style={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />
                                <Typography fontSize="1.2rem">
                                    {readingTime} {t('pages-News.readingTime')}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <i className="ri-calendar-line" style={{ fontSize: '1.6rem', marginRight: '0.5rem' }} />
                                <Typography fontSize="1.2rem">
                                    {DateOutput.formatDateTime(article.createdAt)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>

                {(featured || fullView) && (
                    <Box>
                        {articleContent.map((block: RichTextContent, key) => (
                            <RichTextRenderer key={key} content={block} />
                        ))}
                    </Box>
                )}

                {fullView && article.attachments && article.attachments.length > 0 && (
                    <Box sx={{ width: '100%', mt: 4 }}>
                        <Typography
                            component="h3"
                            fontWeight={600}
                            fontSize="1.8rem"
                            sx={{ borderBottom: '1px solid #eee', pb: 1, mb: 2 }}
                        >
                            {t('pages-News.attachments')}
                        </Typography>
                        <List sx={{ width: '100%' }}>
                            {article.attachments.map((attachment: any) => (
                                <ListItem key={attachment.id} sx={{ px: 0 }}>
                                    <DocumentLink
                                        link={attachment.url}
                                        name={attachment.name || attachment.filename}
                                        icon={getFileIcon(attachment.mimeType)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )}
                {!fullView && (
                    <Box sx={{ bottom: '1rem', left: '3rem' }}>
                        <Typography sx={{ color: PURPLE[900], textDecoration: 'underline' }} variant="body2">
                            {t('pages-News.readMore')}
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Box>
    ) : (
        <Stack width={'100%'} alignItems={'center'} justifyContent={'center'}>
            <LoadingSpinner />
        </Stack>
    )
}

export default NewsArticleCard
