import { cloneElement, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useCustomerDeclaration } from '../CustomerDeclarationContext'
import styles from './List.module.css'
import CustomerDeclarationButton from './CustomerDeclarationButton'
import { addCustomerDeclarationValue, deleteCustomerDeclarationValues } from '#services/thunks/depositor'
import Enter from './Enter'

type ButtonProps = {
    onClick: () => void
    className: string
}

type Props = {
    path: string
    children(key: string, index: number, allKeys: string[]): JSX.Element
    button?: ((props: ButtonProps) => JSX.Element) | string
    hide?: boolean
}

export default function List({ path, children = null, button = '', hide = false }: Props) {
    let values = useCustomerDeclaration(path)
    const depositorId = useCustomerDeclaration('depositorId')
    const dispatch = useDispatch()

    function randomId() {
        let id = ''
        const chars = 'abcdef0123456789'

        for (let i = 0; i < 5; i++) {
            id += chars[Math.floor(Math.random() * 16)]
        }

        return `H${id}`
    }

    const length = values ? Object.keys(values).length : 0

    const ComputedButton = useCallback(() => {
        function addElementToList() {
            const index = randomId()
            dispatch(addCustomerDeclarationValue(depositorId, `${path}.${index}`, JSON.stringify({})))
        }

        if (typeof button === 'string' && button) {
            return (
                <CustomerDeclarationButton.Primary onClick={addElementToList} className={styles.button}>
                    {button}
                </CustomerDeclarationButton.Primary>
            )
        } else if (button instanceof Function) {
            const element = button({ onClick: addElementToList, className: styles.button })
            return cloneElement(element, {
                className: [styles.button, element.props.className || ''].filter(Boolean).join(' ').trim(),
            })
        } else {
            return null
        }
    }, [button, depositorId, dispatch, path])

    const deleteButton = useCallback(
        (index: string) => {
            function removeElementFromList(index: string) {
                dispatch(deleteCustomerDeclarationValues(depositorId, [`${path}.${index}`]))
            }

            return length > 1 ? (
                <CustomerDeclarationButton.Remove
                    className={styles.remove}
                    onClick={() => removeElementFromList(index)}
                />
            ) : null
        },
        [depositorId, dispatch, length, path]
    )

    if (!(children instanceof Function)) {
        return null
    }
    if (!values || Object.keys(values).length === 0) {
        values = {
            H0: {},
        }
    }

    return hide ? null : (
        <div className={styles.body}>
            {Object.keys(values).map((key, index, allKeys) => {
                return (
                    <Enter key={`path-${key}`} hide={false}>
                        <div className={styles.item}>
                            {children(key, index, allKeys)}
                            {deleteButton(key)}
                        </div>
                    </Enter>
                )
            })}
            <ComputedButton />
        </div>
    )
}
