import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { usePortfolioValues } from '#app/services/usePortfolioValues'
import { useSelector } from '#app/state/useSelector'
import NIBORPortfolioInterestRatesChart from '#pages/portfolio-depositor/PortfolioInterestRatesChart'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import styles from './OldOverview.module.scss'
import SegmentChart from './SegmentChart'

export default function DepositOverview() {
    const { t } = useTranslation()
    const portfolioValues = usePortfolioValues()
    const Currency = useCurrencyOutput()
    const currency = useSelector((state) => state.session?.association?.currentCurrency)
    const deposits = useSelector((state) => state.deposits)

    const activeDeposits = deposits.filter(
        (deposit) => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date())
    )
    const numberOfBanks = [...new Set(activeDeposits.map((deposit) => deposit.bankId))].length
    const showSegmentChart = activeDeposits.length > 1 && numberOfBanks > 1

    return (
        <div className={styles.overview}>
            <div className={styles.row}>
                {showSegmentChart && (
                    <div className={styles.portfolioSegmentOverview}>
                        <div>
                            <SegmentChart deposits={activeDeposits} />
                        </div>
                    </div>
                )}
                <div className={styles.portfolioValueOverview}>
                    <div className={styles.portfolioValueRow}>
                        <p className={styles.portfolioValueTitle}>{t('pages-portfolio-depositor.portfolioValue')}</p>
                        <p className={styles.portfolioValueSubtitle}>
                            {t('pages-portfolio-depositor.updateFrequency')}
                        </p>
                        <p className={styles.portfolioValue}>{Currency(portfolioValues?.portfolio, { decimals: 0 })}</p>
                    </div>
                    {currency === 'NOK' && (
                        <div className={styles.portfolioChart}>
                            <div className={styles.header}>{t('pages-portfolio-depositor.historicalInterestRate')}</div>
                            <NIBORPortfolioInterestRatesChart />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
