import FxPaper from '#app/components/Paper/FxPaper'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { DateOutput, Empty, PageHeader, PageLayout, Table, TableRow } from '#components'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import ProductBadge from '#components/ProductBadge/ProductBadge'
import { useAuthorization } from '#services/authorization'
import { securityRoleTMap } from '#services/enumTranslationKeyMapping'
import { formatAccount } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { InterestRateChangeExtendedDto } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Alert, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './InterestRateChangeBank.module.scss'

const inactiveFilter = (irc: InterestRateChangeExtendedDto): boolean =>
    !!irc.implemented || irc?.deposit?.expires?.expired
const notSentFilter = (irc: InterestRateChangeExtendedDto): boolean =>
    !inactiveFilter(irc) && !irc.sentToDepositor && !irc.requestedByDepositor

export default function InterestRateChangeIndex() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const organisationCountry = useCurrentCountryCode()

    const authorization = useAuthorization()
    const interestRateChanges = useSelector(selectors.allInterestRateChanges)

    const isAdManager = authorization.bank.hasAdManagerRole

    const sortedInterestRateChanges = interestRateChanges
        .slice()
        .sort((a, b) => new Date(b.changeDate)?.getTime() - new Date(a.changeDate)?.getTime())

    const notSentIrcRows = sortedInterestRateChanges.filter(notSentFilter).map(ircRowMapper)
    const activeIrcRows = sortedInterestRateChanges
        .filter((irc) => !notSentFilter(irc) && !inactiveFilter(irc))
        .map(ircRowMapper)
    const inactiveIrcRows = sortedInterestRateChanges.filter(inactiveFilter).map(ircRowMapper)

    function ircRowMapper(irc: InterestRateChangeExtendedDto): React.ReactNode {
        return (
            <TableRow key={irc.id} onClick={() => navigate(`/interestratechange-bank/${irc.id}`)}>
                <td>
                    <ProductBadge productId={irc.deposit.product.id} />
                </td>
                <td>
                    <InterestBadge interest={irc.oldInterest} newInterest={irc.newInterest} />
                </td>
                <td className={styles.listAccountField}>{formatAccount(irc.deposit.account, organisationCountry)}</td>
                <td className={styles.listDateField}>{DateOutput.formatDate(irc.changeDate)}</td>
            </TableRow>
        )
    }

    return (
        <>
            <PageHeader title={t('pages-interestratechange-bank.listPageHeader')} />
            <PageLayout>
                <FxPaper
                    title={t('pages-interestratechange-bank.createNewTitle')}
                    supportCommonName="interestRateChange"
                >
                    <p>{t('pages-interestratechange-bank.createNewInfo1')}</p>
                    <p>{t('pages-interestratechange-bank.createNewInfo2')}</p>

                    <Stack spacing={2}>
                        <div>
                            <Button
                                id="createNewInterestRateChangeButton"
                                onClick={() => navigate('/interestratechange-bank/new')}
                                variant="contained"
                                disabled={!isAdManager}
                            >
                                {t('pages-interestratechange-bank.createNewButtonText')}
                            </Button>
                        </div>
                        {!isAdManager && (
                            <Alert severity={'warning'}>
                                {t('pages-interestratechange-bank.notAuthorized', {
                                    role: t(securityRoleTMap.BANK_AD_MANAGER),
                                })}
                            </Alert>
                        )}
                    </Stack>
                </FxPaper>

                <FxPaper title={t('pages-interestratechange-bank.notSentTitle')}>
                    <p>{t('pages-interestratechange-bank.notSentInfo')}</p>
                    <Empty predicate={notSentIrcRows.length === 0}>
                        {t('pages-interestratechange-bank.noNotices')}
                    </Empty>
                    <Table>{notSentIrcRows}</Table>
                </FxPaper>

                <FxPaper
                    title={t('pages-interestratechange-bank.forthcomingTitle')}
                    data-cy="forthcomingInterestChanges"
                >
                    <p>{t('pages-interestratechange-bank.forthcomingInfo')}</p>

                    <Empty predicate={activeIrcRows.length === 0}>{t('pages-interestratechange-bank.noChanges')}</Empty>

                    <Table>{activeIrcRows}</Table>
                </FxPaper>

                <FxPaper title={t('pages-interestratechange-bank.historicTitle')}>
                    <p>{t('pages-interestratechange-bank.historicInfo')}</p>

                    <Empty predicate={inactiveIrcRows.length === 0}>
                        {t('pages-interestratechange-bank.noChanges')}
                    </Empty>

                    <Table>{inactiveIrcRows}</Table>
                </FxPaper>
            </PageLayout>
        </>
    )
}
