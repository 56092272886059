import { useEndpoint } from '#command'
import EmptyState from '#components/EmptyState/EmptyState'
import { i18n } from '#components/i18n'
import OnboardingFrame from '#components/OnboardingFrame'
import { openChatBubble } from '#services/hubspot'
import { SecurityRole } from '@fixrate/fixrate-security'
import { Button } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
    inviteId: string
}

const InviteLandingPage = function ({ inviteId }: Props) {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const { getInvite } = useEndpoint()

    //    const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
    const [userInvite, setUserInvite] = useState(null)
    const [notFound, setNotFound] = useState(false)

    const isAuthorizationSignerInvite = () =>
        userInvite?.roles?.every((role: SecurityRole) => role === 'DEPOSITOR_SIGN_AUTHORIZATION')

    useEffect(() => {
        ;(async function () {
            try {
                const data = await getInvite(inviteId)
                await i18n.changeLanguage(data.language)
                setUserInvite(data)
                if (data.status === 'ACCEPTED') {
                    console.log('User has already accepted this invite. Redirects to /marketplace')
                    navigate('/marketplace')
                }
            } catch (e) {
                console.error('Failed to load invite', e)
                setUserInvite(null)
                setNotFound(true)
            }
        })()
    }, [getInvite, navigate, inviteId])

    if (notFound) {
        return (
            <OnboardingFrame header={t('layers-Signup.inviteLandingHeading')} cancel="/marketplace">
                <EmptyState
                    illustration={{ name: 'brokenLink', color: 'default' }}
                    variant="centered-on-background"
                    button={{
                        text: t('layers-Signup.continueToFixrate'),
                        onClick: () => document.location.assign('https://fixrate.no'),
                    }}
                    link={{
                        text: t('layers-Signup.talkToSupport'),
                        onClick: openChatBubble,
                    }}
                    title={t('error.header')}
                    description={{
                        dataCy: 'inviteNotFound',
                        text: t('blocks-UsersList.inviteNotFound'),
                    }}
                ></EmptyState>
            </OnboardingFrame>
        )
    }

    if (!userInvite) {
        return null
    }

    if (userInvite.status === 'EXPIRED') {
        return (
            <OnboardingFrame header={t('layers-Signup.inviteLandingHeading')} cancel="/marketplace">
                <EmptyState
                    illustration={{ name: 'brokenLink', color: 'default' }}
                    variant="centered-on-background"
                    button={{
                        text: t('layers-Signup.continueToFixrate'),
                        onClick: () => document.location.assign('https://fixrate.no'),
                    }}
                    link={{
                        text: t('layers-Signup.talkToSupport'),
                        onClick: openChatBubble,
                    }}
                    title={t('error.header')}
                    description={{
                        dataCy: 'inviteExpired',
                        text: t('layers-Signup.inviteLandingInviteExpired', {
                            inviterName: userInvite.inviterFirstName + ' ' + userInvite.inviterLastName,
                        }),
                    }}
                ></EmptyState>
            </OnboardingFrame>
        )
    }

    const buttons = (
        <Fragment>
            <Button variant={'outlined'} id="inviteIgnoreButton" onClick={() => navigate('/marketplace')}>
                {t('layers-Signup.inviteLandingIgnore')}
            </Button>
            <Button
                variant={'contained'}
                id="inviteContinueButton"
                onClick={() => navigate('/invite/' + userInvite.id + '?continue')}
            >
                {t('layers-Signup.inviteLandingLogin')}
            </Button>
        </Fragment>
    )

    return (
        <OnboardingFrame header={t('layers-Signup.inviteLandingHeading')} cancel="/marketplace" buttons={buttons}>
            {isAuthorizationSignerInvite() && (
                <p>
                    {t('layers-Signup.inviteLandingMessageSignPowerOfAttorney', {
                        inviterName: `${userInvite.inviterFirstName} ${userInvite.inviterLastName}`,
                        organizationName: userInvite.organisationName,
                    })}
                </p>
            )}

            {!isAuthorizationSignerInvite() && (
                <p>
                    {t('layers-Signup.inviteLandingMessage', {
                        inviterName: userInvite.inviterFirstName + ' ' + userInvite.inviterLastName,
                        organizationName: userInvite.organisationName,
                    })}
                </p>
            )}
        </OnboardingFrame>
    )
}

export default InviteLandingPage
