import { Fragment } from 'react'
import ButtonRow from '#components/ButtonRow'
import Button from '#components/Button'
import styles from './Steps.module.css'
import RadioButtonGroup from '#components/RadioButton/RadioButtonGroup'
import RadioButton from '#components/RadioButton/RadioButton'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { DateOutput } from '#components'
import { expectedDurationTypeTMap } from '#services/enumTranslationKeyMapping'
import { StepProps } from '../types'
import { TextField } from '@mui/material'
import { SILVER_GRAY } from '#app/colors/colors'

function nibor({ onPrevious, onNext, fields, t }) {
    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep3NoticeMessage')}</p>
            <div className={styles.inputSection}>
                <RadioButtonGroup
                    group={'expectedDuration'}
                    onChange={fields.expectedDuration.submitValue}
                    value={fields.expectedDuration.value}
                    className={styles.radioGroup}
                >
                    <RadioButton value={'FROM_1_TO_6_MONTHS'}>
                        {t(expectedDurationTypeTMap['FROM_1_TO_6_MONTHS'])}
                    </RadioButton>
                    <RadioButton value={'FROM_6_TO_12_MONTHS'}>
                        {t(expectedDurationTypeTMap['FROM_6_TO_12_MONTHS'])}
                    </RadioButton>
                    <RadioButton value={'FROM_12_TO_24_MONTHS'}>
                        {t(expectedDurationTypeTMap['FROM_12_TO_24_MONTHS'])}
                    </RadioButton>
                    <RadioButton value={'OVER_24_MONTHS'}>{t(expectedDurationTypeTMap['OVER_24_MONTHS'])}</RadioButton>
                    <RadioButton value={'UNKNOWN'}>{t('pages-offer.wizardStep3NoticeUnknownDuration')}</RadioButton>
                </RadioButtonGroup>
            </div>
            {getCommentField(t, fields)}
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>
                    {t('common.previous')}
                </Button>
                <Button id="continueButton" onClick={onNext}>
                    {t('common.continue')}
                </Button>
            </ButtonRow>
        </Fragment>
    )
}

function fixed({ onPrevious, onNext, fields, t }) {
    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep3FixedMessagePart1')}</p>
            {fields.terminationDate.value && (
                <p>
                    {t('pages-offer.wizardStep3FixedTerminationMessage', {
                        terminationDate: DateOutput.formatDate(fields.terminationDate.value),
                    })}
                </p>
            )}
            <p>{t('pages-offer.wizardStep3FixedMessagePart2')}</p>
            <div className={styles.inputSection}>
                <RadioButtonGroup
                    group={'wantExtensionOffer'}
                    onChange={fields.wantExtensionOffer.submitValue}
                    value={fields.wantExtensionOffer.value}
                    className={styles.radioGroup}
                >
                    <RadioButton value={true}>{t('pages-offer.wizardStep3FixedExtensionPositive')}</RadioButton>
                    <RadioButton value={false}>{t('pages-offer.wizardStep3FixedExtensionNegative')}</RadioButton>
                </RadioButtonGroup>
            </div>
            {getCommentField(t, fields)}
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>
                    {t('common.previous')}
                </Button>
                <Button id="continueButton" onClick={onNext}>
                    {t('common.continue')}
                </Button>
            </ButtonRow>
        </Fragment>
    )
}

function getCommentField(t, fields) {
    return (
        <div className={styles.textAreaSection}>
            <h4>{t('pages-offer.addAComment')}</h4>
            <TextField
                multiline
                fullWidth
                style={{ marginTop: '1rem' }}
                InputProps={{
                    style: {
                        padding: '0rem 0.5rem', // Adjust padding to reduce space inside the text box
                        backgroundColor: SILVER_GRAY[50],
                        fontSize: '1.4rem',
                    },
                }}
                onChange={(e) => fields.comment.setValue(e.target.value)}
                onBlur={fields.comment.submit}
                value={fields.comment.value}
            />
        </div>
    )
}

export default function Step3({ onPrevious, onNext, fields }: StepProps) {
    const { t } = useTranslation()
    const products = useSelector((state) => state.products)
    const product = products[fields.productId.value]

    if (product?.type === 'FIXED') {
        return fixed({ onPrevious, onNext, fields, t })
    } else {
        return nibor({ onPrevious, onNext, fields, t })
    }
}
