import usePortfolio from '#services/usePortfolio'
import { useSelector } from '#state/useSelector'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'
import { useTranslation } from 'react-i18next'
import { FundDto } from '@fixrate/fixrate-query'
import { Alert } from '@mui/material'

export const CurrentFundPlacementValue = ({ fund }: { fund: FundDto }) => {
    const { t } = useTranslation()
    const portfolio = usePortfolio()
    const currentPlacementInFund = useSelector(
        (state) => state.fundPlacements?.find((fp) => fp.fundId === fund.id)?.currentValue
    )
    if (!portfolio || !currentPlacementInFund) {
        return null
    }
    return (
        <Alert severity="info" sx={{ width: '100%', fontSize: '1.2rem' }}>
            <span>
                {t('components-ShoppingCart.currentFundPlacement', {
                    currentFundPlacement: CurrencyOutputObject(currentPlacementInFund, {
                        currency: portfolio.currency,
                    }),
                })}
            </span>
        </Alert>
    )
}
