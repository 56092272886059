import { PURPLE } from '#app/colors/colors'
import { formatPhoneNumber } from '#app/services/formatnumber'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { DateOutput } from '#components'
import HelpPopup from '#components/HelpPopup'
import ResendUserInvite from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/DepositorPersonList/ResendUserInvite'
import InviteModal from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/InviteModal'
import PersonWarnings from '#pages/organizations/OrganizationDetail/UsersAndRoles/views/PersonWarnings'
import { useAuthorization } from '#services/authorization'
import { PersonValidator } from '#services/PersonValidator'
import {
    Alert,
    Avatar,
    Button,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import EditPersonModal from '../components/EditPersonModal'
import styles from './UserProfile.module.scss'

type Params = {
    personId?: string
}

export default function UserProfile({ depositorId, basePath }: { depositorId: string; basePath: string }) {
    const { t } = useTranslation()
    const { personId } = useParams<Params>()
    const currentDepositor = useCurrentDepositor()
    const person = currentDepositor.people.find((p) => p.personId === personId)
    const auth = useAuthorization(depositorId)
    const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false)
    const [resendInviteModalIsOpen, setResendInviteModalIsOpen] = useState(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)

    if (!person) {
        return null
    }

    const user = person.associatedUserId && currentDepositor.users.find((user) => user.id === person.associatedUserId)
    const userInvite = currentDepositor.userInvites.find((ui) => ui.id === person.associatedInviteId)
    const isInCustomerDeclaration = PersonValidator.isCustomerDeclarationPerson(person)

    function BooleanValue({ value }: { value: boolean }) {
        if (value === true) {
            return <span>{t('common.yes')}</span>
        }
        if (value === false) {
            return <span>{t('common.no')}</span>
        }
        return (
            <Fragment>
                <span className={styles.notFilled}>{t('common.notStated')}</span>
            </Fragment>
        )
    }

    const textWithFallback = (text, suffix = '') => {
        if (text === null || text === undefined || text === '') {
            return <span className={styles.notFilled}>{t('common.notStated')}</span>
        }
        return text + suffix
    }

    const citizenShipText = (countryCode: string) => {
        if (countryCode === 'NO') {
            return 'Norge'
        }
        if (countryCode === 'SE') {
            return 'Sverige'
        }
        return countryCode
    }

    const customerDeclarationInfoValid = () => {
        return (
            PersonValidator.PEPInformationIsValid(person) &&
            PersonValidator.OwnershipInformationIsValid(person) &&
            PersonValidator.NationalityInformationIsValid(person) &&
            PersonValidator.ContactInformationIsValid(person)
        )
    }

    return (
        <>
            <Card className={styles.userProfile}>
                <PersonWarnings
                    person={person}
                    depositor={currentDepositor}
                    showRedLeftBorder={false}
                    onSendInvite={() => setInviteModalIsOpen(true)}
                    onResendInvite={() => setResendInviteModalIsOpen(true)}
                    onEditNow={() => setEditModalIsOpen(true)}
                />
                <CardContent>
                    <Stack
                        direction={{ md: 'row' }}
                        justifyContent={{ md: 'space-between' }}
                        alignItems={'center'}
                        gap={2}
                    >
                        <Stack gap={1} alignItems="center" direction={{ md: 'row' }}>
                            <Avatar
                                sx={{
                                    fontSize: '3.5rem',
                                    color: PURPLE[500],
                                    width: '8rem',
                                    height: '8rem',
                                    backgroundColor: PURPLE[50],
                                }}
                            >
                                <i className="ri-user-3-line" />
                            </Avatar>
                            <Typography variant="h2">
                                {person.firstName} {person.lastName}
                            </Typography>
                        </Stack>
                        {(auth.depositor.hasAdminRole ||
                            auth.depositor.hasPartnerRole ||
                            auth.depositor.hasAccountHolderRole) && (
                            <Button
                                size={'small'}
                                startIcon={<i className="ri-pencil-line" />}
                                variant={'outlined'}
                                onClick={() => setEditModalIsOpen(true)}
                            >
                                <span>{t('pages-organizations.editProfile')}</span>
                            </Button>
                        )}
                    </Stack>
                    <Divider sx={{ my: 3 }} />
                    <List
                        sx={{
                            flexDirection: 'row',
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            '& .MuiListItem-root': { width: { md: '45%' } },
                        }}
                    >
                        <ListItem disablePadding>
                            <ListItemText
                                primary={t('pages-organizations.personName')}
                                secondary={person.firstName + ' ' + person.lastName}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText
                                primary={t('pages-organizations.personBirthDate')}
                                secondary={person.birthDate ? DateOutput.formatDate(person.birthDate) : '-'}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText
                                primary={t('pages-organizations.personEmail')}
                                secondary={person.email ?? '-'}
                            />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemText
                                primary={t('pages-organizations.personPhone')}
                                secondary={user?.phone ? formatPhoneNumber(user?.phone) : '-'}
                            />
                        </ListItem>
                    </List>

                    {PersonValidator.isInvite(person) && (
                        <Fragment>
                            <h3>{t('pages-organizations.personInfoInvitation')}</h3>
                            <ul className={styles.list}>
                                <li>
                                    <Typography variant="h4">
                                        {t('pages-organizations.personInfoInvitationSent')}
                                    </Typography>
                                    <p>{userInvite?.requested ? DateOutput.formatDate(userInvite.requested) : '-'}</p>
                                </li>
                                <li>
                                    <Typography variant="h4">
                                        {t('pages-organizations.personInfoInvitationValidTo')}
                                    </Typography>
                                    <p>
                                        {userInvite?.expirationDate
                                            ? DateOutput.formatDate(userInvite.expirationDate)
                                            : '-'}
                                    </p>
                                </li>
                            </ul>
                        </Fragment>
                    )}

                    {isInCustomerDeclaration && (
                        <Fragment>
                            <Typography variant="h3">
                                {t('pages-organizations.personInfoCustomerDeclarationHeader')}
                            </Typography>
                            <Typography>{t('pages-organizations.personInfoCustomerDeclarationDescription')}</Typography>
                            {!customerDeclarationInfoValid() && (
                                <Alert
                                    severity="error"
                                    action={
                                        <Button
                                            size={'small'}
                                            color="inherit"
                                            variant={'outlined'}
                                            onClick={() => setEditModalIsOpen(true)}
                                        >
                                            {t('pages-organizations.addInformation')}
                                        </Button>
                                    }
                                    sx={{ mt: 2 }}
                                >
                                    {t('pages-organizations.missingCustomerDeclarationInfoHeader')}
                                </Alert>
                            )}
                            <List
                                sx={{
                                    '& .MuiListItemText-secondary': { color: PURPLE[900] },
                                    maxWidth: '60rem',
                                    gap: '2rem',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    mt: 2,
                                }}
                            >
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={t('pages-organizations.personAddress')}
                                        secondary={person.address || '-'}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={t('pages-organizations.citizenship')}
                                        secondary={person.citizenships.map(
                                            (c, index) =>
                                                citizenShipText(c) +
                                                (index < person.citizenships.length - 1 ? ', ' : '')
                                        )}
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={t('pages-organizations.personInfoTaxResidencies')}
                                        secondary={
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {t('pages-organizations.personInfoCountry')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {t('pages-organizations.personInfoNationalId')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {person.taxResidencies.map((taxResidency) => (
                                                        <TableRow key={taxResidency.value}>
                                                            <TableCell>
                                                                {citizenShipText(taxResidency.nationality)}
                                                            </TableCell>
                                                            <TableCell>{taxResidency.value}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        }
                                    />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h4">
                                                <HelpPopup
                                                    text={t('pages-organizations.personInfoPepDescription')}
                                                    supportCommonName="pep"
                                                >
                                                    {t('pages-organizations.personInfoPep')}
                                                </HelpPopup>
                                            </Typography>
                                        }
                                        secondary={<BooleanValue value={person.pep} />}
                                    />
                                </ListItem>
                                {person.pep && (
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={t('pages-organizations.personInfoPepPosition')}
                                            secondary={textWithFallback(person.pepPosition)}
                                        />
                                    </ListItem>
                                )}
                                {person.pep && (
                                    <ListItem disablePadding sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <ListItemText primary={t('pages-organizations.pepReason')} />
                                        <FormGroup>
                                            <FormControlLabel
                                                label={t('pages-organizations.pep-isSelf')}
                                                control={
                                                    <Checkbox checked={!!person.politicallyExposedPerson.pepSelf} />
                                                }
                                            />
                                            <FormControlLabel
                                                label={t('pages-organizations.pep-familyMemberIsPep')}
                                                control={
                                                    <Checkbox
                                                        checked={!!person.politicallyExposedPerson.familyMemberOfPep}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={t('pages-organizations.pep-coWorkerIsPep')}
                                                control={
                                                    <Checkbox
                                                        checked={!!person.politicallyExposedPerson.colleagueOfPep}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </ListItem>
                                )}
                                {person.pep && (
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={t('pages-organizations.personInfoPepPositionHeading')}
                                            secondary={person.pepPosition ?? '-'}
                                        />
                                    </ListItem>
                                )}
                                {person.pep && (
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={t('pages-organizations.personInfoPepInvestingPrivateFunds')}
                                            secondary={<BooleanValue value={person.pepInvestingPrivateFunds} />}
                                        />
                                    </ListItem>
                                )}
                                <ListItem disablePadding>
                                    <ListItemText
                                        primary={t('pages-organizations.personInfoAmericanCitizenship')}
                                        secondary={<BooleanValue value={person.americanCitizenship} />}
                                    />
                                </ListItem>
                                {person.organisationalRoles.includes('BENEFICIAL_OWNER') && (
                                    <ListItem disablePadding sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <ListItemText primary={t('pages-organizations.ownership-controllingHeader')} />
                                        <FormGroup>
                                            <FormControlLabel
                                                label={t('pages-organizations.ownership-owningShares')}
                                                control={<Checkbox checked={!!person.control.ownsShares} />}
                                            />
                                            <FormControlLabel
                                                label={t('pages-organizations.ownership-ownerWithFamily')}
                                                control={<Checkbox checked={!!person.control.controlsWithRelatives} />}
                                            />
                                            <FormControlLabel
                                                label={t('pages-organizations.ownership-ownerOther')}
                                                control={<Checkbox checked={!!person.control.other} />}
                                            />
                                        </FormGroup>
                                    </ListItem>
                                )}
                                {person.organisationalRoles.includes('BENEFICIAL_OWNER') && (
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={
                                                <Typography variant="h4">
                                                    <HelpPopup
                                                        text={t(
                                                            'pages-organizations.personInfoControlShareDescription'
                                                        )}
                                                        supportCommonName="ownershipKeyPeople"
                                                    >
                                                        {t('pages-organizations.personInfoControlShare')}
                                                    </HelpPopup>
                                                </Typography>
                                            }
                                            secondary={textWithFallback(person.controlShare)}
                                        />
                                    </ListItem>
                                )}
                                {person.organisationalRoles.includes('KEY_PERSON') && (
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={t('pages-organizations.personInfoKeyPersonRole')}
                                            secondary={textWithFallback(person.keyPersonRole)}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </Fragment>
                    )}
                </CardContent>
            </Card>
            <EditPersonModal open={editModalIsOpen} person={person} onClose={() => setEditModalIsOpen(false)} />

            {inviteModalIsOpen && <InviteModal personId={personId} close={() => setInviteModalIsOpen(false)} />}

            {resendInviteModalIsOpen && userInvite && (
                <ResendUserInvite
                    depositorId={depositorId}
                    inviteId={userInvite.id}
                    inviteEmail={userInvite.email}
                    close={() => setResendInviteModalIsOpen(false)}
                    returnTo={basePath}
                />
            )}
        </>
    )
}
