import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import HistoryPanel from '#blocks/HistoryPanel/HistoryPanel'
import { PageHeader, PageLayout } from '#components'
import { formatAccount } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Link, useParams } from 'react-router-dom'
import ChevronCircledLeft from '../../common/icons/ChevronCircledLeft2'
import Implemented from './Implemented'
import styles from './InterestRateChangeBankDetails.module.css'
import NotSentToDepositor from './NotSentToDepositor'
import RequestedByDepositor from './RequestedByDepositor'
import SentToDepositor from './SentToDepositor'
import TooLate from './TooLate'

function isBeforeNow(dateAsString) {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    return new Date(dateAsString + 'T00:00:00').getTime() - now.getTime() < 0
}

type Params = {
    id?: string
}

export default function InterestRateChangeBankDetails() {
    const params = useParams<Params>()
    const organisationCountry = useCurrentCountryCode()

    const interestRateChange = useSelector((state) =>
        selectors.allInterestRateChanges(state).find(
            (irc) =>
                irc.id === params.id ||
                // DepositId may be used as parameter to select the latest IRC that was just created. This IRC will always be !sentToDepositor.
                (irc.deposit.id === params.id && !irc.sentToDepositor)
        )
    )

    const processIdMap = useSelector((state) => {
        const processIdMap = state.deposits.reduce((acc, deposit) => {
            acc[deposit.id] = [deposit.id, deposit.orderId]
            return acc
        }, {})
        state.interestRateChange.forEach((irc) => {
            processIdMap[irc.depositId].push(irc.id)
        })
        return processIdMap
    })

    if (!interestRateChange) return null

    let body
    if (interestRateChange.implemented) {
        body = <Implemented interestRateChange={interestRateChange} />
    } else if (interestRateChange.requestedByDepositor) {
        body = <RequestedByDepositor interestRateChange={interestRateChange} />
    } else if (interestRateChange.sentToDepositor) {
        body = <SentToDepositor interestRateChange={interestRateChange} />
    } else if (isBeforeNow(interestRateChange.sendDeadline)) {
        body = <TooLate interestRateChange={interestRateChange} />
    } else {
        body = <NotSentToDepositor interestRateChange={interestRateChange} />
    }

    return (
        <>
            <PageHeader
                backToLink="/interestratechange-bank"
                title={formatAccount(interestRateChange.deposit.account, organisationCountry)}
                subtitle={interestRateChange.deposit.depositor.name}
            />

            <PageLayout>
                {body}
                <HistoryPanel processId={processIdMap[interestRateChange.depositId]} />
            </PageLayout>
        </>
    )
}
