import { formatAccount, formatIban } from '#services/formatnumber'
import { SettlementAccountDto } from '@fixrate/fixrate-query'
import { MenuItem, Select } from '@mui/material'
import useCurrentCountryCode from '#services/useCurrentCountryCode'
import { useTranslation } from 'react-i18next'

type Props = {
    accounts: SettlementAccountDto[]
    selectedAccountId: string
    setSelectedAccountId: (id: string) => void
    fundPortfolioClientAccountId?: string
}
export function SettlementAccountSelect({ accounts, selectedAccountId, setSelectedAccountId }: Props) {
    const { t } = useTranslation()
    const organisationCountry = useCurrentCountryCode()
    return (
        <Select
            value={selectedAccountId}
            onChange={(e) => setSelectedAccountId(e.target.value as string)}
            displayEmpty
            renderValue={(value) => {
                const account = accounts.find((a) => a.id === value)
                return account
                    ? account.name +
                          '\xA0(' +
                          (account.account
                              ? formatAccount(account.account, organisationCountry)
                              : formatIban(account.iban)) +
                          ')'
                    : t('pages-FundOverview.selectSettlementAccount')
            }}
        >
            {accounts.map((account) => {
                const accountId = account.id
                const display = account.account
                    ? formatAccount(account.account, organisationCountry)
                    : formatIban(account.iban)
                return (
                    <MenuItem key={accountId} value={accountId} disabled={accountId === selectedAccountId}>
                        {account.name}&nbsp;({display})
                    </MenuItem>
                )
            })}
        </Select>
    )
}
