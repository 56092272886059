import { ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import { PageHeader, PageLayout, Paper } from '#components'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import EmptyState from '#app/components/EmptyState/EmptyState'

export function ErrorFallback() {
    const { t } = useTranslation()

    return (
        <div>
            <PageHeader title={t('components-ErrorBoundary.heading')} />
            <PageLayout>
                <EmptyState
                    title={t('components-ErrorBoundary.subHeading')}
                    illustration={{ name: 'errorProblem', color: 'default' }}
                    description={{
                        text: t('components-ErrorBoundary.msgPart1') + ' ' + t('components-ErrorBoundary.msgPart2'),
                    }}
                    button={{ text: t('components-ErrorBoundary.retry'), onClick: () => (window.location.href = '/') }}
                />
            </PageLayout>
        </div>
    )
}

type Props = {
    children: ReactNode
}

export function ErrorBoundary({ children }: Props) {
    return <Sentry.ErrorBoundary fallback={<ErrorFallback />}>{children}</Sentry.ErrorBoundary>
}
