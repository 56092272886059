import { DateOutput } from '#components'
import { useSelector } from '#state/useSelector'
import { Button, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SILVER_GRAY } from '#app/colors/colors'

type Params = {
    depositorId?: string
}

export default function CustomerDetailPartnership() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()

    const partner = useSelector((state) => state.partner)

    const customer = useSelector((state) => state.partner?.customers.find((c) => c.depositorId === params.depositorId))
    const associations = useSelector((state) => state.session?.associations)
    const isAdmin = associations
        .find((assoc) => assoc.organisation?.id === partner?.id)
        ?.roles.includes('PARTNER_ADMIN')

    function endPartnership() {
        navigate(`/customer/${customer.depositorId}/end-partnership`)
    }

    return (
        <Paper sx={{ px: 4, py: 3 }}>
            {partner.partnerCategory === 'ASSET_MANAGER' && (
                <List sx={{ maxWidth: '60rem', padding: 0 }}>
                    <ListItem sx={{ padding: '0' }}>
                        <ListItemIcon sx={{ backgroundColor: getStatusColor(customer?.accepted) }}>
                            {customer?.accepted === true && <i className="ri-check-line green" />}
                            {customer?.accepted === false && <i className="ri-close-line red" />}
                            {customer?.accepted == null && <i className="ri-error-warning-line" />}
                        </ListItemIcon>
                        {customer?.accepted === true && (
                            <ListItemText
                                primary={t('pages-customers.customerDetailPartnershipHeading')}
                                secondary={
                                    <Trans t={t} i18nKey={'pages-customers.customerDetailPartnershipAccepted'}>
                                        Kunden aksepterte avtalen den{' '}
                                        <DateOutput.Date date={customer?.acceptedOrRejectedAt} />.
                                    </Trans>
                                }
                            />
                        )}
                        {customer?.accepted === false && (
                            <ListItemText
                                primary={t('pages-customers.customerDetailPartnershipHeading')}
                                secondary={
                                    <Trans t={t} i18nKey={'pages-customers.customerDetailPartnershipRejected'}>
                                        Kunden avviste forespørsel om partnerskap den{' '}
                                        <DateOutput.Date date={customer?.acceptedOrRejectedAt} />.
                                    </Trans>
                                }
                            />
                        )}
                        {(customer?.accepted === undefined || customer?.accepted === null) && (
                            <ListItemText
                                primary={t('pages-customers.customerDetailPartnershipHeading')}
                                secondary={t('pages-customers.customerDetailPartnershipNotAccepted')}
                            />
                        )}
                    </ListItem>
                </List>
            )}
            {partner.partnerCategory === 'ACCOUNTANT' && (
                <Stack
                    direction={{ md: 'row' }}
                    sx={{ mb: 2 }}
                    gap={1}
                    justifyContent={{ md: 'space-between' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                >
                    <Stack flex={3} spacing={1}>
                        <Typography variant={'h2'}>
                            {t('pages-customers.customerDetailEndPartnershipHeading')}
                        </Typography>
                        <Typography color={SILVER_GRAY[500]} fontSize={'1.4rem'}>
                            {t('pages-customers.customerDetailEndPartnershipMessage', {
                                partnerName: partner.name,
                                customerName: customer.name,
                            })}
                        </Typography>
                        <Tooltip title={isAdmin ? '' : t('pages-customers.adminRequired')} placement={'bottom'}>
                            <div style={{ width: 'fit-content' }}>
                                <Button
                                    onClick={endPartnership}
                                    disabled={!isAdmin}
                                    variant="outlined"
                                    size="small"
                                    data-cy="endPartnershipButton"
                                >
                                    {t('pages-customers.customerDetailEndPartnershipButtonText')}
                                </Button>
                            </div>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
        </Paper>
    )
}

function getStatusColor(success: boolean | undefined | null) {
    if (success === true) {
        return '#40a25120'
    } else if (success === false) {
        return '#B44A0620'
    } else {
        return '#DFE1DF'
    }
}
