import { CurrencyOutput, DateOutput } from '#components'
import { useSelector } from '#state/useSelector'
import DocumentLink from '#components/DocumentLink'
import { FundPlacementTransaction } from '@fixrate/fixrate-query'
import styles from './TransactionTable.module.scss'
import { useTranslation } from 'react-i18next'
import { TransactionTypeBadge } from '#components/TransactionTypeBadge/TransactionTypeBadge'
import {
    Collapse,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import React, { Fragment, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { SILVER_GRAY } from '#app/colors/colors'

export type TransactionDetails = FundPlacementTransaction & {
    depositorName: string
    fundName: string
    fromExchangeName?: string
    toExchangeName?: string
    confirmationSlipNumber?: number
}

type Options = {
    hideDepositor?: boolean
    hideFund?: boolean
}

type Props = {
    transactions: TransactionDetails[]
    options?: Options
}

export function CollapsibleFundPlacementTransactionTable({ transactions, options }: Props) {
    const { t } = useTranslation()
    return (
        <TableContainer component={Paper}>
            <Table aria-label={'Collapsible transaction table'}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t('pages-fundTransactions.date')}</TableCell>
                        {!options?.hideFund && <TableCell>{t('pages-fundTransactions.fund')}</TableCell>}
                        {!options?.hideDepositor && <TableCell>{t('pages-fundTransactions.depositor')}</TableCell>}
                        <TableCell>{t('pages-fundTransactions.amount')}</TableCell>
                        <TableCell>{t('pages-fundTransactions.type')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactions.map((tr) => (
                        <TransactionRow key={tr.id} transaction={tr} options={options} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

type TransactionRowProps = {
    transaction: TransactionDetails
    options?: Options
}
function TransactionRow({ transaction, options }: TransactionRowProps) {
    const [open, setOpen] = useState(false)
    const Currency = useCurrencyOutput()
    const { t } = useTranslation()
    function getNameValueEntries() {
        // Most transaction types share a common structure.
        const commonEntries = [
            { name: t('pages-fundTransactions.depositor'), value: transaction.depositorName },
            { name: t('pages-fundTransactions.fund'), value: transaction.fundName },
            { name: t('pages-fundTransactions.isin'), value: transaction.isin },
            {
                name: t('pages-fundTransactions.transactionDate'),
                value: DateOutput.formatDate(transaction.transactionDate),
            },
            {
                name: t('pages-fundTransactions.settlementDate'),
                value: DateOutput.formatDate(transaction.settlementDate),
            },
            {
                name: t('pages-fundTransactions.type'),
                element: <TransactionTypeBadge transactionType={transaction.type} />,
            },
            { name: t('pages-fundTransactions.amount'), value: Currency(transaction.amount) },
            { name: t('pages-fundTransactions.comment'), value: transaction.commentForDepositor || null },
            { name: t('pages-fundTransactions.confirmationSlipNumber'), value: transaction.confirmationSlipNumber },
        ]
        switch (transaction.type) {
            // Exchange and move transactions have a different structure than the rest.
            case 'EXCHANGE': {
                return [
                    { name: t('pages-fundTransactions.depositor'), value: transaction.depositorName },
                    {
                        name: t('pages-fundTransactions.transactionDate'),
                        value: DateOutput.formatDate(transaction.transactionDate),
                    },
                    {
                        name: t('pages-fundTransactions.settlementDate'),
                        value: DateOutput.formatDate(transaction.settlementDate),
                    },
                    {
                        name: t('pages-fundTransactions.type'),
                        element: <TransactionTypeBadge transactionType={transaction.type} />,
                    },
                    { name: t('pages-fundTransactions.fromShareClass'), value: transaction.fromExchangeName },
                    { name: t('pages-fundTransactions.fromIsin'), value: transaction.exchangeFromIsin },
                    { name: t('pages-fundTransactions.toShareClass'), value: transaction.fundName },
                    { name: t('pages-fundTransactions.toIsin'), value: transaction.isin },
                    { name: t('pages-fundTransactions.comment'), value: transaction.commentForDepositor || null },
                    {
                        name: t('pages-fundTransactions.confirmationSlipNumber'),
                        value: transaction.confirmationSlipNumber,
                    },
                ]
            }
            case 'MOVE': {
                return [
                    { name: t('pages-fundTransactions.depositor'), value: transaction.depositorName },
                    {
                        name: t('pages-fundTransactions.transactionDate'),
                        value: DateOutput.formatDate(transaction.transactionDate),
                    },
                    {
                        name: t('pages-fundTransactions.settlementDate'),
                        value: DateOutput.formatDate(transaction.settlementDate),
                    },
                    {
                        name: t('pages-fundTransactions.type'),
                        element: <TransactionTypeBadge transactionType={transaction.type} />,
                    },
                    { name: t('pages-fundTransactions.fromShareClass'), value: transaction.fromExchangeName },
                    { name: t('pages-fundTransactions.fromIsin'), value: transaction.exchangeFromIsin },
                    { name: t('pages-fundTransactions.toShareClass'), value: transaction.fundName },
                    { name: t('pages-fundTransactions.toIsin'), value: transaction.isin },
                    { name: t('pages-fundTransactions.comment'), value: transaction.commentForDepositor || null },
                    {
                        name: t('pages-fundTransactions.confirmationSlipNumber'),
                        value: transaction.confirmationSlipNumber,
                    },
                ]
            }
            case 'RETROCESSION_CASH': {
                return commonEntries
            }
            case 'DIVIDEND': {
                commonEntries.push(
                    {
                        name: t('pages-fundTransactions.units'),
                        value: CurrencyOutput.formatNoCode(transaction.unitQuantity),
                    },
                    { name: t('pages-fundTransactions.navPrice'), value: Currency(transaction.unitPrice) }
                )
                return commonEntries
            }
            case 'BUY': {
                commonEntries.push(
                    {
                        name: t('pages-fundTransactions.units'),
                        value: CurrencyOutput.formatNoCode(transaction.unitQuantity),
                    },
                    { name: t('pages-fundTransactions.navPrice'), value: Currency(transaction.unitPrice) },
                    { name: t('pages-fundTransactions.purchaseValue'), value: Currency(transaction.purchaseValue) }
                )
                if (transaction.purchaseAccrualValue != null && transaction.purchaseAccrualValue != 0) {
                    commonEntries.push({
                        name: t('pages-fundTransactions.purchaseAccrual'),
                        value: Currency(transaction.purchaseAccrualValue),
                    })
                }
                return commonEntries
            }
            case 'RETROCESSION_UNITS': {
                commonEntries.push(
                    {
                        name: t('pages-fundTransactions.units'),
                        value: CurrencyOutput.formatNoCode(transaction.unitQuantity),
                    },
                    { name: t('pages-fundTransactions.navPrice'), value: Currency(transaction.unitPrice) },
                    { name: t('pages-fundTransactions.purchaseValue'), value: Currency(transaction.purchaseValue) }
                )
                if (transaction.purchaseAccrualValue != null && transaction.purchaseAccrualValue != 0) {
                    commonEntries.push({
                        name: t('pages-fundTransactions.purchaseAccrual'),
                        value: Currency(transaction.purchaseAccrualValue),
                    })
                }
                return commonEntries
            }
            case 'SELL': {
                commonEntries.push(
                    {
                        name: t('pages-fundTransactions.units'),
                        value: CurrencyOutput.formatNoCode(transaction.unitQuantity),
                    },
                    { name: t('pages-fundTransactions.navPrice'), value: Currency(transaction.unitPrice) },
                    { name: t('pages-fundTransactions.purchaseValue'), value: Currency(transaction.purchaseValue) }
                )
                if (transaction.purchaseAccrualValue != null && transaction.purchaseAccrualValue != 0) {
                    commonEntries.push({
                        name: t('pages-fundTransactions.purchaseAccrual'),
                        value: Currency(transaction.purchaseAccrualValue),
                    })
                }
                if (transaction.saleAccrualValue != null && transaction.saleAccrualValue != 0) {
                    commonEntries.push({
                        name: t('pages-fundTransactions.accrual'),
                        value: Currency(transaction.saleAccrualValue),
                    })
                }
                commonEntries.push({
                    name: t('pages-fundTransactions.totalGain'),
                    value: Currency(transaction.totalReturn),
                })
                return commonEntries
            }
            default:
                return commonEntries
        }
    }

    const nameValueEntries = getNameValueEntries()

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="Expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {transaction.documentId != null ? (
                        <Stack direction={'column'}>
                            <Typography fontSize={'1.6rem'}>
                                {DateOutput.formatDate(transaction.transactionDate)}
                            </Typography>
                            <DocumentCell
                                label={t('pages-fundTransactions.confirmationSlip')}
                                documentId={transaction.documentId}
                            />
                        </Stack>
                    ) : (
                        <Typography fontSize={'1.6rem'}>
                            {DateOutput.formatDate(transaction.transactionDate)}
                        </Typography>
                    )}
                </TableCell>
                {!options?.hideFund && (
                    <TableCell>
                        <Stack direction={'column'}>
                            <Typography fontSize={'1.6rem'}>{transaction.fundName}</Typography>
                            <Typography fontSize={'1.2rem'} color={SILVER_GRAY[500]}>
                                {transaction.isin}
                            </Typography>
                        </Stack>
                    </TableCell>
                )}
                {!options?.hideDepositor && (
                    <TableCell>
                        <Typography fontSize={'1.6rem'}>{transaction.depositorName}</Typography>
                    </TableCell>
                )}
                <TableCell>
                    <Typography fontSize={'1.6rem'}>{Currency(transaction.amount)}</Typography>
                </TableCell>
                <TableCell>
                    <span>
                        <TransactionTypeBadge transactionType={transaction.type} />
                        {transaction.fromExchangeName && (
                            <Tooltip
                                title={
                                    <span>
                                        {transaction.fromExchangeName}{' '}
                                        <i className={'ri-arrow-right-line'} aria-label={'to'} /> {transaction.fundName}
                                    </span>
                                }
                                arrow
                            >
                                <i
                                    className={'ri-information-line purple'}
                                    style={{ fontSize: '1.8rem', marginLeft: '0.5rem', fontWeight: 'normal' }}
                                />
                            </Tooltip>
                        )}
                    </span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className={styles.collapsibleContent}>
                            <Stack direction={{ md: 'column', lg: 'row' }} spacing={{ md: 2, lg: 8 }}>
                                <div>
                                    <h4>Detaljer</h4>
                                    <ul className={styles.keyValueList}>
                                        {nameValueEntries
                                            .filter((entry) => entry.value != null || entry.element != null)
                                            .map((entry, index) => (
                                                <li key={index}>
                                                    <span className={styles.key}>{entry.name}</span>
                                                    {entry.element != null ? (
                                                        entry.element
                                                    ) : (
                                                        <span className={styles.value}>{entry.value}</span>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                                {transaction.documentId && (
                                    <Stack>
                                        <h4>{t('pages-fundTransactions.confirmationSlip')}</h4>
                                        <DocumentCell
                                            label={t('common.download')}
                                            documentId={transaction.documentId}
                                        />
                                    </Stack>
                                )}
                            </Stack>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

function DocumentCell({ label, documentId }: { label: string; documentId: string }) {
    const document = useSelector((state) => state.documents[documentId])
    return document ? <DocumentLink link={document.link} name={label} /> : <span>-</span>
}
