import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import graphicElement from './images/supportUser-header.svg'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { Stack, Typography } from '@mui/material'
import { PURPLE, SPRING_GREEN } from '#app/colors/colors'

const SupportUsers = () => {
    const depositor = useCurrentDepositor()
    const { t } = useTranslation()

    const primaryPartnerRelation = depositor?.partnerRelations
        .filter((pr) => pr.accepted)
        .filter((pr) => pr.partnerCategory === 'SUPPORT')[0]
    const primaryAdvisor = depositor?.users.find((user) => user.id === primaryPartnerRelation?.primaryAdvisor)

    const employeeMeta = (lastName) => {
        if (lastName === 'Landsem') {
            return {
                title: 'Head of Sales and Customer Service',
            }
        }
        if (lastName === 'Ellingsen') {
            return {
                title: 'CEO',
            }
        }
        if (lastName === 'Bruvik') {
            return {
                title: 'CEO, Fixrate Capital',
            }
        }
        if (lastName === 'Bahnsen') {
            return {
                title: 'Key Account Manager',
            }
        }
        if (lastName === 'Nyhus') {
            return {
                title: 'Key Account Manager Bank',
            }
        }
        if (lastName === 'Sotberg') {
            return {
                title: 'Business Controller',
            }
        }
        if (lastName === 'Misfjord') {
            return {
                title: 'Partner Manager',
            }
        }
        if (lastName === 'Veie') {
            return {
                title: 'Customer Success Manager',
            }
        }
        if (lastName === 'Braathen') {
            return {
                title: 'Sales Director',
            }
        }
        if (lastName === 'Ljung') {
            return {
                title: 'Director Business Development - Sweden',
            }
        }
        if (lastName === 'Olausson') {
            return {
                title: 'Key Account Manager',
            }
        }
        if (lastName === 'Karlsen') {
            return {
                title: 'Customer Success Manager',
            }
        }
        return {
            title: 'Teknisk hjelp',
        }
    }

    const formatPhoneNumber = (number) => {
        let formattedNumber = number

        //Check for country code and remove it from number
        if (number.indexOf('+47') !== -1) {
            formattedNumber = formattedNumber.replace('+47', '')
        }

        formattedNumber = formattedNumber.match(/.{1,2}/g).join(' ')

        return '+47 ' + formattedNumber
    }

    const WrapperElement = () => {
        if (depositor && primaryAdvisor) {
            return (
                <Stack>
                    <img src={graphicElement} alt={''} style={{ marginBottom: '-0.1rem' }} />
                    <Stack sx={{ backgroundColor: PURPLE[800] }}>
                        <Stack spacing={2} sx={{ padding: '5rem' }}>
                            <Typography sx={{ color: SPRING_GREEN[500] }}>
                                {t('pages-organizations.fixrateContacts')}
                            </Typography>
                            <Stack spacing={1}>
                                <Stack>
                                    <Typography variant="h3" sx={{ color: PURPLE[50] }}>
                                        {primaryAdvisor.firstName} {primaryAdvisor.lastName}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        {employeeMeta(primaryAdvisor.lastName).title}
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        <i className="ri-mail-line" />
                                        {primaryAdvisor.email}
                                    </Typography>
                                    <Typography sx={{ color: PURPLE[50] }}>
                                        <i className="ri-smartphone-line" />
                                        {formatPhoneNumber(primaryAdvisor.phone)}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )
        } else {
            return null
        }
    }

    return <Fragment>{primaryAdvisor && <WrapperElement />}</Fragment>
}

export default SupportUsers
