import { PURPLE } from '#app/colors/colors'
import FundIntermediaryMenu from '#pages/menu/menus/FundIntermediaryMenu'
import { useSelector } from '#state/useSelector'
import { Box, ButtonBase, Divider, Stack, Typography, useMediaQuery } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import FixrateLogo from './FixrateLogo'
import styles from './Menu.module.scss'
import { BankMenu, DepositorMenu, PartnerMenu, SystemUserMenu, UnknownMenu } from './menus'
import AnimatedHamburger from './menus/AnimatedHamburger/AnimatedHamburger'

const usePrevious = (value) => {
    const previous = useRef(null)

    useEffect(() => {
        previous.current = value
    })

    return previous.current
}

const Menu = () => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { pathname } = useLocation()
    const session = useSelector(({ session }) => session)
    const previous = usePrevious(pathname)
    const ref = useRef(null)
    const [height, setHeight] = useState(0)
    const { t } = useTranslation()
    const isMobile = useMediaQuery('(max-width: 800px)')

    const darkMode = session?.organisationType === 'PARTNER'

    function toggleMenu() {
        setIsExpanded(!isExpanded)
        fixHeightOnMobile(!isExpanded)
    }

    function fixHeightOnMobile(isOpen: boolean) {
        if (ref.current instanceof HTMLElement) {
            if (isOpen) {
                setHeight(window.innerHeight - ref.current.getBoundingClientRect().height)
            } else {
                setHeight(0)
            }
        }
    }

    useEffect(() => {
        if (previous !== pathname) {
            setIsExpanded(false)
            fixHeightOnMobile(false)
        }
    }, [pathname, previous])

    const MenuList = useCallback(() => {
        if (session.authenticated || session.authenticationLevel === 'KNOWN') {
            switch (session.organisationType) {
                case 'BANK': {
                    return <BankMenu />
                }
                case 'PARTNER': {
                    return <PartnerMenu />
                }
                case 'DEPOSITOR': {
                    return <DepositorMenu />
                }
                case 'FUND_INTERMEDIARY': {
                    return <FundIntermediaryMenu />
                }
                case 'FIXRATE':
                    return <SystemUserMenu />
                default:
                    return <DepositorMenu />
            }
        }

        if (session.authenticationLevel === 'UNKNOWN') {
            return <UnknownMenu />
        }

        return <ul className={[styles.menu__list, styles.menu__list__closed].join(' ').trim()} />
    }, [session.authenticationLevel, session.authenticated, session.organisationType])

    return (
        <Stack
            component="nav"
            sx={{
                backgroundColor: darkMode ? PURPLE[900] : 'white',
                borderRight: darkMode ? '0.1rem solid rgba(250,250,250,0.2)' : undefined,
            }}
            className={styles.menu}
        >
            <Stack
                direction="row"
                alignItems={'center'}
                py={{ xs: 1, md: 4 }}
                pl={2}
                pr={{ xs: 0, md: 2 }}
                justifyContent={{ xs: 'space-between', md: 'center' }}
                ref={ref}
                sx={{
                    height: {
                        xs: '6rem',
                        md: 'auto',
                    },
                    borderBottom: darkMode ? '0.1rem solid rgba(250,250,250, 0.2)' : undefined,
                }}
            >
                <Link to={'/marketplace'}>
                    <Box sx={{ maxWidth: { xs: '10rem', md: '14rem' } }}>
                        <FixrateLogo maxWidth="100%" darkMode={darkMode} />
                    </Box>
                </Link>

                {isMobile && (
                    <ButtonBase
                        sx={{ py: 1.5, px: 1.5, fontSize: { xs: '1.4rem', md: '1.6rem' } }}
                        onClick={() => toggleMenu()}
                    >
                        <Stack alignItems={'center'} spacing={1} direction={'row'}>
                            <AnimatedHamburger open={isExpanded} darkMode={darkMode} />
                            <Typography fontSize="1.4rem" color={darkMode ? 'white' : undefined} fontWeight={600}>
                                {t('menu.menu')}
                            </Typography>
                        </Stack>
                    </ButtonBase>
                )}
            </Stack>
            <Box
                height={isExpanded ? { height: height + 'px' } : null}
                sx={{ backgroundColor: darkMode ? PURPLE[900] : undefined }}
                className={
                    isExpanded
                        ? [styles.menu__wrapper, styles.menu__wrapper__expanded].join(' ').trim()
                        : styles.menu__wrapper
                }
            >
                <Divider />
                <Stack width="100%">
                    <MenuList />
                </Stack>
            </Box>
        </Stack>
    )
}

export default Menu
