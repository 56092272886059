import addDays from 'date-fns/addDays'
import isSameDay from 'date-fns/isSameDay'
import isWeekend from 'date-fns/isWeekend'
import { useSelector } from '#state/useSelector'
import startOfDay from 'date-fns/startOfDay'

export function useBankCalendar() {
    const bankCalendar = useSelector((state) => state.bankCalendar)

    function isValidBankDay(date: Date) {
        return !isWeekend(date) && bankCalendar.filter((time) => isSameDay(new Date(time), date)).length === 0
    }

    function nextBankDay(date: Date, days = 0) {
        let bankDaysAdded = 0
        let result = date
        while (bankDaysAdded < days || !isValidBankDay(result)) {
            result = addDays(result, 1)
            if (isValidBankDay(result)) {
                bankDaysAdded++
            }
        }
        return startOfDay(result)
    }

    return {
        nextBankDay,
        isValidBankDay,
    }
}
