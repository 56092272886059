import { createContext, useContext } from 'react'
import { CustomerDeclaration } from '@fixrate/fixrate-query'
import jsonpath from 'jsonpath'

const CustomerDeclerationContext = createContext<CustomerDeclaration>(null)

// Define the type for the useCustomerDeclaration function
export function useCustomerDeclaration<ReturnType, Path = string | null>(
    path: Path = null
): Path extends null ? CustomerDeclaration : ReturnType {
    const customerDeclaration = useContext(CustomerDeclerationContext)
    if (!customerDeclaration) {
        throw new Error('useCustomerDeclaration can only be child of CustomerDecleration')
    }
    if (path !== null) {
        return jsonpath.value(customerDeclaration, path as string)
    } else {
        // Typescript is for some reason not able to infer the return type of the function so we have to cast it
        return customerDeclaration as Path extends null ? CustomerDeclaration : ReturnType
    }
}

export const CustomerDeclarationProvider = CustomerDeclerationContext.Provider
