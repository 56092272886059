import Modal from '#components/Modal'
import styles from './NewNotificationSettinsg.module.scss'
import { ButtonRow, Button } from '#components'
import { useTranslation } from '#components/i18n'

export default function NewNotificationSettings({
    onCancel,
    onUserLegacyNotificationsChange,
}: {
    onCancel: () => void
    onUserLegacyNotificationsChange: (value: boolean) => void
}) {
    const { t } = useTranslation('pages-profile')

    return (
        <Modal header={t('emailNotificationModalHeader')} onCancel={onCancel}>
            <div className={styles.body}>
                <p>{t('emailNotificationModalMsg1')}</p>
                <p>{t('emailNotificationModalMsg2')}</p>
                <p>{t('emailNotificationModalMsg3')}</p>
                <p>{t('emailNotificationModalMsg4')}</p>
                <ButtonRow>
                    <Button variant={'secondary'} onClick={onCancel}>
                        {t('common.cancel')}
                    </Button>
                    <Button onClick={() => onUserLegacyNotificationsChange(false)}>
                        {t('emailNotificationModalButton')}
                    </Button>
                </ButtonRow>
            </div>
        </Modal>
    )
}
