import { Route, Routes } from 'react-router-dom'
import News from './News'

export default function NewsRoutes() {
    return (
        <Routes>
            <Route path="/" element={<News />} />
            <Route path="id/:articleId" element={<News />} />
        </Routes>
    )
}
