import { useEffect, useMemo, useRef, useState } from 'react'
import styles from './PortfolioInterestRatesChart.module.scss'
import { Chart } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { InterestOutput } from '#components'
import { useSelector } from '#state/useSelector'
import { useTranslation } from '#components/i18n'
import isAfter from 'date-fns/isAfter'
import subYears from 'date-fns/subYears'
import format from 'date-fns/format'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import startOfYear from 'date-fns/startOfYear'
import { useEndpoint } from '#command'
import { Range, StaggData } from '#state/stagg'
import { differenceInCalendarDays, eachDayOfInterval } from 'date-fns'
import DateRangePicker from '#components/DateRangePicker/DateRangePicker'
import classNames from 'classnames'
import usePortfolio from '#app/services/usePortfolio'
import useUiSetting from '#services/useUiSetting'

const COLORS = {
    FOREST_GREEN: '#21362C',
    SPRING_GREEN: '#95E1BF',
    SILVER_GRAY: '#6F7271',
    PURPLE: '#4D2A98',
    SUNSET_ORANGE: '#FFA621',
}

type LineChart = Chart<'line', (string | null)[] | (number | null)[]>

type Endpoints = {
    getDepositInterestForPortfolioWithSsb: (depositorId: string, period: Range) => Promise<StaggData>
    getNibor: (period: Range) => Promise<StaggData>
}

export default function NIBORPortfolioInterestRatesChart() {
    const { t } = useTranslation('pages-portfolio-depositor')
    const { getDepositInterestForPortfolioWithSsb, getNibor }: Endpoints = useEndpoint()
    const chartRef = useRef<LineChart | null>(null)

    const [depositInterestRatesData, setDepositInterestRatesData] = useState<number[]>([])
    const [niborData, setNiborData] = useState<(number | null)[]>([])
    const [folioData, setFolioData] = useState<(number | null)[]>([])

    const [hideNibor, setHideNibor] = useUiSetting('hideNibor', false)
    const [hideFolio, setHideFolio] = useUiSetting('hideFolio', false)

    const [selectedStartDate, setSelectedStartDate] = useState<Date>(startOfYear(new Date()))
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date())

    const portfolio = usePortfolio()

    const deposits = useSelector((state) => state.deposits)

    const lastEstimatedDate = useMemo(() => {
        const activeDeposits = deposits.filter(
            (deposit) => deposit.terminationDate === null || isAfter(new Date(deposit.terminationDate), new Date())
        ) // Only active deposits
        return activeDeposits.reduce<Date | null>((earliestCommonEstimatedDate, deposit) => {
            const estimatedDate = deposit.estimatedDate ? new Date(deposit.estimatedDate) : null
            if (!estimatedDate) {
                return earliestCommonEstimatedDate
            }
            return !earliestCommonEstimatedDate || isBefore(estimatedDate, earliestCommonEstimatedDate)
                ? estimatedDate
                : earliestCommonEstimatedDate
        }, null)
    }, [deposits])

    // First and last date for the chart
    const minDate = useMemo(() => subYears(new Date(), 10), [])
    const maxDate = useMemo(() => new Date(), [])

    // Calculates max visible interval
    const { maxDateInterval, visibleInterval } = useMemo(() => {
        const maxDateInterval = eachDayOfInterval({
            start: minDate,
            end: maxDate,
        })
            .map((d) => format(d, 'yyyy-MM-dd'))
            .reverse()

        const visibleInterval = maxDateInterval.filter((d) => !isAfter(new Date(d), lastEstimatedDate ?? new Date()))
        return {
            maxDateInterval,
            visibleInterval,
        }
    }, [minDate, maxDate, lastEstimatedDate])

    // Fetches new depositInterestData if portfolio.id changes
    useEffect(() => {
        if (!portfolio?.id) {
            return
        }

        getDepositInterestForPortfolioWithSsb(portfolio.id, '5Y')
            .then((staggData) => {
                const dailyRateMap = staggData[0].series[2].values.reduce(
                    (acc, d) => {
                        acc[d[0]] = d[1]
                        return acc
                    },
                    {} as Record<string, number>
                )
                const data = visibleInterval.map(
                    (d) => dailyRateMap[d] ?? (isBefore(new Date(d), lastEstimatedDate) ? 0 : null)
                )
                setDepositInterestRatesData(data)
            })
            .catch(console.error)
    }, [portfolio?.id, getDepositInterestForPortfolioWithSsb, visibleInterval, lastEstimatedDate])

    // Fetches NIBOR-data
    useEffect(() => {
        getNibor('5Y')
            .then((staggData) => {
                const niborMap = staggData[0].series[0].values.reduce(
                    (acc, d) => {
                        acc[d[0]] = d[1]
                        return acc
                    },
                    {} as Record<string, number>
                )
                const folioMap = staggData[0].series[1].values.reduce(
                    (acc, d) => {
                        acc[d[0]] = d[1]
                        return acc
                    },
                    {} as Record<string, number>
                )

                setNiborData(visibleInterval.map((d) => niborMap[d] ?? null))

                let lastRate: null | number = null
                setFolioData(
                    visibleInterval.map((d) => {
                        const rate = folioMap[d] ?? null
                        if (rate !== null) {
                            lastRate = rate
                        }
                        return rate ?? lastRate
                    })
                )
            })
            .catch(console.error)
    }, [getNibor, visibleInterval])

    // Updates the chart when the data changes, or if selected dates changes
    useEffect(() => {
        if (chartRef.current) {
            const cappedStartDate = isBefore(selectedStartDate, minDate) ? minDate : selectedStartDate
            const cappedEndDate = isAfter(selectedEndDate, maxDate) ? maxDate : selectedEndDate
            const endShift = Math.abs(differenceInCalendarDays(maxDate, cappedEndDate))
            const dataCount = differenceInCalendarDays(cappedEndDate, cappedStartDate) + 1

            chartRef.current.data.datasets[0].data = niborData.slice(endShift, endShift + dataCount)
            chartRef.current.data.datasets[1].data = folioData.slice(endShift, endShift + dataCount)
            chartRef.current.data.datasets[2].data = depositInterestRatesData.slice(endShift, endShift + dataCount)
            chartRef.current.data.labels = visibleInterval.slice(endShift, endShift + dataCount)

            chartRef.current.update()
        }
    }, [
        depositInterestRatesData,
        niborData,
        folioData,
        selectedStartDate,
        selectedEndDate,
        minDate,
        maxDate,
        visibleInterval,
    ])

    // Hide effect
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.data.datasets[0].hidden = hideNibor
            chartRef.current.data.datasets[1].hidden = hideFolio
            chartRef.current.update()
        }
    }, [hideNibor, hideFolio])

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: maxDateInterval,
                    datasets: [
                        {
                            label: t('nibor3m'),
                            data: [],
                            borderColor: COLORS.SUNSET_ORANGE + '99',
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: 'transparent',
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                            hidden: hideNibor,
                        },
                        {
                            label: t('folio'),
                            data: [],
                            borderColor: COLORS.SPRING_GREEN + '99',
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: 'transparent',
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                            hidden: hideFolio,
                        },
                        {
                            label: t('portfolio'),
                            data: [],
                            backgroundColor: (() => {
                                const gradient = ctx.createLinearGradient(0, 0, 0, 400)
                                gradient.addColorStop(0, COLORS.PURPLE + '40')
                                gradient.addColorStop(0.5, COLORS.PURPLE + '00')
                                return gradient
                            })(),
                            borderColor: COLORS.PURPLE,
                            fill: 'start',
                            borderWidth: 2,
                            pointRadius: 0,
                            pointBackgroundColor: 'transparent',
                            pointBorderColor: 'transparent',
                            tension: 0.1,
                            hidden: false,
                        },
                    ],
                },
                options: {
                    aspectRatio: 5 / 2,
                    color: COLORS.SILVER_GRAY,
                    maintainAspectRatio: true,
                    animation: {
                        duration: 500,
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    scales: {
                        x: {
                            type: 'time',
                            ticks: {
                                font: {
                                    size: 10,
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                            },
                            time: {
                                displayFormats: {
                                    day: 'dd. MMM',
                                    month: 'MMM yy',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            beginAtZero: true,
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '500',
                                },
                                color: COLORS.SILVER_GRAY,
                                callback: (value) => value + '%',
                            },
                            grid: {
                                drawBorder: false,
                                color: (context) => (context.tick.value === 0 ? '#00000025' : '#00000000'),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            axis: 'x',
                            mode: 'nearest',
                            position: 'nearest',
                            intersect: false,
                            displayColors: false,
                            callbacks: {
                                label: (tooltipItem) => {
                                    const datapoint = tooltipItem.dataset.data[tooltipItem.dataIndex]
                                    return datapoint !== undefined
                                        ? `${tooltipItem.dataset.label}: ${InterestOutput.format(datapoint as number)}`
                                        : ''
                                },
                            },
                        },
                    },
                },
            })
        }
    }

    return (
        <div className={styles.root}>
            <ul className={styles.legend}>
                <li>
                    <span className={styles.color} style={{ backgroundColor: COLORS.PURPLE }} />
                    <span>{t('portfolio')}</span>
                </li>
                <li
                    className={classNames(styles.clickable, hideNibor ? styles.hidden : undefined)}
                    onClick={() => setHideNibor(!hideNibor)}
                >
                    <span className={styles.color} style={{ backgroundColor: COLORS.SUNSET_ORANGE + '99' }} />
                    <span>{t('nibor3m')}</span>
                </li>
                <li
                    className={classNames(styles.clickable, hideFolio ? styles.hidden : undefined)}
                    onClick={() => setHideFolio(!hideFolio)}
                >
                    <span className={styles.color} style={{ backgroundColor: COLORS.SPRING_GREEN + '99' }} />
                    <span>{t('folio')}</span>
                </li>
            </ul>
            <canvas ref={canvasCallback} style={{ maxWidth: '100%' }} />
            <DateRangePicker
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                onChange={(startDate, endDate) => {
                    if (!isSameDay(selectedStartDate, startDate)) {
                        setSelectedStartDate(startDate)
                    }
                    if (!isSameDay(selectedEndDate, endDate)) {
                        setSelectedEndDate(endDate)
                    }
                }}
                maxDate={maxDate}
                minDate={minDate}
            />
        </div>
    )
}
