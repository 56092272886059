import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import { IntegrationDescription } from '#pages/organizations/OrganizationDetail/Integrations/IntegrationDescription'
import PandaConnectLogo from '#pages/organizations/OrganizationDetail/Integrations/assets/pandaconnect-shrinked.jpg'
import PandaConnectConfigurationDialog from '#pages/organizations/OrganizationDetail/Integrations/PandaConnectConfigurationDialog'

type PandaConnectIntegrationProps = {
    depositor: DepositorDto
}

const tPath = 'pages-organizations.integrationOverview'
export function PandaConnectIntegration({ depositor }: PandaConnectIntegrationProps) {
    const { t } = useTranslation()
    const [dialogOpen, setDialogOpen] = useState(false)
    return (
        <Fragment>
            {dialogOpen && (
                <PandaConnectConfigurationDialog depositor={depositor} onClose={() => setDialogOpen(false)} />
            )}
            <IntegrationDescription
                id={'pandaconnect'}
                isActive={depositor.pandaConnectEnabled}
                title={'Panda Connect'}
                content={t(`${tPath}.pandaconnect.description`)}
                logo={PandaConnectLogo}
                logoAlt={'Panda Connect'}
                onConfigureButtonClick={() => {
                    setDialogOpen(true)
                }}
            />
        </Fragment>
    )
}
