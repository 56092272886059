import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

type Props = {
    url?: string
    id?: string
    emphasized?: boolean
    text: string
    onClick: () => void
    icon: string
    disabled?: boolean
    children?: JSX.Element
}

export default function CorneredMenuItem({ url, id, text, onClick, icon, disabled, children }: Props) {
    const navigate = useNavigate()
    return (
        <Box sx={{ borderRight: '0.1rem solid rgba(0,0,0,0.1)', '&:last-of-type': { borderRight: 0 } }}>
            <Button
                variant={'text'}
                sx={{
                    display: 'flex',
                    fontSize: { xs: '1.2rem', md: '1.4rem' },
                    alignItems: 'center',
                    px: 1.5,
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'none' },
                }}
                id={id}
                disabled={disabled ?? false}
                startIcon={icon && <i className={icon} />}
                onClick={() => (url ? navigate(url) : onClick())}
            >
                {text}
            </Button>
            {children}
        </Box>
    )
}
