import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import EscaliConfigurationDialog from '#pages/organizations/OrganizationDetail/Integrations/EscaliConfigurationDialog'
import { IntegrationDescription } from '#pages/organizations/OrganizationDetail/Integrations/IntegrationDescription'
import EscaliLogo from '#pages/organizations/OrganizationDetail/Integrations/assets/escali-shrinked.jpg'

type EscaliIntegrationProps = {
    depositor: DepositorDto
}

const tPath = 'pages-organizations.integrationOverview'

export function EscaliIntegration({ depositor }: EscaliIntegrationProps) {
    const { t } = useTranslation()
    const [escaliDialogOpen, setEscaliDialogOpen] = useState(false)
    return (
        <Fragment>
            {escaliDialogOpen && (
                <EscaliConfigurationDialog depositor={depositor} onClose={() => setEscaliDialogOpen(false)} />
            )}
            <IntegrationDescription
                id={'escali'}
                title={'Escali'}
                content={t(`${tPath}.escali.description`)}
                logo={EscaliLogo}
                logoAlt={'Escali'}
                contactInfo={t(`${tPath}.escali.contactInfo`)}
                isActive={depositor.escaliEnabled}
                onConfigureButtonClick={() => {
                    setEscaliDialogOpen(true)
                }}
            />
        </Fragment>
    )
}
