import FXDateInput from '#app/components/DateInput/FXDateInput'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import { userIsAccountCreator } from '#app/utilities/accountCreatorUtils'
import { ButtonRow, InterestOutput, LabeledInput } from '#components'
import Button from '#components/Button'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { replaceComma } from '#services/parse'
import { useSelector } from '#state/useSelector'
import { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StepProps } from '../types'
import styles from './Steps.module.css'

const PRODUCT_ID_FOR_FIXED_TERMS = '11'

export default function Step0({ onPrevious, onNext, fields }: StepProps) {
    const { t } = useTranslation()
    const products = useSelector((state) => state.products)
    const depositor = useCurrentDepositor()
    const currentCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const accountHolderList = depositor?.users.filter((user) => userIsAccountCreator(user)) || []
    const settlementAccountList = depositor?.settlementAccounts || []

    const productOptions = Object.values(products).filter((product) => {
        const isVisible = product.visibility !== 'NONE'
        const isNotice = product.termsType === 'NOTICE'
        const isSpecialFixedTerms = currentCurrency === 'NOK' && product.id === PRODUCT_ID_FOR_FIXED_TERMS
        return isVisible && (isNotice || isSpecialFixedTerms)
    })

    const product = products[fields.productId.value]
    const interestRateLabel =
        product?.type === 'FIXED' ? t('pages-offer.fixedInterest') : t('pages-offer.interestMargin')

    const interestHelpText =
        product?.type === 'FIXED'
            ? t('pages-offer.wizardStep0InterestRateHelpFixed')
            : t('pages-offer.wizardStep0InterestRateHelpMargin', {
                  interestBenchmark: InterestOutput.benchmarkLabel(defaultBenchmark(currentCurrency), t),
              })

    const firstValidTerminationDate = () => {
        const date = new Date()
        date.setDate(date.getDate() + 31)
        return date
    }

    useEffect(() => {
        if (fields.productId.value === PRODUCT_ID_FOR_FIXED_TERMS) {
            if (fields.terminationDate.value === null) {
                console.log('Running useEffect, setting terminationDate')
                fields.terminationDate.setValue(firstValidTerminationDate())
            }
        } else {
            console.log('Running useEffect, clearing terminationDate')
            fields.terminationDate.setValue(null)
        }
    }, [fields.productId.value, fields.terminationDate])

    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep0Message')}</p>
            {product?.type === 'FIXED' && <p>{t('pages-offer.wizardStep0FixedInterestMessage')}</p>}
            <div className={styles.inputSection}>
                <div className={styles.column}>
                    <LabeledInput
                        label={t('pages-offer.product')}
                        className={styles.product}
                        helpText={t('pages-offer.wizardStep0ProductHelp')}
                        errorMessage={fields.productId.errorMessage}
                    >
                        <select
                            id={'product'}
                            onChange={(e) => fields.productId.setValue(e.target.value)}
                            value={fields.productId.value || ''}
                            onBlur={fields.productId.submit}
                        >
                            <option key="empty" value="" disabled>
                                {t('pages-offer.wizardStep0ProductPlaceholder')}
                            </option>
                            {productOptions?.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {t('common.productShortName' + product.id)}
                                </option>
                            ))}
                        </select>
                    </LabeledInput>
                    <LabeledInput
                        label={interestRateLabel}
                        className={styles.interestRate}
                        helpText={interestHelpText}
                        errorMessage={fields.interestRate.errorMessage}
                    >
                        <input
                            id={'interestRate'}
                            className={styles.number}
                            type="text"
                            onChange={(e) => fields.interestRate.setValue(e.target.value)}
                            onBlur={fields.interestRate.submit}
                            value={replaceComma(fields.interestRate.value) || ''}
                        />
                        <span>%</span>
                    </LabeledInput>
                </div>
                {fields.productId.value === PRODUCT_ID_FOR_FIXED_TERMS && (
                    <div className={styles.column}>
                        <LabeledInput
                            label={t('pages-offer.duration')}
                            className={styles.deadline}
                            helpText={t('pages-offer.wizardStep0DurationHelp')}
                            errorMessage={fields.terminationDate.errorMessage}
                        >
                            <div className={styles.date}>
                                <FXDateInput
                                    minDate={firstValidTerminationDate()}
                                    id={'terminationDate'}
                                    onlyBankDays
                                    value={new Date(fields.terminationDate.value)}
                                    onChange={(date) => {
                                        fields.terminationDate.setValue(date)
                                        fields.terminationDate.submit()
                                    }}
                                />
                            </div>
                        </LabeledInput>
                        <LabeledInput
                            label={t('pages-offer.settlementAccount')}
                            helpText={t('pages-offer.wizardStep0SettlementAccountHelp')}
                            errorMessage={fields.settlementAccountId.errorMessage}
                        >
                            <select
                                id={'settlementAccountId'}
                                onChange={(e) => fields.settlementAccountId.setValue(e.target.value)}
                                onBlur={fields.settlementAccountId.submit}
                                value={fields.settlementAccountId.value || ''}
                            >
                                <option key="empty" value="" disabled>
                                    {t('pages-offer.wizardStep0SettlementAccountPlaceholder')}
                                </option>
                                {settlementAccountList?.map((settlementAccount) => (
                                    <option key={settlementAccount.id} value={settlementAccount.id}>
                                        {settlementAccount.name}
                                    </option>
                                ))}
                            </select>
                        </LabeledInput>
                    </div>
                )}
                <div className={styles.column}>
                    <LabeledInput
                        label={t('pages-offer.accountHolder')}
                        helpText={t('pages-offer.wizardStep0AccountHolderHelp')}
                        errorMessage={fields.accountHolderUserId.errorMessage}
                    >
                        <select
                            id={'accountHolder'}
                            onChange={(e) => fields.accountHolderUserId.setValue(e.target.value)}
                            onBlur={fields.accountHolderUserId.submit}
                            value={fields.accountHolderUserId.value || ''}
                        >
                            <option key="empty" value="" disabled>
                                {t('pages-offer.wizardStep0AccountHolderPlaceholder')}
                            </option>
                            {accountHolderList?.map((user) => (
                                <option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</option>
                            ))}
                        </select>
                    </LabeledInput>
                </div>
            </div>
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>
                    {t('common.back')}
                </Button>
                <Button id="continueButton" onClick={onNext}>
                    {t('common.continue')}
                </Button>
            </ButtonRow>
        </Fragment>
    )
}
