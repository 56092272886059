import { SILVER_GRAY } from '#app/colors/colors'
import FundReturnsChart from '#blocks/FundReturnsChart/FundReturnsChart'
import { InterestOutput, LoadingSpinner, PageHeader, PageLayout } from '#components'
import List, { ListItem } from '#components/List/List'
import { useFundPlatformFee } from '#services/platformFeeCalculator'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useShoppingCart from '#services/useShoppingCart'
import { useSelector } from '#state/useSelector'
import { Box, Button, Hidden, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FundCartButton } from '../FundMarketplace/FundCartButton/FundCartButton'
import ShareClassSelect from '../FundMarketplace/FundTable/TableComponents/ShareClassSelect'
import ShoppingCartPane from '../FundMarketplace/ShoppingCartPane/ShoppingCartPane'
import KeyFundFigures from './Components/KeyFundFigures'
import RiskScale from './Components/RiskScale'
import styles from './FundDetails.module.scss'
import { FundLogo } from './FundLogo/FundLogo'
import InfoTabs from './InfoTabs/InfoTabs'
import FundReturnsDisclaimer from '#pages/FundDetails/Components/FundReturnsDisclaimer'
import useFundShareClassSelected from '#services/useFundShareClassSelected'
import { MorningstarRating } from '#components/MorningstarRating/MorningstarRating'
import { useLocalizedString } from '#services/useLocalizedString'

export default function FundDetails() {
    const { t } = useTranslation()
    const { fundId, fundCategory } = useParams()

    const currentDepositor = useCurrentDepositor()
    const shoppingCart = useShoppingCart('FUNDS')
    const { calculateTotalCosts, calculatePlatformFee } = useFundPlatformFee()
    const funds = useSelector((state) => state.funds)
    const fund = funds.find((f) => f?.id === fundId)

    const userHasFundInPortfolio = useSelector(
        (state) => state.fundPlacements?.filter((f) => f.fundId === fund?.id).length > 0
    )

    const [fundClassId] = useFundShareClassSelected(fund?.id, fund?.fundShareClasses[0]?.id)
    const [cartOpen, setCartOpen] = useState(false)

    const selectedShareClass = fund?.fundShareClasses.find((f) => f?.id === fundClassId) || fund?.fundShareClasses[0]
    const managersExplanation = useLocalizedString(selectedShareClass?.effectiveInterestRateManagersExplanation)

    const isInShoppingCart = !!shoppingCart.getItemById(fundId)

    const platformFee = calculatePlatformFee(fund?.fundCategory, selectedShareClass)
    const totalCosts = fund && calculateTotalCosts(fund?.fundCategory, selectedShareClass)

    return !fund || !selectedShareClass || !platformFee || !totalCosts ? (
        <LoadingSpinner />
    ) : (
        <Fragment>
            <PageHeader icon="ri-line-chart-line" title={t('pages-fund-details.fund')}>
                <FundCartButton cartOpen={cartOpen} setCartOpen={setCartOpen} />
            </PageHeader>
            <PageLayout>
                <Button
                    startIcon={<i className={'ri-arrow-left-line'} />}
                    href={'/marketplace/category/funds/' + fundCategory}
                >
                    {t('pages-fund-details.goBackToFundList')}
                </Button>
                <div className={styles.fund}>
                    <div className={styles.heading}>
                        <Stack>
                            <h1>{selectedShareClass.fullName}</h1>
                            <Typography color={SILVER_GRAY[500]}>
                                {t('pages-fund-details.isin')}: {selectedShareClass.isin}
                            </Typography>
                            {fund.morningstarRating && (
                                <MorningstarRating
                                    rating={fund.morningstarRating}
                                    direction={'row'}
                                    titleVariant={'h4'}
                                    sx={{ pt: 1 }}
                                />
                            )}
                            <Stack direction={'row'} alignItems={'center'} spacing={1} mt={1}>
                                <Typography>{t('pages-fund-details.shareClassAndAmount')}:</Typography>
                                {fund && <ShareClassSelect fund={fund} depositor={currentDepositor} />}
                            </Stack>
                        </Stack>
                        <Hidden mdDown>
                            <FundLogo fundName={fund.name} />
                        </Hidden>
                    </div>
                    <div className={styles.overview}>
                        <div className={styles.chart}>
                            <section>
                                <FundReturnsChart fundShareClass={selectedShareClass} />
                                <FundReturnsDisclaimer shareClass={selectedShareClass} />
                            </section>
                        </div>
                        <div className={styles.infoSection}>
                            <section>
                                <h2 className={styles.sectionHeading}>
                                    <span>{t('pages-fund-details.keyNumbers')}</span>
                                </h2>
                                <KeyFundFigures fund={fund} shareClass={selectedShareClass} />
                                <div className={styles.comment}>
                                    <p>{managersExplanation ? '*' + managersExplanation : null}</p>
                                </div>
                            </section>
                        </div>
                        <div className={styles.buySection}>
                            <section className={styles.buy}>
                                <h2 className={styles.sectionHeading}>{t('pages-fund-details.trade')}</h2>
                                {!isInShoppingCart && userHasFundInPortfolio && (
                                    <p>{t('pages-fund-details.inPortfolio')}</p>
                                )}
                                {!isInShoppingCart && !userHasFundInPortfolio && (
                                    <p>{t('pages-fund-details.notInPortfolio')}</p>
                                )}
                                {isInShoppingCart && <p>{t('pages-fund-details.inCart')}</p>}
                                {isInShoppingCart && (
                                    <Button
                                        sx={{ mb: 2 }}
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => setCartOpen(true)}
                                    >
                                        {t('pages-fund-details.goToCart')}
                                    </Button>
                                )}
                                {!isInShoppingCart ? (
                                    <Button
                                        variant={'contained'}
                                        color={'primary'}
                                        onClick={() => {
                                            shoppingCart.addItem(fund?.id, selectedShareClass?.id)
                                            setCartOpen(true)
                                        }}
                                    >
                                        {t('pages-fund-details.addToCart')}
                                    </Button>
                                ) : (
                                    <Button
                                        variant={'outlined'}
                                        color={'error'}
                                        onClick={() => {
                                            shoppingCart.removeItem(fundId)
                                        }}
                                    >
                                        {t('pages-fund-details.removeFromCart')}
                                    </Button>
                                )}
                            </section>
                            <section>
                                <h2 className={styles.sectionHeading}>{t('pages-fund-details.costs')}</h2>
                                <List>
                                    <ListItem
                                        infoText={t('pages-fund-details.totalCostsExplanation')}
                                        label={t('pages-fund-details.totalCosts')}
                                        value={InterestOutput.formatRange(totalCosts?.from, totalCosts?.to, 2, true)}
                                    />
                                    <ListItem
                                        infoText={t('pages-fund-details.ongoingChargesExplanation')}
                                        label={t('pages-fund-details.ongoingCharges')}
                                        value={InterestOutput.formatWithDecimals(
                                            selectedShareClass.ongoingCharges || selectedShareClass.managementFee,
                                            3
                                        )}
                                        disabled
                                    />
                                    <ListItem
                                        infoText={t('pages-fund-details.platformFeeExplanation')}
                                        label={t('pages-fund-details.platformFee')}
                                        value={InterestOutput.formatRange(platformFee?.from, platformFee?.to, 2, true)}
                                        disabled
                                    />
                                </List>
                            </section>
                            <section className={styles.risk}>
                                <h2 className={styles.sectionHeading}>{t('pages-fund-details.risk')}</h2>
                                <RiskScale fund={fund} />
                            </section>
                        </div>
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1,
                        }}
                    >
                        <InfoTabs fund={fund} shareClass={selectedShareClass} />
                    </Box>
                </div>
                <ShoppingCartPane open={cartOpen} setOpen={setCartOpen} />
            </PageLayout>
        </Fragment>
    )
}
