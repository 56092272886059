import { DateOutput, InterestOutput } from '#components'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import PageHeader from '#components/PageHeader/PageHeader'
import PageLayout from '#components/PageLayout'
import Paper from '#components/Paper'
import Table from '#components/Table/Table'
import TableCell from '#components/Table/TableCell'
import TableHeader from '#components/Table/TableHeader'
import TableHeaderCell from '#components/Table/TableHeaderCell'
import TableRow from '#components/Table/TableRow'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styles from './DepositorOfferDetails.module.css'

type Params = {
    depositorOfferId?: string
}

export default function DepositorOfferDetails() {
    const { t } = useTranslation()
    const params = useParams<Params>()

    const depositorOffer = useSelector((state) =>
        state.depositorOffers.find((depositorOffer) => depositorOffer.depositorOfferId === params.depositorOfferId)
    )
    const depositor = useSelector((state) => state.depositor)
    const product = useSelector((state) => state.products[depositorOffer?.productId])
    const banks = useSelector((state) => state.banks)

    if (!depositorOffer) return null

    let interestLabel = t('pages-offer.fixedInterest')
    let interest = InterestOutput.format(depositorOffer.interestRate)
    if (product.type !== 'FIXED') {
        interestLabel = t('pages-offer.interestRate')
        interest = InterestOutput.formatMarginWithBenchmark(
            depositorOffer.interestRate,
            defaultBenchmark(depositorOffer.currency),
            t
        )
    }

    return (
        <>
            <PageHeader
                title={t('pages-offer.offerDetailsHeading', {
                    publishedAt: DateOutput.formatDate(depositorOffer.publishedAt),
                })}
                backToLink={'/depositoroffer/overview'}
            />
            <PageLayout>
                <Paper title={t('pages-offer.offerDetailsInfoHeading')} className={styles.paper}>
                    <h4>{t('pages-offer.termsHeading')}</h4>
                    <div className={styles.info}>
                        <LabeledInfo label={t('pages-offer.organization')} info={depositor?.name} />
                        <LabeledInfo
                            label={t('pages-offer.sent')}
                            info={DateOutput.formatDateTime(depositorOffer.publishedAt)}
                        />
                        <LabeledInfo label={t('pages-offer.product')} info={product.name} />
                        <LabeledInfo
                            label={t('pages-offer.totalVolume')}
                            info={`${depositorOffer.totalVolume} ${t('pages-offer.mill')}`}
                        />
                        <LabeledInfo
                            label={t('pages-offer.volume')}
                            info={t('pages-offer.volumeValue', {
                                minVolume: depositorOffer.minVolume,
                                maxVolume: depositorOffer.maxVolume,
                            })}
                        />
                        <LabeledInfo label={interestLabel} info={interest} />
                        <LabeledInfo
                            label={t('pages-offer.deadline')}
                            info={DateOutput.formatDateTime(depositorOffer.deadline)}
                        />
                        <LabeledInfo label={t('pages-offer.comment')} info={depositorOffer.comment} />
                    </div>
                    <h4>{t('pages-offer.banksHeading')}</h4>
                    <Table>
                        <TableHeader>
                            <TableHeaderCell>{t('pages-offer.bank')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.minAmount')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.maxAmount')}</TableHeaderCell>
                            <TableHeaderCell align={'right'}>{t('pages-offer.ordered')}</TableHeaderCell>
                        </TableHeader>
                        {depositorOffer.banks.map((bank) => (
                            <TableRow key={bank.bankId}>
                                <TableCell>{banks[bank.bankId].name}</TableCell>
                                <TableCell align={'right'}>
                                    {bank.minVolume || depositorOffer.minVolume} {t('pages-offer.mill')}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {bank.maxVolume || depositorOffer.maxVolume} {t('pages-offer.mill')}
                                </TableCell>
                                <TableCell align={'right'}>
                                    {bank.acceptedVolume ? `${bank.acceptedVolume} ${t('pages-offer.mill')}` : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </Paper>
            </PageLayout>
        </>
    )
}
