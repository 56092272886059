import { PURPLE } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { usePortfolioValues } from '#app/services/usePortfolioValues'
import { setPortfolioFilter } from '#app/state/reducers/portfolioFilter'
import DepositsOverview from '#pages/portfolio-depositor/DepositsOverview/DepositsOverview'
import { sortDatesDescending } from '#services/dateandtime'
import * as selectors from '#state/selectors'
import { hasFundAccessSelector } from '#state/selectors'
import { PortfolioFilter } from '#state/types'
import { useSelector } from '#state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import { Alert, Box, Divider, Stack, Typography } from '@mui/material'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PortfolioDepositorOverviewHeader from '../PortfolioDepositorOverviewHeader'
import Deposit, { isOver180DaysOld } from './Deposit'
import styles from './DepositList.module.scss'
import DepositTable from './DepositTable'

const hasOldDeposits = (archivedDeposits: DepositDto[]) => archivedDeposits.some(isOver180DaysOld)

type DepositListHeadersProps = {
    showTags: boolean
    sortClass: (column: string) => string
    setSort: (sort: string) => void
    archived?: boolean
}

const getSortFunction =
    (sortColumn: string, sortDirection: number, bankNames: { [bankId: string]: string }, t: (key: string) => string) =>
    (a: DepositDto, b: DepositDto) => {
        switch (sortColumn) {
            case 'product': {
                const pa = t('common.productShortName' + a.product.id)
                const pb = t('common.productShortName' + b.product.id)
                return sortDirection === 1 ? pa.localeCompare(pb) : pb.localeCompare(pa)
            }
            case 'interest':
                return (b.effectiveInterestRate - a.effectiveInterestRate) * sortDirection
            case 'margin':
                if (a.product.type === 'FIXED') {
                    return 1
                }
                if (b.product.type === 'FIXED') {
                    return -1
                }
                return (b.nominalInterestRate - a.nominalInterestRate) * sortDirection
            case 'volume':
                return (b.volume - a.volume) * sortDirection
            case 'account':
                return b.account.localeCompare(a.account) * sortDirection
            case 'bank':
                return bankNames[b.bankId]?.localeCompare(bankNames[a.bankId]) * sortDirection
            case 'tags':
                return (b.tagText || '').localeCompare(a.tagText || '') * sortDirection
            case 'termination':
                return (
                    sortDatesDescending(a.terminationDate || a.created, b.terminationDate || b.created) * sortDirection
                )
            default:
                return sortDatesDescending(a.terminationDate || a.created, b.terminationDate || b.created)
        }
    }

const DepositListHeaders = ({ showTags, sortClass, setSort, archived = false }: DepositListHeadersProps) => {
    const { t } = useTranslation()

    return (
        <ul className={styles.headers}>
            <li
                className={classNames(styles.headersBankName, styles.sortable, sortClass('bank'))}
                onClick={() => setSort('pages-portfolio-depositor.bank')}
            >
                {t('pages-portfolio-depositor.headersBankName')}
            </li>
            <li
                className={classNames(styles.headersInterest, styles.sortable, sortClass('interest'))}
                onClick={() => setSort('pages-portfolio-depositor.interest')}
            >
                {t('pages-portfolio-depositor.headersInterest')}
            </li>
            <li
                className={classNames(styles.headersInterest, styles.sortable, sortClass('margin'))}
                onClick={() => setSort('pages-portfolio-depositor.margin')}
            >
                {t('pages-portfolio-depositor.headersMargin')}
            </li>
            <li>{t('pages-portfolio-depositor.interestRateConvention')}</li>
            {archived ? (
                <li
                    className={classNames(styles.headersProduct, styles.sortable, sortClass('product'))}
                    onClick={() => setSort('pages-portfolio-depositor.product')}
                >
                    {t('pages-portfolio-depositor.headersProduct')}
                </li>
            ) : (
                <li className={styles.headersProduct}>{t('pages-portfolio-depositor.headersProduct')}</li>
            )}
            {showTags ? (
                <li
                    className={classNames(styles.headersTag, styles.sortable, sortClass('tags'))}
                    onClick={() => setSort('pages-portfolio-depositor.tags')}
                >
                    {t('pages-portfolio-depositor.headersTag')}
                </li>
            ) : null}
            <li
                className={classNames(styles.headersVolume, styles.sortable, sortClass('volume'))}
                onClick={() => setSort('pages-portfolio-depositor.volume')}
            >
                {t('pages-portfolio-depositor.headersVolume')}
            </li>
            <li
                className={classNames(styles.headersExpires, styles.sortable, sortClass('termination'))}
                onClick={() => setSort('pages-portfolio-depositor.termination')}
            >
                {t('pages-portfolio-depositor.headersTerminationDate')}
            </li>
        </ul>
    )
}

type DepositGroups = {
    all: DepositDto[]
    active: DepositDto[]
    floatingInterest: DepositDto[]
    fixedInterest: DepositDto[]
    hasTags: boolean
    byId: { [id: string]: DepositDto }
}

export default function DepositList() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const portfolioValues = usePortfolioValues()
    const Currency = useCurrencyOutput()

    const [archivedSortColumn, setArchivedSortColumn] = useState('termination')
    const [archivedSortDirection, setArchivedSortDirection] = useState(1)
    const [showAllArchived, setShowAllArchived] = useState(false)

    const showFunds: boolean = useSelector(hasFundAccessSelector)
    const portfolioFilter = useSelector((state) => state.portfolioFilter)
    const deposits = useSelector((state) => state.deposits)

    const depositGroups: DepositGroups = useMemo(
        () => ({
            all: deposits,
            floatingInterest: deposits
                .filter((deposit) => deposit.product.type === 'FLOATING')
                .filter(
                    (deposit) =>
                        !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime()
                ),
            fixedInterest: deposits
                .filter((deposit) => deposit.product.type === 'FIXED')
                .filter(
                    (deposit) =>
                        !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime()
                ),
            active: deposits.filter(
                (deposit) =>
                    !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime()
            ),
            hasTags: deposits.some((deposit) => deposit.tagText),
            byId: deposits.reduce((acc, curr) => {
                acc[curr.id] = curr
                return acc
            }, {}),
        }),
        [deposits]
    )

    const bankNames = useSelector(selectors.bankNames)

    const filter = useSelector((state) => state.portfolioFilter)
    const floatingDeposits = deposits
        .filter((deposit) => deposit.product.type === 'FLOATING')
        .filter(
            (deposit) => !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime()
        )
        .filter((deposit) => (filter.bankId ? deposit.bankId === filter.bankId : true))
        .filter((deposit) => (filter.tagText ? deposit.tagText === filter.tagText : true))

    const fixedDeposits = deposits
        .filter((deposit) => deposit.product.type === 'FIXED')
        .filter(
            (deposit) => !deposit.terminationDate || new Date(deposit.terminationDate).getTime() > new Date().getTime()
        )
        .filter((deposit) => (filter.bankId ? deposit.bankId === filter.bankId : true))
        .filter((deposit) => (filter.tagText ? deposit.tagText === filter.tagText : true))

    const groupedDeposits = useSelector(selectors.groupedDepositsByProductType)
    const showTags = useSelector((state) => state.deposits.filter((deposit) => deposit.tagText).length > 0)

    function onSelectDeposit(depositId: string) {
        navigate(`/portfolio/id/${depositId}`)
    }

    function setFilter(filter: PortfolioFilter) {
        dispatch(setPortfolioFilter(filter))
    }

    const archivedSortFunction = getSortFunction(archivedSortColumn, archivedSortDirection, bankNames, t)

    const hasSameInterestConvention = depositGroups?.floatingInterest?.every(
        (deposit) => deposit.interestRateConvention === depositGroups.floatingInterest[0].interestRateConvention
    )

    const sortClass = (sortColumn, sortDirection) => (column) => {
        if (sortColumn === column) {
            if (sortDirection === -1) {
                return styles.activeSortUp
            } else {
                return styles.activeSortDown
            }
        }
        return undefined
    }
    const archivedSortClass = sortClass(archivedSortColumn, archivedSortDirection)

    const setSort = (sortColumn, setSortColumn, sortDirection, setSortDirection) => (column) => {
        if (column === sortColumn) {
            if (sortDirection === 1) {
                setSortDirection(-1)
            } else {
                setSortColumn(undefined)
            }
        } else {
            setSortColumn(column)
            setSortDirection(1)
        }
    }
    const setArchivedSort = setSort(
        archivedSortColumn,
        setArchivedSortColumn,
        archivedSortDirection,
        setArchivedSortDirection
    )

    const archivedDepositsList = groupedDeposits.archived
        .sort(archivedSortFunction)
        .map((deposit) => (
            <Deposit
                key={deposit.id}
                deposit={deposit}
                onSelectDeposit={onSelectDeposit}
                bankNames={bankNames}
                showTags={showTags}
                archived
            />
        ))

    return (
        <>
            {showFunds && <DepositsOverview />}
            <PortfolioDepositorOverviewHeader
                deposits={depositGroups.all}
                onChange={setFilter}
                filter={portfolioFilter}
            />
            <Stack maxWidth={'120rem'} spacing={4} mt={4}>
                {depositGroups.floatingInterest.length > 0 && (
                    <Box>
                        <Typography variant="h2" mb={2}>
                            {t('pages-marketplace.depositsFloatingInterest')}
                        </Typography>
                        <DepositTable deposits={floatingDeposits} productType="NIBOR" />
                        {!hasSameInterestConvention && (
                            <Alert severity="info" sx={{ my: 2 }}>
                                {t('pages-portfolio-depositor.interestRateConventionDeviateInfo')}
                            </Alert>
                        )}
                    </Box>
                )}
                {depositGroups.fixedInterest.length > 0 && (
                    <Box>
                        <Typography variant="h2" mb={2}>
                            {t('pages-marketplace.fixedInterestDeposits')}
                        </Typography>
                        <DepositTable deposits={fixedDeposits} productType="FIXED" />
                    </Box>
                )}
                {
                    <Stack spacing={2}>
                        <Stack alignItems={'flex-end'}>
                            <Box sx={{ width: '20rem' }}>
                                <Typography fontWeight={'600'}>
                                    {t('pages-portfolio-depositor.totalDeposits')}
                                </Typography>
                                <Typography fontSize="2rem" fontWeight="800">
                                    {Currency(portfolioValues?.deposits, { decimals: 0 })}
                                </Typography>
                            </Box>
                        </Stack>
                        <Divider sx={{ borderColor: PURPLE[900], borderWidth: '0.1rem' }} />
                    </Stack>
                }
                {groupedDeposits.archived.length > 0 && (
                    <Box>
                        <Typography variant="h3" mb={2} mt={4}>
                            {t('pages-portfolio-depositor.archivedDeposits')}
                        </Typography>
                        <DepositListHeaders
                            showTags={showTags}
                            setSort={setArchivedSort}
                            sortClass={archivedSortClass}
                            archived={true}
                        />
                        <ul
                            className={classNames(
                                styles.groupList,
                                showAllArchived || groupedDeposits.archived.length <= 5 ? undefined : styles.hideOld
                            )}
                        >
                            {archivedDepositsList}
                            {!showAllArchived &&
                                groupedDeposits.archived.length > 5 &&
                                hasOldDeposits(groupedDeposits.archived) && (
                                    <li className={styles.showOlder} onClick={() => setShowAllArchived(true)}>
                                        {t('pages-portfolio-depositor.showOlder')}
                                    </li>
                                )}
                        </ul>
                    </Box>
                )}
            </Stack>
        </>
    )
}
