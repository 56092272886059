import { useState } from 'react'
import styles from './UserFilterSelector.module.scss'
import { useTranslation } from 'react-i18next'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import ToggleSwitch from '#components/ToggleSwitch'
import { NumericFormat } from 'react-number-format'
import { InterestOutput, NumberInput } from '#components'
import Slider from '#components/Slider/Slider'
import { setCustomUserFilter } from '#services/thunks/user'
import classNames from 'classnames'
import { useTracking } from '#services/useTracking'
import { useSelector } from '#state/useSelector'
import { useDispatch } from 'react-redux'
import { Product, UserFilterDto } from '@fixrate/fixrate-query'
import { LoadingButton } from '@mui/lab'
import { useFieldState } from '@fixrate/fieldstate'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'

interface Props {
    open: boolean
    onClose: () => void
    customUserFilter: UserFilterDto
    products: { [productId: string]: Product }
}

export default function CustomNotificationDialog({ open, onClose, customUserFilter, products }: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { trackEvent } = useTracking({ page: 'MarketplaceFilter' })
    const Currency = useCurrencyOutput()
    const interestRateBenchmarkName = t(
        'common.interestRateBenchmark.' +
            useSelector((state) => state.interestRateBenchmarks.defaultInterestRateBenchmark)
    )

    const floatingProductIds = Object.values(products)
        .filter((p) => p.type === 'FLOATING')
        .map((p) => p.id)
    const fixedProductIds = Object.values(products)
        .filter((p) => p.type === 'FIXED')
        .map((p) => p.id)

    const [includeFloatingProducts, setIncludeFloatingProducts] = useState(
        customUserFilter?.products?.some((id) => floatingProductIds.includes(id)) ?? false
    )
    const [includeFixedProducts, setIncludeFixedProducts] = useState(
        customUserFilter?.products?.some((p) => fixedProductIds.includes(p)) ?? false
    )

    const minVolumeFieldValidator = ({ value, isEditing }) => {
        if (isEditing) return ''
        if (value === null) return t('validation.mustBeNumber')
        if (value < 0) return t('validation.mustBePositiveOrZero')
    }
    const minVolumeField = useFieldState<number | null>(
        customUserFilter?.minDepositAmount ?? 0,
        minVolumeFieldValidator
    )

    const maxVolumeFieldValidator = ({ value, isEditing }) => {
        if (isEditing) return ''
        if (value === null) return ''
        if (value < 1) return t('validation.mustBePositive')
        if (value < minVolumeField.value) return t('pages-marketplace.mustBeBiggerThanMinimum')
    }
    const maxVolumeField = useFieldState<number | null>(
        customUserFilter?.maxDepositAmount ?? null,
        maxVolumeFieldValidator
    )

    const [minBankTotalAssets, setMinBankTotalAssets] = useState(customUserFilter?.totalAssets ?? 0)
    const [minInterestRate, setMinInterestRate] = useState<number | null>(customUserFilter?.minInterestRate ?? 0)
    const [minInterestRateMargin, setMinInterestRateMargin] = useState<number | null>(
        customUserFilter?.minInterestRateMargin ?? 0
    )

    const [submitting, setSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState(false)

    const benchmarkInterestRate = useSelector((state) => state.interestRateBenchmarks.defaultBenchmarkInterestRate)

    const selectedProductIds = [
        ...(includeFloatingProducts ? floatingProductIds : []),
        ...(includeFixedProducts ? fixedProductIds : []),
    ]

    async function submit() {
        const valid = selectedProductIds.length > 0 && minVolumeField.validate() && maxVolumeField.validate()
        if (!valid) {
            setSubmitError(true)
            return
        }

        setSubmitError(false)
        setSubmitting(true)

        trackEvent({ event: 'save' })
        const success = await dispatch(
            setCustomUserFilter(
                customUserFilter.filterId,
                selectedProductIds,
                minVolumeField.value,
                maxVolumeField.value,
                minBankTotalAssets,
                minInterestRate,
                minInterestRateMargin,
                true
            )
        )
        setSubmitting(false)
        if (success) {
            onClose()
        } else {
            setSubmitError(true)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
            <DialogTitle>{t('pages-marketplace.customFilter')}</DialogTitle>
            <DialogContent>
                <div className={styles.customFilterModal}>
                    <section>
                        <h4>{t('pages-marketplace.interestType')}</h4>
                        <div className={styles.flex}>
                            <div className={styles.halfWidth}>
                                <label className={classNames(styles.checkboxTitle)}>
                                    <ToggleSwitch
                                        dataCy="floatingInterestSwitch"
                                        className={styles.toggleSwitch}
                                        checked={includeFloatingProducts}
                                        onChange={() => setIncludeFloatingProducts((v) => !v)}
                                    />
                                    <span>{t('pages-marketplace.newOffersFloatingInterest')}</span>
                                </label>
                            </div>
                            <div className={styles.halfWidth}>
                                <label className={classNames(styles.checkboxTitle)}>
                                    <ToggleSwitch
                                        dataCy="fixedInterestSwitch"
                                        className={styles.toggleSwitch}
                                        checked={includeFixedProducts}
                                        onChange={() => setIncludeFixedProducts((v) => !v)}
                                    />
                                    <span>{t('pages-marketplace.newOffersFixedInterest')}</span>
                                </label>
                            </div>
                        </div>
                        {selectedProductIds.length === 0 && (
                            <p className="field-error-message">{t('pages-marketplace.notificationError')}</p>
                        )}
                    </section>
                    <section>
                        <h4>{t('pages-marketplace.interestLevel')}</h4>
                        <div className={styles.flex}>
                            <div className={classNames(styles.halfWidth, styles.inputContainer)}>
                                <label>{t('pages-marketplace.minimumInterestMargin')}</label>
                                <div className={styles.alignCenter}>
                                    <NumericFormat
                                        data-cy="notificationMinimumInterestMargin"
                                        maxLength={5}
                                        decimalScale={2}
                                        allowLeadingZeros={true}
                                        decimalSeparator={','}
                                        suffix={'%'}
                                        onValueChange={(values) =>
                                            setMinInterestRateMargin(values.floatValue ? values.floatValue : 0)
                                        }
                                        value={minInterestRateMargin}
                                    />
                                    <span className={styles.plusSeparator}>+</span>
                                    <span className={styles.niborLabel}>{interestRateBenchmarkName}</span>
                                </div>
                                <p>
                                    {t('pages-marketplace.floatingInterestTodayExplanation')}
                                    <strong>
                                        {InterestOutput.format(benchmarkInterestRate + minInterestRateMargin)}
                                    </strong>
                                </p>
                            </div>
                            <div className={classNames(styles.halfWidth, styles.inputContainer)}>
                                <label>{t('pages-marketplace.minimumInterest')}</label>
                                <NumericFormat
                                    data-cy="notificationMinimumInterest"
                                    maxLength={5}
                                    decimalScale={2}
                                    allowLeadingZeros={true}
                                    decimalSeparator={','}
                                    suffix={'%'}
                                    value={minInterestRate}
                                    onValueChange={(values) =>
                                        setMinInterestRate(values.floatValue ? values.floatValue : 0)
                                    }
                                />
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>{t('pages-marketplace.volumeLimits')}</h4>
                        <div className={styles.flex}>
                            <div className={classNames(styles.halfWidth, styles.sliderWrapper)}>
                                <label className={styles.sliderLabel}>{t('pages-marketplace.minDepositAmount')}</label>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <NumberInput
                                        data-cy="notificationMinimumAmount"
                                        sx={{ width: '12rem' }}
                                        value={minVolumeField.value}
                                        onChange={minVolumeField.setValue}
                                        onBlur={(e) => {
                                            minVolumeField.onBlur(e)
                                            maxVolumeField.validate()
                                        }}
                                        placeholder={t('pages-marketplace.noVolumeLimit')}
                                        error={!minVolumeField.valid}
                                        noDecimals
                                    />
                                    {minVolumeField.value !== null && (
                                        <span>
                                            {minVolumeField.value === 1
                                                ? t('common.million')
                                                : t('common.million_other')}
                                        </span>
                                    )}
                                </Box>
                                {!minVolumeField.valid && (
                                    <p className="field-error-message">{minVolumeField.errorMessage}</p>
                                )}
                            </div>
                            <div className={classNames(styles.halfWidth, styles.sliderWrapper)}>
                                <label className={styles.sliderLabel}>{t('pages-marketplace.maxDepositAmount')}</label>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <NumberInput
                                        data-cy="notificationMaximumAmount"
                                        sx={{ width: '12rem' }}
                                        value={maxVolumeField.value}
                                        onChange={maxVolumeField.setValue}
                                        onBlur={maxVolumeField.onBlur}
                                        placeholder={t('pages-marketplace.noVolumeLimit')}
                                        error={!maxVolumeField.valid}
                                        noDecimals
                                    />
                                    {maxVolumeField.value !== null && (
                                        <span>
                                            {maxVolumeField.value === 1
                                                ? t('common.million')
                                                : t('common.million_other')}
                                        </span>
                                    )}
                                </Box>
                                {!maxVolumeField.valid && (
                                    <p className="field-error-message">{maxVolumeField.errorMessage}</p>
                                )}
                            </div>
                        </div>
                    </section>
                    <section>
                        <h4>{t('pages-marketplace.bankTotalAssets')}</h4>
                        <div className={styles.flex}>
                            <div className={classNames(styles.halfWidth, styles.sliderWrapper)}>
                                <label className={styles.sliderLabel}>{t('pages-marketplace.minimumBankAssets')}</label>
                                <Slider
                                    data-cy="notificationMinimumBankSize"
                                    min={0}
                                    max={20000}
                                    step={500}
                                    darkBackground={true}
                                    value={minBankTotalAssets}
                                    formatFunc={(value) =>
                                        value > 0
                                            ? t('pages-marketplace.atLeastMillions', {
                                                  amount: Currency(value, { decimals: 0, withCurrency: false }),
                                              })
                                            : t('pages-marketplace.allBanks')
                                    }
                                    onChange={(e) => setMinBankTotalAssets(+e.target.value)}
                                />
                            </div>
                        </div>
                    </section>
                    {submitError && (
                        <p className="field-error-message">{t('pages-marketplace.customNotificationSubmitError')}</p>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={'text'} onClick={onClose} disabled={submitting}>
                    {t('common.cancel')}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    data-cy="notificationSaveCustom"
                    loading={submitting}
                    disabled={submitting}
                    onClick={submit}
                >
                    {t('common.save')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
