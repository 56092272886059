import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useCommand } from '#command'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Switch,
} from '@mui/material'
import AsyncButton from '#components/Button/AsyncButton'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'

const tPath = 'pages-organizations.pandaconnect'

export type PandaConnectProps = {
    depositor: DepositorDto
    onClose: () => void
}

export default function PandaConnectConfigurationDialog({ depositor, onClose }: PandaConnectProps) {
    const { t } = useTranslation()
    const { updateDepositorPandaConnectConfiguration } = useCommand()
    const dispatch = useDispatch()
    const [integrationEnabled, setIntegrationEnabled] = useState(depositor.pandaConnectEnabled)
    const [integrationTermsAccepted, setIntegrationTermsAccepted] = useState(depositor.pandaConnectEnabled)

    function handleIntegrationEnabledChange(checked: boolean) {
        setIntegrationEnabled(checked)
        if (!checked) {
            setIntegrationTermsAccepted(false)
        }
    }

    async function handleSubmit() {
        const { waitForCommand } = await updateDepositorPandaConnectConfiguration(depositor.id, integrationEnabled)

        const success = await waitForCommand()
        if (success) {
            dispatch(
                showConfirmationModal({
                    title: t(`${tPath}.confirmationModal.title`),
                    text: t(`${tPath}.confirmationModal.text`),
                    buttonText: t('common.continue'),
                })
            )
            onClose()
        }
    }

    const canSubmit = () => {
        if (integrationEnabled) {
            return integrationTermsAccepted
        }
        return true
    }

    return (
        <FxDialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
            <FxDialogTitle onClose={onClose}>{t(`${tPath}.configureIntegration.heading`)}</FxDialogTitle>
            <FxDialogContent>
                <FormControl sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2, mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                id={'pandaconnectEnabledSwitch'}
                                checked={integrationEnabled}
                                onChange={(e) => {
                                    handleIntegrationEnabledChange(e.target.checked)
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={t(`${tPath}.configureIntegration.enabled`)}
                    />
                    {integrationEnabled && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={'pandaconnectCheckbox'}
                                    checked={integrationTermsAccepted}
                                    onChange={(e) => {
                                        setIntegrationTermsAccepted(e.target.checked)
                                    }}
                                />
                            }
                            label={t(`${tPath}.configureIntegration.terms`, { depositorName: depositor.name })}
                        />
                    )}
                </FormControl>
            </FxDialogContent>
            <FxDialogActions>
                <Button onClick={onClose}>{t('common.cancel')}</Button>
                <AsyncButton variant={'contained'} disabled={!canSubmit()} onClick={handleSubmit}>
                    {t('common.save')}
                </AsyncButton>
            </FxDialogActions>
        </FxDialog>
    )
}
