import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import jsonpath from 'jsonpath'
import { useValidate } from '../Validate'
import { useCustomerDeclaration } from '../CustomerDeclarationContext'
import styles from './YesOrNoRadio.module.scss'
import { setCustomerDeclarationValue } from '#services/thunks/depositor'
import { CustomerDeclaration } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@mui/material'

type Options = {
    yes: ReactNode
    no: ReactNode
}

type Props = {
    path: string
    options?: Options
    'data-cy'?: string
}

export default function YesOrNoRadio({ path, options = null, 'data-cy': dataCy }: Props) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const customerDeclaration = useCustomerDeclaration<CustomerDeclaration>()
    const initValue = jsonpath.value(customerDeclaration, path)
    const [yes, setYes] = useState<boolean>(initValue === true)
    const [no, setNo] = useState<boolean>(initValue === false)
    const defaultOptions = {
        yes: t('common.yes'),
        no: t('common.no'),
    }
    const { setValid, setTouched } = useValidate(path)

    useEffect(() => {
        const valid = yes || no
        setValid(valid)
    })

    function storeUpdate(path, value) {
        dispatch(setCustomerDeclarationValue(customerDeclaration.depositorId, path, value))
    }

    function onChange(value, type) {
        setTouched(true)
        if (value) {
            if (type === 'yes') {
                setYes(true)
                setNo(false)
                storeUpdate(path, true)
            }
            if (type === 'no') {
                setNo(true)
                setYes(false)
                storeUpdate(path, false)
            }
        } else {
            if (type === 'yes') {
                setYes(false)
            }
            if (type === 'no') {
                setNo(false)
            }
            storeUpdate(path, undefined)
        }
    }

    const dataStateYes = yes ? 'checked' : 'unchecked'
    const dataStateNo = no ? 'checked' : 'unchecked'

    return (
        <div className={styles.yesorno}>
            <FormControlLabel
                className={styles.radiolabel}
                label={<span className={no ? styles.selected : null}>{options?.no || defaultOptions.no}</span>}
                control={
                    <Checkbox
                        onChange={(event) => onChange(event.target.checked, 'no')}
                        checked={no}
                        name={path}
                        data-state={dataStateNo}
                        data-cy={dataCy ? dataCy + '-no' : undefined}
                    />
                }
            />
            <FormControlLabel
                className={styles.radiolabel}
                label={<span className={yes ? styles.selected : null}>{options?.yes || defaultOptions.yes}</span>}
                control={
                    <Checkbox
                        onChange={(event) => onChange(event.target.checked, 'yes')}
                        checked={yes}
                        name={path}
                        data-state={dataStateYes}
                        data-cy={dataCy ? dataCy + '-yes' : undefined}
                    />
                }
            />
        </div>
    )
}
