import { useTranslation } from 'react-i18next'
import { FundCategory, FundCustomerDto } from '@fixrate/fixrate-query'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { CurrencyOutput, DateOutput, InterestOutput } from '#components'
import RemovePlatformFeeEntry from '#pages/FundCustomers/FundCustomerDetail/PlatformFee/RemovePlatformFeeEntry'

type Props = {
    depositorId: string
    fundCategory: FundCategory
    platformFeeHistory: FundCustomerDto['specialPlatformFeeHistory'][FundCategory]
}

export default function PlatformFeeHistory({ depositorId, fundCategory, platformFeeHistory }: Props) {
    const { t } = useTranslation()

    const hasHistory = Object.keys(platformFeeHistory).length > 0

    return hasHistory ? (
        <Table size={'small'}>
            <TableHead>
                <TableRow>
                    <TableCell>{t('pages.fundCustomers.customerDetail.platformFee.startDate')}</TableCell>
                    <TableCell>{t('pages.fundCustomers.customerDetail.platformFee.steps')}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.entries(platformFeeHistory)
                    .reverse()
                    .map(([startDate, steps]) => (
                        <TableRow key={startDate}>
                            <TableCell>{DateOutput.formatDate(startDate)}</TableCell>
                            <TableCell>
                                {steps && (
                                    <table style={{ textAlign: 'left', width: '100%' }}>
                                        <tbody>
                                            {Object.entries(steps).map(([entryVolume, fee]) => (
                                                <tr key={entryVolume}>
                                                    <td>{CurrencyOutput.formatMillion(+entryVolume * 1e6)}</td>
                                                    <td>
                                                        <i className="ri-arrow-right-line" />
                                                    </td>
                                                    <td>{InterestOutput.formatWithDecimals(fee, 4)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                                {!steps && <i>{t('pages.fundCustomers.customerDetail.platformFee.standardPrice')}</i>}
                            </TableCell>
                            <TableCell>
                                <RemovePlatformFeeEntry
                                    depositorId={depositorId}
                                    fundCategory={fundCategory}
                                    startDate={startDate}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    ) : (
        <p>{t('pages.fundCustomers.customerDetail.platformFee.noHistory')}</p>
    )
}
