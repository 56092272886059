import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import HistoryPanel from '#blocks/HistoryPanel/HistoryPanel'
import { InterestOutput, PageHeader } from '#components'
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner'
import columnStyles from '#components/PageLayout/PageLayout.module.scss'
import { formatOrganizationNumber } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styles from './OrderDetails.module.scss'
import OrderDialog from './OrderDialog/OrderDialog'
import OrderProgress from './OrderProgress'

type Params = {
    id?: string
}

export default function OrderDetails() {
    const { t } = useTranslation()
    const { id: orderId } = useParams<Params>()
    const organisationCountry = useCurrentCountryCode()

    const order = useSelector((state) => selectors.ordersWithAd(state).find((order) => order.id === orderId))
    const product = useSelector((state) => order && state.products[order.ad.productId])
    const documents = useSelector((state) => order && selectors.documentIdToDocumentMapper(state)(order.documents))

    if (!order || !product) {
        return (
            <div className="layout__center-content">
                <LoadingSpinner text={t('common.loadingData')} />
            </div>
        )
    }

    return (
        <>
            <PageHeader
                title={order.depositor.name}
                subtitle={formatOrganizationNumber(order.depositor.nationalIdentity, organisationCountry)}
                backToLink={'/bank-orders'}
            >
                <div className="layout__header-element">
                    <div className={styles.orderDetails__headerVolume}>
                        {t('common.volumeWithCurrency', { count: order.volume, currency: order?.currency })}
                    </div>
                    <div className={styles.orderDetails__headerInterest}>
                        {InterestOutput.format(order.ad.interest)}
                    </div>
                    <div className={styles.orderDetails__headerProduct}>{product.name}</div>
                </div>
            </PageHeader>
            <div className={columnStyles.layoutPadding}>
                <OrderProgress orderState={order.orderState} />
                <div className={styles.flexRowHistoryRight}>
                    <div>
                        <OrderDialog order={order} documents={documents} />
                    </div>
                    <div>
                        <HistoryPanel processId={[order.id]} />
                    </div>
                </div>
            </div>
        </>
    )
}
