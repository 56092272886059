import Paper from '#components/Paper'
import { useCommand } from '#command'
import Button from '#components/Button'
import { ButtonRow } from '#components'
import { useFieldState } from '@fixrate/fieldstate'
import styles from './PartnerRequest.module.css'
import { PartnerRelationDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormControlLabel } from '@mui/material'
import useCurrentDepositor from '#app/services/useCurrentDepositor'

export default function PartnerRequest({ openPartnerRequest }: { openPartnerRequest: PartnerRelationDto }) {
    const { t } = useTranslation()

    const acceptAdvisor = useFieldState(false, ({ value }) => {
        if (!value) {
            return t('pages-organizations.partnerRequestValidation')
        }
    })
    const acceptSharing = useFieldState(false, ({ value }) => {
        if (!value) {
            return t('pages-organizations.partnerRequestValidation')
        }
    })

    const depositor = useCurrentDepositor()

    const { acceptPartnerInvite, rejectPartnerInvite } = useCommand()

    async function onAccept() {
        if (acceptAdvisor.validate() && acceptSharing.validate()) {
            const { waitForCommand } = await acceptPartnerInvite(openPartnerRequest.partnerId, depositor.id)
            await waitForCommand()
        }
    }

    async function onDecline() {
        const { waitForCommand } = await rejectPartnerInvite(openPartnerRequest.partnerId, depositor.id)
        await waitForCommand()
    }

    return openPartnerRequest.partnerCategory === 'ACCOUNTANT' ? (
        <Paper title={t('pages-organizations.accountantRequestHeading')}>
            <p>
                {t('pages-organizations.accountantRequestMessagePart1', {
                    partnerName: openPartnerRequest.name,
                    depositorName: depositor.name,
                })}
            </p>
            <p>
                {t('pages-organizations.accountantRequestMessagePart2', {
                    partnerName: openPartnerRequest.name,
                })}
            </p>
            <FormControlLabel
                label={t('pages-organizations.accountantRequestTermsPart1', {
                    partnerName: openPartnerRequest.name,
                    depositorName: depositor.name,
                })}
                control={
                    <Checkbox
                        name="acceptPartnerInvite"
                        data-cy="acceptPartnerInvite"
                        checked={acceptAdvisor.value}
                        onChange={(e) => {
                            acceptSharing.setValue(e.target.checked)
                            acceptAdvisor.setValue(e.target.checked)
                        }}
                        defaultChecked
                    />
                }
            />
            <p className="field-error-message">{acceptAdvisor.errorMessage}</p>

            <ButtonRow>
                <Button variant={'cancel'} onClick={onDecline}>
                    {t('common.decline')}
                </Button>
                <Button data-cy="acceptPartnerInviteButton" onClick={onAccept}>
                    {t('common.accept')}
                </Button>
            </ButtonRow>
        </Paper>
    ) : (
        <Paper title={t('pages-organizations.partnerRequestHeading')}>
            <p>
                {openPartnerRequest.advisors?.length > 0
                    ? t('pages-organizations.partnerRequestMessagePart1WithAdvisor', {
                          advisorNames: openPartnerRequest.advisors
                              .map((a) => a.firstName + ' ' + a.lastName)
                              .join(` ${t('common.and')} `),
                          count: openPartnerRequest.advisors.length,
                          partnerName: openPartnerRequest.name,
                          depositorName: depositor.name,
                      })
                    : t('pages-organizations.partnerRequestMessagePart1', {
                          partnerName: openPartnerRequest.name,
                          depositorName: depositor.name,
                      })}
            </p>
            <p>
                {t('pages-organizations.partnerRequestMessagePart2', {
                    partnerName: openPartnerRequest.name,
                })}
            </p>
            <p>
                {t('pages-organizations.partnerRequestMessagePart3', {
                    partnerName: openPartnerRequest.name,
                    depositorName: depositor.name,
                })}
            </p>
            <label className={styles.checkbox}>
                <input
                    type="checkbox"
                    name="acceptPartnerInvite"
                    data-cy="acceptPartnerInvite"
                    checked={acceptAdvisor.value}
                    onChange={(e) => acceptAdvisor.setValue(e.target.checked)}
                />

                {t('pages-organizations.partnerRequestTermsPart1', {
                    partnerName: openPartnerRequest.name,
                    depositorName: depositor.name,
                })}
            </label>
            <p className="field-error-message">{acceptAdvisor.errorMessage}</p>
            <label className={styles.checkbox}>
                <input
                    type="checkbox"
                    name="acceptSharePortfolio"
                    data-cy="acceptSharePortfolio"
                    checked={acceptSharing.value}
                    onChange={(e) => acceptSharing.setValue(e.target.checked)}
                />
                {t('pages-organizations.partnerRequestTermsPart2', {
                    partnerName: openPartnerRequest.name,
                    depositorName: depositor.name,
                })}
            </label>
            <p className="field-error-message">{acceptSharing.errorMessage}</p>

            <ButtonRow>
                <Button variant={'cancel'} onClick={onDecline}>
                    {t('common.decline')}
                </Button>
                <Button data-cy="acceptPartnerInviteButton" onClick={onAccept}>
                    {t('common.accept')}
                </Button>
            </ButtonRow>
        </Paper>
    )
}
