import { useSelector } from '#state/useSelector'
import { useFieldState } from '@fixrate/fieldstate'
import { FundShareClassPriceData } from '@fixrate/fixrate-query'
import { BigNumber } from 'bignumber.js'
import format from 'date-fns/format'
import { useState } from 'react'
import { FundBuyOrderRow } from '../pages/FundBuyOrderOverview/FundBuyOrderTable'
import { FundSellOrderRow } from '../pages/FundSellOrderOverview/FundSellOrderTable'
import { useJwt } from '#services/useJwt'
import { useQuery } from 'react-query'

export const useFund = (transactionDate: Date | null, fundTransactionOrderRow: FundSellOrderRow | FundBuyOrderRow) => {
    const [fundNav, setFundNav] = useState<BigNumber>(null)
    const [fundAccrual, setFundAccrual] = useState<BigNumber>(null)

    const fundShareClass = useSelector((state) =>
        state.funds
            .flatMap((f) => f.fundShareClasses)
            .find((fsc) => fsc.id === fundTransactionOrderRow?.fundShareClassId)
    )

    const fund = useSelector((state) => state.funds.find((f) => f.id === fundTransactionOrderRow?.fundId))

    const accrualField = useFieldState<number | null>(null, ({ isSet }) => {
        if (fund?.isDividendFund && !isSet) {
            return 'Rentekurs må fylles ut'
        }
        return ''
    })

    const { jwt, isJwtLoading } = useJwt()

    const { data, isLoading, isSuccess } = useQuery(
        ['fundNav', fundShareClass?.isin, transactionDate],
        async () => {
            if (!fundShareClass?.isin || !fund || !transactionDate) {
                return null
            }
            const formattedTransactionDate = format(transactionDate, 'yyyy-MM-dd')
            const navResponse = await fetch(
                `/api/marketdata/v2/nav/${fundShareClass.isin}?date=${formattedTransactionDate}`,
                { headers: { Authorization: 'Bearer ' + jwt } }
            )
            if (!navResponse.ok) {
                throw new Error('Network response was not ok')
            }
            const navs = (await navResponse.json()) as FundShareClassPriceData[]
            return navs.length > 0 ? navs[0] : null
        },
        {
            enabled: !!jwt && !isJwtLoading && !!fundShareClass?.isin && !!transactionDate,
            onSuccess: (nav) => {
                if (nav) {
                    if (nav.unitEntry.unitDate === format(transactionDate, 'yyyy-MM-dd')) {
                        setFundNav(new BigNumber(nav.unitEntry.unitPrice))
                    } else {
                        setFundNav(null)
                    }
                    if (nav.interestEntry.interestDate === format(transactionDate, 'yyyy-MM-dd')) {
                        setFundAccrual(new BigNumber(nav.interestEntry.interestPrice))
                        if (nav.interestEntry.interestPrice !== accrualField.value) {
                            accrualField.fn.setValue(nav.interestEntry.interestPrice)
                        }
                    } else {
                        setFundAccrual(null)
                        accrualField.fn.setValue(null)
                    }
                } else {
                    setFundNav(null)
                    setFundAccrual(null)
                    accrualField.fn.setValue(null)
                }
            },
        }
    )

    return { fundNav, fundAccrual, isLoading, isSuccess }
}
