import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import SwimbirdConfigurationDialog from '#pages/organizations/OrganizationDetail/Integrations/SwimbirdConfigurationDialog'
import { IntegrationDescription } from '#pages/organizations/OrganizationDetail/Integrations/IntegrationDescription'
import SwimbirdLogo from '#pages/organizations/OrganizationDetail/Integrations/assets/swimbird-shrinked.jpg'

type SwimbirdIntegrationProps = {
    depositor: DepositorDto
}

const tPath = 'pages-organizations.integrationOverview'
export function SwimbirdIntegration({ depositor }: SwimbirdIntegrationProps) {
    const { t } = useTranslation()
    const [swimbirdDialogOpen, setSwimbirdDialogOpen] = useState(false)
    return (
        <Fragment>
            {swimbirdDialogOpen && (
                <SwimbirdConfigurationDialog depositor={depositor} onClose={() => setSwimbirdDialogOpen(false)} />
            )}
            <IntegrationDescription
                id={'swimbird'}
                title={'Swimbird'}
                content={t(`${tPath}.swimbird.description`)}
                logo={SwimbirdLogo}
                logoAlt={'Swimbird logo'}
                isActive={depositor.swimbirdEnabled}
                onConfigureButtonClick={() => {
                    setSwimbirdDialogOpen(true)
                }}
            />
        </Fragment>
    )
}
