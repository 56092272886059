import { MainFundCategory } from '#app/pages/FundMarketplace/FundMarketplace'
import { useSelector } from '#app/state/useSelector'
import { forEach } from 'lodash'
import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { sendSentryMessage } from '#services/sentry'

type FundsViewType = 'GRID' | 'LIST'

type Action =
    | {
          type: 'HIDE_INBOX_SWITCH_MESSAGE'
          payload: boolean
      }
    | {
          type: 'SHARE_CLASS_FOR_SLIDEOUT'
          payload: string
      }
    | {
          type: 'FUNDS_VIEW_TYPE'
          payload: FundsViewType
      }
    | {
          type: 'FUND_MAIN_CATEGORY_FILTER'
          payload: MainFundCategory
      }
    | {
          type: 'MIN_MAX_VOLUME_FILTER'
          payload: [number, number]
      }
    | {
          type: 'BANK_DOCUMENT_CHECKED'
          payload: { orderId: string; documentId: string }
      }
    | {
          type: 'BANK_DOCUMENT_UNCHECKED'
          payload: { orderId: string; documentId: string }
      }
    | {
          type: 'SET_SHARE_CLASS_FOR_FUND'
          payload: { fundId: string; shareClassId: string }
      }
type Dispatch = (action: Action) => void
type State = {
    doNotShowInboxSwitchMessage: boolean
    shareClassIdForSlideout: string
    fundsViewType: FundsViewType
    filterMainFundCategory: MainFundCategory
    minMaxVolumeFilter: [number, number]
    bankDocumentsChecked: { [orderId: string]: string[] }
    shareClassForFund: { [fundId: string]: string }
}
type LocalStorageProviderProps = { children: React.ReactNode }

const defaultState: State = {
    doNotShowInboxSwitchMessage: false,
    shareClassIdForSlideout: '',
    fundsViewType: 'LIST',
    filterMainFundCategory: 'ALL',
    minMaxVolumeFilter: [1, 19],
    bankDocumentsChecked: {},
    shareClassForFund: {},
}

// set each key in defaultState to the value in localStorage if it exists
const getSessionInitialState = (userId: string): State => {
    const sessionState = defaultState
    const keySuffix = userId ? userId.slice(0, 5) : ''

    Object.keys(sessionState).forEach((key) => {
        const value = window.localStorage.getItem(key + keySuffix)
        if (value) {
            try {
                sessionState[key] = JSON.parse(value)
            } catch (e) {
                sendSentryMessage(
                    'Error parsing value from localStorage as JSON',
                    { key: key + keySuffix, value },
                    'warning'
                )
            }
        }
    })

    return sessionState
}

const LocalStorageContext = createContext<{ keys: State; dispatchLocalStorage: Dispatch } | undefined>(undefined)

function LocalStorageProvider({ children }: LocalStorageProviderProps) {
    const session = useSelector((state) => state.session)
    // The user id in the current session
    const userId = session.id
    const [keys, dispatchLocalStorage] = useReducer(localStorageReducer, getSessionInitialState(userId))
    const value = useMemo(() => {
        return { keys, dispatchLocalStorage }
    }, [keys])

    useEffect(() => {
        if (userId) {
            const keySuffix = userId.slice(0, 5)
            forEach(keys, (value, key) => {
                window.localStorage.setItem(key + keySuffix, JSON.stringify(value))
            })
        }
    }, [keys, userId])

    return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>
}

function localStorageReducer(keys: State, action: Action) {
    switch (action.type) {
        case 'HIDE_INBOX_SWITCH_MESSAGE': {
            return {
                ...keys,
                doNotShowInboxSwitchMessage: action.payload,
            }
        }
        case 'SHARE_CLASS_FOR_SLIDEOUT': {
            return {
                ...keys,
                shareClassIdForSlideout: action.payload,
            }
        }
        case 'FUNDS_VIEW_TYPE': {
            return {
                ...keys,
                fundsViewType: action.payload,
            }
        }
        case 'FUND_MAIN_CATEGORY_FILTER': {
            return {
                ...keys,
                filterMainFundCategory: action.payload,
            }
        }
        case 'MIN_MAX_VOLUME_FILTER': {
            return {
                ...keys,
                minMaxVolumeFilter: action.payload,
            }
        }
        case 'BANK_DOCUMENT_CHECKED': {
            const { orderId, documentId } = action.payload
            return {
                ...keys,
                bankDocumentsChecked: {
                    ...keys.bankDocumentsChecked,
                    [orderId]: [...(keys.bankDocumentsChecked[orderId] || []), documentId],
                },
            }
        }
        case 'BANK_DOCUMENT_UNCHECKED': {
            const { orderId, documentId } = action.payload
            return {
                ...keys,
                bankDocumentsChecked: {
                    ...keys.bankDocumentsChecked,
                    [orderId]: (keys.bankDocumentsChecked[orderId] || []).filter((id) => id !== documentId),
                },
            }
        }
        case 'SET_SHARE_CLASS_FOR_FUND': {
            const { fundId, shareClassId } = action.payload
            return {
                ...keys,
                shareClassForFund: {
                    ...keys.shareClassForFund,
                    [fundId]: shareClassId,
                },
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

// Custom hook to get the provided context value from LocalStorageProvider
function useLocalStorage() {
    const context = useContext(LocalStorageContext)
    if (context === undefined) {
        throw new Error('useLocalStorage must be used within a LocalStorageProvider')
    }
    return context
}

export { LocalStorageProvider, useLocalStorage }
