import { useCallback } from 'react'
import { useCommand } from '#command'
import { ButtonRow } from '#components'
import Button from '#components/Button'
import Modal from '#components/Modal'
import { useFieldState } from '@fixrate/fieldstate'
import { isAllDigitsEqual, isSequence } from '#services/validateFields'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './SetPinCode.module.css'

type Params = {
    action?: 'edit'
}

export default function SetPinCode() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const params = useParams<Params>()
    const { setPinCode } = useCommand()

    const validatePinCode = useCallback(
        ({ value, isEditing }) => {
            if (!value) {
                return t('pages-profile.pincodeMissing')
            }
            if (!isEditing && !validatePinCode(value)) {
                return t('pages-profile.pincodeInvalidOnlyDigits')
            }
            if (!isEditing && (isSequence(value, 1) || isSequence(value, -1))) {
                return t('pages-profile.pincodeInvalidNoSequence')
            }
            if (!isEditing && isAllDigitsEqual(Number(value))) {
                return t('pages-profile.pincodeInvalidNoEqualDigits')
            }
        },
        [t]
    )
    const pinCode = useFieldState('', validatePinCode)

    async function submit() {
        if (pinCode.validate()) {
            const { waitForCommand } = await setPinCode(pinCode.value)
            const success = await waitForCommand()
            if (success) {
                pinCode.untouch()
                navigate('/profile')
            }
        }
    }

    const title = params.action === 'edit' ? t('pages-profile.editPincode') : t('pages-profile.createPincode')

    return (
        <Modal header={title} cancel="/profile" width="36rem">
            <section>
                <div className={styles.pinCode}>
                    <p>{t('pages-profile.pincodeInstructions')}</p>

                    <dl className={styles.inputvalue}>
                        <dt className={styles.inputvalueLabel}>
                            <label htmlFor="pincode">{t('pages-profile.pincode')}</label>
                        </dt>
                        <dd>
                            <div className={styles.pincodeLayout}>
                                <input
                                    name="newPinCode"
                                    type="password"
                                    value={pinCode.value}
                                    onChange={(e) => pinCode.setValue(e.target.value)}
                                    onBlur={pinCode.onBlur}
                                />
                            </div>
                            <p className="field-error-message">{!pinCode.valid && pinCode.errorMessage}</p>
                        </dd>
                    </dl>

                    <ButtonRow>
                        <Button onClick={submit}>{t('pages-profile.save')}</Button>
                    </ButtonRow>
                </div>
            </section>
        </Modal>
    )
}
