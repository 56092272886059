import { useQueries } from 'react-query'
import { DateRange } from 'react-date-range'
import { enGB, nb, sv } from 'date-fns/locale'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, useTheme } from '@mui/material'

import { LoadingSpinner } from '#components'
import { useJwt } from '#services/useJwt'

type Props = { countryCode: string }

export default function FundTradingInfoCalendar({ countryCode }: Readonly<Props>) {
    const [year, setYear] = useState(new Date().getFullYear())
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const { i18n } = useTranslation()
    const theme = useTheme()
    const { jwt, isJwtLoading } = useJwt()

    const locale = useMemo(() => {
        return i18n.language === 'en' ? enGB : i18n.language === 'sv' ? sv : nb
    }, [i18n.language])
    const countries = ['NO']

    if (countryCode !== 'NO') {
        countries.push(countryCode)
    }

    const holidays = useQueries(
        countries.map((country) => {
            return {
                queryKey: ['holidays', year, country],
                queryFn: async () => {
                    const response = await fetch(`/api/holiday/${country}/${year}`, {
                        headers: { Authorization: 'Bearer ' + jwt },
                    })
                    if (!response.ok) {
                        return Promise.reject(`Failed to fetch holidays for ${country} in ${year}`)
                    }
                    const data = await response?.json()
                    if (data) {
                        return Object.keys(data).map((date) => new Date(date))
                    }
                },
                staleTime: 1000 * 60 * 60 * 24,
                enabled: !!jwt && !isJwtLoading, // Only run the query if jwt exists,
            }
        })
    )

    if (holidays.every((holiday) => holiday.isLoading || !holiday.isSuccess || holiday.isError)) {
        return <LoadingSpinner />
    }

    const allHolidays = holidays.map((holiday) => holiday.data).flat()

    return (
        <Box p={1} sx={{ boxShadow: theme.shadows[8] }}>
            <DateRange
                shownDate={selectedDate}
                onShownDateChange={(date) => {
                    setYear(date.getFullYear())
                    setSelectedDate(date)
                }}
                disabledDay={(date) => date.getDay() === 0 || date.getDay() === 6}
                disabledDates={allHolidays}
                showDateDisplay={false}
                showMonthArrow={true}
                showPreview={false}
                weekStartsOn={1}
                fixedHeight={true}
                locale={locale}
            />
        </Box>
    )
}
