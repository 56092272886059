import ActionBlock from '#components/ActionBlock/ActionBlock'
import { DateOutput } from '#components'
import ButtonRow from '#components/ButtonRow/ButtonRow'
import { InterestOutput } from '#components'
import { useSelector } from '#state/useSelector'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import config from '#app/config'
import styles from './DepositDetailsIRC.module.css'
import Button from '#components/Button'
import { TerminationMode } from '#pages/portfolio-depositor/DepositDetails/DepositTermination/DepositTermination'
import { TerminationModeConstant } from '#pages/portfolio-bank/DepositDetailsBank/DepositDetailsBank'
import { useCommand } from '#command'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'

const API_BASE_URL = config().apiUrl

type Props = {
    deposit: DepositDto
    terminationMode: TerminationMode
    setTerminationMode: (terminationMode: TerminationMode) => void
}

export default function DepositDetailsIRC({ deposit, terminationMode, setTerminationMode }: Props) {
    const { t } = useTranslation()
    const interestRateChange = useSelector((state) =>
        state.interestRateChange.find((irc) => irc.id === deposit.interestRateChangeId)
    )
    const document = useSelector((state) => interestRateChange && state.documents[interestRateChange.documentId])
    const { acceptInterestRateChange } = useCommand()

    // Hide if not relevant or data is not loaded
    if (!deposit?.newInterestRateChangeDate) return null
    if (!interestRateChange) return null

    // Hide if the user has started a termination
    if (terminationMode !== TerminationModeConstant.HIDE || deposit.terminationState !== 'NO_ACTIVE_TERMINATION') {
        return null
    }

    // Hide if IRC is accepted
    if (interestRateChange.acceptedByDepositor) {
        return null
    }

    // Hide if deadline for rejecting the IRC is reached
    if (new Date(interestRateChange.terminationDeadline) < new Date()) {
        return null
    }

    async function submitAcceptInterestRateChange() {
        const { waitForCommand } = await acceptInterestRateChange(deposit.interestRateChangeId)
        await waitForCommand()
    }

    if (interestRateChange.newInterest > interestRateChange.oldInterest) {
        return (
            <ActionBlock header={t('pages-portfolio-depositor.interestChangeHeader')}>
                <p>{t('pages-portfolio-depositor.interestChangeInfo1')}</p>
                <p>
                    {t('pages-portfolio-depositor.interestChangeInfo2', {
                        date: DateOutput.formatVerboseMonth(interestRateChange.changeDate),
                        oldInterest: InterestOutput.format(interestRateChange.oldInterest),
                        newInterest: InterestOutput.format(interestRateChange.newInterest),
                    })}
                </p>
                <p>
                    {document && (
                        <DocumentLink
                            name={t('pages-portfolio-depositor.interestChangeDocumentName')}
                            link={API_BASE_URL + document.link}
                        />
                    )}
                </p>
                <ButtonRow>
                    <Button id="acceptButton" onClick={submitAcceptInterestRateChange}>
                        {t('common.accept')}
                    </Button>
                </ButtonRow>
            </ActionBlock>
        )
    }

    return (
        <ActionBlock header={t('pages-portfolio-depositor.interestChangeHeader')}>
            <div className={styles.sectionWithBorder}>
                <p>{t('pages-portfolio-depositor.interestChangeInfo1')}</p>
                <p>
                    {t('pages-portfolio-depositor.interestChangeInfo2', {
                        date: DateOutput.formatVerboseMonth(interestRateChange.changeDate),
                        oldInterest: InterestOutput.format(interestRateChange.oldInterest),
                        newInterest: InterestOutput.format(interestRateChange.newInterest),
                    })}
                </p>
                <p>
                    {t('pages-portfolio-depositor.interestChangeInfo3', {
                        date: DateOutput.formatVerboseMonth(interestRateChange.terminationDeadline),
                    })}
                </p>
                <p>
                    {document && (
                        <DocumentLink
                            name={t('pages-portfolio-depositor.interestChangeDocumentName')}
                            link={API_BASE_URL + document.link}
                        />
                    )}
                </p>
            </div>
            <div className={styles.sectionWithBorder}>
                <h4>{t('pages-portfolio-depositor.conditionalTerminationHeader')}</h4>
                <p>{t('pages-portfolio-depositor.conditionalTerminationInfo1')}</p>
                <p>
                    {t('pages-portfolio-depositor.conditionalTerminationInfo2', {
                        date: DateOutput.formatVerboseMonth(interestRateChange.changeDate),
                    })}
                </p>
                <p>
                    {t('pages-portfolio-depositor.conditionalTerminationInfo3', {
                        date: DateOutput.formatVerboseMonth(interestRateChange.terminationDeadline),
                    })}
                </p>

                <ButtonRow>
                    <Button id="rejectButton" onClick={() => setTerminationMode('REJECT_INTEREST_RATE_CHANGE')}>
                        {t('pages-portfolio-depositor.conditionalTerminationButtonText')}
                    </Button>
                </ButtonRow>
            </div>
            <div className={styles.sectionWithBorder}>
                <h4>{t('pages-portfolio-depositor.acceptInterestChangeHeader')}</h4>
                <p>
                    {t('pages-portfolio-depositor.acceptInterestInfo', {
                        date: DateOutput.formatVerboseMonth(deposit.newInterestRateChangeDate),
                    })}
                </p>
                <ButtonRow>
                    <Button id="acceptButton" onClick={submitAcceptInterestRateChange}>
                        {t('pages-portfolio-depositor.acceptInterestButtonText')}
                    </Button>
                </ButtonRow>
            </div>
        </ActionBlock>
    )
}
