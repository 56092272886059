import { useEffect, useState } from 'react'
import config from '#app/config'
import { useTranslation } from 'react-i18next'

interface StatusMessage {
    active: boolean
    message: {
        nb: string
        en: string
    }
}

const DEV_STATUS_MESSAGE: StatusMessage = {
    active: false,
    message: {
        nb: 'Dette er en testmelding for utviklingsmiljøet.',
        en: 'This is a test message for the development environment.',
    },
}

const CURRENT_ENVIRONMENT = config().environment

const useStatusMessage = () => {
    const { i18n } = useTranslation()

    const [statusMessage, setStatusMessage] = useState<string | null>(null)

    useEffect(() => {
        let fetchStatusMessage = async () => {
            try {
                const response = await fetch(
                    `https://fixrate-public.s3.eu-central-1.amazonaws.com/status-message/${CURRENT_ENVIRONMENT.toLowerCase()}/data.json`,
                    {
                        cache: 'no-store',
                    }
                )
                if (response.ok) {
                    const result = (await response.json()) as StatusMessage

                    if (result?.active) {
                        const message = result?.message?.[i18n.language] || result?.message?.['nb']
                        setStatusMessage(message ?? null)
                    } else {
                        setStatusMessage(null)
                    }
                } else {
                    setStatusMessage(null)
                }
            } catch (reason) {
                console.warn('Failed to fetch status message for BankID', reason)
            }
        }

        if (CURRENT_ENVIRONMENT === 'DEV') {
            fetchStatusMessage = async () => {
                if (DEV_STATUS_MESSAGE.active) {
                    const message = DEV_STATUS_MESSAGE.message[i18n.language] || DEV_STATUS_MESSAGE.message['nb']
                    setStatusMessage(message ?? null)
                } else {
                    setStatusMessage(null)
                }
            }
        }

        fetchStatusMessage()
        const interval = setInterval(fetchStatusMessage, 60_000)
        return () => clearInterval(interval)
    }, [i18n.language])

    return statusMessage
}

export default useStatusMessage
