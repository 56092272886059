import { FOREST_GREEN, PURPLE, SILVER_GRAY, SUNSET_ORANGE, CHART_COLORS } from '#app/colors/colors'
import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import usePlatformFeeVirtualValuta from '#app/services/platformFeeVirtualValuta'
import usePortfolio from '#app/services/usePortfolio'
import { FundCategory } from '@fixrate/fixrate-query'
import { CurrencyOutput, InterestOutput } from '#components'
import { useSelector } from '#state/useSelector'
import { Alert, Box, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './PricingChart.module.scss'
import Big from 'big.js'

type Props = {
    condensed?: boolean
    order?: {
        type: 'BUY' | 'SELL'
        amount: number
    }
    onClick?: () => void
}

const barChartColors = [PURPLE[500], SUNSET_ORANGE[500], ...CHART_COLORS]

export default function CurrencyPricingBar({ condensed, order, onClick }: Props) {
    const { t } = useTranslation()
    const { convertToVirtual } = usePlatformFeeVirtualValuta()
    const portfolio = usePortfolio()
    const depositor = useSelector((state) => state.depositor)
    const currencies = depositor?.portfolios?.map((p) => p.currency)

    const feeModel = useSelector((state) => state.depositor?.fundData?.feeModel)
    if (!feeModel || !portfolio) {
        return null
    }
    if (order != null && order.amount < 0) {
        return null
    }
    const orderType = order?.type
    const volumeChange = orderType === 'BUY' ? order.amount : orderType === 'SELL' ? -order.amount : 0

    const newMarketValue = {
        ...feeModel.marketValue,
        [portfolio.currency]: (feeModel.marketValue[portfolio.currency] ?? 0) + volumeChange,
    }

    const totalPortfolioValue = feeModel.marketValueVirtualValuta + convertToVirtual(portfolio.currency, volumeChange)

    const allVolumeSteps = Object.keys(feeModel.platformFeeSteps)
        .map((step) => +step)
        .sort((a, b) => a - b)
    // For volumes above 100M, show steps of 100M and above. For volumes below 100M, show steps of 100M and below.
    // This is to make the pricing bar more readable.
    let filteredVolumeSteps = allVolumeSteps.filter((step) =>
        new Big(totalPortfolioValue).gt(100e6) ? step >= 100 : step <= 100
    )
    if (filteredVolumeSteps.length === 0) {
        filteredVolumeSteps = allVolumeSteps
    }

    let highestVolume = new Big(filteredVolumeSteps.at(-1) ?? 0).mul(1e6).toNumber()
    if (highestVolume < 1) {
        highestVolume = 1
    }

    const stepCount = filteredVolumeSteps.length
    const volumePercentage = new Big(Math.min(totalPortfolioValue, highestVolume))
        .div(highestVolume)
        .mul(100)
        .toNumber()

    const oldStepIndex = filteredVolumeSteps.reduce(
        (acc, volume, index) =>
            new Big(feeModel.marketValueVirtualValuta).gte(new Big(volume).mul(1e6)) ? index : acc,
        0
    )
    const currentStepIndex = filteredVolumeSteps.reduce(
        (acc, volume, index) => (new Big(totalPortfolioValue).gte(new Big(volume).mul(1e6)) ? index : acc),
        0
    )
    const oldStepEntryVolume = filteredVolumeSteps[oldStepIndex]
    const currentStepEntryVolume = filteredVolumeSteps[currentStepIndex]
    const platformFeeCategories = Object.keys(feeModel.platformFeeSteps[0])

    const equivalentPlatformFeeStepsForBothCategories = allVolumeSteps.every(
        (step) => feeModel.platformFeeSteps[step]['MONEY_MARKET_FUND'] === feeModel.platformFeeSteps[step]['BOND_FUND']
    )
    const oldPlatformFeeMoneyMarketFund = feeModel.platformFeeSteps[oldStepEntryVolume]['MONEY_MARKET_FUND']
    const platformFeeMoneyMarketFund = feeModel.platformFeeSteps[currentStepEntryVolume]['MONEY_MARKET_FUND']
    const oldPlatformFeeBondFund = feeModel.platformFeeSteps[oldStepEntryVolume]['BOND_FUND']
    const platformFeeBondFund = feeModel.platformFeeSteps[currentStepEntryVolume]['BOND_FUND']
    const higherPlatformFeeMoneyMarketFund = new Big(platformFeeMoneyMarketFund).gt(oldPlatformFeeMoneyMarketFund)
    const higherPlatformFeeBondFund = new Big(platformFeeBondFund).gt(oldPlatformFeeBondFund)
    const higherPlatformFee = higherPlatformFeeMoneyMarketFund || higherPlatformFeeBondFund

    function format3Decimals(value: number) {
        return InterestOutput.formatWithDecimals(value, 3)
    }

    function higherPlatformFeeWarning() {
        return (
            <Alert severity={'info'}>
                <Stack spacing={1}>
                    <Typography fontSize={'1.4rem'} fontWeight={'bold'}>
                        {t('components.CurrencyPricingBar.sell.higherPlatformFeeWarning')}
                    </Typography>
                    {equivalentPlatformFeeStepsForBothCategories && (
                        <Typography fontSize={'1.4rem'}>
                            {t('components.CurrencyPricingBar.sell.platformFeeIncrease', {
                                oldPlatformFee: format3Decimals(oldPlatformFeeMoneyMarketFund),
                                newPlatformFee: format3Decimals(platformFeeMoneyMarketFund),
                            })}
                        </Typography>
                    )}
                    {!equivalentPlatformFeeStepsForBothCategories && higherPlatformFeeMoneyMarketFund && (
                        <Typography fontSize={'1.4rem'}>
                            {t('components.CurrencyPricingBar.sell.platformFeeIncreaseMoneyMarketFunds', {
                                oldPlatformFee: format3Decimals(oldPlatformFeeMoneyMarketFund),
                                newPlatformFee: format3Decimals(platformFeeMoneyMarketFund),
                            })}
                        </Typography>
                    )}
                    {!equivalentPlatformFeeStepsForBothCategories && higherPlatformFeeBondFund && (
                        <Typography fontSize={'1.4rem'}>
                            {t('components.CurrencyPricingBar.sell.platformFeeIncreaseBondFunds', {
                                oldPlatformFee: format3Decimals(oldPlatformFeeBondFund),
                                newPlatformFee: format3Decimals(platformFeeBondFund),
                            })}
                        </Typography>
                    )}
                </Stack>
            </Alert>
        )
    }

    return (
        <Stack spacing={1}>
            {orderType === 'SELL' && higherPlatformFee && higherPlatformFeeWarning()}
            <Paper sx={{ px: 4, py: 2 }}>
                <Stack
                    direction={{ md: 'row' }}
                    gap={{ xs: 2, md: 4 }}
                    textAlign="left"
                    alignItems={{ md: 'center' }}
                    justifyContent={'space-between'}
                >
                    <Stack>
                        <Stack direction="row" spacing={2}>
                            {currencies.map((currency, index) => (
                                <Stack key={currency} direction="row" alignItems="center" spacing={1}>
                                    <Box
                                        width="1rem"
                                        height="1rem"
                                        bgcolor={barChartColors[index]}
                                        borderRadius="10rem"
                                    />
                                    <Typography fontSize={'1.4rem'} fontWeight={'bold'}>
                                        {CurrencyOutputObject(newMarketValue[currency] ?? 0, {
                                            millionFormat: true,
                                            currency: currency,
                                            withCurrency: false,
                                        }) +
                                            ' M' +
                                            currency}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                        <div className={classNames(styles.fundPricingChart, condensed && styles.condensed)}>
                            <ul className={styles.priceSteps}>
                                {filteredVolumeSteps.map((entryVolume, index) => {
                                    const volume: number = +entryVolume * 1e6
                                    return (
                                        <li
                                            key={entryVolume}
                                            style={{
                                                width: `${(volume / highestVolume) * 100}%`,
                                                zIndex: stepCount - index,
                                            }}
                                        >
                                            <div className={styles.bar} />
                                            <span
                                                className={classNames(styles.platformLabel, styles.centerAlign)}
                                                style={{
                                                    fontWeight: currentStepIndex === index ? 'bold' : 'normal',
                                                    color:
                                                        currentStepIndex === index
                                                            ? FOREST_GREEN[500]
                                                            : SILVER_GRAY[500],
                                                }}
                                            >
                                                <Tooltip
                                                    title={platformFeeCategories.map((category: FundCategory) => {
                                                        const value = feeModel.platformFeeSteps[entryVolume][category]
                                                        return (
                                                            <Typography fontSize={'1.4rem'} key={category}>
                                                                {t(`enum-FundCategory.${category}`)}
                                                                <span>: </span>
                                                                {value ? (
                                                                    InterestOutput.formatWithDecimals(value, 3)
                                                                ) : (
                                                                    <i className="ri-arrow-up-line" />
                                                                )}
                                                            </Typography>
                                                        )
                                                    })}
                                                >
                                                    <span className={styles.volumeLabel}>
                                                        {CurrencyOutput.formatMillion(volume)}
                                                    </span>
                                                </Tooltip>
                                            </span>
                                        </li>
                                    )
                                })}
                                <li className={styles.volume} style={{ width: volumePercentage + '%' }}>
                                    <Stack direction="row" width={'100%'}>
                                        {currencies.map((currency, index) => (
                                            <Box
                                                key={currency}
                                                sx={{
                                                    backgroundColor: barChartColors[index],
                                                    width:
                                                        (convertToVirtual(currency, newMarketValue[currency]) /
                                                            totalPortfolioValue) *
                                                            100 +
                                                        '%',
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </li>
                            </ul>
                        </div>
                    </Stack>
                    <Stack spacing={0.5}>
                        <Stack direction={'row'} alignItems="center">
                            <Typography fontSize={'1.6rem'} fontWeight={'bold'}>
                                {t('components.CurrencyPricingBar.platformFee')}
                            </Typography>
                            {onClick && (
                                <IconButton color="primary" onClick={onClick}>
                                    <i className="ri-question-line" />
                                </IconButton>
                            )}
                        </Stack>
                        {!equivalentPlatformFeeStepsForBothCategories &&
                            Object.keys(feeModel.platformFeeSteps[0]).map((category: FundCategory) => {
                                const value = feeModel.platformFeeSteps[currentStepEntryVolume][category]
                                return (
                                    <Typography fontSize={'1.4rem'} key={category}>
                                        {t(`enum-FundCategory.${category}`)}
                                        <span>: </span>
                                        {value ? format3Decimals(value) : <i className="ri-arrow-up-line" />}
                                    </Typography>
                                )
                            })}
                        {equivalentPlatformFeeStepsForBothCategories && (
                            <Typography fontSize={'1.4rem'}>
                                {t('components.CurrencyPricingBar.fee')}
                                <span>: </span>
                                {platformFeeMoneyMarketFund ? (
                                    format3Decimals(platformFeeMoneyMarketFund)
                                ) : (
                                    <i className="ri-arrow-up-line" />
                                )}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </Paper>
        </Stack>
    )
}
