import { useSelector } from '#app/state/useSelector'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import styles from './SegmentChart.module.scss'
import { Chart } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { BankLimitedDto, DepositDto, FundDto, FundPlacementDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { InterestOutput } from '#app/components'
import { COLORS, SINGLE_CATEGORY_COLORS } from '#app/colors/colors'
import getDepositValue from '#services/getDepositValue'

interface Allocation {
    id: string
    name: string
    amount: number
    type: 'bank' | 'fund'
}

type Props = {
    deposits: DepositDto[]
}

export default function SegmentChart({ deposits }: Props) {
    const { t } = useTranslation()

    const chartRef = useRef<Chart<'doughnut', number[]> | null>(null)

    const [showAllHoldings, setShowAllHoldings] = useState(false)

    const banks = useSelector((state) => state.banks)
    const funds = useSelector((state) => state.funds)

    console.log('SegmentChart render')

    // Calculates all the data needed for the chart in a useMemo
    // to avoid recalculating the data on every render
    // and to avoid re-running the useEffect on every render
    const { allocations, labels, dataSets, portfolioSum, fundPlacements, hasFundsInPortfolio } = useMemo(() => {
        console.log('Calculating chart data')
        return calculateAllChartData(deposits, banks, funds, t)
    }, [banks, deposits, funds, t])

    useEffect(() => {
        console.log('Updating chart')
        if (chartRef.current) {
            chartRef.current.data = {
                labels: labels,
                datasets: dataSets,
            }
            chartRef.current.update()
        }
    }, [dataSets, labels, deposits.length, fundPlacements.length, hasFundsInPortfolio])

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: dataSets,
                },
                options: {
                    color: '#666',
                    maintainAspectRatio: true,
                    cutout: '60%',
                    plugins: {
                        legend: {
                            display: false,
                            labels: {
                                generateLabels: function (chart) {
                                    // Get the default label list
                                    const original = Chart.overrides.doughnut.plugins.legend.labels.generateLabels
                                    const labelsOriginal = original.call(this, chart)

                                    // Build an array of colors used in the datasets of the chart
                                    const datasetColors = chart.data.datasets.map((e) => e.backgroundColor).flat()

                                    labelsOriginal.forEach((label) => {
                                        label.datasetIndex = (label.index - (label.index % 2)) / 2

                                        // The hidden state must match the dataset's hidden state
                                        label.hidden = !chart.isDatasetVisible(label.datasetIndex)

                                        // Change the color to match the dataset
                                        label.fillStyle = datasetColors[label.index]
                                    })

                                    return labelsOriginal
                                },
                            },
                        },
                        tooltip: {
                            displayColors: false,
                            callbacks: {
                                label: function (context) {
                                    const labelIndex = context.datasetIndex * 2 + context.dataIndex
                                    return context.chart.data.labels[labelIndex] + ': ' + context.formattedValue
                                },
                            },
                        },
                    },
                },
            })
        }
    }

    return (
        <div className={styles.chartContainer}>
            <div className={styles.segmentChartWrapper}>
                <canvas className={styles.segmentChart} ref={canvasCallback} />
            </div>
            <div className={styles.chartInfoWrapper}>
                <ul className={styles.segmentLabels}>
                    {hasFundsInPortfolio && (
                        <Fragment>
                            <li>
                                <span className={styles.segmentColor} style={{ backgroundColor: COLORS.DEPOSIT }} />
                                <span>{t('pages-portfolio-depositor.fixrateDeposits')}</span>
                            </li>
                            <li>
                                <span className={styles.segmentColor} style={{ backgroundColor: COLORS.FUND }} />
                                <span>{t('pages-portfolio-depositor.funds')}</span>
                            </li>
                        </Fragment>
                    )}
                </ul>
                <p className={styles.positionsTitle}>{t('pages-portfolio-depositor.topHoldings')}</p>
                <ul className={styles.allocationList}>
                    {allocations.slice(0, showAllHoldings ? allocations.length : 5).map((allocation, index) => (
                        <li key={allocation.id}>
                            <span className={styles.name}>
                                <span
                                    className={styles.circle}
                                    style={{
                                        backgroundColor: hasFundsInPortfolio
                                            ? allocation.type === 'bank'
                                                ? COLORS.DEPOSIT
                                                : COLORS.FUND
                                            : SINGLE_CATEGORY_COLORS[index],
                                    }}
                                />
                                <span>{allocation.name}</span>
                            </span>
                            <span className={styles.value}>
                                {InterestOutput.format((allocation.amount / portfolioSum) * 100)}
                            </span>
                        </li>
                    ))}
                </ul>
                {allocations.length > 5 && (
                    <a className={styles.link} onClick={() => setShowAllHoldings(!showAllHoldings)}>
                        <span>
                            {showAllHoldings
                                ? t('pages-portfolio-depositor.showLess')
                                : t('pages-portfolio-depositor.showMore')}
                        </span>
                    </a>
                )}
            </div>
        </div>
    )
}

/**
 * Calculates all the data needed for the chart
 */
function calculateAllChartData(
    deposits: DepositDto[],
    banks: { [p: string]: BankLimitedDto },
    funds: FundDto[],
    t: (key: string) => string
) {
    const hasFundsInPortfolio = false
    const fundPlacements = [] as FundPlacementDto[]

    const depositsByBank = deposits.reduce(
        (acc, deposit) => {
            if (!acc[deposit.bankId]) {
                acc[deposit.bankId] = []
            }
            acc[deposit.bankId].push(deposit)
            return acc
        },
        {} as { [bankId: string]: DepositDto[] }
    )

    const fundPlacementsByFund = fundPlacements.reduce(
        (acc, fundPlacement) => {
            if (!acc[fundPlacement.fundId]) {
                acc[fundPlacement.fundId] = []
            }
            acc[fundPlacement.fundId].push(fundPlacement)
            return acc
        },
        {} as { [fundId: string]: FundPlacementDto[] }
    )

    const allocations: Allocation[] = [
        ...Object.entries(depositsByBank).map(([bankId, deposits]) =>
            depositsToAllocationMapper(bankId, deposits, banks)
        ),
        ...Object.entries(fundPlacementsByFund).map(([fundId, fundPlacements]) =>
            fundPlacementToAllocationMapper(fundId, fundPlacements, funds)
        ),
    ].sort((a, b) => b.amount - a.amount)

    // Labels
    const allocationLabels = [
        ...allocations.filter((a) => a.type === 'bank').map((a) => a.name),
        ...allocations.filter((a) => a.type === 'fund').map((a) => a.name),
    ]
    const labels = hasFundsInPortfolio
        ? [t('pages-portfolio-depositor.fixrateDeposits'), t('pages-portfolio-depositor.funds'), ...allocationLabels]
        : allocationLabels

    //Value of segments
    const fundSum = allocations.filter((a) => a.type === 'fund').reduce((sum, a) => sum + a.amount, 0)
    const depositSum = allocations.filter((a) => a.type === 'bank').reduce((sum, a) => sum + a.amount, 0)
    const portfolioSum = allocations.reduce((sum, a) => sum + a.amount, 0)

    //Segment (outer ring) labels and colors
    const segmentData = {
        label: 'Segment',
        backgroundColor: [COLORS.DEPOSIT, COLORS.FUND],
        hoverBackgroundColor: [COLORS.DEPOSIT_SELECTED, COLORS.FUND_SELECTED],
        borderColor: COLORS.MID,
        data: [+((depositSum / portfolioSum) * 100).toFixed(2), +((fundSum / portfolioSum) * 100).toFixed(2)],
    }
    const multiCategoryColors = [
        ...Object.keys(depositsByBank).map((_) => COLORS.DEPOSIT_LIGHT),
        ...Object.keys(fundPlacementsByFund).map((_) => COLORS.FUND_LIGHT),
    ]

    //Asset data in chart (inner ring)
    const assetData = {
        label: 'Aktiva',
        backgroundColor: hasFundsInPortfolio ? multiCategoryColors : SINGLE_CATEGORY_COLORS,
        hoverBackgroundColor: hasFundsInPortfolio
            ? [
                  ...Object.keys(depositsByBank).map((_) => COLORS.DEPOSIT_SELECTED),
                  ...Object.keys(fundPlacementsByFund).map((_) => COLORS.FUND_SELECTED),
              ]
            : SINGLE_CATEGORY_COLORS.map((color) => color + '95'),
        borderWidth: 0,
        data: [
            ...allocations.filter((a) => a.type === 'bank').map((a) => a.amount),
            ...allocations.filter((a) => a.type === 'fund').map((a) => a.amount),
        ],
    }

    //Datasets being passed into chart
    const dataSets = hasFundsInPortfolio ? [segmentData, assetData] : [assetData]

    return {
        allocations,
        labels,
        dataSets,
        portfolioSum,
        fundPlacements,
        hasFundsInPortfolio,
    }
}

function fundPlacementToAllocationMapper(
    fundId: string,
    fundPlacements: FundPlacementDto[],
    funds: FundDto[]
): Allocation {
    return {
        id: fundId,
        name: funds.find((f) => f.id === fundId)?.name || '',
        amount: fundPlacements.reduce((sum, placement) => sum + placement.currentValue, 0),
        type: 'fund',
    }
}

function depositsToAllocationMapper(
    bankId: string,
    deposits: DepositDto[],
    banks: { [bankId: string]: BankLimitedDto }
): Allocation {
    return {
        id: bankId,
        name: banks[bankId]?.name,
        amount: deposits.reduce((acc, deposit) => acc + getDepositValue(deposit), 0),
        type: 'bank',
    }
}
