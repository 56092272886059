import { copyTextToClipboard } from '#app/components/ClipboardCopyButton/ClipboardCopyButton'
import { Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function CopyLink({
    link,
    buttonText,
    id,
    dataCy,
}: Readonly<{ link: string; buttonText: string; id?: string; dataCy?: string }>) {
    const { t } = useTranslation()
    const [linkCopied, setLinkCopied] = useState(false)
    const copyLink = (link: string) => {
        copyTextToClipboard(link)
            .then(() => {
                setLinkCopied(true)
                setTimeout(() => setLinkCopied(false), 3000)
            })
            .catch(console.error)
    }
    return (
        <Button
            id={id}
            data-cy={dataCy}
            size="small"
            variant="outlined"
            startIcon={<i className={linkCopied ? 'ri-check-line' : 'ri-link'} />}
            onClick={() => copyLink(link)}
        >
            {linkCopied ? t('common.copied') + '!' : buttonText}
        </Button>
    )
}
