import { BURNT_ORANGE, PURPLE, SPRING_GREEN } from '#app/colors/colors'
import { Animated, InterestOutput } from '#app/components'
import AsyncButton from '#app/components/Button/AsyncButton'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import NumberInput from '#app/components/NumberInput/NewNumberInput'
import config from '#app/config'
import { useCommand } from '#app/services/beta'
import { ChangeMandatePolicyType } from '#app/services/beta/command/useCommand.types'
import { expectedDurationTypeTMap } from '#app/services/enumTranslationKeyMapping'
import usePortfolio from '#app/services/usePortfolio'
import { useSelector } from '#app/state/useSelector'
import { useFieldState } from '@fixrate/fieldstate'
import { ExpectedDurationType, MandatePolicyForBankDeposits } from '@fixrate/fixrate-query'
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slider,
    Snackbar,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

const TitleElement = ({ title }: { title: string }) => (
    <Stack spacing={0.5}>
        <Typography variant="h2">{title}</Typography>
        <Divider />
    </Stack>
)

const API_BASE_URL = config().apiUrl
const getBankLogoUrl = (bankId) => `${API_BASE_URL}/api/bank/${bankId}/image/logo`

const PlacementProfile = ({
    mandate = false,
    placementDemands = false,
    directOffer = false,
    inline = true,
}: {
    mandate?: boolean
    placementDemands?: boolean
    directOffer?: boolean
    inline?: boolean
}) => {
    const { t } = useTranslation()
    const { changeMandatePolicy } = useCommand()
    const regulatoryRegion = useCurrentCountryCode()
    const Currency = useCurrencyOutput()
    const currentCurrency = useSelector((state) => state.session?.association?.currentCurrency)
    const depositor = useSelector((state) => state.depositor)
    const portfolio = usePortfolio()
    const banks = useSelector((state) => state.banks)
    const filteredBanks = Object.values(banks)
        .filter((bank) => bank.enabled)
        .filter((bank) => bank.currencies.includes(currentCurrency))
        .filter((bank) => bank.bankType)
        .filter((bank) => bank.bankGroup !== 'Fixrate')
        .filter((bank) => bank.bankType !== 'Administrator')
        .filter((bank) => bank.regulatoryRegion === regulatoryRegion)

    const products = useSelector((state) => state.products)
    const interestBenchMarks = useSelector((state) => state.interestRateBenchmarks)
    const relevantProductIds = ['1', '2', '3', '5']
    const relevantProducts = Object.keys(products)
        .filter((product) => relevantProductIds.includes(products[product]?.id))
        .reverse()
    const floatingInterestProducts = relevantProducts.filter((product) => products[product]?.type === 'FLOATING')
    const fixedInterestProducts = relevantProducts.filter((product) => products[product]?.type === 'FIXED')

    const permissions = useSelector((state) => state.session?.association?.permissions)
    const canChangeMandate = permissions?.includes('GENERAL__ORGANISATION__CONFIG')

    const debouncedMandateUpdate = useDebouncedCallback((updatedFields?: Partial<ChangeMandatePolicyType>) => {
        updateMandate(updatedFields)
    }, 1000)

    const mandatePolicies = depositor?.mandates[portfolio.id]?.policies.BANK_DEPOSITS as MandatePolicyForBankDeposits

    const availableBankTypes = filteredBanks
        ?.map((bank) => bank.bankType)
        .filter((value, index, self) => self.indexOf(value) === index)

    const bankExclusionList = useFieldState<string[]>(
        mandatePolicies?.bankRequirements?.excludedBanks || [],
        null,
        {},
        (value) => debouncedMandateUpdate({ excludedBanks: value })
    )

    const minimumBankAssets = useFieldState<number>(
        mandatePolicies?.bankRequirements?.minTotalAssets || 0,
        null,
        {},
        (value) => debouncedMandateUpdate({ bankMinTotalAssets: value })
    )

    const bankRating = useFieldState<boolean>(
        mandatePolicies?.bankRequirements?.ratingRequired || false,
        null,
        {},
        (value) => debouncedMandateUpdate({ ratingRequired: value })
    )

    const placementVolume = useFieldState<number>(mandatePolicies?.totalVolume || null, null, {}, (value) =>
        debouncedMandateUpdate({ totalVolume: value })
    )

    const minimumVolumePerBank = useFieldState<number>(mandatePolicies?.minVolume || null, null, {}, (value) =>
        debouncedMandateUpdate({ minVolume: value })
    )

    const maximumVolumePerBank = useFieldState<number>(mandatePolicies?.maxVolume || null, null, {}, (value) =>
        debouncedMandateUpdate({ maxVolume: value })
    )

    const duration = useFieldState<ExpectedDurationType | ''>(
        mandatePolicies?.expectedDuration || '',
        null,
        {},
        (value) => debouncedMandateUpdate({ expectedDuration: value })
    )

    const durationComment = useFieldState<string>(mandatePolicies?.expectedDurationComment || '', null, {}, (value) =>
        debouncedMandateUpdate({ expectedDurationComment: value })
    )

    const bankTypes = useFieldState<string[]>(mandatePolicies?.bankRequirements?.bankTypes || [], null, {}, (value) =>
        debouncedMandateUpdate({ bankTypes: value })
    )

    const minInterestRate = useFieldState<{ [key: string]: number } | null>(
        mandatePolicies?.minInterest || null,
        null,
        {},
        (value) => debouncedMandateUpdate({ minInterest: value || null })
    )

    const bankRequirementsEnabled = useFieldState<boolean>(
        mandatePolicies?.bankRequirements?.enabled || false,
        null,
        {},
        (value) => debouncedMandateUpdate({ bankRequirementsEnabled: value })
    )

    const directOfferEnabled = useFieldState<boolean>(mandatePolicies?.directOffer || false, null, {}, (value) =>
        debouncedMandateUpdate({ directOffer: value })
    )

    const [addProductDialog, setAddProductDialog] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<string>('')
    const [selectedProductInterest, setSelectedProductInterest] = useState<number>(0)
    const [missingProductData, setMissingProductData] = useState<boolean>(false)

    const [bankSelectionOpen, setBankSelectionOpen] = useState<boolean>(false)

    const [loadingMandateChange, setLoadingMandateChange] = useState<boolean | null>(null)
    const [loadingMandateFinished, setLoadingMandateFinished] = useState<boolean>(true)
    const [mandateUpdateSuccess, setMandateUpdateSuccess] = useState<boolean>(false)
    const [mandateUpdateFailed, setMandateUpdateFailed] = useState<boolean>(false)

    const bankLength = filteredBanks?.length

    const updateMinInterestRate = (productId: string, value: number) => {
        setMandateUpdateFailed(false)
        setMandateUpdateSuccess(false)
        setLoadingMandateChange(true)
        setLoadingMandateFinished(false)
        const currentMinInterest = minInterestRate.value || {}
        const updatedMinInterest = {
            ...currentMinInterest,
            [productId]: value,
        }
        minInterestRate.submitValue(updatedMinInterest)
    }

    const removeProduct = (productId: string) => {
        setMandateUpdateFailed(false)
        setMandateUpdateSuccess(false)
        setLoadingMandateFinished(false)
        setLoadingMandateChange(true)
        const updatedMinInterest = { ...minInterestRate.value }
        delete updatedMinInterest[productId]
        minInterestRate.submitValue(updatedMinInterest)
    }

    const addProduct = async () => {
        if (selectedProduct && selectedProductInterest) {
            setMissingProductData(false)
            updateMinInterestRate(selectedProduct, selectedProductInterest)
        } else {
            setMissingProductData(true)
        }
    }

    const closeProductDialog = () => {
        setSelectedProduct('')
        setSelectedProductInterest(0)
        setMissingProductData(false)
        setAddProductDialog(false)
    }

    const updateBankTypes = (bankType: string, active: boolean) => {
        if (active) {
            bankTypes.submitValue([...bankTypes.value, bankType])
        } else {
            bankTypes.submitValue(bankTypes.value.filter((b) => b !== bankType))
        }
    }

    const banksAvailable = filteredBanks?.filter((bank) => {
        if (minimumBankAssets.value && bank.totalAssets < minimumBankAssets.value) {
            return false
        }

        if (bankRating.value && !!bank.officialRating === false) {
            return false
        }

        if (bankTypes.value.length > 0 && bankTypes.value.includes(bank.bankType) === false) {
            return false
        }

        return true
    })

    const totalAssetsSliderText =
        minimumBankAssets.value > 0
            ? t('pages-marketplace.atLeastMillions', {
                  amount: Currency(minimumBankAssets.value, { decimals: 0, withCurrency: false }),
              })
            : t('pages-marketplace.allBanks')

    const handleBankExclusionChange = (bankId: string, active: boolean) => {
        if (active) {
            bankExclusionList.submitValue([...bankExclusionList.value, bankId])
        } else {
            bankExclusionList.submitValue(bankExclusionList.value.filter((b) => b !== bankId))
        }
    }

    const updateMandate = async (updatedFields?: Partial<ChangeMandatePolicyType>) => {
        setLoadingMandateFinished(false)
        setLoadingMandateChange(true)
        setMandateUpdateSuccess(false)
        setMandateUpdateFailed(false)

        const { waitForCommand } = await changeMandatePolicy({
            depositorId: depositor?.id,
            portfolioId: portfolio?.id,
            investmentCategory: 'BANK_DEPOSITS',
            enabled: true,
            bankMinTotalAssets: minimumBankAssets.value,
            ratingRequired: bankRating.value,
            bankTypes: bankTypes.value,
            excludedBanks: bankExclusionList.value,
            totalVolume: placementVolume.value,
            minVolume: minimumVolumePerBank.value,
            maxVolume: maximumVolumePerBank.value,
            expectedDuration: duration.value ? duration.value : null,
            expectedDurationComment: durationComment.value,
            bankRequirementsEnabled: bankRequirementsEnabled.value,
            minInterest: minInterestRate.value,
            directOffer: directOfferEnabled.value,
            ...updatedFields,
        })

        const success = await waitForCommand()

        setLoadingMandateChange(false)

        if (success) {
            setTimeout(() => {
                setMandateUpdateSuccess(true)
                setSelectedProduct('')
                setSelectedProductInterest(0)
            }, 500)
        } else {
            setTimeout(() => {
                setMandateUpdateFailed(true)
            }, 500)
        }

        setAddProductDialog(false)

        setTimeout(() => {
            setLoadingMandateFinished(true)
        }, 3000)
    }

    return (
        <Box maxWidth={900}>
            <Stack spacing={5} my={1}>
                {!canChangeMandate && (
                    <Alert severity="warning" icon={null}>
                        {t('block-Mandate.canNotChangeMandate')}
                    </Alert>
                )}
                {mandate && (
                    <Stack spacing={3}>
                        <Stack>
                            <InputLabel>
                                <Stack direction="row" spacing={0.7}>
                                    <i className="ri-bank-line" style={{ fontWeight: 'normal' }} />
                                    <span>{t('pages-mandateOptions.relevantBanks')}</span>
                                </Stack>
                                <Typography variant="labelDescription">
                                    {t('pages-mandateOptions.relevantBanksDescription')}
                                </Typography>
                            </InputLabel>
                            <Stack direction={'row'} spacing={2} alignItems="center" flexWrap={'wrap'}>
                                <Select
                                    disabled={!canChangeMandate}
                                    value={bankRequirementsEnabled.value ? 'filter' : 'all'}
                                    onChange={(event) =>
                                        bankRequirementsEnabled.submitValue(
                                            event.target.value === 'filter' ? true : false
                                        )
                                    }
                                >
                                    <MenuItem value="all">{t('pages-mandateOptions.allBanks')}</MenuItem>
                                    <MenuItem value="filter">{t('pages-mandateOptions.userDefined')}</MenuItem>
                                </Select>
                                {bankRequirementsEnabled.value === true && (
                                    <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                        <Chip
                                            onClick={() => setBankSelectionOpen(true)}
                                            color="primary"
                                            variant="outlined"
                                            label={t('pages-mandateOptions.selectBanks')}
                                        />
                                        <Typography variant="caption">
                                            {t('pages-mandateOptions.numberOfBanksSelected', {
                                                selectedBanks: banksAvailable?.filter(
                                                    (b) => bankExclusionList.value.includes(b.id) === false
                                                ).length,
                                                totalBanks: bankLength,
                                            })}
                                        </Typography>
                                    </Stack>
                                )}
                            </Stack>
                            {bankRequirementsEnabled.value === true && (
                                <Animated>
                                    <Grid container spacing={4} mt={1}>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>{t('pages-mandateOptions.minimumAssets')}</InputLabel>
                                            <Slider
                                                id="minBankAssetsRange"
                                                disabled={!canChangeMandate}
                                                min={0}
                                                max={20_000}
                                                step={500}
                                                value={minimumBankAssets.value}
                                                onChange={(_, value) => minimumBankAssets.submitValue(value as number)}
                                            />
                                            <Typography variant="caption">{totalAssetsSliderText}</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>{t('pages-mandateOptions.officialRating')}</InputLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                defaultValue="no"
                                                name="rating"
                                                value={bankRating.value}
                                                sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}
                                                onChange={(e, val) =>
                                                    bankRating.submitValue(val === 'true' ? true : false)
                                                }
                                            >
                                                <FormControlLabel
                                                    disabled={!canChangeMandate}
                                                    value={false}
                                                    control={<Radio />}
                                                    label={t('pages-mandateOptions.noRatingNeeded')}
                                                />
                                                <FormControlLabel
                                                    disabled={!canChangeMandate}
                                                    value={true}
                                                    control={<Radio />}
                                                    label={t('pages-mandateOptions.ratingRequired')}
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <InputLabel>{t('pages-mandateOptions.bankType')}</InputLabel>
                                            <FormGroup sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' } }}>
                                                {availableBankTypes.map((bankType) => (
                                                    <FormControlLabel
                                                        disabled={!canChangeMandate}
                                                        key={bankType}
                                                        checked={bankTypes.value.includes(bankType)}
                                                        onChange={(e, val) => updateBankTypes(bankType, val)}
                                                        control={<Checkbox />}
                                                        label={bankType}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Animated>
                            )}
                            <FxDialog
                                open={bankSelectionOpen}
                                onClose={() => setBankSelectionOpen(false)}
                                maxWidth="xl"
                            >
                                <FxDialogTitle onClose={() => setBankSelectionOpen(false)}>Velg banker</FxDialogTitle>
                                <FxDialogContent>
                                    <Table size="small" sx={{ mb: 3, width: '80rem', maxWidth: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>{t('pages-mandateOptions.bank')}</TableCell>
                                                <TableCell sx={{ textAlign: 'right' }}>
                                                    {t('pages-mandateOptions.bankAssets')}
                                                </TableCell>
                                                <TableCell>{t('pages-mandateOptions.type')}</TableCell>
                                                <TableCell>{t('pages-mandateOptions.activated')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {banksAvailable.map((bank) => (
                                                <TableRow key={bank.id}>
                                                    <TableCell>
                                                        <Stack alignItems={'center'}>
                                                            <img
                                                                style={{ maxWidth: '12rem' }}
                                                                src={getBankLogoUrl(bank?.id)}
                                                                alt=""
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>{bank.name}</TableCell>
                                                    <TableCell sx={{ textAlign: 'right' }}>
                                                        {Currency(bank.totalAssets, {
                                                            decimals: 0,
                                                            withCurrency: false,
                                                        })}{' '}
                                                        Mill.
                                                    </TableCell>
                                                    <TableCell>{bank.bankType}</TableCell>
                                                    <TableCell>
                                                        <Switch
                                                            checked={!bankExclusionList.value.includes(bank?.id)}
                                                            onChange={(e, checked) =>
                                                                handleBankExclusionChange(
                                                                    bank?.id,
                                                                    checked ? false : true
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </FxDialogContent>
                            </FxDialog>
                        </Stack>
                    </Stack>
                )}
                {placementDemands && products && (
                    <Stack spacing={3}>
                        {!inline && <TitleElement title={t('pages-mandateOptions.placementVolume')} />}
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <InputLabel>
                                    <Stack direction="row" spacing={0.7}>
                                        <i className="ri-coin-line" style={{ fontWeight: 'normal' }} />
                                        <span>{t('pages-mandateOptions.totalVolume')}</span>
                                    </Stack>
                                    <Typography variant="labelDescription">
                                        {t('pages-mandateOptions.totalVolumeDescription')}
                                    </Typography>
                                </InputLabel>
                                <NumberInput
                                    disabled={!canChangeMandate}
                                    sx={{ maxWidth: '14rem', backgroundColor: 'white' }}
                                    value={placementVolume.value}
                                    onChange={(number) => placementVolume.submitValue(number)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>
                                    <Stack direction="row" spacing={0.7}>
                                        <span>{t('pages-mandateOptions.minAmountPerBank')}</span>
                                    </Stack>
                                </InputLabel>
                                <Stack>
                                    <NumberInput
                                        disabled={!canChangeMandate}
                                        sx={{ maxWidth: '14rem', backgroundColor: 'white' }}
                                        value={minimumVolumePerBank.value}
                                        onChange={(number) => minimumVolumePerBank.submitValue(number)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                                        }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>
                                    <Stack direction="row" spacing={0.7}>
                                        <span>{t('pages-mandateOptions.maxAmountPerBank')}</span>
                                    </Stack>
                                </InputLabel>
                                <Stack>
                                    <NumberInput
                                        disabled={!canChangeMandate}
                                        sx={{ maxWidth: '14rem', backgroundColor: 'white' }}
                                        value={maximumVolumePerBank.value}
                                        onChange={(number) => maximumVolumePerBank.submitValue(number)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mill.</InputAdornment>,
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                {placementDemands && products && (
                    <Stack spacing={3}>
                        {!inline && <TitleElement title={t('pages-mandateOptions.interestAndDuration')} />}
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <InputLabel>
                                    <Stack direction="row" spacing={0.7}>
                                        <i className="ri-list-check-2" style={{ fontWeight: 'normal' }} />
                                        <span>{t('pages-mandateOptions.selectInterestProducts')}</span>
                                    </Stack>
                                    <Typography variant="labelDescription">
                                        {t('pages-mandateOptions.interestProductsDescription')}
                                    </Typography>
                                </InputLabel>

                                {Object.keys(minInterestRate.value)?.length > 0 && (
                                    <Table sx={{ mt: 2 }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('pages-mandateOptions.productName')}</TableCell>
                                                <TableCell>{t('pages-mandateOptions.interestRequirements')}</TableCell>
                                                <TableCell>{t('common.effectiveInterest')}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(minInterestRate.value)?.map((product) => (
                                                <TableRow key={product}>
                                                    <TableCell>
                                                        <Typography fontWeight={600} fontSize={'1.4rem'}>
                                                            {products[product]?.type === 'FIXED'
                                                                ? t('common.fixedInterest')
                                                                : t('common.floatingInterest')}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {products[product]?.type === 'FLOATING'
                                                                ? t(
                                                                      'common.product-group.productId' +
                                                                          products[product]?.id
                                                                  )
                                                                : t('common.productLongName' + products[product]?.id)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Stack direction="row" alignItems={'stretch'}>
                                                            {products[product]?.type === 'FLOATING' && (
                                                                <Stack
                                                                    alignItems={'center'}
                                                                    justifyContent="center"
                                                                    sx={{
                                                                        border: '0.1rem solid' + PURPLE[500],
                                                                        backgroundColor: PURPLE[50],
                                                                        borderBottomLeftRadius: '0.5rem',
                                                                        borderTopLeftRadius: '0.5rem',
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            px: 1.5,
                                                                            fontWeight: 600,
                                                                            fontSize: '1.4rem',
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            'common.interestRateBenchmark.' +
                                                                                interestBenchMarks.defaultInterestRateBenchmark
                                                                        )}{' '}
                                                                        +
                                                                    </Typography>
                                                                </Stack>
                                                            )}
                                                            <NumberInput
                                                                disabled={!canChangeMandate}
                                                                sx={{
                                                                    maxWidth: '10rem',
                                                                    backgroundColor: 'white',
                                                                    ml: -0.1,
                                                                }}
                                                                value={
                                                                    minInterestRate.value
                                                                        ? minInterestRate?.value[products[product]?.id]
                                                                        : 0
                                                                }
                                                                onChange={(number) =>
                                                                    updateMinInterestRate(products[product]?.id, number)
                                                                }
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            %
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>
                                                        {products[product]?.type === 'FLOATING' &&
                                                        minInterestRate?.value[products[product]?.id]
                                                            ? InterestOutput.format(
                                                                  interestBenchMarks.benchmarkInterestRates[
                                                                      interestBenchMarks.defaultInterestRateBenchmark
                                                                  ] + minInterestRate.value[products[product]?.id]
                                                              )
                                                            : InterestOutput.format(
                                                                  minInterestRate.value[products[product]?.id]
                                                              )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            startIcon={<i className="ri-delete-bin-line" />}
                                                            size="small"
                                                            color="error"
                                                            disabled={!canChangeMandate}
                                                            onClick={() => removeProduct(product)}
                                                        >
                                                            {t('common.remove')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}
                                {Object.keys(minInterestRate.value)?.length < relevantProducts.length && (
                                    <AsyncButton
                                        startIcon={<i className="ri-add-line" />}
                                        loading={loadingMandateChange}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        disabled={!canChangeMandate || loadingMandateChange}
                                        variant="outlined"
                                        onClick={() => setAddProductDialog(true)}
                                    >
                                        {Object.keys(minInterestRate.value)?.length > 0
                                            ? t('pages-mandateOptions.addMoreProducts')
                                            : t('pages-mandateOptions.addProduct')}
                                    </AsyncButton>
                                )}
                                <FxDialog open={addProductDialog} onClose={closeProductDialog}>
                                    <FxDialogTitle onClose={closeProductDialog}>
                                        {t('pages-mandateOptions.addInterestProduct')}
                                    </FxDialogTitle>
                                    <FxDialogContent sx={{ my: 3, position: 'relative' }}>
                                        {!loadingMandateFinished && (
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    left: 0,
                                                    top: 0,
                                                    zIndex: 2,
                                                    backgroundColor: 'white',
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <CircularProgress />
                                            </Stack>
                                        )}
                                        <Stack spacing={2}>
                                            <Box>
                                                <InputLabel>{t('pages-mandateOptions.selectProduct')}</InputLabel>
                                                <Select
                                                    onChange={(e) => setSelectedProduct(e.target.value as string)}
                                                    value={selectedProduct}
                                                >
                                                    {floatingInterestProducts
                                                        .filter((product) => !minInterestRate.value[product])
                                                        .map((product) => (
                                                            <MenuItem
                                                                value={products[product]?.id}
                                                                key={products[product]?.id}
                                                            >
                                                                <Stack>
                                                                    <Typography fontWeight={700} fontSize="1.4rem">
                                                                        {t('common.floatingInterest')} (
                                                                        {t(
                                                                            'common.interestRateBenchmark.' +
                                                                                interestBenchMarks.defaultInterestRateBenchmark
                                                                        )}
                                                                        )
                                                                    </Typography>
                                                                    {t(
                                                                        'common.product-group.productId' +
                                                                            products[product]?.id
                                                                    )}
                                                                </Stack>
                                                            </MenuItem>
                                                        ))}
                                                    {fixedInterestProducts.filter(
                                                        (product) => !minInterestRate.value[product]
                                                    ).length > 0 && <Divider />}
                                                    {fixedInterestProducts
                                                        .filter((product) => !minInterestRate.value[product])
                                                        .map((product) => (
                                                            <MenuItem
                                                                value={products[product]?.id}
                                                                key={products[product]?.id}
                                                            >
                                                                <Stack>
                                                                    <Typography fontWeight={700} fontSize="1.4rem">
                                                                        {t('common.fixedInterest')}
                                                                    </Typography>
                                                                    {t(
                                                                        'common.product-group.productId' +
                                                                            products[product]?.id
                                                                    )}
                                                                </Stack>
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </Box>
                                            <Box>
                                                <InputLabel>
                                                    {t('pages-mandateOptions.interestRequirements')}
                                                </InputLabel>
                                                <Stack direction="row" alignItems={'stretch'}>
                                                    {products[selectedProduct]?.type === 'FLOATING' && (
                                                        <Stack
                                                            alignItems={'center'}
                                                            justifyContent="center"
                                                            sx={{
                                                                border: '0.1rem solid' + PURPLE[500],
                                                                backgroundColor: PURPLE[50],
                                                                borderBottomLeftRadius: '0.5rem',
                                                                borderTopLeftRadius: '0.5rem',
                                                                zIndex: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    px: 1.5,
                                                                    fontWeight: 600,
                                                                    fontSize: '1.4rem',
                                                                }}
                                                            >
                                                                {t(
                                                                    'common.interestRateBenchmark.' +
                                                                        interestBenchMarks.defaultInterestRateBenchmark
                                                                )}{' '}
                                                                +
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                    <NumberInput
                                                        sx={{ maxWidth: '11rem', backgroundColor: 'white', ml: -0.1 }}
                                                        value={selectedProductInterest}
                                                        onChange={(number) => {
                                                            setSelectedProductInterest(number)
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Stack>
                                                {products[selectedProduct]?.type === 'FLOATING' && (
                                                    <Typography mt={1} component="p" variant="caption">
                                                        {t('common.effectiveInterest')}:{' '}
                                                        <strong>
                                                            {InterestOutput.format(
                                                                interestBenchMarks.benchmarkInterestRates[
                                                                    interestBenchMarks.defaultInterestRateBenchmark
                                                                ] + selectedProductInterest
                                                            )}
                                                        </strong>
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Stack>
                                        {missingProductData && (!selectedProductInterest || !selectedProduct) && (
                                            <Alert sx={{ mt: 2, mb: -2, maxWidth: '30rem' }} severity="error">
                                                {t('pages-mandateOptions.missingProductData')}
                                            </Alert>
                                        )}
                                    </FxDialogContent>
                                    <FxDialogActions>
                                        <Button variant="outlined" onClick={closeProductDialog}>
                                            {t('common.cancel')}
                                        </Button>
                                        <AsyncButton
                                            loading={loadingMandateChange}
                                            variant="contained"
                                            onClick={addProduct}
                                        >
                                            {t('common.add')}
                                        </AsyncButton>
                                    </FxDialogActions>
                                </FxDialog>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>
                                    <Stack direction="row" spacing={0.7}>
                                        <i className="ri-time-line" style={{ fontWeight: 'normal' }} />
                                        <span>{t('pages-mandateOptions.duration')}</span>
                                    </Stack>
                                    <Typography variant="labelDescription">
                                        {t('pages-mandateOptions.durationDescription')}
                                    </Typography>
                                </InputLabel>
                                <Stack spacing={1}>
                                    <Select
                                        disabled={!canChangeMandate}
                                        value={duration.value}
                                        onChange={(e) =>
                                            duration.submitValue((e.target.value as ExpectedDurationType) || '')
                                        }
                                        sx={{ maxWidth: '18rem' }}
                                    >
                                        <MenuItem value={''} disabled>
                                            {t('pages-mandateOptions.selectTimePeriod')}
                                        </MenuItem>
                                        <MenuItem value="FROM_1_TO_6_MONTHS">
                                            {t(expectedDurationTypeTMap['FROM_1_TO_6_MONTHS'])}
                                        </MenuItem>
                                        <MenuItem value="FROM_6_TO_12_MONTHS">
                                            {t(expectedDurationTypeTMap['FROM_6_TO_12_MONTHS'])}
                                        </MenuItem>
                                        <MenuItem value="FROM_12_TO_24_MONTHS">
                                            {t(expectedDurationTypeTMap['FROM_12_TO_24_MONTHS'])}
                                        </MenuItem>
                                        <MenuItem value="OVER_24_MONTHS">
                                            {t(expectedDurationTypeTMap['OVER_24_MONTHS'])}
                                        </MenuItem>
                                        <MenuItem value="UNKNOWN">
                                            {t('pages-offer.wizardStep3NoticeUnknownDuration')}
                                        </MenuItem>
                                    </Select>
                                    <TextField
                                        disabled={!canChangeMandate}
                                        value={durationComment.value}
                                        onChange={(e) => durationComment.submitValue(e.target.value)}
                                        sx={{ backgroundColor: 'white' }}
                                        multiline
                                        minRows={5}
                                        fullWidth
                                        placeholder={t('pages-mandateOptions.durationComment')}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                {directOffer && (
                    <Stack spacing={3}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant="h2">{t('pages-mandateOptions.directOfferHeading')}</Typography>
                                <Typography>{t('pages-mandateOptions.directOfferDescription')}</Typography>
                                <RadioGroup
                                    defaultValue="no"
                                    name="directOffer"
                                    value={directOfferEnabled.value}
                                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '1.4rem' }, mt: 2 }}
                                    onChange={(e, val) => directOfferEnabled.submitValue(val === 'true' ? true : false)}
                                >
                                    <FormControlLabel
                                        disabled={!canChangeMandate}
                                        value={true}
                                        control={<Radio />}
                                        label={t('pages-mandateOptions.wantsDirectOfferOption')}
                                    />
                                    <FormControlLabel
                                        disabled={!canChangeMandate}
                                        value={false}
                                        control={<Radio />}
                                        label={t('pages-mandateOptions.doNotWantDirectOfferOption')}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
            </Stack>
            <Snackbar
                onClose={() => setLoadingMandateFinished(true)}
                autoHideDuration={4000}
                open={!loadingMandateFinished}
                sx={{ backgroundColor: PURPLE[900], px: 2, py: 1.5, color: PURPLE[25], borderRadius: '0.6rem' }}
            >
                <Box>
                    <Stack direction="row" spacing={1} alignItems="center" height={30} minWidth={200}>
                        {mandateUpdateSuccess && (
                            <i
                                className="ri-checkbox-circle-fill"
                                style={{ fontSize: '2rem', color: SPRING_GREEN[500] }}
                            />
                        )}
                        {mandateUpdateFailed && (
                            <i
                                className="ri-error-warning-line"
                                style={{ fontSize: '2rem', color: BURNT_ORANGE[500] }}
                            />
                        )}
                        {!mandateUpdateFailed && !mandateUpdateSuccess && (
                            <CircularProgress color="inherit" size={24} />
                        )}
                        {loadingMandateChange && (
                            <Typography fontSize={'1.4rem'} fontWeight={600}>
                                {t('pages-mandateOptions.mandateUpdating')}
                            </Typography>
                        )}
                        {!loadingMandateChange && (
                            <Typography fontSize={'1.4rem'} fontWeight={600}>
                                {mandateUpdateSuccess
                                    ? t('pages-mandateOptions.mandateUpdated')
                                    : mandateUpdateFailed
                                      ? t('pages-mandateOptions.mandateUpdateFailed')
                                      : t('pages-mandateOptions.mandateUpdating')}
                            </Typography>
                        )}
                    </Stack>
                </Box>
            </Snackbar>
        </Box>
    )
}

export { PlacementProfile }
