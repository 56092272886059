import styles from './RadioButton.module.css'
import classNames from 'classnames'

/**
 *
 * @param {object} props
 * @param props.value
 * @param {function} [props.onChange]
 * @param {boolean} [props.checked]
 * @param {string} [props.group]
 * @param [props.children]
 * @param [props.className]
 * @return {JSX.Element}
 */
export default function RadioButton({ value, onChange, checked, group, children, className }) {
    return (
        <label className={classNames(styles.label, className)}>
            <input
                className={styles.input}
                type="radio"
                name={group}
                value={value}
                checked={checked}
                onChange={() => onChange(value)}
            />
            <div className={styles.labelText}>{children}</div>
        </label>
    )
}
