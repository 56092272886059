import { fundDetailsLink } from '#app/services/fundDetailsLink'
import { Animated } from '#components'
import { FundLogo } from '#pages/FundDetails/FundLogo/FundLogo'
import FundTableHeaderCell from '#pages/FundMarketplace/FundTable/FundTableHeaderCell'
import TableShoppingCartButton from '#pages/FundMarketplace/FundTable/TableComponents/TableShoppingCartButton'
import { fundCategoryTMap, fundMorningstarCategoryTMap, fundVffCategoryTMap } from '#services/enumTranslationKeyMapping'
import useShoppingCart from '#services/useShoppingCart'
import useTableSort, { Column } from '#services/useTableSort'
import { FundDto } from '@fixrate/fixrate-query'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './FundTable.module.scss'

type Props = {
    funds: FundDto[]
    selectedShareClasses: { [fundId: string]: string }
}

type Row = FundDto & {
    logo: string
    returnRate1Y: number
}

export default function FundCategoriesTable({ funds, selectedShareClasses }: Props) {
    const { t } = useTranslation()

    const rows = funds.map((fund) => {
        const selectedShareClass =
            fund.fundShareClasses.find((fundClass) => fundClass.id === selectedShareClasses[fund.id]) ||
            fund.fundShareClasses[0]
        return {
            ...fund,
            logo: '',
            returnRate1Y: selectedShareClass.returnRates.PERIOD_1Y,
        }
    })

    const { sortedRows, sortDirection, sortKey, setSorting } = useTableSort<Row>(
        rows,
        'risk',
        'asc',
        'returnRate1Y',
        'desc'
    )

    const columns: Column<Row>[] = [
        { id: 'logo', label: '', sortable: false },
        { id: 'name', label: t('pages-fund-details.name'), sortable: true },
        { id: 'risk', label: t('pages-fund-details.risk'), sortable: true },
        { id: 'fundCategory', label: t('pages-fund-details.fundCategory'), sortable: true },
        { id: 'vffCategory', label: t('pages-fund-details.VFFCategory'), sortable: true },
        { id: 'morningstarCategory', label: t('pages-fund-details.morningstarCategory'), sortable: true },
    ]

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {columns.map((column) => (
                        <FundTableHeaderCell<Row>
                            key={column.id}
                            column={column}
                            sortKey={sortKey}
                            sortDirection={sortDirection}
                            onClick={(c) => setSorting(c.id)}
                        />
                    ))}
                    <th />
                </tr>
            </thead>
            <tbody>
                {sortedRows.map((row) => (
                    <TableRow key={row.id} row={row} />
                ))}
            </tbody>
        </table>
    )
}

function TableRow({ row }: { row: Row }) {
    const { t } = useTranslation()
    const shoppingCart = useShoppingCart('FUNDS')
    const isInShoppingCart = !!shoppingCart.getItemById(row.id)

    return (
        <Animated tag={'tr'} className={isInShoppingCart ? styles.active : null}>
            <td className={classNames(styles.logo, isInShoppingCart ? styles.highlight : null)}>
                <FundLogo fundName={row.name} squared={true} />
            </td>
            <td className={classNames(styles.name)}>
                <Link className={styles.fundLink} to={fundDetailsLink(row)}>
                    {row.name}
                </Link>
            </td>
            <td>
                <div className={classNames(styles.risk)}>
                    <div className={styles.indicator}>
                        {[...new Array(7)].map((_, i) => (
                            <span key={i} className={i < row.risk ? styles.active : undefined} />
                        ))}
                    </div>
                </div>
            </td>
            <td>{t(fundCategoryTMap[row.fundCategory])}</td>
            <td>{t(fundVffCategoryTMap[row.vffCategory])}</td>
            <td>{t(fundMorningstarCategoryTMap[row.morningstarCategory])}</td>
            <TableShoppingCartButton fund={row} />
        </Animated>
    )
}
