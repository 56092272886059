import { LoadingSpinner } from '#app/components'
import { useSelector } from '#app/state/useSelector'
import ValueReport from '../ValueReport'

const DepositorValueReport = () => {
    const depositor = useSelector((state) => state.depositor)
    return depositor ? (
        <ValueReport
            depositorId={depositor.id}
            depositorName={depositor.name}
            registrationDate={depositor.fullyRegisteredAt}
        />
    ) : (
        <LoadingSpinner />
    )
}

export { DepositorValueReport }
