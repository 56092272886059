import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDepositMarginForBank } from '#app/services/thunks/statistics'
import { useSelector } from '#state/useSelector'
import * as selectors from '#state/selectors'
import { ChartComp } from './ChartComp'
import { StaggData } from '#app/state/stagg'
import { useTranslation } from 'react-i18next'

export const BankMarginSegment = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<StaggData>>()
    const [data, setData] = useState<StaggData>([])
    const [error, setError] = useState(false)
    const bankAssociation = useSelector(selectors.bankAssociation)

    /*
        EXHAUSTIVE DEPS ANALYSIS

        Legg til dispatch

     */
    useEffect(() => {
        if (bankAssociation) {
            dispatch(getDepositMarginForBank(bankAssociation.organisation.id))
                .then(setData)
                .catch(() => setError(true))
        }
    }, [bankAssociation, dispatch])

    const readableLegend = (tags) => {
        if (tags.bankTotalAssets) {
            if (tags.bankTotalAssets === '0-5000') {
                return t('common.banksTotalAssetsBetween', { min: 0, max: 5 })
            } else if (tags.bankTotalAssets === '5000-15000') {
                return t('common.banksTotalAssetsBetween', { min: 5, max: 15 })
            } else {
                return t('common.banksTotalAssetsMoreThan', { count: 15 })
            }
        }
        return tags.bankName
    }

    return (
        <>
            {data.length !== 0 && (
                <ChartComp
                    data={data}
                    dataType={'time'}
                    smallSize={false}
                    error={error}
                    tickValue="%"
                    type="line"
                    labels={readableLegend}
                />
            )}
        </>
    )
}
