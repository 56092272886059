import { Fragment, useCallback, useEffect } from 'react'
import styles from './BankInfo.module.css'
import { useTranslation } from 'react-i18next'
import { useCommand } from '#command'
import AsyncButton from '#app/components/Button/AsyncButton'
import { FieldStateValidateFunction, useFieldState } from '@fixrate/fieldstate'
import { BankDto } from '@fixrate/fixrate-query'

interface BankInfoRegistrationFormProps {
    bank: BankDto
    selectedQuarter: string
}

export default function BankInfoRegistrationForm({ bank, selectedQuarter }: BankInfoRegistrationFormProps) {
    const { t } = useTranslation()

    const { setBankInfo } = useCommand()

    const validateMustBeInteger: FieldStateValidateFunction<string> = useCallback(
        ({ value, isEditing }) => {
            if (isEditing) return
            const rawValue = value
            if (rawValue === '') return

            if (!`${rawValue}`.match(/^\d*$/)) return t('pages-mybank.mustBeAnInteger')

            const intValue = parseInt(rawValue)
            if (intValue < 0) return t('pages-mybank.mustBeAPositiveNumber')
        },
        [t]
    )

    const validateMustBeNumber: FieldStateValidateFunction<string> = useCallback(
        ({ isEditing, value }) => {
            if (isEditing) return
            const rawValue = value
            if (rawValue === '') return

            if (!rawValue.match(/^\d*([.,]\d\d?){0,1}$/)) return t('pages-mybank.mustBeANumberWithMaxTwoDecimals')

            const floatValue = parseFloat(rawValue.replace(',', '.'))
            if (floatValue < 0) return t('pages-mybank.mustBeAPositiveNumber')
        },
        [t]
    )

    const totalAssets = useFieldState('', validateMustBeInteger)
    const totalAssetsIncludingMortgageCredit = useFieldState('', validateMustBeInteger)
    const depositCoverage = useFieldState('', validateMustBeNumber)
    const coreCapitalCoverage = useFieldState('', validateMustBeNumber)

    const anyFieldsTouched =
        totalAssets.touched ||
        totalAssetsIncludingMortgageCredit.touched ||
        depositCoverage.touched ||
        coreCapitalCoverage.touched
    const untouchAllFields = useCallback(() => {
        totalAssets.fn.untouch()
        totalAssetsIncludingMortgageCredit.fn.untouch()
        depositCoverage.fn.untouch()
        coreCapitalCoverage.fn.untouch()
    }, [totalAssets.fn, totalAssetsIncludingMortgageCredit.fn, depositCoverage.fn, coreCapitalCoverage.fn])

    useEffect(() => {
        const info = bank.bankInfoList.find((info) => info.quarter === selectedQuarter)
        totalAssets.fn.setValue(info?.totalAssets?.toString() || '')
        totalAssetsIncludingMortgageCredit.fn.setValue(info?.totalAssetsIncludingMortgageCredit?.toString() || '')
        depositCoverage.fn.setValue(info?.depositCoverage || '')
        coreCapitalCoverage.fn.setValue(info?.coreCapitalCoverage || '')
        untouchAllFields()
    }, [
        bank.bankInfoList,
        coreCapitalCoverage.fn,
        depositCoverage.fn,
        untouchAllFields,
        selectedQuarter,
        totalAssets.fn,
        totalAssetsIncludingMortgageCredit.fn,
    ])

    async function submitInfo() {
        const valid =
            totalAssets.validate() &&
            totalAssetsIncludingMortgageCredit.validate() &&
            depositCoverage.validate() &&
            coreCapitalCoverage.validate()

        if (valid) {
            const info = {
                totalAssets: totalAssets.value,
                totalAssetsIncludingMortgageCredit: totalAssetsIncludingMortgageCredit.value,
                depositCoverage: depositCoverage.value.replace(',', '.'),
                coreCapitalCoverage: coreCapitalCoverage.value.replace(',', '.'),
            }

            const { waitForCommand } = await setBankInfo(bank.id, selectedQuarter, info)
            const success = await waitForCommand()
            if (success) {
                untouchAllFields()
            }
        }
    }

    return (
        <Fragment>
            <div className={styles.inputContainer}>
                <dl className={styles.inputGroup}>
                    <dt className={styles.inputLabel}>{t('pages-mybank.totalAssets')}</dt>
                    <dd>
                        <input
                            type="text"
                            className={styles.millionInput}
                            value={totalAssets.value}
                            onChange={(event) => totalAssets.setValue(event.target.value)}
                            onBlur={totalAssets.onBlur}
                        />
                        <span>&nbsp;{t('common.million')}</span>
                        <p className="field-error-message">{totalAssets.errorMessage}</p>
                    </dd>
                </dl>
                <dl className={styles.inputGroup}>
                    <dt className={styles.inputLabel}>{t('pages-mybank.totalAssetsIncludingMortgageCredit')}</dt>
                    <dd>
                        <input
                            type="text"
                            className={styles.millionInput}
                            value={totalAssetsIncludingMortgageCredit.value}
                            onChange={(event) => totalAssetsIncludingMortgageCredit.setValue(event.target.value)}
                            onBlur={totalAssetsIncludingMortgageCredit.onBlur}
                        />
                        <span>&nbsp;{t('common.million')}</span>
                        <p className="field-error-message">{totalAssetsIncludingMortgageCredit.errorMessage}</p>
                    </dd>
                </dl>
                <dl className={styles.inputGroup}>
                    <dt className={styles.inputLabel}>{t('pages-mybank.depositCoverage')}</dt>
                    <dd>
                        <input
                            type="text"
                            className={styles.percentageInput}
                            value={depositCoverage.value}
                            onChange={(event) => depositCoverage.setValue(event.target.value)}
                            onBlur={depositCoverage.onBlur}
                        />
                        <span>&nbsp;%</span>
                        <p className="field-error-message">{depositCoverage.errorMessage}</p>
                    </dd>
                </dl>
                <dl className={styles.inputGroup}>
                    <dt className={styles.inputLabel}>{t('pages-mybank.coreCapitalCoverage')}</dt>
                    <dd>
                        <input
                            type="text"
                            className={styles.percentageInput}
                            value={coreCapitalCoverage.value}
                            onChange={(event) => coreCapitalCoverage.setValue(event.target.value)}
                            onBlur={coreCapitalCoverage.onBlur}
                        />
                        <span>&nbsp;%</span>
                        <p className="field-error-message">{coreCapitalCoverage.errorMessage}</p>
                    </dd>
                </dl>
            </div>
            <div className={styles.buttonGroup}>
                <AsyncButton onClick={submitInfo} disabled={!anyFieldsTouched}>
                    {t('common.save')}
                </AsyncButton>
                {anyFieldsTouched && <p>{t('pages-mybank.unsavedChanges')}</p>}
            </div>
        </Fragment>
    )
}
