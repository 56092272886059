import { PURPLE } from '#app/colors/colors'
import { DepositDto } from '@fixrate/fixrate-query'
import { DateOutput, InterestOutput } from '#components'
import { Box, List, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const PossibleSourcesOfNonConformance = ({ deposit }: { deposit: DepositDto }) => {
    const { t } = useTranslation()

    return (
        <Box
            sx={{
                backgroundColor: PURPLE[50],
                color: PURPLE[900],
                marginTop: '2rem',
                marginBottom: '2rem',
                padding: '2rem',
            }}
        >
            <Typography variant="h3" color={PURPLE[800]}>
                {t('pages-portfolio-bank.possibleSourcesOfNonConformance')}
            </Typography>
            <List
                sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                }}
            >
                <ListItem sx={{ display: 'list-item' }}>
                    {deposit.interestRateBenchmark === 'NIBOR_3M' && t('pages-portfolio-bank.marginCheckQuestion')}
                    <b>
                        {deposit.interestRateBenchmark === 'NIBOR_3M' &&
                            'NIBOR3M + ' + InterestOutput.format(deposit.nominalInterestRate)}
                    </b>
                    {deposit.product.type === 'FIXED' && t('pages-portfolio-bank.interestCheckQuestion')}
                    <b>{deposit.product.type === 'FIXED' && InterestOutput.format(deposit.nominalInterestRate)}</b>
                    {'?'}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    {t('pages-portfolio-bank.firstInterestBearingDayQuestion')}
                    <b>{DateOutput.formatDate(deposit.startDate)}</b>
                    {'?'}
                </ListItem>
                <ListItem sx={{ display: 'list-item' }}>
                    {t('pages-portfolio-bank.interestRateConventionQuestion')}
                    <b>{t('enum-InterestRateConvention.' + deposit.interestRateConvention)}</b>
                    {'?'}
                </ListItem>
            </List>
        </Box>
    )
}
