import { LastOrders } from '../BankDashboard/components/LastOrders'
import FxPaper from '#app/components/Paper/FxPaper'
import { AnalyticsPaperSx } from './Analytics'

const AnalyticsLastOrders = () => {
    return (
        <FxPaper sx={AnalyticsPaperSx} transparent={true}>
            <LastOrders />
        </FxPaper>
    )
}

export default AnalyticsLastOrders
