import config from '#app/config'
import * as analytics from '#app/services/analytics'
import { EndPointProvider } from '#command'
import '#services/i18n'
import { initializeChartJs } from '#services/initializeChartJs'
import { LocalStorageProvider } from '#services/LocalStorageContext'
import { installSentry } from '#services/sentry'
import reduxStore from '#store'
import '@fixrate/fixrate-analytics-widgets/index.css'
import 'chartjs-adapter-date-fns'
import 'core-js/stable'
import { createRoot } from 'react-dom/client'
import Modal from 'react-modal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { track } from 'react-tracking'
import 'regenerator-runtime/runtime'
import 'swiper/css'
import 'swiper/css/scrollbar'
import '../style/base.scss'
import '../style/blocks.scss'
import '../style/fonts/montserrat.css'
import '../style/swiper-overrides.scss'
import './hotjar'
import Root from './pages/Root'
import * as hubspot from './services/hubspot'
//
// Performs technical initialization that does not depend on redux store or user session
//   - bootstrap global variables
//   - connect to external services
//   - start background services
//   - etc.
//
const queryClient = new QueryClient()
initializeChartJs()

installSentry()

hubspot.loadHubspot()

// The root app element in index.html
const appElement = document.getElementById('app')

// Register appElement so that Modal may hide it from screen readers when a modal window is open
Modal.setAppElement(appElement)

if (!config().isProduction && !('reduxStore' in window)) {
    Object.defineProperty(window, 'reduxStore', {
        get() {
            return reduxStore.getState()
        },
    })
}

const TrackedApp = track(
    { app: 'Marketplace' },
    {
        dispatch: (data) => {
            analytics.pageEvent(window.location.pathname, data)
        },
    }
)(Root)

// Rendering React application
const root = createRoot(appElement)
root.render(
    <Provider store={reduxStore}>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <EndPointProvider>
                    <LocalStorageProvider>
                        <TrackedApp />
                    </LocalStorageProvider>
                </EndPointProvider>
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>
)
