import { useState } from 'react'
import { ButtonRow, NumberInput } from '#components'
import { useTranslation } from 'react-i18next'
import { FundCustomerDto, Portfolio } from '@fixrate/fixrate-query'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'
import { CurrencyOutputObject } from '#components/CurrencyOutput/CurrencyOutput'

type Props = {
    customer: FundCustomerDto
    portfolio: Portfolio
}

export default function SetMinOrderVolume({ customer, portfolio }: Props) {
    const { t } = useTranslation()
    const { setDepositorFundMinOrderVolume } = useCommand()

    const minOrderVolumeFieldValidator = ({ value, isEditing }) => {
        if (isEditing) {
            return
        }
        if (value < 0) {
            return t('pages.fundCustomers.customerDetail.minOrderVolume.mustBePositive')
        }
    }
    const minOrderVolumeField = useFieldState<number | null>(
        portfolio.fundData.minOrderVolume,
        minOrderVolumeFieldValidator
    )

    const [submitting, setSubmitting] = useState(false)

    async function submit() {
        if (!minOrderVolumeField.validate()) {
            return
        }

        setSubmitting(true)
        const { waitForCommand } = await setDepositorFundMinOrderVolume(
            customer.depositorId,
            portfolio.id,
            minOrderVolumeField.value
        )
        const success = await waitForCommand()
        setSubmitting(false)

        if (success) {
            minOrderVolumeField.untouch()
        }
    }

    return (
        <dl>
            <dt>
                <label>{t('pages.fundCustomers.customerDetail.minOrderVolume.label')}</label>
            </dt>
            <dd>
                <NumberInput
                    value={minOrderVolumeField.value}
                    onChange={minOrderVolumeField.setValue}
                    onBlur={minOrderVolumeField.onBlur}
                    formatFn={(v) =>
                        v !== null
                            ? CurrencyOutputObject(v, {
                                  currency: portfolio.currency,
                                  minimumDecimals: 0,
                                  maximumDecimals: 0,
                              })
                            : null
                    }
                    placeholder={t('pages.fundCustomers.customerDetail.minOrderVolume.placeholder')}
                    noDecimals
                />
                {!minOrderVolumeField.valid && (
                    <p className="field-error-message">{minOrderVolumeField.errorMessage}</p>
                )}
                <Box
                    sx={{
                        mt: 1,
                        transformOrigin: 'top',
                        transform: `scaleY(${minOrderVolumeField.touched ? 1 : 0})`,
                        transition: 'transform 0.2s',
                    }}
                >
                    <ButtonRow align={'left'}>
                        <LoadingButton
                            size={'small'}
                            onClick={submit}
                            variant={'contained'}
                            loading={submitting}
                            disabled={submitting}
                        >
                            {t('common.save')}
                        </LoadingButton>
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => minOrderVolumeField.reset()}
                            disabled={submitting}
                        >
                            {t('common.cancel')}
                        </Button>
                    </ButtonRow>
                </Box>
            </dd>
        </dl>
    )
}
