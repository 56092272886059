import { CHART_COLORS } from '#app/colors/colors'
import { StaggData } from '#app/state/stagg'
import { ChartData } from 'chart.js'

export const formatStaggData = ({
    input,
    exludeNames,
    gradient,
    ctx,
    colors,
    singleValue,
}: {
    input: StaggData
    exludeNames?: string[]
    gradient?: boolean
    ctx?: CanvasRenderingContext2D | null
    colors?: string[]
    singleValue?: boolean // bar charts
}): ChartData => {
    const series = input[0]?.series?.filter((s) => !!exludeNames?.includes(s.name) === false)
    const chartColors = colors ? [...colors, ...CHART_COLORS] : CHART_COLORS
    const chartData: ChartData = {
        datasets: [],
        labels: [],
    }

    if (!series) {
        return chartData
    }

    if (singleValue) {
        chartData.datasets = [
            {
                data: series.map((item) => item.values.at(-1)[1]),
            },
        ]
        chartData.labels = series.map((item) => item.tags[Object.keys(item.tags)[0]])

        return chartData
    }

    for (let i = 0; i < series.length; i++) {
        const seriesHasDashedGaps = series[i].values.filter((point) => point[1] === null).length > 1

        const tag = series[i].tags[Object.keys(series[i].tags)[0]]
        const bgGradient = ctx?.createLinearGradient(0, 0, 0, 600)

        if (gradient) {
            bgGradient?.addColorStop(0, chartColors[i] + '70')
            bgGradient?.addColorStop(0.6, chartColors[i] + '00')
        }

        chartData.datasets.push({
            label: tag,
            data: series[i].values?.map(([date, value]) => ({
                x: new Date(date).getTime(),
                y: value,
            })),
            borderColor: chartColors[i],
            backgroundColor: gradient && ctx ? bgGradient : chartColors[i],
            fill: gradient ? true : false,
            spanGaps: true,
            pointRadius: seriesHasDashedGaps ? 4 : 1,
            pointHoverRadius: 6,
            pointBackgroundColor: chartColors[i],
            borderDash: seriesHasDashedGaps ? [6, 6] : [0, 0],
        })
    }

    return chartData
}
