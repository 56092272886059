import { Box } from '@mui/material'
import { BURNT_ORANGE, PURPLE } from '#app/colors/colors'
import { useTranslation } from 'react-i18next'

export function OrderTypeBadge({ orderType }: { orderType: 'BUY' | 'SELL' }) {
    const { t } = useTranslation()
    const typeBadgeStyle = {
        backgroundColor: orderType === 'BUY' ? PURPLE[50] : BURNT_ORANGE[50],
        color: orderType === 'BUY' ? PURPLE[500] : BURNT_ORANGE[600],
        padding: '0.6rem 1.8rem',
        fontWeight: 700,
        fontSize: '1.4rem',
    }

    return (
        <Box component="span" sx={{ ...typeBadgeStyle }}>
            {orderType === 'BUY' && t('enum-FundOrderType.BUY')}
            {orderType === 'SELL' && t('enum-FundOrderType.SELL')}
        </Box>
    )
}
