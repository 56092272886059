import { useSelector } from '#state/useSelector'
import styles from './ProductSelector.module.css'
import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

const ProductSelector = ({
    onSelectProduct,
    selectedProductId,
}: {
    onSelectProduct: Dispatch<SetStateAction<string>>
    selectedProductId: string
}) => {
    const { t } = useTranslation()
    const products = useSelector((state) => state.products)

    const productOptions = Object.values(products).map((product) => (
        <option key={product.id} value={product.id}>
            {t('common.productShortName' + product.id)}
        </option>
    ))

    return (
        <div className={styles.selectorFrame}>
            <select id="product" onChange={(e) => onSelectProduct(e.target.value)} value={selectedProductId}>
                <option key="empty" value="">
                    {t('pages-analytics.exposureFilterAllProducts')}
                </option>
                {productOptions}
            </select>
        </div>
    )
}

export default ProductSelector
