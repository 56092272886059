import MandateChanger from '#app/blocks/MandateChanger/MandateChanger'
import { SPRING_GREEN } from '#app/colors/colors'
import FixrateIcon from '#app/components/FixrateIcon/FixrateIcon'
import { Box, Button, Divider, Drawer, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
export default function MandateSlideOut({ open, onClose }: { open: boolean; onClose: () => void }) {
    const theme = useTheme()
    const { t } = useTranslation()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Drawer
            open={open}
            anchor={isMobile ? 'bottom' : 'right'}
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    width: '50rem',
                    maxHeight: isMobile ? '80%' : '100%',
                },
            }}
        >
            <Stack maxWidth="100%" direction="row" alignItems="center" pl={2} py={1}>
                <FixrateIcon name="protectedLockFill" sx={{ width: '8rem' }} />
                <Typography mb={0} variant="h2" fontWeight={700}>
                    {t('block-Mandate.administrateMandate')}
                </Typography>
            </Stack>
            <Divider />
            <Box px={2}>
                <MandateChanger />
            </Box>
            <Stack mt="auto" alignItems={'flex-start'} px={4} pb={4} bgcolor={SPRING_GREEN[50]}>
                <FixrateIcon name="lightbulbFill" sx={{ width: '11rem' }} />
                <Stack spacing={1} mb={2}>
                    <Typography variant="h3">{t('block-Mandate.wantToKnowMore')}</Typography>
                    <Typography>{t('block-Mandate.wantToKnowMoreDescription')}</Typography>
                </Stack>
                <Button
                    size="small"
                    component={'a'}
                    href="https://www.fixrate.no/innsikt/rentefond"
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                >
                    {t('common.readMore')}
                </Button>
            </Stack>
        </Drawer>
    )
}
