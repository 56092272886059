import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FundCategory } from '@fixrate/fixrate-query'
import { DateOutput } from '#components'
import { LoadingButton } from '@mui/lab'
import { Close } from '@mui/icons-material'
import { useCommand } from '#command'
import { useConfirmModal } from '#app/layers/ConfirmModal/ConfirmModal'

export default function RemovePlatformFeeEntry({
    depositorId,
    fundCategory,
    startDate,
}: {
    depositorId: string
    fundCategory: FundCategory
    startDate: string
}) {
    const { t } = useTranslation()
    const { removeDepositorFundPlatformFeeEntry } = useCommand()

    const confirmModal = useConfirmModal()

    const [submitting, setSubmitting] = useState(false)

    function confirm() {
        confirmModal({
            title: t('pages.fundCustomers.customerDetail.platformFee.confirmRemove.title'),
            text: t('pages.fundCustomers.customerDetail.platformFee.confirmRemove.description', {
                date: DateOutput.formatDate(startDate),
            }),
            submitButtonText: t('common.remove'),
            submitAction: submit,
        })
    }

    async function submit() {
        setSubmitting(true)
        const { waitForCommand } = await removeDepositorFundPlatformFeeEntry(depositorId, fundCategory, startDate)
        await waitForCommand()
        setSubmitting(false)
    }

    return (
        <LoadingButton
            size={'small'}
            variant={'outlined'}
            color={'error'}
            disabled={submitting}
            loading={submitting}
            onClick={confirm}
        >
            <Close fontSize={'inherit'} />
        </LoadingButton>
    )
}
