import { SUNSET_ORANGE } from '#app/colors/colors'
import { DepositDto } from '@fixrate/fixrate-query'
import { TableCell, TableRow } from '@mui/material'
import ProcessInformation, { depositWarnings } from './ProcessInformation'
export default function TableRowWarning({ deposit }: { deposit: DepositDto }) {
    const warnings = depositWarnings(deposit)
    return warnings.hasWarnings ? (
        <TableRow sx={{ position: 'relative', height: '3rem' }}>
            <TableCell
                style={{
                    display: 'flex',
                    width: '100%',
                    position: 'absolute',
                    top: '-3rem',
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderLeft: '0.4rem solid' + SUNSET_ORANGE[600],
                }}
                colSpan={6}
            >
                <ProcessInformation deposit={deposit} sx={{ width: '100%' }} />
            </TableCell>
        </TableRow>
    ) : null
}
