import { DateOutput, InterestOutput, LabeledInfo, LabeledInput, NumberInput } from '#app/components'
import FXDateInput from '#app/components/DateInput/FXDateInput'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { useCommand } from '#app/services/beta'
import { formatAccount, formatIban } from '#app/services/formatnumber'
import { useBankCalendar } from '#app/services/useBankCalendar'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import usePortfolio from '#app/services/usePortfolio'
import { useTermination } from '#app/services/useTermination'
import { useSelector } from '#app/state/useSelector'
import { useFieldState, useStableValidator } from '@fixrate/fieldstate'
import { DepositDto } from '@fixrate/fixrate-query'
import { Alert, Box, Button, InputAdornment, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { endOfDay, format, isAfter, isBefore, startOfDay } from 'date-fns'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react/jsx-runtime'
import { TerminationMode } from '../../DepositTermination'
import { SignTermination } from './SignTermination'

const StartTermination = ({
    terminationMode,
    setTerminationMode,
    deposit,
}: {
    terminationMode: TerminationMode
    deposit: DepositDto
    setTerminationMode: (val: TerminationMode) => void
}) => {
    const { t } = useTranslation()
    const { firstValidTerminationDate, lastValidTerminationDate } = useTermination()
    const { terminateDeposit } = useCommand()
    const { isValidBankDay } = useBankCalendar()
    const organisationCountry = useCurrentCountryCode()
    const portfolio = usePortfolio()
    const portfolioCurrency = portfolio?.currency
    const depositor = useSelector((state) => state.depositor)
    const connectedDepositAccount = depositor?.settlementAccounts?.find(
        (account) => account.id === deposit.settlementAccountId
    )

    const _firstValidTerminationDate = startOfDay(firstValidTerminationDate(deposit, terminationMode))
    const _lastValidTerminationDate = endOfDay(lastValidTerminationDate(deposit, terminationMode))

    const settlementAccountValidator = useStableValidator(
        'NOT_EMPTY_ON_EDIT',
        t('pages-portfolio-depositor.terminationSettlementAccountMissing')
    )
    const settlementAccount = useFieldState<string>('', settlementAccountValidator)
    const accountsInCorrectCurrency = depositor?.settlementAccounts?.filter(
        (account) =>
            account.currency === portfolioCurrency || (account.currency === null && portfolioCurrency === 'NOK')
    )

    const selectedReasonValidator = useStableValidator(
        'NOT_EMPTY_ON_EDIT',
        t('pages-portfolio-depositor.terminationReasonMissing')
    )
    const selectedReason = useFieldState<string>('', selectedReasonValidator)

    const terminationDateValidator = useStableValidator(
        'NOT_EMPTY',
        t('pages-portfolio-depositor.terminationTerminationDateInvalid')
    )
    const terminationDate = useFieldState<Date>(_firstValidTerminationDate, terminationDateValidator)

    const [showSignModal, setShowSignModal] = useState(false)

    const otherReason = t('pages-portfolio-depositor.terminationReasonOther')
    const reasons = [
        t('pages-portfolio-depositor.terminationReason1'),
        t('pages-portfolio-depositor.terminationReason2'),
        t('pages-portfolio-depositor.terminationReason3'),
        otherReason,
    ]

    const statedReason = useFieldState<string>('')

    const interestRateRequirementValidator = useCallback(
        ({ isEditing, value }) => {
            if (isEditing) return

            if (value == null) {
                return t('pages-portfolio-depositor.terminationMarginMissing')
            }
            if (!`${value}`.match(/^-?(\d{0,2}([.,]\d\d?)?)$/)) {
                return t('pages-portfolio-depositor.terminationMarginInvalid')
            }
        },
        [t]
    )
    const interestRateRequirement = useFieldState<number | null>(null, interestRateRequirementValidator)

    function terminationDateValid(date: Date | null) {
        if (!date) {
            return false
        }

        return (
            isValidBankDay(date) &&
            !isBefore(date, _firstValidTerminationDate) &&
            !isAfter(date, _lastValidTerminationDate)
        )
    }

    async function onStartTermination() {
        const isValidSettlementAccount = connectedDepositAccount ? true : settlementAccount.validate()
        const isValidTerminationDate = terminationDate.validate() && terminationDateValid(terminationDate.value)
        const isValidInterestRateRequirement = terminationMode !== 'CONDITIONAL' || interestRateRequirement.validate()

        const settlementAccountValue = connectedDepositAccount?.id ?? settlementAccount.value

        if (isValidSettlementAccount && isValidTerminationDate && isValidInterestRateRequirement) {
            const { waitForCommand } = await terminateDeposit(
                terminationMode,
                deposit.id,
                settlementAccountValue,
                terminationMode === 'REJECT_INTEREST_RATE_CHANGE'
                    ? undefined
                    : format(terminationDate.value, 'yyyy-MM-dd'),
                terminationMode === 'CONDITIONAL' ? interestRateRequirement.value : undefined,
                selectedReason.value === otherReason ? statedReason.value : selectedReason.value
            )
            const success = await waitForCommand()
            if (success) {
                setShowSignModal(true)
            }
        }
    }

    return showSignModal ? (
        <SignTermination
            deposit={deposit}
            onClose={() => {
                setShowSignModal(false)
                setTerminationMode('HIDE')
            }}
        />
    ) : (
        <Fragment>
            <FxDialogContent>
                {terminationMode === 'UNCONDITIONAL' && (
                    <Box>
                        {deposit.product.termsType === 'NOTICE' && (
                            <>
                                <LabeledInfo
                                    info={t('pages-portfolio-depositor.terminationStartInfo1', {
                                        count: deposit.product.days,
                                    })}
                                    helpText={t('pages-portfolio-depositor.noticeHelpText', {
                                        count: deposit.product.days,
                                    })}
                                />
                                <p>{t('pages-portfolio-depositor.terminationStartInfo2')}</p>
                            </>
                        )}
                        {deposit.product.termsType === 'RECURRING_FIXED_TERMS' && (
                            <>
                                <Typography>{t('pages-portfolio-depositor.terminationStartInfo2')}</Typography>
                            </>
                        )}
                    </Box>
                )}
                {terminationMode === 'CONDITIONAL' && (
                    <Stack spacing={2}>
                        <Typography>
                            {t('pages-portfolio-depositor.terminationConditionalInfo1', {
                                count: deposit.product.days,
                            })}
                        </Typography>
                        <Typography>{t('pages-portfolio-depositor.terminationConditionalInfo2')}</Typography>
                        <Typography>{t('pages-portfolio-depositor.terminationConditionalInfo3')}</Typography>
                    </Stack>
                )}

                <Stack spacing={2}>
                    {terminationMode === 'CONDITIONAL' && (
                        <Stack spacing={1} mt={2}>
                            <div>
                                <LabeledInput
                                    label={t('pages-portfolio-depositor.terminationStartNewMarginRequirementLabel')}
                                    helpText={t(
                                        'pages-portfolio-depositor.terminationStartNewMarginRequirementHelpText'
                                    )}
                                    errorMessage={
                                        !interestRateRequirement.valid && interestRateRequirement.errorMessage
                                    }
                                >
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <Typography>
                                            {t('pages-portfolio-depositor.terminationStartNewMarginRequirementInfo', {
                                                margin: InterestOutput.format(deposit.nominalInterestRate),
                                            })}
                                        </Typography>
                                        <NumberInput
                                            id="newInterestInput"
                                            size={'small'}
                                            sx={{ pl: 1, maxWidth: '12rem' }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}
                                            value={interestRateRequirement.value}
                                            onChange={interestRateRequirement.setValue}
                                            onBlur={interestRateRequirement.onBlur}
                                            formatFn={(v) => (v == null ? '' : `${v}`.replace('.', ','))}
                                        />
                                    </Stack>
                                </LabeledInput>
                            </div>
                            {Math.abs(interestRateRequirement.value) >= 1 && (
                                <Alert severity={'warning'}>
                                    {t('pages-portfolio-depositor.terminationStartNewMarginRequirementWarning', {
                                        margin: InterestOutput.format(interestRateRequirement.value),
                                    })}
                                </Alert>
                            )}
                        </Stack>
                    )}

                    {connectedDepositAccount ? (
                        <Stack>
                            <InputLabel>
                                {t('pages-portfolio-depositor.terminationStartSettlementAccountLabel')}
                            </InputLabel>
                            <Typography>
                                {formatAccount(connectedDepositAccount?.account, organisationCountry)}
                            </Typography>
                        </Stack>
                    ) : (
                        <div>
                            <LabeledInput
                                label={t('pages-portfolio-depositor.terminationStartSettlementAccountLabel')}
                                helpText={t('pages-portfolio-depositor.terminationStartSettlementAccountHelpText')}
                                errorMessage={!settlementAccount.valid && settlementAccount.errorMessage}
                            >
                                <Select
                                    displayEmpty={true}
                                    id="DepositTerminationStart_SettlementAccount"
                                    value={settlementAccount.value}
                                    onChange={(e) => settlementAccount.setValue(e.target.value)}
                                    onBlur={settlementAccount.onBlur}
                                >
                                    <MenuItem key={''} value={''}>
                                        {t('common.selectOption')}
                                    </MenuItem>
                                    {accountsInCorrectCurrency?.map((account) => (
                                        <MenuItem key={account.id} value={account.id}>
                                            {account.name}&nbsp;(
                                            {account.account
                                                ? formatAccount(account.account, organisationCountry)
                                                : formatIban(account.iban)}
                                            )
                                        </MenuItem>
                                    ))}
                                </Select>
                            </LabeledInput>
                        </div>
                    )}
                    {terminationMode !== 'REJECT_INTEREST_RATE_CHANGE' &&
                        deposit.product.termsType !== 'RECURRING_FIXED_TERMS' && (
                            <div>
                                <LabeledInput
                                    label={t('pages-portfolio-depositor.terminationStartSettlementDateLabel')}
                                    helpText={t('pages-portfolio-depositor.terminationStartSettlementDateHelpText')}
                                    errorMessage={!terminationDate.valid && terminationDate.errorMessage}
                                >
                                    <FXDateInput
                                        value={terminationDate.value}
                                        onChange={terminationDate.setValue}
                                        minDate={_firstValidTerminationDate}
                                        maxDate={_lastValidTerminationDate}
                                        onlyBankDays
                                    />
                                </LabeledInput>
                            </div>
                        )}
                    {terminationMode !== 'REJECT_INTEREST_RATE_CHANGE' &&
                        deposit.product.termsType === 'RECURRING_FIXED_TERMS' && (
                            <div>
                                <LabeledInput
                                    label={t('pages-portfolio-depositor.terminationStartDisbursementDateLabel')}
                                >
                                    {DateOutput.formatVerboseMonth(terminationDate.value)}
                                </LabeledInput>
                            </div>
                        )}

                    {terminationMode === 'UNCONDITIONAL' && (
                        <div>
                            <LabeledInput
                                label={t('pages-portfolio-depositor.terminationReasonLabel')}
                                helpText={t('pages-portfolio-depositor.terminationReasonHelpText')}
                                errorMessage={!selectedReason.valid && selectedReason.errorMessage}
                            >
                                <Select
                                    displayEmpty
                                    id="reasonSelect"
                                    onChange={(e) => selectedReason.setValue(e.target.value)}
                                    value={selectedReason.value}
                                >
                                    <MenuItem key={''} value={''}>
                                        {t('common.selectOption')}
                                    </MenuItem>
                                    {reasons.map((reason) => (
                                        <MenuItem key={reason} value={reason}>
                                            {reason}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {selectedReason.value === otherReason && (
                                    <textarea
                                        name="reason"
                                        value={statedReason.value}
                                        onChange={(e) => statedReason.setValue(e.target.value)}
                                    />
                                )}
                            </LabeledInput>
                        </div>
                    )}

                    {depositor?.customerDeclarationExpired && (
                        <Alert severity={'warning'}>
                            {t('pages-portfolio-depositor.terminationWhenCustomerDeclIsMissing')}
                        </Alert>
                    )}
                </Stack>
            </FxDialogContent>
            <FxDialogActions sx={{ justifyContent: 'space-between' }}>
                {terminationMode !== 'REJECT_INTEREST_RATE_CHANGE' && (
                    <Button variant="outlined" onClick={() => setTerminationMode('SELECT')}>
                        {t('common.goBack')}
                    </Button>
                )}
                <Button variant="contained" data-cy="createTerminationDocument" onClick={onStartTermination}>
                    {t('pages-portfolio-depositor.terminationStartButtonText')}
                </Button>
            </FxDialogActions>
        </Fragment>
    )
}

export default StartTermination
