import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TourFrame from './TourFrame'
import { MovieViewer } from '#app/components/MovieViewer/MovieViewer'
import movieCover from './assets/fixrateVideo.png'
import { Box, MenuItem } from '@mui/material'
import FixrateIllustration from '#app/components/FixrateIllustration/FixrateIllustration'
import InterestCalculator from '../InterestCalculator/InterestCalculator'
import { useSelector } from '#state/useSelector'
import { bankCountSelector } from '#state/selectors'

type Step = 'welcome' | 'bank' | 'reports' | 'potential'

type Props = {
    open: boolean
    setOpen: (val: boolean) => void
}

const STEPS: Step[] = ['welcome', 'bank', 'reports', 'potential']

export default function WelcomeTour({ setOpen, open }: Props) {
    const { t } = useTranslation()

    const [currentStage, setCurrentStage] = useState(STEPS[0])
    const [videoRunning, setVideoRunning] = useState(false)

    const bankCount = useSelector(bankCountSelector)

    const onBack = () => {
        const index = STEPS.indexOf(currentStage)
        if (index > 0) {
            setCurrentStage(STEPS[index - 1])
        }
    }

    const onNext = () => {
        const index = STEPS.indexOf(currentStage)
        if (index < STEPS.length - 1) {
            setCurrentStage(STEPS[index + 1])
        } else {
            setOpen(false)
        }
    }

    if (currentStage === 'welcome') {
        return (
            <TourFrame
                title={t('pages-onboarding.welcomeHeader')}
                onNext={onNext}
                open={open}
                headerElement={
                    <Box p={4} sx={{ boxShadow: '0rem, 0.2rem, 0.4rem, rgba(0,0,0,0.20)' }}>
                        <MenuItem
                            focusRipple
                            sx={{
                                p: 0,
                                maxWidth: '32rem',
                                cursor: 'pointer',
                                scale: '1',
                                transition: '0.5s',
                                ':hover': { scale: '1.1' },
                            }}
                            onClick={() => setVideoRunning(true)}
                        >
                            <img style={{ maxWidth: '100%' }} src={movieCover} alt="" />
                        </MenuItem>
                    </Box>
                }
                onClose={() => setOpen(false)}
            >
                <p>{t('pages-onboarding.welcomeInfo1')}</p>
                <p>{t('pages-onboarding.welcomeInfo2', { bankCount: bankCount || 56 })}</p>
                {videoRunning && (
                    <MovieViewer vimeoUrl="https://player.vimeo.com/video/676301306" videoActive={setVideoRunning} />
                )}
            </TourFrame>
        )
    }
    if (currentStage === 'bank') {
        return (
            <TourFrame
                title={t('pages-onboarding.bankHeader')}
                headerElement={
                    <Box maxWidth={'45rem'}>
                        <FixrateIllustration name="fixrateEcosystem" color="multiColor" />
                    </Box>
                }
                onNext={onNext}
                onBack={onBack}
                open={open}
                onClose={() => setOpen(false)}
            >
                <p>{t('pages-onboarding.bankInfo2')}</p>
                <p>{t('pages-onboarding.bankInfo3')}</p>
                <p>{t('pages-onboarding.bankInfo4')}</p>
            </TourFrame>
        )
    }
    if (currentStage === 'reports') {
        return (
            <TourFrame
                title={t('pages-onboarding.reportsHeaderNew')}
                headerElement={
                    <Box maxWidth={'45rem'}>
                        <FixrateIllustration name="manShowingReport" color="multiColor" />
                    </Box>
                }
                onNext={onNext}
                onBack={onBack}
                open={open}
                onClose={() => setOpen(false)}
            >
                <p>{t('pages-onboarding.reportsInfo1')}</p>
                <p>{t('pages-onboarding.reportsInfo2')}</p>
            </TourFrame>
        )
    }
    if (currentStage === 'potential') {
        return (
            <TourFrame
                title={t('pages-onboarding.calculateInterestHeader')}
                headerElement={
                    <Box maxWidth={'45rem'}>
                        <FixrateIllustration name="womanOfficeWorking" color="multiColor" />
                    </Box>
                }
                onNext={onNext}
                onBack={onBack}
                open={open}
                onClose={() => setOpen(false)}
                skip={true}
            >
                <InterestCalculator />
            </TourFrame>
        )
    }
}
