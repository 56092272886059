import FixrateIcon, { IconName } from '#components/FixrateIcon/FixrateIcon'
import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import { BENTO_CARD_BACKGROUND, BENTO_PADDING } from '#pages/BankDashboard/components/bento/BentoCard'

type BentoCardAction = {
    text: string
    icon?: string
    onClick?: () => void
    variant?: 'text' | 'outlined' | 'contained'
}

interface IconBentoCardProps {
    title: string
    content: string
    actions?: BentoCardAction[]
    icon: IconName
}

export const IconBentoCard = ({ title, content, actions, icon }: IconBentoCardProps) => {
    return (
        <Grid item xs={12} lg={6}>
            <Paper sx={{ p: BENTO_PADDING, backgroundColor: BENTO_CARD_BACKGROUND, borderRadius: 2 }}>
                <Grid container>
                    <Grid item xs={12} lg={4}>
                        <FixrateIcon name={icon} sx={{ display: 'flex', justifyContent: 'center' }} />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Stack direction={'column'} spacing={2}>
                            <Typography variant={'h3'}>{title}</Typography>
                            <Typography variant={'subtitle1'}>{content}</Typography>
                            {actions && (
                                <Stack py={1} alignItems={'flex-start'}>
                                    {actions.map((action, index) => (
                                        <Button
                                            key={index}
                                            variant={action.variant ?? 'contained'}
                                            size={'small'}
                                            onClick={action.onClick}
                                            startIcon={action.icon && <i className={action.icon} />}
                                        >
                                            {action.text}
                                        </Button>
                                    ))}
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
