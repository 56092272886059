import { Box } from '@mui/material'
import 'intl-tel-input/build/css/intlTelInput.css'
import IntlTelInput from 'intl-tel-input/react/build/IntlTelInputWithUtils'
import './PhoneNumber.scss'

type Props = {
    initialCountry: 'NO' | 'SE'
    initialNumber?: string
    setValid: (valid: boolean) => void
    setNumber: (number: string) => void
}

function PhoneNumber({ setValid, setNumber, initialCountry, initialNumber }: Props) {
    return (
        <Box data-cy="phone">
            <IntlTelInput
                initialValue={initialNumber}
                data-cy="phone"
                onChangeNumber={(val) => setNumber(val)}
                onChangeValidity={(validity) => setValid(validity)}
                initOptions={{
                    initialCountry: initialCountry,
                    countryOrder: ['NO', 'SE', 'DK', 'GB'],
                    separateDialCode: true,
                    formatAsYouType: true,
                    formatOnDisplay: true,
                }}
            />
        </Box>
    )
}

export default PhoneNumber
