import { useState } from 'react'
import { Checkbox, DialogContentText, FormControl, FormControlLabel, Stack } from '@mui/material'
import ClipboardCopyButton from '../../components/ClipboardCopyButton'
import { FundSellOrderRow } from './FundSellOrderTable'
import { useDispatch } from 'react-redux'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useTranslation } from '#components/i18n'
import { CurrencyOutput } from '#components'
import { useSelector } from '#state/useSelector'
import DetailRow from '../FundBuyOrderOverview/components/DetailRow'
import format from 'date-fns/format'
import { formatAccount } from '#services/formatnumber'
import DatePicker from '#components/DatePicker'
import FxDialog from '#components/FxDialog/FxDialog'
import FxDialogTitle from '#components/FxDialog/FxDialogTitle'
import FxDialogContent from '#components/FxDialog/FxDialogContent'
import FxDialogActions from '#components/FxDialog/FxDialogActions'

interface ExportSellOrderToFaResponse {
    resultStatus: string
    description?: string
}

export default function RegisterInFaDialog({
    fundSellOrderRow,
    onClose,
}: {
    fundSellOrderRow: FundSellOrderRow | null
    onClose: () => void
}) {
    const { t } = useTranslation('pages-marketplace')
    const dispatch = useDispatch()

    const { cancelFundSellOrder, registerFundSellOrderInFa } = useCommand()
    const [submittingCancel, setSubmittingCancel] = useState(false)
    const [submittingRegister, setSubmittingRegister] = useState(false)
    const [enableExportToFA, setEnableExportToFA] = useState(false)
    const [exportingToFA, setExportingToFA] = useState(false)
    const [transactionDate, setTransactionDate] = useState<Date>(new Date())
    const fund = useSelector((state) => state.funds.find((fund) => fund.id === fundSellOrderRow?.fundId))
    const fundShareClass = useSelector((state) =>
        state.funds.flatMap((f) => f.fundShareClasses).find((sc) => sc.id === fundSellOrderRow?.fundShareClassId)
    )
    if (!fundSellOrderRow || !fund || !fundShareClass) {
        return null
    }

    const decimalPrecision = fund.decimalPrecision

    const canExport =
        !fundSellOrderRow.registeredInFa &&
        fundSellOrderRow.id != null &&
        fundSellOrderRow.unitQuantity != null &&
        fundSellOrderRow.unitQuantity > 0 &&
        fundSellOrderRow.faClientAccountNumber != null &&
        fundSellOrderRow.cid != null &&
        fundSellOrderRow.state != null &&
        fundSellOrderRow.state !== 'AWAITING_SIGNATURE' &&
        fundShareClass.isin != null &&
        enableExportToFA &&
        transactionDate != null &&
        !exportingToFA

    async function exportSellOrderRequestToFa() {
        if (!canExport) {
            return
        }
        try {
            setExportingToFA(true)
            const cid = fundSellOrderRow.cid
            const faPortfolioId = cid.substring(0, cid.length - 1)
            const request = {
                fundOrderId: fundSellOrderRow.id,
                unitQuantity: fundSellOrderRow.unitQuantity,
                transactionDate: format(transactionDate, 'yyyy-MM-dd'),
                isinCode: fundShareClass.isin,
                cashAccountNumber: fundSellOrderRow.faClientAccountNumber,
                description: 'IMPORT: Salg av fondsandeler via Fixrate Marketplace',
            }
            const response = await fetch(`/api/fund-actions/portfolio/${faPortfolioId}/sell-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(request),
            })
            if (!response.ok) {
                dispatch(
                    showConfirmationModal({
                        title: 'Feilrespons ved opplasting til FA',
                        text: response.statusText,
                        buttonText: t('common.continue'),
                        confirmationIconName: 'error',
                    })
                )
                return
            }
            const data: ExportSellOrderToFaResponse = await response.json()
            await handleExportSellOrderResponse(data)
        } catch (e) {
            dispatch(
                showConfirmationModal({
                    title: 'Feil ved lasting til FA',
                    text: 'Ukjent feil ved lasting til FA: ' + e,
                    buttonText: t('common.continue'),
                    confirmationIconName: 'error',
                })
            )
        } finally {
            setExportingToFA(false)
            onClose()
        }
    }

    async function handleExportSellOrderResponse(exportSellOrderResponse: ExportSellOrderToFaResponse): Promise<void> {
        switch (exportSellOrderResponse.resultStatus) {
            case 'SUCCESS': {
                const { waitForCommand } = await registerFundSellOrderInFa(fundSellOrderRow.id)
                const registerSuccess = await waitForCommand()
                if (!registerSuccess) {
                    dispatch(
                        showConfirmationModal({
                            title: 'Feil ved intern registrering',
                            text: 'Ordren ble opplastet til FA, men intern registrering feilet. Prøv å trykk på "Ordren er registert i FA" manuelt.',
                            buttonText: t('common.continue'),
                        })
                    )
                    break
                }
                dispatch(
                    showConfirmationModal({
                        title: 'Ordren er lastet opp til FA og registrert internt!',
                        text: 'Ordren er lastet opp til FA og registrert internt!',
                        buttonText: t('common.continue'),
                    })
                )
                break
            }
            case 'FAILURE':
            default:
                dispatch(
                    showConfirmationModal({
                        title: 'Feil ved opplasting til FA',
                        text: 'Feil ved opplasting til FA: ' + exportSellOrderResponse.description,
                        buttonText: t('common.continue'),
                        confirmationIconName: 'error',
                    })
                )
                break
        }
    }

    async function handleSubmitCancel() {
        if (!fundSellOrderRow) return

        setSubmittingCancel(true)

        const { waitForCommand } = await cancelFundSellOrder(fundSellOrderRow.id)
        const success = await waitForCommand()
        if (success) {
            dispatch(
                showConfirmationModal({
                    title: 'Ordren er avbrutt!',
                    text: 'Ordren er avbrutt!',
                    buttonText: t('common.continue'),
                })
            )
            onClose()
        }

        setSubmittingCancel(false)
    }

    async function handleSubmitRegister() {
        if (!fundSellOrderRow || fundSellOrderRow.state == null || fundSellOrderRow.state === 'AWAITING_SIGNATURE')
            return

        setSubmittingRegister(true)

        const { waitForCommand } = await registerFundSellOrderInFa(fundSellOrderRow.id)
        const success = await waitForCommand()
        if (success) {
            dispatch(
                showConfirmationModal({
                    title: 'Registrering bekreftet!',
                    text: 'Registrering bekreftet!',
                    buttonText: t('common.continue'),
                })
            )
            onClose()
        }

        setSubmittingRegister(false)
    }
    const isin = fundShareClass.isin
    const fundShareClassName = fundShareClass.fullName
    const faClientAccountNumber = fundSellOrderRow.faClientAccountNumber
    const regulatoryRegion = faClientAccountNumber.length === 11 ? 'NO' : 'SE' // TODO: Add regulatory region to FundCustomerDto.

    return (
        <FxDialog open={true} onClose={onClose} fullWidth maxWidth={'sm'}>
            <FxDialogTitle onClose={onClose}>Manuell registrering i FA</FxDialogTitle>
            {fundSellOrderRow && (
                <FxDialogContent sx={{ my: 2 }}>
                    <DialogContentText sx={{ mb: 2 }}>
                        Registrer denne ordren i FA før du bekrefter registeringen her.
                    </DialogContentText>
                    <DetailRow title={'Andelsklasse'}>{fundShareClassName}</DetailRow>
                    <DetailRow title={'ISIN'}>
                        <div>
                            <span>{isin}</span>
                            <ClipboardCopyButton inputText={isin} />
                        </div>
                    </DetailRow>
                    <DetailRow title={'Innskyter'}>{fundSellOrderRow.depositorName}</DetailRow>
                    <DetailRow title={'Andeler'}>
                        <div>
                            <span>{CurrencyOutput.formatNoCode(fundSellOrderRow.unitQuantity, decimalPrecision)}</span>
                            <ClipboardCopyButton
                                inputText={CurrencyOutput.formatNoCode(
                                    fundSellOrderRow.unitQuantity,
                                    decimalPrecision
                                )?.replace(/\s/g, '')}
                            />
                        </div>
                    </DetailRow>
                    <DetailRow title={'External ID'}>
                        <div>
                            <span>{fundSellOrderRow.id}</span>
                            <ClipboardCopyButton inputText={fundSellOrderRow.id ?? ''} />
                        </div>
                    </DetailRow>
                    <DetailRow title={'Klientkonto i FA'}>
                        <div>
                            <span>
                                {faClientAccountNumber.length > 4
                                    ? formatAccount(faClientAccountNumber, regulatoryRegion)
                                    : faClientAccountNumber}
                            </span>
                            <ClipboardCopyButton inputText={faClientAccountNumber} />
                        </div>
                    </DetailRow>
                    <DetailRow title={'KID/OCR'}>
                        <div>
                            <span>{fundSellOrderRow.cid}</span>
                            <ClipboardCopyButton inputText={fundSellOrderRow.cid ?? ''} />
                        </div>
                    </DetailRow>
                    <FormControl>
                        <FormControlLabel
                            sx={{ mt: 1, mb: 1 }}
                            control={
                                <Checkbox
                                    id="enableExportToFAInput"
                                    checked={enableExportToFA}
                                    onChange={() => setEnableExportToFA(!enableExportToFA)}
                                />
                            }
                            label={'Last opp til FA?'}
                        />
                        {enableExportToFA && (
                            <Stack direction={'row'} spacing={1}>
                                <DatePicker
                                    id="transactionDateInput"
                                    selected={transactionDate}
                                    onChange={(date) => setTransactionDate(date)}
                                    inputProps={{
                                        label: 'Transaksjonsdato',
                                        helperText: !transactionDate && 'Transaksjonsdato må fylles ut',
                                        error: !transactionDate,
                                    }}
                                />
                                <LoadingButton
                                    data-cy="submitSellOrderButton"
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={exportSellOrderRequestToFa}
                                    disabled={exportingToFA}
                                    loading={exportingToFA}
                                >
                                    Last opp til FA
                                </LoadingButton>
                            </Stack>
                        )}
                    </FormControl>
                </FxDialogContent>
            )}
            <FxDialogActions>
                <LoadingButton
                    data-cy="cancelOrderButton"
                    variant={'outlined'}
                    disabled={submittingCancel}
                    loading={submittingCancel}
                    onClick={() =>
                        window.confirm('Er du sikker på at du vil avbryte bestillingen?') && handleSubmitCancel()
                    }
                >
                    Avbryt ordren
                </LoadingButton>
                <LoadingButton
                    data-cy="submitRegisterButton"
                    variant={'contained'}
                    onClick={handleSubmitRegister}
                    disabled={enableExportToFA || submittingRegister}
                    loading={submittingRegister}
                >
                    Ordren er registert i FA
                </LoadingButton>
            </FxDialogActions>
        </FxDialog>
    )
}
