import { COMPANY_COLORS, PURPLE } from '#app/colors/colors'
import FxDialog from '#app/components/FxDialog/FxDialog'
import { listItemIconStyle, listItemTextStyle } from '#app/components/PageHeader/components/MenuComponents'
import { logout, switchOrganisation } from '#app/services/thunks/session'
import { OrganisationType } from '@fixrate/fixrate-security'
import { useSelector } from '#state/useSelector'
import {
    Avatar,
    Box,
    Button,
    Fade,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './ChooseDepositor.module.scss'
import { asOrganisationTypeTMap } from '#app/services/enumTranslationKeyMapping'
import AvatarContent from './AvatarContent'

const SORT_ORDER: OrganisationType[] = ['FIXRATE', 'FUND_INTERMEDIARY', 'PARTNER', 'BANK', 'DEPOSITOR']

export default function ChooseDepositor() {
    const associations = useSelector((state) => state.session.associations)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const hasPartner = associations.some((a) => a.organisationType === 'PARTNER')
    const partnerAssociations = associations.filter((a) => a.organisationType === 'PARTNER')
    const [selectedOrg, setSelectedOrg] = useState('default')
    const [isCypress, setIsCypress] = useState(false)

    const organisationOptions = associations.slice().sort((a, b) => {
        const result = SORT_ORDER.indexOf(a.organisationType) - SORT_ORDER.indexOf(b.organisationType)
        if (result !== 0) {
            return result
        } else {
            return a.organisation.name?.localeCompare(b.organisation.name)
        }
    })

    const hasDifferentOrgTypes = (() => {
        const organisationTypes = new Set(associations.map((a) => a.organisationType))
        return organisationTypes.size > 1
    })()

    useEffect(() => {
        if (window.Cypress) {
            setIsCypress(true)
        }
    }, [])

    const onOrgSelected = (organisationType: OrganisationType) => {
        if (organisationType === 'PARTNER') {
            navigate('/customer')
        }
    }

    if (organisationOptions.length <= 1) {
        return null
    }

    return (
        <FxDialog
            maxWidth="lg"
            sx={{
                backgroundColor: 'rgba(0,0,0,0.8)',
                '& .MuiPaper-root': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    color: 'white',
                    padding: { xs: 3, md: undefined },
                },
            }}
            open={true}
        >
            <Stack alignItems={'center'} justifyContent={'center'} spacing={hasPartner ? 3 : 1}>
                <Box>
                    <h1 className={styles.title}>
                        {hasPartner
                            ? t('layers-chooseDepositor.welcome')
                            : t('layers-chooseDepositor.selectOrganization')}
                    </h1>
                    <MenuList
                        data-cy="choose-partner"
                        sx={{
                            backgroundColor: 'white',
                            py: 0,
                            borderRadius: '0.5rem',
                            width: '40rem',
                            maxWidth: '100%',
                        }}
                    >
                        {partnerAssociations.map((ass, index) => (
                            <MenuItem
                                sx={{ py: 1 }}
                                key={ass.organisation?.id}
                                focusRipple
                                tabIndex={index + 1}
                                onClick={() =>
                                    dispatch(
                                        switchOrganisation(ass.organisationType, ass.organisation?.id, () =>
                                            onOrgSelected(ass.organisationType)
                                        )
                                    )
                                }
                            >
                                <Avatar
                                    sx={{
                                        width: { md: '6rem' },
                                        height: { md: '6rem' },
                                        backgroundColor: COMPANY_COLORS[index],
                                        fontSize: { xs: '1.6rem', md: '2.4rem' },
                                        fontWeight: '600',
                                    }}
                                >
                                    {ass.organisation?.name
                                        .replace(' & ', ' ')
                                        .replace(/ AS$/, '')
                                        .replace('A/S', '')
                                        .split(' ')
                                        .map((i, index) => {
                                            if (index < 2) {
                                                return i.charAt(0)
                                            } else {
                                                return ''
                                            }
                                        })}
                                </Avatar>
                                <ListItemText sx={{ color: PURPLE[900], ml: 2 }}>
                                    <Typography fontWeight={600}>
                                        {t('layers-chooseDepositor.goToPartnerPortal')}
                                    </Typography>
                                    <Typography variant="body2" data-cy="partner-name">
                                        {ass.organisation?.name}
                                    </Typography>
                                </ListItemText>
                                <ListItemIcon sx={{ aspectRatio: '1/1' }}>
                                    <i className="ri-arrow-right-line" />
                                </ListItemIcon>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Box>
                {hasPartner && organisationOptions.length > 0 && (
                    <Typography color={'rgba(250,250,250,0.9)'}>
                        {t('layers-chooseDepositor.logInAsOrganization')}
                    </Typography>
                )}
                {organisationOptions.length <= 3 || isCypress ? (
                    <MenuList
                        sx={{ width: '120rem', maxWidth: '100%' }}
                        data-cy="choose-depositor"
                        className={styles.depositorList}
                    >
                        {organisationOptions
                            .filter((o) => o.organisationType !== 'PARTNER')
                            .map((ass, index) => (
                                <MenuItem
                                    key={ass.organisation?.id}
                                    focusRipple
                                    tabIndex={index + 1}
                                    onClick={() =>
                                        dispatch(switchOrganisation(ass.organisationType, ass.organisation?.id))
                                    }
                                >
                                    <AvatarContent
                                        hasDifferentOrgTypes={hasDifferentOrgTypes}
                                        hasPartner={hasPartner}
                                        name={ass.organisation?.name}
                                        type={ass.organisationType}
                                        colorIndex={index}
                                    />
                                    <Box textAlign={'center'}>
                                        <h2 data-cy="depositor-name">{ass.organisation?.name}</h2>
                                        {hasDifferentOrgTypes && !hasPartner && (
                                            <h3>{t(asOrganisationTypeTMap[ass.organisationType])}</h3>
                                        )}
                                    </Box>
                                </MenuItem>
                            ))}
                    </MenuList>
                ) : (
                    <Stack spacing={2} width={'30rem'} alignItems={'center'}>
                        <Box height={'6rem'}>
                            <Fade timeout={1500} in={true}>
                                <Select
                                    fullWidth
                                    sx={{
                                        '& .MuiListItemText-primary': { overflow: 'hidden', textOverflow: 'ellipsis' },
                                        '& .MuiSelect-select': { display: 'flex', alignItems: 'center' },
                                    }}
                                    value={selectedOrg}
                                    data-cy="depositor-select"
                                    onChange={(e, val) => setSelectedOrg(e.target.value)}
                                >
                                    <MenuItem value="default" disabled>
                                        <ListItemIcon sx={listItemIconStyle}>
                                            <i className="ri-building-line" />
                                        </ListItemIcon>
                                        <ListItemText sx={listItemTextStyle}>
                                            {t('layers-chooseDepositor.selectOrganization')}
                                        </ListItemText>
                                    </MenuItem>
                                    {organisationOptions
                                        .filter((o) => o.organisationType !== 'PARTNER')
                                        .map((asso, index) => (
                                            <MenuItem key={asso.organisation.id} value={asso.organisation.id}>
                                                <ListItemIcon sx={listItemIconStyle}>
                                                    <AvatarContent
                                                        hasDifferentOrgTypes={hasDifferentOrgTypes}
                                                        hasPartner={hasPartner}
                                                        name={asso.organisation.name}
                                                        type={asso.organisationType}
                                                        colorIndex={index}
                                                        isList={true}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText sx={listItemTextStyle} data-cy="orgName">
                                                    <Box>{asso.name}</Box>
                                                    {hasDifferentOrgTypes && !hasPartner && (
                                                        <Typography sx={{ fontSize: '1.5rem' }}>
                                                            {t(asOrganisationTypeTMap[asso.organisationType])}
                                                        </Typography>
                                                    )}
                                                </ListItemText>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Fade>
                        </Box>
                        <Button
                            onClick={() =>
                                dispatch(
                                    switchOrganisation(
                                        organisationOptions.find((o) => o.organisation.id === selectedOrg)
                                            ?.organisationType,
                                        selectedOrg
                                    )
                                )
                            }
                            fullWidth
                            variant="contained"
                            disabled={selectedOrg === 'default'}
                            sx={{ transition: '0.6s', opacity: selectedOrg === 'default' ? '0.6' : '1' }}
                        >
                            {t('menu.logIn')}
                        </Button>
                    </Stack>
                )}
                <Box color={'#FFF'} sx={{ justifyContent: 'center', display: 'flex', marginTop: '4rem' }}>
                    <Button
                        tabIndex={organisationOptions.length + 1}
                        color="inherit"
                        variant="outlined"
                        onClick={() => dispatch(logout(navigate))}
                    >
                        {t('menu.logOut')}
                    </Button>
                </Box>
            </Stack>
        </FxDialog>
    )
}
