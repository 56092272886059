import { BURNT_ORANGE, SUCCESS_GREEN, SUNSET_ORANGE } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { useCommand, useEndpoint } from '#command'
import { CurrencyOutput, DateOutput, LoadingSpinner } from '#components'
import { OrderTypeBadge } from '#components/OrderTypeBadge/OrderTypeBadge'
import OrderGroupProgressBar from '#pages/portfolio-depositor/FundPlacements/OrderGroupProgressBar'
import { fundOrderStateTMap } from '#services/enumTranslationKeyMapping'
import { formatAccount } from '#services/formatnumber'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { FundBuyOrderDto, FundBuyOrderState, FundSellOrderDto, FundSellOrderState } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getI18n, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styles from './OrderCard.module.scss'
import { useSignableDocumentListInstruction } from '#components/SignableDocument/SignableDocumentList'
import config from '#app/config'
import useCurrentDepositor from '#services/useCurrentDepositor'
import AsyncButton from '#components/Button/AsyncButton'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'

type Props = {
    orderGroupId?: string
}

type OrderDetailsProps = {
    order: FundBuyOrderDto | FundSellOrderDto
    orderType: 'BUY' | 'SELL'
    groupState: FundBuyOrderState | FundSellOrderState
}

const orderInfoBoxStyle = {
    backgroundColor: 'white',
    p: '2rem',
    border: '0.1rem solid',
    borderColor: 'grey.300',
    boxShadow: '0.1rem 0.1rem 0.1rem rgba(0,0,0,0.15)',
    mb: '2rem',
}

const OrderStatusBadge = function ({ orderState }: { orderState: FundBuyOrderState | FundSellOrderState }) {
    const { t } = useTranslation()
    const statusBadgeStyle = {
        backgroundColor:
            orderState === 'COMPLETED'
                ? SUCCESS_GREEN[50]
                : orderState === 'CANCELLED'
                  ? BURNT_ORANGE[50]
                  : SUNSET_ORANGE[50],
        color:
            orderState === 'COMPLETED'
                ? SUCCESS_GREEN[700]
                : orderState === 'CANCELLED'
                  ? BURNT_ORANGE[600]
                  : SUNSET_ORANGE[700],
        padding: '0.6rem 1.8rem',
        fontWeight: 600,
        fontSize: '1.5rem',
    }

    return (
        <Box component="span" sx={{ ...statusBadgeStyle }}>
            {orderState === 'AWAITING_SIGNATURE' && <i className="ri-pencil-line orange" />}
            {orderState === 'AWAITING_PAYMENT_CONFIRMATION' && <i className="ri-coin-line orange" />}
            {orderState === 'AWAITING_PAYMENT' && <i className="ri-time-line orange" />}
            {orderState === 'IN_PROGRESS' && <i className="ri-time-line orange" />}
            {orderState === 'EXECUTING_IN_MARKET' && <i className="ri-time-line orange" />}
            {orderState === 'TRANSFERRING_MONEY' && <i className="ri-hand-coin-line orange" />}
            {orderState === 'COMPLETED' && <i className="ri-checkbox-circle-line green" />}
            {orderState === 'CANCELLED' && <i className="ri-close-circle-line red" />}
            {t(fundOrderStateTMap[orderState])}
        </Box>
    )
}

const OrderDetails = function ({ order, orderType, groupState }: OrderDetailsProps) {
    const { t } = useTranslation()
    const userFullNameMap = useSelector((state) =>
        Object.assign({}, ...state.depositors.map((d) => d.knownUserFullNames))
    )
    const depositor = useSelector((state) => state.depositor)
    const orderDocument = useSelector((state) => state.documents[order.documentId])

    return (
        <div className={styles.orderDetails}>
            <h4>
                <span className={styles.orderDetailsHeader}>
                    <i className="ri-file-line purple" />
                    {t('components-OrderCard.orderDetails')}
                </span>
            </h4>
            <ul className={styles.keyValueList}>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.status')}</span>
                    <span data-cy="orderStatusText" className={styles.value}>
                        <OrderStatusBadge orderState={groupState} />
                    </span>
                </li>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.orderType')}</span>
                    <span data-cy="orderInfoOrderTypeText" className={styles.value}>
                        <OrderTypeBadge orderType={orderType} />
                    </span>
                </li>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.orderTime')}</span>
                    <span className={styles.value}>{DateOutput.formatDateTime(order?.created)}</span>
                </li>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.createdBy')}</span>
                    <span data-cy="orderInfoCreatedByText" className={styles.value}>
                        {userFullNameMap[order.createdBy]}
                    </span>
                </li>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.organization')}</span>
                    <span className={styles.value}>{depositor?.name}</span>
                </li>
                <li>
                    <span className={styles.key}>{t('components-OrderCard.document')}</span>
                    <span className={styles.value}>
                        <Link
                            className={styles.documentLink}
                            to={orderDocument.link}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="ri-file-line" />
                            <span>{t('components-OrderCard.orderDocument')}</span>
                        </Link>
                    </span>
                </li>
            </ul>
        </div>
    )
}

export function BuyOrderCard({ orderGroupId }: Props) {
    const { t } = useTranslation()
    const organisationCountry = useCurrentCountryCode()
    const [confirmPayment, setConfirmPayment] = useState(false)
    const fundBuyOrders = useSelector(
        (state) => state.fundBuyOrders.filter((fbo) => fbo.orderGroupId === orderGroupId) ?? []
    )
    const funds = useSelector((state) => state.funds)
    const userCanConfirmPayment = useSelector(selectors.userCanConfirmPayment)
    const orgId = useSelector((state) => state.session.association?.organisation?.id)
    const navigate = useNavigate()
    const orderAmount = fundBuyOrders.reduce((acc, o) => acc + o.amount, 0)
    const { confirmFundBuyOrderPayment } = useCommand()
    const Currency = useCurrencyOutput()
    const orderDocuments = useSelector((state) => fundBuyOrders.map((fbo) => state.documents[fbo.documentId]))
    const [clientAccount, setClientAccount] = useState(undefined)
    const signatureInstructions = useSignableDocumentListInstruction(orderDocuments)
    const orderDocumentSignatureStatus = {
        signedByUser: orderDocuments.every((doc) => doc?.signedByUser),
        signedByAny: orderDocuments.some((doc) => doc?.signedByAny),
    }
    const depositor = useCurrentDepositor()
    const { getTokenForFixrateCapital } = useEndpoint()
    const sampleBuyOrder = fundBuyOrders[0]
    const usingFundPortfolioClientAccount = sampleBuyOrder.depositorFundPortfolioClientAccount
    const currency = funds
        .flatMap((fund) => fund?.fundShareClasses)
        ?.find((fsc) => fsc.id === sampleBuyOrder.fundShareClassId)?.currency
    const clientAccountName = t('components-OrderCard.fcClientAccount', {
        currency: currency !== 'NOK' && currency != null ? ' ' + currency : '',
    })

    useEffect(() => {
        const acc = usingFundPortfolioClientAccount
            ? (() => {
                  const settlementAccount = depositor.settlementAccounts.find(
                      (sa) => sa.account === sampleBuyOrder.accountNumber
                  )
                  return {
                      accountNumber: settlementAccount.account,
                      name: settlementAccount.name,
                      fundPortfolioClientAccount: true,
                  }
              })()
            : {
                  accountNumber: sampleBuyOrder.accountNumber,
                  name: clientAccountName,
                  fundPortfolioClientAccount: false,
              }
        setClientAccount(acc)
    }, [depositor.settlementAccounts, sampleBuyOrder, usingFundPortfolioClientAccount, clientAccountName])

    const calculateOrderProgress = () => {
        const progress = fundBuyOrders.map((order) => order.state)
        if (progress.indexOf('AWAITING_SIGNATURE') !== -1) {
            return 'AWAITING_SIGNATURE'
        }
        if (progress.indexOf('AWAITING_PAYMENT') !== -1) {
            return 'AWAITING_PAYMENT'
        }
        if (progress.indexOf('AWAITING_PAYMENT_CONFIRMATION') !== -1) {
            return 'AWAITING_PAYMENT_CONFIRMATION'
        }
        if (progress.indexOf('IN_PROGRESS') !== -1) {
            return 'IN_PROGRESS'
        }
        if (progress.indexOf('EXECUTING_IN_MARKET') !== -1) {
            return 'EXECUTING_IN_MARKET'
        }
        return 'COMPLETED'
    }
    const groupState = calculateOrderProgress()

    const handleConfirmPayment = async () => {
        if (confirmPayment) {
            const { waitForCommand } = await confirmFundBuyOrderPayment(fundBuyOrders.map((order) => order.id))
            await waitForCommand()
        }
    }

    async function gotoFixrateCapitalSignatureUrl() {
        const { token } = await getTokenForFixrateCapital()
        const lng = getI18n().language
        window.location.href = `${config().fixrateCapitalBaseUrl}/order/buy/confirm?lng=${lng}&customer=${depositor?.id}&externalReference=${orderGroupId}&token=${token}`
    }

    return (
        <>
            {fundBuyOrders.length === 0 && <LoadingSpinner />}
            {fundBuyOrders.length > 0 && (
                <OrderGroupProgressBar
                    className={styles.progressBar}
                    orderType={'BUY'}
                    created={fundBuyOrders[0].created}
                    groupState={groupState}
                    stateHistory={fundBuyOrders[0].stateHistory}
                />
            )}
            {fundBuyOrders.length > 0 && (
                <div className={styles.orderInfo}>
                    <div className={styles.orderWrapper}>
                        <div className={styles.fundOrder}>
                            <Box sx={{ ...orderInfoBoxStyle }}>
                                <h4>
                                    <span className={styles.fundOrderHeader}>
                                        <i className="ri-line-chart-line purple" />
                                        {t('components-OrderCard.fundOrder')}
                                    </span>
                                </h4>
                                <ul className={styles.keyValueList}>
                                    {fundBuyOrders.map((order) => {
                                        const fundShareClass = funds
                                            .find((fund) => fund.id === order.fundId)
                                            ?.fundShareClasses.find((f) => f.id === order.fundShareClassId)
                                        return fundShareClass ? (
                                            <li key={order.id}>
                                                <span className={styles.key}>
                                                    <span data-cy="fundNameText" className={styles.fundName}>
                                                        {fundShareClass.fullName + ' '}
                                                    </span>
                                                    <span data-cy="fundIsinText" className={styles.isin}>
                                                        {fundShareClass.isin}
                                                    </span>
                                                </span>
                                                <span className={styles.value}>
                                                    {Currency(order.amount, { decimals: 0 })}
                                                </span>
                                            </li>
                                        ) : (
                                            <li>
                                                <span>
                                                    {'ERROR: Fund share class definition is missing: ' +
                                                        order.fundShareClassId}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <p className={styles.sum}>
                                    <span>{t('components-OrderCard.invoicedAmount')}</span>
                                    <span>{Currency(orderAmount, { decimals: 0 })}</span>
                                </p>
                            </Box>
                            <Box sx={{ ...orderInfoBoxStyle }}>
                                <OrderDetails orderType="BUY" order={sampleBuyOrder} groupState={groupState} />
                            </Box>
                        </div>
                        {groupState === 'AWAITING_SIGNATURE' && (
                            <Stack spacing={1} alignItems={'flex-start'}>
                                <Alert severity={'warning'}>
                                    <AlertTitle>{signatureInstructions}</AlertTitle>
                                    {orderDocumentSignatureStatus.signedByAny
                                        ? t('components-OrderCard.multipleSignersInstructions')
                                        : t('components-OrderCard.mustBeSignedInstructions')}
                                </Alert>
                                <AsyncButton
                                    onClick={gotoFixrateCapitalSignatureUrl}
                                    disabled={orderDocumentSignatureStatus.signedByUser}
                                >
                                    {t('components-OrderCard.goToSigning')}
                                </AsyncButton>
                                {orderDocumentSignatureStatus.signedByUser && (
                                    <Typography variant="caption">
                                        {t('components-OrderCard.alreadySignedByUser')}
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {groupState === 'AWAITING_PAYMENT' && (
                            <div className={styles.paymentInfo}>
                                <h4>
                                    <span className={styles.paymentInfoHeader}>
                                        <i className="ri-coin-line purple" />
                                        {t('components-OrderCard.paymentInfo')}
                                    </span>
                                </h4>
                                {clientAccount?.fundPortfolioClientAccount ? (
                                    <p>{t('components-OrderCard.paymentInfoDescriptionFundPortfolioClientAccount')}</p>
                                ) : (
                                    <p>{t('components-OrderCard.paymentInfoDescription')}</p>
                                )}
                                <ul className={styles.keyValueList}>
                                    <li>
                                        <span className={styles.key}>{t('components-OrderCard.accountNumber')}</span>
                                        <span className={styles.value}>
                                            {clientAccount?.name}{' '}
                                            {formatAccount(clientAccount?.accountNumber, organisationCountry)}
                                        </span>
                                    </li>
                                    {currency !== 'NOK' && (
                                        <li>
                                            <span className={styles.key}>{t('components-OrderCard.iban')}</span>
                                            <span className={styles.value}>{sampleBuyOrder.iban}</span>
                                        </li>
                                    )}
                                    {currency !== 'NOK' && (
                                        <li>
                                            <span className={styles.key}>{t('components-OrderCard.bic')}</span>
                                            <span className={styles.value}>{sampleBuyOrder.bic}</span>
                                        </li>
                                    )}
                                    {!clientAccount?.fundPortfolioClientAccount && (
                                        <li>
                                            <span className={styles.key}>
                                                {currency !== 'NOK'
                                                    ? t('components-OrderCard.kidNumberRef')
                                                    : t('components-OrderCard.kidNumber')}
                                            </span>
                                            <span className={styles.value}>{sampleBuyOrder?.cid}</span>
                                        </li>
                                    )}
                                    <li>
                                        <span className={styles.key}>{t('components-OrderCard.invoicedAmount')}</span>
                                        <span data-cy="paymentInfoOrderAmountText" className={styles.value}>
                                            {Currency(orderAmount, { decimals: 0 })}
                                        </span>
                                    </li>
                                    <li>
                                        <div>
                                            <FormControlLabel
                                                style={{ width: '100%' }}
                                                control={
                                                    <Checkbox
                                                        disabled={!userCanConfirmPayment}
                                                        data-cy="paymentIsDoneCheckbox"
                                                        checked={confirmPayment}
                                                        onChange={(e, newVal) => setConfirmPayment(newVal)}
                                                        name="wantCake"
                                                    />
                                                }
                                                label={
                                                    clientAccount?.fundPortfolioClientAccount
                                                        ? t('components-OrderCard.requiredAmountIsReadyForTransfer')
                                                        : t('pages-inbox.fundReadyForPaymentTaskCheckboxPledge')
                                                }
                                            />
                                            <Button
                                                data-cy="paymentIsDoneButton"
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={handleConfirmPayment}
                                                disabled={!userCanConfirmPayment}
                                            >
                                                {t('pages-inbox.fundReadyForPaymentTaskCheckboxButton')}
                                            </Button>
                                        </div>
                                    </li>
                                    {!userCanConfirmPayment && (
                                        <li>
                                            <Alert
                                                severity="warning"
                                                action={
                                                    <Button
                                                        sx={{ whiteSpace: 'nowrap' }}
                                                        variant="outlined"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() =>
                                                            navigate('/organizations/' + orgId + '/users/roles')
                                                        }
                                                    >
                                                        {t('components-ShoppingCart.goToRoles')}
                                                    </Button>
                                                }
                                            >
                                                {t('components-ShoppingCart.userMissingPaymentPermission')}
                                            </Alert>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export function SellOrderCard({ orderGroupId }: Props) {
    const { t } = useTranslation()
    const fundSellOrders = useSelector(
        (state) => state.fundSellOrders.filter((fso) => fso.orderGroupId === orderGroupId) ?? []
    )
    const funds = useSelector((state) => state.funds)
    const Currency = useCurrencyOutput()
    const orderDocuments = useSelector((state) => fundSellOrders.map((fbo) => state.documents[fbo.documentId]))
    const signatureInstructions = useSignableDocumentListInstruction(orderDocuments)
    const orderDocumentSignatureStatus = {
        signedByUser: orderDocuments.every((doc) => doc?.signedByUser),
        signedByAny: orderDocuments.some((doc) => doc?.signedByAny),
    }
    const depositor = useCurrentDepositor()
    const { getTokenForFixrateCapital } = useEndpoint()

    const getShareClass = (fundId: string, shareClassId: string) => {
        const fund = funds.find((f) => f.id === fundId)
        return fund.fundShareClasses.find((fsc) => fsc.id === shareClassId)
    }

    const estimatedAmount = fundSellOrders.reduce(
        (acc, o) => acc + o.unitQuantity * getShareClass(o.fundId, o.fundShareClassId).nav,
        0
    )

    const calculateOrderProgress = () => {
        const progress = fundSellOrders.map((order) => order.state)
        if (progress.indexOf('AWAITING_SIGNATURE') !== -1) {
            return 'AWAITING_SIGNATURE'
        }
        if (progress.indexOf('TRANSFERRING_MONEY') !== -1) {
            return 'TRANSFERRING_MONEY'
        }
        if (progress.indexOf('IN_PROGRESS') !== -1) {
            return 'IN_PROGRESS'
        }
        if (progress.indexOf('EXECUTING_IN_MARKET') !== -1) {
            return 'EXECUTING_IN_MARKET'
        }
        return 'COMPLETED'
    }
    const groupState = calculateOrderProgress()

    async function gotoFixrateCapitalSignatureUrl() {
        const { token } = await getTokenForFixrateCapital()
        const lng = getI18n().language
        console.log(
            `${config().fixrateCapitalBaseUrl}/order/sell/confirm?lng=${lng}&customer=${depositor?.id}&externalReference=${orderGroupId}&token=${token}`
        )
        //window.location.href = `${config().fixrateCapitalBaseUrl}/order/sell/confirm?lng=${lng}&customer=${depositor?.id}&externalReference=${orderGroupId}&token=${token}`
    }

    return (
        <>
            {fundSellOrders.length === 0 && <LoadingSpinner />}
            {fundSellOrders.length > 0 && (
                <OrderGroupProgressBar
                    className={styles.progressBar}
                    orderType={'SELL'}
                    created={fundSellOrders[0].created}
                    groupState={groupState}
                    stateHistory={fundSellOrders[0].stateHistory}
                />
            )}
            {fundSellOrders.length > 0 && (
                <div className={styles.orderInfo}>
                    <div className={styles.orderWrapper}>
                        <div className={styles.fundOrder}>
                            <Box sx={{ ...orderInfoBoxStyle }}>
                                <h4>
                                    <span className={styles.fundOrderHeader}>
                                        <i className="ri-line-chart-line purple" />
                                        {t('components-OrderCard.fundSale')}
                                    </span>
                                </h4>
                                <ul className={styles.keyValueList}>
                                    {fundSellOrders.map((order) => {
                                        const shareClass = getShareClass(order.fundId, order.fundShareClassId)
                                        return (
                                            <li key={order.id}>
                                                <span className={styles.key}>
                                                    <Stack>
                                                        <span data-cy="fundNameText" className={styles.fundName}>
                                                            {shareClass.fullName}
                                                        </span>
                                                        <span data-cy="fundIsinText" className={styles.isin}>
                                                            {shareClass.isin}
                                                        </span>
                                                    </Stack>
                                                </span>
                                                <span className={styles.value}>
                                                    {t('components-OrderCard.units')}:{' '}
                                                    {CurrencyOutput.formatNoCode(order.unitQuantity, 4)}
                                                </span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                <p className={styles.sum}>
                                    <span>{t('components-OrderCard.estimatedSum')}</span>
                                    <span data-cy="estimatedSaleSumText">
                                        {Currency(estimatedAmount, { decimals: 0 })}
                                    </span>
                                </p>
                            </Box>
                            <Box sx={{ ...orderInfoBoxStyle }}>
                                <OrderDetails orderType="SELL" order={fundSellOrders[0]} groupState={groupState} />
                            </Box>
                            {groupState === 'AWAITING_SIGNATURE' && (
                                <div className={styles.paymentInfo}>
                                    <h4>
                                        <span className={styles.paymentInfoHeader}>
                                            <i className="ri-pencil-line purple" />
                                            {t('components-OrderCard.missingSignature')}
                                        </span>
                                    </h4>
                                    <p>
                                        {orderDocumentSignatureStatus.signedByAny
                                            ? t('components-OrderCard.multipleSignersInstructions')
                                            : t('components-OrderCard.mustBeSignedInstructions')}
                                    </p>
                                    <p>{signatureInstructions + '.'}</p>
                                    {orderDocumentSignatureStatus.signedByUser && (
                                        <p>{t('components-OrderCard.alreadySignedByUser')}</p>
                                    )}
                                    <AsyncButton
                                        onClick={gotoFixrateCapitalSignatureUrl}
                                        disabled={orderDocumentSignatureStatus.signedByUser}
                                    >
                                        {t('components-OrderCard.goToSigning')}
                                    </AsyncButton>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
