import { Currency, InterestRateBenchmark } from '@fixrate/fixrate-query'

export function defaultBenchmark(currency: Currency): InterestRateBenchmark {
    switch (currency) {
        case 'NOK':
            return 'NIBOR_3M'
        case 'SEK':
            return 'STIBOR_3M'
        case 'USD':
            return 'SOFR'
        case 'EUR':
            return 'EURIBOR_3M'
        case 'CHF':
            return 'SARON_3M'
        default:
            return 'NIBOR_3M'
    }
}
