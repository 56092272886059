import { copyTextToClipboard } from '#app/components/ClipboardCopyButton/ClipboardCopyButton'
import { Box, Button, InputLabel, Stack, Typography } from '@mui/material'
import { useState } from 'react'

export default function CopyPasteMessage({
    linkText,
    messageText,
    subject,
}: {
    linkText: string
    messageText: string
    subject: string
}) {
    const [messageIsCopied, setMessageIsCopied] = useState(false)

    const copyMessage = (message: string) => {
        copyTextToClipboard(message)
            .then(() => {
                setMessageIsCopied(true)
                setTimeout(() => setMessageIsCopied(false), 3000)
            })
            .catch(console.error)
    }
    return (
        <Stack spacing={2}>
            <Stack spacing={1} alignItems="flex-start">
                <Box>
                    <InputLabel sx={{ mb: 0.5 }}>Forslag til meldingstekst</InputLabel>
                    <Typography variant="labelDescription">
                        Her er en meldingstekst som du kan kopiere og sende til din kunde.
                    </Typography>
                </Box>
                <textarea
                    style={{
                        width: '100%',
                        height: '37rem',
                        border: '0.1rem solid rgba(0,0,0,0.2)',
                        backgroundColor: 'rgba(0,0,0,0.05)',
                        fontSize: '1.4rem',
                    }}
                    readOnly
                    value={messageText}
                />
                <Stack direction="row" spacing={1} mt={1}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                            (window.location.href = `mailto:?subject=${encodeURI(
                                subject
                            )}&body=${encodeURI(messageText)}`)
                        }
                        startIcon={<i className={'ri-mail-line'} />}
                    >
                        Åpne meldingen i epostklient
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => copyMessage(messageText)}
                        startIcon={<i className={messageIsCopied ? 'ri-check-line' : 'ri-file-copy-line'} />}
                    >
                        {messageIsCopied ? 'Kopiert!' : 'Kopier meldingstekst'}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}
