import { UsersListParams } from '#blocks/UsersList/UsersList'
import { useCommand } from '#command'
import { addSelfRemovingInfoMessage } from '#services/thunks/infoMessages'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

type Params = {
    userId?: string
}

export default function useBankUserListParams(): UsersListParams {
    const dispatch = useDispatch()
    const { userId } = useParams<Params>()
    const bank = useSelector(({ bank }) => bank)

    const { removeBankUser } = useCommand()

    const userInvites = useSelector(selectors.bankUserInvites)
    const users = useSelector((state) =>
        selectors.bankUsers(state).map((user) => ({
            ...user,
            authorizationDocumentId: null,
            identificationDocumentId: null,
        }))
    )
    const user = users.find((user) => user.id === userId)
    const roles = useSelector(selectors.lookupRolesForBank)

    async function removeUser(providedUserId) {
        try {
            const userToRemove = providedUserId || userId
            const { waitForCommand } = await removeBankUser(bank.id, userToRemove)
            const success = await waitForCommand()
            if (success) {
                dispatch(addSelfRemovingInfoMessage('info', 'Brukeren ble fjernet'))
            }
        } catch (err) {
            console.error(err)
        }
    }

    function isAdmin() {
        return roles.includes('BANK_ADMIN')
    }

    return {
        organisation: bank,
        users,
        user,
        userInvites,
        missingRoles: [],
        roles,
        isAdmin: isAdmin(),
        removeUser,
        basePath: '/my-bank',
    }
}
