import { BURNT_ORANGE, PURPLE, SUNSET_ORANGE } from '#app/colors/colors'
import config from '#app/config'
import { useCommand } from '#command'
import { CurrencyOutput, DateOutput, InterestOutput, Paper } from '#components'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import Modal from '#components/Modal'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import { depositDeviationStateTMap } from '#services/enumTranslationKeyMapping'
import { useFieldState } from '@fixrate/fieldstate'
import { DepositDto } from '@fixrate/fixrate-query'
import { useSelector } from '#state/useSelector'
import { Box, Button, Stack, Typography } from '@mui/material'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './DepositDetailsBankNonConformanceHandling.module.scss'
import { PossibleSourcesOfNonConformance } from './PossibleSourcesOfNonConformance'

const DEVIATION_GUIDE_DOC_URL =
    config().fixrateFrontDocumentUrl + '/dokumentpakke-bank/' + config().deviationGuideDocument

type Props = {
    deposit: DepositDto
}

export default function DepositDetailsBankNonConformanceHandling({ deposit }: Props) {
    const { t } = useTranslation()

    const [formVisible, setFormVisible] = useState(false)
    const interestRateCorrect = useFieldState(null, ({ value }) => {
        if (value === true || value === false) {
            return ''
        }
        return t('pages-portfolio-bank.missingValue')
    })
    const interestDateCorrect = useFieldState(null, ({ value }) => {
        if (value === true || value === false) {
            return ''
        }
        return t('pages-portfolio-bank.missingValue')
    })
    const interestRateConventionCorrect = useFieldState(null, ({ value }) => {
        if (value === true || value === false) {
            return ''
        }
        return t('pages-portfolio-bank.missingValue')
    })
    const additionalComment = useFieldState('')

    const depositDeviations = useSelector((state) =>
        state.depositDeviations
            .filter((deviation) => deviation.depositId === deposit.id)
            .filter((deviation) => deviation.state !== 'RESOLVED')
    )

    const { addDepositDeviationBankDetails } = useCommand()

    if (depositDeviations?.length < 1) {
        return null
    }

    const depositDeviation = depositDeviations[0] // TODO handle the case of multiple deviations
    const deviationStatus = depositDeviation.state

    const statusMessage = () => {
        if (deviationStatus === 'DETECTED') {
            return t('pages-portfolio-bank.formNeeded')
        } else if (deviationStatus === 'BANK_DETAILS_ADDED') {
            return t('pages-portfolio-bank.formProcessing')
        } else if (deviationStatus === 'WAIT_FOR_CORRECTION') {
            return t('pages-portfolio-bank.waitForCorrectionToOccur')
        }
    }

    const statusBadgeStyle = {
        backgroundColor:
            deviationStatus === 'DETECTED'
                ? BURNT_ORANGE[50]
                : deviationStatus === 'BANK_DETAILS_ADDED' || deviationStatus === 'WAIT_FOR_CORRECTION'
                  ? SUNSET_ORANGE[50]
                  : '',
        color:
            deviationStatus === 'DETECTED'
                ? BURNT_ORANGE[700]
                : deviationStatus === 'BANK_DETAILS_ADDED' || deviationStatus === 'WAIT_FOR_CORRECTION'
                  ? SUNSET_ORANGE[700]
                  : '',
        padding: '0.6rem 1.8rem',
        fontWeight: 600,
        fontSize: '1.5rem',
        marginRight: '1.2rem',
    }

    const submitForm = async () => {
        const isInterestRateCorrect = interestRateCorrect.validate()
        const isInterestDateCorrect = interestDateCorrect.validate()
        const isInterestRateConventionCorrect = interestRateConventionCorrect.validate()

        if (isInterestRateCorrect && isInterestDateCorrect && isInterestRateConventionCorrect) {
            const { waitForCommand } = await addDepositDeviationBankDetails(
                depositDeviation.id,
                interestRateCorrect.value,
                interestDateCorrect.value,
                interestRateConventionCorrect.value,
                additionalComment.value
            )
            const success = await waitForCommand()
            if (success) {
                setFormVisible(false)
            }
        }
    }

    return (
        <>
            <Paper
                className={styles.nonConformance}
                title={t('pages-portfolio-bank.nonConformanceHandling')}
                icon={'ri-alert-line'}
            >
                <h4>{t('pages-portfolio-bank.status')}</h4>
                <p>
                    <Box component="span" sx={{ ...statusBadgeStyle }}>
                        {t(depositDeviationStateTMap[deviationStatus])}
                    </Box>
                    {statusMessage()}
                </p>
                <h4>{t('pages-portfolio-bank.nonConformanceInfo')}</h4>
                <p>
                    {t('pages-portfolio-bank.nonConformanceDetails1', {
                        date: DateOutput.formatDate(depositDeviation.bankDocumentReceived),
                    })}
                </p>
                <p>{t('pages-portfolio-bank.nonConformanceDetails2')}</p>
                <ul className={styles.differenceTable}>
                    <li>
                        <span className={styles.tableHeading}>{t('pages-portfolio-bank.balanceInStatement')}</span>
                        <span className={styles.tableDescription}>
                            {deposit.currency +
                                ' ' +
                                CurrencyOutput.formatNoCode(
                                    depositDeviation.bankBalance + depositDeviation.bankAccrued
                                )}
                        </span>
                    </li>
                    <li>
                        <span className={styles.tableHeading}>{t('pages-portfolio-bank.expectedBalance')}</span>
                        <span className={styles.tableDescription}>
                            {deposit.currency +
                                ' ' +
                                CurrencyOutput.formatNoCode(
                                    depositDeviation.fixrateBalance + depositDeviation.fixrateAccrued
                                )}
                        </span>
                    </li>
                    <li>
                        <span className={styles.tableHeading}>{t('pages-portfolio-bank.difference')}</span>
                        <span className={classNames(styles.tableDescription, 'red')}>
                            {deposit.currency +
                                ' ' +
                                CurrencyOutput.formatNoCode(
                                    depositDeviation.bankBalance +
                                        depositDeviation.bankAccrued -
                                        (depositDeviation.fixrateBalance + depositDeviation.fixrateAccrued)
                                )}
                        </span>
                    </li>
                </ul>
                {depositDeviation.awaitComment ? (
                    <Stack sx={{ backgroundColor: PURPLE[50], color: PURPLE[900] }} p={'2rem'} my={1} spacing={0.5}>
                        <Typography variant="h3" color={PURPLE[800]}>
                            {t('pages-portfolio-bank.infoFromFixrateAboutDeviation')}
                        </Typography>
                        <Typography>{depositDeviation.awaitComment}</Typography>
                    </Stack>
                ) : (
                    <PossibleSourcesOfNonConformance deposit={deposit} />
                )}
                {deviationStatus === 'DETECTED' && (
                    <span className={styles.formFillout}>
                        <Button variant={'contained'} onClick={() => setFormVisible(true)}>
                            {t('pages-portfolio-bank.formFillout')}
                        </Button>
                        <i className="ri-file-download-line" />
                        <DocumentLink
                            name={t('pages-portfolio-bank.downloadNonConformanceGuide')}
                            link={DEVIATION_GUIDE_DOC_URL}
                            hideIcon={true}
                        />
                    </span>
                )}
                {deviationStatus === 'BANK_DETAILS_ADDED' && (
                    <p className={styles.formSubmittedInfo}>
                        <span className={styles.checkIcon}>
                            <i className="ri-checkbox-circle-line" />
                        </span>
                        <span>{t('pages-portfolio-bank.formSubmitted')}</span>
                    </p>
                )}
            </Paper>
            {formVisible && (
                <Modal
                    header={t('pages-portfolio-bank.formNonConformance')}
                    submitButtonText={t('pages-portfolio-bank.formSend')}
                    onSubmit={submitForm}
                    onCancel={() => setFormVisible(false)}
                >
                    <div className={styles.formBody}>
                        <p>{t('pages-portfolio-bank.formDescription')}</p>
                        {deposit.product.type === 'FLOATING' && deposit.interestRateBenchmark === 'NIBOR_3M' && (
                            <>
                                <LabeledInfo
                                    label={
                                        t('pages-portfolio-bank.marginCheck') +
                                        ' NIBOR3M + ' +
                                        InterestOutput.format(deposit.nominalInterestRate)
                                    }
                                    helpTextLabel={t('pages-portfolio-bank.marginCheckHelpText')}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            checked={interestRateCorrect.value === true}
                                            onChange={() => interestRateCorrect.setValue(true)}
                                        />
                                        {t('common.yes')}
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            checked={interestRateCorrect.value === false}
                                            onChange={() => interestRateCorrect.setValue(false)}
                                        />
                                        {t('common.no')}
                                    </label>
                                    <p className="field-error-message">
                                        {!interestRateCorrect.valid && interestRateCorrect.errorMessage}
                                    </p>
                                </LabeledInfo>
                            </>
                        )}
                        {deposit.product.type === 'FIXED' && (
                            <>
                                <LabeledInfo
                                    label={
                                        t('pages-portfolio-bank.interestCheck') +
                                        ' ' +
                                        InterestOutput.format(deposit.nominalInterestRate)
                                    }
                                    helpTextLabel={t('pages-portfolio-bank.interestCheckHelpText')}
                                >
                                    <label>
                                        <input
                                            type="radio"
                                            checked={interestRateCorrect.value === true}
                                            onChange={() => interestRateCorrect.setValue(true)}
                                        />
                                        {t('common.yes')}
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            checked={interestRateCorrect.value === false}
                                            onChange={() => interestRateCorrect.setValue(false)}
                                        />
                                        {t('common.no')}
                                    </label>
                                    <p className="field-error-message">
                                        {!interestRateCorrect.valid && interestRateCorrect.errorMessage}
                                    </p>
                                </LabeledInfo>
                            </>
                        )}
                        <LabeledInfo
                            label={
                                t('pages-portfolio-bank.firstInterestBearingDayLabel') +
                                ' ' +
                                DateOutput.formatDate(deposit.startDate)
                            }
                            helpTextLabel={t('pages-portfolio-bank.firstInterestBearingDayHelpText')}
                        >
                            <label>
                                <input
                                    type="radio"
                                    checked={interestDateCorrect.value === true}
                                    onChange={() => interestDateCorrect.setValue(true)}
                                />
                                {t('common.yes')}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    checked={interestDateCorrect.value === false}
                                    onChange={() => interestDateCorrect.setValue(false)}
                                />
                                {t('common.no')}
                            </label>
                            <p className="field-error-message">
                                {!interestDateCorrect.valid && interestDateCorrect.errorMessage}
                            </p>
                        </LabeledInfo>
                        <LabeledInfo
                            label={
                                t('pages-portfolio-bank.interestRateConventionLabel') +
                                ' ' +
                                t('enum-InterestRateConvention.' + deposit.interestRateConvention)
                            }
                            helpTextLabel={t('pages-portfolio-bank.interestRateConventionHelpText')}
                        >
                            <label>
                                <input
                                    type="radio"
                                    checked={interestRateConventionCorrect.value === true}
                                    onChange={() => interestRateConventionCorrect.setValue(true)}
                                />
                                {t('common.yes')}
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    checked={interestRateConventionCorrect.value === false}
                                    onChange={() => interestRateConventionCorrect.setValue(false)}
                                />
                                {t('common.no')}
                            </label>
                            <p className="field-error-message">
                                {!interestRateConventionCorrect.valid && interestRateConventionCorrect.errorMessage}
                            </p>
                        </LabeledInfo>
                        <p className={styles.label}>{t('pages-portfolio-bank.comments')}</p>
                        <textarea
                            value={additionalComment.value}
                            onChange={(e) => additionalComment.setValue(e.target.value)}
                        />
                    </div>
                </Modal>
            )}
        </>
    )
}
