import { CurrencyOutput, InterestOutput, LoadingSpinner } from '#app/components'
import List, { ListItem } from '#app/components/List/List'
import { FundDto, FundEsgCategory, FundShareClassDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useCurrencyOutputWithCurrency } from '#components/CurrencyOutput/useCurrencyOutput'
import { useFundMarketData } from '#blocks/FundReturnsChart/useFundMarketData'
import { useLocalizedString } from '#services/useLocalizedString'

type Ids =
    | 'totalAssets'
    | 'nav'
    | 'standardDeviation'
    | 'interestRateSensitivity'
    | 'creditSensitivity'
    | 'effectiveInterestRate'
    | '3y-return'
    | 'esg'

type FundFigure = {
    id?: Ids
    label: string
    tooltip: string
    value: string
    date: string
    disabled: boolean
    defaultHidden: boolean
    infoText?: string
}
export default function KeyFundFigures({
    fund,
    shareClass,
    figureFilter,
}: {
    fund: FundDto
    shareClass: FundShareClassDto
    figureFilter?: Ids[]
}) {
    const { t } = useTranslation()
    const ShareClassCurrency = useCurrencyOutputWithCurrency(shareClass?.currency)
    const managersExplanation = useLocalizedString(shareClass?.effectiveInterestRateManagersExplanation)
    const { data: fundMarketData, isLoading, isSuccess } = useFundMarketData(shareClass?.isin)

    const getArticleValue = (FundEsgCategory: FundEsgCategory) => {
        const articleNumber = FundEsgCategory.toString().split('_')[1]
        return t('pages-fund-details.article') + ' ' + articleNumber
    }

    if (!fund || !shareClass || isLoading || !isSuccess) {
        return <LoadingSpinner />
    }

    const fundFigures: FundFigure[] = [
        {
            id: 'totalAssets',
            label: t('pages-fund-details.assetsUnderManagement'),
            tooltip: null,
            // NB: Do not replace use of fund.currency here with shareClass.currency; the value here is the total assets of the fund, not the share class.
            // If the fund includes share classes with different currencies, the total assets will have been converted to the fund's base currency.
            value: fund.totalAssets
                ? CurrencyOutput.formatMillion(fund.totalAssets, fund.currency)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: fund.totalAssetsDate,
            disabled: !fund.totalAssets,
            defaultHidden: false,
        },
        {
            id: 'nav',
            label: t('pages-fund-details.navPrice'),
            tooltip: null,
            value: shareClass.nav
                ? ShareClassCurrency(shareClass.nav, { decimals: 4 })
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: shareClass.navDate,
            disabled: !shareClass.nav,
            defaultHidden: false,
        },
        {
            id: 'standardDeviation',
            label: t('pages-fund-details.standardDeviation3Y'),
            tooltip: null,
            value: shareClass.standardDeviation
                ? InterestOutput.format(shareClass.standardDeviation)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: shareClass.standardDeviationDate,
            disabled: !shareClass.standardDeviation,
            defaultHidden: false,
            infoText: t('pages-fund-details.standardDeviationExplanation'),
        },
        {
            id: 'interestRateSensitivity',
            label: t('pages-fund-details.interestRateSensitivity'),
            tooltip: null,
            value: fund.interestRateSensitivity
                ? CurrencyOutput.formatNoCode(fund.interestRateSensitivity)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: fund.interestRateDate,
            disabled: !fund.interestRateSensitivity,
            defaultHidden: false,
            infoText: t('pages-fund-details.interestRateSensitivityExplanation'),
        },
        {
            id: 'creditSensitivity',
            label: t('pages-fund-details.creditSensitivity'),
            tooltip: null,
            value: fund.creditSensitivity
                ? CurrencyOutput.formatNoCode(fund.creditSensitivity)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: fund.interestRateDate,
            disabled: !fund.creditSensitivity,
            defaultHidden: false,
            infoText: t('pages-fund-details.creditSensitivityExplanation'),
        },
        {
            id: 'effectiveInterestRate',
            label:
                t('pages-fund-details.effectiveInterestRate') + (figureFilter ? '' : managersExplanation ? ' *' : ''),
            tooltip: figureFilter ? managersExplanation : null,
            value: shareClass.effectiveInterestRate
                ? InterestOutput.format(shareClass.effectiveInterestRate)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: shareClass.effectiveInterestRateDate,
            disabled: !shareClass.effectiveInterestRate,
            defaultHidden: false,
        },
        {
            id: '3y-return',
            label: t('pages-fund-details.3yAnnualized'),
            tooltip: null,
            value: fundMarketData.annualReturnRates.PERIOD_3Y
                ? InterestOutput.format(fundMarketData.annualReturnRates.PERIOD_3Y)
                : t('pages-fund-details.keyNumbersEmptyState'),
            date: '',
            disabled: !fundMarketData.annualReturnRates.PERIOD_3Y,
            defaultHidden: true,
        },
        {
            id: 'esg',
            label: t('pages-fund-details.sustainability'),
            tooltip: null,
            value: fund.esgCategory ? getArticleValue(fund.esgCategory) : t('pages-fund-details.keyNumbersEmptyState'),
            date: '',
            disabled: !fund.esgCategory,
            defaultHidden: true,
        },
    ]

    const filteredFigures = figureFilter
        ? fundFigures.filter((figure) => figureFilter.includes(figure.id as Ids))
        : fundFigures.filter((figure) => !figure.defaultHidden)

    return (
        <List>
            {filteredFigures.map((figure, index) => (
                <ListItem key={index} {...figure} />
            ))}
        </List>
    )
}
