import { useCallback, useState } from 'react'
import { ButtonRow } from '#components'
import { useTranslation } from 'react-i18next'
import { FundCustomerDto, FundCustomerRiskClass, RiskMitigationMeasure } from '@fixrate/fixrate-query'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'
import format from 'date-fns/format'
import DateInput from '#app/components/DateInput'
import { endOfDay, isBefore } from 'date-fns'

type Props = {
    readonly customer: FundCustomerDto
}

const RiskSelect = ({ value, onChange }: { value: string; onChange: (event: SelectChangeEvent) => void }) => {
    const { t } = useTranslation()
    const risks: FundCustomerRiskClass[] = ['LOW_RISK', 'MEDIUM_RISK', 'HIGH_RISK']
    const changeRisk = (event: SelectChangeEvent) => onChange(event)

    return (
        <Select sx={{ mb: 2 }} id="customer-risk-select" value={value} onChange={changeRisk}>
            {risks.map((risk) => (
                <MenuItem key={risk} value={risk}>
                    {t(`enum-RiskLevel.${risk}`)}
                </MenuItem>
            ))}
        </Select>
    )
}

const RiskMitigationSelect = ({ value, onChange }: { value: string; onChange: (event: SelectChangeEvent) => void }) => {
    const { t } = useTranslation()
    const riskMitigations: RiskMitigationMeasure[] = ['GENERAL', 'ENHANCED']
    const changeRiskMitigation = (event: SelectChangeEvent) => onChange(event)

    return (
        <Select sx={{ mb: 2 }} id="customer-risk-mitigation-select" value={value} onChange={changeRiskMitigation}>
            {riskMitigations.map((mitigation) => (
                <MenuItem key={mitigation} value={mitigation}>
                    {t(`enum-RiskMitigationLevel.${mitigation}`)}
                </MenuItem>
            ))}
        </Select>
    )
}

export default function FundCustomerFollowUp({ customer }: Props) {
    const { t } = useTranslation()
    const { updateDepositorFundCustomerRisk, updateDepositorFundCustomerFollowUp } = useCommand()
    const [customerRisk, setCustomerRisk] = useState<FundCustomerRiskClass>(
        customer.fundCustomerFollowUp.fundCustomerRiskClass
    )
    const [riskMitigation, setRiskMitigation] = useState<RiskMitigationMeasure>(
        customer.fundCustomerFollowUp.riskMitigationMeasure
    )

    const followUpDateValidator = useCallback(
        ({ value }) => {
            if (isBefore(endOfDay(value), new Date())) {
                return t('components-DateInput.mustBeInFuture')
            }
        },
        [t]
    )

    const followUpDate = useFieldState<Date>(
        customer.fundCustomerFollowUp.followUpDate ? new Date(customer.fundCustomerFollowUp.followUpDate) : null,
        followUpDateValidator
    )
    const [submittingRisk, setSubmittingRisk] = useState(false)
    const [submittingFollowUp, setSubmittingFollowUp] = useState(false)
    const [touchedRisk, setTouchedRisk] = useState(false)
    const [touchedFollowUp, setTouchedFollowUp] = useState(false)

    async function submitRisk() {
        const isValid = followUpDate.validate()
        if (!isValid) return

        setSubmittingRisk(true)
        const { waitForCommand } = await updateDepositorFundCustomerRisk(
            customer.depositorId,
            customerRisk,
            riskMitigation
        )
        const success = await waitForCommand()
        setSubmittingRisk(false)

        if (success) {
            setTouchedRisk(false)
        }
    }

    async function submitFollowUp() {
        const isValid = followUpDate.validate()

        if (!isValid) return

        setSubmittingFollowUp(true)
        const followUpDateValue = format(followUpDate.value, 'yyyy-MM-dd')
        const { waitForCommand } = await updateDepositorFundCustomerFollowUp(customer.depositorId, followUpDateValue)
        const success = await waitForCommand()
        setSubmittingFollowUp(false)

        if (success) {
            setTouchedFollowUp(false)
        }
    }

    function resetRisk() {
        setCustomerRisk(customer.fundCustomerFollowUp.fundCustomerRiskClass)
        setRiskMitigation(customer.fundCustomerFollowUp.riskMitigationMeasure)
        setTouchedRisk(false)
    }

    function resetFollowUp() {
        followUpDate.reset()
        setTouchedFollowUp(false)
    }

    function changeRisk(event: SelectChangeEvent) {
        setCustomerRisk(event.target.value as FundCustomerRiskClass)
        setTouchedRisk(true)
    }

    function changeRiskMitigation(event: SelectChangeEvent) {
        setRiskMitigation(event.target.value as RiskMitigationMeasure)
        setTouchedRisk(true)
    }

    return (
        <>
            <Stack direction="row" spacing={2}>
                <Box>
                    <Typography>{t('pages.fundCustomers.customerDetail.customerInfo.risk')}</Typography>
                    <RiskSelect value={customerRisk} onChange={changeRisk} />
                </Box>
                <Box>
                    <Typography>{t('pages.fundCustomers.customerDetail.customerInfo.riskMitigation')}</Typography>
                    <RiskMitigationSelect value={riskMitigation} onChange={changeRiskMitigation} />
                </Box>
            </Stack>
            <Box
                sx={{
                    mb: 3,
                    transformOrigin: 'top',
                    transform: `scaleY(${touchedRisk ? 1 : 0})`,
                    transition: 'transform 0.2s',
                }}
            >
                <ButtonRow align={'left'}>
                    <LoadingButton
                        size={'small'}
                        onClick={submitRisk}
                        variant={'contained'}
                        loading={submittingRisk}
                        disabled={submittingRisk}
                    >
                        {t('common.save')}
                    </LoadingButton>
                    <Button size={'small'} variant={'outlined'} onClick={resetRisk} disabled={submittingRisk}>
                        {t('common.cancel')}
                    </Button>
                </ButtonRow>
            </Box>
            <Stack direction="row" spacing={2}>
                <Box>
                    <Typography>{t('pages.fundCustomers.customerDetail.customerInfo.followUpDate')}</Typography>
                    <DateInput
                        id="followUpDatePicker"
                        value={followUpDate.value}
                        onBlur={followUpDate.onBlur}
                        onChange={(e) => {
                            followUpDate.setValue(e)
                            setTouchedFollowUp(true)
                        }}
                    />
                    {followUpDate.errorMessage && (
                        <Typography sx={{ color: 'error' }}>{followUpDate.errorMessage}</Typography>
                    )}
                </Box>
            </Stack>
            <Box
                sx={{
                    mb: 2,
                    transformOrigin: 'top',
                    transform: `scaleY(${touchedFollowUp ? 1 : 0})`,
                    transition: 'transform 0.2s',
                }}
            >
                <ButtonRow align={'left'}>
                    <LoadingButton
                        size={'small'}
                        onClick={submitFollowUp}
                        variant={'contained'}
                        loading={submittingFollowUp}
                        disabled={submittingFollowUp}
                    >
                        {t('common.save')}
                    </LoadingButton>
                    <Button size={'small'} variant={'outlined'} onClick={resetFollowUp} disabled={submittingFollowUp}>
                        {t('common.cancel')}
                    </Button>
                </ButtonRow>
            </Box>
        </>
    )
}
