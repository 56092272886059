import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import { InterestOutput } from '#components'
import { BankLimitedDto } from '@fixrate/fixrate-query'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Grid, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
    isDepositorAndHasOrganisation: boolean
    bank: BankLimitedDto
}

type Fact = {
    label: string
    value: string
    visible: boolean
}

export default function BankDetails({ isDepositorAndHasOrganisation, bank }: Props) {
    const { t } = useTranslation()

    const hasOfficialRatingPremiumProduct = useSelector(selectors.isDepositorWithOfficialRating)
    const officialRatingVisible = !!(hasOfficialRatingPremiumProduct && bank.officialRating)

    const facts: Fact[] = [
        {
            label: t('pages-marketplace.bankType'),
            value: bank.bankType,
            visible: true,
        },
        {
            label: t('pages-marketplace.totalAssets'),
            value: `${CurrencyOutputObject(bank.totalAssets, { withCurrency: false })} ${t('pages-marketplace.million')} kr`,
            visible: true,
        },
        {
            label: t('pages-marketplace.depositCoverage'),
            value: bank.depositCoverage && InterestOutput.format(bank.depositCoverage),
            visible: true,
        },
        {
            label: t('pages-marketplace.coreCapital'),
            value: bank.coreCapital && InterestOutput.format(bank.coreCapital),
            visible: true,
        },
        {
            label: t('pages-marketplace.officialRating'),
            value: bank.officialRating && `${bank.officialRating} (${bank.ratingAgency})`,
            visible: officialRatingVisible,
        },
    ]

    const getYear = (infoYear: string): string => {
        if (infoYear.includes('Q')) {
            return infoYear.slice(0, -2) // Remove the last two characters
        }
        return infoYear
    }

    if (isDepositorAndHasOrganisation) {
        return (
            <section>
                <Grid container spacing={2} mb={3}>
                    {facts.map(
                        (fact, index) =>
                            fact.visible && (
                                <Grid key={index} item xs={12} md={6}>
                                    <Stack flex="1">
                                        <Typography variant={'body2'}>{fact.label}</Typography>
                                        <Typography variant={'h4'} mt={0.2}>
                                            {fact.value}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )
                    )}
                    {bank.bankPresentationPublic && (
                        <Grid item xs={12} md={6}>
                            <Link
                                href={`/bank/${bank.id}?context=${window.location.pathname}`}
                                sx={{ fontSize: '1.4rem' }}
                                alignItems={'center'}
                                display={'inline-flex'}
                            >
                                <span>{t('pages-marketplace.readMoreAboutBank')}</span>
                                <i className="ri-arrow-right-line" />
                            </Link>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <Typography fontSize={'1.2rem'} variant={'body2'}>
                            {t('pages-marketplace.keyFiguresUpdatedAt')}{' '}
                            {bank.infoYear ? getYear(bank.infoYear) : '2017'}
                        </Typography>
                        {officialRatingVisible &&
                            (() => {
                                const officialRatingDate = bank.officialRatingDate
                                    ? new Date(bank.officialRatingDate)
                                    : null
                                const monthNames =
                                    bank.regulatoryRegion === 'SE'
                                        ? [
                                              'januari',
                                              'februari',
                                              'mars',
                                              'april',
                                              'maj',
                                              'juni',
                                              'juli',
                                              'augusti',
                                              'september',
                                              'oktober',
                                              'november',
                                              'december',
                                          ]
                                        : [
                                              'januar',
                                              'februar',
                                              'mars',
                                              'april',
                                              'mai',
                                              'juni',
                                              'juli',
                                              'august',
                                              'september',
                                              'oktober',
                                              'november',
                                              'desember',
                                          ]
                                return (
                                    <Typography fontSize={'1.2rem'} variant={'body2'}>
                                        {t('pages-marketplace.officialRatingUpdatedAt')}{' '}
                                        {officialRatingDate
                                            ? monthNames[officialRatingDate.getMonth()] +
                                              ' ' +
                                              officialRatingDate.getFullYear()
                                            : '2017'}
                                    </Typography>
                                )
                            })()}
                    </Grid>
                </Grid>
            </section>
        )
    }

    return null
}
