import { Table, TableCell, TableHeader, TableHeaderCell, TableRow } from '#components'
import Button from '#components/Button'
import ButtonRow from '#components/ButtonRow'
import { useSelector } from '#state/useSelector'
import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { StepProps } from '../types'
import Banks from './Banks'
import styles from './Steps.module.css'

type Params = {
    depositorOfferId?: string
    modal?: 'banks'
}

export default function Step2({ onPrevious, onNext, fields }: StepProps) {
    const { t } = useTranslation()
    const params = useParams<Params>()
    const navigate = useNavigate()

    const banks = useSelector((state) => state.banks)

    const selectedBanks = useMemo(() => {
        return [...(fields.banks.value || [])].sort(
            (a, b) => -(banks[a.bankId].totalAssets - banks[b.bankId].totalAssets)
        )
    }, [banks, fields.banks.value])

    const emptyTableMessage =
        selectedBanks.length === 0 ? (
            <TableRow key={'none'}>
                <TableCell className={styles.emptyTableMessage}>{t('pages-offer.wizardStep2SelectBanks')}</TableCell>
                <TableCell />
                <TableCell />
            </TableRow>
        ) : null

    const bankList = (
        <Table>
            <TableHeader>
                <TableHeaderCell>{t('pages-offer.bank')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-offer.minAmount')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-offer.maxAmount')}</TableHeaderCell>
            </TableHeader>
            {emptyTableMessage ??
                fields.banks.value
                    .map((bank) => ({ ...bank, name: banks[bank.bankId].name }))
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((bank) => (
                        <TableRow key={bank.bankId}>
                            <TableCell>{bank.name}</TableCell>
                            <TableCell align={'right'}>
                                {bank.minVolume || fields.minVolume.value} {t('pages-offer.mill')}
                            </TableCell>
                            <TableCell align={'right'}>
                                {bank.maxVolume || fields.maxVolume.value} {t('pages-offer.mill')}
                            </TableCell>
                        </TableRow>
                    ))}
        </Table>
    )

    function showModal() {
        navigate(`/depositoroffer/${params.depositorOfferId}/edit/2/banks`)
    }

    const modal = params.modal === 'banks' && <Banks fields={fields} />

    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep2Message')}</p>
            <div className={styles.bankListSection}>
                {bankList}
                <ButtonRow align={'right'}>
                    <Button id="editBanksButton" variant={'secondary'} onClick={showModal}>
                        {t('pages-offer.wizardStep2AddOrRemoveBanks')}
                    </Button>
                </ButtonRow>
            </div>
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>
                    {t('common.previous')}
                </Button>
                <Button id="continueButton" onClick={onNext}>
                    {t('common.continue')}
                </Button>
            </ButtonRow>
            {modal}
        </Fragment>
    )
}
