import { CHART_COLORS } from '#app/colors/colors'
import { InterestOutput } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { Box, SxProps } from '@mui/material'
import { Chart, ChartDataset, ChartOptions } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { useRef } from 'react'
import merge from 'lodash/merge'

interface DoughnutChartProps {
    chartData: {
        datasets: ChartDataset[]
        labels?: string[]
    }
    sx?: SxProps
    exludeNames?: string[]
    colors?: string[]
    valueType?: 'interest' | 'currency'
    formatFn?: (value: number | string) => string
    options?: ChartOptions
}

const DoughnutChart = ({ chartData, sx, colors, valueType, formatFn, options }: DoughnutChartProps) => {
    const Currency = useCurrencyOutput()
    const chartRef = useRef<Chart | null>(null)
    const chartColors = colors ? [...colors, ...CHART_COLORS] : CHART_COLORS

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        if (!canvas) return

        const context = canvas.getContext('2d')
        const numberWithAnnotation = (value: number | string) => {
            if (formatFn) {
                return formatFn(value)
            }
            const val = typeof value === 'number' ? value : parseFloat(value)
            if (valueType === 'currency') {
                return Currency(val, { decimals: 0 })
            }
            return InterestOutput.format(val)
        }

        if (context && !chartRef.current) {
            chartRef.current?.destroy()
            chartRef.current = new Chart(context, {
                type: 'doughnut',
                data: {
                    datasets: chartData.datasets.map((dataset, i) => ({
                        borderColor: chartColors,
                        backgroundColor: chartColors,
                        hoverBackgroundColor: chartColors.map((c) => c + '80'),
                        hoverBorderColor: chartColors,
                        ...dataset,
                    })),
                    labels: chartData.labels,
                },
                options: merge(
                    {
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        return `${context.label}: ${numberWithAnnotation(context.parsed)}`
                                    },
                                },
                            },
                        },
                        onResize: (chart, size) => {
                            if (size.height < 300) {
                                chart.options.maintainAspectRatio = false
                            }
                        },
                    },
                    options
                ),
            })
        }
    }

    return (
        <Box sx={sx}>
            <Box ref={canvasCallback} component="canvas" maxHeight={'100%'} />
        </Box>
    )
}

export { DoughnutChart }
