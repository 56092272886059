import styles from '#pages/FundMarketplace/FundMarketplace.module.scss'
import { fundMorningstarCategoryTMap, fundVffCategoryTMap } from '#services/enumTranslationKeyMapping'
import Slider from '#components/Slider/Slider'
import { CurrencyOutput } from '#components'
import ToggleSwitch from '#components/ToggleSwitch'
import { useTranslation } from 'react-i18next'
import { FundCreditDurationCategory, FundDto } from '@fixrate/fixrate-query'
import { useTracking } from '#services/useTracking'
import { Button, Stack } from '@mui/material'
import { FundFilterStorage } from '#pages/FundMarketplace/useFundFilterStorage'
import usePortfolio from '#app/services/usePortfolio'

type Props = {
    funds: FundDto[]
    filterStorage: FundFilterStorage
    onClose: () => void
}

export default function FilterPanel({ funds, filterStorage, onClose }: Props) {
    const { t } = useTranslation()
    const { trackEvent, trackEventOnce } = useTracking({ page: 'FundMarketplace' })
    const portfolio = usePortfolio()

    const highestAum = Math.max(...funds.map((fund) => fund.totalAssets))
    const morningstarCategories = [
        ...new Set(funds.filter((f) => f.morningstarCategory).map((fund) => fund.morningstarCategory)),
    ]
    const vffCategories = [...new Set(funds.filter((f) => f.vffCategory).map((fund) => fund.vffCategory))]

    const filteredFunds = funds.filter((fund) => filterStorage.filters.every((filterFn) => filterFn(fund)))

    return (
        <Stack spacing={2} px={2} py={2} width={'60rem'} maxWidth={'100%'} className={styles.filterPopUp}>
            <h2>{t('pages.fundMarketplace.filter')}</h2>
            <section>
                <h4>{t('pages.fundMarketplace.morningstarCategories')}</h4>
                <ul>
                    {morningstarCategories.map((category) => (
                        <li
                            data-cy={'fund-filter-category'}
                            data-category={fundMorningstarCategoryTMap[category]}
                            key={category}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    checked={filterStorage.morningStarCategories.includes(category)}
                                    onChange={() => {
                                        if (filterStorage.morningStarCategories.includes(category)) {
                                            filterStorage.setMorningStarCategories(
                                                filterStorage.morningStarCategories.filter((c) => c !== category)
                                            )
                                        } else {
                                            filterStorage.setMorningStarCategories([
                                                ...filterStorage.morningStarCategories,
                                                category,
                                            ])
                                        }
                                        trackEvent({ event: 'morningstarCategoryFilterUsed', value: category })
                                    }}
                                />
                                <span>{t(fundMorningstarCategoryTMap[category])}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </section>
            <section>
                <h4>{t('pages.fundMarketplace.vffCategories')}</h4>
                <ul>
                    {vffCategories.map((category) => (
                        <li
                            data-cy={'fundFilterVffCategory'}
                            data-category={fundVffCategoryTMap[category]}
                            key={category}
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    checked={filterStorage.vffCategories.includes(category)}
                                    onChange={() => {
                                        if (filterStorage.vffCategories.includes(category)) {
                                            filterStorage.setVffCategories(
                                                filterStorage.vffCategories.filter((c) => c !== category)
                                            )
                                        } else {
                                            filterStorage.setVffCategories([...filterStorage.vffCategories, category])
                                        }
                                        trackEvent({ event: 'vffCategoryFilterUsed', value: category })
                                    }}
                                />
                                <span>{t(fundVffCategoryTMap[category])}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </section>
            <section className={styles.filterEsg}>
                <h4>{t('pages.fundMarketplace.sustainabilityClassification')}</h4>
                <div className={styles.esgList}>
                    <label className={styles.yellow}>
                        <input
                            type="checkbox"
                            checked={filterStorage.esgFilter['ARTICLE_6']}
                            onChange={(e) => {
                                filterStorage.setEsgFilter({ ...filterStorage.esgFilter, ARTICLE_6: e.target.checked })
                                trackEvent({ event: 'esgFilterUsed', value: 'ARTICLE_6' })
                            }}
                        />
                        <span>
                            {t('pages.fundMarketplace.article')} <span className={styles.articleNumber}>6</span>
                        </span>
                    </label>
                    <label className={styles.lightGreen}>
                        <input
                            type="checkbox"
                            checked={filterStorage.esgFilter['ARTICLE_8']}
                            onChange={(e) => {
                                filterStorage.setEsgFilter({ ...filterStorage.esgFilter, ARTICLE_8: e.target.checked })
                                trackEvent({ event: 'esgFilterUsed', value: 'ARTICLE_8' })
                            }}
                        />
                        <span>
                            {t('pages.fundMarketplace.article')} <span className={styles.articleNumber}>8</span>
                        </span>
                    </label>
                    <label className={styles.darkGreen}>
                        <input
                            type="checkbox"
                            checked={filterStorage.esgFilter['ARTICLE_9']}
                            onChange={(e) => {
                                filterStorage.setEsgFilter({ ...filterStorage.esgFilter, ARTICLE_9: e.target.checked })
                                trackEvent({ event: 'esgFilterUsed', value: 'ARTICLE_9' })
                            }}
                        />
                        <span>
                            {t('pages.fundMarketplace.article')} <span className={styles.articleNumber}>9</span>
                        </span>
                    </label>
                </div>
            </section>
            <section>
                <h4>{t('pages.fundMarketplace.minTotalAssets')}</h4>
                <Slider
                    sliderText={''}
                    value={filterStorage.filterMinimumAum}
                    min={0}
                    max={highestAum}
                    showValueText={true}
                    step={100_000_000}
                    onChange={(e) => {
                        filterStorage.setFilterMinimumAum(+e.target.value)
                        trackEventOnce({ event: 'fundTotalAssetsSliderUsed' })
                    }}
                    formatFunc={(v) => CurrencyOutput.formatMillion(v, portfolio?.currency ?? 'NOK')}
                />
            </section>
            <section>
                <h4>{t('pages.fundMarketplace.creditDuration')}</h4>
                <ul>
                    <li>
                        <label>
                            <input
                                type="radio"
                                name="creditDuration"
                                value={''}
                                checked={!filterStorage.filterCreditDurationCategory}
                                onChange={(e) => {
                                    filterStorage.setFilterCreditDurationCategory(e.target.value as '')
                                    trackEvent({ event: 'fundCreditDurationFilterUsed', value: e.target.value })
                                }}
                            />
                            <span>{t('pages.fundMarketplace.all')}</span>
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                data-cy="fund-filter-creditDurationUnderLimit"
                                type="radio"
                                name="creditDuration"
                                value={'BELOW_ONE_AND_A_HALF_YEARS'}
                                checked={filterStorage.filterCreditDurationCategory === 'BELOW_ONE_AND_A_HALF_YEARS'}
                                onChange={(e) => {
                                    filterStorage.setFilterCreditDurationCategory(
                                        e.target.value as FundCreditDurationCategory
                                    )
                                    trackEvent({ event: 'fundCreditDurationFilterUsed', value: e.target.value })
                                }}
                            />
                            <span>{t('pages.fundMarketplace.maxYears')}</span>
                        </label>
                    </li>
                </ul>
            </section>
            <section>
                <h4>{t('pages-fund-details.advancedFilters')}</h4>
                <ul>
                    <li>
                        <div className={styles.switchFilter}>
                            <ToggleSwitch
                                dataCy="fund-filter-omf"
                                className={styles.smallSwitch}
                                checked={filterStorage.filterOmf}
                                onChange={() => {
                                    filterStorage.setFilterOmf(!filterStorage.filterOmf)
                                    trackEvent({ event: 'fundOmfFilterUsed', value: !filterStorage.filterOmf })
                                }}
                            />
                            <span>{t('pages-fund-details.onlyOMFFunds')}</span>
                        </div>
                    </li>
                    <li>
                        <div className={styles.switchFilter}>
                            <ToggleSwitch
                                dataCy="fund-filter-subOrdinatedLoans"
                                className={styles.smallSwitch}
                                checked={filterStorage.filterSubOrdinatedLoans}
                                onChange={() => {
                                    filterStorage.setFilterSubOrdinatedLoans(!filterStorage.filterSubOrdinatedLoans)
                                    trackEvent({
                                        event: 'fundSubOrdinatedLoansFilterUsed',
                                        value: !filterStorage.filterSubOrdinatedLoans,
                                    })
                                }}
                            />
                            <span>{t('pages-fund-details.hideSubordinatedLoans')}</span>
                        </div>
                    </li>
                </ul>
            </section>
            <p className="field-info-message">{filteredFunds.length === 0 && t('pages-fund-details.noFilterMatch')}</p>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Button
                    data-cy="fund-filter-close"
                    variant={'outlined'}
                    size={'medium'}
                    color={'secondary'}
                    onClick={onClose}
                >
                    {t('common.close')}
                </Button>
                <Button
                    data-cy="fund-filter-reset"
                    variant={'outlined'}
                    size={'medium'}
                    color={'inherit'}
                    disabled={filterStorage.filters.length === 0}
                    startIcon={<i className="ri-refresh-line" />}
                    onClick={() => {
                        filterStorage.reset()
                        trackEvent({ event: 'resetFilters' })
                    }}
                >
                    {t('pages-fund-details.resetFilter')}
                </Button>
            </Stack>
        </Stack>
    )
}
