import { Fragment } from 'react'
import styles from './FundPlacements.module.scss'
import FundPlacementAccordion from './FundPlacementAccordion'
import { LinkButton } from '#app/components'
import FundOrders from './FundOrders'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Stack, Tab, Tabs, Tooltip } from '@mui/material'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { FundPortfolioRiskTable } from '#pages/portfolio-depositor/FundPlacements/FundPortfolioRisk/FundPortfolioRisk'
import { LoadingSpinner } from '#components'
import { useNavigate, useParams } from 'react-router-dom'
import { fundPlacementsWithMissingDividendsFromLastYearSelector } from '#state/selectors'

type FundTab = '' | 'risk' | 'orders'

type Params = {
    fundTab?: FundTab | string
}
export default function FundPlacements() {
    const { t } = useTranslation()
    const isLoaded = useSelector((state) => state.loaded['fundPlacements'])
    const fundPlacements = useSelector((state) =>
        state.fundPlacements.filter((placement) => placement.unitQuantity !== 0)
    )
    const fundBuyOrders = useSelector((state) => state.fundBuyOrders.filter((order) => order.state !== 'COMPLETED'))
    const fundSellOrders = useSelector((state) => state.fundSellOrders.filter((order) => order.state !== 'COMPLETED'))

    const navigate = useNavigate()
    const { fundTab } = useParams<Params>()
    const selectedTab = fundTab === 'risk' || fundTab === 'orders' ? fundTab : ''

    if (!isLoaded) {
        return <LoadingSpinner />
    }

    if (fundPlacements.length > 0 || fundBuyOrders.length > 0 || fundSellOrders.length > 0) {
        return (
            <Fragment>
                <Box sx={{ mb: 3 }}>
                    <Tabs
                        color="secondary"
                        value={selectedTab}
                        sx={{
                            backgroundColor: 'white',
                        }}
                        onChange={(_event, newValue) => navigate(`/portfolio/overview/funds/${newValue}`)}
                    >
                        <Tab
                            sx={{
                                fontSize: {
                                    xs: '1.2rem',
                                    sm: '1.4rem',
                                },
                                px: { xs: 0, sm: 2 },
                            }}
                            data-cy="fundOverviewTab"
                            value=""
                            label={t('pages-FundOverview.yourFundPortfolio')}
                        />
                        <Tab
                            sx={{
                                fontSize: {
                                    xs: '1.2rem',
                                    sm: '1.4rem',
                                },
                                px: { xs: 0, sm: 2 },
                            }}
                            data-cy="fundRiskTab"
                            value="risk"
                            label={t('pages-FundOverview.risk')}
                        />
                        <Tab
                            sx={{
                                fontSize: {
                                    xs: '1.2rem',
                                    sm: '1.4rem',
                                },
                                px: { xs: 0, sm: 2 },
                            }}
                            data-cy="fundOrdersTab"
                            value="orders"
                            label={t('pages-FundOverview.tradeOrders')}
                        />
                    </Tabs>
                </Box>

                <div className={styles.myFunds}>
                    {selectedTab === '' && (
                        <Box>
                            <FundOverview />
                            <Box
                                sx={{
                                    mt: 4,
                                    fontSize: '1.2rem',
                                    maxWidth: '120rem',
                                }}
                            >
                                {'*' + t('pages-FundOverview.returnsExplanation')}
                            </Box>
                        </Box>
                    )}
                    {selectedTab === 'risk' && <FundPortfolioRiskTable />}
                    {selectedTab === 'orders' && (
                        <FundOrders
                            hideCompleted={true}
                            showTitle={true}
                            showEmptyMessage={true}
                            emptyMessage={t('pages-fund-order-overview.emptyOngoingOrders')}
                        />
                    )}
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <p className={styles.emptyMessage}>{t('pages-FundOverview.noFundsInPortfolio')}</p>
            <LinkButton className={styles.goToMarketplaceButton} to={'/marketplace/category/funds/money-market-fund'}>
                {t('pages-FundOverview.goToFundMarketplace')}
            </LinkButton>
        </Fragment>
    )
}

function FundOverview() {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const fundPlacements = useSelector((state) =>
        state.fundPlacements.filter((placement) => placement.placementParts.length > 0)
    )
    const fundPlacementsWithMissingDividends = useSelector(fundPlacementsWithMissingDividendsFromLastYearSelector)
    const totalFundValue = fundPlacements.reduce((acc, fp) => acc + fp.currentValue, 0)
    // const totalUnrealizedValue = fundPlacements.reduce((acc, fp) => acc + fp.unrealizedReturnValue, 0) TODO re-implement when ready.
    return (
        <div className={styles.myFunds}>
            {fundPlacements.length > 0 && (
                <Stack direction={'column'} spacing={2}>
                    {fundPlacementsWithMissingDividends.length > 0 && (
                        <Alert severity="info" elevation={1} sx={{ maxWidth: '80rem' }}>
                            {t('pages.portfolio.depositor.fund.newYearDividendWarning')}
                        </Alert>
                    )}
                    <Fragment>
                        <h3 className={styles.title}>
                            <i className="ri-funds-line" />
                            <span>{t('pages-FundOverview.yourFundPortfolio')}</span>
                        </h3>
                        <ul className={styles.fundList}>
                            <li className={styles.accordionDescription}>
                                <span className={styles.name}>{t('pages-FundOverview.name')}</span>
                                <span className={styles.risk}>{t('pages-FundOverview.risk')}</span>
                                <span className={styles.nav}>{t('pages-FundOverview.navPrice')}</span>
                                <span className={styles.subVal}>{t('pages-FundOverview.unitQuantity')}</span>
                                <span className={styles.val}>{t('pages-FundOverview.marketValue')}</span>
                                <span className={styles.returns}>
                                    <span>{t('pages-FundOverview.returns') + '*'}</span>
                                    <Tooltip title={t('pages-FundOverview.returnsExplanationShort')} arrow>
                                        <i
                                            className={'ri-information-line purple'}
                                            style={{ fontSize: '1.8rem', marginLeft: '0.5rem', fontWeight: 'normal' }}
                                        />
                                    </Tooltip>
                                </span>
                            </li>
                            {fundPlacements.map((fundPlacement) => (
                                <FundPlacementAccordion key={fundPlacement.id} fundPlacement={fundPlacement} />
                            ))}
                        </ul>
                        <Stack>
                            <div className={styles.totalValueLine}>
                                <span>
                                    {t('pages-FundOverview.totalMarketValue', {
                                        value: Currency(totalFundValue, { decimals: 0 }),
                                    })}
                                </span>
                                {/*<span>{t('pages-FundOverview.totalUnrealizedReturns', {value: Currency(totalUnrealizedValue, {decimals: 0})})}</span>*/}
                            </div>
                        </Stack>
                    </Fragment>
                </Stack>
            )}
        </div>
    )
}
