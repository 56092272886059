import styles from './InterestRateChangeBankDetails.module.css'
import { ActionBlock, DateOutput, InterestOutput } from '#components'
import { useTranslation } from 'react-i18next'
import { InterestRateChangeExtendedDto } from '#state/selectors'
import FxPaper from '#app/components/Paper/FxPaper'

export default function Implemented({ interestRateChange }: { interestRateChange: InterestRateChangeExtendedDto }) {
    const { t } = useTranslation()

    const showConvention = interestRateChange.oldInterestRateConvention !== interestRateChange.newInterestRateConvention
    const oldInterestRateConvention = interestRateChange.oldInterestRateConvention
        ? t('common.interestRateConvention.' + interestRateChange.oldInterestRateConvention)
        : ''
    const newInterestRateConvention = interestRateChange.newInterestRateConvention
        ? t('common.interestRateConvention.' + interestRateChange.newInterestRateConvention)
        : ''

    return (
        <FxPaper title={t('pages-interestratechange-bank.implementedActionHeader')}>
            <p data-cy="interestRateChangeImplementedInfo">{t('pages-interestratechange-bank.implementedInfo')}</p>
            <table className={styles.infotable} cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr>
                        <td>{t('pages-interestratechange-bank.oldInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.oldInterest)}</td>
                    </tr>
                    <tr>
                        <td>{t('pages-interestratechange-bank.newInterest')}:</td>
                        <td>{InterestOutput.format(interestRateChange.newInterest)}</td>
                    </tr>
                    {showConvention && (
                        <tr>
                            <td>{t('pages-interestratechange-bank.oldInterestRateConvention')}:</td>
                            <td>{oldInterestRateConvention}</td>
                        </tr>
                    )}
                    {showConvention && (
                        <tr>
                            <td>{t('pages-interestratechange-bank.newInterestRateConvention')}:</td>
                            <td>{newInterestRateConvention}</td>
                        </tr>
                    )}
                    <tr>
                        <td>{t('pages-interestratechange-bank.changeDate')}:</td>
                        <td>{DateOutput.formatDate(interestRateChange.changeDate)}</td>
                    </tr>
                    <tr>
                        <td>{t('pages-interestratechange-bank.implementedDate')}:</td>
                        <td>{DateOutput.formatDate(interestRateChange.implemented)}</td>
                    </tr>
                </tbody>
            </table>
        </FxPaper>
    )
}
