import { PageHeader, PageLayout } from '#app/components'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'
import PartnerDepositorProposalWizard from '../PartnerDepositorProposalWizard/PartnerDepositorProposalWizard'
import SigningStepRedirect from './SigningStepRedirect'

export default function DepositorProposalRoutes() {
    const { t } = useTranslation()
    return (
        <>
            <PageHeader icon="ri-mail-send-line" title={t('menu.proposals')} />
            <PageLayout>
                <Routes>
                    <Route path={'/:partnerId/:proposalId/signing-step'} element={<SigningStepRedirect />} />
                    <Route path={'/:partnerId/:proposalId/:inviteId'} element={<PartnerDepositorProposalWizard />} />
                    <Route path={'/:partnerId/:proposalId'} element={<PartnerDepositorProposalWizard />} />
                </Routes>
            </PageLayout>
        </>
    )
}
