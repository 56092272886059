import { InterestOutput } from '#app/components'
import { format } from 'date-fns'

export const proposalSubject = `Forslag om bytte av innskuddskonto`
export const proposalLinkText = (linkPrefix: string, partnerId: string, proposalId: string, inviteId: string) =>
    inviteId !== null
        ? `${linkPrefix}/partner-proposal/${partnerId}/${proposalId}/${inviteId}?proposal-invite`
        : `${linkPrefix}/partner/proposals/${partnerId}/${proposalId}`

export const proposalMessageText = (interest: number, bankName: string, linkText: string) =>
    `Hei,

${bankName} tilbyr nå en innskuddskonto som per i dag (${format(new Date(), 'yyyy-MM-dd')}) har ${InterestOutput.format(interest)} flytende rente med 31 dagers oppsigelsestid. Hvis du ønsker, kan jeg opprette en konto for deg og sende av gårde en bestilling.

Du kan lese mer og bekrefte eller avkrefte om du ønsker å opprette en ny innskuddskonto her: ${linkText ?? ''}`
