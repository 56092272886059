import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import config from '../config'
import restEndpoint from '#services/rest/rest-endpoint'
import { registerCookieConsentCallback, submitHubspotCookieConsent } from '#services/hubspot'
import { AssociationDto } from '@fixrate/fixrate-security'

let GA_is_initialized = false
let GA4_is_initialized = false
const FBPIX_is_initialized = false

export type PageEvent = {
    app: string
    type: 'PageView' | 'PageEvent'
    userId: string
    time: Date
    path: string
    depositorId: string
    bankId: string
    page: string
    event: string
}

function handleCookieConsentEvent(analytics: boolean, advertisement: boolean, functionality: boolean) {
    if (analytics) {
        if (config().ga) {
            if (!GA_is_initialized) {
                ReactGA.initialize(config().ga)
                ReactGA.set({ anonymizeIp: true })
                GA_is_initialized = true
            }
        }

        if (config().ga4) {
            if (!GA4_is_initialized && !document.getElementById('ga4')) {
                const gaScript = document.createElement('script')
                gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${config().ga4}`
                gaScript.async = true
                gaScript.id = 'ga4'
                document.body.appendChild(gaScript)
                window.dataLayer = window.dataLayer || []
                window.gtag = function gtag() {
                    window.dataLayer.push(arguments)
                }
                window.gtag('js', new Date())
                window.gtag('config', config().ga4, {
                    anonymize_ip: true,
                    send_page_view: false,
                })
                GA4_is_initialized = true
            }
        }
    }

    if (!analytics) {
        // TODO: Unregister GA
    }

    if (advertisement) {
        if (config().fbPix) {
            if (!FBPIX_is_initialized) {
                /** @type any */
                const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
                if (config().ga) {
                    ReactGA.ga((tracker) => {
                        // @ts-ignore because we do not have the correct types for the tracker
                        advancedMatching.external_id = 'GA1.2.' + tracker.get('clientId')
                    })
                }

                const options = {
                    autoConfig: false,
                    debug: false,
                }
                // @ts-ignore because we do not have the correct types for the tracker
                ReactPixel.init(config().fbPix, advancedMatching, options)
            }
        }
    }

    if (!advertisement) {
        // TODO: Unregister FBPIX
    }

    submitHubspotCookieConsent({
        analytics: analytics ? 'true' : 'false',
        advertisement: advertisement ? 'true' : 'false',
        functionality: functionality ? 'true' : 'false',
    })
}

/**
 * Register a callback that will be called
 *  - immedeately if the user already has given consent
 *  - or else when the user has given or rejected consent
 *  - or if the user opens the cookie consent dialog and change consent
 */
registerCookieConsentCallback((analytics, advertisement, functionality) => {
    handleCookieConsentEvent(analytics, advertisement, functionality)
})

interface CkyEvent {
    detail: {
        accepted: string[]
        rejected: string[]
    }
}

/**
 * Check if the user has already given consent to cookies
 * Register event listeners for the cookieyes cookie consent dialog
 *
 * https://www.cookieyes.com/documentation/retrieving-consent-data-using-api-getckyconsent/
 * https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
 */
function registerCookieYesEventListeners() {
    if (!config().cookieYesCode) {
        return
    }

    const script = document.createElement('script')
    script.id = 'cookieyes'
    script.type = 'text/javascript'
    script.src = 'https://cdn-cookieyes.com/client_data/' + config().cookieYesCode + '/script.js'
    document.head.appendChild(script)

    window.addEventListener('load', function () {
        // Wait until the getCkyConsent function is available
        const interval = setInterval(function () {
            if (typeof window.getCkyConsent === 'function') {
                clearInterval(interval)
                console.log('Checking cookie consent')
                const ckyConsent = window.getCkyConsent()
                if (ckyConsent.isUserActionCompleted) {
                    const analytics = ckyConsent.categories.analytics
                    const advertisement = ckyConsent.categories.advertisement
                    const functionality = ckyConsent.categories.functionality
                    handleCookieConsentEvent(analytics, advertisement, functionality)
                }
            }
        }, 100) // Check every 100ms
    })

    console.log('Registering cookie consent event listeners')
    document.addEventListener('cookieyes_consent_update', function (eventData) {
        console.log('Received cookie consent event', eventData)
        const ckyEvent = eventData as unknown as CkyEvent
        const data = ckyEvent.detail
        const analytics = data.accepted.includes('analytics')
        const advertisement = data.accepted.includes('advertisement')
        const functionality = data.accepted.includes('functionality')
        handleCookieConsentEvent(analytics, advertisement, functionality)
    })
}
registerCookieYesEventListeners()

export function pageView({
    path,
    association,
    associations,
}: {
    path: string
    association: AssociationDto
    associations: AssociationDto[]
}) {
    if (GA_is_initialized) {
        ReactGA.pageview(path)
    }

    if (GA4_is_initialized) {
        window.gtag('event', 'page_view', {
            page_title: window.document.title,
            page_path: window.document.location.pathname,
            page_location: window.document.location.href,
        })
    }

    if (config().hubspot) {
        console.log('Hubspot: Tracking page view', path)
        const _hsq = (window._hsq = window._hsq || [])
        _hsq.push(['setPath', path])
        _hsq.push(['trackPageView'])
    }

    if (FBPIX_is_initialized) {
        ReactPixel.pageView()
    }

    pageEvent(path, { association, associations }, 'PageView')
}

const removeUuids = (path) =>
    path.replaceAll(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi, ':uuid')

export function pageEvent(
    path: string,
    data: {
        association: AssociationDto
        associations: AssociationDto[]
        userId?: string
        page?: string
        event?: string
    },
    type?: 'PageView' | 'PageEvent'
) {
    let pageEvent: PageEvent = {
        app: 'Marketplace',
        type,
        userId: data.userId,
        time: new Date(),
        path: removeUuids(path),
        depositorId: '',
        bankId: '',
        page: data.page || '',
        event: data.event || '',
    }

    const associationId = data?.association?.organisation?.id

    if (data.association?.organisationType === 'DEPOSITOR') {
        if (associationId) {
            pageEvent = { ...pageEvent, depositorId: associationId }
        } else if (data?.associations.filter((a) => a.organisation?.type === 'DEPOSITOR').length === 1) {
            pageEvent = {
                ...pageEvent,
                depositorId: data?.associations.filter((a) => a.organisation?.type === 'DEPOSITOR')[0].organisation.id,
            }
        }
    } else if (data?.association?.organisationType === 'BANK') {
        if (data?.associations.filter((a) => a.organisation?.type === 'BANK').length === 1) {
            pageEvent = {
                ...pageEvent,
                bankId: data?.associations.filter((a) => a.organisation?.type === 'BANK')[0].organisation.id,
            }
        }
    }

    switch (pageEvent['type']) {
        case 'PageView':
            console.log(`PageView:${pageEvent['path']}`)
            break
        case 'PageEvent':
            console.log(`PageEvent:${pageEvent['page']}:${pageEvent['event']}`)
            break
    }

    restEndpoint(() => 0)
        .notifyPageEvent(pageEvent)
        .then(() => console.log('Notified page event'))
        .catch(() => console.log('Ignoring page event post error'))

    // Special linkedIn-event
    if (config().isProduction && data.page === 'Signup' && data.event === 'userRegistered') {
        const img = new Image()
        img.src = config().linkedInPixelUrl
    }
}
