import { Children, cloneElement } from 'react'
import RadioButton from './RadioButton'
import styles from './RadioButtonGroup.module.css'
import classNames from 'classnames'

/**
 *
 * @param {object} props
 * @param props.children
 * @param {string} props.group
 * @param {any} props.value
 * @param [props.onChange]
 * @param [props.className]
 * @return {JSX.Element}
 */
export default function RadioButtonGroup({ children, group, value, onChange, className }) {
    const radioButtons = []
    Children.forEach(children, (child) => {
        if (child?.type === RadioButton) {
            const extraProps = {}
            if (group) {
                extraProps.name = group
            }
            if (!child.props.hasOwnProperty('checked')) {
                extraProps.checked = child.props.value === value
            }
            if (!child.props.onChange) {
                extraProps.onChange = onChange
            }
            radioButtons.push(cloneElement(child, extraProps))
        }
    })

    return <div className={classNames(styles.body, className)}>{radioButtons}</div>
}
