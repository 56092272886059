import React from 'react'
import { Box, Grid } from '@mui/material'
import { MarketplaceNew } from '#app/services/payloadCMS/payload-types'
import { NewsArticleCard } from './NewsArticleCard'
import { Paper } from '#app/components'

interface NewsArticleCollectionProps {
    articles: MarketplaceNew[]
}

const NewsArticleCollection: React.FC<NewsArticleCollectionProps> = ({ articles }) => {
    if (!articles || articles.length === 0) {
        return <div>No news available</div>
    }

    const sortedArticles = articles.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    const featuredArticle = sortedArticles[0]
    const nextFiveArticles = sortedArticles.slice(1, 6)
    const remainingArticles = sortedArticles.slice(6)

    return (
        <Box sx={{ maxWidth: '140rem' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <Paper
                        sx={{
                            padding: '0',
                            width: '100%',
                            transition: 'box-shadow 0.3s ease-in-out',
                            '&:hover': {
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                            },
                        }}
                    >
                        <NewsArticleCard article={featuredArticle} featured={true} />
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Grid container>
                        {nextFiveArticles.map((article) => (
                            <Grid item xs={12} key={article.id}>
                                <Paper
                                    sx={{
                                        transition: 'box-shadow 0.3s ease-in-out',
                                        '&:hover': {
                                            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                                        },
                                        padding: '0',
                                    }}
                                >
                                    <NewsArticleCard article={article} />
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            {remainingArticles.length > 0 && (
                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {remainingArticles.map((article) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={article.id}>
                            <Paper
                                sx={{
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
                                    },
                                }}
                            >
                                <NewsArticleCard article={article} />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Box>
    )
}

export default NewsArticleCollection
