import { PageHeader, PageLayout } from '#app/components'
import EmptyState from '#app/components/EmptyState/EmptyState'
import { DepositorPortfolioSelection } from '#components/PageHeader/components/PortfolioSelection/DepositorPortfolioSelection'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import usePortfolio from '#app/services/usePortfolio'
import { hasFundAccessSelector, isLoggedIn } from '#app/state/selectors'
import LiquidityOverview from '#app/pages/portfolio-depositor/PortfolioOverview/LiquidityOverview'
import { useSelector } from '#state/useSelector'
import { Box, Button, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { DepositCards } from './DepositCards/DepositCards'
import DepositList from './DepositList/DepositList'
import FundPlacements from './FundPlacements/FundPlacements'
import DepositOverview from './DepositOverview'
import styles from './OldOverview.module.scss'
import { currencyHasBenchmarkInterest } from '#app/services/currencyBenchmarkUtils'

type Tab = 'overview' | 'deposits' | 'funds'

type Params = {
    tab?: Tab | string
}

export default function Portfolio() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { tab } = useParams<Params>()
    const loggedIn = useSelector(isLoggedIn)
    const showFunds: boolean = useSelector(hasFundAccessSelector)
    const currency = useSelector((state) => state.session?.association?.currentCurrency)
    const depositor = useCurrentDepositor()
    const portfolio = usePortfolio()
    const portfolios = depositor?.portfolios
    const hasDepositsOrFundPlacements = useSelector(
        (state) => state.deposits.length > 0 || state.fundPlacements.length > 0
    )

    const selectedTab: Tab = tab === 'funds' ? 'funds' : tab === 'deposits' ? 'deposits' : 'overview'

    const showDepositTable = useMediaQuery('(max-width: 1100px)')

    if (!loggedIn) {
        return null
    }

    if (!hasDepositsOrFundPlacements) {
        return (
            <>
                <PageHeader icon="ri-briefcase-line" title={t('pagetitles.portfolio')} />
                <PageLayout>
                    {portfolios?.length > 1 ? (
                        <EmptyState
                            illustration={{ name: 'portfolio', color: 'default' }}
                            variant="centered-on-background"
                            title={
                                showFunds
                                    ? t('pages-portfolio-depositor.noPlacementsInPortfolio', {
                                          portfolioName: portfolio?.name,
                                      })
                                    : t('pages-portfolio-depositor.noDepositsInPortfolio', {
                                          portfolioName: portfolio?.name,
                                      })
                            }
                        >
                            <Box
                                border={'0.1rem solid rgba(0,0,0,0.2)'}
                                sx={{ backgroundColor: 'white', '& .MuiButton-root': { border: '0' } }}
                            >
                                <DepositorPortfolioSelection />
                            </Box>
                            <Button variant="contained" color="primary" onClick={() => navigate('/marketplace')}>
                                {t('pages-portfolio-depositor.goToMarketplace')}
                            </Button>
                        </EmptyState>
                    ) : (
                        <EmptyState
                            illustration={{ name: 'portfolio', color: 'default' }}
                            variant="centered-on-background"
                            button={{
                                text: t('pages-portfolio-depositor.goToMarketplace'),
                                onClick: () => navigate('/marketplace'),
                            }}
                            title={
                                showFunds
                                    ? t('pages-portfolio-depositor.noPlacements')
                                    : t('pages-portfolio-depositor.noDeposits')
                            }
                        />
                    )}
                </PageLayout>
            </>
        )
    }

    const showTabSelector = currency === 'NOK' || currency === 'SEK' || showFunds

    return (
        <>
            <PageHeader icon="ri-briefcase-line" title={t('pagetitles.portfolio')} />
            <PageLayout className={styles.portfolioLayout}>
                <Box className={styles.portfolioSection}>
                    {showTabSelector && (
                        <Box sx={{ mb: 3 }}>
                            <ToggleButtonGroup color="primary" value={selectedTab} sx={{ backgroundColor: 'white' }}>
                                <ToggleButton
                                    sx={{ px: 2 }}
                                    data-cy="overviewTab"
                                    value="overview"
                                    onClick={() => navigate('/portfolio/overview')}
                                >
                                    <i className="ri-briefcase-line" />
                                    <span>{t('pagetitles.overview')}</span>
                                </ToggleButton>
                                <ToggleButton
                                    sx={{ px: 2 }}
                                    data-cy="depositsTab"
                                    value="deposits"
                                    onClick={() => navigate('/portfolio/overview/deposits')}
                                >
                                    <i className="ri-bank-line" />
                                    <span>{t('pagetitles.deposits')}</span>
                                </ToggleButton>
                                {showFunds && (
                                    <ToggleButton
                                        sx={{ px: 2 }}
                                        data-cy="fundsTab"
                                        value="funds"
                                        onClick={() => navigate('/portfolio/overview/funds')}
                                    >
                                        <i className="ri-line-chart-line" />
                                        <span>{t('pagetitles.funds')}</span>
                                    </ToggleButton>
                                )}
                            </ToggleButtonGroup>
                        </Box>
                    )}
                    {(!showTabSelector || selectedTab === 'deposits') &&
                        (!showDepositTable ? <DepositList /> : <DepositCards />)}
                    {showTabSelector && selectedTab === 'funds' && showFunds && <FundPlacements />}
                    {showTabSelector && selectedTab === 'overview' && currencyHasBenchmarkInterest(currency) && (
                        <div>{showFunds ? <LiquidityOverview /> : <DepositOverview />}</div>
                    )}
                </Box>
            </PageLayout>
        </>
    )
}
