import { DocumentDto } from '@fixrate/fixrate-query'
import { DateOutput, Paper } from '#components'
import DocumentLink from '#pages/common/DocumentLink/DocumentLink'
import { sortDatesAscending, sortDatesDescending } from '#services/dateandtime'
import { historyEntryTypeTMap } from '#services/enumTranslationKeyMapping'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import styles from './HistoryPanel.module.scss'

const API_BASE_URL = config().apiUrl
const DEVIATION_GUIDE_DOC_URL =
    config().fixrateFrontDocumentUrl + '/dokumentpakke-bank/' + config().deviationGuideDocument

function DocumentName({ document }: { document: DocumentDto }) {
    const { t } = useTranslation()

    if (!document) {
        return <span>{t('blocks-HistoryPanel.documentNameMissing')}</span>
    } else {
        return <a href={API_BASE_URL + document.link}>{document.name}</a>
    }
}

function FormattedDocumentList({ documentIdList }) {
    const documents = useSelector((state) => state.documents)

    return documentIdList
        .slice()
        .sort((a, b) => {
            if (documents && documents[a]?.created && documents[b]?.created) {
                return sortDatesAscending(documents[a].created, documents[b].created)
            } else {
                return 0
            }
        })
        .map((documentId, index) => (
            <li key={documentId + index}>
                <i className="ri-file-text-line" />
                <DocumentName document={documents?.[documentId]} />
            </li>
        ))
}

const status = (type) => {
    const warningArr = ['DEPOSIT_DEVIATION_DETECTED']

    const completedArr = ['DEPOSIT_DEVIATION_RESOLVED']

    if (warningArr.indexOf(type) !== -1) {
        return styles.warningEvent
    }

    if (completedArr.indexOf(type) !== -1) {
        return styles.completedEvent
    }

    return ''
}

function HistoryList({ processId }: { processId: string[] }) {
    const history = useSelector((state) => state.history)
    const { t } = useTranslation()

    if (!history) {
        return null
    }

    const elements = history
        .filter((entry) => processId?.includes?.(entry.processId))
        .sort((a, b) => sortDatesDescending(a.time, b.time))
        .map((historyItem) => (
            <li key={historyItem.type + historyItem.time} className={styles.historyPanelElement}>
                <div className={styles.statusLine}>
                    <div className={classNames(status(historyItem.type), styles.circle)}>
                        <i className={classNames(styles.warning, 'ri-alert-line')}></i>
                        <i className={classNames(styles.completed, 'ri-checkbox-circle-line')}></i>
                    </div>
                </div>
                <p className={styles.itemTitle}>{t(historyEntryTypeTMap[historyItem.type]) || historyItem.type}</p>
                <p className={styles.itemMeta}>
                    <span>
                        <i className="ri-calendar-line" />
                        <span>
                            <DateOutput.Date date={new Date(historyItem.time)} />
                        </span>
                    </span>
                    <span>
                        <i className="ri-account-circle-line" />
                        <span>{historyItem.userFullName || historyItem.organisationName || 'Fixrate'}</span>
                    </span>
                </p>
                {historyItem.comment && (
                    <p className={styles.itemMeta}>
                        <span>{historyItem.comment}</span>
                    </p>
                )}
                {historyItem.type === 'DEPOSIT_DEVIATION_DETECTED' && (
                    <p className={styles.itemMeta}>
                        <i className="ri-file-download-line" />
                        <DocumentLink
                            name={t('pages-portfolio-bank.downloadNonConformanceGuide')}
                            link={DEVIATION_GUIDE_DOC_URL}
                            hideIcon={true}
                        />
                    </p>
                )}
                <ul className={styles.documentList}>
                    <FormattedDocumentList documentIdList={historyItem.documents} />
                </ul>
            </li>
        ))

    return elements.length ? (
        <Fragment>{elements}</Fragment>
    ) : (
        <p style={{ padding: '1em' }}>{t('blocks-HistoryPanel.noHistory')}</p>
    )
}

export default function HistoryPanel({
    processId,
    processName = undefined,
}: {
    processId: Array<string>
    processName?: string
}) {
    const { t } = useTranslation()

    const aside = useRef(null)
    const content = useRef(null)
    const [top, setTop] = useState('0px')
    const [overlappingSibling, setOverlappingSibling] = useState(false)
    const [hasScroll, setHasScroll] = useState(false)
    const [scrollAtBottom, setScrollAtBottom] = useState(false)

    const checkIfScrollAtBottom = (e) => {
        const element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            setScrollAtBottom(true)
        } else {
            setScrollAtBottom(false)
        }
    }

    const checkForScroll = () => {
        setHasScroll(content.current.scrollHeight > content.current.clientHeight)
    }

    useLayoutEffect(() => {
        checkForScroll()
    }, [aside])

    useLayoutEffect(() => {
        let timeout = null
        const parentNode = aside.current?.parentNode?.parentNode?.parentNode
        const previousSibling = aside.current?.previousElementSibling
            ? aside.current?.previousElementSibling
            : aside.current?.parentNode?.previousElementSibling
        const { x, width } = previousSibling?.getBoundingClientRect?.() || { x: 0, width: 0 }

        const rightEdge = x + width

        function resize() {
            timeout = setTimeout(() => {
                if (previousSibling && rightEdge && parentNode && aside.current instanceof HTMLElement) {
                    const farRight = parentNode.getBoundingClientRect().x + parentNode.getBoundingClientRect().width
                    setOverlappingSibling(rightEdge + aside.current.clientWidth > farRight)
                }

                if (parentNode && top) {
                    const { y } = parentNode.getBoundingClientRect()

                    setTop(y + 'px')
                }
                checkForScroll()
            }, 20)
        }

        resize()

        window.addEventListener('resize', resize)

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
            window.removeEventListener('resize', resize)
        }
    }, [aside, top])

    return (
        <Paper
            title={
                processName
                    ? t('blocks-HistoryPanel.headingWithName', { processName })
                    : t('blocks-HistoryPanel.heading')
            }
        >
            <aside
                ref={aside}
                className={classNames(styles.historyPanel, overlappingSibling && styles.historyPanelStatic)}
            >
                <div ref={content} onScroll={checkIfScrollAtBottom}>
                    <ul className={styles.historyList}>
                        <HistoryList processId={processId} />
                    </ul>
                    {hasScroll && !scrollAtBottom && <div className={styles.scrollShadow}></div>}
                </div>
            </aside>
        </Paper>
    )
}
