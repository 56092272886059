import { useEffect, useState } from 'react'
import CountUp from 'react-countup'

type State = {
    start: number
    end: number
    decimals?: number
}

const AnimatedNumber = ({ start, end, decimals }: State) => {
    const testMode = document.cookie.includes('_fixrate_stop_time')
    const [prevValue, setPrevValue] = useState<number>(null)

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!testMode && start > prevValue) {
                setPrevValue(start)
            }
        }, 2950)

        return () => clearTimeout(timeout)
    }, [start, prevValue, testMode])

    return testMode ? (
        <div>--test--</div>
    ) : (
        <CountUp
            start={prevValue === null ? start : prevValue}
            end={end}
            duration={3}
            useEasing={false}
            prefix={'kr '}
            decimals={decimals ?? 0}
            decimal={','}
            separator={' '}
        />
    )
}

export default AnimatedNumber
