import { SILVER_GRAY } from '#app/colors/colors'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import FundPlatformFeeTable from '#app/components/FundPlatformFeeTable/FundPlatformFeeTable'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import config from '#app/config'
import CurrencyPricingBar from '#app/pages/FundDetails/PricingChart/CurrencyPricingBar'
import { PersonValidator } from '#app/services/PersonValidator'
import usePortfolio from '#app/services/usePortfolio'
import { useEndpoint } from '#command'
import ShareClassTable from '#components/ShareClassTable/ShareClassTable'
import useShoppingCart from '#services/useShoppingCart'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { FundDto } from '@fixrate/fixrate-query'
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Drawer,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { getI18n, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styles from './ShoppingCartPane.module.scss'
import { useValidState } from './useValidState'
import { OrderAmountInput } from './OrderAmountInput'
import { OrderAmountLimitInfo } from './OrderAmountLimitInfo'
import { CurrentFundPlacementValue } from './CurrentFundPlacementValue'
import FundAgreementSection from '#app/pages/organizations/OrganizationDetail/TermsAndConditions/Sections/FundAgreement'
import useFundStatus from '#app/services/useFundStatus'

type Props = {
    open: boolean
    setOpen: (open: boolean) => void
}

export default function ShoppingCartPane({ open, setOpen }: Props) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const shoppingCart = useShoppingCart<FundDto>('FUNDS')
    const { getTokenForFixrateCapital } = useEndpoint()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const [showFundActivation, setShowFundActivation] = useState(false)
    const orgId = useSelector((state) => state.session.association?.organisation?.id)
    const organisationCanBuyFunds = useSelector(selectors.organisationCanBuyFunds)
    const depositor = useSelector((state) => state.depositor)
    const userCanBuyFunds = useSelector(selectors.userCanBuyFunds)
    const userId = useSelector((state) => state.session.id)
    const Currency = useCurrencyOutput()
    const documents = useSelector((state) => state.documents)
    const [platformFeeTableVisible, setPlatformFeeTableVisible] = useState(false)
    const portfolio = usePortfolio()
    const validState = useValidState()
    const fundStatus = useFundStatus()
    const allAreValid = Object.values(validState).every((v) => v)
    const [touched, setTouched] = useState<Record<string, boolean>>({})
    const minOrderVolume = depositor?.portfolios.find((p) => p.id === portfolio?.id)?.fundData?.minOrderVolume

    if (!depositor || !portfolio) {
        return null
    }
    const person = depositor?.people.find((p) => p.associatedUserId === userId)
    const personIsComplete = person && PersonValidator.isComplete(person, depositor)
    const accounts = depositor?.settlementAccounts || []
    const hasSettlementAccountInCurrency = accounts.find(
        (a) => a.currency === portfolio.currency || (a.currency === null && portfolio.currency === 'NOK')
    )
    const customerDeclaration = documents[depositor.customerDeclarationId]
    const customerDeclarationIsMissing = !customerDeclaration || !customerDeclaration.signedByAny
    const customerDeclarationHasExpired = depositor?.customerDeclarationExpired
    const hasWarnings =
        customerDeclarationIsMissing ||
        customerDeclarationHasExpired ||
        !hasSettlementAccountInCurrency ||
        !organisationCanBuyFunds ||
        !userCanBuyFunds ||
        !personIsComplete ||
        (organisationCanBuyFunds && userCanBuyFunds && !personIsComplete)

    async function startCheckout() {
        // For each fund in the shopping cart, check if the order is valid
        if (!allAreValid) {
            // Order is not valid
            console.warn('Order is not valid')
            return
        }

        if (!hasSettlementAccountInCurrency || !depositor) {
            return
        }

        const { token } = await getTokenForFixrateCapital()
        const lng = getI18n().language
        const itemsBase64 = shoppingCart.toBase64()
        console.log('Redirects to Fixrate Capital')
        window.location.href = `${config().fixrateCapitalBaseUrl}/order/buy/confirm?lng=${lng}&items=${itemsBase64}&customer=${depositor?.id}&externalReference=${shoppingCart.shoppingCartId}&token=${token}`
    }

    return (
        <Drawer
            open={open}
            onClose={() => {
                setTouched({})
                setOpen(false)
            }}
            anchor={isMobile ? 'bottom' : 'right'}
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '100%',
                    maxHeight: isMobile ? '80%' : '100%',
                },
            }}
        >
            <div className={classNames(styles.shoppingCart)}>
                <Stack spacing={4} className={styles.innerSlide}>
                    <h2>{t('components-ShoppingCart.shoppingCart')}</h2>
                    {minOrderVolume && (
                        <Alert severity="info" sx={{ width: '90%', fontSize: '1.2rem', mt: '2rem' }}>
                            <Stack spacing={0.5}>
                                <span>
                                    {t('components-ShoppingCart.infoMinimumOrderVolume1', {
                                        depositorName: depositor.name,
                                        minAmount: Currency(minOrderVolume, { decimals: 0 }),
                                    })}
                                </span>
                                <span>{t('components-ShoppingCart.infoMinimumOrderVolume2')}</span>
                            </Stack>
                        </Alert>
                    )}
                    {shoppingCart.getItemCount() === 0 && (
                        <Stack sx={{ mt: 2 }}>
                            <p>
                                {shoppingCart.isLoaded
                                    ? t('components-ShoppingCart.emptyCart')
                                    : t('common.loading') + ' ...'}
                            </p>
                            <div>
                                <Button
                                    data-cy={'shoppingCartCloseButton'}
                                    variant={'outlined'}
                                    color={'secondary'}
                                    onClick={() => setOpen(false)}
                                >
                                    {t('common.close')}
                                </Button>
                            </div>
                        </Stack>
                    )}
                    {shoppingCart.getItemCount() !== 0 && (
                        <>
                            <Table
                                sx={{
                                    '& .MuiTableCell-root': {
                                        pr: isMobile ? 1 : null,
                                    },
                                }}
                            >
                                <TableHead sx={{ color: SILVER_GRAY[500] }}>
                                    <TableRow>
                                        <TableCell>{t('components-ShoppingCart.name')}</TableCell>
                                        <TableCell>
                                            {t('components-ShoppingCart.amount', {
                                                currency: 'M' + portfolio?.currency,
                                            })}
                                        </TableCell>
                                        <TableCell className={styles.hideMobile} />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shoppingCart
                                        .getObjectReferences()
                                        .sort((f1, f2) => f1.name.localeCompare(f2.name, 'nb', { sensitivity: 'base' }))
                                        .map((fund) => {
                                            if (!fund) {
                                                return null
                                            }
                                            const shareClassId = shoppingCart.getItemSubId(fund.id)
                                            const shareClass = fund.fundShareClasses.find(
                                                (fc) => fc.id === shareClassId
                                            )
                                            if (!shareClass) {
                                                return null
                                            }
                                            return (
                                                <Fragment key={fund.id}>
                                                    <TableRow
                                                        sx={{
                                                            '& .MuiTableCell-root': { border: '0' },
                                                            borderTop: '0.1rem solid rgba(0,0,0,0.1)',
                                                            borderBottom: '0',
                                                            position: 'relative',
                                                            verticalAlign: 'top',
                                                        }}
                                                        data-cy="fundListItem"
                                                        key={fund.id}
                                                    >
                                                        <TableCell className={styles.fundName}>
                                                            <span>{fund.name}</span>
                                                            <div className={styles.isin}>
                                                                <p>{shareClass.isin}</p>
                                                            </div>
                                                            <div className={styles.documents}>
                                                                <Link
                                                                    to={
                                                                        '/api/document/id/' +
                                                                        fund.kiidDocumentId +
                                                                        '/data'
                                                                    }
                                                                    rel="noopener noreferrer"
                                                                    target="_blank"
                                                                >
                                                                    <i className="ri-file-text-line" />
                                                                    <span>{t('components-ShoppingCart.KIID')}</span>
                                                                </Link>
                                                                <Link
                                                                    to={
                                                                        '/api/document/id/' +
                                                                        fund.prospectusDocumentId +
                                                                        '/data'
                                                                    }
                                                                    rel="noopener noreferrer"
                                                                    target="_blank"
                                                                >
                                                                    <i className="ri-file-text-line" />
                                                                    <span>{t('components-ShoppingCart.prospect')}</span>
                                                                </Link>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <OrderAmountInput
                                                                fund={fund}
                                                                touched={touched[fund.id]}
                                                                setTouched={() =>
                                                                    setTouched({ ...touched, [fund.id]: true })
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            className={classNames(
                                                                styles.minAmount,
                                                                styles.hideMobile,
                                                                styles.fundName
                                                            )}
                                                        >
                                                            {fund && <ShareClassTable fund={fund} />}
                                                        </TableCell>
                                                        <TableCell>
                                                            {isMobile ? (
                                                                <IconButton
                                                                    sx={{ width: '5rem', height: '5rem' }}
                                                                    onClick={() => shoppingCart.removeItem(fund.id)}
                                                                    color="error"
                                                                >
                                                                    <i className="ri-delete-bin-line" />
                                                                </IconButton>
                                                            ) : (
                                                                <Button
                                                                    size={'small'}
                                                                    variant={'outlined'}
                                                                    color={'error'}
                                                                    onClick={() => shoppingCart.removeItem(fund.id)}
                                                                    startIcon={<i className="ri-delete-bin-line" />}
                                                                    data-cy="removeFromShoppingCart"
                                                                >
                                                                    {t('components-ShoppingCart.remove')}
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        sx={{
                                                            '& .MuiTableCell-root': { border: '0' },
                                                            borderTop: '0',
                                                            borderBottom: '0.1rem solid rgba(0,0,0,0.1)',
                                                        }}
                                                    >
                                                        <TableCell colSpan={4} sx={{ paddingTop: 0 }}>
                                                            <Stack spacing={1} mb={4}>
                                                                <OrderAmountLimitInfo fund={fund} />
                                                                <CurrentFundPlacementValue fund={fund} />
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                </Fragment>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                            <h3 className={styles.sum}>
                                <span>{t('components-ShoppingCart.sum')}</span>
                                <span data-cy="totalAmountText">
                                    {Currency(shoppingCart.getTotalAmount(), { decimals: 0 })}
                                </span>
                            </h3>
                            <Stack spacing={2}>
                                <Typography variant={'h4'}>{t('components-ShoppingCart.platformFee')}</Typography>
                                <CurrencyPricingBar
                                    onClick={() => setPlatformFeeTableVisible(true)}
                                    condensed
                                    order={{ type: 'BUY', amount: shoppingCart.getTotalAmount() }}
                                />
                                <FxDialog
                                    open={platformFeeTableVisible}
                                    onClose={() => setPlatformFeeTableVisible(false)}
                                >
                                    <FxDialogTitle onClose={() => setPlatformFeeTableVisible(false)}>
                                        <Typography variant={'h4'}>
                                            {t('components-ShoppingCart.platformFee')}
                                        </Typography>
                                    </FxDialogTitle>
                                    <FxDialogContent>
                                        <Box pb={2}>
                                            <FundPlatformFeeTable
                                                order={{ type: 'BUY', amount: shoppingCart.getTotalAmount() }}
                                            />
                                        </Box>
                                    </FxDialogContent>
                                </FxDialog>
                            </Stack>
                            <Stack spacing={2}>
                                <div className={styles.metaInfo}>
                                    <Typography variant="h4">
                                        {t('components-ShoppingCart.tradingInformationTitle')}
                                    </Typography>
                                    <p>
                                        <span>{t('components-ShoppingCart.paymentInfoDescription')} </span>
                                        <span>{t('components-ShoppingCart.tradingInformationDescription')}</span>
                                    </p>
                                </div>
                            </Stack>
                            {hasWarnings && (
                                <Stack spacing={1}>
                                    {!hasSettlementAccountInCurrency && (
                                        <Alert
                                            severity="warning"
                                            action={
                                                <Button
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => navigate('/organizations/' + orgId + '/accounts')}
                                                >
                                                    {t('components-ShoppingCart.goToAccounts')}
                                                </Button>
                                            }
                                        >
                                            {t('components-ShoppingCart.noAccountInCurrency', {
                                                currency: portfolio?.currency,
                                            })}
                                        </Alert>
                                    )}
                                    {!organisationCanBuyFunds && (
                                        <Fragment>
                                            {fundStatus?.inFundOnboardingQueue ? (
                                                <Alert severity="success" icon={<i className="ri-time-line" />}>
                                                    <AlertTitle>
                                                        {t('pages-organizations.fundDocumentsInQueueTitle')}
                                                    </AlertTitle>
                                                    {t('pages-organizations.fundDocumentsInQueue', {
                                                        name: depositor?.name,
                                                    })}
                                                </Alert>
                                            ) : (
                                                <Alert
                                                    severity="warning"
                                                    action={
                                                        <Button
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                            variant="outlined"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => setShowFundActivation(true)}
                                                        >
                                                            {t('components-ShoppingCart.goToEnableFund')}
                                                        </Button>
                                                    }
                                                >
                                                    {t('components-ShoppingCart.noAccess')}
                                                </Alert>
                                            )}
                                        </Fragment>
                                    )}
                                    {showFundActivation && (
                                        <FxDialog open={true} onClose={() => setShowFundActivation(false)}>
                                            <FxDialogTitle onClose={() => setShowFundActivation(false)}>
                                                {t('pages-organizations.fundActivationHeading')}
                                            </FxDialogTitle>
                                            <FxDialogContent>
                                                <FundAgreementSection inline />
                                            </FxDialogContent>
                                        </FxDialog>
                                    )}
                                    {!userCanBuyFunds && (
                                        <Alert
                                            severity="warning"
                                            action={
                                                <Button
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => navigate('/organizations/' + orgId + '/users/roles')}
                                                >
                                                    {t('components-ShoppingCart.goToRoles')}
                                                </Button>
                                            }
                                        >
                                            {t('components-ShoppingCart.userMissingPermissions')}
                                        </Alert>
                                    )}
                                    {organisationCanBuyFunds && userCanBuyFunds && !personIsComplete && (
                                        <Alert
                                            severity="warning"
                                            action={
                                                <Button
                                                    sx={{ whiteSpace: 'nowrap' }}
                                                    variant="outlined"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => navigate('/organizations/' + orgId + '/users/roles')}
                                                >
                                                    {t('components-ShoppingCart.goToAuthorizations')}
                                                </Button>
                                            }
                                        >
                                            {t('components-ShoppingCart.userMissingPermissions')}
                                        </Alert>
                                    )}
                                    {(customerDeclarationIsMissing || customerDeclarationHasExpired) && (
                                        <Alert
                                            severity="warning"
                                            elevation={1}
                                            sx={{ maxWidth: '80rem' }}
                                            action={
                                                <Button
                                                    variant={'outlined'}
                                                    color={'inherit'}
                                                    size="small"
                                                    href={`/organizations/${depositor.id}/customerdecl`}
                                                >
                                                    {t('components-ShoppingCart.goToCustomerDeclaration')}
                                                </Button>
                                            }
                                        >
                                            {customerDeclarationIsMissing
                                                ? t('components-ShoppingCart.customerDeclarationMissing', {
                                                      depositorName: depositor.name,
                                                  })
                                                : t('components-ShoppingCart.customerDeclarationMustBeUpdated', {
                                                      depositorName: depositor.name,
                                                  })}
                                        </Alert>
                                    )}
                                </Stack>
                            )}
                            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 2 }}>
                                <Button variant={'outlined'} color={'secondary'} onClick={() => setOpen(false)}>
                                    {t('common.close')}
                                </Button>
                                <Button
                                    data-cy="orderButton"
                                    variant={'contained'}
                                    disabled={
                                        !allAreValid ||
                                        !organisationCanBuyFunds ||
                                        !userCanBuyFunds ||
                                        customerDeclarationHasExpired ||
                                        customerDeclarationIsMissing
                                    }
                                    onClick={startCheckout}
                                >
                                    {t('components-ShoppingCart.goToOrderPage')}
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </div>
        </Drawer>
    )
}
