export function parseNumber(value: string): number {
    return parseFloat(value.replace(/\s/g, '').replace(',', '.'))
}

export function replaceComma(value: string | number) {
    if (typeof value === 'string') {
        return value.replace(',', '.')
    }
    return value
}

export function toString(value: string | number) {
    if (typeof value === 'number') {
        return '' + value
    }
    return value
}
