import { RegulatoryRegion } from '@fixrate/fixrate-query'
import {
    MarketplaceNew,
    MarketReport,
    Media,
    NorwegianSupportArticle,
    SwedishSupportArticle,
    Webinar,
} from './payload-types'
import config from '#app/config'

export type SupportArticlePayload = NorwegianSupportArticle | SwedishSupportArticle
type Breadcrumbs = NonNullable<Pick<SupportArticlePayload, 'breadcrumbs'>>['breadcrumbs']
type CountryLocale = 'nb_NO' | 'sv_SE'
export type SupportArticleData = {
    id: string
    slug: string
    parent: {
        id: string | null
    } | null
    breadcrumbs: Breadcrumbs
}

export type SupportSubMenuItems = Breadcrumbs

export type SupportMenuItem = {
    label?: string
    url?: string
    id?: string
    articleId: string
    subMenu: SupportSubMenuItems
    orderPriority: number
}

type Child = {
    detail: number
    format: number
    mode: string
    style: string
    text: string
    type: string
    version: number
    fields?: {
        url: string
        newTab: boolean
        linkType: 'custom' | 'internal'
        doc?: {
            value: Webinar | MarketReport
        }
    }
    children?: Child[]
}
export type Content = {
    children: Child[]
    direction: string
    format: string
    indent: number
    type: string
    version: number
    tag?: string
    value?: Media
    listType?: 'bullet' | 'number'
}

export type ArticleData = {
    id: string
    slug: string
    title: string
    parent: {
        id: string | null
    } | null
    breadcrumbs: Breadcrumbs
    orderPriority: number
}

const fixrateCMSgraphQL = '/fixrate-cms/api/graphql'

type CollectionName = {
    singular: string
    plural: string
}

const getSupportArticleCollectionName = (country: RegulatoryRegion): CollectionName => {
    if (country === 'SE') {
        return {
            singular: 'SwedishSupportArticle',
            plural: 'SwedishSupportArticles',
        }
    }

    return {
        singular: 'NorwegianSupportArticle',
        plural: 'NorwegianSupportArticles',
    }
}

export async function getMarketReports(): Promise<MarketReport[] | null> {
    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                MarketReports(limit: 50) {
                    docs {
                        id,
                        title,
                        commentary,
                        date,
                        reportSlug
                    }
                }
            }`,
            variables: {},
        }),
    })
        .then((data) => data.json())
        .catch(() => null)

    const data = await res

    if (data) {
        const sortedReports = data?.data?.MarketReports.docs.sort((a: MarketReport, b: MarketReport) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        return sortedReports as MarketReport[]
    } else {
        return null
    }
}

export async function getWebinars(): Promise<Webinar[] | null> {
    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                Webinars(limit: 50) {
                    docs {
                        id,
                        title,
                        description,
                        descriptionPast,
                        webinarCategory,
                        episodeNumber,
                        country,
                        customerSegment,
                        dateTo,
                        dateFrom,
                        eventUrl,
                        guests {
                            name,
                            id,
                            title,
                            image {
                            	url
                            },
                        },
                        hosts {
                            employee {
                              name,
                              title,
                              image {
                                url
                              }
                            }
                        }
                        vimeoID
                    }
                }
            }`,
            variables: {},
        }),
    })
        .then((data) => data.json())
        .catch(() => null)

    const data = await res

    if (data) {
        const sortedReports = data?.data?.Webinars?.docs?.sort((a: Webinar, b: Webinar) => {
            return new Date(b.dateTo).getTime() - new Date(a.dateTo).getTime()
        })
        return sortedReports as Webinar[]
    } else {
        return null
    }
}

export async function getBankWebinars(): Promise<Webinar[] | null> {
    const webinars = await getWebinars()

    return webinars?.filter((e) => e.customerSegment === 'bank') || null
}

export async function getSupportArticles(
    language: CountryLocale,
    country: RegulatoryRegion
): Promise<SupportArticlePayload[] | null> {
    const collectionNames = getSupportArticleCollectionName(country)
    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                ${collectionNames.plural}(locale: ${language}, limit: 500) {
                    docs {
                        id,
                        title,
                        content,
                        slug,
                        orderPriority,
                        parent {
                            id
                        },
                        breadcrumbs {
                            label,
                            url,
                        },
                        meta {
                            title,
                            description,
                            image {
                                url
                            }
                        }
                    }
                }
            }`,
            variables: {},
        }),
    }).catch((e) => {
        console.error(e)
        return null
    })

    const data = await res?.json()

    if (data) {
        return data?.data?.[collectionNames.plural]?.docs
    } else {
        return null
    }
}

export async function getSupportArticle({
    id,
    language,
    country,
}: {
    id: string | null
    language: CountryLocale
    country: RegulatoryRegion
}): Promise<SupportArticlePayload | null> {
    if (!id) {
        return null
    }

    const collectionNames = getSupportArticleCollectionName(country)

    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                ${collectionNames.singular}(id: "${id}", locale: ${language}) {
                    id,
                    title,
                    content,
                    slug,
                    orderPriority,
                    parent {
                        id
                    },
                    breadcrumbs {
                        label,
                        url,
                        doc {
                            id
                        }
                    },
                    meta {
                        title,
                        description,
                        image {
                            url
                        }
                    }
                }
            }`,
            variables: {},
        }),
    }).catch((e) => {
        console.error(e)
        return null
    })

    const data = await res?.json()

    if (data) {
        return data?.data?.[collectionNames.singular]
    } else {
        return null
    }
}

export const getMarketPlaceNews = async (): Promise<MarketplaceNew[] | null> => {
    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                MarketplaceNews(limit: 50) {
                    docs {
                        id,
                        title,
                        newsType,
                        targetAudience,
                        newsCategory,
                        videoSource,
                        videoId,
                        fundInformation {
                            isin,
                            leiNumber,
                            orgNumber,
                            id
                        },
                        coverPhoto {
                            url
                        },
                        attachments {
                            id
                            name
                            url
                            filename
                            mimeType
                            filesize
                        },
                        content,
                        updatedAt,
                        createdAt,
                        _status
                    }
                }
            }`,
            variables: {},
        }),
    })
        .then((data) => data.json())
        .catch(() => null)

    const data = await res

    if (data) {
        const sortedNews = data?.data?.MarketplaceNews?.docs?.sort((a: MarketplaceNew, b: MarketplaceNew) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        })
        return sortedNews as MarketplaceNew[]
    } else {
        return null
    }
}

type LoggedInToPayload = {
    collection: string
    user: {
        id: string
        name: string
        email: string
        createdAt: string
        updatedAt: string
        loginAttempts: number
    }
    exp: number
    token: string
}
export async function loggedInToPayload(): Promise<boolean> {
    // TODO: This call will generate a CORS error and an error message to the user unless the domain is registred in
    //  the CORS settings in the API. To avoid this, we should only make this call if we known that the domain is
    //  registred in the CORS settings.
    if (config().environment === 'DEMO') {
        return false
    }

    const res = await fetch('https://fixrate.no/api/users/me', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    })
    const data: LoggedInToPayload = await res?.json()

    //await updateOrderPriority("SE", "6735093727f19756d645d773", 23)

    if (data?.user) {
        return true
    } else {
        return false
    }
}

export async function updateOrderPriority(
    country: RegulatoryRegion,
    documentId: string,
    newOrderPriority: number
): Promise<boolean> {
    const collectionNames = getSupportArticleCollectionName(country)
    const res = await fetch('https://fixrate.no' + fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            query: `
                mutation UpdateOrderPriority($id: String!, $orderPriority: Float!) {
                    update${collectionNames.singular}(id: $id, data: { orderPriority: $orderPriority }) {
                        id
                        title
                        orderPriority
                    }
                }
            `,
            variables: {
                id: documentId,
                orderPriority: newOrderPriority,
            },
        }),
    }).catch((e) => {
        console.error(e)
        return null
    })

    const data = await res?.json()

    // Check if the mutation succeeded by seeing if it returned the updated document
    if (data?.data?.['update' + collectionNames?.singular]?.orderPriority === newOrderPriority) {
        return true
    } else {
        console.error('Failed to update order priority:', data || '')
        return false
    }
}

export async function updateMultipleOrderPriorities(country: RegulatoryRegion, itemOrder: string[]): Promise<boolean> {
    try {
        await Promise.all(
            itemOrder.map((id, index) => {
                return updateOrderPriority(country, id, index + 1)
            })
        )
        return true // All updates succeeded
    } catch (error) {
        console.error('Error updating order priorities:', error)
        return false // At least one update failed
    }
}

export type FilteredSubmenu = {
    doc: string
    id: string
    slug: string
    parent: string | NorwegianSupportArticle | SwedishSupportArticle
    breadcrumbs: Pick<SupportArticlePayload, 'breadcrumbs'>['breadcrumbs']
    label: string
    orderPriority: number
}[]

export async function getSubMenuForSupportArticle(
    language: CountryLocale,
    country: RegulatoryRegion,
    articleId: string
): Promise<FilteredSubmenu | null> {
    const collectionNames = getSupportArticleCollectionName(country)

    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                ${collectionNames.plural}(locale: ${language}, limit: 500) {
                    docs {
                        id
                        slug
                        title
                        orderPriority
                        parent {
                            id
                        }
                        breadcrumbs {
                            doc {
                                id
                            }
                            label
                            url
                            id
                        }
                    }
                }
            }`,
        }),
    }).catch((error) => {
        console.error(error)
        return null
    })

    const data = await res?.json()
    const articles: SupportArticlePayload[] = data?.data?.[collectionNames.plural]?.docs

    if (!articles) {
        return null
    }

    // Create a map of id to sub-menu items
    const filteredSubMenu = articles
        ?.filter((article) => (typeof article.parent !== 'string' ? article.parent?.id === articleId : false))
        ?.map((article) => ({
            doc: article.id,
            id: article.id,
            slug: article.slug,
            parent: article.parent,
            breadcrumbs: article.breadcrumbs,
            label: article.title,
            orderPriority: article.orderPriority,
        }))
        ?.sort((a, b) => a.orderPriority - b.orderPriority)

    return filteredSubMenu
}
export async function getSupportArticleMenuItems(
    language: CountryLocale,
    country: RegulatoryRegion
): Promise<SupportMenuItem[] | null> {
    const collectionNames = getSupportArticleCollectionName(country)

    const res = await fetch(fixrateCMSgraphQL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
                ${collectionNames.plural}(
                    locale: ${language},
                    limit: 500
                ) {
                    docs {
                        id
                        slug
                        title
                        orderPriority
                        parent {
                            id
                        }
                        breadcrumbs {
                            doc {
                                id
                            }
                            label
                            url
                            id
                        }
                    }
                }
            }`,
        }),
    }).catch((error) => {
        console.error(error)
        return null
    })

    const data = await res?.json()
    const articles: ArticleData[] = data?.data?.[collectionNames.plural]?.docs

    if (!articles) {
        return null
    }

    // Filter top-level menu items
    const topLevelItems = articles.filter((article) => article.parent === null)

    // Create a map of id to sub-menu items
    const subMenuMap: Record<string, SupportSubMenuItems> = {}
    articles.forEach((article) => {
        if (article.parent && article.parent.id) {
            const parentId = article.parent.id
            const breadcrumb = article.breadcrumbs.at(-1)
            if (breadcrumb) {
                if (!subMenuMap[parentId]) {
                    subMenuMap[parentId] = []
                }
                subMenuMap[parentId].push(breadcrumb)
            }
        }
    })

    // Create the menu with sub-menu items
    const menuWithSubItems: SupportMenuItem[] = topLevelItems
        .map((topItem) => {
            const topBreadcrumb = topItem.breadcrumbs.at(-1)
            return {
                ...topBreadcrumb,
                articleId: topItem.id,
                label: topItem.title,
                subMenu: subMenuMap[topItem.id] || [],
                orderPriority: topItem.orderPriority,
            }
        })
        .sort((a, b) => a.orderPriority - b.orderPriority)

    return menuWithSubItems
}
