import { SignableDocument } from '#app/components/SignableDocument/SignableDocument'
import { useSignableDocumentListInstruction } from '#app/components/SignableDocument/SignableDocumentList'
import { useSelector } from '#app/state/useSelector'
import { DepositDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import * as selectors from '#state/selectors'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useCommand } from '#app/services/beta'
import { useAuthorization } from '#app/services/authorization'
import { Paper } from '#app/components'

const TerminationDocumentSign = ({ deposit }: { deposit: DepositDto }) => {
    const navigate = useNavigate()
    const auth = useAuthorization(deposit?.depositor.id)
    const { t } = useTranslation()
    const { cancelDepositTermination, startElectronicSignature } = useCommand()
    const signedAuthDocIsMissing = useSelector(
        (state) => selectors.signedAuthorizationDocumentIsMissing(state)[deposit?.depositor.id]
    )
    const terminationDocument = useSelector(
        (state) => deposit.terminationDocumentId && state.documents[deposit.terminationDocumentId]
    )
    const isCheckingSignatureStatus = useSelector(
        (state) =>
            deposit.terminationDocumentId && selectors.lookupSignatureStatus(state)(deposit.terminationDocumentId)
    )
    const terminationDocumentSignatureInstructions = useSignableDocumentListInstruction([terminationDocument])
    const isConditionalTermination = deposit.terminationDocumentType === 'CONDITIONAL_TERMINATION'
    const hasOrderPermissions = auth.depositor.hasOrderRole || auth.depositor.hasPartnerAccountantRole

    async function onStartSignature() {
        if (signedAuthDocIsMissing) {
            return
        }
        const processId = uuidv4()
        await startElectronicSignature(processId, 'SINGLE_DOCUMENT', terminationDocument.id)
        navigate(`/signature/${processId}?context=/portfolio/id/${deposit.id}`)
    }

    async function onCancelTermination() {
        const { waitForCommand } = await cancelDepositTermination(deposit.id)
        await waitForCommand()
    }

    return (
        <Paper
            title={
                isConditionalTermination
                    ? t('pages-portfolio-depositor.conditionalTerminationCreatedHeader')
                    : t('pages-portfolio-depositor.unconditionalTerminationCreatedHeader')
            }
        >
            {isConditionalTermination ? (
                <>
                    <p data-cy="terminationDocumentCreatedInfo">
                        {t('pages-portfolio-depositor.conditionalTerminationCreatedInfo1')}
                    </p>
                    <p>{t('pages-portfolio-depositor.conditionalTerminationCreatedInfo2')}</p>
                </>
            ) : (
                <p data-cy="terminationDocumentCreatedInfo">
                    {t('pages-portfolio-depositor.unconditionalTerminationCreatedInfo', {
                        signInstructions: terminationDocumentSignatureInstructions,
                    })}
                </p>
            )}
            <SignableDocument
                isCheckingSignatureStatus={isCheckingSignatureStatus}
                linkText={
                    isConditionalTermination
                        ? t('pages-portfolio-depositor.conditionalTerminationDocumentName')
                        : t('pages-portfolio-depositor.unconditionalTerminationDocumentName')
                }
                document={terminationDocument}
                onStartSignature={onStartSignature}
                onRemoveDocument={hasOrderPermissions && onCancelTermination}
                signingErrorText={
                    signedAuthDocIsMissing
                        ? t('pages-portfolio-depositor.terminationDocumentSigningErrorMissingAuthorization')
                        : t('pages-portfolio-depositor.terminationDocumentSigningErrorNotAccountHolder')
                }
                showSigningError={!auth.depositor.hasAccountHolderRole || signedAuthDocIsMissing}
            />
        </Paper>
    )
}

export { TerminationDocumentSign }
