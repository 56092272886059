import { CurrencyOutputObject } from '#app/components/CurrencyOutput/CurrencyOutput'
import { useSelector } from '#app/state/useSelector'
import { HelpIcon, InterestOutput } from '#components'
import { AdDto } from '@fixrate/fixrate-query'
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SupportCategoryNameType } from '#app/layers/PayloadSupportPane/utils/supportNameMappings'

type Props = {
    ad: AdDto
}

type Fact = {
    label: string | JSX.Element
    value: string | JSX.Element
    tooltipTitle?: string
    visible: boolean
    helpArticle?: SupportCategoryNameType
}

export default function AdDetails({ ad }: Props) {
    const { t } = useTranslation()
    const product = useSelector((state) => state.products[ad?.productId])
    const fixedProducts = [1, 2, 3, 4, 11, 12]
    const regularProducts = [5, 6, 7, 10]
    const frnProducts = [8, 9]

    const isRecurringFixed = product.termsType === 'RECURRING_FIXED_TERMS'
    const isFixed = product.type === 'FIXED'

    const calculateAdMaxVolume = (ad) => {
        if (ad.max > ad.remaining) {
            return ad.remaining
        } else {
            return ad.max
        }
    }

    const interestLabel = () => {
        if (isRecurringFixed) {
            return t('pages-marketplace.initialInterest')
        }
        if (isFixed) {
            return t('pages-marketplace.fixedInterest')
        }
        return t('pages-marketplace.floatingInterest')
    }

    const calculationOfInterest = () => {
        if (product.type === 'FIXED') {
            return InterestOutput.format(ad.interest)
        }
        let explanation: string
        switch (ad.interestRateBenchmark) {
            case 'NIBOR_3M':
                explanation = t('pages-marketplace.niborExplanation')
                break
            case 'SWB_VECKA':
                explanation = t('pages-marketplace.swbVeckaExplanation')
                break
            case 'STIBOR_3M':
                explanation = t('pages-marketplace.stiborExplanation')
                break
            default:
                explanation = ''
        }
        const benchmark = t('common.interestRateBenchmark.' + ad.interestRateBenchmark)

        if (explanation) {
            return (
                <span>
                    <Tooltip title={explanation}>
                        <Typography
                            fontWeight={'600'}
                            display={'inline-flex'}
                            fontSize={'1.4rem'}
                            sx={{ textDecoration: 'underline' }}
                        >
                            {t('common.interestRateBenchmark.' + ad.interestRateBenchmark)}
                        </Typography>
                    </Tooltip>
                    <Typography fontWeight={'600'} display={'inline-flex'} fontSize={'1.4rem'} mx={0.2}>
                        {ad.nominalInterestRate > 0 ? '+' : '-'}
                    </Typography>
                    <Tooltip title={t('pages-marketplace.marginAdditionExplanation', { benchmark })}>
                        <Typography
                            fontWeight={'600'}
                            display={'inline-flex'}
                            fontSize={'1.4rem'}
                            sx={{ textDecoration: 'underline' }}
                        >
                            {InterestOutput.format(ad.nominalInterestRate).replace('-', '')}
                        </Typography>
                    </Tooltip>
                </span>
            )
        } else {
            return (
                t('common.interestRateBenchmark.' + ad.interestRateBenchmark) +
                ' + ' +
                InterestOutput.format(ad.nominalInterestRate)
            )
        }
    }

    function getProductInfo(productId: string) {
        return {
            label: (
                <span>
                    {regularProducts.includes(parseInt(productId)) && t('pages-marketplace.noticePeriod')}
                    {fixedProducts.includes(parseInt(productId)) && t('pages-marketplace.duration')}
                    {frnProducts.includes(parseInt(productId)) && t('pages-marketplace.product')}
                </span>
            ),
            value: t(`common.productLongName${productId}`),
        }
    }

    const facts: Fact[] = [
        {
            label: ad.interestRateConvention === 'ACTUAL_360' ? t('common.interest') : t('common.effectiveInterest'),
            value: InterestOutput.format(ad.interest),
            visible: true,
        },
        {
            label: t('pages-marketplace.calculationOfInterest'),
            value: calculationOfInterest(),
            visible: !isFixed,
        },
        {
            label: t('pages-marketplace.amountLimit'),
            value:
                CurrencyOutputObject(ad.min, { withCurrency: false }) +
                '-' +
                CurrencyOutputObject(calculateAdMaxVolume(ad), { withCurrency: false }) +
                ' ' +
                t('pages-marketplace.millions'),
            visible: true,
        },
        {
            label: getProductInfo(product.id).label,
            helpArticle: 'terminationFloatingInterest',
            value: getProductInfo(product.id).value,
            tooltipTitle: t('pages-marketplace.noticePeriodExplanation'),
            visible: !isFixed,
        },
        {
            label: t('pages-marketplace.interestType'),
            value: interestLabel(),
            visible: true,
        },
        {
            label: t('pages-marketplace.interestConvention'),
            helpArticle: 'interestRateConvention',
            value: t('common.interestRateConvention.' + ad.interestRateConvention),
            visible: true,
        },
    ]

    return (
        <section>
            <Grid container spacing={2} mb={3}>
                {facts.map(
                    (fact, index) =>
                        fact.visible && (
                            <Grid key={index} flexWrap={'wrap'} item xs={6} sm={6}>
                                <Stack flex="1">
                                    <Stack direction="row" alignItems={'center'} height="2rem">
                                        <Typography variant={'body2'}>{fact.label}</Typography>
                                        {fact.helpArticle && (
                                            <Stack>
                                                <HelpIcon
                                                    supportCommonName={fact.helpArticle}
                                                    sx={{ fontSize: '2rem' }}
                                                />
                                            </Stack>
                                        )}
                                    </Stack>
                                    {fact.tooltipTitle ? (
                                        <Tooltip sx={{ display: 'inline-flex' }} title={fact.tooltipTitle}>
                                            <Typography
                                                display={'inline-flex'}
                                                sx={{ textDecoration: 'underline' }}
                                                variant={'h4'}
                                                mt={0.2}
                                            >
                                                {fact.value}
                                            </Typography>
                                        </Tooltip>
                                    ) : (
                                        <Typography variant={'h4'} mt={0.2}>
                                            {fact.value}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                        )
                )}
            </Grid>
        </section>
    )
}
