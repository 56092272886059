import { FormEvent, useState } from 'react'
import styles from '../Contact.module.scss'
import { PopupMessage } from '../../PopupMessage/PopupMessage'
import classNames from 'classnames'
import LoadingSpinner from '../../LoadingSpinner'
import { useSelector } from '#app/state/useSelector'
import FxDialog from '../../FxDialog/FxDialog'
import FxDialogContent from '../../FxDialog/FxDialogContent'
import FxDialogTitle from '../../FxDialog/FxDialogTitle'
import { useTranslation } from 'react-i18next'
import { Box, Button, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import FixrateIllustration from '../../FixrateIllustration/FixrateIllustration'
import { PURPLE } from '#app/colors/colors'
const CakeForm = ({
    title,
    formId,
    buttonText,
    onClose,
    open,
    onFinish,
}: {
    title?: string;
    formId: string;
    buttonText?: string;
    onClose: () => void;
    open: boolean;
    onFinish: () => void;
}) => {
    const profile = useSelector((state) => state.profile)
    const { t } = useTranslation()
    const association = useSelector((state) => state.session?.association)
    const [receiver, setReceiver] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [deliveryDate, setDeliveryDate] = useState('')
    const [cakeSize, setCakeSize] = useState<string>('Liten (6-8 personer)')

    const [loading, setLoading] = useState(false)
    const [popupMessage, setPopupMessage] = useState(false)
    const [error, setError] = useState(false)

    const closePopup = () => {
        setPopupMessage(false)
    }

    const closePopupAndReset = () => {
        setPopupMessage(false)
        setReceiver('')
        setAddress('')
        setCity('')
        setDeliveryDate('')
        setCakeSize('Liten (6-8 personer)')
        setError(false)
        onClose()
    }

    const getHsContext = () => {
        if (document) {
            if (document.cookie.indexOf('hubspotutk') !== -1) {
                const cookieValue = document.cookie
                    .split('; ')
                    ?.find((row) => row.startsWith('hubspotutk='))
                    ?.split('=')[1]
                return {
                    pageUri: window.location.href,
                    pageName: document.title,
                    hutk: cookieValue,
                }
            }
        }

        return {
            pageUri: window.location.href,
            pageName: document.title,
        }
    }

    const getFields = () => {
        return [
            {
                name: 'email',
                value: profile.email,
            },
            {
                name: 'phone',
                value: profile.mobile,
            },
            {
                name: 'kakemottakerens_navn',
                value: receiver + (association ? ' (' + association.name + ')' : ''),
            },
            {
                name: 'leveringsadresse',
                value: address,
            },
            {
                name: 'city',
                value: city,
            },
            {
                name: 'zip',
                value: zipCode,
            },
            {
                name: 'leveringsdato_og_klokkeslett',
                value: deliveryDate,
            },
            {
                name: 'kakestorrelse',
                value: cakeSize,
            },
        ]
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault()

        const formData = {
            submittedAt: Date.now(), // This millisecond timestamp is optional. Update the value from 1517927174000 to avoid an INVALID_TIMESTAMP error.
            fields: getFields(),
            context: getHsContext(),
        }
        setLoading(true)

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/7525826/' + formId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => {
                setPopupMessage(true)
                setError(false)
                setLoading(false)
                onFinish()
            })
            .catch((error) => {
                console.log(error)
                setPopupMessage(true)
                setError(true)
                setLoading(false)
            })
    }

    return (
        <FxDialog open={open} onClose={onClose}>
            <FxDialogTitle onClose={onClose}>
                { title ?? "Takk for din bestilling!" }
            </FxDialogTitle>
            <FxDialogContent sx={{pb: 3, pt: 0, px: 0}}>
                <Stack sx={{backgroundColor: PURPLE[50]}} alignItems={'center'}>
                    <Box sx={{ width: '25rem'}}>
                        <FixrateIllustration name="cake" color="default" />
                    </Box>
                </Stack>
                <Box px={3} pt={3}>
                    <p>Vennligst fyll ut skjemaet under for å få din vel fortjente kake tilsendt. Håper det smaker!</p>
                    <form
                        className={classNames(styles.fixrateForm, loading ? styles.disabledForm : '')}
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <Stack spacing={2}>
                            <Box>
                                <InputLabel>Hvem skal motta kaken?</InputLabel>
                                <TextField
                                    disabled={loading}
                                    name="kakemottakerens_navn"
                                    required
                                    value={receiver}
                                    onChange={(e) => setReceiver(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>Leveringsaddresse</InputLabel>
                                <TextField
                                    disabled={loading}
                                    name="leveringsadresse"
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>By</InputLabel>
                                <TextField
                                    disabled={loading}
                                    name="city"
                                    required
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>Postnummer</InputLabel>
                                <TextField
                                    disabled={loading}
                                    name="zip"
                                    required
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>Leveringsdato og klokkeslett</InputLabel>
                                <TextField
                                    multiline
                                    minRows={3}
                                    disabled={loading}
                                    name="leveringsdato_og_klokkeslett"
                                    required
                                    value={deliveryDate}
                                    onChange={(e) => setDeliveryDate(e.target.value)}
                                />
                            </Box>
                            <Box>
                                <InputLabel>Kakestørrelse</InputLabel>
                                <Select
                                    name="kakestorrelse"
                                    value={cakeSize}
                                    onChange={(e) => setCakeSize(e.target.value)}
                                >
                                    <MenuItem value="Liten (6-8 personer)">Liten (6-8 personer)</MenuItem>
                                    <MenuItem value="Medium (8-12 personer)">Medium (8-12 personer)</MenuItem>
                                    <MenuItem value="Stor (12-16 personer)">Stor (12-16 personer)</MenuItem>
                                </Select>
                            </Box>
                            {!loading && (
                                <Button sx={{ maxWidth: '30rem' }} variant="contained" type="submit">
                                    {buttonText ? buttonText : t('common.send')}
                                </Button>
                            )}
                            {loading && <LoadingSpinner text="Sender skjema, vennligst vent" />}
                        </Stack>
                    </form>
                    {popupMessage && !error && (
                        <PopupMessage
                            closePopup={closePopupAndReset}
                            message={'Takk for din henvendelse, vi kommer tilbake til deg så fort som mulig!'}
                        ></PopupMessage>
                    )}
                    {popupMessage && error && (
                        <PopupMessage
                            closePopup={closePopup}
                            success={false}
                            message={'Noe gikk galt når vi skulle sende skjemaet, vennligst prøv på nytt.'}
                        ></PopupMessage>
                    )}
                </Box>
            </FxDialogContent>
        </FxDialog>
    )
}

export default CakeForm
