import SuccessIcon from './assets/SuccessIcon.svg?url'
import ErrorIcon from './assets/ErrorIcon.svg?url'

const ICON_MAP = {
    success: SuccessIcon,
    error: ErrorIcon,
}

export type ConfirmationIconName = keyof typeof ICON_MAP

type Props = {
    name: ConfirmationIconName
    width?: string
}

export default function ConfirmationIcon({ name = 'success', width = 'auto' }: Props): JSX.Element | null {
    const illustration = ICON_MAP[name]

    return illustration ? <img style={{ maxWidth: '100%', width: width }} src={illustration} alt="" /> : null
}
