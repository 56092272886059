import config from '../../config'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

type Report = 'DEPOSITS' | 'FUND_TRANSACTIONS_EXPORT' | 'DEPOSITOR_FUND_TRANSACTIONS_EXPORT'

const API_BASE_URL = config().apiUrl

const ReportDownloadExcel = ({ report, additionalParams }: { report: Report; additionalParams?: URLSearchParams }) => {
    const { t } = useTranslation()

    const url = `${API_BASE_URL}/document/report/${report}${additionalParams ? `?${additionalParams.toString()}` : ''}`
    return (
        <Button
            variant="outlined"
            color="primary"
            size="small"
            {...{ href: '', onClick: () => (window.location.href = `${url}`) }}
            startIcon={<i className="ri-file-excel-2-line" />}
        >
            {t('pages-portfolio-depositor.reportsDownloadExcel')}
        </Button>
    )
}

export default ReportDownloadExcel
