import { ordersCount } from '#state/selectors'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import styles from './OrderNavigation.module.scss'

interface Link {
    pathname: string
    icon: string
    orderCount: number
    text: string
    hideEmpty: boolean
    ['data-cy']: string
}

export default function OrderNavigation() {
    const { t } = useTranslation()
    const orderCounts = useSelector(ordersCount)
    const location = useLocation()

    const bankIcon = 'ri-bank-line'
    const businessIcon = 'ri-building-line'

    const [menuOpen, toggleMenu] = useState(false)

    const links: Link[] = [
        {
            pathname: '/orders/waiting',
            icon: bankIcon,
            orderCount: orderCounts.ordersWithStateInitialProcessing,
            text: t('menu.bankProcessing'),
            hideEmpty: false,
            'data-cy': 'orderNavigationWaiting',
        },
        {
            pathname: '/orders/signing',
            icon: businessIcon,
            orderCount: orderCounts.ordersWithStateReadyForSigning,
            text: t('menu.readyToSign'),
            hideEmpty: false,
            'data-cy': 'orderNavigationSigning',
        },
        {
            pathname: '/orders/approval',
            icon: bankIcon,
            orderCount: orderCounts.ordersWithStateReadyForApproval,
            text: t('menu.awaitingActivation'),
            hideEmpty: false,
            'data-cy': 'orderNavigationApproval',
        },
        {
            pathname: '/orders/transaction',
            icon: businessIcon,
            orderCount: orderCounts.ordersWithStateReadyForTransaction,
            text: t('menu.readyForPayment'),
            hideEmpty: false,
            'data-cy': 'orderNavigationTransaction',
        },
        {
            pathname: '/orders/transaction-confirmation',
            icon: bankIcon,
            orderCount: orderCounts.ordersWithStateReadyForTransactionConfirmation,
            text: t('menu.awaitingBankConfirmation'),
            hideEmpty: false,
            'data-cy': 'orderNavigationConfirmation',
        },
    ]

    const selectedLink = links.find((link) => link.pathname === location.pathname)

    function getStepBody(link: Link) {
        return (
            <>
                <div>
                    <div className={styles.line}>
                        <div className={styles.lineBefore}></div>
                        <div className={styles.lineAfter}></div>
                    </div>
                    <div className={styles.stepCircle}>
                        {link.orderCount !== 0 && (
                            <span className={styles.orderCount}>
                                <span>{link.orderCount}</span>
                            </span>
                        )}
                        <div className={styles.iconWrapper}>
                            <i className={link.icon}></i>
                        </div>
                    </div>
                </div>
                <div>
                    <span className={styles.navText}>{link.text}</span>
                </div>
                {link.orderCount !== 0 && (
                    <span className={styles.mobileCounter}>
                        <span>{link.orderCount}</span>
                    </span>
                )}
                <i className={classNames(styles.downIcon, 'ri-arrow-down-s-line')}></i>
            </>
        )
    }

    const mobileNavigation = (
        <div className={classNames(styles.step, styles.mobileSelectedStep)} onClick={() => toggleMenu(!menuOpen)}>
            {getStepBody(selectedLink)}
            <i className={classNames(styles.menuIcon, menuOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line')}></i>
        </div>
    )

    const steps = links
        .filter((l) => !(l.hideEmpty && l.orderCount === 0))
        .map((link) => (
            <NavLink
                data-cy={link['data-cy']}
                key={link.pathname}
                to={link.pathname}
                className={({ isActive }) => classNames(styles.step, { [styles.activeState]: isActive })}
                onClick={() => toggleMenu(false)}
            >
                {getStepBody(link)}
            </NavLink>
        ))

    return (
        <div className={classNames(styles.stepNavigation, styles.mobileMenu, menuOpen ? styles.menuOpen : '')}>
            {mobileNavigation}
            {steps}
        </div>
    )
}
