import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import CompanySelect from '#components/CompanySelect'
import { CompanyInfo } from '#components/CompanySelect/CompanySelect'
import styles from '#app/layers/Signup/RegisterCompany.module.scss'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'
import { useTranslation } from 'react-i18next'

export default function RegisterFundCustomer() {
    const { t } = useTranslation()

    const [company, setCompany] = useState<CompanyInfo | null>(null)
    const [error, setError] = useState('')
    const [submitting, setSubmitting] = useState(false)

    const { registerFundCustomer } = useCommand()

    async function submit() {
        if (!company?.nationalIdentity) {
            setError(t('pages.fundCustomers.missingNationalIdentity'))
        }

        setSubmitting(true)
        const { waitForCommand } = await registerFundCustomer(company.nationalIdentity)
        const success = await waitForCommand()
        setSubmitting(false)
        if (success) {
            resetForm()
        }
    }

    function resetForm() {
        setCompany(null)
        setError('')
    }

    return (
        <Stack spacing={2}>
            <Box>
                <CompanySelect
                    onChange={setCompany}
                    setErrorMessage={setError}
                    placeholder={t('pages.fundCustomers.brregSearch')}
                />
                <Box className={styles.errorMessage}>{error}</Box>
            </Box>
            <Box>
                <LoadingButton
                    variant={'contained'}
                    onClick={submit}
                    loading={submitting}
                    disabled={!company || submitting}
                >
                    {t('pages.fundCustomers.registerNewCustomer')}
                </LoadingButton>
            </Box>
        </Stack>
    )
}
