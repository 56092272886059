import { Fragment, useState } from 'react'
import classNames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

type Props = {
    dataCy?: string | null
    id?: string
    checked: boolean
    onChange: () => void
    disabled?: boolean
    className?: string | null
}

export default function ToggleSwitch({ dataCy = null, id, checked, onChange, disabled, className = null }: Props) {
    const [inProgress, setInProgress] = useState(false)

    const markupId = id || uuidv4()
    return (
        <Fragment>
            <input
                id={markupId}
                data-cy={dataCy}
                type="checkbox"
                className={classNames(
                    'toggle-switch__checkbox',
                    (inProgress || disabled) && 'toggle-switch__checkbox_disabled'
                )}
                onChange={() => {
                    // In progress functionality only works if onChange returns a promise
                    // Promise.all resolves immediately if not
                    setInProgress(true)
                    Promise.resolve(onChange()).then(() => {
                        setInProgress(false)
                    })
                }}
                disabled={disabled}
                checked={checked}
            />
            <label
                htmlFor={markupId}
                className={classNames(
                    'toggle-switch__slider',
                    (inProgress || disabled) && 'toggle-switch__slider_disabled',
                    className
                )}
            />
        </Fragment>
    )
}
