import { FixrateColor, SUNSET_ORANGE } from '#app/colors/colors'
import { Box, Card, CardContent, Divider, Stack, SxProps, Typography } from '@mui/material'

const ColorKeyFigureCard = ({
    color,
    title,
    description,
    keyFigure,
    keyFigureAlternateText,
    metaDescription,
    children,
    sx,
}: {
    color: FixrateColor
    title: string
    description: string
    keyFigure: string
    keyFigureAlternateText?: string
    metaDescription?: string
    children?: React.ReactNode
    sx?: SxProps
}) => {
    let titleColorValue = 800
    let descriptionColorValue = 700
    let dividerColor = 100

    if (color === SUNSET_ORANGE) {
        titleColorValue = 900
        descriptionColorValue = 800
        dividerColor = 100
    }
    return (
        <Card sx={{ backgroundColor: color[50], boxShadow: 'none', ...sx }} elevation={0}>
            <CardContent>
                <Stack spacing={0.5} sx={{ py: 2, px: 1 }}>
                    <Typography variant="h3" color={color[titleColorValue]}>
                        {title}
                    </Typography>
                    <Typography color={color[descriptionColorValue]} fontSize="1.4rem">
                        {description}
                    </Typography>
                    <Stack mt={1} direction="row" alignItems="flex-end" justifyContent="space-between">
                        <Typography variant="interestBig" color={color[titleColorValue]}>
                            {keyFigure}
                        </Typography>
                        <Typography fontSize={'2rem'} fontWeight={600} color={color[titleColorValue]}>
                            {keyFigureAlternateText}
                        </Typography>
                    </Stack>
                    {metaDescription && (
                        <Stack mt={1.5} spacing={1.5}>
                            <Divider sx={{ borderColor: color[900] + '50' }} />
                            <Typography variant="caption" component={'p'} color={color[descriptionColorValue]}>
                                {metaDescription}
                            </Typography>
                        </Stack>
                    )}
                    {children}
                </Stack>
            </CardContent>
        </Card>
    )
}

export { ColorKeyFigureCard }
