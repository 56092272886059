import UserFilterSelector from '#blocks/UserFilterSelector/UserFilterSelector'
import { useCommand } from '#command'
import Button from '#components/Button'
import { useTranslation } from 'react-i18next'
import Paper from '#app/components/Paper/FxPaper'
import ToggleSwitch from '#components/ToggleSwitch'
import NewNotificationSettings from '#pages/profile/NewNotificationSettings'
import { useSelector } from '#state/useSelector'
import { LegacyNotificationSettingDto } from '@fixrate/fixrate-query'
import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EmailSettings from './EmailSettings/EmailSettings'
import styles from './NotificationSettings.module.scss'
import HelpIcon from '#components/HelpIcon/HelpIcon'

function getTextKey(setting) {
    switch (setting.notificationType) {
        case 'NEW_ORDER':
            return 'newOrder'
        case 'DOCUMENT_SIGNED':
            return 'documentSigned'
        case 'DEPOSIT_TERMINATED':
            if (setting.associationType === 'BANK') {
                return 'depositTerminated'
            } else if (setting.associationType === 'DEPOSITOR') {
                return 'depositConfirmedTerminated'
            }
            return null
        case 'ORDER_PAYMENT_NOT_CONFIRMED_BY_BANK':
            return 'orderPaymentNotConfirmedByBank'
        case 'DEPOSIT_TERMINATION_REACHED':
            return 'depositTerminationReached'
        case 'ORDER_COMPLETED':
            return 'orderCompleted'
        case 'AD_EXPIRED':
            return 'adExpired'
        case 'SETTLEMENT_ACCOUNT_CHANGED':
            return 'settlementAccountChanged'
        case 'SETTLEMENT_ACCOUNT_CHANGE_CANCELED':
            return 'settlementAccountChangedCanceled'
        case 'DOCUMENT_READY_FOR_SIGNING':
            return 'documentReadyForSigning'
        case 'ACCOUNT_ACTIVATED':
            return 'accountActivated'
        case 'DEPOSIT_TERMINATION_CANCELED':
            return 'depositTerminationCanceled'
        case 'NEW_AD':
            return 'newAd'
        case 'SETTLEMENT_ACCOUNT_CHANGE_CONFIRMED':
            return 'settlementAccountChangeConfirmed'
        case 'SETTLEMENT_ACCOUNT_ADDED':
            return 'settlementAccountAdded'
        case 'INTEREST_RATE_CHANGE_MESSAGE':
            return 'interestRateChange'
        case 'ORDER_CANCELED':
            return 'orderCanceled'
        default:
            return null
    }
}

const SettingList = ({
    settings,
    onChange,
}: {
    settings: LegacyNotificationSettingDto[]
    onChange: (setting: LegacyNotificationSettingDto) => void
}) => {
    const { t } = useTranslation()

    const list = settings.map((setting) => {
        const key = `${setting.associationType}-${setting.notificationType}`
        return (
            <li key={key} className={styles.notificationSettings__setting}>
                <div>
                    <label htmlFor={key}>{t('pages-profile.' + getTextKey(setting))}</label>
                    <p className={styles.notificationSettings__description}>
                        {t('pages-profile.' + getTextKey(setting) + 'Verbose')}
                    </p>
                </div>
                <ToggleSwitch id={key} checked={setting.notify} onChange={() => onChange(setting)} />
            </li>
        )
    })
    return <ul>{list}</ul>
}

export default function NotificationSettings() {
    const { t } = useTranslation()

    const [showNewNotificationSetting, setShowNewNotificationSetting] = useState(false)
    const notificationSettings = useSelector((state) => state.notificationSettings)
    const { legacySettings } = notificationSettings
    const navigate = useNavigate()
    const { setNotificationSetting, setUserLegacyNotifications } = useCommand()

    async function onChange(setting) {
        const { waitForCommand } = await setNotificationSetting(
            setting.notificationType,
            setting.associationType,
            !setting.notify
        )
        await waitForCommand()
    }

    async function onUserLegacyNotificationsChange(value) {
        const { waitForCommand } = await setUserLegacyNotifications(value)
        await waitForCommand()
        setShowNewNotificationSetting(false)
        if (!value) {
            navigate('/profile')
        }
    }

    let hasFixrateSettings = null
    let hasBankSettings = null
    let hasDepositorSettings = null
    let settingsByAssociationType = null

    if (legacySettings) {
        settingsByAssociationType = legacySettings.reduce(
            (result, setting) => {
                switch (setting.associationType) {
                    case 'BANK':
                        result.bankSettings.push(setting)
                        break
                    case 'DEPOSITOR':
                        result.depositorSettings.push(setting)
                        break
                    case 'FIXRATE':
                        result.fixrateSettings.push(setting)
                        break
                }
                return result
            },
            { bankSettings: [], depositorSettings: [], fixrateSettings: [] }
        )

        hasFixrateSettings = settingsByAssociationType.fixrateSettings.length > 0
        hasBankSettings = settingsByAssociationType.bankSettings.length > 0
        hasDepositorSettings = settingsByAssociationType.depositorSettings.length > 0
    }

    return (
        <>
            <Paper
                id="email-notifications"
                className={styles.emailNotifications}
                title={t('pages-profile.emailNotifications')}
                supportCommonName="rolesEmail"
            >
                <Stack spacing={1}>
                    <Typography variant="h4">{t('pages-profile.systemNotifications')}</Typography>
                    {settingsByAssociationType && (
                        <>
                            {hasFixrateSettings && (
                                <section className={styles.notificationSettings__section}>
                                    <h4 className={styles.notificationSettings__sectionHeader}>
                                        {t('pages-profile.asFixrateEmployee')}
                                    </h4>
                                    <SettingList
                                        settings={settingsByAssociationType.fixrateSettings}
                                        onChange={onChange}
                                    />
                                </section>
                            )}
                            {hasBankSettings && (
                                <section className={styles.notificationSettings__section}>
                                    <h4 className={styles.notificationSettings__sectionHeader}>
                                        {t('pages-profile.asBankEmployee')}
                                    </h4>
                                    <SettingList
                                        settings={settingsByAssociationType.bankSettings}
                                        onChange={onChange}
                                    />
                                </section>
                            )}
                            {hasDepositorSettings && (
                                <section className={styles.notificationSettings__section}>
                                    {(hasFixrateSettings || hasBankSettings) && (
                                        <h4 className={styles.notificationSettings__sectionHeader}>
                                            {t('pages-profile.asDepositor')}
                                        </h4>
                                    )}
                                    <SettingList
                                        settings={settingsByAssociationType.depositorSettings}
                                        onChange={onChange}
                                    />
                                </section>
                            )}
                            <Button
                                className={styles.tryNewSolutionButton}
                                variant={'secondary'}
                                onClick={() => setShowNewNotificationSetting(true)}
                            >
                                {t('pages-profile.emailNotificationModalOpenButton')}
                            </Button>
                        </>
                    )}
                </Stack>
                {!legacySettings && <EmailSettings />}
                <Stack spacing={1} sx={{ py: 2 }}>
                    <Typography variant="h3">
                        {t('pages-profile.adNotifications')}
                        <HelpIcon supportCommonName="depositorAds" />
                    </Typography>
                    <p className="secondary-description">{t('pages-profile.adNotificationsDescription')}</p>
                    <UserFilterSelector />
                </Stack>
            </Paper>
            {showNewNotificationSetting && (
                <NewNotificationSettings
                    onUserLegacyNotificationsChange={onUserLegacyNotificationsChange}
                    onCancel={() => setShowNewNotificationSetting(false)}
                />
            )}
        </>
    )
}
