import usePortfolio from '#app/services/usePortfolio'
import { NotAvailableInCurrency } from './Analytics'
import AnalyticsDashboard from './dashboard/AnalyticsDashboard'

export default function AnalyticsDashboards() {
    const portfolio = usePortfolio()
    const currency = portfolio?.currency

    return currency === 'NOK' ? <AnalyticsDashboard /> : <NotAvailableInCurrency />
}
