import { Fragment, useEffect, useState } from 'react'
import styles from './TestingGrounds.module.scss'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'motion/react'
import { CurrencyOutput, InterestOutput, PageHeader, PageLayout } from '#components'
import { Box } from '@mui/material'
import { useSelector } from '#state/useSelector'
import { AdDto, BankLimitedDto, Product } from '@fixrate/fixrate-query'
import BankDetails from '#pages/marketplace/AdCardDetails/BankDetails'
import { defaultBenchmark } from '#services/interestRateBenchmark'
import { useTranslation } from 'react-i18next'

export default function TestingGrounds() {
    const [selectedAdId, setSelectedAdId] = useState<string | null>(null)

    const ads = useSelector((state) => state.ads)
    const banks = useSelector((state) => state.banks)
    const products = useSelector((state) => state.products)

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown)
        return () => window.removeEventListener('keydown', onKeyDown)
    }, [])

    function onKeyDown(e) {
        const key = e.keyCode || e.which

        switch (key) {
            // ESC
            case 27: {
                e.preventDefault()
                setSelectedAdId(null)
                break
            }
        }
    }

    const ad = ads.find((ad) => ad.id === selectedAdId)
    const bank = banks[ad?.bankId ?? '']
    const product = products[ad?.productId ?? '']

    return (
        <Fragment>
            <PageHeader title={'Gards eksperimenter'} />
            <PageLayout>
                <Box className={styles.layout}>
                    {ads.map((ad) => (
                        <AdCard
                            key={ad.id}
                            ad={ad}
                            bank={banks[ad.bankId]}
                            product={products[ad.productId]}
                            onClick={(ad) => setSelectedAdId(ad?.id ?? null)}
                        />
                    ))}
                </Box>
                <Box
                    onClick={() => setSelectedAdId(null)}
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        pointerEvents: selectedAdId ? 'initial' : 'none',
                        background: selectedAdId ? 'rgba(0,0,0,0.5)' : 'transparent',
                        transition: 'background 0.5s',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AnimatePresence>
                        {ad && (
                            <AdCard
                                ad={ad}
                                bank={bank}
                                product={product}
                                expanded
                                onClick={(ad) => setSelectedAdId(ad?.id ?? null)}
                            />
                        )}
                    </AnimatePresence>
                </Box>
            </PageLayout>
        </Fragment>
    )
}

type AdCardProps = {
    ad: AdDto
    bank: BankLimitedDto
    product: Product
    expanded?: boolean
    onClick?: (ad: AdDto | null) => void
}

function AdCard({ ad, bank, product, expanded, onClick }: AdCardProps) {
    const { t } = useTranslation()
    const niborInterestFormatted = InterestOutput.formatMarginWithBenchmarkObj(
        ad.nominalInterestRate,
        defaultBenchmark(ad.currency),
        t
    )
    return (
        <motion.div
            layoutId={ad.id}
            onClick={() => onClick?.(!expanded ? ad : null)}
            className={classNames(styles.card, expanded && styles.expanded)}
        >
            {expanded && (
                <motion.div className={styles.title}>
                    <BankName ad={ad} bank={bank} expanded />
                </motion.div>
            )}
            {expanded && (
                <motion.div
                    className={styles.sidebar}
                    transition={{ delay: 0.2 }}
                    initial={{ width: 0, opacity: 0, x: -50 }}
                    animate={{ width: 240, opacity: 1, x: 0 }}
                >
                    <BankDetails isDepositorAndHasOrganisation={true} bank={bank} />
                </motion.div>
            )}
            <motion.div className={styles.body}>
                {!expanded && <BankName ad={ad} bank={bank} />}
                <motion.div
                    layoutId={ad.id + 'interestGroup'}
                    className={classNames(styles.interestgroup, expanded && styles.expanded)}
                >
                    {expanded && <motion.div>Flytende rente</motion.div>}
                    <motion.div layoutId={ad.id + 'interest'} className={styles.interest}>
                        {InterestOutput.format(ad.interest)}
                    </motion.div>
                    <motion.div layoutId={ad.id + 'nibor'} className={styles.nibor}>
                        {product.type === 'FIXED' ? (
                            <motion.span>Fast rente</motion.span>
                        ) : (
                            <Fragment>
                                <motion.span className={styles.niborLabel}>{niborInterestFormatted?.label}</motion.span>
                                <motion.strong className={styles.niborValue}>
                                    {niborInterestFormatted?.interest}
                                </motion.strong>
                            </Fragment>
                        )}
                    </motion.div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

function BankName({ ad, bank, expanded }: { ad: AdDto; bank: BankLimitedDto; expanded?: boolean }) {
    return (
        <motion.div layoutId={ad.id + 'bank'} className={classNames(styles.bank, expanded && styles.expanded)}>
            <motion.span layoutId={ad.id + 'bankIcon'} className={styles.icon}>
                <motion.i className="ri-bank-line" />
            </motion.span>
            <motion.div>
                <motion.span
                    layoutId={ad.id + 'bankName'}
                    className={classNames(styles.name, expanded && styles.expanded)}
                >
                    {bank?.label || 'Bank'}
                </motion.span>
                {!expanded && (
                    <motion.span className={styles.totalAssets}>
                        {CurrencyOutput.formatNoCode((bank?.totalAssets ?? 0) / 1000, 1)} mrd. i forvaltningskapital
                    </motion.span>
                )}
            </motion.div>
        </motion.div>
    )
}
