import { useDispatch } from 'react-redux'
import { useSelector } from '#state/useSelector'
import { closeConfirmationModal, showConfirmationModal } from '#state/reducers/confirmationModal'
import styles from './ConfirmationModal.module.scss'
import Modal from '#components/Modal'
import { useTranslation } from 'react-i18next'
import ConfirmationIcon from './ConfirmationIcon'
import FixrateIllustration from '#components/FixrateIllustration/FixrateIllustration'

/**
 * A modal dialog designed to show a mssage that confirms that a function has completed successfully
 *
 * Use the <code>useConfirmationModal</code> function to activate the modal.
 */
export default function ConfirmationModal() {
    const confirmationModal = useSelector((state) => state.confirmationModal)
    const dispatch = useDispatch()

    // Should not be visible if showModal is false
    if (!confirmationModal.showModal) return null

    function handleClick() {
        dispatch(closeConfirmationModal({}))
    }

    return (
        <Modal
            onSubmit={handleClick}
            submitButtonText={confirmationModal.buttonText}
            submitButtonId={'modalConfirmButton'}
            className={styles.body}
            alignButtons={'center'}
        >
            <div className={styles.contentWrapper}>
                {confirmationModal.illustrationName && (
                    <FixrateIllustration
                        name={confirmationModal.illustrationName}
                        color={confirmationModal.illustrationColor}
                    />
                )}
                {confirmationModal.sentIcon && !confirmationModal.illustrationName && (
                    <FixrateIllustration name={'emailSent'} color="default" />
                )}
                {!confirmationModal.sentIcon && !confirmationModal.illustrationName && (
                    <ConfirmationIcon name={confirmationModal.confirmationIconName ?? 'success'} />
                )}
                <h2 className={styles.title}>{confirmationModal.title}</h2>
                <p>{confirmationModal.text}</p>
            </div>
        </Modal>
    )
}

export function useConfirmationModal() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    return function confirmationModal(
        title: string,
        text: string,
        buttonText: string = t('layers-ConfirmationModal.continue')
    ) {
        dispatch(
            showConfirmationModal({
                title,
                text,
                buttonText,
            })
        )

        // Returns a function that will close the modal
        return () => dispatch(closeConfirmationModal({}))
    }
}
