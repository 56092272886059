import { NumberInput, PageHeader, PageLayout } from '#components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import config from '#app/config'
import restEndpoint from '#services/rest/rest-endpoint'
import { POST_NO_RESPONSE } from '#services/network/rest-connection'
import {
    Divider,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from '@mui/material'
import AsyncButton from '#components/Button/AsyncButton'
import Paper from '#app/components/Paper/FxPaper'
import format from 'date-fns/format'
import { showConfirmationModal } from '#state/reducers/confirmationModal'

type FixtureOption = {
    value: string
    label: string
}

type CurrentFixture = {
    label: string
    loaded: Date
    newEvents: number
}

function sortFixtures(fixtures: FixtureOption[]) {
    const fixturesWithNumbers = fixtures
        .filter((fixture) => fixture.label.match(/IntegrationTest\.\d+/))
        .sort((a, b) => {
            const aNumber = parseInt(a.label.split('.')[1])
            const bNumber = parseInt(b.label.split('.')[1])
            return bNumber - aNumber
        })
    const fixturesWithoutNumbers = fixtures
        .filter((fixture) => !fixture.label.match(/IntegrationTest\.\d+/))
        .sort((a, b) => a.label.localeCompare(b.label))
    return fixturesWithNumbers.concat(fixturesWithoutNumbers)
}

function sortFixturesInDemo(fixtures: FixtureOption[]) {
    const fixturesWithDates = fixtures
        .filter((fixture) => fixture.label.startsWith('20'))
        .sort((a, b) => b.label.localeCompare(a.label))
    const fixturesWithoutDates = fixtures
        .filter((fixture) => !fixture.label.startsWith('20'))
        .sort((a, b) => a.label.localeCompare(b.label))
    return fixturesWithDates.concat(fixturesWithoutDates)
}

function nextIntegrationTestNumber(fixtures) {
    const highestNumber = fixtures
        .filter((fixture) => fixture.label.match(/IntegrationTest\.\d+/))
        .reduce((acc, a) => {
            const current = parseInt(a.label.split('.')[1])
            return current > acc ? current : acc
        }, 0)
    return highestNumber + 1
}

export function Fixtures() {
    const [selectedOption, setSelectedOption] = useState('')
    const [newFixtureName, setNewFixtureName] = useState('')
    const [shiftDays, setShiftDays] = useState(0)
    const [fixtures, setFixtures] = useState<FixtureOption[]>([])
    const [currentFixture, setCurrentFixture] = useState<CurrentFixture>(null)
    const [timestamp, setTimestamp] = useState<string>('')
    const [working, setWorking] = useState<boolean>(false)
    const dispatch = useDispatch()

    // Update timestamp every second
    useEffect(() => {
        const interval = setInterval(() => {
            if (!working) {
                setTimestamp(`${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [dispatch, working])

    useEffect(() => {
        restEndpoint(dispatch)
            .getFixtures()
            .then((result) => {
                if (result) {
                    const fixtures = result.map((fixture) => ({ value: fixture, label: fixture }))

                    let sortedFixtures: FixtureOption[]
                    if (config().environment === 'DEMO') {
                        sortedFixtures = sortFixturesInDemo(fixtures)
                    } else {
                        sortedFixtures = sortFixtures(fixtures)
                    }

                    setFixtures(sortedFixtures)
                }
            })
        restEndpoint(dispatch)
            .getCurrentFixture()
            .then((result) => {
                if (result) {
                    setCurrentFixture(result)
                }
            })
    }, [dispatch, working])

    async function activateLatestFixture() {
        const latestFixture = fixtures[0].value
        await activateFixture(latestFixture)
    }

    async function activateFixture(label) {
        setWorking(true)
        const fixtureToActivate = label || selectedOption
        console.log(`Activating fixture: ${fixtureToActivate}`)
        await POST_NO_RESPONSE(dispatch, '/api/fixtures/' + fixtureToActivate, '')
        dispatch(
            showConfirmationModal({
                title: 'Baseline is loading',
                text: 'The baseline is now loading. This may take up to several minutes. Wait until you are logged out and then log in again.',
                buttonText: 'OK',
            })
        )
        setWorking(false)
    }

    async function saveFixture(name) {
        setWorking(true)
        await POST_NO_RESPONSE(dispatch, '/api/fixtures/' + name + '/new', '')
        setWorking(false)
    }

    async function shift() {
        if (!shiftDays && shiftDays !== 0) {
            return // Must be defined, but 0 is ok
        }
        try {
            setWorking(true)
            dispatch(
                showConfirmationModal({
                    title: 'Running time machine',
                    text: 'All events will now be moved back in time. This may take a while. You may have to refresh any open pages to see the changes.',
                    buttonText: 'OK',
                })
            )
            await POST_NO_RESPONSE(dispatch, '/api/fixtures/shift?days=' + shiftDays, '')
        } finally {
            setWorking(false)
        }
    }

    if (!config().fixture.read) {
        return null
    } else {
        const tableCellStyle = { borderBottom: 'none', fontSize: '1.6rem', padding: '0.5rem' }
        return (
            <>
                <PageHeader icon="ri-save-line" title={'Baseline'} />
                <PageLayout>
                    <Paper title={'Testdata'}>
                        <Stack direction="column" spacing={2}>
                            {currentFixture && (
                                <>
                                    <Table sx={{ maxWidth: 400, fontSize: 1.6 }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle} component="th" scope="row">
                                                    Current baseline:
                                                </TableCell>
                                                <TableCell sx={tableCellStyle} align="left">
                                                    {currentFixture.label}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={tableCellStyle} component="th" scope="row">
                                                    System events after this baseline:
                                                </TableCell>
                                                <TableCell sx={tableCellStyle} align="left">
                                                    {currentFixture.newEvents}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <AsyncButton
                                        sx={{ maxWidth: 240 }}
                                        onClick={() => activateLatestFixture()}
                                        disabled={currentFixture.label === null || working}
                                    >
                                        {'Reset to latest baseline'}
                                    </AsyncButton>
                                </>
                            )}

                            {config().fixture.write && (
                                <>
                                    <Divider />

                                    <p style={{ marginBottom: 0 }}>{'You may load a different baseline.'}</p>

                                    <Select
                                        name="field-fixture"
                                        value={selectedOption || ''}
                                        placeholder={'Velg fixture'}
                                        onChange={(e: SelectChangeEvent) => setSelectedOption(e.target.value)}
                                    >
                                        <MenuItem value={''}>Velg fixture</MenuItem>
                                        {fixtures.map((fixture, index) => (
                                            <MenuItem key={index} value={fixture.value}>
                                                {fixture.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <AsyncButton
                                        sx={{ maxWidth: 240 }}
                                        onClick={() => activateFixture(selectedOption)}
                                        variant={'outlined'}
                                        disabled={!selectedOption || working}
                                    >
                                        {'Load baseline'}
                                    </AsyncButton>
                                </>
                            )}
                        </Stack>
                    </Paper>
                    <Paper title={'Create new baseline'}>
                        <Stack direction="column" spacing={2}>
                            <p style={{ marginBottom: 0 }}>{'You can save the current state as a new baseline.'}</p>
                            {config().environment === 'DEMO' && (
                                <>
                                    <TextField
                                        label={'Optional short description'}
                                        name="description"
                                        value={newFixtureName}
                                        onChange={(e) => setNewFixtureName(e.target.value)}
                                    />
                                    <div>Name of the new baseline: {timestamp + ' ' + newFixtureName}</div>
                                    <AsyncButton
                                        sx={{ maxWidth: 240 }}
                                        variant={'outlined'}
                                        onClick={() =>
                                            saveFixture(timestamp + (newFixtureName ? ' ' + newFixtureName : ''))
                                        }
                                        disabled={working}
                                    >
                                        {'Create new baseline'}
                                    </AsyncButton>
                                </>
                            )}
                            {config().environment !== 'DEMO' && (
                                <>
                                    <div>Navn: {`IntegrationTest.${nextIntegrationTestNumber(fixtures)}`}</div>
                                    <AsyncButton
                                        sx={{ maxWidth: 240 }}
                                        variant={'outlined'}
                                        onClick={() =>
                                            saveFixture(`IntegrationTest.${nextIntegrationTestNumber(fixtures)}`)
                                        }
                                        disabled={working}
                                    >
                                        {'Lag ny fixture'}
                                    </AsyncButton>
                                </>
                            )}
                        </Stack>
                    </Paper>
                    <Paper title={'Time machine'}>
                        <Stack direction="column" spacing={2}>
                            <p style={{ marginBottom: 0 }}>{'Push all current events back in time'}</p>
                            <p style={{ marginBottom: 0 }}>
                                {
                                    'Example: If you have an order made last week, and push the events back in time 7 days, then the order will appear as if it was made two weeks ago.'
                                }
                            </p>
                            <Stack direction="row" spacing={2}>
                                <NumberInput value={0} onChange={setShiftDays} label={'Days'} />
                                <AsyncButton
                                    sx={{ maxWidth: 240 }}
                                    variant={'outlined'}
                                    onClick={shift}
                                    disabled={working}
                                >
                                    {'Push events back in time'}
                                </AsyncButton>
                            </Stack>
                        </Stack>
                    </Paper>
                </PageLayout>
            </>
        )
    }
}
