import { DepositDto } from '@fixrate/fixrate-query'

export default function getDepositValue(deposit: DepositDto): number {
    // Pri. 1: Final balance of a terminated deposit
    if (deposit.calculatedFinalBalance) {
        return deposit.calculatedFinalBalance
    }
    // Pri. 2: Estimated data for today, using the most recent available nibor
    if (deposit.estimatedTotalBalance) {
        return deposit.estimatedTotalBalance
    }
    // Pri. 3: Calculated balance with accrued interest. For nibor products, this will be at the same day as the last nibor.
    // Pri. 4: Calculated balance without accrued interest. This is updated yearly, and at the deposit's start and end.
    return deposit.calculatedTotalBalance ? deposit.calculatedTotalBalance : deposit.calculatedBalance
}
