import { Fragment } from 'react'

import { DateOutput } from '#components'
import styles from './OrdersDepositorWaiting.module.scss'
import { useTranslation } from 'react-i18next'
import { BankLimitedDto, OrderDto } from '@fixrate/fixrate-query'

type Props = {
    order: OrderDto
    bank: BankLimitedDto
}

const OrdersDepositorWaiting = ({ order, bank }: Props) => {
    const { t } = useTranslation()

    let message
    let date
    let alertCircle = false,
        canceledIcon = false

    if (order.orderState === 'READY_FOR_APPROVAL') {
        alertCircle = true
        message = (
            <Fragment>
                <p className={styles.message}>{t('pages-orders.depositorReadyForApprovalWarning')}</p>
            </Fragment>
        )
        date = <span>{t('pages-orders.depositorReadyForApproval', { bankName: bank.name })}</span>
    }

    if (order.orderState === 'INITIAL_PROCESSING') {
        alertCircle = true
        message = (
            <p className={styles.message}>{t('pages-orders.depositorInitialProcessing', { bankName: bank.name })}</p>
        )
        date = (
            <Fragment>
                <span>{t('pages-orders.depositorInitialProcessingDate')}</span>
                <span className={styles.timeStamp}>{DateOutput.formatDateTime(order.created)}</span>
            </Fragment>
        )
    }

    const cancellationCategoryText = (category) => {
        switch (category) {
            case 'CUSTOMER_DECLARATION':
                return <p className={styles.message}>{t('pages-orders.customerDeclaration')}</p>
            case 'OVEREXPOSED':
                return <p className={styles.message}>{t('pages-orders.overexposed')}</p>
            case 'SEGMENT_NOT_ALLOWED':
                return <p className={styles.message}>{t('pages-orders.notAllowedSegment')}</p>
            default:
                return <Fragment></Fragment>
        }
    }

    if (order.orderState === 'CANCELED') {
        canceledIcon = true
        message = (
            <Fragment>
                {cancellationCategoryText(order.cancellationCategory)}
                <p className={styles.message}>{order.reasonForCancelling}</p>
            </Fragment>
        )
        date = (
            <Fragment>
                <span>{t('pages-orders.depositorOrderRejected')}</span>
                <span className={styles.timeStamp}>{DateOutput.formatDateTime(order['lastHistoryAction']?.time)}</span>
            </Fragment>
        )
    }

    if (order.orderState === 'READY_FOR_TRANSACTION_CONFIRMATION') {
        alertCircle = true
        message = (
            <p className={styles.message}>
                {t('pages-orders.depositorReadyForTransactionConfirmation', { bankName: bank.name })}
            </p>
        )
        date = <span>{t('pages-orders.depositorWaitingForConfirmation')}</span>
    }

    return (
        <section className={styles.waiting}>
            {alertCircle && (
                <span className={styles.alertCircle}>
                    <span></span>
                </span>
            )}
            {canceledIcon && (
                <div className={styles.cancelledIcon}>
                    <i className="ri-close-circle-line" />
                </div>
            )}
            <div>
                <p className={styles.waitingDate}>{date}</p>
                {message}
            </div>
        </section>
    )
}
export default OrdersDepositorWaiting
