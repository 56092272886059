import config from '#app/config'
import { Button, LinkButton, Paper } from '#components'
import { deletePinCode } from '#services/thunks/user'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import styles from './PinCodeSettings.module.css'
import SetPinCode from './SetPinCode'

export default function PinCodeSettings() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const hasPinCode = useSelector((state) => state.profile && state.profile.hasSessionPin)
    const canSetPinCode = useSelector(selectors.canSetPinCode)

    if (!canSetPinCode || !config().enableAdvancedSessions) return null

    return (
        <Paper title={t('pages-profile.pincode')}>
            <p>{t('pages-profile.pincodeInfo')}</p>

            <Routes>
                <Route path={'pincode/:action'} element={<SetPinCode />} />
            </Routes>

            <div className={styles.pincodeButtons}>
                {hasPinCode && (
                    <LinkButton id="editPincode" to={'/profile/pincode/edit'}>
                        {t('pages-profile.editPincode')}
                    </LinkButton>
                )}
                {!hasPinCode && (
                    <LinkButton id="editPincode" to={'/profile/pincode/new'}>
                        {t('pages-profile.createPincode')}
                    </LinkButton>
                )}
                {hasPinCode && (
                    <Button onClick={() => dispatch(deletePinCode())}>{t('pages-profile.deletePincode')}</Button>
                )}
            </div>
        </Paper>
    )
}
