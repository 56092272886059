import AsyncButton from '#app/components/Button/AsyncButton'
import FxDialogActions from '#app/components/FxDialog/FxDialogActions'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { useSelector } from '#app/state/useSelector'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Fragment } from 'react/jsx-runtime'
import * as selectors from '#state/selectors'
import { DepositDto } from '@fixrate/fixrate-query'
import { useAuthorization } from '#app/services/authorization'
import { useTranslation } from 'react-i18next'
import { useCommand } from '#app/services/beta'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const SignTermination = ({ deposit, onClose }: { deposit: DepositDto; onClose: () => void }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const documents = useSelector((state) => state.documents)
    const { cancelDepositTermination, startElectronicSignature } = useCommand()
    const signedAuthDocIsMissing = useSelector(
        (state) => selectors.signedAuthorizationDocumentIsMissing(state)[deposit?.depositor.id]
    )
    const auth = useAuthorization(deposit?.depositor.id)

    async function onCancelTermination() {
        const { waitForCommand } = await cancelDepositTermination(deposit.id)
        await waitForCommand()
        onClose()
    }

    async function onStartSignature() {
        if (signedAuthDocIsMissing) {
            return
        }
        const processId = uuidv4()
        const terminationDocument = documents[deposit.terminationDocumentId]
        if (!terminationDocument) {
            console.warn('Missing termination document')
            return
        }
        await startElectronicSignature(processId, 'SINGLE_DOCUMENT', terminationDocument.id)
        navigate(`/signature/${processId}?context=/portfolio/id/${deposit.id}`)
    }

    return (
        <Fragment>
            <FxDialogContent>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                    <i className="ri-file-text-line" style={{ fontSize: '4rem' }} />
                    <Box>
                        <Typography variant="h4" fontWeight={700} mt={0}>
                            {t('pages-portfolio-depositor.terminationDocumentCreated')}
                        </Typography>
                        {!auth.depositor.hasAccountHolderRole && (
                            <Typography>
                                {t('pages-portfolio-depositor.terminationMustBeSignedByAccountHolder')}
                            </Typography>
                        )}
                        {signedAuthDocIsMissing && (
                            <Typography>{t('pages-portfolio-depositor.signedAuthDocIsMissing')}</Typography>
                        )}
                        {auth.depositor.hasAccountHolderRole && (
                            <Typography>{t('pages-portfolio-depositor.terminationMustBeSignedAndSent')}</Typography>
                        )}
                    </Box>
                </Stack>
            </FxDialogContent>
            <FxDialogActions>
                <AsyncButton variant={'outlined'} autoFocus onClick={onCancelTermination}>
                    {t('pages-portfolio-depositor.terminationCancelAndRemove')}
                </AsyncButton>

                {!auth.depositor.hasAccountHolderRole && !signedAuthDocIsMissing && (
                    <Button variant={'contained'} onClick={() => onClose()} data-cy="okButton">
                        {t('common.ok')}
                    </Button>
                )}
                {auth.depositor.hasAccountHolderRole && !signedAuthDocIsMissing && (
                    <Button variant={'contained'} onClick={onStartSignature} data-cy="signTerminationDocumentButton">
                        {t('pages-portfolio-depositor.terminationSign')}
                    </Button>
                )}
            </FxDialogActions>
        </Fragment>
    )
}

export { SignTermination }
