import { Grid, Paper, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import React from 'react'
import { PURPLE } from '#app/colors/colors'

interface BentoCardProps {
    sx?: SxProps
    title?: string
    darkMode?: boolean
    noPadding?: boolean
    titleIcon?: string
    helpText?: string
    size?: 6 | 8 | 12
    children: React.ReactNode
}

export const BENTO_PADDING = 3
export const BENTO_CARD_BACKGROUND = '#fcfcff'

export const BentoCard = ({ sx, title, darkMode, noPadding, titleIcon, size, helpText, children }: BentoCardProps) => {
    return (
        <Grid item xs={12} lg={size ?? 6} zeroMinWidth>
            <Paper
                sx={{
                    px: 0,
                    py: 2,
                    backgroundColor: darkMode ? PURPLE[900] : BENTO_CARD_BACKGROUND,
                    borderRadius: '0.5rem',
                    ...sx,
                }}
            >
                {(titleIcon || title || helpText) && (
                    <Stack px={BENTO_PADDING} direction={'row'} justifyContent={'space-between'} mb={1}>
                        {(titleIcon || title) && (
                            <Typography variant={'h3'} sx={{ m: 0, color: darkMode ? PURPLE[50] : null }}>
                                {titleIcon && <i className={titleIcon} />}
                                {title}
                            </Typography>
                        )}
                        {helpText && (
                            <Tooltip title={helpText}>
                                <HelpOutline />
                            </Tooltip>
                        )}
                    </Stack>
                )}
                <Stack px={noPadding ? 0 : BENTO_PADDING}>{children}</Stack>
            </Paper>
        </Grid>
    )
}
