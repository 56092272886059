import { useState } from 'react'
import { PageHeader, PageLayout, Paper } from '#components'
import { useSelector } from '#state/useSelector'
import AdminTransactionTable from '#pages/FundTransactions/AdminTransactionTable'
import Pagination from '#components/Pagination/Pagination'
import styles from './FundTransactions.module.scss'
import isValidUuid from '#services/isValidUuid'
import { useTranslation } from 'react-i18next'
import ReportDownloadExcel from '#components/ReportDownloadExcel/ReportDownloadExcel'
import { compareDesc } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import AsyncButton from '#components/Button/AsyncButton'

const ITEMS_PER_PAGE = 20
const DEPOSITOR_ID = 'depositorId'
const FUND_SHARE_CLASS_ID = 'fundShareClassId'
const CONFIRMATION_SLIP_NUMBER = 'confirmationSlipNumber'

export default function FundTransactions() {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const depositorId = searchParams.has(DEPOSITOR_ID) ? searchParams.get(DEPOSITOR_ID) : ''
    const fundShareClassId = searchParams.has(FUND_SHARE_CLASS_ID) ? searchParams.get(FUND_SHARE_CLASS_ID) : ''
    const confirmationSlipNumber = searchParams.has(CONFIRMATION_SLIP_NUMBER)
        ? searchParams.get(CONFIRMATION_SLIP_NUMBER)
        : ''

    const [page, setPage] = useState(0)
    const [depositorFilter, setDepositorFilter] = useState(isValidUuid(depositorId) ? depositorId : '')
    const [fundShareClassFilter, setFundShareClassFilter] = useState(
        isValidUuid(fundShareClassId) ? fundShareClassId : ''
    )
    const [confirmationSlipNumberFilter, setConfirmationSlipNumberFilter] = useState(confirmationSlipNumber)

    const fundPlacements = useSelector((state) => state.fundPlacements)
    const depositorNames = useSelector((state) =>
        Object.assign(
            {},
            ...Object.values(state.fundPlacements).map((d) => ({
                [d.depositorId]: state.depositorNames[d.depositorId],
            }))
        )
    )
    const funds = useSelector((state) => state.funds)
    const fundShareClasses = funds.flatMap((f) => f.fundShareClasses)
    const fundNames: { string: string } = useSelector((state) =>
        Object.assign(
            {},
            ...state.funds.flatMap((f) => f.fundShareClasses.map((sc) => ({ [sc.id]: `${sc.fullName}` })))
        )
    )
    const confirmationSlips = useSelector((state) => state.fundCustomers.flatMap((fc) => fc.confirmationSlips))
    const filteredConfirmationSlips = confirmationSlips.filter(
        (cs) => cs.serialNumber === parseInt(confirmationSlipNumberFilter)
    )

    function setFundPlacementIdFilter(id: string) {
        const fundPlacement = fundPlacements.find((fp) => fp.id === id)
        if (fundPlacement) {
            setDepositorFilter(fundPlacement.depositorId)
            setFundShareClassFilter(fundPlacement.fundShareClassId)
        }
    }

    const filteredFundPlacements = fundPlacements
        .filter((fp) => !depositorFilter || fp.depositorId === depositorFilter)
        .filter((fp) => !fundShareClassFilter || fp.fundShareClassId === fundShareClassFilter)

    const transactions = filteredFundPlacements
        .flatMap((fp) => {
            const shareClassHistory = fp.fundShareClassHistory
            return fp.transactions.map((t) => {
                const shareClassId =
                    t.fundShareClassId ??
                    getShareClassIdForDate(shareClassHistory, t.transactionDate) ??
                    fp.fundShareClassId
                const fundShareClass = fundShareClasses.find((sc) => sc.id === shareClassId)
                const fund = funds.find((f) => f.id === fundShareClass.fundId)
                return {
                    ...t,
                    fundName: `${fundShareClass.fullName}`,
                    decimalPrecision: fund.decimalPrecision,
                    depositorName: depositorNames[fp.depositorId],
                }
            })
        })
        .sort((a, b) => {
            if (a.transactionDate > b.transactionDate) return -1
            if (a.transactionDate < b.transactionDate) return 1
            return b.sequenceNumber - a.sequenceNumber
        })

    const filteredTransactions = transactions.filter(
        (tr) => !confirmationSlipNumberFilter || filteredConfirmationSlips.some((cs) => cs.transactionId === tr.id)
    )

    const visibleTransactions = filteredTransactions.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)

    function getShareClassIdForDate(fundShareClassHistory, inputDateString) {
        const inputDate = new Date(inputDateString)
        const dates = Object.keys(fundShareClassHistory)
            .filter((date) => {
                return new Date(date) <= inputDate
            })
            .sort((a, b) => {
                return compareDesc(new Date(a), new Date(b))
            })

        if (dates.length === 0) {
            return null
        }
        const closestDate = dates[0]
        return fundShareClassHistory[closestDate]
    }

    function updateDepositorFilter(id: string) {
        const params = new URLSearchParams(searchParams)
        if (id) {
            params.set(DEPOSITOR_ID, id)
        } else {
            params.delete(DEPOSITOR_ID)
        }
        setPage(0)
        setDepositorFilter(id)
        setSearchParams(params)
    }

    function updateFundShareClassFilter(id: string) {
        const params = new URLSearchParams(searchParams)
        if (id) {
            params.set(FUND_SHARE_CLASS_ID, id)
        } else {
            params.delete(FUND_SHARE_CLASS_ID)
        }
        setPage(0)
        setFundShareClassFilter(id)
        setSearchParams(params)
    }

    function updateConfirmationSlipNumberFilter(num: string) {
        const params = new URLSearchParams(searchParams)
        if (num) {
            params.set(CONFIRMATION_SLIP_NUMBER, num)
        } else {
            params.delete(CONFIRMATION_SLIP_NUMBER)
        }
        setPage(0)
        setConfirmationSlipNumberFilter(num)
        setSearchParams(params)
    }

    function resetFilters() {
        const params = new URLSearchParams(searchParams)
        params.delete(DEPOSITOR_ID)
        params.delete(FUND_SHARE_CLASS_ID)
        params.delete(CONFIRMATION_SLIP_NUMBER)
        setPage(0)
        setDepositorFilter('')
        setFundShareClassFilter('')
        setConfirmationSlipNumberFilter('')
        setSearchParams(params)
    }

    return (
        <>
            <PageHeader title={t('pages-fundTransactions.header')} />
            <PageLayout>
                <Paper title={t('pages-fundTransactions.title')} sx={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="space-between" marginBottom={2}>
                        <Stack direction="row" spacing={2} alignItems="baseline">
                            <p className={styles.toolbarLabel}>
                                <i className="ri-filter-line" />
                                <span>{t('pages-fundTransactions.filter')}</span>
                            </p>
                            <Select
                                displayEmpty
                                value={fundShareClassFilter}
                                onChange={(e) => updateFundShareClassFilter(e.target.value)}
                            >
                                <MenuItem value={''}>
                                    <Typography noWrap>{t('pages-fundTransactions.allFunds')}</Typography>
                                </MenuItem>
                                {Object.keys(fundNames)
                                    .sort((a, b) => fundNames[a].localeCompare(fundNames[b]))
                                    .map((id) => (
                                        <MenuItem key={id} value={id}>
                                            <Typography noWrap>{fundNames[id]}</Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                            <Select
                                displayEmpty
                                value={depositorFilter}
                                onChange={(e) => updateDepositorFilter(e.target.value)}
                            >
                                <MenuItem value={''}>
                                    <Typography noWrap>{t('pages-fundTransactions.allDepositors')}</Typography>
                                </MenuItem>
                                {Object.keys(depositorNames)
                                    .sort((a, b) => depositorNames[a].localeCompare(depositorNames[b]))
                                    .map((id) => (
                                        <MenuItem key={id} value={id}>
                                            <Typography noWrap>{depositorNames[id]}</Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                            <TextField
                                sx={{ minWidth: 'fit-content' }}
                                placeholder={t('pages-fundTransactions.confirmationSlipNumber')}
                                value={confirmationSlipNumberFilter}
                                onChange={(e) => updateConfirmationSlipNumberFilter(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <i className="ri-search-line" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {(fundShareClassFilter || depositorFilter || confirmationSlipNumberFilter) && (
                                <AsyncButton variant="contained" size="small" onClick={() => resetFilters()}>
                                    <i className="ri-restart-line" style={{ marginRight: '0.4rem' }} />
                                    <span>{t('pages-fundTransactions.resetFilter')}</span>
                                </AsyncButton>
                            )}
                        </Stack>
                        {filteredTransactions.length !== 0 && !confirmationSlipNumberFilter && (
                            <ReportDownloadExcel
                                report="FUND_TRANSACTIONS_EXPORT"
                                additionalParams={
                                    new URLSearchParams({
                                        depositorId: depositorFilter,
                                        fundShareClassId: fundShareClassFilter,
                                    })
                                }
                            />
                        )}
                    </Stack>
                    <AdminTransactionTable
                        transactions={visibleTransactions}
                        setFundPlacementIdFilter={setFundPlacementIdFilter}
                        options={{ hideDepositor: !!depositorFilter, hideFund: !!fundShareClassFilter }}
                    />
                    {visibleTransactions.length === 0 && (
                        <p className={styles.emptyMessage}>
                            {!!depositorFilter || !!fundShareClassFilter
                                ? t('pages-fundTransactions.noTransactionsMatchingFilter')
                                : t('pages-fundTransactions.noTransactions')}
                        </p>
                    )}
                    {transactions.length > ITEMS_PER_PAGE && (
                        <div style={{ marginTop: '2rem' }}>
                            <Pagination
                                totalItems={transactions.length}
                                itemsPerPage={ITEMS_PER_PAGE}
                                selectedPage={page}
                                setPage={setPage}
                            />
                        </div>
                    )}
                </Paper>
            </PageLayout>
        </>
    )
}
