import { Alert, Stack } from '@mui/material'
import { FundShareClassDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { useLocalizedString } from '#services/useLocalizedString'

type FundReturnsDisclaimerProps = {
    shareClass: FundShareClassDto
}
export default function FundReturnsDisclaimer({ shareClass }: FundReturnsDisclaimerProps) {
    const { t } = useTranslation()
    const additionalDisclaimer = useLocalizedString(shareClass?.additionalDisclaimer)
    if (!shareClass) {
        return null
    }

    return (
        <Stack>
            <Alert severity="info" sx={{ width: '100%', fontSize: '1.2rem', mt: '2rem' }}>
                <Stack spacing={1}>
                    {additionalDisclaimer && <span>{additionalDisclaimer}</span>}
                    <span>{t('pages-fund-details.returnsExplanation')}</span>
                </Stack>
            </Alert>
        </Stack>
    )
}
