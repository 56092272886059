import React, { useState } from 'react'
import { Paper } from '#app/components'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Stack, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NewsArticleCollection from './NewsArticleCollection'
import NewsArticleCard from './NewsArticleCard'
import { useNavigate, useParams } from 'react-router-dom'
import { MarketplaceNew } from '#app/services/payloadCMS/payload-types'

type Params = {
    articleId: string
}

interface NewsTabsProps {
    news: MarketplaceNew[]
    isFixrate: boolean
}

const NewsTabs: React.FC<NewsTabsProps> = ({ news, isFixrate }) => {
    const { t } = useTranslation()
    const { articleId } = useParams<Params>()
    const navigate = useNavigate()

    const defaultTab = news.length > 0 ? news[0].targetAudience[0] : 'fundNews'

    const [tab, setTab] = useState<'fundNews' | 'depositorNews' | 'bankNews' | 'partnerNews' | 'fixrateDraft'>(
        defaultTab
    )

    const handleTabChange = (
        event: React.SyntheticEvent,
        newValue: 'fundNews' | 'depositorNews' | 'bankNews' | 'partnerNews' | 'fixrateDraft'
    ) => {
        setTab(newValue)
    }

    const handleBackClick = () => {
        navigate(`/news`)
    }

    const fundNews = news.filter((article) => article.targetAudience?.includes('fundNews'))
    const depositorNews = news.filter((article) => article.targetAudience?.includes('depositorNews'))
    const bankNews = news.filter((article) => article.targetAudience?.includes('bankNews'))
    const partnerNews = news.filter((article) => article.targetAudience?.includes('partnerNews'))
    const fixrateDraft = news.filter((article) => article.targetAudience?.includes('fixrateDraft'))

    const selectedArticle = articleId ? news.find((article) => article.id === articleId) : null

    return (
        <>
            {selectedArticle ? (
                <>
                    <Button startIcon={<i className={'ri-arrow-left-line'} />} onClick={handleBackClick}>
                        {t('pages-News.backButton')}
                    </Button>
                    <Box sx={{ maxWidth: '80rem' }}>
                        <Paper sx={{ width: '100%', padding: '0' }}>
                            <NewsArticleCard article={selectedArticle} fullView={true} />
                        </Paper>
                    </Box>
                </>
            ) : (
                <Stack>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            {fundNews.length > 0 && (
                                <Tab
                                    label={t('pages-News.newsFund')}
                                    value="fundNews"
                                    icon={<i className="ri-newspaper-line" />}
                                />
                            )}
                            {depositorNews.length > 0 && (
                                <Tab
                                    label={t('pages-News.newsDepositor')}
                                    value="depositorNews"
                                    icon={<i className="ri-newspaper-line" />}
                                />
                            )}
                            {bankNews.length > 0 && (
                                <Tab
                                    label={t('pages-News.newsBank')}
                                    value="bankNews"
                                    icon={<i className="ri-newspaper-line" />}
                                />
                            )}
                            {partnerNews.length > 0 && (
                                <Tab
                                    label={t('pages-News.newsPartner')}
                                    value="partnerNews"
                                    icon={<i className="ri-newspaper-line" />}
                                />
                            )}
                            {fixrateDraft.length > 0 && isFixrate && (
                                <Tab
                                    label={t('pages-News.newsFixrateDraft')}
                                    value="fixrateDraft"
                                    icon={<i className="ri-newspaper-line" />}
                                />
                            )}
                        </TabList>
                        {fundNews.length > 0 && (
                            <TabPanel value={'fundNews'} sx={{ pt: 1, px: 0, pb: 0 }}>
                                <NewsArticleCollection articles={fundNews} />
                            </TabPanel>
                        )}
                        {depositorNews.length > 0 && (
                            <TabPanel value={'depositorNews'} sx={{ pt: 1, px: 0, pb: 0 }}>
                                <NewsArticleCollection articles={depositorNews} />
                            </TabPanel>
                        )}
                        {bankNews.length > 0 && (
                            <TabPanel value={'bankNews'} sx={{ pt: 1, px: 0, pb: 0 }}>
                                <NewsArticleCollection articles={bankNews} />
                            </TabPanel>
                        )}
                        {partnerNews.length > 0 && (
                            <TabPanel value={'partnerNews'} sx={{ pt: 1, px: 0, pb: 0 }}>
                                <NewsArticleCollection articles={partnerNews} />
                            </TabPanel>
                        )}
                        {fixrateDraft.length > 0 && isFixrate && (
                            <TabPanel value={'fixrateDraft'} sx={{ pt: 1, px: 0, pb: 0 }}>
                                <NewsArticleCollection articles={fixrateDraft} />
                            </TabPanel>
                        )}
                    </TabContext>
                </Stack>
            )}
        </>
    )
}

export default NewsTabs
