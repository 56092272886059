import { useCallback, useState } from 'react'
import { ButtonRow, DateOutput } from '#components'
import { useTranslation } from 'react-i18next'
import { FundCustomerDto } from '@fixrate/fixrate-query'
import { useFieldState } from '@fixrate/fieldstate'
import { Box, Button, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useCommand } from '#command'

type Props = {
    customer: FundCustomerDto
}

export default function SetFundAccess({ customer }: Props) {
    const { t } = useTranslation()
    const { setDepositorFundEnabled } = useCommand()

    const enabledField = useFieldState<boolean>(customer.enabled)

    const buyEnabledFieldValidator = useCallback(
        ({ value }) => {
            if (customer.portfolios.every((p) => !p.fundData.cid) && value) {
                return t('pages.fundCustomers.customerDetail.fundAccess.noCid')
            }
        },
        [customer.portfolios, t]
    )
    const buyEnabledField = useFieldState<boolean>(customer.buyEnabled, buyEnabledFieldValidator)

    const [submitting, setSubmitting] = useState(false)

    const touched = enabledField.touched || buyEnabledField.touched

    async function submit() {
        const valid = enabledField.validate() && buyEnabledField.validate()

        if (!valid) {
            return
        }

        setSubmitting(true)
        const { waitForCommand } = await setDepositorFundEnabled(
            customer.depositorId,
            enabledField.value,
            buyEnabledField.value
        )
        const success = await waitForCommand()
        setSubmitting(false)

        if (success) {
            enabledField.untouch()
            buyEnabledField.untouch()
        }
    }

    function reset() {
        enabledField.reset()
        buyEnabledField.reset()
    }

    return (
        <div>
            <dt>
                <label>{t('pages.fundCustomers.customerDetail.fundAccess.label')}</label>
            </dt>
            <dd>
                <Box
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <FormGroup sx={{ display: 'inline-flex', mt: 1, ml: 1 }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size={'small'}
                                    value={enabledField.value}
                                    checked={enabledField.value}
                                    onChange={(e) => enabledField.setValue(e.target.checked)}
                                    onBlur={() => enabledField.validate()}
                                />
                            }
                            label={t('pages.fundCustomers.customerDetail.fundAccess.view')}
                        />
                        {!enabledField.valid && <p className="field-error-message">{enabledField.errorMessage}</p>}
                        <FormControlLabel
                            control={
                                <Switch
                                    size={'small'}
                                    value={buyEnabledField.value}
                                    checked={buyEnabledField.value}
                                    onChange={(e) => buyEnabledField.setValue(e.target.checked)}
                                    onBlur={() => buyEnabledField.validate()}
                                />
                            }
                            label={t('pages.fundCustomers.customerDetail.fundAccess.buy')}
                        />
                        {!buyEnabledField.valid && (
                            <p className="field-error-message">{buyEnabledField.errorMessage}</p>
                        )}
                    </FormGroup>
                    <Box>
                        {customer.buyEnabledDate && (
                            <Typography>
                                {t('pages.fundCustomers.customerDetail.fundAccess.fundEnabledDate')}:{' '}
                                {DateOutput.formatMonthYearNumeric(customer.buyEnabledDate)}
                            </Typography>
                        )}
                        {customer.buyDisabledDate && (
                            <Typography>
                                {t('pages.fundCustomers.customerDetail.fundAccess.fundDisabledDate')}:{' '}
                                {DateOutput.formatMonthYearNumeric(customer.buyDisabledDate)}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </dd>
            <Box
                sx={{
                    mt: 1,
                    transformOrigin: 'top',
                    transform: `scaleY(${touched ? 1 : 0})`,
                    transition: 'transform 0.2s',
                }}
            >
                <ButtonRow align={'left'}>
                    <LoadingButton
                        size={'small'}
                        onClick={submit}
                        variant={'contained'}
                        loading={submitting}
                        disabled={submitting}
                    >
                        {t('common.save')}
                    </LoadingButton>
                    <Button size={'small'} variant={'outlined'} onClick={reset} disabled={submitting}>
                        {t('common.cancel')}
                    </Button>
                </ButtonRow>
            </Box>
        </div>
    )
}
