import FundReturnsChart from '#app/blocks/FundReturnsChart/FundReturnsChart'
import { CurrencyOutput, DateOutput, InterestOutput, LinkButton } from '#app/components'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import config from '#app/config'
import ShoppingCartPane from '#app/pages/FundMarketplace/ShoppingCartPane/ShoppingCartPane'
import { fundDetailsLink } from '#app/services/fundDetailsLink'
import useShoppingCart from '#app/services/useShoppingCart'
import { useCommand, useEndpoint } from '#command'
import { InterestBadge } from '#components/InterestBadge/InterestBadge'
import { OrderTypeBadge } from '#components/OrderTypeBadge/OrderTypeBadge'
import { FundOrder, FundOrderState } from '#pages/portfolio-depositor/FundPlacements/FundOrders'
import { fundOrderStateTMap } from '#services/enumTranslationKeyMapping'
import { formatAccount } from '#services/formatnumber'
import { openChatBubble } from '#services/hubspot'
import { useFundPlatformFee } from '#services/platformFeeCalculator'
import usePortfolio from '#services/usePortfolio'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { FundDto, FundPlacementDto, FundShareClassDto } from '@fixrate/fixrate-query'
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Hidden,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { getI18n, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styles from './FundPlacementAccordion.module.scss'
import OrderGroupProgressBar from './OrderGroupProgressBar'
import SellModal from './SellModal'
import AsyncButton from '#components/Button/AsyncButton'
import { useSignableDocumentListInstruction } from '#components/SignableDocument/SignableDocumentList'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import { useDispatch } from 'react-redux'
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import { useConfirmModal } from '#app/layers/ConfirmModal/ConfirmModal'
import { LoadingButton } from '@mui/lab'
import { FundLogo } from '#app/pages/FundDetails/FundLogo/FundLogo'
import { useLocalizedString } from '#services/useLocalizedString'

type Props = {
    fundPlacement: FundPlacementDto
}

export default function FundPlacementAccordion({ fundPlacement }: Props) {
    const API_BASE_URL = config().apiUrl
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()

    const shoppingCart = useShoppingCart('FUNDS')

    const [accordionOpen, setAccordionOpen] = useState(false)
    const [sellModalVisible, setSellModalVisible] = useState(false)
    const [cartOpen, setCartOpen] = useState(false)

    const depositors = useSelector((state) => state.depositors)
    const depositor = useSelector((state) =>
        state.depositors.find((depositor) => fundPlacement?.depositorId === depositor.id)
    )
    const fund = useSelector((state) => state.funds.find((fund) => fund.id === fundPlacement?.fundId))
    const shareClass = fund?.fundShareClasses.find((sc) => sc.id === fundPlacement?.fundShareClassId)
    const managersExplanation = useLocalizedString(shareClass?.effectiveInterestRateManagersExplanation)

    const isInShoppingCart = !!shoppingCart.getItemById(fund?.id)

    const platformFee = useFundPlatformFee().fundPlatformFeeForPortfolio?.[fund?.fundCategory]

    function buyMore() {
        if (!isInShoppingCart) {
            shoppingCart.addItem(fund?.id, shareClass?.id)
        }
        setCartOpen(true)
    }

    if (!fund || !shareClass || !fundPlacement) return null

    const distinctShareClassIds = [...new Set(fundPlacement.placementParts.map((pp) => pp.fundShareClassId))]
    const availableUnitQuantity = fundPlacement.availableUnitQuantity
    const availableMarketValue = availableUnitQuantity * shareClass.nav

    return (
        <li
            data-cy="fundListItem"
            data-fx={fundPlacement.id}
            className={classNames(styles.accordion, styles.fundAsset)}
        >
            <div className={styles.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>
                <div className={styles.name} data-fx={fundPlacement.fundId}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Hidden mdDown>
                            <Box paddingRight={'1rem'}>
                                <Box
                                    sx={{ '& img': { width: '100%' } }}
                                    width={'4rem'}
                                    display="flex"
                                    justifyContent="center"
                                >
                                    <FundLogo fundName={shareClass?.fullName} squared={true} />
                                </Box>
                            </Box>
                        </Hidden>
                        <Box>
                            <p className={styles.nameHeader}>
                                {distinctShareClassIds.length > 1 && (
                                    <Tooltip
                                        title={t('pages-FundOverview.tooltipWarningMultipleFundShareClasses', {
                                            fundShareClassName: shareClass?.fullName,
                                        })}
                                    >
                                        <IconButton color={'error'}>
                                            <i className="ri-error-warning-line" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {shareClass?.fullName}
                            </p>
                            <Typography variant="caption">
                                {t('pages-FundOverview.isin')}: {shareClass?.isin}
                            </Typography>
                        </Box>
                    </Stack>
                    {depositors.length > 1 && (
                        <p className={styles.fundHoldersName}>
                            <i className="ri-building-line" />
                            <span>{depositor.name}</span>
                        </p>
                    )}
                </div>
                <span className={styles.risk}>
                    <div className={styles.indicator}>
                        {[...new Array(7)].map((_, i) => (
                            <span key={i} className={i < fund.risk ? styles.active : undefined} />
                        ))}
                    </div>
                </span>
                <Hidden xlDown>
                    <div className={styles.nav}>
                        <p className={styles.navPrice}>{Currency(shareClass.nav, { decimals: 4 })}</p>
                        <p className={styles.navDate}>
                            {shareClass.navDate != null ? DateOutput.formatDate(shareClass.navDate) : '-'}
                        </p>
                    </div>
                </Hidden>
                <span className={styles.subVal}>{CurrencyOutput.formatNoCode(fundPlacement.unitQuantity, 4)}</span>
                <span className={styles.val}>{Currency(Math.round(fundPlacement.currentValue), { decimals: 0 })}</span>
                <span className={styles.returns}>
                    <InterestBadge interest={fundPlacement.returnRate * 100} />
                    {/*<Box sx={{ml: 1}}>{Currency(fundPlacement.dirtyReturnValue, { decimals: 0 })}</Box>*/}
                </span>
                <i className={classNames('ri-arrow-down-s-line', styles.menuArrow, accordionOpen && styles.flip)} />
            </div>
            <div className={classNames(styles.accordionContent, accordionOpen ? styles.open : null)}>
                <div className={styles.chart}>
                    <FundReturnsChart fundShareClass={shareClass} />
                    <ButtonGroup sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                        <LinkButton
                            className={styles.fileButton}
                            variant="secondary"
                            to={API_BASE_URL + '/api/document/id/' + fund.kiidDocumentId + '/data'}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className={'ri-file-line'} />
                            <span>{t('pages-FundOverview.KIID-document')}</span>
                        </LinkButton>
                        <LinkButton
                            className={styles.fileButton}
                            variant="secondary"
                            to={API_BASE_URL + '/api/document/id/' + fund.prospectusDocumentId + '/data'}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className={'ri-file-line'} />
                            <span>{t('pages-FundOverview.prospectus')}</span>
                        </LinkButton>
                    </ButtonGroup>
                </div>
                <div className={styles.fundInfo}>
                    <div className={styles.section}>
                        <h4>{t('pages-FundOverview.keyFigures')}</h4>
                        <ul className={styles.keyValueList}>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.totalAssets')}
                                    {fund.totalAssetsDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')} {DateOutput.formatDate(fund.totalAssetsDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>
                                    {CurrencyOutput.formatMillion(fund.totalAssets, fund.currency)}
                                </span>
                            </li>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.navPrice')}
                                    {shareClass?.navDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')} {DateOutput.formatDate(shareClass?.navDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>{Currency(shareClass?.nav, { decimals: 4 })}</span>
                            </li>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.standardDeviation')}
                                    {shareClass?.standardDeviationDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')}{' '}
                                            {DateOutput.formatDate(shareClass?.standardDeviationDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>
                                    {InterestOutput.format(shareClass?.standardDeviation)}
                                </span>
                            </li>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.interestRateSensitivity')}
                                    {fund.interestRateDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')} {DateOutput.formatDate(fund.interestRateDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>
                                    {CurrencyOutput.formatNoCode(fund.interestRateSensitivity)}
                                </span>
                            </li>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.creditSensitivity')}
                                    {fund.interestRateDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')} {DateOutput.formatDate(fund.interestRateDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>
                                    {CurrencyOutput.formatNoCode(fund.creditSensitivity)}
                                </span>
                            </li>
                            <li>
                                <span className={styles.key}>
                                    {t('pages-FundOverview.effectiveInterestRate') + (managersExplanation ? ' *' : '')}
                                    {shareClass.effectiveInterestRateDate && (
                                        <div className={styles.comment}>
                                            {t('common.updated')}{' '}
                                            {DateOutput.formatDate(shareClass.effectiveInterestRateDate)}
                                        </div>
                                    )}
                                </span>
                                <span className={styles.value}>
                                    {InterestOutput.format(shareClass.effectiveInterestRate)}
                                </span>
                            </li>
                        </ul>
                        <div className={styles.comment}>
                            <p>{managersExplanation ? '*' + managersExplanation : null}</p>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <h4>{t('pages-FundOverview.costs')}</h4>
                        {platformFee !== undefined && (
                            <ul className={styles.keyValueList}>
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.totalCost')}</span>
                                    <span className={styles.value}>
                                        {InterestOutput.formatWithDecimals(shareClass?.managementFee + platformFee, 3)}
                                    </span>
                                </li>
                                <li className={styles.sub}>
                                    <span className={styles.key}>{t('pages-FundOverview.managementFee')}</span>
                                    <span className={styles.value}>
                                        {InterestOutput.formatWithDecimals(shareClass?.managementFee, 3)}
                                    </span>
                                </li>
                                <li className={styles.sub}>
                                    <span className={styles.key}>{t('pages-FundOverview.platformFee')}</span>
                                    <span className={styles.value}>
                                        {InterestOutput.formatWithDecimals(platformFee, 3)}
                                    </span>
                                </li>
                            </ul>
                        )}
                        {platformFee === undefined && (
                            <ul className={styles.keyValueList}>
                                <li className={styles.sub}>
                                    <span className={styles.key}>{t('pages-FundOverview.managementFee')}</span>
                                    <span className={styles.value}>
                                        {InterestOutput.formatWithDecimals(shareClass?.managementFee, 3)}
                                    </span>
                                </li>
                            </ul>
                        )}
                        <h4>{t('pages-FundOverview.yourHoldings')}</h4>
                        <ul className={styles.keyValueList}>
                            <li>
                                <span className={styles.key}>{t('pages-FundOverview.unitQuantity')}</span>
                                <span className={styles.value}>
                                    {CurrencyOutput.formatNoCode(fundPlacement.unitQuantity, 4)}
                                </span>
                            </li>
                            <li className={styles.sub}>
                                <span className={styles.key}>{t('pages-FundOverview.availableUnitQuantity')}</span>
                                <span className={styles.value}>
                                    {CurrencyOutput.formatNoCode(availableUnitQuantity, 4)}
                                </span>
                            </li>
                            <li>
                                <span className={styles.key}>{t('pages-FundOverview.marketValue')}</span>
                                <span className={styles.value}>
                                    {Currency(Math.round(fundPlacement.currentValue), { decimals: 0 })}
                                </span>
                            </li>
                            <li className={styles.sub}>
                                <span className={styles.key}>{t('pages-FundOverview.availableMarketValue')}</span>
                                <span className={styles.value}>
                                    {Currency(Math.round(availableMarketValue), { decimals: 0 })}
                                </span>
                            </li>
                        </ul>
                        <Stack direction={'row'} spacing={2} alignItems={'center'}>
                            <Button
                                data-cy="sellButton"
                                onClick={() => setSellModalVisible(true)}
                                variant={'outlined'}
                                sx={{ width: '45%' }}
                                color={'error'}
                            >
                                {t('pages-FundOverview.sell')}
                            </Button>
                            <Button onClick={buyMore} variant={'contained'} sx={{ width: '45%' }}>
                                {t('pages-FundOverview.buy')}
                            </Button>
                        </Stack>
                        <div className={styles.readMore}>
                            <Link className={styles.readMoreLink} to={fundDetailsLink(fund)}>
                                <span>{t('pages-FundOverview.readMore')}</span>
                                <i className={'ri-arrow-right-line'} />
                            </Link>
                            <Link
                                className={styles.readMoreLink}
                                to={`/portfolio/fund-transactions?depositorId=${fundPlacement.depositorId}&fundId=${fundPlacement.fundId}`}
                            >
                                <span>{t('pages-FundOverview.transactionsList')}</span>
                                <i className={'ri-arrow-right-line'} />
                            </Link>
                        </div>
                    </div>
                </div>
                {sellModalVisible && (
                    <SellModal fundPlacement={fundPlacement} onCancel={() => setSellModalVisible(false)} />
                )}
                <ShoppingCartPane open={cartOpen} setOpen={setCartOpen} />
            </div>
        </li>
    )
}

export const BuySellOrderAccordion = ({ orderGroup }: { orderGroup: FundOrder[] }) => {
    const { t } = useTranslation()
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()
    const [accordionOpen, setAccordionOpen] = useState(false)
    const orderDocument = useSelector((state) => state.documents[orderGroup[0].documentId])
    const funds = useSelector((state) => state.funds)
    const userFullNameMap = useSelector((state) =>
        Object.assign({}, ...state.depositors.map((d) => d.knownUserFullNames))
    )
    const depositor = useSelector((state) =>
        state.depositors.find((depositor) => orderGroup[0]?.depositorId === depositor.id)
    )
    const userCanConfirmPayment = useSelector(selectors.userCanConfirmPayment)
    const orgId = useSelector((state) => state.session.association?.organisation?.id)
    const navigate = useNavigate()
    const currentPortfolio = usePortfolio()
    const dispatch = useDispatch()
    const confirmModal = useConfirmModal()
    const currentCid = depositor?.portfolios?.find((p) => p.id === currentPortfolio?.id)?.fundData?.cid
    const signatureInstructions = useSignableDocumentListInstruction([orderDocument])
    const orderDocumentSignatureStatus = {
        signedByUser: orderDocument?.signedByUser,
        signedByAny: orderDocument?.signedByAny,
    }
    const { getTokenForFixrateCapital } = useEndpoint()
    const orderGroupId = orderGroup[0].orderGroupId

    const [confirmPayment, setConfirmPayment] = useState(false)
    const { confirmFundBuyOrderPayment } = useCommand()
    const [cancellingOrder, setCancellingOrder] = useState(false)
    const { cancelFundBuyOrderAsDepositor } = useCommand()
    const { cancelFundSellOrderAsDepositor } = useCommand()
    const [clientAccount, setClientAccount] = useState(undefined)

    const orderAmount = orderGroup.reduce((acc, o) => acc + o.amount, 0)
    const sampleOrder = orderGroup[0]
    const orderType = sampleOrder.type
    const currency = funds
        .find((fund) => fund.id === sampleOrder.fundId)
        ?.fundShareClasses.find((fsc) => fsc.id === sampleOrder.fundShareClassId)?.currency
    const currencyName = t('pages-FundOverview.fcClientAccount', { currency: currency !== 'NOK' ? ' ' + currency : '' })
    const usingFundPortfolioClientAccount = sampleOrder.depositorFundPortfolioClientAccount
    useEffect(() => {
        const acc = usingFundPortfolioClientAccount
            ? (() => {
                  const settlementAccount = depositor.settlementAccounts.find(
                      (sa) => sa.account === sampleOrder.accountNumber
                  )
                  return {
                      accountNumber: settlementAccount?.account,
                      name: settlementAccount?.name,
                      fundPortfolioClientAccount: true,
                  }
              })()
            : {
                  accountNumber: sampleOrder.accountNumber,
                  name: currencyName,
                  fundPortfolioClientAccount: false,
              }
        setClientAccount(acc)
    }, [depositor.settlementAccounts, sampleOrder, usingFundPortfolioClientAccount, currencyName])
    const handleConfirmPayment = async () => {
        if (confirmPayment) {
            const { waitForCommand } = await confirmFundBuyOrderPayment(orderGroup.map((order) => order.id))
            await waitForCommand()
        }
    }

    const getShareClassByFundOrder = (fundOrder: FundOrder): FundShareClassDto | null => {
        const fund = funds.find((fund) => fund.id === fundOrder.fundId)
        if (!fund) return null
        return fund?.fundShareClasses.find((sc) => sc.id === fundOrder.fundShareClassId)
    }

    const getShareClass = (fund: FundDto, shareClassId: string): FundShareClassDto | null => {
        return fund?.fundShareClasses.find((sc) => sc.id === shareClassId)
    }

    async function cancelOrder() {
        if (cancellingOrder) {
            return
        }
        confirmModal({
            title: t('pages-FundOverview.cancelOrderDialogTitle'),
            text: t('pages-FundOverview.cancelOrderDialogText'),
            submitButtonText: t('common.yes'),
            cancelButtonText: t('common.no'),
            submitAction: async () => {
                setCancellingOrder(true)
                try {
                    let success = false
                    if (orderType === 'BUY') {
                        const { waitForCommand } = await cancelFundBuyOrderAsDepositor(orderGroupId, depositor.id)
                        success = await waitForCommand()
                    } else if (orderType === 'SELL') {
                        const { waitForCommand } = await cancelFundSellOrderAsDepositor(orderGroupId, depositor.id)
                        success = await waitForCommand()
                    }
                    if (success) {
                        dispatch(
                            showConfirmationModal({
                                title: t('pages-FundOverview.cancelOrderDialogConfirmationTitle'),
                                text: t('pages-FundOverview.cancelOrderDialogConfirmationText'),
                                buttonText: t('common.ok'),
                            })
                        )
                    } else {
                        dispatch(
                            showConfirmationModal({
                                title: t('pages-FundOverview.cancelOrderDialogFailedTitle'),
                                text: t('pages-FundOverview.cancelOrderDialogFailedText'),
                                buttonText: t('common.ok'),
                                confirmationIconName: 'error',
                            })
                        )
                    }
                } finally {
                    setCancellingOrder(false)
                }
            },
            cancelAction: () => {
                setCancellingOrder(false)
                return
            },
        })
        setCancellingOrder(false)
    }

    async function gotoFixrateCapitalSignatureUrl(orderType: 'BUY' | 'SELL') {
        console.log('gotoFixrateCapitalSignatureUrl', orderType)
        const { token } = await getTokenForFixrateCapital()
        const lng = getI18n().language
        window.location.href = `${config().fixrateCapitalBaseUrl}/order/${orderType.toLowerCase()}/confirm?lng=${lng}&customer=${depositor?.id}&externalReference=${orderGroupId}&token=${token}`
    }

    if (!orderGroup) return null

    const orderInfoBoxStyle = {
        backgroundColor: 'white',
        p: '2rem',
        border: '0.1rem solid',
        borderColor: 'grey.300',
        boxShadow: '0.1rem 0.1rem 0.1rem rgba(0,0,0,0.15)',
        mb: '2rem',
    }

    const statesInGroup = orderGroup.map((order) => order.state)
    const groupState: FundOrderState = orderGroup.reduce((acc, order) => {
        switch (order.type) {
            case 'BUY':
                if (statesInGroup.includes('AWAITING_SIGNATURE')) return 'AWAITING_SIGNATURE'
                if (statesInGroup.includes('AWAITING_PAYMENT')) return 'AWAITING_PAYMENT'
                if (statesInGroup.includes('AWAITING_PAYMENT_CONFIRMATION')) return 'AWAITING_PAYMENT_CONFIRMATION'
                if (statesInGroup.includes('IN_PROGRESS')) return 'IN_PROGRESS'
                if (statesInGroup.includes('EXECUTING_IN_MARKET')) return 'EXECUTING_IN_MARKET'
                if (statesInGroup.includes('COMPLETED')) return 'COMPLETED'
                if (statesInGroup.includes('CANCELLED')) return 'CANCELLED'
                if (statesInGroup.includes('CANCELLED_BY_DEPOSITOR')) return 'CANCELLED_BY_DEPOSITOR'
                break
            case 'SELL':
                if (statesInGroup.includes('AWAITING_SIGNATURE')) return 'AWAITING_SIGNATURE'
                if (statesInGroup.includes('IN_PROGRESS')) return 'IN_PROGRESS'
                if (statesInGroup.includes('EXECUTING_IN_MARKET')) return 'EXECUTING_IN_MARKET'
                if (statesInGroup.includes('TRANSFERRING_MONEY')) return 'TRANSFERRING_MONEY'
                if (statesInGroup.includes('COMPLETED')) return 'COMPLETED'
                if (statesInGroup.includes('CANCELLED')) return 'CANCELLED'
                if (statesInGroup.includes('CANCELLED_BY_DEPOSITOR')) return 'CANCELLED_BY_DEPOSITOR'
                break
        }
        return acc
    }, '')

    const fundOrderHeader = (orderDate: string, headerText: string) => {
        return (
            <Stack>
                {headerText}
                <Typography variant={'caption'}>
                    {t('pages-FundOverview.orderDate') + ': ' + DateOutput.formatDateTime(orderDate)}
                </Typography>
            </Stack>
        )
    }

    return (
        <li data-cy="fundOrderListItem" className={classNames(styles.accordion, styles.fundOrder)}>
            <div className={styles.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>
                <div className={styles.orderName}>
                    {orderGroup.length > 1 ? (
                        <span>
                            {accordionOpen
                                ? fundOrderHeader(orderGroup[0].created, t('pages-FundOverview.fundInThisOrder'))
                                : fundOrderHeader(
                                      orderGroup[0].created,
                                      orderGroup.length + ' ' + t('pages-FundOverview.fundInThisOrder').toLowerCase()
                                  )}
                        </span>
                    ) : (
                        <div className={styles.orderNameDetails}>
                            {orderGroup.map((o) => {
                                const fund = funds.find((fund) => fund.id === o.fundId)
                                const shareClass = getShareClass(fund, o.fundShareClassId)
                                return fund ? (
                                    <Tooltip key={o.id} title={t(fundOrderStateTMap[o.state])} placement={'left'} arrow>
                                        <>
                                            <div key={fund.id}>
                                                <div data-cy="fundNameText">{shareClass?.fullName}</div>
                                                <p data-cy="fundIsinText">
                                                    {t('pages-FundOverview.isin')}: {shareClass?.isin}
                                                </p>
                                            </div>
                                            <Typography variant={'caption'}>
                                                {t('pages-FundOverview.orderDate') +
                                                    ': ' +
                                                    DateOutput.formatDateTime(o.created)}
                                            </Typography>
                                        </>
                                    </Tooltip>
                                ) : null
                            })}
                            <span className={styles.date}> </span>
                        </div>
                    )}
                </div>
                <span className={styles.orderType}>
                    <span data-cy="orderTypeText">
                        <OrderTypeBadge orderType={orderType} />
                    </span>
                </span>
                <span data-cy="orderStatusText" className={styles.orderStatus}>
                    <span className={styles.statusTitle}>
                        {groupState === 'AWAITING_SIGNATURE' && <i className="ri-pencil-line orange" />}
                        {groupState === 'AWAITING_PAYMENT' && <i className="ri-coin-line orange" />}
                        {groupState === 'AWAITING_PAYMENT_CONFIRMATION' && <i className="ri-coin-line orange" />}
                        {groupState === 'IN_PROGRESS' && <i className="ri-time-line orange" />}
                        {groupState === 'EXECUTING_IN_MARKET' && <i className="ri-store-line orange" />}
                        {groupState === 'TRANSFERRING_MONEY' && <i className="ri-hand-coin-line orange" />}
                        {groupState === 'COMPLETED' && <i className="ri-checkbox-circle-line green" />}
                        {groupState === 'CANCELLED' && <i className="ri-close-circle-line red" />}
                        {groupState === 'CANCELLED_BY_DEPOSITOR' && <i className="ri-close-circle-line red" />}
                        {t(fundOrderStateTMap[groupState])}
                    </span>
                </span>
                <span data-cy="orderAmountText" className={styles.orderValue}>
                    {orderType === 'SELL'
                        ? Currency((getShareClassByFundOrder(sampleOrder)?.nav ?? 0) * orderAmount, { decimals: 0 })
                        : Currency(orderAmount, { decimals: 0 })}
                </span>
                <i className={classNames('ri-arrow-down-s-line', styles.menuArrow, accordionOpen && styles.flip)} />
            </div>
            {orderGroup.length > 1 ? (
                <div className={styles.accordionHeader} onClick={() => setAccordionOpen(!accordionOpen)}>
                    <div className={styles.orderName}>
                        <div className={styles.orderNameDetailsMultiple}>
                            {orderGroup.map((o) => {
                                const fund = funds.find((fund) => fund.id === o.fundId)
                                const shareClass = getShareClass(fund, o.fundShareClassId)
                                return fund ? (
                                    <Tooltip title={t(fundOrderStateTMap[o.state])} placement={'left'} arrow>
                                        <span key={fund.id}>
                                            <span data-cy="fundNameText">{shareClass?.fullName}</span>
                                            <p data-cy="fundIsinText">
                                                {t('pages-FundOverview.isin')}: {shareClass?.isin}
                                            </p>
                                        </span>
                                    </Tooltip>
                                ) : null
                            })}
                            <span className={styles.date}> </span>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className={classNames(styles.accordionContent, accordionOpen ? styles.open : null)}>
                <div>
                    <OrderGroupProgressBar
                        groupState={groupState}
                        orderType={orderType}
                        created={sampleOrder.created}
                        stateHistory={sampleOrder.stateHistory}
                    />
                    <div className={styles.orderFlex}>
                        <div className={styles.orderProgress}>
                            <Box sx={{ ...orderInfoBoxStyle }}>
                                {groupState === 'AWAITING_SIGNATURE' && (
                                    <Stack spacing={1}>
                                        <Alert severity={'warning'}>
                                            <AlertTitle>{signatureInstructions}</AlertTitle>
                                            {orderDocumentSignatureStatus.signedByAny
                                                ? t('components-OrderCard.multipleSignersInstructions')
                                                : t('components-OrderCard.mustBeSignedInstructions')}
                                        </Alert>
                                        <AsyncButton
                                            onClick={() => gotoFixrateCapitalSignatureUrl(orderType)}
                                            disabled={orderDocumentSignatureStatus.signedByUser}
                                        >
                                            {t('components-OrderCard.goToSigning')}
                                        </AsyncButton>
                                        <LoadingButton onClick={cancelOrder} variant={'outlined'} color={'error'}>
                                            Avbryt ordre
                                        </LoadingButton>
                                        {orderDocumentSignatureStatus.signedByUser && (
                                            <Typography
                                                variant="caption"
                                                sx={{ borderTop: '0.1rem solid rgba(0,0,0,0.1)', pt: 1, mt: 1 }}
                                            >
                                                {t('components-OrderCard.alreadySignedByUser')}
                                            </Typography>
                                        )}
                                    </Stack>
                                )}
                                {groupState === 'AWAITING_PAYMENT' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-coin-line purple" />
                                                {t('pages-FundOverview.paymentInformation')}
                                            </span>
                                        </h4>
                                        <ul className={styles.keyValueList}>
                                            <li>
                                                <span className={styles.key}>
                                                    {t('pages-FundOverview.accountNumber')}
                                                </span>
                                                <span data-cy="paymentInfoAccountNoText" className={styles.value}>
                                                    {clientAccount?.name}{' '}
                                                    {formatAccount(clientAccount?.accountNumber, organisationCountry)}
                                                </span>
                                            </li>
                                            {currency !== 'NOK' && (
                                                <li>
                                                    <span className={styles.key}>{t('pages-FundOverview.iban')}</span>
                                                    <span className={styles.value}>{sampleOrder.iban}</span>
                                                </li>
                                            )}
                                            {currency !== 'NOK' && (
                                                <li>
                                                    <span className={styles.key}>{t('pages-FundOverview.bic')}</span>
                                                    <span className={styles.value}>{sampleOrder.bic}</span>
                                                </li>
                                            )}
                                            {!clientAccount?.fundPortfolioClientAccount && (
                                                <li>
                                                    <span className={styles.key}>
                                                        {currency !== 'NOK'
                                                            ? t('pages-FundOverview.KID-numberRef')
                                                            : t('pages-FundOverview.KID-number')}
                                                    </span>
                                                    <span data-cy="paymentInfoKIDNoText" className={styles.value}>
                                                        {currentCid}
                                                    </span>
                                                </li>
                                            )}
                                            <li>
                                                <span className={styles.key}>
                                                    {t('pages-FundOverview.invoicedAmount')}
                                                </span>
                                                <span data-cy="paymentInfoOrderAmountText" className={styles.value}>
                                                    {Currency(orderAmount, { decimals: 0 })}
                                                </span>
                                            </li>
                                            <li>
                                                <div>
                                                    <FormControlLabel
                                                        style={{ width: '100%' }}
                                                        control={
                                                            <Checkbox
                                                                disabled={!userCanConfirmPayment}
                                                                data-cy="paymentIsDoneCheckbox"
                                                                checked={confirmPayment}
                                                                onChange={(e, newVal) => setConfirmPayment(newVal)}
                                                                name="wantCake"
                                                            />
                                                        }
                                                        label={
                                                            clientAccount?.fundPortfolioClientAccount
                                                                ? t(
                                                                      'components-OrderCard.requiredAmountIsReadyForTransfer'
                                                                  )
                                                                : t('pages-inbox.fundReadyForPaymentTaskCheckboxPledge')
                                                        }
                                                    />
                                                    <Button
                                                        data-cy="paymentIsDoneButton"
                                                        variant={'contained'}
                                                        color={'primary'}
                                                        onClick={handleConfirmPayment}
                                                        disabled={!userCanConfirmPayment}
                                                    >
                                                        {t('pages-inbox.fundReadyForPaymentTaskCheckboxButton')}
                                                    </Button>
                                                </div>
                                            </li>
                                            <li>
                                                {!userCanConfirmPayment && (
                                                    <Alert
                                                        severity="warning"
                                                        action={
                                                            <Button
                                                                sx={{ whiteSpace: 'nowrap' }}
                                                                variant="outlined"
                                                                color="inherit"
                                                                size="small"
                                                                onClick={() =>
                                                                    navigate('/organizations/' + orgId + '/users/roles')
                                                                }
                                                            >
                                                                {t('components-ShoppingCart.goToRoles')}
                                                            </Button>
                                                        }
                                                    >
                                                        {t('components-ShoppingCart.userMissingPaymentPermission')}
                                                    </Alert>
                                                )}
                                            </li>
                                        </ul>
                                    </>
                                )}
                                {groupState === 'AWAITING_PAYMENT_CONFIRMATION' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.awaitingPaymentConfirmationTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.awaitingPaymentConfirmationInformation')}</p>
                                        <Button size={'small'} variant={'outlined'} onClick={openChatBubble}>
                                            {t('common.openChat')}
                                        </Button>
                                    </>
                                )}
                                {groupState === 'IN_PROGRESS' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderProcessedTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderProcessedInformation')}</p>
                                        <Button size={'small'} variant={'outlined'} onClick={openChatBubble}>
                                            {t('common.openChat')}
                                        </Button>
                                    </>
                                )}
                                {groupState === 'EXECUTING_IN_MARKET' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderExecutingInMarketTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderExecutingInMarketInformation')}</p>
                                        <Button size={'small'} variant={'outlined'} onClick={openChatBubble}>
                                            {t('common.openChat')}
                                        </Button>
                                    </>
                                )}
                                {groupState === 'TRANSFERRING_MONEY' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderTransferringMoneyTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderTransferringMoneyInformation')}</p>
                                    </>
                                )}
                                {groupState === 'COMPLETED' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderCompletedTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderCompletedInformation')}</p>
                                    </>
                                )}
                                {groupState === 'CANCELLED' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderCancelledTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderCancelledInformation')}</p>
                                    </>
                                )}
                                {groupState === 'CANCELLED_BY_DEPOSITOR' && (
                                    <>
                                        <h4>
                                            <span className={styles.progressHeader}>
                                                <i className="ri-chat-1-line purple" />
                                                {t('pages-FundOverview.orderCancelledByDepositorTitle')}
                                            </span>
                                        </h4>
                                        <p>{t('pages-FundOverview.orderCancelledByDepositorInformation')}</p>
                                    </>
                                )}
                            </Box>
                            <Box sx={{ ...orderInfoBoxStyle, p: '2rem 2rem 0 2rem' }}>
                                <h4>
                                    <span className={styles.progressHeader}>
                                        <i className="ri-line-chart-line purple" />
                                        {t('pages-FundOverview.funds')}
                                    </span>
                                </h4>
                                <ul className={styles.orderedFunds}>
                                    {orderGroup.map((o) => {
                                        const fund = funds.find((fund) => fund.id === o.fundId)
                                        const shareClass = getShareClass(fund, o.fundShareClassId)
                                        return (
                                            <li key={o.id}>
                                                <Tooltip
                                                    title={t(fundOrderStateTMap[o.state])}
                                                    placement={'left'}
                                                    arrow
                                                >
                                                    <span className={styles.fundName}>
                                                        {o.state === 'AWAITING_PAYMENT' && (
                                                            <i className="ri-coin-line orange" />
                                                        )}
                                                        {o.state === 'IN_PROGRESS' && (
                                                            <i className="ri-time-line orange" />
                                                        )}
                                                        {o.state === 'EXECUTING_IN_MARKET' && (
                                                            <i className="ri-store-line orange" />
                                                        )}
                                                        {o.state === 'TRANSFERRING_MONEY' && (
                                                            <i className="ri-hand-coin-line orange" />
                                                        )}
                                                        {o.state === 'COMPLETED' && (
                                                            <i className="ri-checkbox-circle-line green" />
                                                        )}
                                                        {o.state === 'CANCELLED' && (
                                                            <i className="ri-close-circle-line red" />
                                                        )}
                                                        {o.state === 'CANCELLED_BY_DEPOSITOR' && (
                                                            <i className="ri-close-circle-line red" />
                                                        )}
                                                        <span data-cy="fundNameText">{shareClass?.fullName}</span>
                                                    </span>
                                                </Tooltip>
                                                {orderType === 'BUY' && (
                                                    <span className={styles.fundAmount}>
                                                        {Currency(o.amount, { decimals: 0 })}
                                                    </span>
                                                )}
                                                {orderType === 'SELL' && (
                                                    <span className={styles.fundAmount}>
                                                        {Currency(o.amount * shareClass?.nav, { decimals: 0 })}
                                                    </span>
                                                )}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Box>
                        </div>
                        <div className={styles.orderInformation}>
                            <h4>
                                <span className={styles.orderInfoHeader}>
                                    <i className="ri-file-line purple" />
                                    {t('pages-FundOverview.orderInformation')}
                                </span>
                            </h4>
                            <ul className={styles.keyValueList}>
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.orderType')}</span>
                                    <span data-cy="orderInfoOrderTypeText" className={styles.value}>
                                        <OrderTypeBadge orderType={orderType} />
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.key}>
                                        {orderType === 'SELL'
                                            ? t('pages-FundOverview.estimatedAmount')
                                            : t('pages-FundOverview.amount')}
                                    </span>
                                    <span data-cy="orderInfoOrderAmountText" className={styles.value}>
                                        {orderType === 'SELL'
                                            ? Currency(
                                                  (getShareClassByFundOrder(sampleOrder)?.nav ?? 0) * orderAmount,
                                                  { decimals: 0 }
                                              )
                                            : Currency(orderAmount, { decimals: 0 })}
                                    </span>
                                </li>
                                {orderType === 'SELL' && (
                                    <li>
                                        <span className={styles.key}>{t('pages-FundOverview.units')}</span>
                                        <span className={styles.value}>{CurrencyOutput.formatNoCode(orderAmount)}</span>
                                    </li>
                                )}
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.currency')}</span>
                                    <span className={styles.value}>{currency}</span>
                                </li>
                                {orderType === 'SELL' && (
                                    <li>
                                        <span className={styles.key}>{t('pages-FundOverview.settlementAccount')}</span>
                                        <span data-cy="orderInfoAccountNoText" className={styles.value}>
                                            {sampleOrder.accountNumber}
                                        </span>
                                    </li>
                                )}
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.organization')}</span>
                                    <span className={styles.value}>{depositor?.name}</span>
                                </li>
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.orderCreatedBy')}</span>
                                    <span data-cy="orderInfoCreatedByText" className={styles.value}>
                                        {userFullNameMap[sampleOrder.createdBy]}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.orderCreated')}</span>
                                    <span data-cy="orderInfoCreatedText" className={styles.value}>
                                        {DateOutput.formatDateTime(sampleOrder.created)}
                                    </span>
                                </li>
                                <li>
                                    <span className={styles.key}>{t('pages-FundOverview.documentation')}</span>
                                    <span className={styles.value}>
                                        {orderDocument && (
                                            <Link
                                                className={styles.documentLink}
                                                to={orderDocument.link}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                <i className="ri-file-line" />
                                                <span>{t('pages-FundOverview.orderDocument')}</span>
                                            </Link>
                                        )}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}
