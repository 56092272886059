import { Paper } from '#app/components'
import { DepositorDto } from '@fixrate/fixrate-query'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import styles from './UserProfile.module.scss'

type Params = {
    userId?: string
    partnerId?: string
}

export default function AdvisorProfile({ depositor }: { depositor: DepositorDto }) {
    const { t } = useTranslation()
    const { userId, partnerId } = useParams<Params>()
    const user = depositor.users.find((user) => user.id === userId)

    const partnerRelation = depositor.partnerRelations.find((pr) => pr.partnerId === partnerId)

    if (!user) {
        return null
    }

    return (
        <div className={styles.container}>
            <Paper className={styles.userProfile}>
                <div className={styles.header}>
                    <h2>
                        <span className={styles.profileCircle}>
                            <i className="ri-user-line" />
                        </span>
                        <span>
                            {user.firstName} {user.lastName}
                        </span>
                    </h2>
                </div>

                <p>
                    {partnerRelation.partnerCategory === 'ACCOUNTANT'
                        ? t('pages-organizations.accountantDescription', { name: user.firstName + ' ' + user.lastName })
                        : t('pages-organizations.partnerDescription', { name: user.firstName + ' ' + user.lastName })}
                    &nbsp;
                    <Link to={`/organizations/${depositor.id}/terms`}>{partnerRelation.name}</Link>.
                </p>

                <ul>
                    <li>
                        <h4 className={styles.fieldHeader}>{t('pages-organizations.personEmail')}</h4>
                        <p>{user.email}</p>
                    </li>
                    <li>
                        <h4 className={styles.fieldHeader}>{t('pages-organizations.personPhone')}</h4>
                        <p>{user.phone}</p>
                    </li>
                </ul>
            </Paper>
        </div>
    )
}
