import { useLocalStorage } from '#app/services/LocalStorageContext'
import { useSelector } from '#app/state/useSelector'
import FundGrid from '#pages/FundMarketplace/FundGrid/FundGrid'
import FundTable from '#pages/FundMarketplace/FundTable/FundTable'
import useFundFilterStorage from '#pages/FundMarketplace/useFundFilterStorage'
import useCurrentDepositor from '#services/useCurrentDepositor'
import { FundCategory } from '@fixrate/fixrate-query'
import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import FundInfoSlideOut from './FundInfoSlideOut/FundInfoSlideOut'
import MandateSlideOut from './MandateSlideOut/MandateSlideOut'
import ShoppingCartPane from './ShoppingCartPane/ShoppingCartPane'
import usePortfolio from '#services/usePortfolio'
import { fundCategory2investmentCategory } from '#services/investmentCategory'
import fixrateLogo from './assets/FixrateLogoGray.svg?url'

export type MainFundCategory = 'ALL' | FundCategory
export default function FundMarketplace({
    cartOpen,
    setCartOpen,
}: {
    cartOpen: boolean
    setCartOpen: (open: boolean) => void
}) {
    const isFundIntermediary = useSelector(
        (state) => state.session.association?.organisationType === 'FUND_INTERMEDIARY'
    )
    const isSystemUser = useSelector((state) => state.session.association?.organisationType === 'FIXRATE')
    const isBank = useSelector((state) => state.session.association?.organisationType === 'BANK')
    const isPartner = useSelector((state) => state.session.association?.organisationType === 'PARTNER')
    const currentDepositor = useCurrentDepositor()
    const portfolio = usePortfolio()
    const { t } = useTranslation()
    const [mandateSlideOutOpen, setMandateSlideOutOpen] = useState(false)
    const { fundCategory } = useParams() as { fundCategory: FundCategory }
    const filterMainFundCategory = fundCategory.replace(/-/g, '_').toUpperCase() as FundCategory

    const {
        keys: { shareClassIdForSlideout, fundsViewType },
    } = useLocalStorage()

    const filterStorage = useFundFilterStorage()

    const funds = useSelector((state) => state.funds)

    if (!funds) {
        return null
    }

    const availableFunds = funds
        .filter((f) =>
            f.fundShareClasses?.some(
                (fsc) => fsc.published || fsc.tradableByDepositorIds?.includes(currentDepositor?.id)
            )
        )
        .filter((fund) => fund.fundCategory === filterMainFundCategory)

    const filteredFunds = availableFunds.filter((fund) => filterStorage.filters.every((filterFn) => filterFn(fund)))
    const hasAccessToFundCategory =
        currentDepositor?.mandates[portfolio.id].policies[fundCategory2investmentCategory(filterMainFundCategory)]
            .enabled ||
        isFundIntermediary ||
        isSystemUser

    const categoryDescription =
        filterMainFundCategory === 'MONEY_MARKET_FUND'
            ? t('common.moneyMarketFundsDescription')
            : filterMainFundCategory === 'BOND_FUND'
              ? t('common.bondFundsDescription')
              : null

    return (
        <Fragment>
            <Box
                sx={{
                    backgroundImage: `url(${fixrateLogo})`,
                    backgroundPosition: 'top right',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Stack mt={{ md: 2.7 }} spacing={4} sx={{ px: { xs: 2, md: 0 } }} maxWidth="140rem">
                    <Typography fontSize={'1.4rem'} fontWeight={600}>
                        {categoryDescription.split('\n').map((item, key) => {
                            return (
                                <span key={key}>
                                    {item}
                                    <br />
                                </span>
                            )
                        })}
                    </Typography>
                    {currentDepositor?.customerDeclarationExpired && (
                        <Alert
                            severity="warning"
                            elevation={1}
                            sx={{ maxWidth: '80rem' }}
                            action={
                                <Button
                                    variant={'outlined'}
                                    color={'inherit'}
                                    size="small"
                                    href={`/organizations/${currentDepositor.id}/customerdecl`}
                                >
                                    {t('pages-marketplace.doItNow')}
                                </Button>
                            }
                        >
                            {t('pages-marketplace.customerDeclarationMustBeUpdatedWarning', {
                                depositorName: currentDepositor.name,
                            })}
                        </Alert>
                    )}
                    {hasAccessToFundCategory ? (
                        <Box>
                            {fundsViewType === 'GRID' && (
                                <FundGrid funds={filteredFunds} openShoppingCart={() => setCartOpen(true)} />
                            )}
                            {fundsViewType === 'LIST' && <FundTable funds={filteredFunds} />}
                        </Box>
                    ) : (
                        <Box>
                            <Alert
                                icon={<i className="ri-lock-line" />}
                                severity="warning"
                                action={
                                    !isBank &&
                                    !isPartner && (
                                        <Button
                                            onClick={() => setMandateSlideOutOpen(true)}
                                            size="small"
                                            color="inherit"
                                            variant="outlined"
                                        >
                                            {t('block-Mandate.administrateMandate')}
                                        </Button>
                                    )
                                }
                            >
                                <AlertTitle>{t('block-Mandate.fundCategoryNotAllowed')}</AlertTitle>
                                {t('block-Mandate.fundCategoryNotAllowedDescription')}
                            </Alert>
                        </Box>
                    )}
                </Stack>
            </Box>
            <MandateSlideOut
                open={mandateSlideOutOpen}
                onClose={() => {
                    setMandateSlideOutOpen(false)
                }}
            />
            <ShoppingCartPane open={cartOpen} setOpen={setCartOpen} />
            <FundInfoSlideOut shareClassId={shareClassIdForSlideout} />
        </Fragment>
    )
}
