import { formatOrganizationNumber } from '#app/services/formatnumber'
import { useSelector } from '#app/state/useSelector'
import { FundCustomerDto, FundPlacementDto } from '@fixrate/fixrate-query'
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    TableSortLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DateOutput from '#app/components/DateOutput/DateOutput'
import { SearchSharp } from '@mui/icons-material'
import { parse, format, isValid } from 'date-fns'
import { enGB, nb, sv } from 'date-fns/locale'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useSessionLanguage } from '#services/useSessionLanguage'

type Row = FundCustomerDto & {
    id: string
    signedContracts: boolean
    riskMitigationMeasure: string
    fundplacements: number
    lastTransactionDate: string
    buyEnabledDate: string
}

type Props = {
    customers: FundCustomerDto[]
    fundPlacements: FundPlacementDto[]
}

const getSelectedLanguage = (language: string) => {
    switch (language) {
        case 'en':
            return enGB
        case 'sv':
            return sv
        default:
            return nb
    }
}

export default function FundCustomerList({ customers, fundPlacements }: Readonly<Props>) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [order, setOrder] = useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = useState<string>('name')
    const [signedContractsFilter, setSignedContractsFilter] = useState<string>('all')
    const [buyEnabledFilter, setBuyEnabledFilter] = useState<string>('all')
    const [riskMitigationMeasureFilter, setRiskMitigationMeasureFilter] = useState<string>('all')
    const [fundPlacementsFilter, setFundPlacementsFilter] = useState<string>('all')
    const [fromDate, setFromDate] = useState<Date | null>(null)
    const [toDate, setToDate] = useState<Date | null>(null)
    const documents = useSelector((state) => state.documents)
    const language = useSessionLanguage()
    const selectedAdapterLanguage = getSelectedLanguage(language)

    const getLastTransactionDate = (depositorId: string) => {
        const placements = fundPlacements.filter((placement) => placement.depositorId === depositorId)
        if (placements.length === 0) {
            return ''
        }
        const lastTransactionDate = placements
            .flatMap((placement) => placement.transactions)
            .reduce(
                (latest, transaction) =>
                    new Date(latest.transactionDate) > new Date(transaction.transactionDate) ? latest : transaction,
                placements[0].transactions[0]
            ).transactionDate
        return DateOutput.formatDate(lastTransactionDate)
    }

    const compareBuyEnabledDates = (dateA: string, dateB: string) => {
        const [monthA, yearA] = dateA.split('-').map(Number)
        const [monthB, yearB] = dateB.split('-').map(Number)
        return yearA - yearB || monthA - monthB
    }

    const rows: Row[] = customers.map((customer) => ({
        ...customer,
        id: customer.depositorId,
        signedContracts:
            customer.contracts.length > 0 &&
            customer.contracts.every((contract) => documents[contract.documentId]?.signedByAll),
        riskMitigationMeasure: customer.fundCustomerFollowUp?.riskMitigationMeasure ?? '',
        fundplacements: fundPlacements.filter(
            (placement) => placement.depositorId === customer.depositorId && placement.unitQuantity > 0
        ).length,
        lastTransactionDate: getLastTransactionDate(customer.depositorId),
        buyEnabledDate: customer.buyEnabled ? DateOutput.formatMonthYearNumeric(customer.buyEnabledDate) : '',
    }))

    const filteredRows = rows.filter((row) => {
        const signedContractsMatch =
            signedContractsFilter === 'all' ||
            (signedContractsFilter === 'yes' ? row.signedContracts : !row.signedContracts)
        const buyEnabledMatch =
            buyEnabledFilter === 'all' || (buyEnabledFilter === 'yes' ? row.buyEnabled : !row.buyEnabled)
        const riskMitigationMeasureMatch =
            riskMitigationMeasureFilter === 'all' || row.riskMitigationMeasure === riskMitigationMeasureFilter
        const fundPlacementsMatch =
            fundPlacementsFilter === 'all' ||
            (fundPlacementsFilter === '0' ? row.fundplacements === 0 : row.fundplacements > 0)

        const fromDateMatch = fromDate
            ? row.buyEnabledDate && compareBuyEnabledDates(format(fromDate, 'MM-yyyy'), row.buyEnabledDate) <= 0
            : true
        const toDateMatch = toDate
            ? row.buyEnabledDate && compareBuyEnabledDates(row.buyEnabledDate, format(toDate, 'MM-yyyy')) <= 0
            : true

        return (
            signedContractsMatch &&
            buyEnabledMatch &&
            riskMitigationMeasureMatch &&
            fundPlacementsMatch &&
            fromDateMatch &&
            toDateMatch
        )
    })

    const columns = [
        { field: 'name', headerName: t('pages.fundCustomers.customerList.tableHeaders.name') },
        { field: 'buyEnabledDate', headerName: t('pages.fundCustomers.customerList.tableHeaders.buyEnabledDate') },
        { field: 'nationalIdentity', headerName: t('pages.fundCustomers.customerList.tableHeaders.orgNumber') },
        { field: 'enabled', headerName: t('pages.fundCustomers.customerList.tableHeaders.enabled') },
        { field: 'signedContracts', headerName: t('pages.fundCustomers.customerList.tableHeaders.signedContracts') },
        { field: 'buyEnabled', headerName: t('pages.fundCustomers.customerList.tableHeaders.buyEnabled') },
        {
            field: 'riskMitigationMeasure',
            headerName: t('pages.fundCustomers.customerList.tableHeaders.riskMitigationMeasure'),
        },
        {
            field: 'fundplacements',
            headerName: t('pages.fundCustomers.customerList.tableHeaders.activefundPlacements'),
        },
        {
            field: 'lastTransactionDate',
            headerName: t('pages.fundCustomers.customerList.tableHeaders.lastTransactionDate'),
        },
    ]

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const compareDates = (dateA: string, dateB: string) => {
        if (!dateA) return 1
        if (!dateB) return -1

        const dA = parse(dateA, 'dd.MM.yyyy', new Date())
        const dB = parse(dateB, 'dd.MM.yyyy', new Date())
        return order === 'asc' ? dA.getTime() - dB.getTime() : dB.getTime() - dA.getTime()
    }

    const compareValues = (valueA: string | number | boolean, valueB: string | number | boolean) => {
        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return order === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return order === 'asc' ? valueA - valueB : valueB - valueA
        } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
            return order === 'asc' ? Number(valueA) - Number(valueB) : Number(valueB) - Number(valueA)
        }
        return 0
    }

    const sortedRows = [...filteredRows].sort((a, b) => {
        if (orderBy === 'lastTransactionDate') {
            return compareDates(a.lastTransactionDate, b.lastTransactionDate)
        } else if (orderBy === 'buyEnabledDate') {
            return compareBuyEnabledDates(a.buyEnabledDate, b.buyEnabledDate)
        } else {
            const valueA = a[orderBy as keyof Row]
            const valueB = b[orderBy as keyof Row]
            if (typeof valueA === 'string' || typeof valueA === 'number' || typeof valueA === 'boolean') {
                return compareValues(valueA, valueB as string | number | boolean)
            }
            return 0
        }
    })

    const goToFundTransactions = (depositorId: string) => {
        navigate('/fund-transactions?depositorId=' + depositorId)
    }

    return (
        <Box sx={{ width: '100%', paddingTop: 2 }}>
            <Box sx={{ pr: 2, pb: 2, justifyContent: 'flex-end', display: 'flex' }}>
                {t('pages.fundCustomers.customerList.numberOfCustomers')}: {sortedRows.length}
            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
                <FormControl sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>{t('pages.fundCustomers.customerList.tableHeaders.signedContracts')}</InputLabel>
                    <Select
                        value={signedContractsFilter}
                        onChange={(e) => setSignedContractsFilter(e.target.value)}
                        label={t('pages.fundCustomers.customerList.tableHeaders.signedContracts')}
                    >
                        <MenuItem value="all">{t('pages.fundCustomers.customerList.filters.all')}</MenuItem>
                        <MenuItem value="yes">{t('pages.fundCustomers.customerList.filters.yes')}</MenuItem>
                        <MenuItem value="no">{t('pages.fundCustomers.customerList.filters.no')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>{t('pages.fundCustomers.customerList.tableHeaders.buyEnabled')}</InputLabel>
                    <Select
                        value={buyEnabledFilter}
                        onChange={(e) => setBuyEnabledFilter(e.target.value)}
                        label={t('pages.fundCustomers.customerList.tableHeaders.buyEnabled')}
                    >
                        <MenuItem value="all">{t('pages.fundCustomers.customerList.filters.all')}</MenuItem>
                        <MenuItem value="yes">{t('pages.fundCustomers.customerList.filters.yes')}</MenuItem>
                        <MenuItem value="no">{t('pages.fundCustomers.customerList.filters.no')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>{t('pages.fundCustomers.customerList.tableHeaders.riskMitigationMeasure')}</InputLabel>
                    <Select
                        value={riskMitigationMeasureFilter}
                        onChange={(e) => setRiskMitigationMeasureFilter(e.target.value)}
                        label={t('pages.fundCustomers.customerList.tableHeaders.riskMitigationMeasure')}
                    >
                        <MenuItem value="all">{t('pages.fundCustomers.customerList.filters.all')}</MenuItem>
                        <MenuItem value="GENERAL">{t('enum-RiskMitigationLevel.GENERAL')}</MenuItem>
                        <MenuItem value="ENHANCED">{t('enum-RiskMitigationLevel.ENHANCED')}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 120, mr: 2 }}>
                    <InputLabel>{t('pages.fundCustomers.customerList.tableHeaders.activefundPlacements')}</InputLabel>
                    <Select
                        value={fundPlacementsFilter}
                        onChange={(e) => setFundPlacementsFilter(e.target.value)}
                        label={t('pages.fundCustomers.customerList.tableHeaders.activefundPlacements')}
                    >
                        <MenuItem value="all">{t('pages.fundCustomers.customerList.filters.all')}</MenuItem>
                        <MenuItem value="0">{t('pages.fundCustomers.customerList.filters.no')}</MenuItem>
                        <MenuItem value="1">{t('pages.fundCustomers.customerList.filters.yes')}</MenuItem>
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={selectedAdapterLanguage}>
                    <DatePicker
                        value={fromDate}
                        onChange={(newValue) => {
                            if (newValue && isValid(newValue)) {
                                setFromDate(newValue)
                            }
                        }}
                        label={t('pages.fundCustomers.customerList.filters.fromDate')}
                        minDate={new Date('2023-01-01')}
                        maxDate={new Date('2050-12-31')}
                        views={['year', 'month']}
                        format="MM-yyyy"
                        slotProps={{
                            field: { clearable: true, onClear: () => setFromDate(null) },
                        }}
                        sx={{ minWidth: 120, mr: 2 }}
                    />
                    <DatePicker
                        value={toDate}
                        onChange={(newValue) => {
                            if (newValue && isValid(newValue)) {
                                setToDate(newValue)
                            }
                        }}
                        label={t('pages.fundCustomers.customerList.filters.toDate')}
                        minDate={new Date('2023-01-01')}
                        maxDate={new Date('2050-12-31')}
                        views={['year', 'month']}
                        format="MM-yyyy"
                        slotProps={{
                            field: { clearable: true, onClear: () => setToDate(null) },
                        }}
                        sx={{ minWidth: 120 }}
                    />
                </LocalizationProvider>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.field}>
                                    <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={orderBy === column.field ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.field)}
                                    >
                                        {column.headerName}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row) => (
                            <TableRow
                                key={row.id}
                                onClick={() => navigate('/fund-customers/' + row.depositorId)}
                                sx={{
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.buyEnabledDate}</TableCell>
                                <TableCell>{formatOrganizationNumber(row.nationalIdentity, 'NO')}</TableCell>
                                <TableCell>
                                    {row.enabled ? (
                                        <i className="ri-check-line green" />
                                    ) : (
                                        <i className="ri-close-line red" />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {row.signedContracts ? (
                                        <i className="ri-check-line green" />
                                    ) : (
                                        <i className="ri-close-line red" />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {row.buyEnabled ? (
                                        <i className="ri-check-line green" />
                                    ) : (
                                        <i className="ri-close-line red" />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {row.riskMitigationMeasure
                                        ? t(`enum-RiskMitigationLevel.${row.riskMitigationMeasure}`)
                                        : ''}
                                </TableCell>
                                <TableCell>{row.fundplacements}</TableCell>
                                <TableCell>
                                    {row.lastTransactionDate}
                                    {row.lastTransactionDate && (
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                goToFundTransactions(row.depositorId)
                                            }}
                                        >
                                            <SearchSharp color="action" />
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
