import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './MarketplaceFilter.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from '#state/useSelector'
import classNames from 'classnames'
import * as actions from '#state/reducers/marketplacefilter'
import { setFilterProduct } from '#services/thunks/user'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Switch,
    Tab,
    Tabs,
} from '@mui/material'
import { isLoggedIn } from '#state/selectors'
import { useTranslation } from 'react-i18next'
import RichTooltip from '#pages/FundMarketplace/RichTooltip'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import useUiSetting from '#app/services/useUiSetting'
import FxDialog from '#app/components/FxDialog/FxDialog'
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle'
import FxDialogContent from '#app/components/FxDialog/FxDialogContent'
import { PlacementProfile } from '#app/pages/organizations/OrganizationDetail/Mandate/PlacementProfile'
import { usePlacementProfileAccess } from '#app/services/usePlacementProfileAccess'

export type DepositFilterOption = 'ALL' | 'FLOATING' | 'FIXED' | 'CUSTOM' | 'MANDATE'

export default function MarketplaceFilter() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const filter = useSelector((state) => state.marketplacefilter.activeFilter)
    const deposits = useSelector((state) => state.deposits)
    const ads = useSelector((state) => state.ads)
    const depositor = useSelector((state) => state.depositor)
    const hasPlacementProfileAccess = usePlacementProfileAccess()
    const products = useSelector((state) => {
        // All products visible to the user, either because they are marked as visible or because they have a history for this depositor
        return Object.values(state.products).filter(
            (product) =>
                product.visibility === 'VISIBLE' ||
                deposits.some((d) => d.product?.id === product.id) ||
                ads.some((a) => a.productId === product.id)
        )
    })

    const Currency = useCurrencyOutput()

    const [filterOpen, setFilterOpen] = useState(false)

    const selectedProductIds =
        Object.keys(filter.products).length === 0
            ? products.map((p) => p.id)
            : Object.keys(filter.products).filter((key) => filter.products[key] === true)

    useEffect(() => {
        if (Object.keys(filter.products).length === 0) {
            products
                .map((p) => p.id)
                .forEach((id) => {
                    dispatch(setFilterProduct(id, true))
                })
        }
    }, [dispatch, filter.products, products])

    const [currentFilterSelection, setCurrentFilterSelection] = useUiSetting(
        'selectedDepositFilter',
        depositor ? { [depositor.id]: 'ALL' } : {}
    )
    const [editMandateDialog, setEditMandateDialog] = useState(false)
    const [selectedDialogTab, setSelectedDialogTab] = useState<'placement' | 'mandate'>('mandate')

    const func = useMemo(
        () => ({
            setFilterMinDepositAmount: (value: number) => dispatch(actions.setFilterMinDepositAmount(value)),
            setFilterTotalAssets: (value: number) => dispatch(actions.setFilterTotalAssets(value)),
            setFilterProduct: (id: string, newState: boolean) => dispatch(setFilterProduct(id, newState)),
        }),
        [dispatch]
    )

    const onToggleProduct = useCallback(
        (id: string) => {
            func.setFilterProduct(id, !filter.products[id])
        },
        [filter.products, func]
    )

    function onFilterSelect(value: DepositFilterOption) {
        const mergedObj = { ...currentFilterSelection, [depositor?.id]: value }
        setCurrentFilterSelection(mergedObj)
        switch (value) {
            case 'FLOATING': {
                func.setFilterTotalAssets(0)
                func.setFilterMinDepositAmount(0)
                showOnlyFloatingInterest()
                break
            }
            case 'FIXED': {
                func.setFilterTotalAssets(0)
                func.setFilterMinDepositAmount(0)
                showOnlyFixedInterest()
                break
            }
            case 'ALL': {
                func.setFilterTotalAssets(0)
                func.setFilterMinDepositAmount(0)
                showAllProducts()
                break
            }
            case 'MANDATE': {
                func.setFilterTotalAssets(0)
                func.setFilterMinDepositAmount(0)
                showAllProducts()
                break
            }
        }
    }

    // Creates the badges for the products that are visible to the user
    const { productsFloatingBadges, productsFixedBadges } = useMemo(() => {
        const productsFloatingBadges: ReactElement[] = []
        const productsFixedBadges: ReactElement[] = []

        products.forEach((product) => {
            const markup = (
                <div
                    id={`product${product.id}Badge`}
                    key={product.id}
                    onClick={() => onToggleProduct(product.id)}
                    className={classNames(
                        styles.badge,
                        filter.products[product.id] && styles.badgeSelected,
                        filter.products[product.id] && 'cyBadgeSelected'
                    )}
                >
                    {t('common.productShortName' + product.id)}
                </div>
            )

            if (product.type === 'FLOATING') {
                productsFloatingBadges.push(markup)
            } else if (product.type === 'FIXED') {
                productsFixedBadges.push(markup)
            }
        })

        return { productsFloatingBadges, productsFixedBadges }
    }, [products, filter.products, t, onToggleProduct])

    const floatingIds = productsFloatingBadges.map((p) => p.key)
    const fixedIds = productsFixedBadges.map((p) => p.key)

    const filterHasFixed = [...selectedProductIds].filter((item) => fixedIds.includes(item)).length > 0
    const filterHasFloating = [...selectedProductIds].filter((item) => floatingIds.includes(item)).length > 0

    const totalAssetsSliderText =
        filter.totalAssets > 0
            ? t('pages-marketplace.atLeastMillions', {
                  amount: Currency(filter.totalAssets, { decimals: 0, withCurrency: false }),
              })
            : t('pages-marketplace.allBanks')
    const minDepositAmountSliderText =
        filter.minDepositAmount > 0
            ? t('pages-marketplace.atLeastMillions', { amount: filter.minDepositAmount })
            : t('pages-marketplace.allAmounts')

    const showOnlyFloatingInterest = () => {
        products
            .map((p) => p.id)
            .forEach((id) => {
                func.setFilterProduct(id, floatingIds.includes(id))
            })
    }

    const showOnlyFixedInterest = () => {
        products
            .map((p) => p.id)
            .forEach((id) => {
                func.setFilterProduct(id, fixedIds.includes(id))
            })
    }

    const showAllProducts = () => {
        products
            .map((p) => p.id)
            .forEach((id) => {
                func.setFilterProduct(id, true)
            })
    }

    useEffect(() => {
        if (currentFilterSelection[depositor?.id] === 'CUSTOM') {
            setFilterOpen(true)
        }
    }, [currentFilterSelection, depositor?.id])

    const filterPanel = (
        <Stack spacing={2} p={1} width={'30rem'}>
            <section>
                <label className={styles.sliderLabel}>{t('pages-marketplace.bankTotalAssets')}</label>
                <Box sx={{ pl: 1, pr: 2 }}>
                    <Slider
                        id="minBankAssetsRange"
                        min={0}
                        max={20_000}
                        step={500}
                        value={filter.totalAssets}
                        onChange={(_, value) => func.setFilterTotalAssets(value as number)}
                    />
                </Box>
                <span className={styles.sliderValue}>{totalAssetsSliderText}</span>
            </section>
            <section>
                <label className={styles.sliderLabel}>{t('pages-marketplace.desiredDepositAmount')}</label>
                <Box sx={{ pl: 1, pr: 2 }}>
                    <Slider
                        id="minDepositAmountRange"
                        min={20}
                        max={100}
                        step={1}
                        value={filter.minDepositAmount}
                        onChange={(_, value) => func.setFilterMinDepositAmount(value as number)}
                    />
                </Box>
                <span className={styles.sliderValue}>{minDepositAmountSliderText}</span>
            </section>
            <section>
                <div className={classNames(styles.smallCheckBoxFilter)}>
                    <FormControlLabel
                        sx={{ mb: 0 }}
                        control={
                            <Switch
                                name="filter-nibor"
                                checked={filterHasFloating}
                                onChange={() =>
                                    floatingIds.forEach((id) => func.setFilterProduct(id, !filterHasFloating))
                                }
                            />
                        }
                        label={t('pages-marketplace.floatingInterest')}
                    />
                </div>
                {filterHasFloating && productsFloatingBadges}
            </section>
            <section>
                <div className={classNames(styles.smallCheckBoxFilter)}>
                    <FormControlLabel
                        sx={{ mb: 0 }}
                        control={
                            <Switch
                                name="filter-fixed"
                                checked={filterHasFixed}
                                onChange={() => fixedIds.forEach((id) => func.setFilterProduct(id, !filterHasFixed))}
                            />
                        }
                        label={t('pages-marketplace.fixedInterest')}
                    />
                </div>
                {filterHasFixed && productsFixedBadges}
            </section>
        </Stack>
    )

    return !isLoggedIn ? null : (
        <RichTooltip open={filterOpen} onClose={() => setFilterOpen(false)} placement={'bottom'} content={filterPanel}>
            <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                <FormControl>
                    <Select
                        sx={{
                            minWidth: '20rem',
                            maxWidth: '25rem',
                            height: '4.7rem',
                            '& .MuiSelect-select': {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                                maxHeight: '100%',
                            },
                        }}
                        value={currentFilterSelection[depositor?.id] || 'ALL'}
                        onChange={(e) => onFilterSelect(e.target.value as DepositFilterOption)}
                    >
                        <MenuItem
                            data-cy="filterAllOffers"
                            data-selected={currentFilterSelection[depositor?.id] === 'ALL' ? 'yes' : 'no'}
                            value="ALL"
                        >
                            {t('pages-marketplace.allOffers')}
                        </MenuItem>
                        <MenuItem
                            data-cy="filterFloatingInterestTab"
                            data-selected={currentFilterSelection[depositor?.id] === 'FLOATING' ? 'yes' : 'no'}
                            value="FLOATING"
                        >
                            {t('pages-marketplace.floatingInterest')}
                        </MenuItem>
                        <MenuItem
                            data-cy="filterFixedInterestTab"
                            data-selected={currentFilterSelection[depositor?.id] === 'FIXED' ? 'yes' : 'no'}
                            value="FIXED"
                        >
                            {t('pages-marketplace.fixedInterest')}
                        </MenuItem>
                        <MenuItem
                            data-cy="filterCustomTab"
                            data-selected={currentFilterSelection[depositor?.id] === 'CUSTOM' ? 'yes' : 'no'}
                            value="CUSTOM"
                        >
                            {t('pages-marketplace.customFilter')}
                        </MenuItem>
                        {hasPlacementProfileAccess && (
                            <MenuItem
                                data-cy="filterMandateTab"
                                data-selected={currentFilterSelection[depositor?.id] === 'MANDATE' ? 'yes' : 'no'}
                                value="MANDATE"
                            >
                                {t('pages-marketplace.mandateFilter')}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
                {currentFilterSelection[depositor?.id] === 'CUSTOM' && (
                    <Button size={'small'} variant={'text'} onClick={() => setFilterOpen(true)}>
                        {t('pages-marketplace.edit')}
                    </Button>
                )}
                {currentFilterSelection[depositor?.id] === 'MANDATE' && (
                    <Button size={'small'} variant={'text'} onClick={() => setEditMandateDialog(true)}>
                        {t('pages-marketplace.edit')}
                    </Button>
                )}
                <FxDialog open={editMandateDialog} maxWidth="lg" onClose={() => setEditMandateDialog(false)}>
                    <FxDialogTitle onClose={() => setEditMandateDialog(false)}>
                        {t('pages-marketplace.editMandate')}
                    </FxDialogTitle>
                    <FxDialogContent sx={{ width: '100rem', maxWidth: '100%' }}>
                        <Tabs
                            sx={{ mb: 3 }}
                            value={selectedDialogTab}
                            onChange={(_, newValue) => setSelectedDialogTab(newValue)}
                        >
                            <Tab label={t('pages-organizations.bankSelection')} value="mandate" />
                            <Tab label={t('pages-organizations.terms')} value="placement" />
                        </Tabs>
                        <PlacementProfile
                            inline
                            mandate={selectedDialogTab === 'mandate'}
                            placementDemands={selectedDialogTab === 'placement'}
                        />
                    </FxDialogContent>
                </FxDialog>
            </Stack>
        </RichTooltip>
    )
}
