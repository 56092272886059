import { CHART_COLORS, SILVER_GRAY } from '#app/colors/colors'
import { DoughnutChart } from '#app/components/Charts/DoughnutChart/DoughnutChart'
import LoadingSpinner from '#components/LoadingSpinner/LoadingSpinner'
import { StaggDataItem } from '#state/stagg'
import { useSelector } from '#state/useSelector'
import { Box, Stack, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { ChartDataset } from 'chart.js'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './SimpleExposureChart.module.scss'

type Props = {
    data: StaggDataItem[] | null
    depositorId: string
}

type ExposureData = {
    interest: number
    name: string
    volume: number
}[]

type ChartData = {
    datasets: ChartDataset[]
    labels?: string[]
}

const SimpleExposureChart = ({ data, depositorId }: Props) => {
    const { t } = useTranslation()
    const deposits = useSelector((state) => state.deposits)
    const banks = useSelector((state) => state.banks)
    const chartRef = useRef()

    const formatVolume = (volume) => volume.toLocaleString('no-NO', { maximumFractionDigits: 0 })
    const namesEqual = (name1, name2) => name1?.toLowerCase().trim() === name2.toLowerCase().trim()
    const balance = (deposit) =>
        deposit.calculatedTotalBalance ? deposit.calculatedTotalBalance : deposit.calculatedFinalBalance

    const depositsForDepositor = deposits.filter(
        (deposit) => deposit.depositor?.id === depositorId && !deposit.expires?.expired
    )
    const totalCalculatedBalance = () => depositsForDepositor.reduce((acc, deposit) => acc + balance(deposit), 0)
    const calculatedBalance = (name) =>
        depositsForDepositor
            .filter((deposit) => getBankIds(name).includes(deposit.bankId))
            .reduce((acc, deposit) => acc + balance(deposit), 0)

    const getBankIds = (nameOrType) =>
        Object.values(banks)
            .filter((bank) => namesEqual(bank.name, nameOrType) || namesEqual(bank.bankType, nameOrType))
            .map((bank) => bank.id)

    const { series } = !!data && data.length > 0 && data[0]
    const typedData: ExposureData = series
        ?.filter((s) => s.values[s.values.length - 1][1])
        .map((s) => ({
            name: s.tags.name,
            volume: s.values[s.values.length - 1][1],
            interest: s.values[s.values.length - 1][2],
        }))

    const total = typedData?.reduce((acc, s) => acc + s.volume, 0)

    const chartData: ChartData = {
        datasets: [
            {
                data: typedData?.map((d) => (d.volume * 100) / total),
            },
        ],
        labels: typedData?.map((d) => d?.name),
    }

    return (
        <div ref={chartRef}>
            <Stack direction="row" spacing={4} flexWrap="wrap">
                <Box width={{ xs: '100%', md: 'calc(100% - 30rem)' }}>
                    {typedData !== undefined && typedData?.length > 0 && (
                        <Table sx={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('pages-analytics.exposureTableSimpleBank')}</TableCell>
                                    <TableCell>
                                        <Tooltip title={t('pages-analytics.exposureTableSimpleVolumeTooltip')}>
                                            <span>{t('pages-analytics.exposureTableSimpleVolume')}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{t('pages-analytics.exposureTableSimpleShare')}</TableCell>
                                    <TableCell>
                                        {t('pages-analytics.exposureTableSimpleWeightedInterestRate')}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={t('pages-analytics.exposureTableSimpleCalculatedBalanceTooltip')}
                                        >
                                            <span>{t('pages-analytics.exposureTableSimpleCalculatedBalance')}</span>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {typedData
                                    ?.sort((a, b) => b.volume - a.volume)
                                    .map((data, index) => (
                                        <TableRow key={data.name}>
                                            <TableCell>
                                                <span style={{ color: CHART_COLORS[index] }}>&#9646;</span> {data.name}
                                            </TableCell>
                                            <TableCell>
                                                {data.volume % 1 === 0 ? data.volume : data.volume?.toFixed(2)}M
                                            </TableCell>
                                            <TableCell>{((data.volume * 100) / total)?.toFixed(1)}%</TableCell>
                                            <TableCell>{data.interest?.toFixed(2)}%</TableCell>
                                            <TableCell>kr {formatVolume(calculatedBalance(data.name))}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TableCell style={{ paddingLeft: '16px' }}>
                                        {t('pages-analytics.exposureTotally')}
                                    </TableCell>
                                    <TableCell>{total % 1 === 0 ? total : total.toFixed(2)}M</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell>kr {formatVolume(totalCalculatedBalance())}</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    )}
                    {(typedData === undefined || typedData?.length === 0) && (
                        <Typography color={SILVER_GRAY[500]}>
                            {t('pages-analytics.exposureTableEmptyDescription')}
                        </Typography>
                    )}
                </Box>
                <Box height={'20rem'} width={'20rem'} maxWidth="100%">
                    {typedData ? (
                        <DoughnutChart chartData={chartData} options={{ plugins: { legend: { display: false } } }} />
                    ) : (
                        <LoadingSpinner text={'Henter data...'} />
                    )}
                </Box>
            </Stack>
        </div>
    )
}

export default SimpleExposureChart
