import { Fragment } from 'react'
import { ButtonRow, LabeledInput } from '#components'
import Button from '#components/Button'
import styles from './Steps.module.css'
import { useTranslation } from 'react-i18next'
import { StepProps } from '../types'
import { replaceComma } from '#services/parse'

export default function Step1({ onPrevious, onNext, fields }: StepProps) {
    const { t } = useTranslation()

    function onContinue() {
        onNext()
    }

    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep1Message')}</p>
            <div className={styles.inputSection}>
                <div className={styles.column}>
                    <LabeledInput
                        label={t('pages-offer.totalVolume')}
                        className={styles.totalVolume}
                        helpText={t('pages-offer.wizardStep1TotalVolumeHelp')}
                        errorMessage={fields.totalVolume.errorMessage}
                    >
                        <input
                            id={'totalVolume'}
                            type="text"
                            className={styles.number}
                            onChange={(e) => fields.totalVolume.setValue(e.target.value as unknown as number)}
                            onBlur={fields.totalVolume.submit}
                            value={replaceComma(fields.totalVolume.value) || ''}
                        />
                        <span>{t('pages-offer.mill')}</span>
                    </LabeledInput>
                </div>
                <div className={styles.column}>
                    <LabeledInput
                        label={t('pages-offer.minAmount')}
                        className={styles.minVolume}
                        helpText={t('pages-offer.wizardStep1MinAmountHelp')}
                        errorMessage={fields.minVolume.errorMessage}
                    >
                        <input
                            id={'minVolume'}
                            type="text"
                            className={styles.number}
                            onChange={(e) => fields.minVolume.setValue(e.target.value as unknown as number)}
                            onBlur={fields.minVolume.submit}
                            value={replaceComma(fields.minVolume.value) || ''}
                        />
                        <span>{t('pages-offer.mill')}</span>
                    </LabeledInput>
                    <LabeledInput
                        label={t('pages-offer.maxAmount')}
                        className={styles.number}
                        helpText={t('pages-offer.wizardStep1MaxAmountHelp')}
                        errorMessage={fields.maxVolume.errorMessage}
                    >
                        <input
                            id={'maxVolume'}
                            type="text"
                            className={styles.number}
                            onChange={(e) => fields.maxVolume.setValue(e.target.value as unknown as number)}
                            onBlur={fields.maxVolume.submit}
                            value={replaceComma(fields.maxVolume.value) || ''}
                        />
                        <span>{t('pages-offer.mill')}</span>
                    </LabeledInput>
                </div>
            </div>
            <ButtonRow>
                <Button variant={'secondary'} onClick={onPrevious}>
                    {t('common.previous')}
                </Button>
                <Button id="continueButton" onClick={onContinue}>
                    {t('common.continue')}
                </Button>
            </ButtonRow>
        </Fragment>
    )
}
