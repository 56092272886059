import { Fragment } from 'react'
import ButtonRow from '#components/ButtonRow'
import Button from '#components/Button'
import { useTranslation } from 'react-i18next'
import { StepProps } from '../types'
import { useSelector } from '#state/useSelector'
import * as selectors from '#state/selectors'
import { Alert, Stack } from '@mui/material'

export default function Step5({ onPrevious, onNext, fields }: StepProps) {
    const { t } = useTranslation()
    const isFixrateUser = useSelector(selectors.isFixrateUser)
    const valid = fields.allValid()
    const fieldErrorMessage = fields.getFirstFieldErrorMessage()

    return (
        <Fragment>
            <p>{t('pages-offer.wizardStep5MessagePart1')}</p>
            <p>{t('pages-offer.wizardStep5MessagePart2')}</p>
            <p>{t('pages-offer.wizardStep5MessagePart3')}</p>
            <p>{t('pages-offer.wizardStep5MessagePart4')}</p>
            <Stack spacing={2}>
                {!valid && (
                    <Alert severity={'error'}>
                        {t('pages-offer.wizardStep5MandatoryFieldsValidation')}
                        {fieldErrorMessage && ' ' + fieldErrorMessage}
                    </Alert>
                )}
                {isFixrateUser && <Alert severity={'warning'}>{t('pages-offer.wizardStep5MessageSupport')}</Alert>}
                <ButtonRow>
                    <Button variant={'secondary'} onClick={onPrevious}>
                        {t('common.previous')}
                    </Button>
                    <Button id="sendOfferButton" onClick={onNext} disabled={!valid || isFixrateUser}>
                        {t('pages-offer.wizardStep5SendOffer')}
                    </Button>
                </ButtonRow>
            </Stack>
        </Fragment>
    )
}
